const itemFormDefaultState = {
    items: [],
    has_choices: false,
    message: '',
    error: '',
};

export default (state = itemFormDefaultState, action) => {

    switch (action.type) {

        case 'ITEM_FORM_UPDATE':
            return {
                ...state,
                [action.item.prop]: action.item.value
            };

        case 'ITEM_FORM_ADD_ITEM':
            return {
                ...state,
                items: state.items.concat(action.item)
            };

        case 'ITEM_FORM_REMOVE_ITEM':
            return {
                ...state,
                items: state.items.filter((item, index) => {
                    return index != action.index;
                })
            };

        case 'ITEM_FORM_ERROR':
            return {
                ...state,
                message: '',
                error: action.message
            }

        case 'ITEM_FORM_SUCCESS':
            return {
                ...state,
                error: '',
                message: action.message
            }

        case 'ITEM_FORM_RESET':
            return itemFormDefaultState;

        default:
            return state;
    }
};