import React from 'react';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

import LoadScreen from '../common/LoadScreen';
import verifyLogin from '../../utils/verifyLogin';
import { loadPageData } from '../../actions/auth';
import { importMenuUpdate, doImportMenu, importMenuFormError } from '../../actions/restaurant/importMenu';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CircularProgress from '@material-ui/core/CircularProgress';

import Alert from '@material-ui/lab/Alert';

import checkPermissions from '../../utils/checkUserPermissions';

class ImportMenu extends React.Component {

    constructor(props) {
        super(props);

        // this.updateSelectedRestaurant = this.updateSelectedRestaurant.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onFileChange = this.onFileChange.bind(this);
        this.checkPermissions = this.checkPermissions.bind(this);

        this.state = {
            selectedFile: null,
            sidebarMenuOpen: false,
        };

    }

    checkPermissions() {
        if (checkPermissions(this.props.user, 'restaurant_manager', this.props.match.params.id) ||
            checkPermissions(this.props.user, 'support')) {
            // load the restaurant
            this.props.dispatch(loadPageData(this.props.match.params));
        } else {
            this.props.history.push('/permission-denied');
        }  
    }

    onFileChange(e) {
        this.setState({ selectedFile: e.target.files[0] });
    }

    onSubmit(e) {
        e.preventDefault();

        if (!this.state.selectedFile) {
            this.props.dispatch(importMenuFormError('Please select a valid file to import before proceeding.'));
        }
        else {
            this.props.dispatch(doImportMenu(this.props.match.params.id, this.state.selectedFile, this.props.purge));
        }
    }

    componentDidMount() {
        verifyLogin(this.props.history);
        this.props.dispatch(loadPageData(this.props.match.params));
    }

    render() {
        if (this.props.loading) {
            return (
                <div className="page-loading">
                    <CircularProgress size={100} />
                </div>
            );
        }
        else {
            return (
                <LoadScreen
                    checkPermissions={this.checkPermissions}
                >
                    <div className="form-container">
                        <form className="form" onSubmit={this.onSubmit}>
                        { this.props.error && <Alert className="form-error" severity="error">{this.props.error}</Alert> }
                            <Grid container>
                                <Grid item xs={12} className="heading-grid">
                                    <h1>Import Menu</h1>
                                    { this.props.message && <Typography className="success-text">
                                    { this.props.message }
                            </Typography> }
                                </Grid>
                                <Grid item xs={6} className="restaurant-card-grid">
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Typography className="modal-description" variant="h6">
                                                Imported Menu
                                            </Typography>
                                            <InputLabel>Upload your previously exported Click2Order menu here:</InputLabel>
                                        </Grid>
                                    </Grid>
                                    <br />
                                    <input type="file" onChange={(e) => this.onFileChange(e)} />
                                </Grid>

                                <Grid item xs={6} className="restaurant-card-grid">
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Typography className="modal-description" variant="h6">
                                                Purge Old Items?
                                            </Typography>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox 
                                                        checked={this.props.purge}
                                                        onChange={(e) => this.props.dispatch(importMenuUpdate({ prop: 'purge', value: !this.props.purge }))}
                                                        name={"purge"}
                                                        color="primary"
                                                    />
                                                }
                                                label="Yes, do the purge!"
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} className="restaurant-card-grid">
                                    <Button variant="contained" fullWidth={true} color="primary" type="submit" className="submit submit-prim">
                                        Import Menu
                                    </Button>
                                </Grid> 
                            </Grid>
                        </form>
                    </div>
                </LoadScreen>
            );
        }
    }
};

const mapStateToProps = (state, props) => {
    return {
        purge: state.menuImportForm.purge,
        error: state.menuImportForm.error,
        message: state.menuImportForm.message,
        loading: state.menuImportForm.loading,
        user: state.loggedUser.user,
    };
};

export default connect(mapStateToProps)(ImportMenu);