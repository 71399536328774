import React from 'react';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Typography from '@material-ui/core/Typography';

import SortableContainer from '../../common/sort/SortableContainer';
import SortableHandle from '../../common/sort/SortableHandle';
import SortableItem from '../../common/sort/SortableItem';

import { API_URL } from '../../../config/constants';
import restaurant_logo from '../../../assets/images/restaurant_default.png';

class SortableMenuList extends React.Component {

    constructor(props) {
        super(props);
    }
      

    componentDidMount() {
    }

    calcGridSize(){
        let optionalItems = [this.props.showImage, this.props.showEnable, this.props.showEdit, this.props.showDelete].filter(Boolean).length;
        return 12 - 1 - optionalItems;//1 being the sorter handle
    }

    renderDeleteButton(item){
        return (
            <Button fullWidth={true} color="secondary" className="modal-button-list" onClick={() => this.props.deleteItem(item)}>
                <DeleteIcon className="modal-button-icon" />
            </Button>
        );
    }

    renderEnableButton(item) {
        if (item.enabled) {
            return (
                <Button fullWidth={true} color="secondary" className="modal-button-list" onClick={() => this.props.toggleEnabled(item, 0)}>
                    Disable
                </Button>
            );
        }
        else {
            return (
                <Button fullWidth={true} color="primary" className="modal-button-list" onClick={() => this.props.toggleEnabled(item, 1)}>
                    Enable
                </Button>
            );
        }
    }

    renderItems(direction){
        if(direction == "row"){
            return this.renderRows();
        }else{
            return this.renderColumns();
        }
    }

    renderName(item){
        if(this.props.getCustomName == false){
            return item.name;
        }else{
            return this.props.getCustomName(item);
        }
    }

    renderColumns(){
        return (
            <Grid item xs={12} className="sortable-list">
                {this.props.items.map((item, index) => (
                    <SortableItem key={"settingssize-"+index} index={index}>
                        <Grid key={"item-"+index} className={(item.enabled) ? "sortable-menu-item" : "sortable-menu-item disabled-item"} container>
                            <Grid item xs={1} className="list-sort">
                                <SortableHandle />
                            </Grid>
                            {this.props.showImage && (
                                <Grid item xs={1} className="list-image">
                                    <img src={(item.item_image) ? API_URL+'/'+item.item_image : restaurant_logo} />
                                </Grid>
                            )}
                            <Grid item xs={this.calcGridSize()} className="list-name" onClick={() => { if(this.props.isCategory) { this.props.history.push('/'+this.props.restaurantId+'/categories/'+item.id+'/fooditems'); } }}>
                                <Typography>{this.renderName(item)}</Typography>
                            </Grid>
                            {this.props.showEnable && (
                                <Grid item xs={1} className="list-sort">
                                    {this.renderEnableButton(item)}
                                </Grid>
                            )}
                            {this.props.showEdit && (
                                <Grid item xs={1} className="list-sort">
                                    <Button fullWidth={true} color="primary" className="modal-button-list" onClick={() => this.props.toggleUpdateModal(true, item)}>
                                        <EditIcon className="modal-button-icon" />
                                    </Button>
                                </Grid>
                            )}
                            {this.props.showDelete && (
                                <Grid item xs={1} className="list-sort">
                                    {this.renderDeleteButton(item)}
                                </Grid>
                            )}
                        </Grid>
                    </SortableItem>
                ))}
            </Grid>
        );
    }

    renderRows(){
        return  this.props.items.map((item, index) => (
                    <SortableItem key={"settingssize-"+index} index={index}>
                        {this.props.itemContent(item, index, <SortableHandle />)}
                    </SortableItem>
            ));
    }

    render() {
        return (
            <SortableContainer axis={this.props.direction == "row" ? 'xy' : 'y'} helperClass='sortableHelper' onSortEnd={this.props.onSortEnd} useDragHandle>
                <Grid container direction={this.props.direction}>
                    {this.renderItems(this.props.direction)}
                </Grid>
            </SortableContainer>
        );
    }
};

SortableMenuList.defaultProps = {
    direction: 'column',
    showDelete: false,
    showEdit: true,
    showEnable: true,
    getCustomName: false,
};

export default connect()(SortableMenuList);