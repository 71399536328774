export const customPizzaFormUpdate = ({ prop, value }) => {
    return {
        type: 'CUSTOM_PIZZA_FORM_UPDATE',
        customPizza: { prop, value }
    };
};

export const customPizzaFormUpdateHoursAvailable = ({ prop, value }) => {
    return {
        type: 'CUSTOM_PIZZA_FORM_UPDATE_HOURS_AVAILABLE',
        location: { prop, value }
    };
};

export const customPizzaFormUpdatePrice = ({ prop, index, value }) => {

    return {
        type: 'CUSTOM_PIZZA_FORM_UPDATE_PRICE',
        item: { prop, index, value }
    };
};

export const customPizzaFormResetPrices = (index) => {

    return {
        type: 'CUSTOM_PIZZA_FORM_RESET_PRICES'
    }
}

export const customPizzaFormError = (message) => {

    return {
        type: 'CUSTOM_PIZZA_FORM_ERROR',
        message
    };
};

export const customPizzaFormSuccess = (message) => {

    return {
        type: 'CUSTOM_PIZZA_FORM_SUCCESS',
        message
    };
};

export const customPizzaFormSet = (customPizza, index) => {
    
    return {
        type: 'CUSTOM_PIZZA_FORM_SET',
        customPizza: {
            ...customPizza,
            prices: customPizza.prices.sort(function(a, b) {
                if ( a.price_order < b.price_order ){
                    return -1;
                  }
                  if ( a.price_order > b.price_order ){
                    return 1;
                  }
                  return 0;
            }),
            index
        }
    };
}

export const customPizzaFormReset = () => {
    
    return {
        type: 'CUSTOM_PIZZA_FORM_RESET',
    };
}

export const populateNewCustomPizzaFormSizes = (sizes) => {

    return (dispatch) => {

        let prices = [];
        for(let i = 0; i < sizes.length; i++) {
            prices.push({
                size_id: sizes[i].id,
                size_name: sizes[i].size_name,
                price: sizes[i].default_price
            });
        }

        dispatch(customPizzaFormUpdate({
            prop: 'prices',
            value: prices
        }));
    };
}

export const populateUpdateCustomPizzaFormSizes = (customPizza) => {

    return (dispatch) => {

        let prices = [];
        for(let i = 0; i < customPizza.prices.length; i++) {
            prices.push({
                id: customPizza.prices[i].id,
                size_id: customPizza.prices[i].size_id,
                size_name: customPizza.prices[i].size_name,
                price: customPizza.prices[i].price
            });
        }

        dispatch(customPizzaFormUpdate({
            prop: 'prices',
            value: prices
        }));
    };
}