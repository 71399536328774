import React from 'react';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import validateCurrency from '../../../../utils/validateCurrency';
import { setPizzasLoading } from '../../../../actions/menu/pizzas/pizzas';
import { settingsFormUpdate, settingsFormError, savePizzaSettings } from '../../../../actions/menu/pizzas/settingsForm';
import { closePizzaSettingsUpdate } from '../../../../actions/modals';

import CloseIcon from '../../../common/modal/CloseIcon';

// import Info from './Info';
import ConfigSizes from './ConfigSizes';
import ConfigToppings from './ConfigToppings';
import ConfigCrusts from './ConfigCrusts';
import ConfigSauces from './ConfigSauces';

class UpdatePizzaSettings extends React.Component {

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.closeConfigModal = this.closeConfigModal.bind(this);
        this.updatePizzaSettingSizes = this.updatePizzaSettingSizes.bind(this);
        this.updatePizzaSettingToppings = this.updatePizzaSettingToppings.bind(this);
        this.updatePizzaSettingCrusts = this.updatePizzaSettingCrusts.bind(this);
        this.updatePizzaSettingSauces = this.updatePizzaSettingSauces.bind(this);
        this.updateTabSelection = this.updateTabSelection.bind(this);

        this.state = {
            displayView: 0
        };
    }

    handleChange(event, newValue) {
        this.props.dispatch(settingsFormUpdate({ prop: 'error', value: '' }));
        this.props.dispatch(settingsFormUpdate({ prop: 'message', value: '' }));
        this.setState(() => ({displayView: newValue}))
    }

    getSteps() {
        return [
            'Sizes and Prices',
            'Toppings',
            'Crusts',
            'Sauces'
        ];
    }

    closeConfigModal() {
        this.props.dispatch(closePizzaSettingsUpdate());
        this.setState(() => ({displayView: 0}));
    }

    updateTabSelection(index) {
        this.props.dispatch(settingsFormUpdate({ prop: 'activeTab', value: index }));
    }

    updatePizzaSettingSizes() {

        let valid = true;
        let multiplePriceCount = 0;

        // go through each size and determine if it is valid
        for (let i = 0; i < this.props.pizzaSettingsForm.sizes.length; i++) {

            // ignore any blank rows
            if (this.props.pizzaSettingsForm.sizes[i].size_name != '' || this.props.pizzaSettingsForm.sizes[i].default_price != '') {
                if (!validateCurrency(this.props.pizzaSettingsForm.sizes[i].default_price) || this.props.pizzaSettingsForm.sizes[i].size_name.match(/^\s*$/)) {
                    valid = false;
                    this.props.dispatch(settingsFormError("Please ensure that all sizes have a valid name and default price."));
                }
                else {
                    multiplePriceCount++;
                }
            }
        }

        if (multiplePriceCount <= 0 && valid) {
            valid = false;
            this.props.dispatch(settingsFormError("Please enter at least one size."));
        }

        if (valid) {
            this.props.dispatch(settingsFormError(""));
            
            // clean out any multiple sizes that are empty
            let trimmedSizes = this.props.pizzaSettingsForm.sizes.filter((size) => {
                return size.size_name != '' && size.default_price != '';
            });
            this.props.dispatch(settingsFormUpdate({ prop: 'sizes', value: trimmedSizes }));
            this.props.dispatch(savePizzaSettings(this.props.restaurantId, 'sizes', trimmedSizes, this.props.pizzaSettingsForm.deletedSizes, true, this.props.pizzaSettingsForm.scrollPosition));
        }
    }

    updatePizzaSettingToppings() {

        let valid = true;
        let toppingsCount = 0;

        // go through each size and determine if it is valid
        for (let i = 0; i < this.props.pizzaSettingsForm.toppings.length; i++) {

            for (let j = 0; j < this.props.pizzaSettingsForm.toppings[i].prices.length; j++) {

                if (!validateCurrency(this.props.pizzaSettingsForm.toppings[i].prices[j].price)) {
                    valid = false;
                    this.props.dispatch(settingsFormError("Please ensure that all sizes have a valid price."));
                }
            }

            toppingsCount++;
        }

        if (toppingsCount <= 0 && valid) {
            valid = false;
            this.props.dispatch(settingsFormError("Please enter at least one topping."));
        }

        if (valid) {
            this.props.dispatch(settingsFormError(""));
            this.props.dispatch(savePizzaSettings(this.props.restaurantId, 'toppings', this.props.pizzaSettingsForm.toppings, this.props.pizzaSettingsForm.deletedToppings, true, this.props.pizzaSettingsForm.scrollPosition));
        }
    }

    updatePizzaSettingCrusts() {

        let valid = true;

        // go through each size and determine if it is valid
        for (let i = 0; i < this.props.pizzaSettingsForm.crusts.length; i++) {

            for (let j = 0; j < this.props.pizzaSettingsForm.crusts[i].prices.length; j++) {

                if (!validateCurrency(this.props.pizzaSettingsForm.crusts[i].prices[j].price)) {
                    valid = false;
                    this.props.dispatch(settingsFormError("Please ensure that all sizes have a valid price."));
                }
            }
        }

        if (valid) {
            this.props.dispatch(settingsFormError(""));
            this.props.dispatch(savePizzaSettings(this.props.restaurantId, 'crusts', this.props.pizzaSettingsForm.crusts, this.props.pizzaSettingsForm.deletedCrusts, true, this.props.pizzaSettingsForm.scrollPosition));
        }
    }

    updatePizzaSettingSauces() {

        let valid = true;

        // go through each size and determine if it is valid
        for (let i = 0; i < this.props.pizzaSettingsForm.sauces.length; i++) {

            for (let j = 0; j < this.props.pizzaSettingsForm.sauces[i].prices.length; j++) {

                if (!validateCurrency(this.props.pizzaSettingsForm.sauces[i].prices[j].price)) {
                    valid = false;
                    this.props.dispatch(settingsFormError("Please ensure that all sizes have a valid price."));
                }
            }
        }

        if (valid) {
            this.props.dispatch(settingsFormError(""));
            this.props.dispatch(savePizzaSettings(this.props.restaurantId, 'sauces', this.props.pizzaSettingsForm.sauces, this.props.pizzaSettingsForm.deletedSauces, true, this.props.pizzaSettingsForm.scrollPosition));
        }
    }

    renderUpdateModalActiveTab() {
        if (this.props.loading) {
            return (
                <div className="page-loading">
                    <CircularProgress size={100} />
                </div>
            );
        }
        else {
            switch(this.state.displayView) {
                case 0:
                    return this.renderUpdateConfigSizes();

                case 1:
                    return this.renderUpdateConfigToppings();

                case 2:
                    return this.renderUpdateConfigCrusts();

                case 3:
                    return this.renderUpdateConfigSauces();
            }
        }
    }

    renderUpdateFeedback() {
        return (
            <div>
                { this.props.pizzaSettingsForm.error && (
                    <Typography className="modal-error">
                        { this.props.pizzaSettingsForm.error }
                    </Typography>
                )}

                { this.props.pizzaSettingsForm.message && (
                    <Typography className="modal-success">
                        { this.props.pizzaSettingsForm.message }
                    </Typography>
                )}
            </div>
        );
    }

    renderUpdateConfigSizes() {
        return (
            <div>
                {this.renderUpdateFeedback()}

                <Typography variant="h5" id="modal-title">
                    Sizes and Default Prices
                </Typography>

                <Typography className="modal-description">
                    Enter any available pizza sizes that you have, such as 'Small', 'Medium' and 'Large'. Please note, you need to click 'Save Settings' in order for any new or updated sizes to take effect.
                </Typography>

                <ConfigSizes />

                <Grid container>
                    <Grid item xs={12}>
                        <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom" onClick={this.updatePizzaSettingSizes}>
                            Save Settings
                        </Button>
                    </Grid>
                </Grid>
            </div>
        );
    }

    renderUpdateConfigToppings() {
        return (
            <div>
                {this.renderUpdateFeedback()}

                <ConfigToppings />

                <Grid container>
                    <Grid item xs={12}>
                        <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom" onClick={this.updatePizzaSettingToppings}>
                            Save Settings
                        </Button>
                    </Grid>
                </Grid>
            </div>
        );
    }

    renderUpdateConfigCrusts() {
        return (
            <div>
                {this.renderUpdateFeedback()}

                <ConfigCrusts />

                <Grid container>
                    <Grid item xs={12}>
                        <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom" onClick={this.updatePizzaSettingCrusts}>
                            Save Settings
                        </Button>
                    </Grid>
                </Grid>
            </div>
        );
    }

    renderUpdateConfigSauces() {
        return (
            <div>
                {this.renderUpdateFeedback()}

                <ConfigSauces />

                <Grid container>
                    <Grid item xs={12}>
                        <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom" onClick={this.updatePizzaSettingSauces}>
                            Save Settings
                        </Button>
                    </Grid>
                </Grid>
            </div>
        );
    }
    
    render() {
        return (
            <Modal
                aria-labelledby="update-pizza-settings"
                aria-describedby="update-pizza-settings"
                open={this.props.updateModalOpen}
                onClose={() => this.closeConfigModal()}
                className="modal_admin"
                disableBackdropClick
            >
                <div className="modal-member">
                    <Typography variant="h4" id="modal-title">
                        <CloseIcon/>
                    </Typography>
                    <Tabs
                        value={this.state.displayView}
                        onChange={this.handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        centered
                        style={{marginBottom: 20}}
                    >
                        <Tab label="Sizes and Prices" />
                        <Tab label="Toppings" />
                        <Tab label="Crusts" />
                        <Tab label="Sauces" />
                    </Tabs>

                    <Typography variant="h4" id="modal-title" style={{marginBottom: 20}}>
                        Pizza Settings
                    </Typography>

                    {this.renderUpdateModalActiveTab()}
                </div>
            </Modal>
        );
    }
};

const mapStateToProps = (state, props) => {
    return {
        loading: state.pizzas.loading,
        restaurant: state.selectedRestaurant.restaurant,
        settings: state.pizzas.settings,
        pizzaSettingsForm: state.pizzaSettingsForm,
        updateModalOpen: state.modals.pizzaSettingsUpdate
    };
};

export default connect(mapStateToProps)(UpdatePizzaSettings);