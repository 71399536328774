export const pizzaFormUpdate = ({ prop, value }) => {

    return {
        type: 'PIZZA_FORM_UPDATE',
        pizza: { prop, value }
    };
};

export const pizzaFormUpdateHoursAvailable = ({ prop, value }) => {
    return {
        type: 'PIZZA_FORM_UPDATE_HOURS_AVAILABLE',
        location: { prop, value }
    };
};

export const pizzaFormUpdatePrice = ({ prop, index, value }) => {

    return {
        type: 'PIZZA_FORM_UPDATE_PRICE',
        item: { prop, index, value }
    };
};

export const pizzaFormAddTopping = (topping) => {

    return {
        type: 'PIZZA_FORM_ADD_TOPPING',
        topping
    }
};

export const pizzaFormRemoveTopping = (topping) => {

    return {
        type: 'PIZZA_FORM_REMOVE_TOPPING',
        topping
    }
}

export const pizzaFormAddCrust = (crust) => {

    return {
        type: 'PIZZA_FORM_ADD_CRUST',
        crust
    }
};

export const pizzaFormRemoveCrust = (crust) => {

    return {
        type: 'PIZZA_FORM_REMOVE_CRUST',
        crust
    }
}

export const pizzaFormAddSauce = (sauce) => {

    return {
        type: 'PIZZA_FORM_ADD_SAUCE',
        sauce
    }
};

export const pizzaFormRemoveSauce = (sauce) => {

    return {
        type: 'PIZZA_FORM_REMOVE_SAUCE',
        sauce
    }
}

export const pizzaFormError = (message) => {

    return {
        type: 'PIZZA_FORM_ERROR',
        message
    };
};

export const pizzaFormSuccess = (message) => {

    return {
        type: 'PIZZA_FORM_SUCCESS',
        message
    };
};

export const pizzaFormReset = () => {
    
    return {
        type: 'PIZZA_FORM_RESET',
    };
}

export const populateNewPizzaFormSizes = (sizes) => {

    return (dispatch) => {

        let prices = [];
        for(let i = 0; i < sizes.length; i++) {
            prices.push({
                size_id: sizes[i].id,
                size_name: sizes[i].size_name,
                price: sizes[i].default_price
            });
        }

        dispatch(pizzaFormUpdate({
            prop: 'prices',
            value: prices
        }));
    };
}

export const populateUpdatePizzaFormSizes = (pizza) => {

    return (dispatch) => {

        let prices = [];
        for(let i = 0; i < pizza.prices.length; i++) {
            prices.push({
                id: pizza.prices[i].id,
                size_id: pizza.prices[i].size_id,
                size_name: pizza.prices[i].size_name,
                price: pizza.prices[i].price
            });
        }

        dispatch(pizzaFormUpdate({
            prop: 'prices',
            value: prices
        }));
    };
}