import {roundToDecimal} from "../helpers/number_rounding";

export const infoFormUpdate = ({ prop, value }) => {

    let roundingInputs = ['additional_charge_blur', 'standard_charge_blur'];

    if( roundingInputs.indexOf(prop) > -1 ) {

        let { prop, value } = roundToDecimal({prop: prop, value: value, roundToPlace: 3, removeAffix: "_blur"});

    }

    return {
        type: 'INFO_FORM_UPDATE',
        info: { prop, value }
    };
};

export const infoFormError = (message) => {

    return {
        type: 'INFO_FORM_ERROR',
        message
    };
};

export const infoFormSuccess = (message) => {

    return {
        type: 'INFO_FORM_SUCCESS',
        message
    };
};

export const infoFormReset = () => {
    
    return {
        type: 'INFO_FORM_RESET',
    };
}