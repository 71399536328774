const promoCodesDefaultState = {
    loading: false,
    message: '',
    error: '',
    promos: [],
    archive_promos: [],
    promoArchives: [],
    locations: [],
    dispatchCenters: [],
    promos_filter: '',
};

export default (state = promoCodesDefaultState, action) => {
    
    switch (action.type) {

        case 'SET_PROMOS':
            return {
                ...state,
                promos: action.promos
            };

        case 'SET_PROMOS_FILTER':
            return {
                ...state,
                promos_filter: action.promos_filter
            };

        case 'SET_PROMO_ARCHIVES':
            return {
                ...state,
                archive_promos: action.archive_promos
            };

        case 'SET_PROMO_ADMIN_LOCATIONS':
            return {
                ...state,
                locations: action.locations
            };

        case 'SET_PROMO_ADMIN_DISPATCH_CENTERS':
            return {
                ...state,
                dispatchCenters: action.dispatchCenters
            };

        case 'SET_PROMO_CODES_LOADING':
            return {
                ...state,
                loading: true
            };

        case 'SET_PROMO_CODES_DONE_LOADING':
            return {
                ...state,
                loading: false
            };

        case 'PROMO_CODES_RESET':
            return promoCodesDefaultState;

        default:
            return state;
    }
};