// the default items that we can possibly filter by
const locationsDefaultState = {
    loading: true,
    locations: [],
    filter: ''
};

export default (state = locationsDefaultState, action) => {
    switch (action.type) {
        case 'SET_LOCATIONS':
            return {
                ...state,
                loading: false,
                locations: action.locations.map((location) => {
                    location.dispatchAssociated = !!location.dispatch_location;
                    // React doesn't like nulls in the input fields so we want to make them empty strings
                    location.address = location.address ?? '';
                    location.address2 = location.address2 ?? '';
                    location.city = location.city ?? '';
                    location.postal = location.postal ?? '';
                    location.phone = location.phone ?? '';
                    location.phone2 = location.phone2 ?? '';
                    location.warning_phone = location.warning_phone ?? '';
                    location.message = location.message ?? '';
                    return location;
                }),
                filter: ''
            };

        case 'SET_LOCATION_FILTER':
            return {
                ...state,
                filter: action.text
            };

        case 'SET_LOCATIONS_LOADING':
            return {
                ...state,
                loading: true
            };

        case 'SET_LOCATIONS_DONE_LOADING':
            return {
                ...state,
                loading: false
            };
            
        case 'RESET_LOCATIONS':
            return locationsDefaultState;

        default:
            return state;
    }
};