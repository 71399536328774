import React from 'react';
import Favicon from 'react-favicon';
import { BrowserRouter, Switch } from 'react-router-dom';
import Route from './CustomRoute';

import DashboardPage from '../components/DashboardPage';
import RestaurantDashboardPage from '../components/restaurants/DashboardPage';
import NotFoundPage from '../components/NotFoundPage';
import LoginPage from '../components/LoginPage';
import ForgotPasswordPage from '../components/ForgotPasswordPage';
import ResetPasswordPage from '../components/ResetPasswordPage';
import RegisterManagerPage from '../components/RegisterManagerPage';
import Header from '../components/common/Header';
import LeftSidebar from '../components/common/LeftSidebar';

// restaurant pages
import GeneralInformationPage from '../components/restaurants/GeneralInformationPage';
import OrdersPage from '../components/restaurants/OrdersPage';
import ManagersPage from '../components/restaurants/ManagersPage';
import LoyaltyPage from '../components/restaurants/LoyaltyPage';

// dispatch pages
import DispatchOrdersPage from '../components/dispatch/DispatchOrdersPage';

// menu pages
import FoodCategoriesPage from '../components/menu/categories/FoodCategoriesPage';
import FoodItemsPage from '../components/menu/foodItems/FoodItemsPage';
import PizzasPage from '../components/menu/pizzas/PizzasPage';
import SpecialsPage from '../components/menu/specials/SpecialsPage';
import AddonsPage from '../components/menu/addons/AddonsPage';
import ModifiersPage from '../components/menu/modifiers/ModifiersPage';
import ImportMenu from '../components/restaurants/ImportMenu';

// menu export page
import ExportRestaurantMenu from '../components/superuser/ExportRestaurantMenu';

// superuser pages
import AppDirectoriesPage from '../components/superuser/AppDirectoriesPage';
import DispatchCentersPage from '../components/superuser/DispatchCentersPage';
import UserManagementPage from '../components/superuser/UserManagementPage';
import PromoCodesPage from '../components/superuser/PromoCodesPage';

// reports pages
import OrderReportsPage from '../components/reports/OrderReportsPage';
import DispatchReportsPage from '../components/reports/DispatchReportsPage';
import ItemizedReportsPage from '../components/reports/ItemizedReportsPage';

// support pages
import SupportOrdersPage from '../components/support/SupportOrdersPage';
import SupportReportsPage from '../components/reports/SupportReportsPage';

const MainRouter = (props) => {
    return (
        <BrowserRouter>
            <div>
                <Favicon url="/favicon.ico" />
                <Header />
                <LeftSidebar />
                <Switch>
                    <Route path="/" component={DashboardPage} exact={true} />
                    <Route path="/login" component={LoginPage} exact={true} />
                    <Route path="/resetPassword/:email/:token/:source?/:sitename?/:lid?" component={ResetPasswordPage} exact={true} />
                    <Route path="/registerManager/:email/:token" component={RegisterManagerPage} exact={true} />
                    <Route path="/forgotPassword" component={ForgotPasswordPage} exact={true} />

                    <Route path="/:id/dashboard/" component={RestaurantDashboardPage} exact={true} />

                    <Route path="/:id/settings/" component={GeneralInformationPage} exact={true} />
                    <Route path="/:id/orders/:lid/" component={OrdersPage} exact={true} showOrdersTime={true} />
                    <Route path="/:id/managers/" component={ManagersPage} exact={true} />
                    <Route path="/:id/loyalty/" component={LoyaltyPage} exact={true} />

                    <Route path="/:id/categories/" component={FoodCategoriesPage} exact={true} />
                    <Route path="/:id/categories/:category_id/fooditems" component={FoodItemsPage} exact={true} />
                    <Route path="/:id/pizzas/" component={PizzasPage} exact={true} />
                    <Route path="/:id/specials/" component={SpecialsPage} exact={true} />
                    <Route path="/:id/addons/" component={AddonsPage} exact={true} />
                    <Route path="/:id/modifiers/" component={ModifiersPage} exact={true} />
                    <Route path="/:id/importMenu/" component={ImportMenu} exact={true} />

                    <Route path="/:id/reports/orders/:lid/" component={OrderReportsPage} exact={true} />

                    <Route path="/admin/directories/" component={AppDirectoriesPage} exact={true} />

                    <Route path="/admin/export-menus/" component={ExportRestaurantMenu} exact={true} />

                    <Route path="/admin/dispatch-centers/" component={DispatchCentersPage} exact={true} />
                    <Route path="/admin/dispatch-centers/:did/orders/" component={DispatchOrdersPage} exact={true} />
                    <Route path="/admin/dispatch-centers/:did/reports/" component={DispatchReportsPage} exact={true} />
                    <Route path="/admin/users/" component={UserManagementPage} exact={true} />     
                    <Route path="/:id/reports/itemized/" component={ItemizedReportsPage} exact={true} />

                    <Route path="/admin/support/orders/" component={SupportOrdersPage} exact={true} showOrdersTime={true} />               
                    <Route path="/admin/support/summary/" component={SupportReportsPage} exact={true} />               
                    <Route path="/admin/support/promos/" component={PromoCodesPage} exact={true} />               

                    <Route component={NotFoundPage} />
                </Switch>
            </div>
        </BrowserRouter>
    );
}

export default MainRouter;