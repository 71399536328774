import React from 'react';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';

import { confirmOrder } from '../../actions/restaurant/orders';
import { confirmOrderFormError, confirmOrderFormUpdate } from '../../actions/restaurant/confirmOrderForm';
import { openConfirmOrder, closeConfirmOrder } from '../../actions/modals';

import CloseIcon from '../../components/common/modal/CloseIcon';

class OrderConfirm extends React.Component {

    constructor(props) {
        super(props);

        this.toggleModal = this.toggleModal.bind(this);
    }

    toggleModal(isOpen) {
        if (isOpen) {
            this.props.dispatch(openConfirmOrder());
        }
        else {
            this.props.dispatch(closeConfirmOrder());
        }
    }
    
    render() {
        return (
            <Modal
                aria-labelledby="order-confirm"
                aria-describedby="order-confirm"
                open={this.props.modalOpen}
                onClose={() => this.toggleModal(false)}
                className="modal_admin"
                disableBackdropClick
            >
                <div className="modal-member">

                    <Typography variant="h4" className="modal-title">
                        <CloseIcon/>
                    </Typography>

                    <Typography variant="h2">
                        Confirm Order
                    </Typography>

                    { this.props.confirmOrderForm.error && (
                        <Typography className="modal-error">
                            { this.props.confirmOrderForm.error }
                        </Typography>
                    )}

                    {(this.props.loading) && (
                        <div className="page-loading modal-loading">
                            <CircularProgress size={100} />
                        </div>
                    )}
                    {(!this.props.loading) && (
                        <div>
                            <Grid container className="confirm-buttons-window">
                                <Grid item xs={4} className="form-grid confirm-button-window">
                                    <Button
                                        type="button"
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        fullWidth={true}
                                        className="btn-order-status-complete order-feed-details-button"
                                        onClick={() => { this.props.confirmOrder(this.props.order.restaurant.id, this.props.order.id, 15) }}
                                    >
                                        15 Minutes
                                    </Button> 
                                </Grid>
                                <Grid item xs={4} className="form-grid confirm-button-window">
                                    <Button
                                        type="button"
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        fullWidth={true}
                                        className="btn-order-status-complete order-feed-details-button"
                                        onClick={() => { this.props.confirmOrder(this.props.order.restaurant.id, this.props.order.id, 20) }}
                                    >
                                        20 Minutes
                                    </Button> 
                                </Grid>
                                <Grid item xs={4} className="form-grid confirm-button-window">
                                    <Button
                                        type="button"
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        fullWidth={true}
                                        className="btn-order-status-complete order-feed-details-button"
                                        onClick={() => { this.props.confirmOrder(this.props.order.restaurant.id, this.props.order.id, 25) }}
                                    >
                                        25 Minutes
                                    </Button> 
                                </Grid>
                                <Grid item xs={4} className="form-grid confirm-button-window">
                                    <Button
                                        type="button"
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        fullWidth={true}
                                        className="btn-order-status-complete order-feed-details-button"
                                        onClick={() => { this.props.confirmOrder(this.props.order.restaurant.id, this.props.order.id, 30) }}
                                    >
                                        30 Minutes
                                    </Button> 
                                </Grid>
                                <Grid item xs={4} className="form-grid confirm-button-window">
                                    <Button
                                        type="button"
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        fullWidth={true}
                                        className="btn-order-status-complete order-feed-details-button"
                                        onClick={() => { this.props.confirmOrder(this.props.order.restaurant.id, this.props.order.id, 35) }}
                                    >
                                        35 Minutes
                                    </Button> 
                                </Grid>
                                <Grid item xs={4} className="form-grid confirm-button-window">
                                    <Button
                                        type="button"
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        fullWidth={true}
                                        className="btn-order-status-complete order-feed-details-button"
                                        onClick={() => { this.props.confirmOrder(this.props.order.restaurant.id, this.props.order.id, 40) }}
                                    >
                                        40 Minutes
                                    </Button> 
                                </Grid>
                            </Grid>

                            <Typography>
                                Or enter your time below:
                            </Typography>

                            <TextField
                                className="modal-input"
                                fullWidth={true}
                                value={this.props.confirmOrderForm.minutes}
                                onChange={(e) => { this.props.dispatch(confirmOrderFormUpdate({ prop: 'minutes', value: e.target.value })); }}
                                margin="normal"
                                variant="outlined"
                            />
                    
                            <Button variant="contained" fullWidth={true} className="modal-button-bottom" onClick={() => this.props.confirmOrder(this.props.order.restaurant.id, this.props.order.id, this.props.confirmOrderForm.minutes)}>
                                Confirm
                            </Button>
                        </div>
                    )}
                </div>
            </Modal>
        );
    }
};

const mapStateToProps = (state, props) => {
    return {
        loading: state.orders.loading,
        restaurant: state.selectedRestaurant.restaurant,
        confirmOrderForm: state.confirmOrderForm,
        modalOpen: state.modals.confirmOrder
    };
};

export default connect(mapStateToProps)(OrderConfirm);