import React from 'react';
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import CancelIcon from '@material-ui/icons/Cancel';

import moment from 'moment';
import TimePicker from 'rc-time-picker';

import { locationFormUpdateHourOfOperation, locationFormUpdateDeliveryHours, locationFormUpdate, locationFormNewHolidayReset } from '../../../actions/restaurant/locationForm';
import generateDropdownTimes from '../../../utils/generateDropdownTimes';
import checkPermissions from '../../../utils/checkUserPermissions';
import { daysOfWeek } from '../../../utils/dateUtil';

import DetailedAccordion from "../../common/DetailedAccordion";

const availableTimes = generateDropdownTimes();

class HoursOfOperation extends React.Component {

    constructor(props) {
        super(props);

        this.state = { 
            pictures: [],
            deliveryHoursAccess: false,
        };
    }

    componentDidMount(){

        if(checkPermissions(this.props.curUser, 'superuser') || checkPermissions(this.props.curUser, 'support')){
            this.setState(() => ({deliveryHoursAccess: true}));
        }else if(this.props.locationForm.dispatchAssociated == false){
            this.setState(() => ({deliveryHoursAccess: true}));
        }else{
            //false
        }
    }

    componentDidUpdate(prevProps, prevState){
        if(prevProps.triggerUpdate == false && this.props.triggerUpdate == true){
            this.props.dispatch(locationFormUpdate({ prop: 'triggerUpdate', value: false }));
            this.props.dispatch(locationFormUpdate({ prop: 'holidayHours', value: this.props.selectedFullLocation.holiday_hours }));
        }
    }
    
    toggleNewHolidayHoursPanel(){

        if(this.props.locationForm.showNewHolidayHours == false){ // We are showing it
            this.props.dispatch(locationFormNewHolidayReset());
            this.props.dispatch(locationFormUpdate({ prop: 'holidayHoursSelectedID', value: false }));
        }
        
        this.props.dispatch(locationFormUpdate({ prop: 'showNewHolidayHours', value: !this.props.locationForm.showNewHolidayHours }));

    }

    render() {
        let addButtonStyle = (this.props.locationForm.showNewHolidayHours) ? 'btn-cancel': 'btn-add';
        addButtonStyle += " btn-fab btn-fab-inline";

        return (
            <Grid container key={"render"}>
                <Grid item xs={12} className="form-grid">
                    <Typography variant="h3">
                        Hours of Operation
                    </Typography>
                    <Typography className="modal-description">
                        Please enter your hours of operation, or set your restaurant's button to closed if you are not open that day.
                    </Typography>
                </Grid>

                <Grid container key={"render1"} className="hour-of-operation-list">
                    <Grid item xs={3} className="form-grid"></Grid>
                    <Grid item xs={3} className="form-image-container">
                        <Typography>
                            Open Time
                        </Typography>
                    </Grid>
                    <Grid item xs={3} className="form-image-container">
                        <Typography>
                            Close Time
                        </Typography>
                    </Grid>
                    <Grid item xs={3} className="form-grid"></Grid>
                </Grid> 

                {daysOfWeek.map((day) =>{

                    const openingTimes = this.props.locationForm.hoursOfOperation[day.id+'_open'].split(":");
                    let openingHours = openingTimes[0];
                    let openingMinutes = openingTimes[1];

                    const closingTimes = this.props.locationForm.hoursOfOperation[day.id+'_close'].split(":");
                    let closingHours = closingTimes[0];
                    let closingMinutes = closingTimes[1];

                    return (
                        <Grid container key={"renderow1"+day.id} className="hour-of-operation-list">
                            <Grid item xs={3} className="form-image-container">
                                <Typography>
                                    {day.label}
                                </Typography>
                            </Grid>
                            <Grid item xs={3} className="form-grid">
                                {/* moment requires a date be set but we are not interested in that so a static one is fine */}
                                <TimePicker
                                    allowEmpty={false}
                                    showSecond={false}
                                    defaultValue={moment("2000-01-01").set("hour", openingHours).set("minute", openingMinutes)} 
                                    use12Hours={true}
                                    minuteStep={5}
                                    inputClassName="date-picker-input"
                                    onChange={(date) => { 
                                        let timeString = date.hour().toString().padStart(2, '0') + ":" + date.minutes().toString().padStart(2, '0') + ":00";
                                        this.props.dispatch(locationFormUpdateHourOfOperation({ prop: day.id+'_open', value: timeString })); 
                                    }} 
                                />

                            </Grid>
                            <Grid item xs={3} className="form-grid">
                                <TimePicker
                                    allowEmpty={false}
                                    showSecond={false}
                                    defaultValue={moment("2000-01-01").set("hour", closingHours).set("minute", closingMinutes)}
                                    use12Hours={true}
                                    minuteStep={5}
                                    inputClassName="date-picker-input"
                                    onChange={(date) => { 
                                        let timeString = date.hour().toString().padStart(2, '0') + ":" + date.minutes().toString().padStart(2, '0') + ":00";
                                        this.props.dispatch(locationFormUpdateHourOfOperation({ prop: day.id+'_close', value: timeString })); 
                                    }} 
                                />
                            </Grid>
                            <Grid item xs={3} className="form-image-container">
                                <FormControlLabel
                                    control={
                                    <Checkbox
                                        checked={!!this.props.locationForm.hoursOfOperation[day.id+'_closed']}
                                        onChange={(e) => { this.props.dispatch(locationFormUpdateHourOfOperation({ prop: day.id+'_closed', value: !this.props.locationForm.hoursOfOperation[day.id+'_closed'] })); }}
                                        name={"Closed all day?"}
                                        color="primary"
                                    />
                                    }
                                    label={"Closed all day?"}
                                />
                            </Grid>
                        </Grid>
                    )
                })}

                <Grid item xs={12} className="form-grid">
                    <Typography variant="h3">
                        Automatic Close
                    </Typography>
                    <Typography className="modal-description">
                        If you would like the store to automatically close, please select the option below.
                    </Typography>
                </Grid>
                <Grid item xs={6} className="form-grid">
                    <FormControlLabel
                        control={
                        <Checkbox
                            checked={!!this.props.locationForm.hoursOfOperation['automatic_close']}
                            onChange={(e) => { this.props.dispatch(locationFormUpdateHourOfOperation({ prop: 'automatic_close', value: !this.props.locationForm.hoursOfOperation['automatic_close'] })); }}
                            name={"Automatically close online ordering?"}
                            color="primary"
                        />
                        }
                        label={"Automatically close online ordering?"}
                        className="switch-window"
                    />
                </Grid>
                <Grid item xs={3} className="form-grid">
                    {/* <TextField
                        label="Minutes Before Close"
                        className="modal-input"
                        fullWidth={true}
                        value={this.props.locationForm.hoursOfOperation['automatic_close_minutes']}
                        onChange={(e) => { this.props.dispatch(locationFormUpdateHourOfOperation({ prop: 'automatic_close_minutes', value: e.target.value })); }}
                        margin="normal"
                        variant="outlined"
                    /> */}
                </Grid>

                {/* Holiday hours here */}

                <Grid item xs={12} className="form-grid">
                    <Typography variant="h3">
                        Holiday Hours
                    </Typography>
                </Grid>

                <Grid item xs={12} className="form-grid" style={{paddingBottom: "10px"}}>
                    <Fab className={addButtonStyle} onClick={() => this.toggleNewHolidayHoursPanel()}>
                        {this.props.locationForm.showNewHolidayHours && <CancelIcon fontSize={"large"} />}
                        {!this.props.locationForm.showNewHolidayHours && <AddIcon fontSize={"large"} />}
                    </Fab>
                        {this.props.locationForm.showNewHolidayHours && "Hide New Holiday Hours"}
                        {!this.props.locationForm.showNewHolidayHours && "Add Holiday Hours"}
                </Grid>

                {this.props.locationForm.showNewHolidayHours && (
                    <Grid item xs={12} className="form-grid" pb={3}>
                        <DetailedAccordion
                            new={true}
                            holiday={false}
                            selectedFullLocation={this.props.selectedFullLocation}
                        />
                    </Grid>
                )}

                <Grid item xs={12} className="form-grid">
                    <Typography variant="h6">
                        Upcoming Holiday Hours
                    </Typography>
                </Grid>
                {this.props.holidayHours && this.props.holidayHours.map((holiday) => {

                    return <div style={{marginBottom: 15}}>
                        <DetailedAccordion
                            new={false}
                            holiday={holiday}
                            selectedFullLocation={this.props.selectedFullLocation}
                        />
                        </div>
                })}

                {/* Holiday hours end here */}

                <Grid item xs={12} className="form-grid">
                    <Typography variant="h3">
                        Delivery Hours
                    </Typography>
                    <Typography className="modal-description">
                        {this.props.locationForm.dispatchAssociated && "Delivery hours are set by your local fleet and corresponds with their hours of operation. If your restaurant is set to be open outside these hours you can still accept pickup orders but delivery orders will be disabled."}

                        {!this.props.locationForm.dispatchAssociated && "Please enter the hours your restaurant will be delivering, or set your restaurant's button to closed if you are not delivering that day."}
                    </Typography>
                </Grid>

                <Grid container key={"render3"} className="hour-of-operation-list">
                    <Grid item xs={3} className="form-grid"></Grid>
                    <Grid item xs={3} className="form-image-container">
                        <Typography>
                            Start Time
                        </Typography>
                    </Grid>
                    <Grid item xs={3} className="form-image-container">
                        <Typography>
                            End Time
                        </Typography>
                    </Grid>
                    <Grid item xs={3} className="form-grid"></Grid>
                </Grid> 

                {daysOfWeek.map((day) => {

                    const openingTimes = this.props.locationForm.deliveryHours[day.id+'_open'].split(":");
                    let openingHours = openingTimes[0];
                    let openingMinutes = openingTimes[1];

                    const closingTimes = this.props.locationForm.deliveryHours[day.id+'_close'].split(":");
                    let closingHours = closingTimes[0];
                    let closingMinutes = closingTimes[1];
                    
                    return (
                        <Grid container key={"renderdow2"+day.id} className="hour-of-operation-list">
                            <Grid item xs={3} className="form-image-container">
                                <Typography>
                                    {day.label}
                                </Typography>
                            </Grid>
                            <Grid item xs={3} className="form-grid">
                                {/* moment requires a date be set but we are not interested in that so a static one is fine */}
                                <TimePicker
                                    allowEmpty={false}
                                    showSecond={false}
                                    defaultValue={moment("2000-01-01").set("hour", openingHours).set("minute", openingMinutes)} 
                                    use12Hours={true}
                                    minuteStep={5}
                                    inputClassName="date-picker-input"
                                    onChange={(date) => { 
                                        let timeString = date.hour().toString().padStart(2, '0') + ":" + date.minutes().toString().padStart(2, '0') + ":00";
                                        this.props.dispatch(locationFormUpdateDeliveryHours({ prop: day.id+'_open', value: timeString })); 
                                    }}
                                    disabled={!this.state.deliveryHoursAccess}
                                />

                            </Grid>
                            <Grid item xs={3} className="form-grid">
                                <TimePicker
                                    allowEmpty={false}
                                    showSecond={false}
                                    defaultValue={moment("2000-01-01").set("hour", closingHours).set("minute", closingMinutes)} 
                                    use12Hours={true}
                                    minuteStep={5}
                                    inputClassName="date-picker-input"
                                    onChange={(date) => { 
                                        let timeString = date.hour().toString().padStart(2, '0') + ":" + date.minutes().toString().padStart(2, '0') + ":00";
                                        this.props.dispatch(locationFormUpdateDeliveryHours({ prop: day.id+'_close', value: timeString })); 
                                    }}
                                    disabled={!this.state.deliveryHoursAccess}
                                />
                            </Grid>
                            <Grid item xs={3} className="form-image-container">
                                <FormControlLabel
                                    control={
                                    <Checkbox
                                        checked={this.props.locationForm.deliveryHours && !!this.props.locationForm.deliveryHours[day.id+'_closed']}
                                        onChange={(e) => { this.props.dispatch(locationFormUpdateDeliveryHours({ prop: day.id+'_closed', value: !this.props.locationForm.deliveryHours[day.id+'_closed'] })); }}
                                        name={"Closed all day?"}
                                        color="primary"
                                        disabled={!this.state.deliveryHoursAccess}
                                    />
                                    }
                                    label={"Closed all day?"}
                                />
                            </Grid>
                        </Grid>
                    )
                })}
            </Grid>
        );
    }
};

const mapStateToProps = (state, props) => {
    return {
        locationForm: state.locationForm,
        triggerUpdate: state.locationForm.triggerUpdate,
        selectedFullLocation: state.locationForm.selectedLocation,
        holidayHours: state.locationForm.holidayHours,
        curUser: state.loggedUser.user,
    };
};

export default connect(mapStateToProps)(HoursOfOperation);