export const promoCodeFormUpdate = ({ prop, value }) => {

    return {
        type: 'PROMO_CODE_FORM_UPDATE',
        promoCode: { prop, value }
    };
};

export const promoCodeUpdateValidPromoDays = ({ prop, value }) => {

    return {
        type: 'PROMO_CODE_REST_FORM_UPDATE_VALID_DAYS',
        promoCode: { prop, value }
    };
};

export const promoCodeFormError = (message) => {

    return {
        type: 'PROMO_CODE_FORM_ERROR',
        message
    };
};

export const promoCodeFormSuccess = (message) => {

    return {
        type: 'PROMO_CODE_FORM_SUCCESS',
        message
    };
};

export const promoCodeFormReset = () => {
    
    return {
        type: 'PROMO_CODE_FORM_RESET',
    };
}

export const promoCodeFormSet = (promoCode, index) => {

    // promoCode.always_available = (promoCode.start_time == null && promoCode.end_time == null) ? true: false;
    promoCode.max_uses = promoCode.max_uses;
    promoCode.total_avail = promoCode.total_avail;
    promoCode.total_type = promoCode.total_type; // PERCENTAGE OR FIXED
    promoCode.start_time = (promoCode.start_time == null) ? null: new Date(promoCode.start_time);
    promoCode.end_time = (promoCode.end_time == null) ? null: new Date(promoCode.end_time);

    return {
        type: 'PROMO_CODE_FORM_SET',
        promoCode: {
            ...promoCode,
            index
        }
    };
}