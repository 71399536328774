
const modifiersDefaultState = {
    loading: false,
    modifiers: [],
    filter: ''
};

export default (state = modifiersDefaultState, action) => {
    switch (action.type) {
        case 'SET_MODIFIERS':
            return {
                ...state,
                loading: false,
                modifiers: action.modifiers,
                filter: ''
            };

        case 'SET_MODIFIER_FILTER':
            return {
                ...state,
                filter: action.text
            };

        case 'SET_MODIFIERS_LOADING':
            return {
                ...state,
                loading: true
            };
            
        case 'RESET_MODIFIERS':
            return modifiersDefaultState;

        default:
            return state;
    }
};