import React, {Fragment} from 'react';
import Moment from 'react-moment';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

import LoadScreen from '../common/LoadScreen';
import verifyLogin from '../../utils/verifyLogin';
import { promoCodesFormReset } from '../../actions/restaurant/promoCodes';
import filterPromoCodes from '../../utils/filterListName';
import { openPromoCodeCreate, openMassPromoCodeCreate, openMassPromoCodeUpdate, openPromoCodeUpdate, closePromoCodeCreate, closeMassPromoCodeCreate, closeMassPromoCodeUpdate } from '../../actions/modals';

import { loadPromoCodes, setSelectedAdminPromoCode, promoCodeAdminFormUpdate, promoCodeAdminUpdateValidPromoDays,
    setPromoCodeAdminEditing, removeAdminPromoCode, readdAdminPromoCode, 
    setPromosFilter, promoCodeAdminFormReset, downloadMassPromoCodes } from '../../actions/superuser/promoCodeAdminForm';

import { setSelectedMassAdminPromoCode, massPromoCodeAdminFormUpdate, setEditingMassAdminPromoCode, massPromoCodeAdminFormReset } from '../../actions/superuser/massPromoCodeAdminForm';
    

import AdminPromoCodeModal from './modal/AdminPromoCodeModal';
import MassPromoCodeModal from './modal/MassPromoCodeModal';

import checkPermissions from '../../utils/checkUserPermissions';
import {getFilteredLocations} from '../../actions/helpers/getFilteredLocations';

class PromoCodesPage extends React.Component {

    constructor(props) {
        super(props);

        this.checkPermissions = this.checkPermissions.bind(this);

        this.state = {
            sidebarMenuOpen: false
        };
    }

    componentDidMount() {
        verifyLogin(this.props.history);
    }

    checkPermissions() {
        if (checkPermissions(this.props.user, 'support')) {
            this.props.dispatch(loadPromoCodes());
        } else {
            this.props.history.push('/permission-denied');
        }
    }

    toggleCreateModal(isOpen) {
        if (isOpen) {
            this.props.dispatch(promoCodesFormReset());
            this.props.dispatch(promoCodeAdminFormReset());
            this.props.dispatch(openPromoCodeCreate());
        }
        else {
            this.props.dispatch(closePromoCodeCreate());
        }
    }

    toggleMassCreateModal(isOpen) {
        if (isOpen) {
            this.props.dispatch(promoCodesFormReset());
            this.props.dispatch(promoCodeAdminFormReset());
            this.props.dispatch(massPromoCodeAdminFormReset());
            this.props.dispatch(openMassPromoCodeCreate());
        }
        else {
            this.props.dispatch(closeMassPromoCodeCreate());
        }
    }

    getTargetIcon(code){
        if (code.total_target == 'DELIVERY') {
            return <Icon className="fa fa-shipping-fast wideIcon" />;
        } else {
            return <Icon className="fa fa-shopping-cart wideIcon" />;
        }
    }

    getPromoUsageDetails(code){
        let content = '';
        if (code.total_avail == 'ALWAYS') {
            if(code.max_uses == 0){
                content = <Typography>Always available</Typography>;
            }else{
                content = <><Typography>Always available</Typography>
                            <Typography display="block">{code.usage_ratio} remaining</Typography>
                        </>;
            }
        } else if (code.total_avail == 'LIMITED DATE' && !code.start_time && code.end_time) {
            content = <Typography>Continued Use - Expires <Moment format="MMM DD, YYYY">
                    {code.end_time}
                </Moment> </Typography>;
        } else if (code.total_avail == 'LIMITED DATE' && code.start_time && code.end_time) {
            let extra = (code.max_uses > 0) ? 'Usage: ' + code.usage_ratio: '';
            content = <><Typography>Active <Moment format="MMM DD, YYYY">
                    {code.start_time}
                </Moment> — <Moment format="MMM DD, YYYY">
                    {code.end_time}
                </Moment></Typography>
                <Typography display="block">{extra}</Typography></>;
        } else if (code.total_avail == 'LIMITED QUANTITY') {
            if(code.max_uses == 1){
                content = <Typography>One-time only</Typography>;
            }else{
                content = <Typography>Limited Uses - {code.max_uses} Max </Typography>;
            }
        } else {
            content = <Typography>Unknown usage details</Typography>;
        }

        let dayAvailability = [];
        for (let [key, value] of Object.entries(code.valid_days)) {
            let formattedKey = key[0].toUpperCase();
            let activeClasses = (value) ? 'activeDayIndicator dayIndicator': 'inactiveDayIndicator dayIndicator';
            let fragment = <Fragment><span className={activeClasses}>{formattedKey}</span></Fragment>;
            dayAvailability.push(fragment);
        }

        return  <Grid container>
                    <Grid item direction={"column"}>
                        {<>
                            {content}
                            {dayAvailability}
                        </>}
                    </Grid>
                </Grid>;
    }

    getMassUsageDetails(code){
        
        return <>{code.used_single_mass_promo_codes_count} / {code.global_batch_count}</>;
    }

    formatPromoCodeDetails(code) {

        if (code.total_type == 'PERCENTAGE') {
            return code.total + '% off';
        } else if (code.total_type == 'FIXED') {
            return '$'+code.total+' off';
        } else {
            return '$'+code.total+' off';
        }
    }

    handleUpdatePromoCodeView(item) {

        //setPromoCodeAdminEditing
        this.props.dispatch(promoCodesFormReset());
        this.props.dispatch(promoCodeAdminFormReset());

        this.props.dispatch(setSelectedAdminPromoCode(item));

        this.props.dispatch(promoCodeAdminFormUpdate({ prop: 'code', value: item.code }));
        if(item.start_time){
            this.props.dispatch(promoCodeAdminFormUpdate({ prop: 'start_time', value: new Date(item.start_time) }));
        }
        if(item.end_time){
            this.props.dispatch(promoCodeAdminFormUpdate({ prop: 'end_time', value: new Date(item.end_time) }));
        }
        this.props.dispatch(promoCodeAdminFormUpdate({ prop: 'total_target', value: item.total_target }));
        this.props.dispatch(promoCodeAdminFormUpdate({ prop: 'total_type', value: item.total_type }));
        this.props.dispatch(promoCodeAdminFormUpdate({ prop: 'total_avail', value: item.total_avail }));

        this.props.dispatch(promoCodeAdminFormUpdate({ prop: 'threshold', value: item.total_threshold }));
        this.props.dispatch(promoCodeAdminFormUpdate({ prop: 'threshold_limit', value: item.threshold_limit }));
        if(item.total_threshold !== "NO_THRESHOLD"){
            this.props.dispatch(promoCodeAdminFormUpdate({ prop: 'threshold_disable', value: false }));
        }

        this.props.dispatch(promoCodeAdminFormUpdate({ prop: 'valid_days', value: item.valid_days }));

        this.props.dispatch(promoCodeAdminFormUpdate({ prop: 'total', value: item.total }));
        this.props.dispatch(promoCodeAdminFormUpdate({ prop: 'max_uses', value: item.max_uses }));
        let selectedLocations = (item.locations) ? item.locations.map((location) => {
            return location.id;
        }) : [];
        this.props.dispatch(promoCodeAdminFormUpdate({ prop: 'selectedLocations', value: selectedLocations }));

        let locCount = 0;
        this.props.locations.forEach((location) => {
            if(!(location && location.dispatch_location && location.dispatch_location.dispatch_center && location.dispatch_location.dispatch_center.id)){
                return false;
            }
            locCount++;
        });

        if(selectedLocations.length >= locCount){
            this.props.dispatch(promoCodeAdminFormUpdate({ prop: 'selectAllLocations', value: true }));
        }

        this.props.dispatch(setPromoCodeAdminEditing());
        this.props.dispatch(openPromoCodeCreate());
    }

    handleUpdateMassPromoCodeView(item) {
        this.props.dispatch(promoCodesFormReset());
        this.props.dispatch(promoCodeAdminFormReset());
        this.props.dispatch(massPromoCodeAdminFormReset());

        this.props.dispatch(setSelectedMassAdminPromoCode(item));

        this.props.dispatch(massPromoCodeAdminFormUpdate({ prop: 'code', value: item.code }));
        if(item.start_time){
            this.props.dispatch(promoCodeAdminFormUpdate({ prop: 'start_time', value: new Date(item.start_time) }));
        }
        if(item.end_time){
            this.props.dispatch(promoCodeAdminFormUpdate({ prop: 'end_time', value: new Date(item.end_time) }));
        }
        this.props.dispatch(massPromoCodeAdminFormUpdate({ prop: 'total_target', value: item.total_target }));
        this.props.dispatch(massPromoCodeAdminFormUpdate({ prop: 'total_type', value: item.total_type }));
        this.props.dispatch(massPromoCodeAdminFormUpdate({ prop: 'total_avail', value: item.total_avail }));
        this.props.dispatch(massPromoCodeAdminFormUpdate({ prop: 'total', value: item.total }));
        this.props.dispatch(massPromoCodeAdminFormUpdate({ prop: 'max_uses', value: item.max_uses }));

        this.props.dispatch(massPromoCodeAdminFormUpdate({ prop: 'selectedLocations', value: item.locations.map((location) => {return location.id;}) }));

        this.props.dispatch(setEditingMassAdminPromoCode());
        this.props.dispatch(openMassPromoCodeCreate());
        //resetEditingMassAdminPromoCode
    }

    handleRemovePromoCode(index) {
        if (confirm("Remove this promo code?")) {
            this.props.dispatch(removeAdminPromoCode(index));
        }
    }

    handleReaddPromoCode(index) {
        if (confirm("Re-add this promo code?")) {
            this.props.dispatch(readdAdminPromoCode(index));
        }
    }

    renderPromoCodes() {

        if (!this.props.promoCodes || this.props.promoCodes.length <= 0){
            return (<Typography className="message-block wide-indent">No promo codes available.</Typography>);
        }

        if (this.props.promoCodes.length > 0){

            return (
                <Grid container>
                    <Grid item xs={12} className="promo-sortable-list">
                        {this.props.promoCodes.map((item, index) => (
                            <div key={"settingssize-"+index}>
                                <Grid key={"item-"+index} className="sortable-menu-item" container>
                                    <Grid item xs={5} className="list-name">
                                        <Typography>{item.code} - {this.formatPromoCodeDetails(item)}</Typography>
                                    </Grid>
                                    <Grid item xs={4} className="list-name">
                                        {this.getPromoUsageDetails(item)}
                                    </Grid>
                                    <Grid item xs={1} className="list-name">
                                        {this.getTargetIcon(item)}
                                    </Grid>
                                    <Grid item xs={1} className="list-sort">
                                        <Button fullWidth={true} color="primary" onClick={() => this.handleUpdatePromoCodeView(item)}>
                                            <EditIcon />
                                        </Button>
                                    </Grid>
                                    <Grid item xs={1} className="list-sort">
                                        <Button fullWidth={true} color="secondary" onClick={() => this.handleRemovePromoCode(item.id)}>
                                            <DeleteIcon />
                                        </Button>
                                    </Grid>
                                </Grid>
                            </div>
                        ))}
                    </Grid>
                </Grid>
            )
        }
    }

    renderArchivedPromoCodes() {

        if (!this.props.archive_promos || this.props.archive_promos.length <= 0){
            return (<Typography className="message-block wide-indent">No archived promo codes available.</Typography>);
        }

        if (this.props.archive_promos.length > 0){
            return (
                <Grid container>
                    <Grid item xs={12} className="promo-sortable-list">
                        {this.props.archive_promos.map((item, index) => (
                            <div key={"settingssize-"+index}>
                                <Grid key={"item-a-"+index} className="sortable-menu-item" container>
                                    <Grid item xs={5} className="list-name">
                                        <Typography>{item.code} - {this.formatPromoCodeDetails(item)}</Typography>
                                    </Grid>
                                    <Grid item xs={5} className="list-name">
                                        {this.getPromoUsageDetails(item)}
                                    </Grid>
                                    <Grid item xs={1} className="list-name">
                                        {this.getTargetIcon(item)}
                                    </Grid>
                                    {/* <Grid item xs={1} className="list-sort">
                                        <Button fullWidth={true} color="primary" onClick={() => this.handleUpdatePromoCodeView(item)}>
                                            <EditIcon />
                                        </Button>
                                    </Grid> */}
                                    <Grid item xs={1} className="list-sort">
                                        <Button fullWidth={true} color="primary" onClick={() => this.handleReaddPromoCode(item.id)}>
                                            <AddIcon />
                                        </Button>
                                    </Grid>
                                </Grid>
                            </div>
                        ))}
                    </Grid>
                </Grid>
            )
        }
    }

    renderMassPromoCodes() {

        if (!this.props.massPromoCodes || this.props.massPromoCodes.length <= 0){
            return (<Typography className="message-block wide-indent">No mass promo codes available.</Typography>);
        }

        if (this.props.massPromoCodes.length > 0){
            return (
                <Grid container>
                    <Grid item xs={12} className="promo-sortable-list">
                        {this.props.massPromoCodes.map((item, index) => (
                            <div key={"settingssize-"+index}>
                                <Grid key={"item-"+index} className="sortable-menu-item" container>
                                    <Grid item xs={5} className="list-name">
                                        <Typography>{item.label} - {this.formatPromoCodeDetails(item)}</Typography>
                                    </Grid>
                                    <Grid item xs={4} className="list-name">
                                        {this.getPromoUsageDetails(item)}
                                    </Grid>
                                    {/* <Grid item xs={2} className="list-name">
                                        <Typography>{this.getMassUsageDetails(item)}</Typography>
                                    </Grid> */}
                                    <Grid item xs={1} className="list-name">
                                        {this.getTargetIcon(item)}
                                    </Grid>
                                    {/* <Grid item xs={1} className="list-sort">
                                        <Button fullWidth={true} color="primary" onClick={() => this.handleUpdateMassPromoCodeView(item)}>
                                            <EditIcon />
                                        </Button>
                                    </Grid> */}
                                    <Grid item xs={1} className="list-sort">
                                        <IconButton
                                            className="fa fa-file-csv" color={'primary'}
                                            onClick={() => { this.props.dispatch(downloadMassPromoCodes(item.id, item.label) )}}
                                        />
                                    </Grid>
                                    <Grid item xs={1} className="list-sort">
                                        <Button fullWidth={true} color="secondary" onClick={() => this.handleRemovePromoCode(item.id)}>
                                            <DeleteIcon />
                                        </Button>
                                    </Grid>
                                </Grid>
                            </div>
                        ))}
                    </Grid>
                </Grid>
            )
        }
    }

    renderArchivedMassPromoCodes() {

        if (!this.props.mass_archive_promos || this.props.mass_archive_promos.length <= 0){
            return (<Typography className="message-block wide-indent">No mass archived promo codes available.</Typography>);
        }

        if (this.props.mass_archive_promos.length > 0){
            return (
                <Grid container>
                    <Grid item xs={12} className="promo-sortable-list">
                        {this.props.mass_archive_promos.map((item, index) => (
                            <div key={"settingssize-"+index}>
                                <Grid key={"item-a-"+index} className="sortable-menu-item" container>
                                    <Grid item xs={5} className="list-name">
                                        <Typography>{item.label} - {this.formatPromoCodeDetails(item)}</Typography>
                                    </Grid>
                                    <Grid item xs={5} className="list-name">
                                        {this.getPromoUsageDetails(item)}
                                    </Grid>
                                    {/* <Grid item xs={3} className="list-name">
                                        <Typography>{this.getMassUsageDetails(item)}</Typography>
                                    </Grid> */}
                                    <Grid item xs={1} className="list-name">
                                        {this.getTargetIcon(item)}
                                    </Grid>
                                    {/* <Grid item xs={1} className="list-sort">
                                        <Button fullWidth={true} color="primary" onClick={() => this.handleUpdateMassPromoCodeView(item)}>
                                            <EditIcon />
                                        </Button>
                                    </Grid> */}
                                    <Grid item xs={1} className="list-sort">
                                        <Button fullWidth={true} color="primary" onClick={() => this.handleReaddPromoCode(item.id)}>
                                            <AddIcon />
                                        </Button>
                                    </Grid>
                                </Grid>
                            </div>
                        ))}
                    </Grid>
                </Grid>
            )
        }
    }

    render() {
        return (
            <LoadScreen
                checkPermissions={this.checkPermissions}
                customLoading={this.props.loading}
            >
                <div className="card-container">
                    <Grid container>
                        <Grid item xs={12} className="heading-grid">
                            <h1>Active Promo Codes</h1>
                            <FormControl fullWidth>
                                <TextField 
                                    label="Filter Promo Codes" 
                                    variant="outlined" 
                                    value={this.props.filter}
                                    onChange={(e) => this.props.dispatch(setPromosFilter(e.target.value))} 
                                />
                            </FormControl>
                        </Grid>
                        {this.renderPromoCodes()}
                    </Grid>
                </div>

                <div className="card-container" style={{paddingBottom: 30}}>
                    <Grid container>
                        <Grid item xs={12} className="heading-grid">
                            <h1>View Expired/Archived Promo Codes</h1>
                            {/* <FormControl fullWidth>
                                <TextField 
                                    label="Filter Promo Codes" 
                                    variant="outlined" 
                                    value={this.props.filter}
                                    onChange={(e) => this.props.dispatch(setPromoCodeFilter(e.target.value))} 
                                />
                            </FormControl> */}
                        </Grid>
                        {this.renderArchivedPromoCodes()}
                    </Grid>
                </div>

                <div className="card-container" style={{paddingBottom: 30}}>
                    <Grid container>
                        <Grid item xs={12} className="heading-grid">
                            <h1>View Mass Codes</h1>
                            {/* <FormControl fullWidth>
                                <TextField 
                                    label="Filter Promo Codes" 
                                    variant="outlined" 
                                    value={this.props.filter}
                                    onChange={(e) => this.props.dispatch(setPromoCodeFilter(e.target.value))} 
                                />
                            </FormControl> */}
                        </Grid>
                        {this.renderMassPromoCodes()}
                    </Grid>
                </div>

                <div className="card-container" style={{paddingBottom: 30}}>
                    <Grid container>
                        <Grid item xs={12} className="heading-grid">
                            <h1>View Expired/Archived Mass Codes</h1>
                        </Grid>
                        {this.renderArchivedMassPromoCodes()}
                    </Grid>
                </div>

                <AdminPromoCodeModal
                    locations={this.props.locations}
                    unfilteredLocations={this.props.unfilteredLocations}
                    dispatchCenters={this.props.dispatchCenters}
                    promoCodes={this.props.promoCodes}
                />

                <MassPromoCodeModal
                />

                <div className="window-fab">
                    <Fab className="btn-fab btn-add-orange" onClick={() => this.toggleMassCreateModal(true)}>
                        <AddIcon />
                        <AddIcon />
                    </Fab>
                    <Fab className="btn-fab btn-add" onClick={() => this.toggleCreateModal(true)}>
                        <AddIcon />
                    </Fab>
                </div>

            </LoadScreen>
        );
    }
};

const mapStateToProps = (state, props) => {
    return {
        loading: state.promoCodeAdminForm.loading,
        user: state.loggedUser.user,
        promoCodesForm: state.promoCodes,
        filter: state.promoCodes.promos_filter,
        promoCodes: filterPromoCodes(state.promoCodes.promos.filter((code) => {
            return code.global == 0;
        }), {text: state.promoCodes.promos_filter, index: 'code'}),
        massPromoCodes: state.promoCodes.promos.filter((code) => {
            return code.global == 1;
        }),
        archive_promos: state.promoCodes.archive_promos.filter((code) => {
            return code.global == 0;
        }),
        mass_archive_promos: state.promoCodes.archive_promos.filter((code) => {
            return code.global == 1;
        }),
        locations: getFilteredLocations(state.promoCodes.locations, state.promoCodeAdminForm.location_filter, state.promoCodeAdminForm.selectedLocations),
        unfilteredLocations: state.promoCodes.locations,
        dispatchCenters: state.promoCodes.dispatchCenters,
    };
};

export default connect(mapStateToProps)(PromoCodesPage);