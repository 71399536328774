// the default items that we can possibly filter by
const ordersDefaultState = {
    loading: true,
    incoming: [],
    confirmed: [],
    ready: [],
    complete: [],
    all: [],
    dispatchWarning: [],
    restaurantWarning: [],
    search: [],
    orderInformation: [],
    searchOrderInformation: [],
    maxOrderId: 0,
    maxWarningOrderId: 0,
    playChime: false,
    filter: '',
    ordersLastUpdatedVisible: false,
    ordersLastUpdated: ''
};

export default (state = ordersDefaultState, action) => {

    switch (action.type) {

        case 'SET_ORDERS_LAST_UPDATED_VISIBLE':
            return {
                ...state,
                ordersLastUpdatedVisible: action.visible
            };

        case 'SET_ORDERS_FEED':
            let ordersDate = new Date();
            let ordersCurrentDateTime = ordersDate.toDateString() + ' ' + ordersDate.toLocaleTimeString();
            return {
                ...state,
                loading: false,
                filter: '',
                incoming: action.incoming,
                confirmed: action.confirmed,
                ready: action.ready,
                complete: action.complete,
                orderInformation: action.orderInformation,
                maxOrderId: action.maxOrderId,
                ordersLastUpdatedVisible: true,
                ordersLastUpdated: ordersCurrentDateTime
            };

        case 'SET_SUPPORT_ORDERS_FEED':
            let supportOrdersDate = new Date();
            let supportOrdersCurrentDateTime = supportOrdersDate.toDateString() + ' ' + supportOrdersDate.toLocaleTimeString();
            return {
                ...state,
                loading: false,
                filter: '',
                all: action.all,
                dispatchWarning: action.dispatchWarning,
                restaurantWarning: action.restaurantWarning,
                orderInformation: action.orderInformation,
                maxOrderId: action.maxOrderId,
                maxWarningOrderId: action.maxWarningOrderId,
                ordersLastUpdatedVisible: true,
                ordersLastUpdated: supportOrdersCurrentDateTime
            };

        case 'SET_SEARCH_ORDERS_FEED':
            return {
                ...state,
                loading: false,
                filter: '',
                search: action.orders,
                searchOrderInformation: action.orderInformation
            };

        case 'SET_ORDER_FILTER':
            return {
                ...state,
                filter: action.text
            };

        case 'SET_ORDERS_LOADING':
            return {
                ...state,
                loading: true
            };

        case 'REMOVE_ORDERS_LOADING':
            return {
                ...state,
                loading: false
            };

        case 'PLAY_CHIME':
            return {
                ...state,
                playChime: true
            };
        
        case 'STOP_CHIME':
            return {
                ...state,
                playChime: false
            };

        case 'SET_MAX_ORDER_ID':
            return {
                ...state,
                maxOrderId: action.maxOrderId
            };
    
        case 'RESET_ORDERS':
            return ordersDefaultState;

        default:
            return state;
    }
};