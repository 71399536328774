import React from 'react';
import { connect } from 'react-redux';
import arrayMove from 'array-move';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { foodItemFormUpdate, foodItemFormUpdatePrice, foodItemFormAddPrice, foodItemFormRemovePrice } from '../../../../actions/menu/fooditems/foodItemForm';

import SortableItemList from '../../common/SortableItemList';

class Prices extends React.Component {

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.addPrice = this.addPrice.bind(this);
        this.deletePrice = this.deletePrice.bind(this);
        this.handleUpdateItem = this.handleUpdateItem.bind(this);
        this.onSortEnd = this.onSortEnd.bind(this);
        this.getNonTaxableCheckbox = this.getNonTaxableCheckbox.bind(this);

        this.state = {
            priceDisplayView: (props.foodItemForm.hasMultiplePrices) ? 1 : 0
        };
    }

    componentDidMount() {
        if (this.props.foodItemForm.hasMultiplePrices) {
            this.setState(() => ({priceDisplayView: 1}));
        }
    }

    onSortEnd({oldIndex, newIndex})  {

        let newPriceOrder = arrayMove(this.props.foodItemForm.prices, oldIndex, newIndex);
        for (let i = 0; i < newPriceOrder.length; i++) {
            newPriceOrder[i]['price_order'] = i + 1;
        }

        this.props.dispatch(foodItemFormUpdate({ prop: 'prices', value: newPriceOrder }));
    }

    addPrice() {
        this.props.dispatch(foodItemFormAddPrice({
            name: '',
            price: ''
        }));
    }

    deletePrice(index) {
        this.props.dispatch(foodItemFormRemovePrice(index));
    }

    handleUpdateItem(prop, index, value) {
        this.props.dispatch(foodItemFormUpdatePrice({ prop, index, value }));
    }

    handleChange(event, newValue) {
        const hasMultiplePrices = (newValue) ? true : false;

        this.props.dispatch(foodItemFormUpdate({ prop: 'hasMultiplePrices', value: hasMultiplePrices }));
        this.setState(() => ({priceDisplayView: newValue}))
    }

    getNonTaxableCheckbox(){
        return (
            <Grid container 
                            spacing={0}
                            direction="column"
                            alignItems="center"
                            justifyContent="center"><FormControlLabel
                control={
                    <Checkbox 
                        checked={!!this.props.foodItemForm.non_taxable}
                        onChange={(e) => this.props.dispatch(foodItemFormUpdate({ prop: 'non_taxable', value: +!this.props.foodItemForm.non_taxable }))}
                        name={"non_taxable"}
                        color="primary"
                    />
                }
                label="Non-taxable"
            /></Grid>
        );
    }

    renderPriceEditSection() {
        if (this.state.priceDisplayView == 0) {
            
            // it's a single price; show the single price menu
            return (
                <Grid container>
                    {this.getNonTaxableCheckbox()}
                    <Grid item xs={3}></Grid>
                    <Grid item xs={6} className="form-grid">
                        <TextField
                            label="Price"
                            className="modal-input"
                            fullWidth={true}
                            value={this.props.foodItemForm.singlePrice}
                            onChange={(e) => { this.props.dispatch(foodItemFormUpdate({ prop: 'singlePrice', value: e.target.value })); }}
                            margin="normal"
                            variant="outlined"
                        />
                    </Grid>
                </Grid>
            );
        }
        else if (this.state.priceDisplayView == 1) {

            // show the dashboard for multiple prices
            return (
                <div>
                {this.getNonTaxableCheckbox()}
                    {this.props.foodItemForm.prices.length <= 0 && (
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography className="no-results">No prices entered.</Typography>
                            </Grid>
                        </Grid>
                    )}
                    {this.props.foodItemForm.prices.length > 0 && (
                        <SortableItemList
                            items={this.props.foodItemForm.prices}
                            handleUpdateItem={this.handleUpdateItem}
                            handleDeleteItem={this.deletePrice}
                            onSortEnd={this.onSortEnd}
                        />
                    )}

                    <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom" onClick={this.addPrice}>
                        <AddIcon />
                    </Button>
                </div>
            );
        }
    }
    
    render() {
        return (
            <div>
                <Tabs
                    value={this.state.priceDisplayView}
                    onChange={this.handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    centered
                >
                    <Tab label="One Size" />
                    <Tab label="Multiple Sizes" />
                </Tabs>

                {this.renderPriceEditSection()}
            </div>
        );
    }
};

const mapStateToProps = (state, props) => {
    return {
        foodItemForm: state.foodItemForm
    };
};

export default connect(mapStateToProps)(Prices);