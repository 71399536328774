import axios from 'axios';
import { API_URL } from '../../../config/constants';
import { setPizzas, setPizzaSettings, setPizzasLoading, setPizzasShowConfig } from './pizzas';
import { setSelectedRestaurantAvailabilitySchedules } from '../../selectedRestaurant';

export const loadPizzaSettings = (settings) => {

    return (dispatch) => {

        // load the sizes
        if (settings.sizes && settings.sizes.length > 0) {
            dispatch(settingsFormUpdate({prop: 'sizes', value: settings.sizes}));
        }

        // load the toppings
        dispatch(settingsFormUpdate({prop: 'toppings', value: settings.toppings}));

        // load the crusts
        dispatch(settingsFormUpdate({prop: 'crusts', value: settings.crusts}));

        // load the sauces
        dispatch(settingsFormUpdate({prop: 'sauces', value: settings.sauces}));

        // load the custom pizzas
        let customPizzas = settings.customPizzas.map((customPizza) => {
            
            if(customPizza.hours_available == null){

                return {
                    ...customPizza,
                    hours_available: {
                        sun_start: '00:00:00',
                        sun_end: '00:00:00',
                        mon_start: '00:00:00',
                        mon_end: '00:00:00',
                        tue_start: '00:00:00',
                        tue_end: '00:00:00',
                        wed_start: '00:00:00',
                        wed_end: '00:00:00',
                        thu_start: '00:00:00',
                        thu_end: '00:00:00',
                        fri_start: '00:00:00',
                        fri_end: '00:00:00',
                        sat_start: '00:00:00',
                        sat_end: '00:00:00',
                        sun_available: false,
                        mon_available: false,
                        tue_available: false,
                        wed_available: false,
                        thu_available: false,
                        fri_available: false,
                        sat_available: false,
                        sun_all_day: false,
                        mon_all_day: false,
                        tue_all_day: false,
                        wed_all_day: false,
                        thu_all_day: false,
                        fri_all_day: false,
                        sat_all_day: false
                    },
                    scrollPosition: 0,
                    saveSchedule: false,
                    scheduleName: ''                    
                }
                
            }
            return {
                ...customPizza,
                scrollPosition: 0,
                saveSchedule: false,
                scheduleName: ''                
            }
         })
         dispatch(settingsFormUpdate({prop: 'customPizzas', value: customPizzas}));

         // load the custom pizza crusts
         dispatch(settingsFormUpdate({prop: 'customPizzaCrusts', value: settings.customPizzaCrusts}));

         // load the custom pizza sauces
         dispatch(settingsFormUpdate({prop: 'customPizzaSauces', value: settings.customPizzaSauces}));
    }
};

export const savePizzaSettings = (restaurant_id, scope, settings, deletedSettings, handleUpdate = false, scrollPosition = 0) => {

    return (dispatch) => {

        dispatch(setPizzasLoading());

        return axios.post(API_URL+'/api/restaurants/'+restaurant_id+'/pizzas/settings', { 
            scope,
            [scope]: settings,
            ['deleted'+scope.substring(0, 1).toUpperCase() + scope.substring(1, scope.length)]: deletedSettings
        })
        .then(function(res) {
            if (res.data.status == 'success') {

                // load the pizza settings
                dispatch(loadPizzaSettings(res.data.data.config));
                dispatch(setPizzaSettings(res.data.data.config));
                dispatch(setPizzas(res.data.data.pizzas));
                dispatch(setSelectedRestaurantAvailabilitySchedules(res.data.data.availability_schedules));

                if (handleUpdate) {
                    let scopeString = scope == "customPizzas" ? "customPizza" : scope;
                    dispatch(settingsFormSuccess(scopeString.toLowerCase().replace(/\b((custom(?=\w))?)/g, function(customString) {
                        return customString + " ";//If we find custom followed by a word character then assume it's customPizzas or something and replace it with itself plus a space
                    }).replace(/\b((pizza(?=\w))?)/g, function(customString) {
                        return customString + " ";
                    }).replace(/\b[a-z]/g, function(letter) {
                        return letter.toUpperCase();
                    })+' settings updated!'));
                }
                else {
                    let activeTab = 1;
                    if (scope == 'toppings') {
                        activeTab = 2;
                    }
                    else if (scope == 'crusts') {
                        activeTab = 3;
                    }
                    else if (scope == 'sauces') {
                        dispatch(setPizzasShowConfig(false));
                    }
                    dispatch(settingsFormUpdate({prop: 'activeTab', value: activeTab}));
                }

                window.scrollTo(0, scrollPosition);

            }
            else {
                dispatch(settingsFormError(res.data.message));
            }
        })
        .catch(function(err) {
            
            // token is likely invalid; remove it and prompt the user to login again
            console.log(err);
            dispatch(settingsFormError("We're sorry, but our system has encountered an issue, to which we have been notified."));
            if (err.response && err.response.status === 401) {
                removeAuthToken(dispatch);
            }
            
        });
    };
};

export const settingsFormUpdate = ({ prop, value }) => {

    return {
        type: 'PIZZA_SETTINGS_FORM_UPDATE',
        setting: { prop, value }
    };
};

export const settingsFormUpdateSize = ({ prop, index, value }) => {

    return {
        type: 'PIZZA_SETTINGS_FORM_UPDATE_SIZE',
        size: { prop, index, value }
    };
};

export const settingsFormAddSize = (size) => {

    return {
        type: 'PIZZA_SETTINGS_FORM_ADD_SIZE',
        size
    }
};

export const settingsFormRemoveSize = (index) => {

    return {
        type: 'PIZZA_SETTINGS_FORM_REMOVE_SIZE',
        index
    }
}

export const settingsFormUpdateTopping = (topping, index) => {

    return {
        type: 'PIZZA_SETTINGS_FORM_UPDATE_TOPPING',
        topping,
        index
    }
};

export const settingsFormAddTopping = (topping) => {

    return {
        type: 'PIZZA_SETTINGS_FORM_ADD_TOPPING',
        topping
    }
};

export const settingsFormRemoveTopping = (index) => {

    return {
        type: 'PIZZA_SETTINGS_FORM_REMOVE_TOPPING',
        index
    }
}

export const settingsFormUpdateCrust = (crust, index) => {

    return {
        type: 'PIZZA_SETTINGS_FORM_UPDATE_CRUST',
        crust,
        index
    }
};

export const settingsFormAddCrust = (crust) => {

    return {
        type: 'PIZZA_SETTINGS_FORM_ADD_CRUST',
        crust
    }
};

export const settingsFormRemoveCrust = (index) => {

    return {
        type: 'PIZZA_SETTINGS_FORM_REMOVE_CRUST',
        index
    }
}

export const settingsFormUpdateSauce = (sauce, index) => {

    return {
        type: 'PIZZA_SETTINGS_FORM_UPDATE_SAUCE',
        sauce,
        index
    }
};

export const settingsFormAddSauce = (sauce) => {

    return {
        type: 'PIZZA_SETTINGS_FORM_ADD_SAUCE',
        sauce
    }
};

export const settingsFormRemoveSauce = (index) => {

    return {
        type: 'PIZZA_SETTINGS_FORM_REMOVE_SAUCE',
        index
    }
}

export const settingsFormUpdateCustomPizza = (customPizza, index) => {

    return {
        type: 'PIZZA_SETTINGS_FORM_UPDATE_CUSTOM_PIZZA',
        customPizza: {
            ...customPizza,
            error: ''
        },
        index
    }
};

export const settingsFormAddCustomPizza = (customPizza) => {

    return {
        type: 'PIZZA_SETTINGS_FORM_ADD_CUSTOM_PIZZA',
        customPizza
    }
};

export const settingsFormRemoveCustomPizza = (index) => {

    return {
        type: 'PIZZA_SETTINGS_FORM_REMOVE_CUSTOM_PIZZA',
        index
    }
}

export const settingsFormAddCustomCrust = (crust) => {

    return {
        type: 'PIZZA_SETTINGS_FORM_ADD_CUSTOM_CRUST',
        crust
    }
};

export const settingsFormRemoveCustomCrust = (crust) => {

    return {
        type: 'PIZZA_SETTINGS_FORM_REMOVE_CUSTOM_CRUST',
        crust
    }
}

export const settingsFormAddCustomSauce = (sauce) => {

    return {
        type: 'PIZZA_SETTINGS_FORM_ADD_CUSTOM_SAUCE',
        sauce
    }
};

export const settingsFormRemoveCustomSauce = (sauce) => {

    return {
        type: 'PIZZA_SETTINGS_FORM_REMOVE_CUSTOM_SAUCE',
        sauce
    }
}

export const settingsFormError = (message) => {

    return {
        type: 'PIZZA_SETTINGS_FORM_ERROR',
        message
    };
};

export const settingsFormSuccess = (message) => {

    return {
        type: 'PIZZA_SETTINGS_FORM_SUCCESS',
        message
    };
};

export const settingsFormReset = () => {
    
    return {
        type: 'PIZZA_SETTINGS_FORM_RESET',
    };
}