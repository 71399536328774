const pizzaFormDefaultState = {
    name: '',
    description: '',
    non_taxable: 0,
    prices: [],
    toppings: [],
    deletedToppings: [],
    crusts: [],
    deletedCrusts: [],
    sauces: [],
    deletedSauces: [],
    message: '',
    error: '',
    activeTab: 0,
    alwaysAvailable: true,
    hours_available: {
        sun_start: '00:00:00',
        sun_end: '00:00:00',
        mon_start: '00:00:00',
        mon_end: '00:00:00',
        tue_start: '00:00:00',
        tue_end: '00:00:00',
        wed_start: '00:00:00',
        wed_end: '00:00:00',
        thu_start: '00:00:00',
        thu_end: '00:00:00',
        fri_start: '00:00:00',
        fri_end: '00:00:00',
        sat_start: '00:00:00',
        sat_end: '00:00:00',
        sun_available: false,
        mon_available: false,
        tue_available: false,
        wed_available: false,
        thu_available: false,
        fri_available: false,
        sat_available: false,
        sun_all_day: false,
        mon_all_day: false,
        tue_all_day: false,
        wed_all_day: false,
        thu_all_day: false,
        fri_all_day: false,
        sat_all_day: false
    },
    scrollPosition: 0,
    saveSchedule: false,
    scheduleName: ''
};

export default (state = pizzaFormDefaultState, action) => {

    switch (action.type) {

        case 'PIZZA_FORM_UPDATE':
            return {
                ...state,
                [action.pizza.prop]: action.pizza.value
            };

        case 'PIZZA_FORM_UPDATE_HOURS_AVAILABLE':
            return {
                ...state,
                hours_available: 
                    {
                        ...state.hours_available,
                        [action.location.prop]: action.location.value
                    }
            };

        case 'PIZZA_FORM_UPDATE_PRICE':
            return {
                ...state,
                prices: state.prices.map((item, index) => {
                    if (index == action.item.index) {
                        return {
                            ...item,
                            [action.item.prop]: action.item.value
                        }
                    }

                    return item;
                })
            };

        case 'PIZZA_FORM_RESET_PRICES':
            return {
                ...state,
                prices: [],
            }

        case 'PIZZA_FORM_ADD_TOPPING':
            return {
                ...state,
                toppings: state.toppings.concat({
                    topping_id: action.topping
                })
            };

        case 'PIZZA_FORM_REMOVE_TOPPING':
            let deletedToppings = state.deletedToppings;
            for (let i = 0; i < state.toppings.length; i++) {
                if (state.toppings[i].id && state.toppings[i].topping_id == action.topping) {
                    deletedToppings = state.deletedToppings.concat(state.toppings[i].id);
                }
            }
            return {
                ...state,
                deletedToppings,
                toppings: state.toppings.filter((topping, index) => {
                    return topping.topping_id != action.topping;
                })
            };

        case 'PIZZA_FORM_ADD_CRUST':
            return {
                ...state,
                crusts: state.crusts.concat({
                    crust_id: action.crust
                })
            };
    
        case 'PIZZA_FORM_REMOVE_CRUST':
            let deletedCrusts = state.deletedCrusts;
            for (let i = 0; i < state.crusts.length; i++) {
                if (state.crusts[i].id && state.crusts[i].crust_id == action.crust) {
                    deletedCrusts = state.deletedCrusts.concat(state.crusts[i].id);
                }
            }
            return {
                ...state,
                deletedCrusts,
                crusts: state.crusts.filter((crust, index) => {
                    return crust.crust_id != action.crust;
                })
            };

        case 'PIZZA_FORM_ADD_SAUCE':
            return {
                ...state,
                sauces: state.sauces.concat({
                    sauce_id: action.sauce
                })
            };
    
        case 'PIZZA_FORM_REMOVE_SAUCE':
            let deletedSauces = state.deletedSauces;
            for (let i = 0; i < state.sauces.length; i++) {
                if (state.sauces[i].id && state.sauces[i].sauce_id == action.sauce) {
                    deletedSauces = state.deletedSauces.concat(state.sauces[i].id);
                }
            }
            return {
                ...state,
                deletedSauces,
                sauces: state.sauces.filter((sauce, index) => {
                    return sauce.sauce_id != action.sauce;
                })
            };
        
        case 'PIZZA_FORM_RESET_TOPPINGS':
            return {
                ...state,
                toppings: [],
            }

        case 'PIZZA_FORM_RESET_CRUSTS':
            return {
                ...state,
                crusts: [],
            }


        case 'PIZZA_FORM_ERROR':
            return {
                ...state,
                message: '',
                error: action.message
            }

        case 'PIZZA_FORM_SUCCESS':
            return {
                ...state,
                error: '',
                message: action.message
            }

        case 'PIZZA_FORM_RESET':
            return pizzaFormDefaultState;

        default:
            return state;
    }
};