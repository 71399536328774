import axios from 'axios';
import { API_URL } from '../../../config/constants';
import { pizzaFormReset, pizzaFormError, pizzaFormSuccess, pizzaFormUpdate } from './pizzaForm';
import { setSelectedRestaurantAvailabilitySchedules } from '../../selectedRestaurant';
import { closePizzaCreate } from '../../modals';

export const setPizzaSettings = (settings) => {
    return {
        type: 'SET_PIZZA_SETTINGS',
        settings
    }
}

export const setPizzasShowConfig = (showConfig) => {
    return {
        type: 'SET_PIZZAS_SHOW_CONFIG',
        showConfig
    }
}

export const setPizzas = (pizzas) => {
    return {
        type: 'SET_PIZZAS',
        pizzas
    }
}

export const setPizzaFilter = (text) => {
    return {
        type: 'SET_PIZZA_FILTER',
        text
    }
}

export const resetPizzas = () => {
    return {
        type: 'RESET_PIZZAS'
    }
}

export const setPizzasLoading = () => ({
    type: 'SET_PIZZAS_LOADING'
});

export const loadPizzas = (restaurant_id) => {
    return (dispatch) => {
        return axios.get(API_URL+'/api/restaurants/'+restaurant_id+'/pizzas')
            .then(function(res) {

                // update if we need to show the loading screen
                let showConfig = false;
                if (!res.data.data.config.sizes || res.data.data.config.sizes.length <= 0 || !res.data.data.config.toppings || res.data.data.config.toppings.length <= 0) {
                    showConfig = true;
                }

                dispatch(setPizzasShowConfig(showConfig));
                dispatch(setPizzas(res.data.data.pizzas));
                dispatch(setPizzaSettings(res.data.data.config));

            })
            .catch(function(err) {
                console.log("Err!");
                console.log(err);
            });
    };
}

export const addPizza = (restaurantId, pizza) => {

    return (dispatch) => {

        const formData = new FormData();

        // add the image
        formData.append('files', pizza.newImage);

        dispatch(setPizzasLoading());

        // add the data
        formData.append('data', JSON.stringify(pizza));

        return axios.post(
                API_URL+'/api/restaurants/'+restaurantId+'/pizzas', 
                formData,
                {
                    headers: { 'Content-Type': 'multipart/form-data' }
                }
            )
            .then(function(res) {
                if (res.data.status == 'success') {
                    dispatch(setPizzas(res.data.data.pizzas));
                    dispatch(setPizzaSettings(res.data.data.config));
                    dispatch(setSelectedRestaurantAvailabilitySchedules(res.data.data.availability_schedules));
                    dispatch(pizzaFormReset());
                    dispatch(closePizzaCreate());
                }
                else {
                    dispatch(pizzaFormError(res.data.message));
                }
            })
            .catch(function(err) {
                
                // token is likely invalid; remove it and prompt the user to login again
                dispatch(pizzaFormError("We're sorry, but our system has encountered an issue, to which we have been notified."));
                if (err.response.status === 401) {
                    removeAuthToken(dispatch);
                }
                
            });
    };
};

export const updatePizza = (restaurantId, pizzaId, scope, pizza) => {
    
    return (dispatch) => {

        const formData = new FormData();

        // add the image
        formData.append('files', pizza.newImage);

        // add the data
        formData.append('scope', scope);
        formData.append('data', JSON.stringify(pizza));

        dispatch(setPizzasLoading());

        return axios.post(
            API_URL+'/api/restaurants/'+restaurantId+'/pizzas/'+pizzaId, 
            formData,
            {
                headers: { 'Content-Type': 'multipart/form-data' }
            }
        ).then(function(res) {
            if (res.data.status == 'success') {
                dispatch(setPizzas(res.data.data.pizzas));
                dispatch(pizzaFormReset());
                dispatch(pizzaFormUpdate({ prop: 'name', value: res.data.data.pizza.name }));
                dispatch(pizzaFormUpdate({ prop: 'description', value: res.data.data.pizza.description }));
                dispatch(pizzaFormUpdate({ prop: 'thumb_image', value: res.data.data.pizza.thumb_image }));
                dispatch(pizzaFormUpdate({ prop: 'non_taxable', value: res.data.data.pizza.non_taxable }));
                dispatch(pizzaFormUpdate({ prop: 'prices', value: res.data.data.pizza.prices.sort(function(a, b) {
                    if ( a.price_order < b.price_order ){
                        return -1;
                      }
                      if ( a.price_order > b.price_order ){
                        return 1;
                      }
                      return 0;
                }) }));
                dispatch(pizzaFormUpdate({ prop: 'toppings', value: res.data.data.pizza.toppings }));
                dispatch(pizzaFormUpdate({ prop: 'crusts', value: res.data.data.pizza.available_crusts }));
                dispatch(pizzaFormUpdate({ prop: 'sauces', value: res.data.data.pizza.available_sauces }));
                if(res.data.data.pizza.hours_available != null){
                    dispatch(pizzaFormUpdate({ prop: 'hours_available', value: res.data.data.pizza.hours_available }));
                }
                dispatch(pizzaFormUpdate({ prop: 'alwaysAvailable', value: res.data.data.pizza.alwaysAvailable }));
                dispatch(pizzaFormUpdate({ prop: 'saveSchedule', value: false }));
                dispatch(pizzaFormUpdate({ prop: 'scheduleName', value: '' }));
                dispatch(setSelectedRestaurantAvailabilitySchedules(res.data.data.availability_schedules));
                dispatch(pizzaFormSuccess('Pizza Updated!'));

                window.scrollTo(0, pizza.scrollPosition);
                
            }
            else {
                dispatch(pizzaFormError(res.data.message));
                console.log(res.data);
                console.log("Could not update your pizza!");
            }
        })
        .catch(function(err) {
            console.log('err');
            console.log(err);
            // token is likely invalid; remove it and prompt the user to login again
            dispatch(pizzaFormError("We're sorry, but our system has encountered an issue, to which we have been notified."));
            if (err.response && err.response.status === 401) {
                removeAuthToken(dispatch);
            }
            
        });
    };
};

export const updatePizzaOrder = (restaurant_id, pizzas) => {

    return (dispatch) => {

        dispatch(setPizzasLoading());

        // prepare the new order of items to move over
        let itemOrder = [];
        for (let i = 0; i < pizzas.length; i++) {
            itemOrder.push(pizzas[i].id);
        }

        // prepare the order of pizzas and IDs to send
        return axios.post(API_URL+'/api/restaurants/'+restaurant_id+'/pizzas/order', { order: itemOrder })
            .then(function(res) {
                if (res.data.status == 'success') {
                    dispatch(setPizzas(res.data.data.pizzas));
                    dispatch(pizzaFormSuccess());
                }
                else {
                    dispatch(pizzaFormError(res.data.message));
                    console.log(res.data);
                    console.log("Could not update pizza order!");
                }
            })
            .catch(function(err) {

                // token is likely invalid; remove it and prompt the user to login again
                dispatch(pizzaFormError("We're sorry, but our system has encountered an issue, to which we have been notified."));
                if (err.response.status === 401) {
                    removeAuthToken(dispatch);
                }
                
            });
    };
};

export const togglePizzaEnabled = (restaurant_id, pizza_id, enabled) => {
    
    return (dispatch) => {

        dispatch(setPizzasLoading());

        let scope = 'enable';

        return axios.post(API_URL+'/api/restaurants/'+restaurant_id+'/pizzas/'+pizza_id, { enabled, scope })
            .then(function(res) {
                if (res.data.status == 'success') {
                    dispatch(setPizzas(res.data.data.pizzas));
                }
                else {
                    dispatch(pizzaFormError(res.data.message));
                    console.log(res.data);
                    console.log("Could not add your pizza!");
                }
            })
            .catch(function(err) {

                // token is likely invalid; remove it and prompt the user to login again
                dispatch(pizzaFormError("We're sorry, but our system has encountered an issue, to which we have been notified."));
                if (err.response.status === 401) {
                    removeAuthToken(dispatch);
                }
                
            });
    };
};

export const removePizza = (pizza_id, restaurant_id) => {

    return (dispatch) => {

        dispatch(setPizzasLoading());
        return axios.delete(API_URL+'/api/restaurants/'+restaurant_id+'/pizzas/'+pizza_id, {})
            .then(function(res) {
                if (res.data.status == 'success') {
                    dispatch(setPizzas(res.data.data.pizzas));
                }
                else {
                    // @TODO: need better messaging here
                    console.log(res.data);
                    console.log("Could not remove your pizza!");
                }
            })
            .catch(function(err) {

                // token is likely invalid; remove it and prompt the user to login again
                if (err.response.status === 401) {
                    removeAuthToken(dispatch);
                }
                
            });
    };
}