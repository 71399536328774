import axios from 'axios';
import { API_URL } from '../../../config/constants';
import { foodItemFormUpdate, foodItemFormReset, foodItemFormError, foodItemFormSuccess } from './foodItemForm';
import { setSelectedRestaurantAvailabilitySchedules } from '../../selectedRestaurant';
import { closeFoodItemCreate } from '../../modals';

export const setFoodItems = (foodItems) => {
    return {
        type: 'SET_FOOD_ITEMS',
        foodItems
    }
}

export const setAllFoodItems = (foodItems) => {
    return {
        type: 'SET_ALL_FOOD_ITEMS',
        foodItems
    }
}

export const setFoodItemFilter = (text) => {
    return {
        type: 'SET_FOOD_ITEM_FILTER',
        text
    }
}

export const resetFoodItems = () => {
    return {
        type: 'RESET_FOOD_ITEMS'
    }
}

export const setFoodItemsLoading = () => {
    return {
        type: 'SET_FOOD_ITEMS_LOADING'
    }
}

export const loadFoodItems = (restaurantId, categoryId) => {
    return (dispatch) => {
        return axios.get(API_URL+'/api/restaurants/'+restaurantId+'/categories/'+categoryId+'/fooditems')
            .then(function(res) {
                // get the food items
                dispatch(setFoodItems(res.data.data.food_items));
            })
            .catch(function(err) {
                console.log("Err!");
                console.log(err);
            });
    };
}

export const loadAllFoodItems = (restaurantId) => {
    return (dispatch) => {
        return axios.get(API_URL+'/api/restaurants/'+restaurantId+'/fooditems')
            .then(function(res) {
                // get the food items
                dispatch(setAllFoodItems(res.data.data.food_items));
            })
            .catch(function(err) {
                console.log("Err!");
                console.log(err);
            });
    };
}


export const addFoodItem = (restaurantId, categoryId, foodItem) => {
    
    return (dispatch) => {

        const formData = new FormData();

        // add the image
        formData.append('files', foodItem.newImage);

        dispatch(setFoodItemsLoading());

        // if it's a single price, set it up
        if (!foodItem.hasMultiplePrices) {
            foodItem.prices = [
                {
                    name: "<default>",
                    price: foodItem.singlePrice,
                    price_order: 0
                }
            ];
        }

        // add the data
        formData.append('data', JSON.stringify(foodItem));

        return axios.post(
                API_URL+'/api/restaurants/'+restaurantId+'/categories/'+categoryId+'/fooditems', 
                formData,
                {
                    headers: { 'Content-Type': 'multipart/form-data' }
                }
            )
            .then(function(res) {
                if (res.data.status == 'success') {
                    dispatch(setFoodItems(res.data.data.food_items));
                    dispatch(setSelectedRestaurantAvailabilitySchedules(res.data.data.availability_schedules));
                    dispatch(foodItemFormReset());
                    dispatch(closeFoodItemCreate());
                }
                else {
                    dispatch(foodItemFormError(res.data.message));
                }
            })
            .catch(function(err) {
                
                // token is likely invalid; remove it and prompt the user to login again
                console.log(err);
                dispatch(foodItemFormError("We're sorry, but our system has encountered an issue, to which we have been notified."));
                if (err.response.status === 401) {
                    removeAuthToken(dispatch);
                }
                
            });
    };
};

export const updateFoodItem = (restaurantId, categoryId, foodItemId, scope, foodItem) => {
    
    return (dispatch) => {

        const formData = new FormData();

        // add the image
        formData.append('files', foodItem.newImage);

        // add the data
        formData.append('scope', scope);
        formData.append('data', JSON.stringify(foodItem));

        dispatch(setFoodItemsLoading());

        return axios.post(
            API_URL+'/api/restaurants/'+restaurantId+'/categories/'+categoryId+'/fooditems/'+foodItemId, 
            formData,
            {
                headers: { 'Content-Type': 'multipart/form-data' }
            }
        ).then(function(res) {
            if (res.data.status == 'success') {
                dispatch(setFoodItems(res.data.data.food_items));
                dispatch(foodItemFormUpdate({ prop: 'thumb_image', value: res.data.data.food_item.thumb_image }));
                dispatch(foodItemFormUpdate({ prop: 'non_taxable', value: res.data.data.food_item.non_taxable }));
                dispatch(foodItemFormUpdate({ prop: 'choices', value: res.data.data.food_item.choices }));
                dispatch(foodItemFormUpdate({ prop: 'prices', value: res.data.data.food_item.prices }));
                dispatch(foodItemFormUpdate({ prop: 'addons', value: res.data.data.food_item.addons }));
                dispatch(foodItemFormUpdate({ prop: 'modifiers', value: res.data.data.food_item.modifiers }));
                if(res.data.data.food_item.hours_available != null){
                    dispatch(foodItemFormUpdate({ prop: 'hours_available', value: res.data.data.food_item.hours_available }));
                }
                dispatch(foodItemFormUpdate({ prop: 'alwaysAvailable', value: res.data.data.food_item.alwaysAvailable }));
                dispatch(foodItemFormUpdate({ prop: 'saveSchedule', value: false }));
                dispatch(foodItemFormUpdate({ prop: 'scheduleName', value: '' }));
                dispatch(setSelectedRestaurantAvailabilitySchedules(res.data.data.availability_schedules));
                dispatch(foodItemFormSuccess('Food Item Updated!'));

                window.scrollTo(0, foodItem.scrollPosition);

            }
            else {
                dispatch(foodItemFormError(res.data.message));
                console.log(res.data);
                console.log("Could not update your food item!");
            }
        })
        .catch(function(err) {
            console.log('err');
            console.log(err);
            // token is likely invalid; remove it and prompt the user to login again
            dispatch(foodItemFormError("We're sorry, but our system has encountered an issue, to which we have been notified."));
            if (err.response.status === 401) {
                removeAuthToken(dispatch);
            }
            
        });
    };
};

export const updateFoodItemOrder = (restaurantId, categoryId, foodItems) => {

    return (dispatch) => {

        dispatch(setFoodItemsLoading());

        // prepare the new order of items to move over
        let itemOrder = [];
        for (let i = 0; i < foodItems.length; i++) {
            itemOrder.push(foodItems[i].id);
        }

        // prepare the order of categories and IDs to send
        return axios.post(API_URL+'/api/restaurants/'+restaurantId+'/categories/'+categoryId+'/fooditems/order', { order: itemOrder })
            .then(function(res) {
                if (res.data.status == 'success') {
                    dispatch(setFoodItems(res.data.data.food_items));
                    dispatch(foodItemFormSuccess());
                }
                else {
                    dispatch(foodItemFormError(res.data.message));
                    console.log(res.data);
                    console.log("Could not add your food item!");
                }
            })
            .catch(function(err) {

                // token is likely invalid; remove it and prompt the user to login again
                dispatch(foodItemFormError("We're sorry, but our system has encountered an issue, to which we have been notified."));
                if (err.response.status === 401) {
                    removeAuthToken(dispatch);
                }
                
            });
    };
};

export const toggleFoodItemEnabled = (restaurantId, categoryId, food_item_id, enabled) => {
    
    return (dispatch) => {

        dispatch(setFoodItemsLoading());
        return axios.post(API_URL+'/api/restaurants/'+restaurantId+'/categories/'+categoryId+'/fooditems/'+food_item_id, { 
                scope: 'enable',
                enabled 
            })
            .then(function(res) {
                if (res.data.status == 'success') {
                    dispatch(setFoodItems(res.data.data.food_items));
                }
                else {
                    dispatch(foodItemFormError(res.data.message));
                    console.log(res.data);
                    console.log("Could not enable/disable your food item!");
                }
            })
            .catch(function(err) {

                // token is likely invalid; remove it and prompt the user to login again
                dispatch(foodItemFormError("We're sorry, but our system has encountered an issue, to which we have been notified."));
                if (err.response.status === 401) {
                    removeAuthToken(dispatch);
                }
                
            });
    };
};

export const removeFoodItem = (food_item_id, categoryId, restaurantId) => {

    return (dispatch) => {

        dispatch(setFoodItemsLoading());
        return axios.delete(API_URL+'/api/restaurants/'+restaurantId+'/categories/'+categoryId+'/fooditems/'+food_item_id, {})
            .then(function(res) {
                if (res.data.status == 'success') {
                    dispatch(setFoodItems(res.data.data.food_items));
                }
                else {
                    // @TODO: need better messaging here
                    console.log(res.data);
                    console.log("Could not remove your food item!");
                }
            })
            .catch(function(err) {

                // token is likely invalid; remove it and prompt the user to login again
                if (err.response.status === 401) {
                    removeAuthToken(dispatch);
                }
                
            });
    };
}