import axios from 'axios';
import { API_URL } from '../../config/constants';

export const importMenuUpdate = ({ prop, value }) => {

    return {
        type: 'IMPORT_MENU_FORM_UPDATE',
        importMenu: { prop, value }
    };
};

export const importMenuFormError = (message) => {

    return {
        type: 'IMPORT_MENU_FORM_ERROR',
        message
    };
};

export const importMenuFormMessage = (message) => {

    return {
        type: 'IMPORT_MENU_FORM_MESSAGE',
        message
    };
};

export const doImportMenu = (restaurant_id, importMenuFile, purge) => {
    return (dispatch) => {

        const formData = new FormData();

        // add the image
        formData.append('importmenu', importMenuFile);
        formData.append('purge_menu', purge);

        dispatch(importMenuUpdate({ prop: 'loading', value: true }));

        return axios.post(
            API_URL+'/api/restaurants/'+restaurant_id+'/menu/import', 
            formData,
            {
                headers: { 'Content-Type': 'multipart/form-data' }
            }
        ).then(function(res) {
            if (res.data.status == 'success') {

                // add the new restaurant
                dispatch(importMenuUpdate({ prop: 'loading', value: false }));
                dispatch(importMenuUpdate({ prop: 'message', value: res.data.message }));
                dispatch(importMenuFormMessage("Menu was successfully imported!"));
                console.log(res);

            }
            else {
                // dispatch(infoFormError(res.data.message));
                dispatch(importMenuUpdate({ prop: 'loading', value: false }));
                console.log(res);
                console.log(res.data);
                console.log(res.data.message);
                console.log("Could not import your menu!");
            }
        })
        .catch(function(err) {
            console.log('err');
            console.log(err);
            console.log(err.response);
            dispatch(importMenuUpdate({ prop: 'loading', value: false }));
            dispatch(importMenuFormError("We're sorry, but our system has encountered an issue, to which we have been notified."));
        });
    };
}