import axios from 'axios';
import { API_URL } from '../../config/constants';
import { closeSearchReports } from '../modals';

export const setOrdersReportLoading = () => ({
    type: 'SET_REPORT_ORDERS_LOADING'
});

export const setOrdersSummarySearchLoading = () => ({
    type: 'SET_SUMMARY_SEARCH_ORDERS_LOADING'
});

export const removeOrdersReportLoading = () => {
    return {
        type: 'REMOVE_REPORT_ORDERS_LOADING'
    };
}

export const setOrders = (orders, orderSummary, currentPage, totalPages, ordersCompleteList) => {
    return {
        type: 'SET_REPORT_ORDERS',
        orders,
        orderSummary,
        currentPage,
        totalPages,
        ordersCompleteList
    };
}

export const setLocation = (location) => {
    return {
        type: 'SET_LOCATION_INFO',
        location,
    };
}

export const setDispatchCenter = (dispatchCenter) => {
    return {
        type: 'SET_DISPATCH_CENTER_INFO',
        dispatchCenter,
    };
}

export const loadOrdersReport = (restaurant_id, location_id, searchData = {}) => {

    return (dispatch) => {

        let params = searchData;
        return axios.post(API_URL+'/api/restaurants/'+restaurant_id+'/report/'+location_id, searchData)
            .then(function(res) {
                dispatch(removeOrdersReportLoading());
                dispatch(setOrders(res.data.data.orders, res.data.data.orderSummary, res.data.metadata.page, res.data.metadata.total_pages, res.data.data.ordersCompleteList)); 
                dispatch(setLocation(res.data.data.location));
            })
            .catch(function(err) {
                console.log(err);
                console.log(err.response);
            });
    };
}

export const loadSupportReport = (searchData = {}) => {

    return (dispatch) => {

        return axios.post(API_URL+'/api/support/reports', searchData)
            .then(function(res) {
                dispatch(removeOrdersReportLoading());
                dispatch(setOrders(res.data.data.orders, res.data.data.orderSummary, res.data.metadata.page, res.data.metadata.total_pages, res.data.data.ordersCompleteList)); 
            })
            .catch(function(err) {
                console.log(err);
                console.log(err.response);
            });
    };
}

export const exportOrdersReportCSV = (restaurant_id, location_id, searchData = {}) => {
    return (dispatch) => {

        let params = searchData;
        return axios.post(API_URL+'/api/restaurants/'+restaurant_id+'/report/export/CSV/'+location_id, {
            searchData,
            responseType: 'blob',
        }).then(function(response) {

            dispatch(removeOrdersReportLoading());
            
            let fileName = 'order-report';
            let startTime = searchData.start_time,
            startDate = new Date(startTime),
            startYMD = startDate.getFullYear() + '-' + (startDate.getMonth() + 1) + '-' + startDate.getDate();
            let endTime = searchData.end_time,
            endDate = new Date(endTime),
            endYMD = endDate.getFullYear() + '-' + (endDate.getMonth() + 1) + '-' + endDate.getDate();

            var today = new Date(),
            date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

            if(searchData.start_time && searchData.end_time){
                fileName = fileName.concat("-" + startYMD + "-to-" + endYMD + ".csv");
            }else if(searchData.start_time){
                fileName = fileName.concat("-past-" + startTime.getFullYear() + '-' + (startTime.getMonth() + 1) + '-' + startTime.getDate() + ".csv");
            }else if(searchData.end_time){
                fileName = fileName.concat("-before-" + endTime.getFullYear() + '-' + (endTime.getMonth() + 1) + '-' + endTime.getDate() + ".csv");
            }else{
                fileName = fileName.concat("-" + date + ".csv");
            }

            var fileDownload = require('js-file-download');
            fileDownload(response.data, fileName);
            
        })
        .catch(function(err) {
            console.log(err);
            console.log(err.response);
        });
    };
}

export const exportSupportOrdersReportCSV = (searchData = {}) => {
    return (dispatch) => {

        let params = searchData;
        return axios.post(API_URL+'/api/support/report/export/CSV', {
            searchData,
            responseType: 'blob',
        }).then(function(response) {

            dispatch(removeOrdersReportLoading());
            
            let fileName = 'support-order-report';
            let startTime = searchData.start_time,
            startDate = new Date(startTime),
            startYMD = startDate.getFullYear() + '-' + (startDate.getMonth() + 1) + '-' + startDate.getDate();
            let endTime = searchData.end_time,
            endDate = new Date(endTime),
            endYMD = endDate.getFullYear() + '-' + (endDate.getMonth() + 1) + '-' + endDate.getDate();

            var today = new Date(),
            date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

            if(searchData.start_time && searchData.end_time){
                fileName = fileName.concat("-" + startYMD + "-to-" + endYMD + ".csv");
            }else if(searchData.start_time){
                fileName = fileName.concat("-past-" + startTime.getFullYear() + '-' + (startTime.getMonth() + 1) + '-' + startTime.getDate() + ".csv");
            }else if(searchData.end_time){
                fileName = fileName.concat("-before-" + endTime.getFullYear() + '-' + (endTime.getMonth() + 1) + '-' + endTime.getDate() + ".csv");
            }else{
                fileName = fileName.concat("-" + date + ".csv");
            }

            var fileDownload = require('js-file-download');
            fileDownload(response.data, fileName);
            
        })
        .catch(function(err) {
            console.log(err);
            console.log(err.response);
        });
    };
}

export const exportDispatchReportCSV = (dispatch_center_id, searchData = {}) => {
    return (dispatch) => {

        let params = searchData;
        return axios.post(API_URL+'/api/dispatch/report/export/CSV/'+dispatch_center_id, {
            searchData,
            responseType: 'blob',
        }).then(function(response) {

            dispatch(removeOrdersReportLoading());

            let fileName = 'dispatch-order-report';
            let startTime = searchData.start_time,
            startDate = new Date(startTime),
            startYMD = startDate.getFullYear() + '-' + (startDate.getMonth() + 1) + '-' + startDate.getDate();
            let endTime = searchData.end_time,
            endDate = new Date(endTime),
            endYMD = endDate.getFullYear() + '-' + (endDate.getMonth() + 1) + '-' + endDate.getDate();

            var today = new Date(),
            date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

            if(searchData.start_time && searchData.end_time){
                fileName = fileName.concat("-" + startYMD + "-to-" + endYMD + ".csv");
            }else if(searchData.start_time){
                fileName = fileName.concat("-past-" + startTime.getFullYear() + '-' + (startTime.getMonth() + 1) + '-' + startTime.getDate() + ".csv");
            }else if(searchData.end_time){
                fileName = fileName.concat("-before-" + endTime.getFullYear() + '-' + (endTime.getMonth() + 1) + '-' + endTime.getDate() + ".csv");
            }else{
                fileName = fileName.concat("-" + date + ".csv");
            }

            var fileDownload = require('js-file-download');
            fileDownload(response.data, fileName);
            
        })
        .catch(function(err) {
            console.log(err);
            console.log(err.response);
        });
    };
}

export const loadDispatchOrdersReport = (dispatch_center_id, searchData = {}) => {

    return (dispatch) => {

        let params = searchData;
        return axios.post(API_URL+'/api/dispatch/report/'+dispatch_center_id, searchData)
            .then(function(res) {
                dispatch(removeOrdersReportLoading());
                dispatch(setOrders(res.data.data.orders, res.data.data.orderSummary, res.data.metadata.page, res.data.metadata.total_pages, res.data.data.ordersCompleteList)); 
                dispatch(setDispatchCenter(res.data.data.dispatchCenter));
            })
            .catch(function(err) {
                console.log('err');
                console.log(JSON.parse(JSON.stringify(err)));
                // console.log(err);
                // console.log(err.response);
            });
    };
}