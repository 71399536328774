// the default items that we can possibly filter by
const userDefaultState = {
    loading: true,
    user: {}
};

export default (state = userDefaultState, action) => {
    switch (action.type) {
        case 'SET_USER':
            return {
                ...state,
                loading: false,
                user: action.user
            };
            
        case 'REMOVE_USER':
            return userDefaultState;

        default:
            return state;
    }
};