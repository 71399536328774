import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { loadLoggedInUser } from '../../actions/loggedUser';

class LoadScreen extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.dispatch(loadLoggedInUser(this.props.history, this.props.checkPermissions));
    }

    render() {

        let customLoading = false;
        if (this.props.customLoading === true) {
            customLoading = this.props.customLoading;
        }

        if (this.props.isLoading || customLoading) {
            return (
                <div className="load-screen">
                    <CircularProgress className="loader" />
                </div>
            );
        }
        else {
            return this.props.children;
        }
    }
}

const mapStateToProps = (state, props) => {
    return {
        isLoading: state.loggedUser.loading
    };
};

export default withRouter(connect(mapStateToProps)(LoadScreen));