const specialFormDefaultState = {
    name: '',
    description: '',
    price: 0.00,
    non_taxable: 0,
    items: [],
    deletedItems: [],
    message: '',
    error: '',
    activeTab: 0,
    alwaysAvailable: true,
    hours_available: {
        sun_start: '00:00:00',
        sun_end: '00:00:00',
        mon_start: '00:00:00',
        mon_end: '00:00:00',
        tue_start: '00:00:00',
        tue_end: '00:00:00',
        wed_start: '00:00:00',
        wed_end: '00:00:00',
        thu_start: '00:00:00',
        thu_end: '00:00:00',
        fri_start: '00:00:00',
        fri_end: '00:00:00',
        sat_start: '00:00:00',
        sat_end: '00:00:00',
        sun_available: false,
        mon_available: false,
        tue_available: false,
        wed_available: false,
        thu_available: false,
        fri_available: false,
        sat_available: false,
        sun_all_day: false,
        mon_all_day: false,
        tue_all_day: false,
        wed_all_day: false,
        thu_all_day: false,
        fri_all_day: false,
        sat_all_day: false
    },
    scrollPosition: 0,
    saveSchedule: false,
    scheduleName: ''
};

export default (state = specialFormDefaultState, action) => {

    switch (action.type) {

        case 'SPECIAL_FORM_UPDATE':
            return {
                ...state,
                [action.special.prop]: action.special.value
            };

        case 'SPECIAL_FORM_UPDATE_HOURS_AVAILABLE':
            return {
                ...state,
                hours_available: 
                    {
                        ...state.hours_available,
                        [action.location.prop]: action.location.value
                    }
            };

        case 'SPECIAL_FORM_ADD_ITEM':
            return {
                ...state,
                items: state.items.concat(action.item)
            };
    
        case 'SPECIAL_FORM_REMOVE_ITEM':

            let deletedItems = (state.items[action.index].id) ? state.deletedItems.concat(state.items[action.index].id) : state.deletedItems;

            return {
                ...state,
                deletedItems,
                items: state.items.filter((item, index) => {
                    return index != action.index;
                })
            };

        case 'SPECIAL_FORM_RESET_ITEMS':
            return {
                ...state,
                items: [],
            }


        case 'SPECIAL_FORM_ERROR':
            return {
                ...state,
                message: '',
                error: action.message
            }

        case 'SPECIAL_FORM_SUCCESS':
            return {
                ...state,
                error: '',
                message: action.message
            }

        case 'SPECIAL_FORM_RESET':
            return specialFormDefaultState;

        default:
            return state;
    }
};