import React from 'react';
import { connect } from 'react-redux';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

class SettingToggle extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }


    render() {
        return (
            <FormControlLabel
                control={
                <Checkbox
                    checked={this.props.value}
                    onChange={this.props.handleChange}
                    name={this.props.name}
                    color="primary"
                />
                }
                label={this.props.name}
            />
        );
    }
};

export default connect()(SettingToggle);