import React from 'react';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import EditIcon from '@material-ui/icons/Edit';
import Typography from '@material-ui/core/Typography';

class LoyaltyUsersList extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    render() {
        return (
            <Grid container>

                {this.props.users.length <= 0 && (
                    <Grid item xs={12} className="grid-center">
                        <Typography className="empty-message">
                            No users yet.
                        </Typography>
                    </Grid>
                )}

                {this.props.users.length > 0 && (
                    <Grid item xs={12} className="sortable-list">
                        <Grid container className="sortable-menu-item">
                            <Grid item xs={6} className="form-grid"></Grid>
                            <Grid item xs={2} className="form-image-container">
                                <Typography className='table-heading-text'>
                                    Points
                                </Typography>
                            </Grid>
                            <Grid item xs={2} className="form-image-container">
                                <Typography className='table-heading-text'>
                                    Points used
                                </Typography>
                            </Grid>
                            <Grid item xs={2} className="form-grid"></Grid>
                        </Grid> 
                        {this.props.users.map((user, index) => (
                            <div>
                                <Grid key={"item-"+index} className="sortable-menu-item" container>
                                    <Grid item xs={6} className="list-name">
                                        <Typography>{user.user.email}</Typography>
                                    </Grid>
                                    <Grid item xs={2} className="form-image-container">
                                        <Typography>{user.points}</Typography>
                                    </Grid>
                                    <Grid item xs={2} className="form-image-container">
                                        <Typography>{user.points_used}</Typography>
                                    </Grid>
                                    <Grid item xs={2} className="list-sort">
                                        <Button fullWidth={true} color="primary" className="modal-button-list" onClick={() => this.props.toggleUpdateModal(true, user)}>
                                            <EditIcon className="modal-button-icon" />
                                        </Button>
                                    </Grid>
                                </Grid>
                            </div>
                        ))}
                    </Grid>
                )}
            </Grid>
        );
    }
};

export default connect()(LoyaltyUsersList);