import React from 'react';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import arrayMove from 'array-move';

import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

import LoadScreen from '../../common/LoadScreen';
import verifyLogin from '../../../utils/verifyLogin';
import { loadPageData } from '../../../actions/auth';

import { loadModifiers, setModifierFilter, toggleModifierEnabled, updateModifierOrder } from '../../../actions/menu/modifiers/modifiers';
import { modifierFormUpdate, modifierFormReset } from '../../../actions/menu/modifiers/modifierForm';
import { openModifierModal } from '../../../actions/modals';
import filterModifiers from '../../../utils/filterListName';

import ModifierModal from './modal/ModifierModal';

import SortableMenuList from '../common/SortableMenuList';
import checkPermissions from '../../../utils/checkUserPermissions';

class ModifiersPage extends React.Component {

    constructor(props) {
        super(props);

        this.checkPermissions = this.checkPermissions.bind(this);
        this.openCreateModal = this.openCreateModal.bind(this);
        this.toggleModifierEnabled = this.toggleModifierEnabled.bind(this);
        this.openUpdateModal = this.openUpdateModal.bind(this);
        this.onSortEnd = this.onSortEnd.bind(this);

        this.state = {
            selectedModifier: {}
        };

    }

    componentDidMount() {
        verifyLogin(this.props.history);
    }

    componentDidUpdate() {
        verifyLogin(this.props.history);
    }

    checkPermissions() {
        if (checkPermissions(this.props.user, 'restaurant_manager', this.props.match.params.id) || checkPermissions(this.props.user, 'support')) {
            this.props.dispatch(loadPageData(this.props.match.params));
            this.props.dispatch(loadModifiers(this.props.match.params.id));
        } else {
            this.props.history.push('/permission-denied');
        } 
    }

    openUpdateModal(isOpen, selectedModifier) {

        this.setState(() => ({
            selectedModifier
        }));
        
        this.props.dispatch(modifierFormReset());
        this.props.dispatch(modifierFormUpdate({ prop: 'id', value: selectedModifier.id }));
        this.props.dispatch(modifierFormUpdate({ prop: 'name', value: selectedModifier.name }));
        this.props.dispatch(modifierFormUpdate({ prop: 'min', value: selectedModifier.min }));
        this.props.dispatch(modifierFormUpdate({ prop: 'max', value: selectedModifier.max }));
        this.props.dispatch(modifierFormUpdate({ prop: 'price_levels', value: selectedModifier.price_levels }));
        this.props.dispatch(modifierFormUpdate({ prop: 'items', value: selectedModifier.items }));
        this.props.dispatch(openModifierModal());
        
    }

    openCreateModal() {
        this.props.dispatch(modifierFormReset());
        this.props.dispatch(openModifierModal());
    }

    onSortEnd({oldIndex, newIndex})  {

        let newOrder = arrayMove(this.props.modifiers, oldIndex, newIndex);
        for (let i = 0; i < newOrder.length; i++) {
            newOrder[i]['sort_order'] = i;
        }

        this.props.dispatch(updateModifierOrder(this.props.match.params.id, newOrder));
    }
   
    toggleModifierEnabled(modifier, enabled) {
        let confirmStr = "Are you sure you want to disable this modifier?";
        if (enabled) {
            confirmStr = "Enable this modifier?";
        }

        if (confirm(confirmStr)) {
            this.props.dispatch(toggleModifierEnabled(this.props.match.params.id, modifier.id, enabled));
        };
    }

    renderModifiers() {
        
        if (this.props.loading) {
            return (
                <div className="page-loading">
                    <CircularProgress size={100} />
                </div>
            );
        }
        else {
            if (this.props.modifiers.length <= 0) {
                return (
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography className="no-results">No modifiers entered.</Typography>
                        </Grid>
                    </Grid>
                );
            }
            else {
                return (
                    <SortableMenuList
                        items={this.props.modifiers}
                        toggleEnabled={this.toggleModifierEnabled}
                        toggleUpdateModal={this.openUpdateModal}
                        onSortEnd={this.onSortEnd}
                        showImage={false}
                        isCategory={false}
                    />
                );
            }
        }
    }
    
    render() {
        return (
            <LoadScreen checkPermissions={this.checkPermissions} >
                <div>
                    <div className="card-container">
                        <Grid container className="menu-page-header">
                            <Grid item xs={12} className="heading-grid">
                                <h1>Modifiers</h1>
                                <FormControl fullWidth>
                                    <TextField 
                                        label="Filter Modifiers" 
                                        variant="outlined" 
                                        value={this.props.filter}
                                        onChange={(e) => this.props.dispatch(setModifierFilter(e.target.value))} 
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        {this.renderModifiers()}
                    </div>
                    <div className="window-fab">
                        <Fab className="btn-fab btn-add" onClick={() => this.openCreateModal()}>
                            <AddIcon />
                        </Fab>
                    </div>
                    <ModifierModal restaurantId={this.props.match.params.id} />
                </div>
            </LoadScreen>
        );
    }
};

const mapStateToProps = (state, props) => {
    return {
        loading: state.modifiers.loading || state.restaurants.loading,
        restaurant: state.selectedRestaurant.restaurant,
        modifiers: filterModifiers(state.modifiers.modifiers, {text: state.modifiers.filter}),
        user: state.loggedUser.user
    };
};

export default connect(mapStateToProps)(ModifiersPage);