import React from 'react';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import TextField from '@material-ui/core/TextField';

import SortableContainer from '../../common/sort/SortableContainer';
import SortableHandle from '../../common/sort/SortableHandle';
import SortableItem from '../../common/sort/SortableItem';

import { API_URL } from '../../../config/constants';
import restaurant_logo from '../../../assets/images/restaurant_default.png';

class SortableItemList extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    render() {
        return (
            <SortableContainer helperClass='sortableHelper' onSortEnd={this.props.onSortEnd} useDragHandle>
                <Grid container>
                    <Grid item xs={12}>
                        {this.props.items.map((item, index) => (
                            <SortableItem key={"settingssize-"+index} index={index}>
                                <Grid key={"item-"+index} container>
                                    <Grid item xs={1} className="list-sort">
                                        <SortableHandle />
                                    </Grid>
                                    <Grid item xs={5} className="form-grid">
                                        <TextField
                                            label="Name"
                                            className="modal-input"
                                            fullWidth={true}
                                            value={item.name}
                                            onChange={(e) => { this.props.handleUpdateItem('name', index, e.target.value ); }}
                                            margin="normal"
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={5} className="form-grid">
                                        <TextField
                                            label="Price"
                                            className="modal-input"
                                            fullWidth={true}
                                            value={item.price}
                                            onChange={(e) => { this.props.handleUpdateItem('price', index, e.target.value ); }}
                                            margin="normal"
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={1} className="list-sort">
                                        <Button fullWidth={true} color="secondary" onClick={() => this.props.handleDeleteItem(index)}>
                                            <DeleteIcon />
                                        </Button>
                                    </Grid>
                                </Grid>
                            </SortableItem>
                        ))}
                    </Grid>
                </Grid>
            </SortableContainer>
        );
    }
};

export default connect()(SortableItemList);