const menuExportFormDefaultState = {
    pizzas: false,
    food_items: false,
    beverages: false,
    specials: false,
    addons: false,
    disabled: false,
    filter: '',
    error: '',
    loading: false,
};

export default (state = menuExportFormDefaultState, action) => {
    
    switch (action.type) {

        case 'EXPORT_MENU_FORM_UPDATE':
            return {
                ...state,
                [action.directory.prop]: action.directory.value
            };

        case 'EXPORT_MENU_FORM_ERROR':
            return {
                ...state,
                message: '',
                loading: false,
                error: action.message
            }

        case 'EXPORT_MENU_FORM_LOADING':
            return {
                ...state,
                loading: true
            }

        case 'EXPORT_MENU_FORM_SUCCESS':
            return {
                ...state,
                error: '',
                loading: false,
                message: action.message
            }

        case 'EXPORT_MENU_FORM_RESET':
            return menuExportFormDefaultState;

        default:
            return state;
    }
};