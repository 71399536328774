import React from "react";
import { Route } from 'react-router-dom';
import { connect } from "react-redux";
import { setOrdersLastUpdatedVisible } from "../actions/restaurant/orders";

class CustomRoute extends React.Component {

    constructor(props) {
        super(props);
    }
    
    render() {

        if(this.props.showOrdersTime){
            this.props.dispatch(setOrdersLastUpdatedVisible(true));
        }else{
            this.props.dispatch(setOrdersLastUpdatedVisible(false));
        }

        return (
            <Route path={this.props.path} component={this.props.component} exact={this.props.exact} />
        );
    }

}

const mapStateToProps = (state, props) => {
    return {
    };
};

export default connect(mapStateToProps)(CustomRoute);