const loyaltyRewardFormDefaultState = {
    name: '',
    value: 0,
    points: 0,
    message: '',
    loading: false,
    error: ''
};

export default (state = loyaltyRewardFormDefaultState, action) => {

    switch (action.type) {

        case 'LOYALTY_REWARD_FORM_UPDATE':
            return {
                ...state,
                [action.reward.prop]: action.reward.value
            };

        case 'LOYALTY_REWARD_FORM_ERROR':
            return {
                ...state,
                message: '',
                error: action.message
            }

        case 'LOYALTY_REWARD_FORM_SUCCESS':
            return {
                ...state,
                error: '',
                message: action.message
            }

        case 'LOYALTY_REWARD_FORM_RESET':
            return loyaltyRewardFormDefaultState;

        default:
            return state;
    }
};