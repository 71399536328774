const endTime = new Date();
endTime.setHours(23);
endTime.setMinutes(59);
endTime.setSeconds(59);

const startTime = new Date();
startTime.setHours(0);
startTime.setMinutes(0);
startTime.setSeconds(0);

const searchFormDefaultState = {
    name: '',
    phone: '',
    address: '',
    order_id: '',
    start_time: startTime,
    end_time: endTime,
    any_time: 1,
    order_status: 'all',
    message: '',
    error: '',
    default: 1,
};

export default (state = searchFormDefaultState, action) => {

    switch (action.type) {

        case 'REPORTS_SEARCH_FORM_UPDATE':
            let any_time_value = state.any_time;
            if(action.search.prop == 'end_time' || action.search.prop == 'start_time' || action.search.prop == 'start_time_time' || action.search.prop == 'end_time_time'){
                any_time_value = 0; // We are intentionally choosing a date, assume we are not searching by all time
            }
            if(action.search.prop == 'start_time_time' || action.search.prop == 'end_time_time'){
                let curTime = (action.search.prop == 'start_time_time') ? state.start_time: state.end_time;
                let newTime = new Date(curTime.getFullYear(), curTime.getMonth(),
                                curTime.getDate(), action.search.value.toDate().getHours(),
                                action.search.value.toDate().getMinutes(), action.search.value.toDate().getSeconds());
                
                action.search.value = newTime;
                action.search.prop = action.search.prop.substring(0, action.search.prop.length - 5);
            }
            return {
                ...state,
                any_time: any_time_value,
                [action.search.prop]: action.search.value,
                default: 0
            };

        case 'REPORTS_SEARCH_FORM_ERROR':
            return {
                ...state,
                message: '',
                error: action.message
            }

        case 'REPORTS_SEARCH_FORM_SUCCESS':
            return {
                ...state,
                error: '',
                message: action.message
            }

        case 'REPORTS_SEARCH_FORM_RESET':
            return searchFormDefaultState;

        default:
            return state;
    }
};