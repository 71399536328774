import React from 'react';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Typography from '@material-ui/core/Typography';

class LoyaltyRewardsList extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    render() {
        return (
            <Grid container>
                <Grid item xs={12} className="heading-grid">
                    <Typography variant="h2">
                        Available Loyalty Rewards
                    </Typography>
                </Grid>

                {this.props.rewards.length <= 0 && (
                    <Grid item xs={12} className="grid-center">
                        <Typography className="empty-message">
                            No rewards available.
                        </Typography>
                    </Grid>
                )}

                {this.props.rewards.length > 0 && (
                    <Grid item xs={12} className="sortable-list">
                        {this.props.rewards.map((reward, index) => (
                            <div>
                                <Grid key={"item-"+index} className="sortable-menu-item" container>
                                    <Grid item xs={10} className="list-name">
                                        <Typography>{reward.name}</Typography>
                                    </Grid>
                                    <Grid item xs={1} className="list-sort">
                                        <Button fullWidth={true} color="primary" className="modal-button-list" onClick={() => this.props.toggleUpdateModal(true, reward)}>
                                            <EditIcon className="modal-button-icon" />
                                        </Button>
                                    </Grid>
                                    <Grid item xs={1} className="list-sort">
                                        <Button fullWidth={true} color="primary" className="modal-button-list" onClick={() => this.props.handleDeleteReward(reward)}>
                                            <DeleteIcon className="modal-button-icon" />
                                        </Button>
                                    </Grid>
                                </Grid>
                            </div>
                        ))}
                    </Grid>
                )}
            </Grid>
        );
    }
};

export default connect()(LoyaltyRewardsList);