export const specialFormUpdate = ({ prop, value }) => {

    return {
        type: 'SPECIAL_FORM_UPDATE',
        special: { prop, value }
    };
};

export const specialFormUpdateHoursAvailable = ({ prop, value }) => {
    return {
        type: 'SPECIAL_FORM_UPDATE_HOURS_AVAILABLE',
        location: { prop, value }
    };
};

export const specialFormError = (message) => {

    return {
        type: 'SPECIAL_FORM_ERROR',
        message
    };
};

export const specialFormAddItem = (item) => {

    return {
        type: 'SPECIAL_FORM_ADD_ITEM',
        item
    }
};

export const specialFormRemoveItem = (index) => {

    return {
        type: 'SPECIAL_FORM_REMOVE_ITEM',
        index
    }
}

export const specialFormSuccess = (message) => {

    return {
        type: 'SPECIAL_FORM_SUCCESS',
        message
    };
};

export const specialFormReset = () => {
    
    return {
        type: 'SPECIAL_FORM_RESET',
    };
}