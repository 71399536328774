import axios from 'axios';
import { API_URL } from '../../config/constants';

export const itemizedFormUpdate = ({ prop, value }) => {

    return {
        type: 'REPORTS_ITEMIZED_FORM_UPDATE',
        search: { prop, value }
    };
};

export const setItemizedOrdersSearchLoading = () => {

    return {
        type: 'REPORTS_ITEMIZED_FORM_LOADING',
    };
};

export const setItemizedOrdersSearchDoneLoading = () => {

    return {
        type: 'REPORTS_ITEMIZED_FORM_DONE_LOADING',
    };
};

export const itemizedFormError = (message) => {

    return {
        type: 'REPORTS_ITEMIZED_FORM_ERROR',
        message
    };
};

export const itemizedFormSuccess = (message) => {

    return {
        type: 'REPORTS_ITEMIZED_FORM_SUCCESS',
        message
    };
};

export const itemizedFormReset = () => {
    
    return {
        type: 'REPORTS_ITEMIZED_FORM_RESET',
    };
}

export const setcategorizedOrders = (foodItems = [], pizzas = [], specials = [], addons = [], orderCount, totalQuantity, totalSales) => {
    
    return {
        type: 'SET_CATEGORIZED_ORDERS',
        foodItems,
        pizzas,
        specials,
        addons,
        orderCount,
        totalQuantity,
        totalSales,
    };
}

export const setItemizedOrders = (orders = [], orderCount, totalQuantity, totalSales) => {
    
    return {
        type: 'SET_ITEMIZED_ORDERS',
        orders,
        orderCount,
        totalQuantity,
        totalSales,
    };
}

export const toggleRowVisibility = (foodItemID) => {
    return {
        type: 'TOGGLE_ITEMIZED_REPORT_ROW',
        foodItemID: foodItemID
    };
}

export const loadItemizedReport = (restaurant_id, location_id, searchData = {}) => {

    return (dispatch) => {

        let params = searchData;
        return axios.post(API_URL+'/api/restaurants/'+restaurant_id+'/itemized_report/'+location_id, searchData)
            .then(function(res) {
                let categorizedOrders = res.data.data.itemizedOrders;
                dispatch(setItemizedOrdersSearchDoneLoading());
                // dispatch(setCategorizedOrders(categorizedOrders.foodItems, categorizedOrders.pizzas, categorizedOrders.specials, categorizedOrders.addons, res.data.metadata.total_records, res.data.data.totalQuantity, res.data.data.totalSales));
                dispatch(setItemizedOrders(categorizedOrders, res.data.metadata.total_records, res.data.data.totalQuantity, res.data.data.totalSales));

            })
            .catch(function(err) {
                console.log(err);
                console.log(err.response);
            });
    };
}

export const loadItemizedReportCSV = (restaurant_id, location_id, searchData = {}, expanded) => {

    return (dispatch) => {

        let params = searchData;
        return axios.post(API_URL+'/api/restaurants/'+restaurant_id+'/itemized_report_csv/'+location_id, {
            searchData,
            expanded,
            responseType: 'blob',
        }).then(function(res) {
                dispatch(setItemizedOrdersSearchDoneLoading());
                let fileName = 'itemized-order-report';
                let startTime = searchData.start_time,
                startDate = new Date(startTime),
                startYMD = startDate.getFullYear() + '-' + (startDate.getMonth() + 1) + '-' + startDate.getDate();
                let endTime = searchData.end_time,
                endDate = new Date(endTime),
                endYMD = endDate.getFullYear() + '-' + (endDate.getMonth() + 1) + '-' + endDate.getDate();

                var today = new Date(),
                date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

                if(searchData.start_time && searchData.end_time){
                    fileName = fileName.concat("-" + startYMD + "-to-" + endYMD + ".csv");
                }else if(searchData.start_time){
                    fileName = fileName.concat("-past-" + startTime.getFullYear() + '-' + (startTime.getMonth() + 1) + '-' + startTime.getDate() + ".csv");
                }else if(searchData.end_time){
                    fileName = fileName.concat("-before-" + endTime.getFullYear() + '-' + (endTime.getMonth() + 1) + '-' + endTime.getDate() + ".csv");
                }else{
                    fileName = fileName.concat("-" + date + ".csv");
                }

                var fileDownload = require('js-file-download');
                fileDownload(res.data, fileName);
            })
            .catch(function(err) {
                console.log(err);
                console.log(err.response);
            });
    };
}