import React from 'react';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

import validateCurrency from '../../../../utils/validateCurrency';
import { setPizzasLoading } from '../../../../actions/menu/pizzas/pizzas';
import { settingsFormUpdate, settingsFormError, savePizzaSettings } from '../../../../actions/menu/pizzas/settingsForm';
import { closePizzaSettingsConfig } from '../../../../actions/modals';

import CloseIcon from '../../../common/modal/CloseIcon';

// import Info from './Info';
import ConfigSizes from './ConfigSizes';
import ConfigToppings from './ConfigToppings';
import ConfigCrusts from './ConfigCrusts';
import ConfigSauces from './ConfigSauces';

class ConfigPizzaSettings extends React.Component {

    constructor(props) {
        super(props);

        this.closeConfigModal = this.closeConfigModal.bind(this);
        this.checkPizzaSettingSizes = this.checkPizzaSettingSizes.bind(this);
        this.checkPizzaSettingToppings = this.checkPizzaSettingToppings.bind(this);
        this.checkPizzaSettingCrusts = this.checkPizzaSettingCrusts.bind(this);
        this.checkPizzaSettingSauces = this.checkPizzaSettingSauces.bind(this);
        this.updateTabSelection = this.updateTabSelection.bind(this);

        this.state = {
            activeStep: 0
        };
    }

    getSteps() {
        return [
            'Sizes and Prices',
            'Toppings',
            'Crusts',
            'Sauces'
        ];
    }

    closeConfigModal() {
        this.props.dispatch(closePizzaSettingsConfig());
    }

    updateTabSelection(index) {
        this.props.dispatch(settingsFormUpdate({ prop: 'activeTab', value: index }));
    }

    checkPizzaSettingSizes() {

        let valid = true;
        let multiplePriceCount = 0;

        // go through each size and determine if it is valid
        for (let i = 0; i < this.props.pizzaSettingsForm.sizes.length; i++) {

            // ignore any blank rows
            if (this.props.pizzaSettingsForm.sizes[i].size_name != '' || this.props.pizzaSettingsForm.sizes[i].default_price != '') {
                if (!validateCurrency(this.props.pizzaSettingsForm.sizes[i].default_price) || this.props.pizzaSettingsForm.sizes[i].size_name.match(/^\s*$/)) {
                    valid = false;
                    this.props.dispatch(settingsFormError("Please ensure that all sizes have a valid name and default price."));
                }
                else {
                    multiplePriceCount++;
                }
            }
        }

        if (multiplePriceCount <= 0 && valid) {
            valid = false;
            this.props.dispatch(settingsFormError("Please enter at least one size."));
        }

        if (valid) {
            this.props.dispatch(settingsFormError(""));
            
            // clean out any multiple sizes that are empty
            let trimmedSizes = this.props.pizzaSettingsForm.sizes.filter((size) => {
                return size.size_name != '' && size.default_price != '';
            });
            this.props.dispatch(settingsFormUpdate({ prop: 'sizes', value: trimmedSizes }));
            this.props.dispatch(savePizzaSettings(this.props.restaurantId, 'sizes', trimmedSizes, this.props.pizzaSettingsForm.deletedSizes, false, this.props.pizzaSettingsForm.scrollPosition));
        }
    }

    checkPizzaSettingToppings() {

        let valid = true;
        let toppingsCount = 0;

        // go through each size and determine if it is valid
        for (let i = 0; i < this.props.pizzaSettingsForm.toppings.length; i++) {

            for (let j = 0; j < this.props.pizzaSettingsForm.toppings[i].prices.length; j++) {

                if (!validateCurrency(this.props.pizzaSettingsForm.toppings[i].prices[j].price)) {
                    valid = false;
                    this.props.dispatch(settingsFormError("Please ensure that all sizes have a valid price."));
                }
            }

            toppingsCount++;
        }

        if (toppingsCount <= 0 && valid) {
            valid = false;
            this.props.dispatch(settingsFormError("Please enter at least one topping."));
        }

        if (valid) {
            this.props.dispatch(settingsFormError(""));
            this.props.dispatch(savePizzaSettings(this.props.restaurantId, 'toppings', this.props.pizzaSettingsForm.toppings, this.props.pizzaSettingsForm.deletedToppings, false, this.props.pizzaSettingsForm.scrollPosition));
        }
    }

    checkPizzaSettingCrusts() {

        let valid = true;

        // go through each size and determine if it is valid
        for (let i = 0; i < this.props.pizzaSettingsForm.crusts.length; i++) {

            for (let j = 0; j < this.props.pizzaSettingsForm.crusts[i].prices.length; j++) {

                if (!validateCurrency(this.props.pizzaSettingsForm.crusts[i].prices[j].price)) {
                    valid = false;
                    this.props.dispatch(settingsFormError("Please ensure that all sizes have a valid price."));
                }
            }
        }

        if (valid) {
            this.props.dispatch(settingsFormError(""));
            this.props.dispatch(savePizzaSettings(this.props.restaurantId, 'crusts', this.props.pizzaSettingsForm.crusts, this.props.pizzaSettingsForm.deletedCrusts, false, this.props.pizzaSettingsForm.scrollPosition));
        }
    }

    checkPizzaSettingSauces() {

        let valid = true;

        // go through each size and determine if it is valid
        for (let i = 0; i < this.props.pizzaSettingsForm.sauces.length; i++) {

            for (let j = 0; j < this.props.pizzaSettingsForm.sauces[i].prices.length; j++) {

                if (!validateCurrency(this.props.pizzaSettingsForm.sauces[i].prices[j].price)) {
                    valid = false;
                    this.props.dispatch(settingsFormError("Please ensure that all sizes have a valid price."));
                }
            }
        }

        if (valid) {
            this.props.dispatch(settingsFormError(""));
            this.props.dispatch(savePizzaSettings(this.props.restaurantId, 'sauces', this.props.pizzaSettingsForm.sauces, this.props.pizzaSettingsForm.deletedSauces, false, this.props.pizzaSettingsForm.scrollPosition));
        }
    }

    renderCreateModalActiveTab() {
        if (this.props.loading) {
            return (
                <div className="page-loading">
                    <CircularProgress size={100} />
                </div>
            );
        }
        else {
            switch(this.props.pizzaSettingsForm.activeTab) {
                case 0:
                    return this.renderSettingsConfigSizes();

                case 1:
                    return this.renderSettingsConfigToppings();

                case 2:
                    return this.renderSettingsConfigCrusts();

                case 3:
                    return this.renderSettingsConfigSauces();

                default:
                    return <div></div>;
            }
        }
    }

    renderSettingsConfigSizes() {
        return (
            <div>
                { this.props.pizzaSettingsForm.error && (
                    <Typography className="modal-error">
                        { this.props.pizzaSettingsForm.error }
                    </Typography>
                )}

                <Typography variant="h5" id="modal-title">
                    Sizes and Default Prices
                </Typography>

                <Typography className="modal-description">
                    Enter any available pizza sizes that you have, such as 'Small', 'Medium' and 'Large'.
                </Typography>

                <ConfigSizes />

                <Button variant="contained" fullWidth={true} className="modal-button-bottom" onClick={this.checkPizzaSettingSizes}>
                    Next - Add Toppings
                </Button>
            </div>
        );
    }

    renderSettingsConfigToppings() {
        return (
            <div>
                { this.props.pizzaSettingsForm.error && (
                    <Typography className="modal-error">
                        { this.props.pizzaSettingsForm.error }
                    </Typography>
                )}

                <ConfigToppings />

                <Grid container>
                    <Grid item xs={6} className="form-grid">
                        <Button variant="contained" fullWidth={true} className="modal-button-bottom btn-secondary" onClick={() => this.updateTabSelection(0)}>
                            Back
                        </Button>
                    </Grid>
                    <Grid item xs={6} className="form-grid">
                        <Button variant="contained" fullWidth={true} className="modal-button-bottom" onClick={this.checkPizzaSettingToppings}>
                            Next - Specialty Crusts
                        </Button>
                    </Grid>
                </Grid>
            </div>
        );
    }

    renderSettingsConfigCrusts() {
        return (
            <div>
                { this.props.pizzaSettingsForm.error && (
                    <Typography className="modal-error">
                        { this.props.pizzaSettingsForm.error }
                    </Typography>
                )}

                <ConfigCrusts />

                <Grid container>
                    <Grid item xs={6} className="form-grid">
                        <Button variant="contained" fullWidth={true} className="modal-button-bottom btn-secondary" onClick={() => this.updateTabSelection(1)}>
                            Back
                        </Button>
                    </Grid>
                    <Grid item xs={6} className="form-grid">
                        <Button variant="contained" fullWidth={true} className="modal-button-bottom" onClick={this.checkPizzaSettingCrusts}>
                            Next - Specialty Sauces
                        </Button>
                    </Grid>
                </Grid>
            </div>
        );
    }

    renderSettingsConfigSauces() {
        return (
            <div>
                { this.props.pizzaSettingsForm.error && (
                    <Typography className="modal-error">
                        { this.props.pizzaSettingsForm.error }
                    </Typography>
                )}

                <ConfigSauces />

                <Grid container>
                    <Grid item xs={6} className="form-grid">
                        <Button variant="contained" fullWidth={true} className="modal-button-bottom btn-secondary" onClick={() => this.updateTabSelection(2)}>
                            Back
                        </Button>
                    </Grid>
                    <Grid item xs={6} className="form-grid">
                        <Button variant="contained" fullWidth={true} className="modal-button-bottom" onClick={this.checkPizzaSettingSauces}>
                            Finish
                        </Button>
                    </Grid>
                </Grid>
            </div>
        );
    }
    
    render() {
        return (
            <Modal
                aria-labelledby="config-pizza-settings"
                aria-describedby="config-pizza-settings"
                open={this.props.createModalOpen}
                onClose={() => this.closeConfigModal()}
                className="modal_admin"
                disableBackdropClick
            >
                <div className="modal-member">
                    <Typography variant="h4" id="modal-title">
                        <CloseIcon/>
                    </Typography>
                    <Stepper activeStep={this.props.pizzaSettingsForm.activeTab} alternativeLabel>
                        {this.getSteps().map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    <Typography variant="h4" id="modal-title">
                        Configure Pizza Settings
                    </Typography>

                    {this.renderCreateModalActiveTab()}
                </div>
            </Modal>
        );
    }
};

const mapStateToProps = (state, props) => {
    return {
        loading: state.pizzas.loading,
        restaurant: state.selectedRestaurant.restaurant,
        settings: state.pizzas.settings,
        pizzaSettingsForm: state.pizzaSettingsForm,
        createModalOpen: state.modals.pizzaSettingsConfig
    };
};

export default connect(mapStateToProps)(ConfigPizzaSettings);