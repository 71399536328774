// the default items that we can possibly filter by
const ordersDefaultState = {
    loading: true,
    generatingFile: false,
    performingSearch: false, // Strictly true on page load since we're getting the orders for the day, but will be used when performing a regular search that might take awhile
    orders: [],
    ordersCompleteList: [],
    location: [],
    dispatchCenter: [],
    orderSummary: {},
    currentPage: 0,
    totalPages: 0,
    filter: ''
};

export default (state = ordersDefaultState, action) => {

    switch (action.type) {
        case 'SET_REPORT_ORDERS':

            return {
                ...state,
                loading: false,
                filter: '',
                orders: action.orders,
                orderSummary: action.orderSummary,
                currentPage: action.currentPage,
                totalPages: action.totalPages,
                ordersCompleteList: action.ordersCompleteList,
            };

        case 'SET_LOCATION_INFO':

            return {
                ...state,
                location: action.location,
            };

        case 'SET_DISPATCH_CENTER_INFO':

            return {
                ...state,
                dispatchCenter: action.dispatchCenter,
            };

        case 'SET_REPORT_ORDER_FILTER':
            return {
                ...state,
                filter: action.text
            };

        case 'SET_REPORT_ORDERS_LOADING':
            return {
                ...state,
                loading: true,
                generatingFile: true,
            };

        case 'SET_SUMMARY_SEARCH_ORDERS_LOADING':
            return {
                ...state,
                loading: true,
                performingSearch: true,
            };

        case 'REMOVE_REPORT_ORDERS_LOADING':
            return {
                ...state,
                loading: false,
                generatingFile: false,
                performingSearch: false,
            };
            
        case 'RESET_REPORT_ORDERS':
            return ordersDefaultState;

        default:
            return state;
    }
};