export const choiceFormUpdate = ({ prop, value }) => {

    return {
        type: 'CHOICE_FORM_UPDATE',
        foodItem: { prop, value }
    };
};

export const choiceFormUpdateItem = ({ prop, index, value }) => {

    return {
        type: 'CHOICE_FORM_UPDATE_ITEM',
        item: { prop, index, value }
    };
};

export const choiceFormAddItem = (item) => {

    return {
        type: 'CHOICE_FORM_ADD_ITEM',
        item
    }
};

export const choiceFormRemoveItem = (index) => {

    return {
        type: 'CHOICE_FORM_REMOVE_ITEM',
        index
    }
}

export const choiceFormError = (message) => {

    return {
        type: 'CHOICE_FORM_ERROR',
        message
    };
};

export const choiceFormSuccess = (message) => {

    return {
        type: 'CHOICE_FORM_SUCCESS',
        message
    };
};

export const choiceFormSet = (choice, index) => {
    
    return {
        type: 'CHOICE_FORM_SET',
        choice: {
            ...choice,
            index
        }
    };
}

export const choiceFormReset = () => {
    
    return {
        type: 'CHOICE_FORM_RESET',
    };
}