export const roundToDecimal = ({ prop, value, roundToPlace, removeAffix }) => {

    value = Number.parseFloat(value).toFixed(roundToPlace);

    if(removeAffix){

        let affix = prop.substring(prop.length - removeAffix.length, prop.length);

        if(affix == removeAffix){
            prop = prop.substring(0, prop.length - removeAffix.length);
        }

    }

    return {prop, value};

};