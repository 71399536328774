import React from 'react';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

import LoadScreen from '../common/LoadScreen';
import verifyLogin from '../../utils/verifyLogin';
import { getExportedRestaurantMenu, exportMenuFormUpdate } from '../../actions/superuser/menuExportForm';

import { loadAdminRestaurantLocations } from '../../actions/superuser/restaurantLocations';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import restaurantSelector from '../../selectors/restaurants';

import checkPermissions from '../../utils/checkUserPermissions';

class ExportRestaurantMenu extends React.Component {

    constructor(props) {
        super(props);

        this.updateSelectedRestaurant = this.updateSelectedRestaurant.bind(this);
        this.exportMenu = this.exportMenu.bind(this);
        this.updateFilter = this.updateFilter.bind(this);
        this.checkPermissions = this.checkPermissions.bind(this);
        this.toggleSpecials = this.toggleSpecials.bind(this);

        this.state = {
            selectedRestaurantId: 0,
            selectedRestaurant: {}
        };
    }

    componentDidMount() {
        verifyLogin(this.props.history);
    }

    checkPermissions() {
        if (checkPermissions(this.props.user, 'superuser')) {
            this.props.dispatch(loadAdminRestaurantLocations());
        } else {
            this.props.history.push('/permission-denied');
        }
    }

    exportMenu(){

        if(this.state.selectedRestaurantId == 0){
            alert('A restaurant must be chosen for export');
            return;
        }

        if(this.props.menuExportForm.pizzas == false &&
            this.props.menuExportForm.food_items == false &&
            this.props.menuExportForm.beverages == false &&
            this.props.menuExportForm.specials == false &&
            this.props.menuExportForm.addons == false){
                alert('At least one category must be chosen');
        }else{
            this.props.dispatch(getExportedRestaurantMenu(this.state.selectedRestaurantId, this.state.selectedRestaurant, this.props.menuExportForm));
        }
    }

    updateSelectedRestaurant(e) {
        let selectedRestaurant = {},
            selectedRestaurantId = '';

        // update the selected restaurant from the list of admin restaurants
        for (let i = 0; i < this.props.restaurants.length; i++) {
            if (this.props.restaurants[i].id == e.target.value) {
                selectedRestaurant = this.props.restaurants[i];
                selectedRestaurantId = this.props.restaurants[i].id;
            }
        }

        return this.setState(() => ({ 
            selectedRestaurantId,
            selectedRestaurant,
        }));
    }

    updateFilter(e){

        this.props.dispatch(exportMenuFormUpdate({ prop: 'filter', value: e.target.value }));

        let filteredRestaurants = restaurantSelector(this.props.restaurants, {text: e.target.value})

        let selectedRestaurantRemains = false;

        for (let i = 0; i < filteredRestaurants.length; i++) {
            if (filteredRestaurants[i].id == this.state.selectedRestaurantId) {
                // no change needed
                selectedRestaurantRemains = true;
            }
        }

        if(!selectedRestaurantRemains){
            return this.setState(() => ({ 
                selectedRestaurantId: 0,
                selectedRestaurant: {},
            }));
        }
        
    }

    toggleSpecials(){
        let newValue = !this.props.menuExportForm.specials;
        this.props.dispatch(exportMenuFormUpdate({ prop: 'specials', value: !this.props.menuExportForm.specials }));
        if(newValue){
            //Since specials may contain all other item types, once it's enabled we need to make sure all the others are checked as well
            this.props.dispatch(exportMenuFormUpdate({ prop: 'pizzas', value: true }));
            this.props.dispatch(exportMenuFormUpdate({ prop: 'food_items', value: true }));
            this.props.dispatch(exportMenuFormUpdate({ prop: 'beverages', value: true }));
            this.props.dispatch(exportMenuFormUpdate({ prop: 'addons', value: true }));
        }
    }

    componentDidMount() {
        verifyLogin(this.props.history);
    }

    render() {
        return (
            <LoadScreen
                checkPermissions={this.checkPermissions}
            >
                <div className="form-container">
                    <Grid container>
                        <Grid item xs={12} className="heading-grid">
                            <h1>Export Restaurant Menu</h1>
                        </Grid>
                        <Grid item xs={6} className="restaurant-card-grid">
                            <Grid container>
                                <Grid item xs={6}>
                                    <FormControl>
                                        <TextField 
                                            label="Filter Restaurants" 
                                            variant="outlined" 
                                            value={this.props.filter}
                                            onChange={(e) => this.updateFilter(e)} 
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <br />
                            <Select
                                fullWidth={true}
                                labelId="restaurant"
                                value={this.state.selectedRestaurantId}
                                onChange={this.updateSelectedRestaurant}
                                variant="outlined"
                            >
                                <MenuItem key={'restaurant-empty'} value={0}>Select a Restaurant</MenuItem>
                                {this.props.restaurants.map((restaurant) => {
                                        let addressLabel = (restaurant.locations.length > 1) ? restaurant.locations.length + " locations": restaurant.locations[0].address;
                                        return (<MenuItem key={'adminrestaurant-'+restaurant.id} value={restaurant.id}>{restaurant.name} - {addressLabel}</MenuItem>)
                                    }
                                )}
                            </Select>
                        </Grid>

                        <Grid item xs={6} className="restaurant-card-grid">
                            <FormControlLabel
                                control={
                                    <Checkbox 
                                        checked={this.props.menuExportForm.pizzas}
                                        onChange={(e) => this.props.dispatch(exportMenuFormUpdate({ prop: 'pizzas', value: !this.props.menuExportForm.pizzas }))}
                                        name={"pizzas"}
                                        color="primary"
                                    />
                                }
                                label="Pizzas"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox 
                                        checked={this.props.menuExportForm.food_items}
                                        onChange={(e) => this.props.dispatch(exportMenuFormUpdate({ prop: 'food_items', value: !this.props.menuExportForm.food_items }))}
                                        name={"food_items"}
                                        color="primary"
                                    />
                                }
                                label="Food Items"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox 
                                        checked={this.props.menuExportForm.beverages}
                                        onChange={(e) => this.props.dispatch(exportMenuFormUpdate({ prop: 'beverages', value: !this.props.menuExportForm.beverages }))}
                                        name={"beverages"}
                                        color="primary"
                                    />
                                }
                                label="Beverages"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox 
                                        checked={this.props.menuExportForm.specials}
                                        onChange={(e) => this.toggleSpecials()}
                                        name={"specials"}
                                        color="primary"
                                    />
                                }
                                label="Specials"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox 
                                        checked={this.props.menuExportForm.addons}
                                        onChange={(e) => this.props.dispatch(exportMenuFormUpdate({ prop: 'addons', value: !this.props.menuExportForm.addons }))}
                                        name={"addons"}
                                        color="primary"
                                    />
                                }
                                label="Addons"
                            />
                            <br />
                            <Typography>
                                If selecting specials, take note that all other items will be included as well.
                            </Typography>
                        </Grid>

                        <Grid item xs={12} className="restaurant-card-grid">
                            <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom" onClick={() => { this.exportMenu() }}>
                                Export Menu
                            </Button>
                        </Grid> 
                    </Grid>
                </div>
            </LoadScreen>
        );
    }
};

const mapStateToProps = (state, props) => {
    return {
        loading: state.directories.loading,
        user: state.loggedUser.user,
        menuExportForm: state.menuExportForm,
        filter: state.menuExportForm.filter,
        restaurants: restaurantSelector(state.adminRestaurantLocations.restaurants, {text: state.menuExportForm.filter})
    };
};

export default connect(mapStateToProps)(ExportRestaurantMenu);