import React from 'react';
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { settingsFormAddCustomCrust, settingsFormRemoveCustomCrust } from '../../../../actions/menu/pizzas/settingsForm';

import SettingToggle from '../../../common/SettingToggle';

class CustomPizzaCrusts extends React.Component {

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.isCrustSet = this.isCrustSet.bind(this);
    }

    handleChange(e, crust_id) {
        if (e.target.checked) {
            this.props.dispatch(settingsFormAddCustomCrust(crust_id));
        }
        else {
            this.props.dispatch(settingsFormRemoveCustomCrust(crust_id));
        }
    }

    isCrustSet(crust_id) {

        for (let i = 0; i < this.props.settingsForm.customPizzaCrusts.length; i++) {
            if (this.props.settingsForm.customPizzaCrusts[i].crust_id == crust_id) {
                return true;
            }
        }

        return false;
    }
    
    render() {
        return (
            <div>
                <Typography className="modal-description">
                    Please select the crusts that can come with the custom pizza.
                </Typography>

                <Grid container>
                    {this.props.settings.crusts.map((crust, index) => (
                        <Grid key={'crust-'+index} item xs={3} className="form-grid">
                            <SettingToggle
                                name={crust.name}
                                value={this.isCrustSet(crust.id)}
                                handleChange={(e) => this.handleChange(e, crust.id)}
                            />
                        </Grid>
                    ))}
                </Grid>
            </div>
        );
    }
};

const mapStateToProps = (state, props) => {
    return {
        settingsForm: state.pizzaSettingsForm,
        settings: state.pizzas.settings
    };
};

export default connect(mapStateToProps)(CustomPizzaCrusts);