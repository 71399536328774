// the default items that we can possibly filter by
const categoriesDefaultState = {
    loading: true,
    categories: [],
    filter: ''
};

export default (state = categoriesDefaultState, action) => {
    switch (action.type) {
        case 'SET_CATEGORIES':
            return {
                ...state,
                loading: false,
                categories: action.categories,
                filter: ''
            };

        case 'SET_CATEGORY_FILTER':
            return {
                ...state,
                filter: action.text
            };

        case 'SET_CATEGORIES_LOADING':
            return {
                ...state,
                loading: true
            };
            
        case 'RESET_CATEGORIES':
            return categoriesDefaultState;

        default:
            return state;
    }
};