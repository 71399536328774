import React, {Fragment} from 'react';
import Moment from 'react-moment';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import FlashAuto from '@material-ui/icons/FlashAuto';
import Icon from '@material-ui/core/Icon';

class PromoCodesList extends React.Component {

    constructor(props) {
        super(props);
    }

    formatAdminPromoCodeDetails(code) {

        if (code.total_type == 'PERCENTAGE') {
            return code.total + '% off';
        } else if (code.total_type == 'FIXED') {
            return '$'+code.total+' off';
        } else {
            return '$'+code.total+' off';
        }
    }

    formatPromoCodeDetails(code) {
        if (code.type == 'FREE DELIVERY') {
            return 'Free Delivery';
        } else if (code.type == 'PERCENTAGE') {
            return code.total + '% off';
        } else {
            return '$'+code.total+' off';
        }
    }

    getPromoUsageDetails(code){
        let content = '';
        if (code.total_avail == 'ALWAYS') {
            if(code.max_uses == 0){
                content = <Typography>Always available</Typography>;
            }else{
                content = <><Typography>Always available</Typography>
                            <Typography display="block">{code.remaining_uses}/{code.max_uses} remaining</Typography>
                        </>;
            }
        } else if (code.total_avail == 'LIMITED DATE' && !code.start_time && code.end_time) {
            content = <Typography>Continued Use - Expires <Moment format="MMM DD, YYYY">
                    {code.end_time}
                </Moment> </Typography>;
        } else if (code.total_avail == 'LIMITED DATE' && code.start_time && code.end_time) {
            let extra = (code.max_uses > 0) ? 'Usage: ' + code.remaining_uses + "/" + code.max_uses: '';
            content = <><Typography>Active <Moment format="MMM DD, YYYY">
                    {code.start_time}
                </Moment> — <Moment format="MMM DD, YYYY">
                    {code.end_time}
                </Moment></Typography>
                <Typography display="block">{extra}</Typography></>;
        } else {
            if(code.max_uses == 1){
                content = <Typography>One-time only</Typography>;
            }else{
                content = <Typography>Limited Uses - {code.max_uses} Max </Typography>;
            }
        }

        let dayAvailability = [];
        for (let [key, value] of Object.entries(code.valid_days)) {
            let formattedKey = key[0].toUpperCase();
            let activeClasses = (value) ? 'activeDayIndicator dayIndicator': 'inactiveDayIndicator dayIndicator';
            let fragment = <Fragment><span class={activeClasses}>{formattedKey}</span></Fragment>;
            dayAvailability.push(fragment);
        }
        
        if(code.auto_apply){
            let fragment = <Fragment><FlashAuto /></Fragment>;
            dayAvailability.push(fragment);
        }

        return  <Grid container>
                    <Grid item direction={"column"}>
                        {<>
                            {content}
                            {dayAvailability}
                        </>}
                    </Grid>
                </Grid>;
    }

    getTargetIcon(code){
        if (code.total_target == 'DELIVERY') {
            return <Icon className="fa fa-shipping-fast wideIcon" />;
        } else {
            return <Icon className="fa fa-shopping-cart wideIcon" />;
        }
    }

    render() {
        return (
            <div>
                {this.props.locationForm.promoCodes.length <= 0 && (
                    <Typography className="modal-description">No promo codes available.</Typography>
                )}

                {this.props.locationForm.promoCodes.length > 0 && (
                    <Grid container>
                        <Grid item xs={12} className="sortable-choice-list">
                            {this.props.locationForm.promoCodes.map((item, index) => {
                                item.index = index; // retaining original item index is important
                                return item;
                            // }).filter((item, index) => { // These will be disabled in the DB and new ones cannot be created
                            //     return (item.type != "FREE DELIVERY");
                            }).sort((a,b) => {
                                return a.admin_created > b.admin_created ? 1 : -1;
                            }).map((item, index) => (
                                <div key={"settingssize-"+index}>
                                        {
                                            ((item && item.admin_created) ? item.admin_created : 0) == 0 && (
                                                <Grid key={"item-"+item.index} className="sortable-menu-item" container>
                                                    <Grid item xs={6} className="list-name">
                                                        <Typography>{item.code} - {this.formatPromoCodeDetails(item)}</Typography>
                                                    </Grid>
                                                    <Grid item xs={4} className="list-name">
                                                        {this.getPromoUsageDetails(item)}
                                                    </Grid>
                                                    {/* <Grid item xs={1} className="list-name">
                                                        {this.getTargetIcon(item)}
                                                        This may be re-added in the future if we allow delivery promos for users
                                                    </Grid> */}
                                                    <Grid item xs={1} className="list-sort">
                                                        <Button fullWidth={true} color="primary" onClick={() => this.props.handleUpdatePromoCodeView(item, item.index)}>
                                                            <EditIcon />
                                                        </Button>
                                                    </Grid>
                                                    <Grid item xs={1} className="list-sort">
                                                        <Button fullWidth={true} color="secondary" onClick={() => this.props.handleRemovePromoCode(item.index)}>
                                                            <DeleteIcon />
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            )
                                        }

                                        {
                                            ((item && item.admin_created) ? item.admin_created : 0) == 1 && (
                                                <Grid key={"item-"+item.index} className="sortable-menu-item" container>
                                                    <Grid item xs={5} className="list-name">
                                                        <Typography>{item.code} - {this.formatAdminPromoCodeDetails(item)}</Typography>
                                                    </Grid>
                                                    <Grid item xs={4} className="list-name">
                                                        {this.getPromoUsageDetails(item)}
                                                    </Grid>
                                                    <Grid item xs={1} className="list-name">
                                                        {this.getTargetIcon(item)}
                                                    </Grid>
                                                    <Grid item xs={2} className="list-sort">
                                                        <Typography>(Admin-created code)</Typography>
                                                    </Grid>
                                                </Grid>
                                            )
                                        }

                                </div>
                            ))}
                        </Grid>
                    </Grid>
                )}
                <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom" onClick={this.props.handleAddPromoCodeView}>
                    New Promo Code
                </Button>
            </div>
        );
    }
};

const mapStateToProps = (state, props) => {
    return {
        locationForm: state.locationForm
    };
};

export default connect(mapStateToProps)(PromoCodesList);