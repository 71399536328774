import axios from 'axios';
import { API_URL } from '../../config/constants';
import { userManagementFormSuccess, searchUsersByEmail } from '../../actions/superuser/userManagementForm';
import { closeUserCreate } from '../../actions/modals'; 

export const addUserFormUpdate = ({ prop, value }) => {

    return {
        type: 'ADD_USER_FORM_UPDATE',
        directory: { prop, value }
    };
};

export const addUserFormError = (message) => {

    return {
        type: 'ADD_USER_FORM_ERROR',
        message
    };
};

export const addUserFormSuccess = (message) => {

    return {
        type: 'ADD_USER_FORM_SUCCESS',
        message
    };
};

export const addUserUpdate = (user) => {

    return {
        type: 'ADD_USER_LIST_UPDATE',
        user
    };
};

export const addUserFormReset = () => {
    
    return {
        type: 'ADD_USER_FORM_RESET',
    };
}

export const setAddUserLoading = () => {
    
    return {
        type: 'ADD_USER_FORM_LOADING',
    };
}

export const createUser = (data) => {
    
    return (dispatch) => {

        dispatch(setAddUserLoading());

        return axios.post(API_URL+'/api/users', data).then(function(res) {
            if (res.data.data.status == 'success') {

                // set the app centers, reset the form, and close the modal
                dispatch(addUserFormSuccess('User Created!'));
                dispatch(userManagementFormSuccess('User Created!'));
                dispatch(addUserFormUpdate({prop: 'user', value: res.data.data.user}));
                dispatch(closeUserCreate());
                dispatch(searchUsersByEmail(res.data.data.user.email));
            }
            else {
                dispatch(addUserFormError(res.data.message));
            }
            dispatch(addUserFormUpdate({prop: 'loading', value: false}));
        })
        .catch(function(err) {
            console.log('err');
            console.log(err);

            dispatch(addUserFormUpdate({prop: 'loading', value: false}));

            if(err.response.data && err.response.data.status){
                dispatch(addUserFormError(err.response.data.status));
            }else{
                dispatch(addUserFormError("We're sorry, but our system has encountered an issue, to which we have been notified."));
            }

            // token is likely invalid; remove it and prompt the user to login again
            if (err.response.status === 401) {
                removeAuthToken(dispatch);
            }
            
        });
    };
};