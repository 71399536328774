const promoCodeFormDefaultState = {
    code: '',
    total_target: 'SUBTOTAL',//'SUBTOTAL',
    total_type: 'PERCENTAGE',//'PERCENTAGE',
    total_avail: 'ALWAYS',//'ALWAYS',
    total_threshold: 'NO_THRESHOLD', // NO_THRESHOLD, MAX_DISCOUNT_ALLOWED, MINIMUM_TOTAL_SPENT
    threshold_disable: false, // because total_target defaults to delivery we need to start this off disabled
    threshold_limit: 0.00,
    threshold_limit_text: 'Limit',
    auto_apply: false,
    show_on_listing: false,
    total: 0.00,
    max_uses: 0,
    start_time: null,
    end_time: null,
    deleted: false,
    admin_created: 0,
    index: null,
    loading: false,
    message: '',
    error: '',
    valid_days: {
        sun: true,
        mon: true,
        tue: true,
        wed: true,
        thu: true,
        fri: true,
        sat: true
    },
};

export default (state = promoCodeFormDefaultState, action) => {
    
    switch (action.type) {

        case 'PROMO_CODE_FORM_UPDATE':
            return {
                ...state,
                [action.promoCode.prop]: action.promoCode.value
            };

        case 'PROMO_CODE_REST_FORM_UPDATE_VALID_DAYS':
            return {
                ...state,
                valid_days: 
                    {
                        ...state.valid_days,
                        [action.promoCode.prop]: action.promoCode.value
                    }
            };

        case 'PROMO_CODE_FORM_ERROR':
            return {
                ...state,
                message: '',
                error: action.message
            }

        case 'PROMO_CODE_FORM_SUCCESS':
            return {
                ...state,
                error: '',
                message: action.message
            }

        case 'PROMO_CODE_FORM_SET':
            return {
                ...state,
                ...action.promoCode
            };

        case 'PROMO_CODE_FORM_RESET':
            return promoCodeFormDefaultState;

        default:
            return state;
    }
};