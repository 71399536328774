import React from 'react';
import ImageUploader from "react-images-upload";
import { connect } from 'react-redux';

import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { infoFormUpdate } from '../../../actions/restaurant/infoForm';
import { connectPayment, disconnectPayment } from '../../../actions/restaurants';
import { API_URL } from '../../../config/constants';

import no_image_available from '../../../assets/images/no_image_available.jpg';

import checkPermissions from '../../../utils/checkUserPermissions';

class GeneralInfo extends React.Component {

    constructor(props) {
        super(props);
        this.state = { pictures: [] };
        this.onLogoDrop = this.onLogoDrop.bind(this);
        this.onFeatureImageDrop = this.onFeatureImageDrop.bind(this);
        this.handlePaymentConnect = this.handlePaymentConnect.bind(this);
        this.handlePaymentDisconnect = this.handlePaymentDisconnect.bind(this);
    }
    
    onLogoDrop(pictureFiles, pictureDataURLs) {
        this.props.dispatch(infoFormUpdate({ prop: 'logoImage', value: pictureFiles[0] }));
    }

    onFeatureImageDrop(pictureFiles, pictureDataURLs) {
        this.props.dispatch(infoFormUpdate({ prop: 'featureImage', value: pictureFiles[0] }));
    }

    handlePaymentConnect() {
        this.props.dispatch(connectPayment(this.props.restaurant));
    }

    handlePaymentDisconnect() {
        if (confirm("Are you sure you want to disconnect your payment? This cannot be undone.")) { 
            this.props.dispatch(disconnectPayment(this.props.restaurant_id));
        }
    }

    renderPaymentConnectButton() {
        if (this.props.infoForm.online_payment_status == 'ENABLED') {

            // the payment status is currently disabled; render the disconnect button
            return (
                <Grid item xs={6} className="form-grid">
                    <Button 
                        variant="contained" 
                        fullWidth={true} 
                        size={'large'} 
                        color="secondary" 
                        className="button-sec" 
                        onClick={this.handlePaymentDisconnect}
                    >
                        Disconnect
                    </Button>
                </Grid>
            );
        } else {

            return (
                <Grid item xs={6} className="form-grid">
                    <Button 
                        variant="contained" 
                        fullWidth={true} 
                        size={'large'} 
                        color="primary" 
                        className="button-prim" 
                        onClick={this.handlePaymentConnect}
                    >
                        Connect
                    </Button>
                </Grid>
            );
        }
    }
    
    render() {
        return (
            <div>
                {(this.props.loading) && (
                    <div className="page-loading">
                        <CircularProgress size={100} />
                    </div>
                )}
                
                {(!this.props.loading && (!this.props.scope || this.props.scope == 'general')) && (
                    <div>
                        <InputLabel>Restaurant Name</InputLabel>
                        <TextField
                            label="Name"
                            className="modal-input"
                            fullWidth={true}
                            value={this.props.infoForm.name}
                            onChange={(e) => { this.props.dispatch(infoFormUpdate({ prop: 'name', value: e.target.value })); }}
                            margin="normal"
                            variant="outlined"
                        />

                        <Typography className="modal-description" variant="h2">
                            Images
                        </Typography>

                        <Grid container>
                            <Grid item xs={6} className="form-grid grid-center form-grid-mb">
                                <ImageUploader
                                    withIcon={false}
                                    withPreview={true}
                                    buttonText="Choose a Logo"
                                    withLabel={false}
                                    onChange={this.onLogoDrop}
                                    imgExtension={[".jpg", ".jpeg", ".gif", ".png", ".gif"]}
                                    maxFileSize={5242880}
                                    singleImage={true}
                                />

                                <div className="form-image-container">
                                    {(!this.props.infoForm.logoImage || !this.props.infoForm.logoImage.name) && this.props.infoForm.logo && (
                                        <img src={API_URL+'/'+this.props.infoForm.logo+'?'+Date.now()} className="form-image" />
                                    )}

                                    {(!this.props.infoForm.logoImage || !this.props.infoForm.logoImage.name) && !this.props.infoForm.logo && (
                                        <img src={no_image_available} className="form-image" />
                                    )}
                                </div>
                            </Grid>

                            <Grid item xs={6} className="form-grid form-grid-mb">
                                <ImageUploader
                                    withIcon={false}
                                    withLabel={false}
                                    withPreview={true}
                                    buttonText="Choose a Feature Image"
                                    onChange={this.onFeatureImageDrop}
                                    imgExtension={[".jpg", ".jpeg", ".gif", ".png", ".gif"]}
                                    maxFileSize={5242880}
                                    singleImage={true}
                                />

                                <div className="form-image-container">
                                    {(!this.props.infoForm.featureImage || !this.props.infoForm.featureImage.name) && this.props.infoForm.feature_image && (
                                        <img src={API_URL+'/'+this.props.infoForm.feature_image+'?'+Date.now()} className="form-image" />
                                    )}

                                    {(!this.props.infoForm.featureImage || !this.props.infoForm.featureImage.name) && !this.props.infoForm.feature_image && (
                                        <img src={no_image_available} className="form-image" />
                                    )}
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                )}

                {(!this.props.loading && this.props.curUser && checkPermissions(this.props.curUser, 'superuser')) && (
                    <div>
                        {(!this.props.scope || this.props.scope == 'charges') && (
                            <div>
                                <Typography className="modal-description" variant="h2">
                                    Charges
                                </Typography>

                                <Grid container>
                                    <Grid item xs={4} className="form-grid form-grid-mb">
                                        <InputLabel className="input-label-mb">Standard Charge Type</InputLabel>
                                        <Select
                                            fullWidth={true}
                                            label="Type"
                                            margin="none"
                                            variant="outlined"
                                            value={this.props.infoForm.standard_charge_type}
                                            onChange={(e) => { this.props.dispatch(infoFormUpdate({ prop: 'standard_charge_type', value: e.target.value })); }}
                                        >
                                            <MenuItem key={'percentage'} value={'percentage'}>Percentage (%)</MenuItem>
                                            <MenuItem key={'fixed'} value={'fixed'}>Fixed Amount ($)</MenuItem>
                                        </Select>
                                    </Grid>
                                    <Grid item xs={4} className="form-grid form-grid-mb">
                                        <InputLabel>Standard Charge Amount</InputLabel>
                                        <TextField
                                            label="Amount"
                                            className="modal-input"
                                            fullWidth={true}
                                            value={this.props.infoForm.standard_charge_amount}
                                            onChange={(e) => { this.props.dispatch(infoFormUpdate({ prop: 'standard_charge_amount', value: e.target.value })); }}
                                            onBlur={(e) => { this.props.dispatch(infoFormUpdate({ prop: 'standard_charge_amount_blur', value: e.target.value })); }}
                                            margin="normal"
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={4} className="form-grid form-grid-mb">
                                        <InputLabel>Additional Charge/Processing Fee</InputLabel>
                                        <TextField
                                            label="Amount"
                                            className="modal-input"
                                            fullWidth={true}
                                            value={this.props.infoForm.additional_charge}
                                            onChange={(e) => { this.props.dispatch(infoFormUpdate({ prop: 'additional_charge', value: e.target.value })); }}
                                            onBlur={(e) => { this.props.dispatch(infoFormUpdate({ prop: 'additional_charge_blur', value: e.target.value })); }}
                                            margin="normal"
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={4} className="form-grid form-grid-mb">
                                        <InputLabel>Whitelabel Charges</InputLabel>
                                        <FormControlLabel
                                            control={
                                            <Checkbox
                                                checked={this.props.infoForm.whitelabel_enabled}
                                                onChange={(e) => { this.props.dispatch(infoFormUpdate({ prop: 'whitelabel_enabled', value: !this.props.infoForm.whitelabel_enabled })); }}
                                                name={"Enable Whitelabel Charges?"}
                                                color="primary"
                                            />
                                            }
                                            label={"Enable Whitelabel Charges?"}
                                            className="switch-window"
                                        />
                                    </Grid>
                                    <Grid item xs={4} className="form-grid form-grid-mb">
                                        <InputLabel className="input-label-mb">Whitelabel Charge Type</InputLabel>
                                        <Select
                                            fullWidth={true}
                                            label="Type"
                                            margin="none"
                                            variant="outlined"
                                            disabled={!this.props.infoForm.whitelabel_enabled}
                                            value={this.props.infoForm.whitelabel_charge_type}
                                            onChange={(e) => { this.props.dispatch(infoFormUpdate({ prop: 'whitelabel_charge_type', value: e.target.value })); }}
                                        >
                                            <MenuItem key={'percentage'} value={'percentage'}>Percentage (%)</MenuItem>
                                            <MenuItem key={'fixed'} value={'fixed'}>Fixed Amount ($)</MenuItem>
                                        </Select>
                                    </Grid>
                                    <Grid item xs={4} className="form-grid form-grid-mb">
                                        <InputLabel>Whitelabel Charge Amount</InputLabel>
                                        <TextField
                                            label="Amount"
                                            className="modal-input"
                                            fullWidth={true}
                                            disabled={!this.props.infoForm.whitelabel_enabled}
                                            value={this.props.infoForm.whitelabel_charge_amount}
                                            onChange={(e) => { this.props.dispatch(infoFormUpdate({ prop: 'whitelabel_charge_amount', value: e.target.value })); }}
                                            margin="normal"
                                            variant="outlined"
                                        />
                                    </Grid>

                                    <Grid item xs={4} className="form-grid form-grid-mb">
                                        <InputLabel>Service Fee</InputLabel>
                                        <FormControlLabel
                                            control={
                                            <Checkbox
                                                checked={this.props.infoForm.fuel_surcharge_enabled}
                                                onChange={(e) => { this.props.dispatch(infoFormUpdate({ prop: 'fuel_surcharge_enabled', value: !this.props.infoForm.fuel_surcharge_enabled })); }}
                                                name={"Add Service Fee?"}
                                                color="primary"
                                            />
                                            }
                                            label={"Add Service Fee?"}
                                            className="switch-window"
                                        />
                                    </Grid>
                                    <Grid item xs={4} className="form-grid form-grid-mb">
                                        <InputLabel>Service Fee Amount</InputLabel>
                                        <TextField
                                            label="Amount"
                                            className="modal-input"
                                            fullWidth={true}
                                            disabled={!this.props.infoForm.fuel_surcharge_enabled}
                                            value={this.props.infoForm.fuel_surcharge_amount}
                                            onChange={(e) => { this.props.dispatch(infoFormUpdate({ prop: 'fuel_surcharge_amount', value: e.target.value })); }}
                                            margin="normal"
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={4} className="form-grid form-grid-mb">
                                    </Grid>

                                    <Grid item xs={3} className="form-grid form-grid-mb">
                                        <InputLabel>Internal Charges</InputLabel>
                                        <FormControlLabel
                                            control={
                                            <Checkbox
                                                checked={this.props.infoForm.internal_enabled}
                                                onChange={(e) => { this.props.dispatch(infoFormUpdate({ prop: 'internal_enabled', value: !this.props.infoForm.internal_enabled })); }}
                                                name={"Enable Internal Charges?"}
                                                color="primary"
                                            />
                                            }
                                            label={"Enable Internal Charges?"}
                                            className="switch-window"
                                        />
                                    </Grid>
                                    <Grid item xs={3} className="form-grid form-grid-mb">
                                        <InputLabel className="input-label-mb">Internal Charge Type</InputLabel>
                                        <Select
                                            fullWidth={true}
                                            label="Type"
                                            margin="none"
                                            variant="outlined"
                                            disabled={!this.props.infoForm.internal_enabled}
                                            value={this.props.infoForm.internal_charge_type}
                                            onChange={(e) => { this.props.dispatch(infoFormUpdate({ prop: 'internal_charge_type', value: e.target.value })); }}
                                        >
                                            <MenuItem key={'percentage'} value={'percentage'}>Percentage (%)</MenuItem>
                                            <MenuItem key={'fixed'} value={'fixed'}>Fixed Amount ($)</MenuItem>
                                        </Select>
                                    </Grid>
                                    <Grid item xs={3} className="form-grid form-grid-mb">
                                        <InputLabel>Internal Charge Amount</InputLabel>
                                        <TextField
                                            label="Amount"
                                            className="modal-input"
                                            fullWidth={true}
                                            disabled={!this.props.infoForm.internal_enabled}
                                            value={this.props.infoForm.internal_charge_amount}
                                            onChange={(e) => { this.props.dispatch(infoFormUpdate({ prop: 'internal_charge_amount', value: e.target.value })); }}
                                            margin="normal"
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={3} className="form-grid form-grid-mb">
                                        <InputLabel className="input-label-mb">Internal Charge Target</InputLabel>
                                        <Select
                                            fullWidth={true}
                                            label="Type"
                                            margin="none"
                                            variant="outlined"
                                            disabled={!this.props.infoForm.internal_enabled}
                                            value={this.props.infoForm.internal_charge_target}
                                            onChange={(e) => { this.props.dispatch(infoFormUpdate({ prop: 'internal_charge_target', value: e.target.value })); }}
                                        >
                                            <MenuItem key={'delivery'} value={'delivery'}>Delivery</MenuItem>
                                            <MenuItem key={'pickup'} value={'pickup'}>Pick Up</MenuItem>
                                            <MenuItem key={'both'} value={'both'}>Both</MenuItem>
                                        </Select>
                                    </Grid>
                                    <Grid item xs={12} className="form-grid form-grid-mb">
                                        <InputLabel className="input-label-mb">Comment (Optional, currently used for bookkeeping in case of disputes) </InputLabel>
                                        <TextField
                                            label="Comment"
                                            className="modal-input"
                                            fullWidth={true}
                                            multiline={true}
                                            minRows={3}
                                            disabled={!this.props.infoForm.internal_enabled}
                                            value={this.props.infoForm.internal_charge_comment}
                                            onChange={(e) => { this.props.dispatch(infoFormUpdate({ prop: 'internal_charge_comment', value: e.target.value })); }}
                                            margin="normal"
                                            variant="outlined"
                                        />
                                    </Grid>

                                </Grid>
                            </div>
                        )}

                        {(!this.props.scope || this.props.scope == 'loyalty') && (
                            <div>
                                <Typography className="modal-description" variant="h2">
                                    Loyalty Points
                                </Typography>

                                <Grid container>
                                    <Grid item xs={4} className="form-grid form-grid-mb">
                                        <InputLabel>Loyalty Rewards Points</InputLabel>
                                        <FormControlLabel
                                            control={
                                            <Checkbox
                                                checked={this.props.infoForm.loyalty_points_enabled}
                                                onChange={(e) => { this.props.dispatch(infoFormUpdate({ prop: 'loyalty_points_enabled', value: !this.props.infoForm.loyalty_points_enabled })); }}
                                                name={"Enable Loyalty Rewards?"}
                                                color="primary"
                                            />
                                            }
                                            label={"Enable Loyalty Rewards?"}
                                            className="switch-window"
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        )}

                        {(!this.props.scope || this.props.scope == 'custompartners') && (
                            <div>
                                <Typography className="modal-description" variant="h2">
                                    Custom Delivery Partners
                                </Typography>

                                <Grid container>
                                    <Grid item xs={4} className="form-grid form-grid-mb">
                                        <InputLabel className="input-label-mb">Delivery Partner</InputLabel>
                                        <Select
                                            fullWidth={true}
                                            label="Type"
                                            margin="none"
                                            variant="outlined"
                                            value={this.props.infoForm.custom_delivery}
                                            onChange={(e) => { this.props.dispatch(infoFormUpdate({ prop: 'custom_delivery', value: e.target.value })); }}
                                        >
                                            <MenuItem key={'none'} value={'none'}>None</MenuItem>
                                            <MenuItem key={'scotiasoftware'} value={'scotiasoftware'}>Scotia Software</MenuItem>
                                        </Select>
                                    </Grid>
                                </Grid>
                            </div>
                        )}
                    </div>
                )}

                {(this.props.scope && this.props.scope == 'payment') && (
                    <div>
                        <Typography className="modal-description" variant="h2">
                            Payment Information
                        </Typography>

                        <Grid container>
                            <Grid item xs={12} className="form-grid form-grid-mb">
                                <Typography className="modal-description" variant="h4">
                                    Status: {this.props.infoForm.online_payment_status}
                                </Typography>
                                
                                {this.props.infoForm.online_payment_status == 'ENABLED' && (
                                    <Typography className="modal-description">
                                        Publishable Key: {this.props.infoForm.publishable_key}
                                    </Typography>   
                                )}
                            </Grid>
                            {this.renderPaymentConnectButton()}
                        </Grid>
                    </div>
                )}
            </div>
        );
    }
};

const mapStateToProps = (state, props) => {
    return {
        loading: state.restaurantInfoForm.loading,
        infoForm: state.restaurantInfoForm,
        curUser: state.loggedUser.user,
        restaurant: state.selectedRestaurant.restaurant
    };
};

export default connect(mapStateToProps)(GeneralInfo);