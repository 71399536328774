import React from 'react';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import SearchIcon from '@material-ui/icons/Search';

import LoadScreen from '../common/LoadScreen';
import verifyLogin from '../../utils/verifyLogin';
import { 
    loadSupportReport,
    exportSupportOrdersReportCSV,
    setOrdersReportLoading,
    setOrdersSummarySearchLoading,
} from '../../actions/reports/orders';
import { searchFormReset } from '../../actions/reports/searchForm';
import { openOrderDetails, closeOrderDetails, openSearchReports, closeSearchReports } from '../../actions/modals';
import { loadPageData } from '../../actions/auth';

import OrdersList from './common/OrdersList';
import OrderDetails from './modal/OrderDetails';
import SearchOrders from '../common/SearchOrders';

import checkPermissions from '../../utils/checkUserPermissions';

import "jspdf-autotable";
import generatePDF from "./common/generatePDF";

class SupportReportsPage extends React.Component {

    constructor(props) {
        super(props);

        this.toggleViewOrder = this.toggleViewOrder.bind(this);
        this.toggleSearchModal = this.toggleSearchModal.bind(this);
        this.submitSearchOrders = this.submitSearchOrders.bind(this);
        this.checkPermissions = this.checkPermissions.bind(this);
        this.renderSummary = this.renderSummary.bind(this);

        this.state = {
            sidebarMenuOpen: false,
            selectedOrder: {},
            searchedData: this.props.searchForm,
        };
    }

    componentDidMount() {
        verifyLogin(this.props.history);
    }

    checkPermissions(){
        if (checkPermissions(this.props.user, 'restaurant_staff', this.props.match.params.id)) {
            this.props.dispatch(loadPageData(this.props.match.params));
            this.props.dispatch(loadSupportReport());
        } else {
            this.props.history.push('/permission-denied');
        }
    }

    componentDidUpdate() {
        verifyLogin(this.props.history);
    }

    toggleSearchModal(isOpen) {
        if (isOpen) {
            this.props.dispatch(searchFormReset());
            this.props.dispatch(openSearchReports());
        }
        else {
            this.props.dispatch(closeSearchReports());
        }
    }

    toggleViewOrder(order, isOpen) {
        if (isOpen) {
            this.setState(() => ({
                selectedOrder: order
            }));

            this.props.dispatch(openOrderDetails());
        }
        else {
            this.props.dispatch(closeOrderDetails());
        }
    }

    submitSearchOrders(searchData) {
        let startTime = searchData.start_time;
        let endTime = searchData.end_time;

        if(searchData.order_id == ''){ // If Order ID is provided all other variables are ignored server side
            
            if(searchData.any_time == 0 && (startTime != '' || endTime != '')){
                //One of the two was entered
                if(startTime == '' || endTime == ''){
                    alert('You must enter both a start and end time if searching by date!');
                    return;
                }
            }
    
            if(searchData.any_time == 1){
                // If searching for any time we need to make sure we don't get too many orders back from the server
                // Only allow this if the user is searching by address or phone number
                if(searchData.address == '' && searchData.phone == ''){
                    alert('If searching by any date, then an address or phone number will need to be provided.');
                    return;
                }
            }
        }

        this.state.searchedData = searchData;
        this.props.dispatch(setOrdersSummarySearchLoading());
        this.props.dispatch(closeSearchReports());
        this.props.dispatch((loadSupportReport(searchData)));
    }

    renderSummary(){
        let commasRegex = /\B(?=(\d{3})+(?!\d))/g;
        if(this.props.summary.summaryStartFormatted){
            // this.props.summary.summaryStartFormatted = new Date(this.props.summary.summaryStartFormatted + ' UTC');
            // console.log(this.props.summary.summaryStartFormatted);
            // this.props.summary.summaryStartFormatted.toString();
        }

        return (
            <div>
                <Typography gutterBottom variant="h5" component="h2">
                    Order Summary
                </Typography>
                <div className="summary-line-item">
                    <Typography>
                        Orders Created:
                    </Typography>
                    <Typography>
                        {(this.props.summary.totalCreated) && this.props.summary.totalCreated}
                    </Typography>
                </div>
                <div className="summary-line-item">
                    <Typography>
                        Start Time:
                    </Typography>
                    <Typography>
                        {this.props.summary.summaryStartFormatted && this.props.summary.summaryStartFormatted}
                    </Typography>
                </div>
                <div className="summary-line-item">
                    <Typography>
                        End Time:
                    </Typography>
                    <Typography>
                        {this.props.summary.summaryEndFormatted && this.props.summary.summaryEndFormatted}
                    </Typography>
                </div>
                <div className="summary-line-item">
                    <Typography>
                        Orders Completed:
                    </Typography>
                    <Typography>
                        {this.props.summary.totalCompleted}
                    </Typography>
                </div>
                <div className="summary-line-item">
                    <Typography>
                        Orders Cancelled:
                    </Typography>
                    <Typography>
                        {this.props.summary.totalCancelled}
                    </Typography>
                </div>
                <div className="summary-line-item">
                    <Typography>
                        Paid Online:
                    </Typography>
                    <Typography>
                        {this.props.summary.totalPaidOnline}
                    </Typography>
                </div>
                <div className="summary-line-item">
                    <Typography>
                        Paid In store:
                    </Typography>
                    <Typography>
                        {this.props.summary.totalPaidInStore}
                    </Typography>
                </div>
                <div className="summary-line-item">
                    <Typography>
                        Food Revenue:
                    </Typography>
                    <Typography>
                        ${(this.props.summary.totalSubtotal) && this.props.summary.totalSubtotal.toFixed(2).replace(commasRegex, ",")}
                    </Typography>
                </div>
                <div className="summary-line-item red-text">
                    <Typography>
                        Less - Redeemed Loyalty Rewards:
                    </Typography>
                    <Typography>
                        $-{(this.props.summary.totalLoyaltyAmount) && this.props.summary.totalLoyaltyAmount.toFixed(2).replace(commasRegex, ",")}
                    </Typography>
                </div>
                <div className="summary-line-item red-text">
                    <Typography>
                        Less - Promo Codes:
                    </Typography>
                    <Typography>
                        $-{(this.props.summary.totalPromoCodes) && this.props.summary.totalPromoCodes.toFixed(2).replace(commasRegex, ",")}
                    </Typography>
                </div>
                <div className="summary-line-item">
                    <Typography>
                        Net Food Revenue:
                    </Typography>
                    <Typography>
                        ${(this.props.summary.netFoodRevenue) && this.props.summary.netFoodRevenue.toFixed(2).replace(commasRegex, ",")}
                    </Typography>
                </div>
                <div className="summary-line-item">
                    <Typography>
                        Additional Charges:
                    </Typography>
                    <Typography>
                        ${(this.props.summary.additionalCharges) && this.props.summary.additionalCharges.toFixed(2).replace(commasRegex, ",")}
                    </Typography>
                </div>
                <div className="summary-line-item">
                    <Typography>
                        Taxes Collected:
                    </Typography>
                    <Typography>
                        ${(this.props.summary.taxes) && this.props.summary.taxes.toFixed(2).replace(commasRegex, ",")}
                    </Typography>
                </div>
                <div className="summary-line-item bold-text">
                    <Typography className="bold-text">
                        Total Restaurant:
                    </Typography>
                    <Typography className="bold-text">
                        ${(this.props.summary.totalRestaurant) && this.props.summary.totalRestaurant.toFixed(2).replace(commasRegex, ",")}
                    </Typography>
                </div>
                <hr />
                <div className="summary-line-item blue-tint">
                    <Typography>
                        Dispatch Delivery Fees Collected:
                    </Typography>
                    <Typography>
                        ${(this.props.summary.dispatchDeliveryCharges) && (this.props.summary.dispatchDeliveryCharges).toFixed(2).replace(commasRegex, ",")}
                    </Typography>
                </div>
                <div className="summary-line-item blue-tint">
                    <Typography>
                        Dispatch Delivery Tax Collected:
                    </Typography>
                    <Typography>
                        ${(this.props.summary.dispatchDeliveryChargeTax) && (this.props.summary.dispatchDeliveryChargeTax).toFixed(2).replace(commasRegex, ",")}
                    </Typography>
                </div>
                <div className="summary-line-item blue-tint">
                    <Typography>
                        Dispatch Delivery Tips Collected:
                    </Typography>
                    <Typography>
                        ${(this.props.summary.dispatchDeliveryTips) && (this.props.summary.dispatchDeliveryTips).toFixed(2).replace(commasRegex, ",")}
                    </Typography>
                </div>
                <div className="summary-line-item blue-tint">
                    <Typography>
                        Fuel Surcharge:
                    </Typography>
                    <Typography>
                        ${(this.props.summary.fuelSurcharge) && (this.props.summary.fuelSurcharge).toFixed(2).replace(commasRegex, ",")}
                    </Typography>
                </div>
                <div className="summary-line-item blue-tint">
                    <Typography>
                        Fuel Surcharge Tax:
                    </Typography>
                    <Typography>
                        ${(this.props.summary.fuelSurchargeTax) && (this.props.summary.fuelSurchargeTax).toFixed(2).replace(commasRegex, ",")}
                    </Typography>
                </div>
                <div className="summary-line-item green-tint">
                    <Typography>
                        Restaurant Delivery Fees Collected:
                    </Typography>
                    <Typography>
                        ${(this.props.summary.restaurantDeliveryCharges) && (this.props.summary.restaurantDeliveryCharges).toFixed(2).replace(commasRegex, ",")}
                    </Typography>
                </div>
                <div className="summary-line-item green-tint">
                    <Typography>
                        Restaurant Delivery Tax Collected:
                    </Typography>
                    <Typography>
                        ${(this.props.summary.restaurantDeliveryChargeTax) && (this.props.summary.restaurantDeliveryChargeTax).toFixed(2).replace(commasRegex, ",")}
                    </Typography>
                </div>
                <div className="summary-line-item green-tint">
                    <Typography>
                    Restaurant Delivery Tips Collected:
                    </Typography>
                    <Typography>
                        ${(this.props.summary.restaurantDeliveryTips) && (this.props.summary.restaurantDeliveryTips).toFixed(2).replace(commasRegex, ",")}
                    </Typography>
                </div>
                <div className="summary-line-item bold-text">
                    <Typography className="bold-text">
                        Total Collected:
                    </Typography>
                    <Typography className="bold-text">
                        ${(this.props.summary.totalRevenue) && this.props.summary.totalRevenue.toFixed(2).replace(commasRegex, ",")}
                    </Typography>
                </div>
                <hr />
                <div className="summary-line-item red-text">
                    <Typography>
                        Delivery Fees Disbursed:
                    </Typography>
                    <Typography>
                        $-{this.props.summary.deliveryFeesDisbursed && this.props.summary.deliveryFeesDisbursed.toFixed(2).replace(commasRegex, ",")}
                    </Typography>
                </div>
                <div className="summary-line-item red-text">
                    <Typography>
                    Click2Order Platform Fee:
                    </Typography>
                    <Typography>
                        $-{this.props.summary.platformFees && Math.abs(this.props.summary.platformFees).toFixed(2).replace(commasRegex, ",")}
                    </Typography>
                </div>
                <div className="summary-line-item red-text">
                    <Typography>
                    Internal Charge:
                    </Typography>
                    <Typography>
                        $-{this.props.summary.internalCharge && Math.abs(this.props.summary.internalCharge).toFixed(2).replace(commasRegex, ",")}
                    </Typography>
                </div>
                <div className="summary-line-item red-text">
                    <Typography>
                    Taxes Charged to Restaurants on Fees:
                    </Typography>
                    <Typography>
                        $-{this.props.summary.platformFeeTax && this.props.summary.platformFeeTax.toFixed(2).replace(commasRegex, ",")}
                    </Typography>
                </div>
                <div className="summary-line-item red-text">
                    <Typography>
                        Stripe Processing Fee
                    </Typography>
                    <Typography>
                        $-{(this.props.summary.apiCharge && this.props.summary.apiCharge.toFixed(2).replace(commasRegex, ","))}
                    </Typography>
                </div>
                <hr />
                <div className="summary-line-item bold-text">
                    <Typography className="bold-text">
                        Net Deposit:
                    </Typography>
                    <Typography className="bold-text">
                        ${(this.props.summary.netRevenue) && this.props.summary.netRevenue.toFixed(2).replace(commasRegex, ",")}
                    </Typography>
                </div>
            </div>
        )
    }

    renderOrders() {

        if (this.props.loading) {
            return (
                <div className="page-loading">
                    <Grid container>
                        <Grid item xs={12} className="restaurant-card-grid">
                        {this.props.generatingFile && (
                            <div className="form-image-container">
                                <Typography>Hang tight! We're preparing your file and it should be ready shortly!</Typography>    
                            </div>
                        )}
                        {this.props.performingSearch && (
                            <div className="form-image-container">
                                <Typography>Please wait while we perform your search, depending on the volume of orders this may take a few moments.</Typography>    
                            </div>
                        )}
                            <div className="form-image-container">
                                <CircularProgress size={100} />
                            </div>
                        </Grid>
                    </Grid>
                </div>
            );
        }
        else {
            return (
                <OrdersList
                    orders={this.props.orders}
                    toggleViewOrder={this.toggleViewOrder}
                />
            );
        }
    }

    goBackPage() {
        if (this.props.currentPage > 1) {
            let searchForm = this.props.searchForm;
            searchForm.page = this.props.currentPage - 1;
            this.props.dispatch((loadSupportReport(searchForm)));
        }
    }

    goForwardPage() {
        if (this.props.currentPage < this.props.totalPages) {
            let searchForm = this.props.searchForm;
            searchForm.page = this.props.currentPage + 1;
            this.props.dispatch((loadSupportReport(searchForm)));
        }
    }

    exportCSV(){
        let searchForm = this.state.searchedData;
        this.props.dispatch(setOrdersReportLoading());
        this.props.dispatch(exportSupportOrdersReportCSV(searchForm));
    }

    exportPDF(){
        let summaryTitle = this.props.location.address;
        this.props.summary.source = 'support';
        generatePDF(this.props.ordersCompleteList, this.props.summary, false, summaryTitle);
    }
    
    render() {

        return (
            <LoadScreen
                checkPermissions={this.checkPermissions}
            >
                <div className="card-container">
                    <Grid container className="menu-page-header">
                        <Grid item xs={12} className="heading-grid">
                            <h1>Support Orders Report</h1>
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid item xs={4} className="restaurant-card-grid">
                            <Card className="card restaurant-card">
                                <CardActions>
                                    <Button size="small" color="primary" onClick={() => { this.exportPDF() }}>
                                        Generate PDF
                                    </Button>
                                    <Button size="small" color="primary" onClick={() => { this.exportCSV() }}>
                                        Generate CSV
                                    </Button>
                                </CardActions>
                                <CardContent>
                                    {this.renderSummary()}
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={8} className="restaurant-card-grid">
                            <Card className="card restaurant-card">
                                <CardActions>
                                    <Button size="small" color="primary" onClick={() => { this.goBackPage() }}>
                                        &lt;&lt;
                                    </Button>
                                    <Typography>
                                        Page {this.props.currentPage} of {(this.props.totalPages >= 1) ? this.props.totalPages: 1}
                                    </Typography>
                                    <Button size="small" color="primary" onClick={() => { this.goForwardPage() }}>
                                        &gt;&gt;
                                    </Button>
                                </CardActions>
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        Orders
                                    </Typography>
                                    {this.renderOrders()}
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </div>

                <div className="window-fab">
                    <Fab className="btn-fab btn-add" onClick={() => this.toggleSearchModal(true)}>
                        <SearchIcon />
                    </Fab>
                </div>

                <SearchOrders 
                    submitForm={this.submitSearchOrders}
                    source={"support"}
                />

                <OrderDetails
                    order={this.state.selectedOrder}
                />
            </LoadScreen>
        );
    }
};

const mapStateToProps = (state, props) => {
    return {
        loading: state.reportOrders.loading,
        generatingFile: state.reportOrders.generatingFile,
        performingSearch: state.reportOrders.performingSearch,
        restaurant: state.selectedRestaurant.restaurant,
        searchForm: state.reportSearchForm,
        summary: state.reportOrders.orderSummary,
        orders: state.reportOrders.orders,
        location: state.reportOrders.location,
        ordersCompleteList: state.reportOrders.ordersCompleteList,
        currentPage: state.reportOrders.currentPage,
        totalPages: state.reportOrders.totalPages,
        user: state.loggedUser.user,
    };
};

export default connect(mapStateToProps)(SupportReportsPage);