import React from 'react';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import validatePhone from '../../../utils/validatePhone';
import { updateLocation } from '../../../actions/restaurant/locations';
import { locationFormUpdate, locationFormError } from '../../../actions/restaurant/locationForm';
import { closeLocationUpdate } from '../../../actions/modals';

import checkPermissions from '../../../utils/checkUserPermissions';

import CloseIcon from '../../common/modal/CloseIcon';

import Info from './LocationInfo';
import HoursOfOperation from './HoursOfOperation';
import DeliveryCharges from './DeliveryCharges';
import PromoCodes from './PromoCodes';

const daysOfWeek = [
    {
        label: 'Sunday',
        id: 'sun'
    },
    {
        label: 'Monday',
        id: 'mon'
    },
    {
        label: 'Tuesday',
        id: 'tue'
    },
    {
        label: 'Wednesday',
        id: 'wed'
    },
    {
        label: 'Thursday',
        id: 'thu'
    },
    {
        label: 'Friday',
        id: 'fri'
    },
    {
        label: 'Saturday',
        id: 'sat'
    }
];

class UpdateLocation extends React.Component {

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.closeUpdateModal = this.closeUpdateModal.bind(this);
        this.updateLocationInfo = this.updateLocationInfo.bind(this);
        this.updateLocationHoursOfOperation = this.updateLocationHoursOfOperation.bind(this);
        this.updateLocationDeliveryCharges = this.updateLocationDeliveryCharges.bind(this);
        this.updateLocationPromoCodes = this.updateLocationPromoCodes.bind(this);

        this.state = {
            displayView: 0
        };
    }

    handleChange(event, newValue) {
        this.props.dispatch(locationFormUpdate({ prop: 'error', value: '' }));
        this.props.dispatch(locationFormUpdate({ prop: 'message', value: '' }));
        this.setState(() => ({displayView: newValue}))
    }

    closeUpdateModal() {
        this.props.dispatch(closeLocationUpdate());
        this.setState(() => ({displayView: 0}));
    }

    updateLocationInfo() {

        // perform some error checking
        if (this.props.locationForm.address.trim() == "" || !this.props.locationForm.city || this.props.locationForm.city.trim() == "" || !this.props.locationForm.postal || this.props.locationForm.postal.trim() == "") {

            // required info isn't present
            this.props.dispatch(locationFormError("Please enter a location address, city, province and postal code."));
        }
        else if (this.props.locationForm.phone.trim() == "" || !validatePhone(this.props.locationForm.phone) || (this.props.locationForm.phone2 && this.props.locationForm.phone2.trim() != "" && !validatePhone(this.props.locationForm.phone2))) {

            // phone numbers entered aren't valid
            this.props.dispatch(locationFormError("Please ensure that your phone number is entered, or all entered phone numbers are valid."));
        }
        else if (this.props.locationForm.warning_phone && this.props.locationForm.warning_phone.trim() != "" && !validatePhone(this.props.locationForm.warning_phone)) {

            // warning phone isn't valid
            this.props.dispatch(locationFormError("Please ensure that your warning phone number is valid."));
        }
        else {

            this.props.dispatch(locationFormError(""));
            this.props.dispatch(updateLocation(this.props.restaurantId, this.props.location.id, 'info', this.props.locationForm));
        }
    }

    updateLocationHoursOfOperation() {

        let valid = true;
    
        for (let i = 0; i < daysOfWeek.length; i++) {
            if (this.props.locationForm.hoursOfOperation[daysOfWeek[i].id+'_open'] == this.props.locationForm.hoursOfOperation[daysOfWeek[i].id+'_close'] && !this.props.locationForm.hoursOfOperation[daysOfWeek[i].id+'_closed']) {
                valid = false;
                this.props.dispatch(locationFormError("Please ensure that your open date and close date are not the same if your store isn't closed all day."));
            }
        }

        if (valid) {
            this.props.dispatch(locationFormError(""));
            this.props.dispatch(updateLocation(this.props.restaurantId, this.props.location.id, 'hours_of_operation', this.props.locationForm));
        }
    }

    updateLocationDeliveryCharges() {

        let valid = true;
        
        if (this.props.locationForm.delivery_type == 'fixed') {
            // delivery type is fixed, ensure that the fixed distance and total are correct
            if (this.props.locationForm.max_delivery_range <= 0 || this.props.locationForm.delivery_charge < 0) {
                valid = false;
                this.props.dispatch(locationFormError("Please ensure that your delivery range is greater than zero, and your price is valid."));
            }else if(this.props.locationForm.max_delivery_range > 99.999){
                valid = false;
                this.props.dispatch(locationFormError("Please ensure that your delivery range is no more than 99.999."));
            }else if(this.props.locationForm.delivery_charge > 999.99){
                valid = false;
                this.props.dispatch(locationFormError("Please ensure that your delivery charge is no more than 999.99."));
            }
        }
        else if (this.props.locationForm.delivery_type == 'distance') {

            // delivery type is distance, ensure that all entered charges are correct
            for (let i = 0; i < this.props.locationForm.deliveryCharges.length; i++) {

                if (
                    (typeof this.props.locationForm.deliveryCharges[i].max_distance === 'string' && !this.props.locationForm.deliveryCharges[i].max_distance.match(/^\d+(\.\d+)?$/)) || 
                    parseFloat(this.props.locationForm.deliveryCharges[i].max_distance) < 0 || 
                    (typeof this.props.locationForm.deliveryCharges[i].min_total === 'string' && !this.props.locationForm.deliveryCharges[i].min_total.match(/^\d+(\.\d+)?$/)) || 
                    parseFloat(this.props.locationForm.deliveryCharges[i].min_total) < 0 || 
                    (typeof this.props.locationForm.deliveryCharges[i].price === 'string' && !this.props.locationForm.deliveryCharges[i].price.match(/^\d+(\.\d+)?$/)) || 
                    parseFloat(this.props.locationForm.deliveryCharges[i].price) < 0
                ) {
                    valid = false;
                    this.props.dispatch(locationFormError("Please ensure that you have entered a max distance, min order total and price for each delivery charge."));
                }
            }
        }

        if (valid) {
            this.props.dispatch(locationFormError(""));
            this.props.dispatch(updateLocation(this.props.restaurantId, this.props.location.id, 'delivery_charges', this.props.locationForm));
        }
    }

    updateLocationPromoCodes() {

        this.props.dispatch(locationFormError(""));
        this.props.dispatch(updateLocation(this.props.restaurantId, this.props.location.id, 'promo_codes', this.props.locationForm));
    }

    renderUpdateModalActiveTab() {
        if (this.props.loading) {
            return (
                <div className="page-loading">
                    <CircularProgress size={100} />
                </div>
            );
        }
        else {
            switch(this.state.displayView) {
                case 0:
                    return this.renderUpdateModalInfo();

                case 1:
                    return this.renderUpdateModalHoursOfOperation();

                case 2:
                    return this.renderUpdateModalPromoCodes();

                case 3:
                    return this.renderUpdateModalDeliveryCharges();
            }
        }
    }

    renderUpdateFeedback() {
        return (
            <div>
                { this.props.locationForm.error && (
                    <Typography className="modal-error">
                        { this.props.locationForm.error }
                    </Typography>
                )}

                { this.props.locationForm.message && (
                    <Typography className="modal-success">
                        { this.props.locationForm.message }
                    </Typography>
                )}
            </div>
        );
    }

    renderUpdateModalInfo() {
        return (
            <div>
                {this.renderUpdateFeedback()}

                <Info />

                <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom" onClick={this.updateLocationInfo}>
                    Update Location
                </Button>
            </div>
        );
    }

    renderUpdateModalHoursOfOperation() {
        return (
            <div>
                {this.renderUpdateFeedback()}

                <HoursOfOperation
                    selectedLocation={this.props.restaurant}
                />

                <Grid container>
                    <Grid item xs={12}>
                        <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom" onClick={this.updateLocationHoursOfOperation}>
                            Update Location
                        </Button>
                    </Grid>
                </Grid>
            </div>
        );
    }

    renderUpdateModalDeliveryCharges() {
        return (
            <div>
                {this.renderUpdateFeedback()}

                <DeliveryCharges />

                <Grid container>
                    <Grid item xs={12}>
                        <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom" onClick={this.updateLocationDeliveryCharges}>
                            Update Location
                        </Button>
                    </Grid>
                </Grid>          
            </div>
        );
    }

    renderUpdateModalPromoCodes() {
        return (
            <div>
                {this.renderUpdateFeedback()}

                <PromoCodes />

                <Grid container>
                    <Grid item xs={12}>
                        <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom" onClick={this.updateLocationPromoCodes}>
                            Update Location
                        </Button>
                    </Grid>
                </Grid>          
            </div>
        );
    }
    
    render() {
        return (
            <Modal
                aria-labelledby="location-update"
                aria-describedby="location-update"
                open={this.props.updateModalOpen}
                onClose={() => this.closeUpdateModal()}
                className="modal_admin"
                disableBackdropClick
            >
                <div className="modal-member">
                    <Typography variant="h4" id="modal-title">
                        <CloseIcon/>
                    </Typography>
                    <Tabs
                        value={this.state.displayView}
                        onChange={this.handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        centered
                    >
                        <Tab label="General Information" />
                        <Tab label="Hours of Operation" />
                        <Tab label="Promo Codes" />
                        {checkPermissions(this.props.user, 'superuser') && (<Tab label="Delivery Charges" />)}
                    </Tabs>

                    <Typography variant="h2">
                        Update Location: {this.props.location.address}
                    </Typography>

                    {this.renderUpdateModalActiveTab()}
                </div>
            </Modal>
        );
    }
};

const mapStateToProps = (state, props) => {
    return {
        loading: state.locations.loading,
        restaurant: state.selectedRestaurant.restaurant,
        locationForm: state.locationForm,
        location: state.locationForm.selectedLocation,
        updateModalOpen: state.modals.locationUpdate,
        user: state.loggedUser.user,
    };
};

export default connect(mapStateToProps)(UpdateLocation);