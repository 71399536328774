import React from 'react';
import { connect } from 'react-redux';

import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import { userManagementFormUpdate, updateUser, userManagementFormError } from '../../../actions/superuser/userManagementForm';
import { closeUserManagementUpdate } from '../../../actions/modals';  

import CloseIcon from '../../common/modal/CloseIcon';

class UpdateUser extends React.Component {

    constructor(props) {
        super(props);

        this.closeUpdateModal = this.closeUpdateModal.bind(this);
        this.updateUserInfo = this.updateUserInfo.bind(this);

        this.state = {
            displayView: 0
        };
    }

    closeUpdateModal() {
        this.props.dispatch(closeUserManagementUpdate());
    }

    updateUserInfo() {

        // clear the error and attempt to update the user
        this.props.dispatch(userManagementFormError(""));
        this.props.dispatch(updateUser(this.props.user.id, this.props.userManagementForm));
    }

    render() {
        return (
            <Modal
                aria-labelledby="user-management-update"
                aria-describedby="user-management-update"
                open={this.props.updateModalOpen}
                onClose={() => this.closeUpdateModal()}
                className="modal_admin"
                disableBackdropClick
            >
                <div className="modal-member">
                    <Typography variant="h4" id="modal-title">
                        Update {this.props.user.first_name} {this.props.user.last_name}
                        <CloseIcon/>
                    </Typography>

                    <div>
                        { this.props.userManagementForm.error && (
                            <Typography className="modal-error">
                                { this.props.userManagementForm.error }
                            </Typography>
                        )}

                        { this.props.userManagementForm.message && (
                            <Typography className="modal-success">
                                { this.props.userManagementForm.message }
                            </Typography>
                        )}
                    </div>

                    {this.props.loading && (
                        <div className="page-loading">
                            <CircularProgress size={100} />
                        </div>
                    )}

                    {!this.props.loading && (
                        <Grid container>
                            <Grid item xs={12} className="form-grid">
                                <Typography className="modal-description">
                                    Please update the user name, phone number, superuser status, or reset the password.
                                </Typography>
                            </Grid>

                            <Grid item xs={6} className="form-grid">
                                <TextField
                                    label="First Name"
                                    className="modal-input"
                                    fullWidth={true}
                                    value={this.props.userManagementForm.first_name}
                                    onChange={(e) => { this.props.dispatch(userManagementFormUpdate({ prop: 'first_name', value: e.target.value })); }}
                                    margin="normal"
                                />
                            </Grid>

                            <Grid item xs={6} className="form-grid">
                                <TextField
                                    label="Last Name"
                                    className="modal-input"
                                    fullWidth={true}
                                    value={this.props.userManagementForm.last_name}
                                    onChange={(e) => { this.props.dispatch(userManagementFormUpdate({ prop: 'last_name', value: e.target.value })); }}
                                    margin="normal"
                                />
                            </Grid>

                            <Grid item xs={6} className="form-grid">
                                <TextField
                                    label="Phone"
                                    className="modal-input"
                                    fullWidth={true}
                                    value={this.props.userManagementForm.phone}
                                    onChange={(e) => { this.props.dispatch(userManagementFormUpdate({ prop: 'phone', value: e.target.value })); }}
                                    margin="normal"
                                />
                            </Grid>

                            <Grid item xs={3} className="form-grid">
                                <FormGroup row>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={this.props.userManagementForm.superuser}
                                                onChange={(e) => { this.props.dispatch(userManagementFormUpdate({ prop: 'superuser', value: !this.props.userManagementForm.superuser })); }}
                                                name="superuser"
                                                color="primary"
                                            />
                                        }
                                        label="Superuser"
                                        labelPlacement="top"
                                    />
                                </FormGroup>
                            </Grid>  

                            <Grid item xs={3} className="form-grid">
                                <FormGroup row>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={this.props.userManagementForm.support}
                                                onChange={(e) => { this.props.dispatch(userManagementFormUpdate({ prop: 'support', value: !this.props.userManagementForm.support })); }}
                                                name="support"
                                                color="primary"
                                            />
                                        }
                                        label="Support"
                                        labelPlacement="top"
                                    />
                                </FormGroup>
                            </Grid>   

                            <Grid item xs={12} className="form-grid">
                                <TextField
                                    label="Password"
                                    className="modal-input"
                                    fullWidth={true}
                                    value={this.props.userManagementForm.new_password}
                                    onChange={(e) => { this.props.dispatch(userManagementFormUpdate({ prop: 'new_password', value: e.target.value })); }}
                                    margin="normal"
                                    type="password"
                                />
                            </Grid>

                            <Grid item xs={12} className="form-grid">
                                <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom" onClick={this.updateUserInfo}>
                                    Update User
                                </Button>   
                            </Grid>
                        </Grid> 
                    )}  
                </div>
            </Modal>
        );
    }
};

const mapStateToProps = (state, props) => {
    return {
        loading: state.userManagementForm.loading,
        userManagementForm: state.userManagementForm,
        updateModalOpen: state.modals.userManagementUpdate,
    };
};

export default connect(mapStateToProps)(UpdateUser);