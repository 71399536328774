import React from 'react';
import { connect } from 'react-redux';

import { updateAppDirectory } from '../../../actions/superuser/appDirectories/directories';
import { appDirectoryFormError } from '../../../actions/superuser/appDirectories/appDirectoryForm';

import UpdateLocationsSortable from './common/UpdateLocationsSortable';

class AppDirectoryLocations extends React.Component {

    constructor(props) {
        super(props);

        this.addAppDirectoryLocation = this.addAppDirectoryLocation.bind(this);
        this.deleteAppDirectoryLocation = this.deleteAppDirectoryLocation.bind(this);
    }

    addAppDirectoryLocation(selectedLocationId) {

        if (selectedLocationId > 0) {
            this.props.dispatch(updateAppDirectory(this.props.directory.id, 'add_location', {location_id: selectedLocationId}));
        }
        else {
            this.props.dispatch(appDirectoryFormError());  
        }
    }

    deleteAppDirectoryLocation(location) {

        this.props.dispatch(updateAppDirectory(this.props.directory.id, 'delete_location', {location_id: location.location.id}));
    }
    
    render() {
        return (
            <UpdateLocationsSortable
                locations={this.props.locations}
                deleteLocation={this.deleteAppDirectoryLocation}
                addLocation={this.addAppDirectoryLocation}
            />
        );
    }
};

const mapStateToProps = (state, props) => {
    return {
        directory: state.directories.selectedDirectory,
        locations: state.directories.selectedDirectory.locations,
        appDirectoryForm: state.appDirectoryForm,
        restaurants: state.adminRestaurantLocations.restaurants
    };
};

export default connect(mapStateToProps)(AppDirectoryLocations);