export const appDirectoryFormUpdate = ({ prop, value }) => {

    return {
        type: 'APP_DIRECTORY_FORM_UPDATE',
        directory: { prop, value }
    };
};

export const appDirectoryFormError = (message) => {

    return {
        type: 'APP_DIRECTORY_FORM_ERROR',
        message
    };
};

export const appDirectoryFormSuccess = (message) => {

    return {
        type: 'APP_DIRECTORY_FORM_SUCCESS',
        message
    };
};

export const appDirectoryFormReset = () => {
    
    return {
        type: 'APP_DIRECTORY_FORM_RESET',
    };
}