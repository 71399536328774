import axios from 'axios';
import { API_URL } from '../../config/constants';

export const setAdminRestaurantLocations = (restaurants) => {

    return {
        type: 'SET_ADMIN_RESTAURANT_LOCATIONS',
        restaurants
    };
};

export const adminRestaurantLocationsReset = () => {
    
    return {
        type: 'RESET_ADMIN_RESTAURANT_LOCATIONS',
    };
}

export const loadAdminRestaurantLocations = () => {
    return (dispatch) => {
        return axios.get(API_URL+'/api/locations')
            .then(function(res) {

                // load the directories
                dispatch(setAdminRestaurantLocations(res.data.data.restaurants));

            })
            .catch(function(err) {
                console.log("Err!");
                console.log(err);
            });
    };
}