const crustFormDefaultState = {
    name: '',
    description: '',
    prices: [],
    message: '',
    error: '',
};

export default (state = crustFormDefaultState, action) => {
    
    switch (action.type) {

        case 'CRUST_FORM_UPDATE':
            return {
                ...state,
                [action.crust.prop]: action.crust.value
            };

        case 'CRUST_FORM_UPDATE_PRICE':
            return {
                ...state,
                prices: state.prices.map((item, index) => {
                    if (index == action.item.index) {
                        return {
                            ...item,
                            [action.item.prop]: action.item.value
                        }
                    }

                    return item;
                })
            };

        case 'CRUST_FORM_RESET_PRICES':
            return {
                ...state,
                prices: [],
            }

        case 'CRUST_FORM_ERROR':
            return {
                ...state,
                message: '',
                error: action.message
            }

        case 'CRUST_FORM_SUCCESS':
            return {
                ...state,
                error: '',
                message: action.message
            }

        case 'CRUST_FORM_SET':
            return {
                ...state,
                ...action.crust
            };

        case 'CRUST_FORM_RESET':
            return crustFormDefaultState;

        default:
            return state;
    }
};