export const addonFormUpdate = ({ prop, value }) => {

    return {
        type: 'ADDON_FORM_UPDATE',
        addon: { prop, value }
    };
};

export const addonFormError = (message) => {

    return {
        type: 'ADDON_FORM_ERROR',
        message
    };
};

export const addonFormSuccess = (message) => {

    return {
        type: 'ADDON_FORM_SUCCESS',
        message
    };
};

export const addonFormReset = () => {
    
    return {
        type: 'ADDON_FORM_RESET',
    };
}