import React from 'react';
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';

import { 
    locationFormUpdate, 
    locationFormAddDeliveryCharge, 
    locationFormRemoveDeliveryCharge,
    locationFormUpdateDeliveryCharge 
} from '../../../actions/restaurant/locationForm';

class DeliveryCharges extends React.Component {

    constructor(props) {
        super(props);

        this.updateDeliveryType = this.updateDeliveryType.bind(this);
        this.updatePickupOptions = this.updatePickupOptions.bind(this);
        this.addDeliveryCharge = this.addDeliveryCharge.bind(this);
        this.removeDeliveryCharge = this.removeDeliveryCharge.bind(this);

        this.state = { pictures: [] };
    }

    addDeliveryCharge() {
        this.props.dispatch(locationFormAddDeliveryCharge({
            max_distance: 0,
            min_total: 0,
            price: 0
        }));
    }

    removeDeliveryCharge(index) {
        this.props.dispatch(locationFormRemoveDeliveryCharge(index));
    }

    updateDeliveryType(event, value) {
        this.props.dispatch(locationFormUpdate({ prop: 'delivery_type', value }));

        if (value == 'fixed' || value == 'distance') {
            this.props.dispatch(locationFormUpdate({ prop: 'pickup_options', value: 'BOTH' }));
        }
    }

    updatePickupOptions(event, value) {
        this.props.dispatch(locationFormUpdate({ prop: 'pickup_options', value }));
    }

    renderDeliveryChargeForm() {
        if (this.props.locationForm.delivery_type != 'none') {
            return (
                <Grid container>
                    <Grid item xs={12} className="form-grid">
                        <Typography variant="h3">
                            Enable Pickup?
                        </Typography>
                    </Grid>

                    <Grid item xs={12} className="form-grid">
                        <Tabs
                            value={this.props.locationForm.pickup_options}
                            onChange={this.updatePickupOptions}
                            indicatorColor="primary"
                            textColor="primary"
                            centered
                        >
                            <Tab label="Pickup and Delivery" value="BOTH" />
                            <Tab label="Delivery Only" value="DELIVERY ONLY" />
                        </Tabs>
                    </Grid>

                    {(this.props.locationForm.delivery_type == 'distance') ? this.renderRangeDistanceForm() : this.renderFixedDistanceForm()}
                </Grid>
            );
        }
    }

    renderFixedDistanceForm() {
        return (
            <Grid item xs={12} className="form-grid">
                <Grid container>
                    <Grid item xs={12} className="form-grid">
                        <Typography variant="h3">
                            Fixed Delivery Charge
                        </Typography>
                    </Grid>
                    <Grid item xs={3} className="form-image-container">
                        <Typography>
                            Fixed Price Of:
                        </Typography>
                    </Grid>
                    <Grid item xs={3} className="form-grid">
                        <TextField
                            label="Delivery Charge"
                            className="modal-input"
                            fullWidth={true}
                            value={this.props.locationForm.delivery_charge}
                            onChange={(e) => { this.props.dispatch(locationFormUpdate({ prop: 'delivery_charge', value: e.target.value })); }}
                            margin="normal"
                            variant="outlined"
                        />
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid item xs={3} className="form-image-container">
                        <Typography>
                            Max Distance (km):
                        </Typography>
                    </Grid>
                    <Grid item xs={3} className="form-grid">
                        <TextField
                            label="Distance (km)"
                            className="modal-input"
                            fullWidth={true}
                            value={this.props.locationForm.max_delivery_range}
                            onChange={(e) => { this.props.dispatch(locationFormUpdate({ prop: 'max_delivery_range', value: e.target.value })); }}
                            margin="normal"
                            variant="outlined"
                        />
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    renderRangeDistanceForm() {
        return (
            <Grid item xs={12} className="form-grid">
                <Grid container>
                    <Grid item xs={12} className="form-grid">
                        <Typography variant="h3">
                            Adjustable Delivery Charge
                        </Typography>
                    </Grid>
                </Grid>

                {this.props.locationForm.deliveryCharges.length <= 0 && (
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography className="no-results">No charges entered.</Typography>
                        </Grid>
                    </Grid>
                )}
                
                {this.props.locationForm.deliveryCharges.length > 0 && (
                    <Grid container>
                        <Grid item xs={4} className="form-image-container">
                            <Typography>
                                Distance (km)
                            </Typography>
                        </Grid>
                        <Grid item xs={4} className="form-image-container">
                            <Typography>
                                Total is Above
                            </Typography>
                        </Grid>
                        <Grid item xs={3} className="form-image-container">
                            <Typography>
                                Charge
                            </Typography>
                        </Grid>
                        <Grid item xs={1} className="form-image-container"></Grid>
                    </Grid>
                )}

                {this.props.locationForm.deliveryCharges.length > 0 && this.props.locationForm.deliveryCharges.map((charge, index) => {
                    return (
                        <Grid container>
                            <Grid item xs={4} className="form-grid">
                                <TextField
                                    label="Distance"
                                    className="modal-input"
                                    fullWidth={true}
                                    value={charge.max_distance}
                                    onChange={(e) => { this.props.dispatch(locationFormUpdateDeliveryCharge({ prop: 'max_distance', index, value: e.target.value })); }}
                                    margin="normal"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={4} className="form-grid">
                                <TextField
                                    label="Order Total"
                                    className="modal-input"
                                    fullWidth={true}
                                    value={charge.min_total}
                                    onChange={(e) => { this.props.dispatch(locationFormUpdateDeliveryCharge({ prop: 'min_total', index, value: e.target.value })); }}
                                    margin="normal"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={3} className="form-grid">
                                <TextField
                                    label="Charge"
                                    className="modal-input"
                                    fullWidth={true}
                                    value={charge.price}
                                    onChange={(e) => { this.props.dispatch(locationFormUpdateDeliveryCharge({ prop: 'price', index, value: e.target.value })); }}
                                    margin="normal"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={1} className="form-image-container">
                                <Button fullWidth={true} color="secondary" onClick={() => this.removeDeliveryCharge(index)}>
                                    <DeleteIcon />
                                </Button>
                            </Grid>
                        </Grid>
                    );
                })}

                <Grid item xs={12}>
                    <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom" onClick={this.addDeliveryCharge}>
                        <AddIcon />
                    </Button>
                </Grid>
            </Grid>
        );
    }
    
    render() {
        return (
            <div>
                <Tabs
                    value={this.props.locationForm.delivery_type}
                    onChange={this.updateDeliveryType}
                    indicatorColor="primary"
                    textColor="primary"
                    centered
                >
                    <Tab label="Range-Based" value="distance" />
                    <Tab label="Fixed" value="fixed" />
                    <Tab label="No Delivery" value="none" />
                </Tabs>

                {this.renderDeliveryChargeForm()}
            </div>
        );
    }
};

const mapStateToProps = (state, props) => {
    return {
        locationForm: state.locationForm,
        curUser: state.loggedUser.user
    };
};

export default connect(mapStateToProps)(DeliveryCharges);