import React from 'react';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

import LoadScreen from './common/LoadScreen';
import verifyLogin from '../utils/verifyLogin';
import { loadRestaurants, setRestaurantFilter, updateRestaurantEnabled } from '../actions/restaurants';
import { openRestaurantCreate, closeRestaurantCreate } from '../actions/modals';
import { setSelectedRestaurant } from '../actions/selectedRestaurant';
import { infoFormReset, infoFormUpdate } from '../actions/restaurant/infoForm';
import { API_URL } from '../config/constants';
import restaurant_logo from '../assets/images/restaurant_default.png';
import filterRestaurants from '../selectors/restaurants';

import CreateRestaurant from './restaurants/modal/CreateRestaurant';
import checkPermissions from '../utils/checkUserPermissions';

class DashboardPage extends React.Component {

    constructor(props) {
        super(props);

        this.selectRestaurant = this.selectRestaurant.bind(this);
        this.toggleCreateModal = this.toggleCreateModal.bind(this);

        this.state = {
            sidebarMenuOpen: false
        };
    }

    componentDidMount() {
        verifyLogin(this.props.history);

        // load the restaurants
        this.props.dispatch(loadRestaurants());

        if(this.props.location.state && this.props.location.state.arrivalAction && this.props.location.state.arrivalAction == 'create_restaurant' && checkPermissions(this.props.curUser, 'superuser')){
            this.toggleCreateModal(true);
        }
    }

    componentDidUpdate() {
        verifyLogin(this.props.history);
    }

    selectRestaurant(restaurant, pageType) {

        this.props.dispatch(setSelectedRestaurant({ restaurant }));
        this.props.history.push('/'+restaurant.id+'/'+pageType);
    }

    toggleCreateModal(isOpen) {
        if (isOpen) {
            this.props.dispatch(infoFormReset());
            this.props.dispatch(infoFormUpdate({ prop: 'loading', value: false }));
            this.props.dispatch(openRestaurantCreate());
        }
        else {
            this.props.dispatch(closeRestaurantCreate());
        }
    }

    toggleEnabled(restaurantId, isEnable) {
        if (isEnable) {
            this.props.dispatch(updateRestaurantEnabled(restaurantId, true));
        }
        else {
            this.props.dispatch(updateRestaurantEnabled(restaurantId, false));
        }
    }

    renderRestaurantLogo(restaurant) {
        if (restaurant.logo) {
            return API_URL + '/' + restaurant.logo;
        }
        else {
           return restaurant_logo;
        }
    }

    renderEnableButton(item) {
        if (checkPermissions(this.props.curUser, 'superuser')) {
            if (!item.inactive) {
                return (
                    <Button size="small" color="secondary" onClick={() => this.toggleEnabled(item.id, 0)}>
                        Disable
                    </Button>
                );
            }
            else {
                return (
                    <Button size="small" color="primary" onClick={() => this.toggleEnabled(item.id, 1)}>
                        Enable
                    </Button>
                );
            }
        }
    }

    renderRestaurants() {

        if (this.props.loading) {
            return (
                <div className="page-loading">
                    <CircularProgress size={100} />
                </div>
            );
        }
        else {
            return this.props.restaurants.map((restaurant) => (
                <Grid item xs={12} xl={2} lg={2} md={3} sm={4} className="restaurant-card-grid" key={restaurant.id}>
                    <Card className="card restaurant-card">
                        <CardActionArea onClick={() => { this.selectRestaurant(restaurant, 'dashboard')}}>
                            <CardMedia
                                component="img"
                                alt="Contemplative Reptile"
                                image={this.renderRestaurantLogo(restaurant)}
                                title={restaurant.logo}
                            />
                            <CardContent style={{ height: 45}}>
                                <Typography gutterBottom variant="h5" component="h2">
                                    {restaurant.name}
                                </Typography>
                                <Typography variant="body2" color="textSecondary" component="p">
                                    {restaurant.logo}
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                        <CardActions>
                            <Button size="small" color="primary" onClick={() => { this.selectRestaurant(restaurant, 'dashboard')}}>
                                Dashboard
                            </Button>
                            {this.renderEnableButton(restaurant)}
                        </CardActions>
                    </Card>
                </Grid>
            ));
        }
    }
    
    render() {
        return (
            <LoadScreen>
                <div className="card-container">
                    <Grid container>
                        <Grid item xs={12} className="heading-grid">
                            <h1>Restaurants</h1>
                            <FormControl fullWidth>
                                <TextField 
                                    label="Filter Restaurants" 
                                    variant="outlined" 
                                    value={this.props.filter}
                                    onChange={(e) => this.props.dispatch(setRestaurantFilter(e.target.value))} 
                                />
                            </FormControl>
                        </Grid>
                        {this.renderRestaurants()}
                    </Grid>

                    {checkPermissions(this.props.curUser, 'superuser') && (
                        <div>
                            <CreateRestaurant />

                            <div className="window-fab">
                                <Fab className="btn-fab btn-add" onClick={() => this.toggleCreateModal(true)}>
                                    <AddIcon />
                                </Fab>
                            </div>
                        </div>
                    )}
                </div>
            </LoadScreen>
        )
    }
};

const mapStateToProps = (state, props) => {
    return {
        loading: state.restaurants.loading,
        restaurants: filterRestaurants(state.restaurants.restaurants, {text: state.restaurants.filter}),
        filter: state.restaurants.filter,
        createModalOpen: state.modals.restaurantCreate,
        curUser: state.loggedUser.user
    };
};

export default connect(mapStateToProps)(DashboardPage);