const modalsDefaultState = {
    restaurantCreate: false,
    loyaltyRewardCreate: false,
    loyaltyRewardUpdate: false,
    loyaltyPointsAdd: false,
    loyaltyPointsUpdate: false,
    locationCreate: false,
    locationUpdate: false,
    managerAdd: false,
    pizzaCreate: false,
    pizzaUpdate: false,
    pizzaSettingsConfig: false,
    pizzaSettingsUpdate: false,
    customPizzasUpdate: false,
    foodItemCreate: false,
    foodItemUpdate: false,
    specialCreate: false,
    specialUpdate: false,
    addonCreate: false,
    addonUpdate: false,
    modifierModal: false,
    appDirectoryCreate: false,
    appDirectoryUpdate: false,
    dispatchCenterCreate: false,
    dispatchCenterUpdate: false,
    userManagementUpdate: false,
    userCreate: false,
    confirmOrder: false,
    searchReports: false,
    orderDetails: false,
    promoCodeCreate: false,
    promoCodeUpdate: false,
    massPromoCodeCreate: false,
    massPromoCodeUpdate: false,
};

export default (state = modalsDefaultState, action) => {
    switch (action.type) {

        case 'OPEN_SEARCH_REPORTS_MODAL':
            return {
                ...state,
                searchReports: true
            };

        case 'CLOSE_SEARCH_REPORTS_MODAL':
            return {
                ...state,
                searchReports: false
            };

        case 'OPEN_RESTAURANT_CREATE_MODAL':
            return {
                ...state,
                restaurantCreate: true
            };

        case 'CLOSE_RESTAURANT_CREATE_MODAL':
            return {
                ...state,
                restaurantCreate: false
            };

        case 'OPEN_LOYALTY_REWARD_CREATE_MODAL':
            return {
                ...state,
                loyaltyRewardCreate: true
            };

        case 'CLOSE_LOYALTY_REWARD_CREATE_MODAL':
            return {
                ...state,
                loyaltyRewardCreate: false
            };

        case 'OPEN_LOYALTY_REWARD_UPDATE_MODAL':
            return {
                ...state,
                loyaltyRewardUpdate: true
            };

        case 'CLOSE_LOYALTY_REWARD_UPDATE_MODAL':
            return {
                ...state,
                loyaltyRewardUpdate: false
            };

        case 'OPEN_LOYALTY_POINTS_ADD_MODAL':
            return {
                ...state,
                loyaltyPointsAdd: true
            };

        case 'CLOSE_LOYALTY_POINTS_ADD_MODAL':
            return {
                ...state,
                loyaltyPointsAdd: false
            };

        case 'OPEN_LOYALTY_POINTS_UPDATE_MODAL':
            return {
                ...state,
                loyaltyPointsUpdate: true
            };

        case 'CLOSE_LOYALTY_POINTS_UPDATE_MODAL':
            return {
                ...state,
                loyaltyPointsUpdate: false
            };

        case 'OPEN_LOCATION_CREATE_MODAL':
            return {
                ...state,
                locationCreate: true
            };

        case 'CLOSE_LOCATION_CREATE_MODAL':
            return {
                ...state,
                locationCreate: false
            };

        case 'OPEN_LOCATION_UPDATE_MODAL':
            return {
                ...state,
                locationUpdate: true
            };

        case 'CLOSE_LOCATION_UPDATE_MODAL':
            return {
                ...state,
                locationUpdate: false
            };

        case 'OPEN_MANAGER_ADD_MODAL':
            return {
                ...state,
                managerAdd: true
            };

        case 'CLOSE_MANAGER_ADD_MODAL':
            return {
                ...state,
                managerAdd: false
            };

        case 'OPEN_PIZZA_SETTINGS_CONFIG_MODAL':
            return {
                ...state,
                pizzaSettingsConfig: true
            };

        case 'CLOSE_PIZZA_SETTINGS_CONFIG_MODAL':
            return {
                ...state,
                pizzaSettingsConfig: false
            };

        case 'OPEN_PIZZA_SETTINGS_UPDATE_MODAL':
            return {
                ...state,
                pizzaSettingsUpdate: true
            };

        case 'CLOSE_PIZZA_SETTINGS_UPDATE_MODAL':
            return {
                ...state,
                pizzaSettingsUpdate: false
            };

        case 'OPEN_CUSTOM_PIZZAS_UPDATE_MODAL':
            return {
                ...state,
                customPizzasUpdate: true
            };

        case 'CLOSE_CUSTOM_PIZZAS_UPDATE_MODAL':
            return {
                ...state,
                customPizzasUpdate: false
            };

        case 'OPEN_PIZZA_CREATE_MODAL':
            return {
                ...state,
                pizzaCreate: true
            };

        case 'CLOSE_PIZZA_CREATE_MODAL':
            return {
                ...state,
                pizzaCreate: false
            };

        case 'OPEN_PIZZA_UPDATE_MODAL':
            return {
                ...state,
                pizzaUpdate: true
            };

        case 'CLOSE_PIZZA_UPDATE_MODAL':
            return {
                ...state,
                pizzaUpdate: false
            }

        case 'OPEN_SPECIAL_CREATE_MODAL':
            return {
                ...state,
                specialCreate: true
            };

        case 'CLOSE_SPECIAL_CREATE_MODAL':
            return {
                ...state,
                specialCreate: false
            };

        case 'OPEN_SPECIAL_UPDATE_MODAL':
            return {
                ...state,
                specialUpdate: true
            };

        case 'CLOSE_SPECIAL_UPDATE_MODAL':
            return {
                ...state,
                specialUpdate: false
            }
            
        case 'OPEN_ADDON_CREATE_MODAL':
            return {
                ...state,
                addonCreate: true
            };

        case 'CLOSE_ADDON_CREATE_MODAL':
            return {
                ...state,
                addonCreate: false
            };

        case 'OPEN_ADDON_UPDATE_MODAL':
            return {
                ...state,
                addonUpdate: true
            };

        case 'CLOSE_ADDON_UPDATE_MODAL':
            return {
                ...state,
                addonUpdate: false
            };

        case 'OPEN_MODIFIER_MODAL':
            return {
                ...state,
                modifierModal: true
            };

        case 'CLOSE_MODIFIER_MODAL':
            return {
                ...state,
                modifierModal: false
            };

        case 'OPEN_CATEGORY_CREATE_MODAL':
            return {
                ...state,
                categoryCreate: true
            };

        case 'CLOSE_CATEGORY_CREATE_MODAL':
            return {
                ...state,
                categoryCreate: false
            };

        case 'OPEN_CATEGORY_UPDATE_MODAL':
            return {
                ...state,
                categoryUpdate: true
            };

        case 'CLOSE_CATEGORY_UPDATE_MODAL':
            return {
                ...state,
                categoryUpdate: false
            };

        case 'OPEN_FOOD_ITEM_CREATE_MODAL':
            return {
                ...state,
                foodItemCreate: true
            };

        case 'CLOSE_FOOD_ITEM_CREATE_MODAL':
            return {
                ...state,
                foodItemCreate: false
            };

        case 'OPEN_FOOD_ITEM_UPDATE_MODAL':
            return {
                ...state,
                foodItemUpdate: true
            };

        case 'CLOSE_FOOD_ITEM_UPDATE_MODAL':
            return {
                ...state,
                foodItemUpdate: false
            };

        case 'OPEN_APP_DIRECTORY_CREATE_MODAL':
            return {
                ...state,
                appDirectoryCreate: true
            };

        case 'CLOSE_APP_DIRECTORY_CREATE_MODAL':
            return {
                ...state,
                appDirectoryCreate: false
            };

        case 'OPEN_APP_DIRECTORY_UPDATE_MODAL':
            return {
                ...state,
                appDirectoryUpdate: true
            };

        case 'CLOSE_APP_DIRECTORY_UPDATE_MODAL':
            return {
                ...state,
                appDirectoryUpdate: false
            };

        case 'OPEN_DISPATCH_CENTER_CREATE_MODAL':
            return {
                ...state,
                dispatchCenterCreate: true
            };

        case 'OPEN_PROMO_CODE_CREATE_MODAL':
            return {
                ...state,
                promoCodeCreate: true
            };
    
        case 'OPEN_MASS_PROMO_CODE_CREATE_MODAL':
            return {
                ...state,
                massPromoCodeCreate: true
            };    
        case 'OPEN_MASS_PROMO_CODE_UPDATE_MODAL':
            return {
                ...state,
                massPromoCodeUpdate: true
            };
    
        case 'CLOSE_PROMO_CODE_CREATE_MODAL':
            return {
                ...state,
                promoCodeCreate: false
            };
        
        case 'CLOSE_MASS_PROMO_CODE_CREATE_MODAL':
            return {
                ...state,
                massPromoCodeCreate: false
            };
            
        case 'CLOSE_MASS_PROMO_CODE_UPDATE_MODAL':
            return {
                ...state,
                massPromoCodeUpdate: false
            };
    
        case 'OPEN_PROMO_CODE_UPDATE_MODAL':
            return {
                ...state,
                promoCodeUpdate: true
            };

        case 'CLOSE_PROMO_CODE_UPDATE_MODAL':
            return {
                ...state,
                promoCodeUpdate: false
            };
        case 'CLOSE_DISPATCH_CENTER_CREATE_MODAL':
            return {
                ...state,
                dispatchCenterCreate: false
            };

        case 'OPEN_DISPATCH_CENTER_UPDATE_MODAL':
            return {
                ...state,
                dispatchCenterUpdate: true
            };

        case 'CLOSE_DISPATCH_CENTER_UPDATE_MODAL':
            return {
                ...state,
                dispatchCenterUpdate: false
            };

        case 'OPEN_USER_MANAGEMENT_UPDATE_MODAL':
            return {
                ...state,
                userManagementUpdate: true
            };

        case 'CLOSE_USER_MANAGEMENT_UPDATE_MODAL':
            return {
                ...state,
                userManagementUpdate: false
            };

        case 'OPEN_USER_CREATE_MODAL':
            return {
                ...state,
                userCreate: true
            };

        case 'CLOSE_USER_CREATE_MODAL':
            return {
                ...state,
                userCreate: false
            };

        case 'OPEN_CONFIRM_ORDER_MODAL':
            return {
                ...state,
                confirmOrder: true
            };

        case 'OPEN_COMPLETE_ORDER_MODAL':
            return {
                ...state,
                completeOrder: true
            };

        case 'CLOSE_CONFIRM_ORDER_MODAL':
            return {
                ...state,
                confirmOrder: false
            };

        case 'CLOSE_COMPLETE_ORDER_MODAL':
            return {
                ...state,
                completeOrder: false
            };

        case 'OPEN_ORDER_DETAILS':
            return {
                ...state,
                orderDetails: true
            };

        case 'CLOSE_ORDER_DETAILS':
            return {
                ...state,
                orderDetails: false
            };

        case 'RESET_MODALS':
            return modalsDefaultState;

        default:
            return state;
    }
};