const massPromoCodeAdminFormDefaultState = {
    total_target: 'DELIVERY',
    total_type: 'PERCENTAGE',
    total_avail: 'ALWAYS',
    label: '',
    max_uses: 1,
    codes_to_create: 0,
    total: 0.00,
    start_time: null,
    end_time: null,
    deleted: false,
    index: null,
    loading: false,
    editing: false,
    message: '',
    error: '',
    selectedPromoCode: null,
    valid_days: {
        sun: true,
        mon: true,
        tue: true,
        wed: true,
        thu: true,
        fri: true,
        sat: true
    },
};

export default (state = massPromoCodeAdminFormDefaultState, action) => {
    
    switch (action.type) {

        case 'SET_SELECTED_ADMIN_MASS_PROMO_CODE':
            return {
                ...state,
                selectedPromoCode: action.promoCode
            };

        case 'SET_EDITING_ADMIN_MASS_PROMO_CODE':
            return {
                ...state,
                editing: true
            };

        case 'RESET_EDITING_ADMIN_MASS_PROMO_CODE':
            return {
                ...state,
                editing: false
            };

        case 'MASS_PROMO_CODE_ADMIN_FORM_UPDATE':
            return {
                ...state,
                [action.promoCode.prop]: action.promoCode.value
            };

        case 'MASS_PROMO_CODE_FORM_UPDATE_VALID_DAYS':
            return {
                ...state,
                valid_days: 
                    {
                        ...state.valid_days,
                        [action.promoCode.prop]: action.promoCode.value
                    }
            };

        case 'MASS_PROMO_CODE_ADMIN_FORM_ERROR':
            return {
                ...state,
                message: '',
                error: action.message
            }

        case 'MASS_PROMO_CODE_ADMIN_FORM_SUCCESS':
            return {
                ...state,
                error: '',
                message: action.message
            }

        case 'MASS_PROMO_CODE_ADMIN_FORM_LOADING':
            return {
                ...state,
                loading: true,
            }

        case 'MASS_PROMO_CODE_ADMIN_FORM_DONE_LOADING':
            return {
                ...state,
                loading: false,
            }

        case 'MASS_PROMO_CODE_ADMIN_FORM_SET':
            return {
                ...state,
                ...action.promoCode
            };

        case 'MASS_PROMO_CODE_ADMIN_FORM_RESET':
            return massPromoCodeAdminFormDefaultState;

        default:
            return state;
    }
};