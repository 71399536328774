import React from 'react';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import arrayMove from 'array-move';

import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

import LoadScreen from '../../common/LoadScreen';
import verifyLogin from '../../../utils/verifyLogin';
import { loadCategories, setCategoryFilter, addCategory, updateCategory, updateCategoryOrder, toggleCategoryEnabled } from '../../../actions/menu/categories/categories';
import { categoryFormUpdate, categoryFormError, categoryFormReset } from '../../../actions/menu/categories/categoryForm';
import { openCategoryCreate, closeCategoryCreate, openCategoryUpdate, closeCategoryUpdate } from '../../../actions/modals';
import { loadPageData } from '../../../actions/auth';
import filterCategories from '../../../selectors/categories';

import SortableMenuList from '../common/SortableMenuList';

import checkPermissions from '../../../utils/checkUserPermissions';

import CloseIcon from '../../common/modal/CloseIcon';

class FoodCategoriesPage extends React.Component {

    constructor(props) {
        super(props);

        this.toggleCreateModal = this.toggleCreateModal.bind(this);
        this.toggleUpdateModal = this.toggleUpdateModal.bind(this);
        this.toggleCategoryEnabled = this.toggleCategoryEnabled.bind(this);
        this.submitCreateCategory = this.submitCreateCategory.bind(this);
        this.submitUpdateCategory = this.submitUpdateCategory.bind(this);
        this.checkPermissions = this.checkPermissions.bind(this);
        this.onSortEnd = this.onSortEnd.bind(this);

        this.state = {
            sidebarMenuOpen: false,
            selectedCategory: {}
        };
    }

    componentDidMount() {
        verifyLogin(this.props.history);
    }

    componentDidUpdate() {
        verifyLogin(this.props.history);
    }

    checkPermissions() {
        if (checkPermissions(this.props.user, 'restaurant_manager', this.props.match.params.id) ||
            checkPermissions(this.props.user, 'support')) {
            this.props.dispatch(loadPageData(this.props.match.params));
            this.props.dispatch(loadCategories(this.props.match.params.id));
        } else {
            this.props.history.push('/permission-denied');
        } 
    }

    toggleUpdateModal(isOpen, selectedCategory) {
        if (isOpen) {
            this.setState(() => ({
                selectedCategory
            }));
            this.props.dispatch(categoryFormReset());
            this.props.dispatch(categoryFormUpdate({ prop: 'name', value: selectedCategory.name }));
            this.props.dispatch(categoryFormUpdate({ prop: 'description', value: selectedCategory.description }));
            this.props.dispatch(openCategoryUpdate());
        }
        else {
            this.props.dispatch(closeCategoryUpdate());
        }
    }

    toggleCreateModal(isOpen) {
        if (isOpen) {
            this.props.dispatch(categoryFormReset());
            this.props.dispatch(openCategoryCreate());
        }
        else {
            this.props.dispatch(closeCategoryCreate());
        }
    }

    onSortEnd({oldIndex, newIndex})  {

        let newCategoryOrder = arrayMove(this.props.categories, oldIndex, newIndex);
        for (let i = 0; i < newCategoryOrder.length; i++) {
            newCategoryOrder[i]['category_order'] = i + 1;
        }

        this.props.dispatch(updateCategoryOrder(this.props.match.params.id, newCategoryOrder));
    }

    submitCreateCategory() {

        // perform some error checking
        if (this.props.categoryForm.name.trim() == "") {

            // required info isn't present
            this.props.dispatch(categoryFormError("Please enter a category name."));
        }
        else {

            // add the category
            this.props.dispatch(addCategory(this.props.match.params.id, this.props.categoryForm));
        }
    }

    submitUpdateCategory() {

        // perform some error checking
        if (this.props.categoryForm.name.trim() == "") {

            // required fields aren't present
            this.props.dispatch(categoryFormError("Please enter a category name."));
        }
        else {

            // add the food category
            this.props.dispatch(updateCategory(this.props.match.params.id, this.state.selectedCategory.id, this.props.categoryForm));
        }
    }

    toggleCategoryEnabled(category, enabled) {
        let confirmStr = "Are you sure you want to disable this category?";
        if (enabled) {
            confirmStr = "Enable this category?";
        }

        if (confirm(confirmStr)) {
            this.props.dispatch(toggleCategoryEnabled(this.props.match.params.id, category.id, enabled));
        };
    }

    renderCreateModal() {

        return (
            <Modal
                aria-labelledby="category-add"
                aria-describedby="category-add"
                open={this.props.createModalOpen}
                onClose={() => this.toggleCreateModal(false)}
                className="modal_admin"
                disableBackdropClick
            >
                <div className="modal-member">
                    <Typography variant="h4" id="modal-title">
                        Add a Category
                        <CloseIcon/>
                    </Typography>
                    <Typography className="modal-description">
                        Please add the category name and description, than click 'Add Category'.
                    </Typography>

                    { this.props.categoryForm.error && (
                        <Typography className="modal-error">
                            { this.props.categoryForm.error }
                        </Typography>
                    )}

                    <TextField
                        id="create-category-title"
                        label="Name"
                        className="modal-input"
                        fullWidth={true}
                        value={this.props.categoryForm.name}
                        onChange={(e) => { this.props.dispatch(categoryFormUpdate({ prop: 'name', value: e.target.value })); }}
                        margin="normal"
                    />

                    <TextField
                        id="create-category-description"
                        label="Description"
                        className="modal-input"
                        fullWidth={true}
                        multiline={true}
                        value={this.props.categoryForm.description}
                        onChange={(e) => { this.props.dispatch(categoryFormUpdate({ prop: 'description', value: e.target.value })); }}
                        margin="normal"
                    />

                    <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom" onClick={this.submitCreateCategory}>
                        Add Category
                    </Button>
                </div>
            </Modal>
        )
    }

    renderUpdateModal() {

        return (
            <Modal
                aria-labelledby="category-update"
                aria-describedby="category-update"
                open={this.props.updateModalOpen}
                onClose={() => this.toggleUpdateModal(false)}
                className="modal_admin"
                disableBackdropClick
            >
                <div className="modal-member">
                    <Typography variant="h4" className="modal-title">
                        Update Category
                        <CloseIcon/>
                    </Typography>
                    <Typography className="modal-description">
                        Please update the category name or description, and click the update button to continue.
                    </Typography>

                    { this.props.categoryForm.error && (
                        <Typography className="modal-error">
                            { this.props.categoryForm.error }
                        </Typography>
                    )}

                    { this.props.categoryForm.message && (
                        <Typography className="modal-success">
                            { this.props.categoryForm.message }
                        </Typography>
                    )}

                    <TextField
                        id="update-category-name"
                        label="Name"
                        className="modal-input"
                        fullWidth={true}
                        value={this.props.categoryForm.name}
                        onChange={(e) => { this.props.dispatch(categoryFormUpdate({ prop: 'name', value: e.target.value })); }}
                        margin="normal"
                    />

                    <TextField
                        id="update-category-description"
                        label="Description"
                        className="modal-input"
                        fullWidth={true}
                        multiline={true}
                        value={this.props.categoryForm.description}
                        onChange={(e) => { this.props.dispatch(categoryFormUpdate({ prop: 'description', value: e.target.value })); }}
                        margin="normal"
                    />

                    <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom" onClick={this.submitUpdateCategory}>
                        Update Category
                    </Button>
                </div>
            </Modal>
        )
    }

    renderCategories() {

        if (this.props.loading) {
            return (
                <div className="page-loading">
                    <CircularProgress size={100} />
                </div>
            );
        }
        else {
            if (this.props.categories.length <= 0) {
                return (
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography className="no-results">No food categories entered.</Typography>
                        </Grid>
                    </Grid>
                );
            }
            else {
                return (
                    <SortableMenuList
                        items={this.props.categories}
                        toggleEnabled={this.toggleCategoryEnabled}
                        toggleUpdateModal={this.toggleUpdateModal}
                        onSortEnd={this.onSortEnd}
                        showImage={false}
                        isCategory={true}
                        restaurantId={this.props.restaurant.id}
                        history={this.props.history}
                    />
                );
            }
        }
    }
    
    render() {
        return (
            <LoadScreen
                checkPermissions={this.checkPermissions}
            >
                <div className="card-container">
                    <Grid container className="menu-page-header">
                        <Grid item xs={12} className="heading-grid">
                            <h1>Categories</h1>
                            <FormControl fullWidth>
                                <TextField 
                                    label="Filter Categories" 
                                    variant="outlined" 
                                    value={this.props.filter}
                                    onChange={(e) => this.props.dispatch(setCategoryFilter(e.target.value))} 
                                />
                            </FormControl>
                        </Grid>
                    </Grid>

                    {this.renderCategories()}
                </div>


                <div className="window-fab">
                    <Fab className="btn-fab btn-add" onClick={() => this.toggleCreateModal(true)}>
                        <AddIcon />
                    </Fab>
                </div>
                

                {this.renderCreateModal()}
                {this.renderUpdateModal()}
            </LoadScreen>
        );
    }
};

const mapStateToProps = (state, props) => {
    return {
        loading: state.categories.loading || state.restaurants.loading,
        restaurant: state.selectedRestaurant.restaurant,
        categories: filterCategories(state.categories.categories, {text: state.categories.filter}),
        categoryForm: state.categoryForm,
        createModalOpen: state.modals.categoryCreate,
        updateModalOpen: state.modals.categoryUpdate,
        user: state.loggedUser.user,
    };
};

export default connect(mapStateToProps)(FoodCategoriesPage);