import React from 'react';
import { sortableHandle  } from 'react-sortable-hoc';

import Button from '@material-ui/core/Button';
import HeightIcon from '@material-ui/icons/Height';
import DragHandleIcon from '@material-ui/icons/DragHandle';

const SortableHandle = (props) => {
    return (
        <Button 
            fullWidth={true}
            size="large"
        >
            <DragHandleIcon className="modal-button-icon" />
        </Button>
    );
}

export default sortableHandle(SortableHandle);
