import React from 'react';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import validateCurrency from '../../../../utils/validateCurrency';
import { updateSpecial } from '../../../../actions/menu/specials/specials';
import { specialFormUpdate, specialFormError, } from '../../../../actions/menu/specials/specialForm';
import { closeSpecialUpdate } from '../../../../actions/modals';

import CloseIcon from '../../../common/modal/CloseIcon';

import Info from './Info';
import Items from './Items';

class UpdateSpecial extends React.Component {

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.closeUpdateModal = this.closeUpdateModal.bind(this);
        this.updateSpecialInfo = this.updateSpecialInfo.bind(this);
        this.updateSpecialItems = this.updateSpecialItems.bind(this);

        this.state = {
            displayView: 0
        };
    }

    handleChange(event, newValue) {
        this.props.dispatch(specialFormUpdate({ prop: 'error', value: '' }));
        this.props.dispatch(specialFormUpdate({ prop: 'message', value: '' }));
        this.setState(() => ({displayView: newValue}))
    }

    closeUpdateModal() {
        this.props.dispatch(closeSpecialUpdate());
        this.setState(() => ({displayView: 0}));
    }

    updateSpecialInfo() {

        // perform some error checking
        if (this.props.specialForm.name.trim() == "" || !validateCurrency(this.props.specialForm.price) || this.props.specialForm.price <= 0) {
            this.props.dispatch(specialFormError("Please enter a special name and valid price greater than 0."));
        }else if(this.props.specialForm.saveSchedule && this.props.specialForm.scheduleName.trim() == ""){
            this.props.dispatch(specialFormError("Please enter a name for the availability schedule."));
        }else if(this.props.specialForm.saveSchedule && this.props.restaurant.availability_schedules.map((schedule) => {return schedule.name}).includes(this.props.specialForm.scheduleName.trim())){
            this.props.dispatch(specialFormError("An availability schedule with that name already exists."));
        } else {

            // clear the error and attempt to update the special info
            this.props.dispatch(specialFormError(""));
            this.props.dispatch(updateSpecial(this.props.restaurantId, this.props.special.id, 'info', this.props.specialForm));
        }
    }

    updateSpecialItems() {

        let valid = true;

        if (this.props.specialForm.items.length <= 0) {
            valid = false;
            this.props.dispatch(specialFormError("Please enter at least one item for the special."));
        }

        if (valid) {
            this.props.dispatch(specialFormError(""));
            this.props.dispatch(updateSpecial(this.props.restaurantId, this.props.special.id, 'items', this.props.specialForm));
        }
    }

    renderUpdateModalActiveTab() {
        if (this.props.loading) {
            return (
                <div className="page-loading">
                    <CircularProgress size={100} />
                </div>
            );
        }
        else {
            switch(this.state.displayView) {
                case 0:
                    return this.renderUpdateModalInfo();

                case 1:
                    return this.renderUpdateModalItems();
            }
        }
    }

    renderUpdateFeedback() {
        return (
            <div>
                { this.props.specialForm.error && (
                    <Typography className="modal-error">
                        { this.props.specialForm.error }
                    </Typography>
                )}

                { this.props.specialForm.message && (
                    <Typography className="modal-success">
                        { this.props.specialForm.message }
                    </Typography>
                )}
            </div>
        );
    }

    renderUpdateModalInfo() {
        return (
            <div>
                {this.renderUpdateFeedback()}

                <Info />

                <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom" onClick={this.updateSpecialInfo}>
                    Update Special
                </Button>
            </div>
        );
    }

    renderUpdateModalItems() {
        return (
            <div>
                {this.renderUpdateFeedback()}

                <Typography variant="h5" id="modal-title">
                    Available Items
                </Typography>

                <Typography className="modal-description">
                    Enter any available food items with the special, along with a price/size if necessary.
                </Typography>

                <Items
                    checkAddSpecialItems={this.checkAddSpecialItems}
                >
                    <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom" onClick={this.updateSpecialItems}>
                        Update Special
                    </Button>
                </Items>         
            </div>
        );
    }
    
    render() {
        return (
            <Modal
                aria-labelledby="special-update"
                aria-describedby="special-update"
                open={this.props.updateModalOpen}
                onClose={() => this.closeUpdateModal()}
                className="modal_admin"
                disableBackdropClick
            >
                <div className="modal-member">
                    <Typography variant="h4" id="modal-title">
                        <CloseIcon/>
                    </Typography>
                    <Tabs
                        value={this.state.displayView}
                        onChange={this.handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        centered
                    >
                        <Tab label="General Info" />
                        <Tab label="Items" />
                    </Tabs>

                    <Typography variant="h4" id="modal-title" style={{padding: "20px 0px"}}>
                        Update {this.props.special.name}
                    </Typography>

                    {this.renderUpdateModalActiveTab()}
                </div>
            </Modal>
        );
    }
};

const mapStateToProps = (state, props) => {
    return {
        loading: state.specials.loading,
        restaurant: state.selectedRestaurant.restaurant,
        specialForm: state.specialForm,
        updateModalOpen: state.modals.specialUpdate,
    };
};

export default connect(mapStateToProps)(UpdateSpecial);