import React from 'react';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';

import { closeCompleteOrder } from '../../actions/modals';
import { completeOrderFormReset } from '../../actions/restaurant/completeOrderForm';

import CloseIcon from '../../components/common/modal/CloseIcon';

class OrderComplete extends React.Component {

    constructor(props) {
        super(props);

        this.toggleModal = this.toggleModal.bind(this);
    }

    toggleModal(isOpen) {
        if (!isOpen) {
            this.props.dispatch(completeOrderFormReset());
            this.props.dispatch(closeCompleteOrder());
        }
    }
    
    render() {
        return (
            <Modal
                aria-labelledby="order-confirm"
                aria-describedby="order-confirm"
                open={this.props.modalOpen}
                onClose={() => this.toggleModal(false)}
                className="modal_admin"
                disableBackdropClick
            >
                <div className="modal-member">

                    <Typography variant="h4" className="modal-title">
                        <CloseIcon/>
                    </Typography>

                    <Typography variant="h2">
                        Complete order?
                    </Typography>

                    { this.props.completeOrderForm.error && (
                        <Typography className="modal-error">
                            { this.props.completeOrderForm.error }
                        </Typography>
                    )}

                    {(this.props.completeOrderForm.loading) && (
                        <div className="page-loading modal-loading">
                            <CircularProgress size={100} />
                        </div>
                    )}
                    {(!this.props.completeOrderForm.loading) && (
                        <div>
                            <Typography>
                                Would you like to mark this order as completed?
                            </Typography>

                            <Button variant="contained" fullWidth={true} className="modal-button-bottom" onClick={() => this.props.completeOrder(this.props.order.id)}>
                                Confirm
                            </Button>

                            <Button variant="contained" fullWidth={true} color="secondary" className="modal-button-bottom" onClick={() => this.toggleModal(false)}>
                                Cancel
                            </Button>
                        </div>
                    )}
                </div>
            </Modal>
        );
    }
};

const mapStateToProps = (state, props) => {
    return {
        loading: state.orders.loading,
        completeOrderForm: state.completeOrderForm,
        modalOpen: state.modals.completeOrder
    };
};

export default connect(mapStateToProps)(OrderComplete);