// Filter out locations already associated with entity (dispatch center/app directory/etc.)

export default (active_locations, this_location) => {

    let valid_location = true;

    for (const [key, value] of Object.entries(active_locations)) {
        if(value.location_id == this_location.id){
            valid_location = false;
        }
    }

    return valid_location;
    
};