
export const foodItemModifierFormUpdate = ({ prop, value }) => {
    return {
        type: 'FOOD_ITEM_MODIFIER_FORM_UPDATE',
        foodItemModifier: { prop, value }
    };
};

export const foodItemModifierFormSet = (foodItemModifier, index) => {
    return {
        type: 'FOOD_ITEM_MODIFIER_FORM_SET',
        foodItemModifier: {
            ...foodItemModifier,
            index
        }
    };
}

export const foodItemModifierFormReset = () => {
    return {
        type: 'FOOD_ITEM_MODIFIER_FORM_RESET',
    };
}