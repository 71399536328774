import React from 'react';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import EditIcon from '@material-ui/icons/Edit';
import AssignmentIcon from '@material-ui/icons/Assignment';
import Typography from '@material-ui/core/Typography';

import checkPermissions from '../../../utils/checkUserPermissions';

class LocationsList extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    renderEnableButton(item) {
        if (item.enabled) {
            return (
                <Button fullWidth={true} color="secondary" className="modal-button-list" onClick={() => this.props.toggleEnabled(item, 0)}>
                    Disable
                </Button>
            );
        }
        else {
            return (
                <Button fullWidth={true} color="primary" className="modal-button-list" onClick={() => this.props.toggleEnabled(item, 1)}>
                    Enable
                </Button>
            );
        }
    }

    renderOpenToggle(item) {
        if (item.open) {
            return (
                <Button variant="contained" fullWidth={true} color={'secondary'} className={"modal-button-list"} onClick={() => this.props.toggleOpen(item, 'close')}>
                    Close Store
                </Button>
            );
        }
        else {
            return (
                <Button variant="contained" fullWidth={true} color={'primary'} className={"modal-button-list"} onClick={() => this.props.toggleOpen(item, 'open')}>
                    Open Store
                </Button>
            );
        }
    }

    render() {
        // this.props.condensed is always currently true as this is now only called from the dashboard page
        // @TODO Those checks should be removed in the future if that is looking like a permanent change
        let addressGridVal = (this.props.user.permissionLevel == "superuser") ? 2: 3;
        if (this.props.locations.length > 0) {
            return (
                <Grid container>
                    <Grid item xs={12} className={(this.props.condensed) ? "inline-sortable-list" : "sortable-list"}>
                    {this.props.locations.map((location, index) => (
                        <div key={"item-"+index}>
                            <Grid className="sortable-menu-item" container>
                                <Grid item xs={(this.props.condensed) ? addressGridVal : 6} className="list-name">
                                    <Typography>{location.address}, {location.city} {<br />}
                                        Location ID: {location.id}</Typography>
                                </Grid>
                                
                                {this.props.condensed && (
                                    <Grid item xs={1} className="list-sort">
                                        <Typography 
                                            className={(location.open) ? 'location-list-open' : 'location-list-closed'}>{(location.open) ? 'Taking Orders' : 'Not Taking Orders'}
                                        </Typography>
                                    </Grid>
                                )}
                                {checkPermissions(this.props.user, 'restaurant_manager', location.restaurant_id) && (
                                    <Grid item xs={(this.props.condensed) ? 2 : 1} className="list-sort list-right-padding">
                                        {this.renderOpenToggle(location)}
                                    </Grid>
                                )}
                                <Grid item xs={(this.props.condensed) ? 2 : 1} className="list-sort list-right-padding">
                                    <Button variant="contained" fullWidth={true} color={'primary'} className={"modal-button-list"} onClick={() => { this.props.history.push('/'+location.restaurant_id+'/orders/'+location.id) }}>
                                        View Orders
                                    </Button>
                                </Grid>
                                <Grid item xs={(this.props.condensed) ? 2 : 1} className="list-sort list-right-padding">
                                    <Button variant="contained" fullWidth={true} color={'primary'} className={"modal-button-list"} onClick={() => { this.props.history.push('/'+location.restaurant_id+'/reports/orders/'+location.id) }}>
                                        Summary
                                    </Button>
                                </Grid>
                                {//!this.props.condensed && 
                                checkPermissions(this.props.user, 'restaurant_manager', location.restaurant_id) && (
                                    <Grid item xs={1} className="list-sort">
                                        <Button fullWidth={true} color="primary" className="modal-button-list" onClick={() => this.props.toggleUpdateModal(true, location)}>
                                            <EditIcon className="modal-button-icon" />
                                        </Button>
                                    </Grid>
                                )}
                                {//!this.props.condensed && 
                                checkPermissions(this.props.user, 'restaurant_manager', location.restaurant_id) && (
                                    <Grid item xs={1} className="list-sort">
                                        <Button fullWidth={true} color="primary" className="modal-button-list" onClick={() => this.props.createTestOrder(location)}>
                                            <AssignmentIcon className="modal-button-icon" />
                                        </Button>
                                    </Grid>
                                )}
                                {//!this.props.condensed && 
                                checkPermissions(this.props.user, 'superuser') && (
                                    <Grid item xs={1} className="list-sort">
                                        {this.renderEnableButton(location)}
                                    </Grid>
                                )}
                            </Grid>
                        </div>
                    ))}
                    </Grid>
                </Grid>
            );
        } else {
            return (
                <Grid container>
                    <Grid item xs={12}>
                        <Typography className="no-results">No locations entered.</Typography>
                    </Grid>
                </Grid>
            );
        }
    }
};

const mapStateToProps = (state, props) => {
    return {
        user: state.loggedUser.user
    };
};

export default connect(mapStateToProps)(LocationsList);