import React from 'react';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';

import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';

import LoadScreen from '../common/LoadScreen';
import verifyLogin from '../../utils/verifyLogin';
import { 
    loadManagers, 
    setManagerFilter, 
    addManager,
    addNewManager,
    revokeManagerAccess
} from '../../actions/restaurant/managers';
import { managerFormUpdate, managerFormReset } from '../../actions/restaurant/managerForm';
import { openManagerAdd, closeManagerAdd } from '../../actions/modals';
import { loadPageData } from '../../actions/auth';
import filterManagers from '../../selectors/managers';

import ManagersList from './common/ManagersList';
import AddManager from './modal/AddManager';

import checkPermissions from '../../utils/checkUserPermissions';

class ManagersPage extends React.Component {

    constructor(props) {
        super(props);

        this.toggleModal = this.toggleModal.bind(this);
        this.enableManager = this.enableManager.bind(this);
        this.enableNewManager = this.enableNewManager.bind(this);
        this.revokeAccess = this.revokeAccess.bind(this);
        this.checkPermissions = this.checkPermissions.bind(this);

        this.state = {
            sidebarMenuOpen: false,
            selectedManager: {}
        };
    }

    componentDidMount() {
        verifyLogin(this.props.history);
    }

    checkPermissions() {
        if (checkPermissions(this.props.user, 'restaurant_admin', this.props.match.params.id)) {
            // load the restaurant
            this.props.dispatch(loadPageData(this.props.match.params));
            this.props.dispatch(loadManagers(this.props.match.params.id));
        } else {
            this.props.history.push('/permission-denied');
        }
    }

    componentDidUpdate() {
        verifyLogin(this.props.history);
    }

    toggleModal(isOpen) {
        if (isOpen) {
            this.props.dispatch(managerFormReset());
            this.props.dispatch(openManagerAdd());
            this.props.dispatch(managerFormUpdate({ prop: 'activeTab', value: 0 }));
        }
        else {
            this.props.dispatch(closeManagerAdd());
        }
    }

    enableManager(manager, managerType) {

        // attempt to add the user
        this.props.dispatch(addManager(this.props.match.params.id, manager.id, managerType));
    }

    enableNewManager(managerForm, managerType) {

        // attempt to add the user
        this.props.dispatch(addNewManager(this.props.match.params.id, managerForm, managerType));
    }

    revokeAccess(manager) {

        if (confirm("Are you sure you want to revoke access? This can't be undone.")) {
            this.props.dispatch(revokeManagerAccess(this.props.match.params.id, manager.id));
        }
    }

    renderManagers() {

        if (this.props.loading) {
            return (
                <div className="page-loading">
                    <CircularProgress size={100} />
                </div>
            );
        }
        else {
            if (this.props.managers.length <= 0) {
                return (
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography className="no-results">No managers entered.</Typography>
                        </Grid>
                    </Grid>
                );
            }
            else {
                return (
                    <ManagersList
                        managers={this.props.managers}
                        revokeAccess={this.revokeAccess}
                    />
                );
            }
        }
    }
    
    render() {
        return (
            <LoadScreen
                checkPermissions={this.checkPermissions}
            >
                <div className="card-container">
                    <Grid container className="menu-page-header">
                        <Grid item xs={12} className="heading-grid">
                            <h1>Restaurant Managers</h1>
                            <FormControl fullWidth>
                                <TextField 
                                    label="Filter Managers" 
                                    variant="outlined" 
                                    value={this.props.filter}
                                    onChange={(e) => this.props.dispatch(setManagerFilter(e.target.value))} 
                                />
                            </FormControl>
                        </Grid>
                    </Grid>

                    {this.renderManagers()}
                </div>


                <div className="window-fab">
                    <Fab className="btn-fab btn-add" onClick={() => this.toggleModal(true)}>
                        <AddIcon />
                    </Fab>
                </div>
                

                <AddManager 
                    restaurantId={this.props.match.params.id}
                    enableManager={this.enableManager}
                    enableNewManager={this.enableNewManager}
                />
            </LoadScreen>
        );
    }
};

const mapStateToProps = (state, props) => {
    return {
        loading: state.managers.loading || state.restaurants.loading,
        restaurant: state.selectedRestaurant.restaurant,
        managers: filterManagers(state.managers.managers, {text: state.managers.filter}),
        managerForm: state.managerForm,
        user: state.loggedUser.user,
    };
};

export default connect(mapStateToProps)(ManagersPage);