import { createStore, applyMiddleware, combineReducers } from 'redux';
import ReduxThunk from 'redux-thunk';

import loggedUserReducer from '../reducers/loggedUser';
import restaurantsReducer from '../reducers/restaurants';
import selectedRestaurantReducer from '../reducers/selectedRestaurant';
import loginFormReducer from '../reducers/loginForm';
import forgotPasswordFormReducer from '../reducers/forgotPasswordForm';
import resetPasswordFormReducer from '../reducers/resetPasswordForm';
import registerManagerFormReducer from '../reducers/registerManagerForm';
import sidebarReducer from '../reducers/sidebar';
import modalsReducer from '../reducers/modals';
import timezonesReducer from '../reducers/timezones';

import restaurantInfoFormReducer from '../reducers/restaurant/infoForm';
import locationsReducer from '../reducers/restaurant/locations';
import confirmOrderFormReducer from '../reducers/restaurant/confirmOrderForm';
import completeOrderFormReducer from '../reducers/restaurant/completeOrderForm';
import ordersReducer from '../reducers/restaurant/orders';
import selectedOrderReducer from '../reducers/restaurant/selectedOrder';
import locationFormReducer from '../reducers/restaurant/locationForm';
import promoCodeFormReducer from '../reducers/restaurant/promoCodeForm';
import promoCodeAdminFormReducer from '../reducers/restaurant/promoCodeAdminForm';
import massPromoCodeAdminFormReducer from '../reducers/restaurant/massPromoCodeAdminForm';
import promoCodesReducer from '../reducers/restaurant/promoCodes';
import managersReducer from '../reducers/restaurant/managers';
import managerFormReducer from '../reducers/restaurant/managerForm';
import loyaltyReducer from '../reducers/restaurant/loyalty';
import loyaltyRewardFormReducer from '../reducers/restaurant/loyaltyRewardForm';
import loyaltyPointsFormReducer from '../reducers/restaurant/loyaltyPointsForm';

import pizzasReducer from '../reducers/menu/pizzas/pizzas';
import pizzaFormReducer from '../reducers/menu/pizzas/pizzaForm';
import pizzaSettingsFormReducer from '../reducers/menu/pizzas/settingsForm';
import toppingFormReducer from '../reducers/menu/pizzas/toppingForm';
import crustFormReducer from '../reducers/menu/pizzas/crustForm';
import sauceFormReducer from '../reducers/menu/pizzas/sauceForm';
import customPizzaFormReducer from '../reducers/menu/pizzas/customPizzaForm';

import categoriesReducer from '../reducers/menu/categories/categories';
import categoryFormReducer from '../reducers/menu/categories/categoryForm';
import selectedCategoryReducer from '../reducers/menu/categories/selectedCategory';
import foodItemsReducer from '../reducers/menu/fooditems/foodItems';
import foodItemFormReducer from '../reducers/menu/fooditems/foodItemForm';
import choiceFormReducer from '../reducers/menu/fooditems/choiceForm';
import foodItemModifierFormReducer from '../reducers/menu/fooditems/foodItemModifierForm';

import specialsReducer from '../reducers/menu/specials/specials';
import specialFormReducer from '../reducers/menu/specials/specialForm';
import itemFormReducer from '../reducers/menu/specials/itemForm';

import modifiersReducer from '../reducers/menu/modifiers/modifers';
import modifierFormReducer from '../reducers/menu/modifiers/modifierForm';
import addonsReducer from '../reducers/menu/addons/addons';
import addonFormReducer from '../reducers/menu/addons/addonForm';

import importMenuReducer from '../reducers/restaurant/importMenu';

import directoriesReducer from '../reducers/superuser/appDirectories/directories';
import appDirectoryFormReducer from '../reducers/superuser/appDirectories/appDirectoryForm';
import centersReducer from '../reducers/superuser/dispatchCenters/centers';
import dispatchCenterFormReducer from '../reducers/superuser/dispatchCenters/dispatchCenterForm';
import adminRestaurantLocationsReducer from '../reducers/superuser/restaurantLocations';
import userManagementFormReducer from '../reducers/superuser/userManagementForm';
import addUserFormReducer from '../reducers/superuser/addUserForm';
import menuExportFormReducer from '../reducers/superuser/menuExportForm';

import reportSearchFormReducer from '../reducers/reports/searchForm';
import reportItemizedFormReducer from '../reducers/reports/itemizedForm';
import reportOrdersReducer from '../reducers/reports/orders';

/*
*
*   NOTE: When adding new reducers, be sure to add them to actions/loggedUser/resetAllReducers
*
*/

export default () => {
    const store = createStore(
        combineReducers({
            loginForm: loginFormReducer,
            forgotPasswordForm: forgotPasswordFormReducer,
            resetPasswordForm: resetPasswordFormReducer,
            registerManagerForm: registerManagerFormReducer,
            loggedUser: loggedUserReducer,
            restaurants: restaurantsReducer,
            restaurantInfoForm: restaurantInfoFormReducer,
            locations: locationsReducer,
            locationForm: locationFormReducer,
            orders: ordersReducer,
            reportOrders: reportOrdersReducer,
            selectedOrder: selectedOrderReducer,
            confirmOrderForm: confirmOrderFormReducer,
            completeOrderForm: completeOrderFormReducer,
            promoCodeForm: promoCodeFormReducer,
            promoCodeAdminForm: promoCodeAdminFormReducer,
            massPromoCodeAdminForm: massPromoCodeAdminFormReducer,
            promoCodes: promoCodesReducer,
            managers: managersReducer,
            managerForm: managerFormReducer,
            loyalty: loyaltyReducer,
            loyaltyRewardForm: loyaltyRewardFormReducer,
            loyaltyPointsForm: loyaltyPointsFormReducer,
            pizzas: pizzasReducer,
            pizzaForm: pizzaFormReducer,
            pizzaSettingsForm: pizzaSettingsFormReducer,
            toppingForm: toppingFormReducer,
            crustForm: crustFormReducer,
            sauceForm: sauceFormReducer,
            customPizzaForm: customPizzaFormReducer,
            categories: categoriesReducer,
            categoryForm: categoryFormReducer,
            selectedCategory: selectedCategoryReducer,
            foodItems: foodItemsReducer,
            foodItemForm: foodItemFormReducer,
            choiceForm: choiceFormReducer,
            foodItemModifierForm: foodItemModifierFormReducer,
            specials: specialsReducer,
            specialForm: specialFormReducer,
            addons: addonsReducer,
            addonForm: addonFormReducer,
            modifiers: modifiersReducer,
            modifierForm: modifierFormReducer,
            itemForm: itemFormReducer,
            directories: directoriesReducer,
            appDirectoryForm: appDirectoryFormReducer,
            dispatchCenters: centersReducer,
            dispatchCenterForm: dispatchCenterFormReducer,
            selectedRestaurant: selectedRestaurantReducer,
            sidebar: sidebarReducer,
            modals: modalsReducer,
            timezones: timezonesReducer,
            adminRestaurantLocations: adminRestaurantLocationsReducer,
            userManagementForm: userManagementFormReducer,
            addUserForm: addUserFormReducer,
            menuExportForm: menuExportFormReducer,
            menuImportForm: importMenuReducer,
            reportSearchForm: reportSearchFormReducer,
            reportItemizedForm: reportItemizedFormReducer,
        }),
        {},
        applyMiddleware(ReduxThunk)
    );

    return store;
}