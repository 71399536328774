import React from 'react';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';

import validateCurrency from '../../../utils/validateCurrency';
import { addRestaurant } from '../../../actions/restaurants';
import { infoFormReset, infoFormError } from '../../../actions/restaurant/infoForm';
import { openRestaurantCreate, closeRestaurantCreate } from '../../../actions/modals';

import CloseIcon from '../../common/modal/CloseIcon';

import Info from './GeneralInfo';

class CreateRestaurant extends React.Component {

    constructor(props) {
        super(props);

        this.toggleCreateModal = this.toggleCreateModal.bind(this);
        this.submitCreateRestaurant = this.submitCreateRestaurant.bind(this);

        this.state = {
            activeStep: 0
        };
    }

    toggleCreateModal(isOpen) {
        if (isOpen) {
            this.props.dispatch(infoFormReset());
            this.props.dispatch(openRestaurantCreate());
        }
        else {
            this.props.dispatch(closeRestaurantCreate());
        }
    }

    submitCreateRestaurant() {
        // perform some error checking
        if (this.props.infoForm.name.trim() == "") {

            // restaurant name isn't present
            this.props.dispatch(infoFormError("Please enter a restaurant name."));
        }
        else if (!validateCurrency(this.props.infoForm.additional_charge) || this.props.infoForm.additional_charge < 0) {

            // additional charge value isn't valid
            this.props.dispatch(infoFormError("Please make sure that your additional charge is a valid number that is not less than zero."));
        }
        else if (this.props.curUser && 
            this.props.curUser.superuser && (
            (typeof this.props.infoForm.standard_charge_amount === 'string' && !this.props.infoForm.standard_charge_amount.match(/^\d+(\.\d+)?$/)) || 
            parseFloat(this.props.infoForm.standard_charge_amount)  < 0)) {

            // additional charge value isn't valid
            this.props.dispatch(infoFormError("Please make sure that your standard charge is a valid number that is not less than zero."));
        }
        else if (this.props.curUser && 
            this.props.curUser.superuser && (
            this.props.infoForm.whitelabel_enabled && (typeof this.props.infoForm.whitelabel_charge_amount === 'string' && !this.props.infoForm.whitelabel_charge_amount.match(/^\d+(\.\d+)?$/)) || 
            parseFloat(this.props.infoForm.whitelabel_charge_amount)  < 0)) {

            // additional charge value isn't valid
            this.props.dispatch(infoFormError("Please make sure that your whitelabel charge is a valid number that is not less than zero."));
        }
        else {

            // clear the error and attempt to update the food item info
            this.props.dispatch(infoFormError(""));
            this.props.dispatch(addRestaurant(this.props.infoForm));
        }
    }
    
    render() {
        return (
            <Modal
                aria-labelledby="restaurant-add"
                aria-describedby="restaurant-add"
                open={this.props.createModalOpen}
                onClose={() => this.toggleCreateModal(false)}
                className="modal_admin"
                disableBackdropClick
            >
                <div className="modal-member">
                    <Typography variant="h4" id="modal-title">
                        <CloseIcon/>
                    </Typography>
                    <Typography variant="h2">
                        Add a New Restaurant
                    </Typography>
                    <Typography className="modal-description">
                        Please enter the new restaurant information, than click 'Add Restaurant'.
                    </Typography>

                    {(this.props.loading) && (
                        <div className="page-loading">
                            <CircularProgress size={100} />
                        </div>
                    )}

                    {(!this.props.loading) && (
                        <div>
                            { this.props.infoForm.error && (
                                <Typography className="modal-error">
                                    { this.props.infoForm.error }
                                </Typography>
                            )}

                            <Info />

                            <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom" onClick={this.submitCreateRestaurant}>
                                Add Restaurant
                            </Button>
                        </div>
                    )}
                </div>
            </Modal>
        );
    }
};

const mapStateToProps = (state, props) => {
    return {
        loading: state.restaurantInfoForm.loading,
        restaurant: state.selectedRestaurant.restaurant,
        infoForm: state.restaurantInfoForm,
        createModalOpen: state.modals.restaurantCreate
    };
};

export default connect(mapStateToProps)(CreateRestaurant);