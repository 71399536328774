import axios from 'axios';
import { API_URL } from '../../config/constants';
import { readErrMsg } from '../helpers/readErrMsg';
import { loadPromoCodes } from '../../actions/superuser/promoCodeAdminForm';

export const setSelectedMassAdminPromoCode = (promoCode) => {
    return {
        type: 'SET_SELECTED_ADMIN_MASS_PROMO_CODE',
        promoCode: promoCode,
    }
}

export const setEditingMassAdminPromoCode = () => {
    return {
        type: 'SET_EDITING_ADMIN_MASS_PROMO_CODE',
    }
}

export const resetEditingMassAdminPromoCode = () => {
    return {
        type: 'RESET_EDITING_ADMIN_MASS_PROMO_CODE',
    }
}

export const massPromoCodeAdminFormUpdate = ({ prop, value }) => {

    return {
        type: 'MASS_PROMO_CODE_ADMIN_FORM_UPDATE',
        promoCode: { prop, value }
    };
};

export const massPromoCodeAdminUpdateValidPromoDays = ({ prop, value }) => {

    return {
        type: 'MASS_PROMO_CODE_FORM_UPDATE_VALID_DAYS',
        promoCode: { prop, value }
    };
};

export const massPromoCodeAdminFormError = (message) => {

    return {
        type: 'MASS_PROMO_CODE_ADMIN_FORM_ERROR',
        message
    };
};

export const massPromoCodeAdminFormSuccess = (message) => {

    return {
        type: 'MASS_PROMO_CODE_ADMIN_FORM_SUCCESS',
        message
    };
};

export const massPromoCodeAdminFormReset = () => {
    
    return {
        type: 'MASS_PROMO_CODE_ADMIN_FORM_RESET',
    };
}

export const setMassPromoCodeAdminLoading = () => {
    
    return {
        type: 'MASS_PROMO_CODE_ADMIN_FORM_LOADING',
    };
}

export const setMassPromoCodeAdminDoneLoading = () => {
    
    return {
        type: 'MASS_PROMO_CODE_ADMIN_FORM_DONE_LOADING',
    };
}

export const updateMassPromoCode = (formData, closeForm) => {

    return (dispatch) => {

        dispatch(setMassPromoCodeAdminLoading());

        return axios.post(
            API_URL+'/api/support/manage/promocode', 
            {
                total_avail: formData.total_avail,
                total_target: formData.total_target,
                total_type: formData.total_type,
                valid_days: formData.valid_days,
                total: formData.total,
                start_time: formData.start_time,
                end_time: formData.end_time,
                label: formData.label,
                id: formData.selectedPromoCode.id,
                scope: 'edit'
            }
        ).then(function(res) {
            // update these

            dispatch(massPromoCodeAdminFormReset());
            dispatch(setMassPromoCodeAdminDoneLoading());
            dispatch(massPromoCodeAdminFormError(''));
            dispatch(closeForm());
            dispatch(loadPromoCodes());
        })
        .catch(function(err) {
            dispatch(setMassPromoCodeAdminDoneLoading());

            let response = readErrMsg(err);
            if (response.statusCode == 500) {
                dispatch(massPromoCodeAdminFormError('An unexpected error was encountered'));
            }
            else {
                dispatch(massPromoCodeAdminFormError(response.msg));
            }
            
        });
    };
};

export const addMassPromoCode = (formData, closeForm) => {

    return (dispatch) => {

        dispatch(setMassPromoCodeAdminLoading());

        return axios.post(
            API_URL+'/api/support/promocodes/masscreate', 
            {
                total_avail: formData.total_avail,
                total_target: formData.total_target,
                total_type: formData.total_type,
                valid_days: formData.valid_days,
                total: formData.total,
                start_time: formData.start_time,
                end_time: formData.end_time,
                max_uses: formData.max_uses,
                codes_to_create: formData.codes_to_create,
                label: formData.label,
            }
        ).then(function(res) {
            // update these

            dispatch(massPromoCodeAdminFormReset());
            dispatch(setMassPromoCodeAdminDoneLoading());
            dispatch(massPromoCodeAdminFormError(''));
            dispatch(closeForm());
            dispatch(loadPromoCodes());
        })
        .catch(function(err) {
            dispatch(setMassPromoCodeAdminDoneLoading());

            let response = readErrMsg(err);
            if (response.statusCode == 500) {
                dispatch(massPromoCodeAdminFormError('An unexpected error was encountered'));
            }
            else {
                dispatch(massPromoCodeAdminFormError(response.msg));
            }
            
        });
    };
};