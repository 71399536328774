import axios from 'axios';
import { API_URL } from '../../config/constants';

export const exportMenuFormUpdate = ({ prop, value }) => {

    return {
        type: 'EXPORT_MENU_FORM_UPDATE',
        directory: { prop, value }
    };
};

export const exportMenuReset = () => {

    return {
        type: 'EXPORT_MENU_FORM_RESET'
    };
};

export const getExportedRestaurantMenu = (restaurant_id, selectedRestaurant, menuExportForm) => {
    return (dispatch) => {
        return axios.post(API_URL+'/api/restaurant/'+restaurant_id+'/menu/export', {
            pizzas: menuExportForm.pizzas,
            fooditems: menuExportForm.food_items,
            beverages: menuExportForm.beverages,
            specials: menuExportForm.specials,
            addons: menuExportForm.addons,
            disabled: menuExportForm.disabled,
            responseType: 'blob',
        })
            .then(function(response) {

                // turn the response into a csv download
                let fileName = selectedRestaurant.sitename + '_menu_export.csv';

                var fileDownload = require('js-file-download');
                fileDownload(response.data, fileName);

            })
            .catch(function(err) {
                console.log("Err!");
                console.log(err);
                console.log(err.response);
            });
    };
}

export const loadAdminRestaurantLocations = () => {
    return (dispatch) => {
        return axios.get(API_URL+'/api/locations')
            .then(function(res) {

                // load the directories
                dispatch(setAdminRestaurantLocations(res.data.data.restaurants));

            })
            .catch(function(err) {
                console.log("Err!");
                console.log(err);
            });
    };
}