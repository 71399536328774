import React from 'react';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import DatePicker from "react-datepicker";
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import moment from 'moment';

import LoadScreen from '../common/LoadScreen';
import verifyLogin from '../../utils/verifyLogin';
import { 
    loadLocations, 
} from '../../actions/restaurant/locations';
import { 
    itemizedFormUpdate,
    loadItemizedReport,
    loadItemizedReportCSV,
    itemizedFormError,
    itemizedFormReset,
    toggleRowVisibility,
    setItemizedOrdersSearchLoading,
} from '../../actions/reports/itemizedForm';

import checkPermissions from '../../utils/checkUserPermissions';

import "jspdf-autotable";
// import generateItemizedPDF from "./common/generateItemizedPDF";

class ItemizedReportsPage extends React.Component {

    constructor(props) {
        super(props);

        this.submitSearchOrders = this.submitSearchOrders.bind(this);
        this.checkPermissions = this.checkPermissions.bind(this);

        this.state = {
            sidebarMenuOpen: false,
            selectedOrder: {},
            searchedData: this.props.itemizedForm,
            itemizedCategories: [
                'foodItems',
                'pizzas',
                'specials',
                'addons',
            ],
            rowColorIncrement: 0,
        };
    }

    componentDidMount() {
        verifyLogin(this.props.history);
    }

    checkPermissions(){
        if (checkPermissions(this.props.user, 'restaurant_staff', this.props.match.params.id)) {
            
            this.props.dispatch(loadLocations(this.props.match.params.id));
            // this.props.dispatch(loadItemizedReport(this.props.match.params.id, searchData.location_id, searchData));
        } else {
            this.props.history.push('/permission-denied');
        }
    }

    componentDidUpdate() {
        verifyLogin(this.props.history);
    }

    submitSearchOrders(searchData) {

        this.props.dispatch(itemizedFormUpdate({ prop: 'initialSet', value: false }));

        this.state.searchedData = searchData;
        this.props.dispatch(setItemizedOrdersSearchLoading());
        this.props.dispatch((loadItemizedReport(this.props.match.params.id, searchData.location_id, searchData)));

    }

    toggleRow(foodItemID){
        this.props.dispatch(toggleRowVisibility(foodItemID));
    }

    renderItemizedOrders() {

        this.state.rowColorIncrement = 0;

        let commasRegex = /\B(?=(\d{3})+(?!\d))/g;

        if (this.props.loading) {
            return (
                <div className="page-loading">
                    <Grid container>
                        <Grid item xs={12} className="restaurant-card-grid">
                        {this.props.generatingFile && (
                            <div className="form-image-container">
                                <Typography>Hang tight! We're preparing your file and it should be ready shortly!</Typography>    
                            </div>
                        )}
                        {this.props.performingSearch && (
                            <div className="form-image-container">
                                <Typography>Please wait while we perform your search, depending on the volume of orders this may take a few moments.</Typography>    
                            </div>
                        )}
                            <div className="form-image-container">
                                <CircularProgress size={100} />
                            </div>
                        </Grid>
                    </Grid>
                </div>
            );
        }
        else if(this.props.itemizedForm.initialSet == true) {
            return (
                <div>
                    <Typography variant='h4' align='center'>
                        Choose a date range and location
                    </Typography>
                </div>
            );
        }
        else if(this.props.itemizedForm.orderCount == 0){
            return (
                <div>
                    <Typography variant='h4' align='center'>
                        No orders found
                    </Typography>
                </div>
            );
        }
        else {
            return (
                <div>
                    <Grid container>
                        <Grid item xs={4} className="restaurant-card-grid">
                            <Typography align='center' variant='h6'>Item Name</Typography>
                        </Grid>
                        <Grid item xs={4} className="restaurant-card-grid">
                            <Typography align='center' variant='h6'>Quantity</Typography>
                        </Grid>
                        <Grid item xs={4} className="restaurant-card-grid">
                            <Typography align='center' variant='h6'>Sales</Typography>
                        </Grid>
                        {/* this.state.itemizedCategories.map((categories) => { */}
                                {/* return this.props.itemizedForm[categories] && Object.entries(this.props.itemizedForm[categories]).map((item, i) => { */}
                        {
                            Object.entries(this.props.itemizedForm.orders).map((item, i) => {
                                this.state.rowColorIncrement++;
                                let subRowColorIncrement = 0;
                                let orderIndex = item[0];

                                let ChoiceSales = item[1].ChoiceSales ?? 0;
                                let AddonSales = item[1].FoodItemAddonsSales ?? 0;
                                let ModifierSales = item[1].ModifierSales ?? 0;
                                let sizeRows = null;
                                let choiceRows = null;
                                let addonRows = null;
                                let modifierRows = null;
                                let rowToggled = (this.props.itemizedForm.hiddenRows[item[1]['id']] ?? false);
                                let hidden = rowToggled ? ' ': 'display-none';

                                if(item[1].Sizes){
                                    sizeRows = Object.entries(item[1].Sizes).map((item2, i2) => {
                                        subRowColorIncrement++;
                                        return (
                                            <Grid container className={hidden}>
                                                <Grid item xs={4} className={subRowColorIncrement % 2 == 0 ? 'restaurant-grid-sub-row-color-alternate': 'restaurant-grid-sub-row-color'}>
                                                    <Typography align='center'>{item[1].Name} - {item2[1].Name}</Typography>
                                                </Grid>
                                                <Grid item xs={4} className={subRowColorIncrement % 2 == 0 ? 'restaurant-grid-sub-row-color-alternate': 'restaurant-grid-sub-row-color'}>
                                                    <Typography align='center'>{item2[1].Quantity}</Typography>
                                                </Grid>
                                                <Grid item xs={4} className={subRowColorIncrement % 2 == 0 ? 'restaurant-grid-sub-row-color-alternate': 'restaurant-grid-sub-row-color'}>
                                                    <Typography align='center'>${item2[1].Sales.toFixed(2).replace(commasRegex, ",")}</Typography>
                                                </Grid>
                                            </Grid>
                                        )});
                                }

                                if(item[1].Choices){
                                    choiceRows = Object.entries(item[1].Choices).sort((a,b) => (a[1].Name > b[1].Name) ? 1 : ((b[1].Name > a[1].Name) ? -1 : 0)).map((item2, i2) => {
                                        subRowColorIncrement++;
                                        return (
                                            <Grid container className={hidden}>
                                                <Grid item xs={4} className={subRowColorIncrement % 2 == 0 ? 'restaurant-grid-sub-row-color-alternate': 'restaurant-grid-sub-row-color'}>
                                                    <Typography align='center'>{item2[1].Name}</Typography>
                                                </Grid>
                                                <Grid item xs={4} className={subRowColorIncrement % 2 == 0 ? 'restaurant-grid-sub-row-color-alternate': 'restaurant-grid-sub-row-color'}>
                                                    <Typography align='center'>{item2[1].Quantity}</Typography>
                                                </Grid>
                                                <Grid item xs={4} className={subRowColorIncrement % 2 == 0 ? 'restaurant-grid-sub-row-color-alternate': 'restaurant-grid-sub-row-color'}>
                                                    <Typography align='center'>${item2[1].Sales.toFixed(2).replace(commasRegex, ",")}</Typography>
                                                </Grid>
                                            </Grid>
                                        )});
                                }

                                if(item[1].FoodItemAddons){
                                    addonRows = Object.entries(item[1].FoodItemAddons).map((item2, i2) => {
                                        subRowColorIncrement++;
                                        return (
                                            <Grid container className={hidden}>
                                                <Grid item xs={4} className={subRowColorIncrement % 2 == 0 ? 'restaurant-grid-sub-row-color-alternate': 'restaurant-grid-sub-row-color'}>
                                                    <Typography align='center'>{item2[1].Name}</Typography>
                                                </Grid>
                                                <Grid item xs={4} className={subRowColorIncrement % 2 == 0 ? 'restaurant-grid-sub-row-color-alternate': 'restaurant-grid-sub-row-color'}>
                                                    <Typography align='center'>{item2[1].Quantity}</Typography>
                                                </Grid>
                                                <Grid item xs={4} className={subRowColorIncrement % 2 == 0 ? 'restaurant-grid-sub-row-color-alternate': 'restaurant-grid-sub-row-color'}>
                                                    <Typography align='center'>${item2[1].Sales.toFixed(2).replace(commasRegex, ",")}</Typography>
                                                </Grid>
                                            </Grid>
                                        )});
                                }

                                if(item[1].Modifiers){
                                    modifierRows = Object.entries(item[1].Modifiers).map((item2, i2) => {
                                        subRowColorIncrement++;
                                        return (
                                            <Grid container className={hidden}>
                                                <Grid item xs={4} className={subRowColorIncrement % 2 == 0 ? 'restaurant-grid-sub-row-color-alternate': 'restaurant-grid-sub-row-color'}>
                                                    <Typography align='center'>{item2[1].Name}</Typography>
                                                </Grid>
                                                <Grid item xs={4} className={subRowColorIncrement % 2 == 0 ? 'restaurant-grid-sub-row-color-alternate': 'restaurant-grid-sub-row-color'}>
                                                    <Typography align='center'>{item2[1].Quantity}</Typography>
                                                </Grid>
                                                <Grid item xs={4} className={subRowColorIncrement % 2 == 0 ? 'restaurant-grid-sub-row-color-alternate': 'restaurant-grid-sub-row-color'}>
                                                    <Typography align='center'>${item2[1].Sales.toFixed(2).replace(commasRegex, ",")}</Typography>
                                                </Grid>
                                            </Grid>
                                        )});
                                }

                                let bundledSales = item[1].Sales + ChoiceSales + AddonSales + ModifierSales;
                                let salesDisplay = bundledSales;
                                if(rowToggled && item[1].Sizes){
                                    salesDisplay = 0;
                                }else if(rowToggled){
                                    salesDisplay = item[1].Sales;
                                }
                                let showExpand = ((item[1].Choices || item[1].FoodItemAddons || item[1].Modifiers));
                                let mainRow = (<Grid container>
                                    <Grid item xs={4} className={this.state.rowColorIncrement % 2 == 0 ? 'restaurant-grid-row-color-alternate': 'restaurant-grid-row-color'}>
                                        <Typography align='center'>{item[1].Name}</Typography>
                                    </Grid>
                                    <Grid item xs={4} className={this.state.rowColorIncrement % 2 == 0 ? 'restaurant-grid-row-color-alternate': 'restaurant-grid-row-color'}>
                                        <Typography align='center'>{item[1].Quantity}</Typography>
                                    </Grid>
                                    <Grid item xs={4} className={this.state.rowColorIncrement % 2 == 0 ? 'restaurant-grid-row-color-alternate': 'restaurant-grid-row-color'}>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Typography align='center'>${salesDisplay.toFixed(2).replace(commasRegex, ",")}{(showExpand && !rowToggled) && "*"} {showExpand && <a className='link' onClick={() => { this.toggleRow(item[1]['id']) }}>Expand+</a>}</Typography>
                                            </Grid>
                                            <Grid item xs={showExpand ? 6 : 0}>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>);

                                return (
                                <>
                                    {mainRow}
                                    {sizeRows}
                                    {choiceRows}
                                    {addonRows}
                                    {modifierRows}
                                </>)
                            })
                        }
                        <Grid item xs={4} className="restaurant-card-grid">
                            <Typography align='center'><b>Total</b></Typography>
                        </Grid>
                        <Grid item xs={4} className="restaurant-card-grid">
                            <Typography align='center'><b>{this.props.itemizedForm.totalQuantity}</b></Typography>
                        </Grid>
                        <Grid item xs={4} className="restaurant-card-grid">
                            <Typography align='center'><b>${this.props.itemizedForm.totalSales.toFixed(2).replace(commasRegex, ",")}</b></Typography>
                        </Grid>
                    </Grid>
                </div>
            );
        }
    }

    exportCSV(expanded=false){

        if(this.props.itemizedForm.initialSet == 1){
            alert('Please perform a search before requesting CSV version');
            return;
        }

        this.props.dispatch(itemizedFormUpdate({ prop: 'initialSet', value: false }));

        let itemizedForm = this.state.searchedData;
        this.props.dispatch(setItemizedOrdersSearchLoading());
        this.props.dispatch(loadItemizedReportCSV(this.props.match.params.id, itemizedForm.location_id, itemizedForm, expanded));
    }

    exportPDF(){
        let summaryTitle = this.props.location.address;
        // generateItemizedPDF(this.props.itemizedForm);
    }
    
    render() {

        return (
            <LoadScreen
                checkPermissions={this.checkPermissions}
                customLoading={this.props.loading}
            >
                <div className="card-container">
                    <Grid container className="menu-page-header">
                        <Grid item xs={12} className="heading-grid">
                            <h1>Itemized Reports</h1>
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid item xs={12} className="restaurant-card-grid">
                            <Card className="card restaurant-card">
                                <CardActions>
                                    {/* <Button size="small" color="primary" onClick={() => { this.exportPDF() }}>
                                        Generate PDF
                                    </Button> */}
                                    <Button size="small" color="primary" onClick={() => { this.exportCSV(false) }}>
                                        Generate CSV
                                    </Button>
                                    <Button size="small" color="primary" onClick={() => { this.exportCSV(true) }}>
                                        Generate CSV with expanded details
                                    </Button>
                                </CardActions>
                                <CardActions>
                                    <Grid container>
                                        <Grid item xs={3} className="form-grid">
                                            <InputLabel className="input-label-mb">Start Date</InputLabel>
                                            <Grid container>
                                                <Grid item xs={6} className="form-grid">
                                                    <DatePicker 
                                                        className="date-picker-input"
                                                        selected={this.props.itemizedForm.start_time} 
                                                        onChange={(date) => { this.props.dispatch(itemizedFormUpdate({ prop: 'start_time', value: date })); }} 
                                                        dateFormat="MM/dd/yyyy"
                                                        selectsStart
                                                        startDate={this.props.itemizedForm.start_time}
                                                        endDate={this.props.itemizedForm.end_time}
                                                    />
                                                    <TimePicker
                                                        allowEmpty={false}
                                                        showSecond={false}
                                                        defaultValue={moment().set({hour: 8, minute: 0})}
                                                        use12Hours={true}
                                                        minuteStep={5}
                                                        inputClassName="date-picker-input"
                                                        onChange={(date) => { this.props.dispatch(itemizedFormUpdate({ prop: 'start_time_time', value: date })); }} 
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={3} className="form-grid">
                                            <InputLabel className="input-label-mb">End Time</InputLabel>
                                            <Grid container>
                                                <Grid item xs={6} className="form-grid">
                                                    <DatePicker 
                                                        className="date-picker-input"
                                                        selected={this.props.itemizedForm.end_time} 
                                                        onChange={(date) => { this.props.dispatch(itemizedFormUpdate({ prop: 'end_time', value: date })); }} 
                                                        dateFormat="MM/dd/yyyy"
                                                        selectsEnd
                                                        startDate={this.props.itemizedForm.start_time}
                                                        endDate={this.props.itemizedForm.end_time}
                                                    />
                                                    <TimePicker
                                                        allowEmpty={false}
                                                        showSecond={false}
                                                        defaultValue={moment().set({hour: 23, minute: 59})}
                                                        use12Hours={true}
                                                        minuteStep={5}
                                                        inputClassName="date-picker-input"
                                                        onChange={(date) => { this.props.dispatch(itemizedFormUpdate({ prop: 'end_time_time', value: date })); }} 
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={3} className="form-grid">
                                            <InputLabel className="input-label-mb">Sort Options</InputLabel>
                                            <Grid container xs={9}>
                                                <Grid item xs={12} className="form-grid">
                                                    <Select
                                                        label="Sort Options"
                                                        fullWidth={true}
                                                        margin="none"
                                                        variant="outlined"
                                                        value={this.props.itemizedForm.sort_option}
                                                        onChange={(e) => { this.props.dispatch(itemizedFormUpdate({ prop: 'sort_option', value: e.target.value })); }}
                                                    >
                                                        <MenuItem key={'Name'} value={'Name'}>Name</MenuItem>
                                                        <MenuItem key={'Quantity'} value={'Quantity'}>Quantity</MenuItem>
                                                        <MenuItem key={'CombinedSales'} value={'CombinedSales'}>Sales</MenuItem>
                                                    </Select>
                                                </Grid>
                                                <Grid item xs={12} className="form-grid">
                                                    <Select
                                                        label="Sort Direction"
                                                        fullWidth={true}
                                                        margin="none"
                                                        variant="outlined"
                                                        value={this.props.itemizedForm.sort_direction}
                                                        onChange={(e) => { this.props.dispatch(itemizedFormUpdate({ prop: 'sort_direction', value: e.target.value })); }}
                                                    >
                                                        <MenuItem key={'Asc'} value={'Asc'}>Ascending</MenuItem>
                                                        <MenuItem key={'Desc'} value={'Desc'}>Descending</MenuItem>
                                                    </Select>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={3} className="form-grid">
                                            <InputLabel className="input-label-mb">Location</InputLabel>
                                            <Select
                                                label="Locations"
                                                fullWidth={true}
                                                margin="none"
                                                variant="outlined"
                                                value={this.props.itemizedForm.location_id}
                                                onChange={(e) => { this.props.dispatch(itemizedFormUpdate({ prop: 'location_id', value: e.target.value })); }}
                                            >
                                                <MenuItem key={'all'} value={0}>All Locations</MenuItem>
                                                {this.props.locations.map((location, index) => (
                                                    <MenuItem key={'location-' + location.id} value={location.id}>{location.address}</MenuItem>
                                                ))}
                                            </Select>
                                            <Grid container>
                                                <Grid item xs={12} className="restaurant-card-grid">
                                                    <Button variant="contained" fullWidth={true} color={'primary'} className={"modal-button-list"}
                                                        onClick={() => this.submitSearchOrders(this.props.itemizedForm)}
                                                        >
                                                        View Orders
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </CardActions>

                            </Card>
                        </Grid>

                        <Grid item xs={12} className="restaurant-card-grid">
                            <Card className="card restaurant-card">
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        Items ordered in time period
                                    </Typography>
                                    <Typography>
                                        Sales with * indicate the sum of bundled choices/addons/modifiers
                                    </Typography>
                                    {this.renderItemizedOrders()}
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </div>
            </LoadScreen>
        );
    }
};

const mapStateToProps = (state, props) => {
    return {
        loading: state.reportItemizedForm.loading,
        generatingFile: state.reportOrders.generatingFile,
        performingSearch: state.reportOrders.performingSearch,
        restaurant: state.selectedRestaurant.restaurant,
        searchForm: state.reportSearchForm,
        itemizedForm: state.reportItemizedForm,
        summary: state.reportOrders.orderSummary,
        orders: state.reportOrders.orders,
        location: state.reportOrders.location,
        locations: state.locations.locations,  
        ordersCompleteList: state.reportOrders.ordersCompleteList,
        currentPage: state.reportOrders.currentPage,
        totalPages: state.reportOrders.totalPages,
        user: state.loggedUser.user,
    };
};

export default connect(mapStateToProps)(ItemizedReportsPage);