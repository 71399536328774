import React from 'react';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

import validateCurrency from '../../../../utils/validateCurrency';
import { addPizza } from '../../../../actions/menu/pizzas/pizzas';
import { pizzaFormError, pizzaFormReset, pizzaFormUpdate } from '../../../../actions/menu/pizzas/pizzaForm';
import { openPizzaCreate, closePizzaCreate } from '../../../../actions/modals';

import CloseIcon from '../../../common/modal/CloseIcon';

import Info from './Info';
import Toppings from './Toppings';
import Crusts from './Crusts';
import Sauces from './Sauces';

class CreatePizza extends React.Component {

    constructor(props) {
        super(props);

        this.toggleCreateModal = this.toggleCreateModal.bind(this);
        this.checkPizzaInfo = this.checkPizzaInfo.bind(this);
        this.submitCreatePizza = this.submitCreatePizza.bind(this);
        this.updateTabSelection = this.updateTabSelection.bind(this);
    }

    getSteps() {
        return [
            'Info and Prices',
            'Toppings',
            'Crusts',
            'Sauces'
        ];
    }

    toggleCreateModal(isOpen) {
        if (isOpen) {
            this.props.dispatch(pizzaFormReset());
            this.props.dispatch(openPizzaCreate());
        }
        else {
            this.props.dispatch(closePizzaCreate());
        }
    }

    updateTabSelection(index) {
        this.props.dispatch(pizzaFormUpdate({ prop: 'activeTab', value: index }));
    }

    checkPizzaInfo() {

        let valid = true;
        
        // perform some error checking
        if (this.props.pizzaForm.name.trim() == "") {
            this.props.dispatch(pizzaFormError("Please enter a food item name."));
        }else if(this.props.pizzaForm.saveSchedule && this.props.pizzaForm.scheduleName.trim() == ""){
            this.props.dispatch(pizzaFormError("Please enter a name for the availability schedule."));
        }else if(this.props.pizzaForm.saveSchedule && this.props.restaurant.availability_schedules.map((schedule) => {return schedule.name}).includes(this.props.pizzaForm.scheduleName.trim())){
            this.props.dispatch(pizzaFormError("An availability schedule with that name already exists."));
        } else {

            // go through each price and determine if it is valid
            for (let i = 0; i < this.props.pizzaForm.prices.length; i++) {

                // ignore any blank rows
                if (this.props.pizzaForm.prices[i].name != '' || this.props.pizzaForm.prices[i].price != '') {
                    if (!validateCurrency(this.props.pizzaForm.prices[i].price) || this.props.pizzaForm.prices[i].price <= 0) {
                        valid = false;
                        this.props.dispatch(pizzaFormError("Please ensure that all prices have a valid price that is greater than 0."));
                    }
                }
            }

            if (valid) {
                this.props.dispatch(pizzaFormError(""));
                this.props.dispatch(pizzaFormUpdate({ prop: 'activeTab', value: 1 }));
            }
        }
    }

    submitCreatePizza() {
        this.props.dispatch(addPizza(this.props.restaurantId, this.props.pizzaForm));
    }

    renderCreateModalActiveTab() {
        if (this.props.loading) {
            return (
                <div className="page-loading">
                    <CircularProgress size={100} />
                </div>
            );
        }
        else {
            switch(this.props.pizzaForm.activeTab) {
                case 0:
                    return this.renderCreateModalInfo();

                case 1:
                    return this.renderCreateModalToppings();

                case 2:
                    return this.renderCreateModalCrusts();

                case 3:
                    return this.renderCreateModalSauces();

                default:
                    return <div />
            }
        }
    }

    renderCreateModalInfo() {
        return (
            <div>
                <Info />

                <Button variant="contained" fullWidth={true} className="modal-button-bottom" onClick={this.checkPizzaInfo}>
                    Next - Add Toppings
                </Button>
            </div>
        );
    }

    renderCreateModalToppings() {
        return (
            <div>
                <Toppings />
                    
                <Grid container>
                    <Grid item xs={6} className="form-grid">
                        <Button variant="contained" fullWidth={true} color="secondary" className="modal-button-bottom" onClick={() => this.updateTabSelection(0)}>
                            Back
                        </Button>
                    </Grid>
                    <Grid item xs={6} className="form-grid">
                        <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom" onClick={() => this.updateTabSelection(2)}>
                            Next - Crusts
                        </Button>
                    </Grid>
                </Grid>             
            </div>
        );
    }

    renderCreateModalCrusts() {
        return (
            <div>
                <Crusts />
                    
                <Grid container>
                    <Grid item xs={6} className="form-grid">
                        <Button variant="contained" fullWidth={true} color="secondary" className="modal-button-bottom" onClick={() => this.updateTabSelection(1)}>
                            Back
                        </Button>
                    </Grid>
                    <Grid item xs={6} className="form-grid">
                        <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom" onClick={() => this.updateTabSelection(3)}>
                            Next - Sauces
                        </Button>
                    </Grid>
                </Grid>             
            </div>
        );
    }

    renderCreateModalSauces() {
        return (
            <div>
                <Sauces />
                    
                <Grid container>
                    <Grid item xs={6} className="form-grid">
                        <Button variant="contained" fullWidth={true} color="secondary" className="modal-button-bottom" onClick={() => this.updateTabSelection(2)}>
                            Back
                        </Button>
                    </Grid>
                    <Grid item xs={6} className="form-grid">
                        <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom" onClick={this.submitCreatePizza}>
                            Finish
                        </Button>
                    </Grid>
                </Grid>             
            </div>
        );
    }
    
    render() {
        return (
            <Modal
                aria-labelledby="food-item-add"
                aria-describedby="food-item-add"
                open={this.props.createModalOpen}
                onClose={() => this.toggleCreateModal(false)}
                className="modal_admin"
                disableBackdropClick
            >
                <div className="modal-member">
                    <Typography variant="h4" id="modal-title">
                        <CloseIcon/>
                    </Typography>
                    <Stepper activeStep={this.props.pizzaForm.activeTab} alternativeLabel>
                        {this.getSteps().map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    <Typography variant="h4" id="modal-title">
                        Add a Pizza
                    </Typography>

                    { this.props.pizzaForm.error && (
                        <Typography className="modal-error">
                            { this.props.pizzaForm.error }
                        </Typography>
                    )}

                    {this.renderCreateModalActiveTab()}
                </div>
            </Modal>
        );
    }
};

const mapStateToProps = (state, props) => {
    return {
        loading: state.pizzas.loading,
        restaurant: state.selectedRestaurant.restaurant,
        pizzaForm: state.pizzaForm,
        createModalOpen: state.modals.pizzaCreate,
        updateModalOpen: state.modals.pizzaUpdate,
    };
};

export default connect(mapStateToProps)(CreatePizza);