export const confirmOrderFormUpdate = ({ prop, value }) => {

    return {
        type: 'CONFIRM_ORDER_FORM_UPDATE',
        manager: { prop, value }
    };
};

export const confirmOrderFormError = (message) => {

    return {
        type: 'CONFIRM_ORDER_FORM_ERROR',
        message
    };
};

export const confirmOrderFormLoading = () => {

    return {
        type: 'CONFIRM_ORDER_FORM_LOADING',
    };
};

export const confirmOrderFormReset = () => {
    
    return {
        type: 'CONFIRM_ORDER_FORM_RESET',
    };
}
