import React from 'react';
import { connect } from 'react-redux';
import DatePicker from "react-datepicker";

import CircularProgress from '@material-ui/core/CircularProgress';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';

import { promoCodeAdminFormUpdate, promoCodeAdminUpdateValidPromoDays, promoCodeAdminFormError, promoCodeAdminFormReset, 
    addUpdatePromoCode } from '../../../actions/superuser/promoCodeAdminForm';

import { toggleSelectedLocation, selectAllLocations } from '../../../actions/restaurant/promoCodes';

import { closePromoCodeCreate } from '../../../actions/modals'; 
import PromoDaySelection from '../../common/modal/PromoDaySelection'; 

import CloseIcon from '../../common/modal/CloseIcon';
import validateCurrency from '../../../utils/validateCurrency';

const minLocationFilterCharacterCount = 3;

class AdminPromoCodeModal extends React.Component {

    constructor(props) {
        super(props);

        this.closeUpdateModal = this.closeUpdateModal.bind(this);
        this.handleCancelButton = this.handleCancelButton.bind(this);
        this.addUpdatePromoCode = this.addUpdatePromoCode.bind(this);
        this.renderLocationsList = this.renderLocationsList.bind(this);
        this.selectAllLocations = this.selectAllLocations.bind(this);
        this.updateThresholdChoice = this.updateThresholdChoice.bind(this);
        this.updatePromoTotalType = this.updatePromoTotalType.bind(this);
        this.updateThresholdValue = this.updateThresholdValue.bind(this);
        this.updateThresholdLimitText = this.updateThresholdLimitText.bind(this);
        this.updatePromoTarget = this.updatePromoTarget.bind(this);
        this.disableThresholdOptions = this.disableThresholdOptions.bind(this);
        this.enableThresholdOptions = this.enableThresholdOptions.bind(this);

        this.state = {
            displayView: 0,
            animate: false,
        };
    }

    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.updateModalOpen == true && prevProps.updateModalOpen == false) {
          this.updateThresholdLimitText(this.props.promoCodesForm.threshold);
        }
      }

    closeUpdateModal() {
        this.props.dispatch(closePromoCodeCreate());
    }

    handleCancelButton() {
        this.props.dispatch(promoCodeAdminFormReset());
        this.props.dispatch(closePromoCodeCreate());
    }

    selectAllLocations(){
        this.props.dispatch(selectAllLocations(this.props.locations));
    }

    disableThresholdOptions(){
        this.updateThresholdChoice("NO_THRESHOLD");
        this.props.dispatch(promoCodeAdminFormUpdate({ prop: 'threshold_disable', value: true }));
        this.props.dispatch(promoCodeAdminFormUpdate({ prop: 'threshold_limit', value: 0.00 }));
    }

    enableThresholdOptions({total_target = null, total_type = null}){ // Called when one option can enable threshold options, but checks if other options are also valid first
        if(!total_target){
            total_target = this.props.promoCodesForm.total_target;
        }
        if(!total_type){
            total_type = this.props.promoCodesForm.total_type;
        }
        if(total_target == "SUBTOTAL" && total_type == "PERCENTAGE"){
            this.props.dispatch(promoCodeAdminFormUpdate({ prop: 'threshold_disable', value: false }));
        }
    }

    updatePromoTarget(value){
        this.props.dispatch(promoCodeAdminFormUpdate({ prop: 'total_target', value: value })); // SUBTOTAL/DELIVERY
        if(value == "DELIVERY"){
            this.disableThresholdOptions();
        }else{
            this.enableThresholdOptions({total_target: value});
        }
    }

    updateThresholdValue(value){
        const validNumber = new RegExp(/^\d*\.?\d*$/);

        if (validNumber.test(value)) {
            this.props.dispatch(promoCodeAdminFormUpdate({ prop: 'threshold_limit', value: value }));
        }
    }

    updateThresholdLimitText(value){
        if(value == "MAX_DISCOUNT_ALLOWED"){
            this.props.dispatch(promoCodeAdminFormUpdate({ prop: 'threshold_limit_text', value: 'Discount up to this dollar amount' }));
        }else if(value == "MINIMUM_TOTAL_SPENT"){
            this.props.dispatch(promoCodeAdminFormUpdate({ prop: 'threshold_limit_text', value: 'Enable after spending this amount' }));
        }else{
            this.props.dispatch(promoCodeAdminFormUpdate({ prop: 'threshold_limit_text', value: 'Limit' }));
        }
    }

    updateThresholdChoice(value) {
        this.props.dispatch(promoCodeAdminFormUpdate({ prop: 'threshold', value: value }));
        // If choice was MAX_DISCOUNT_ALLOWED or MINIMUM_TOTAL_SPENT then update threshold_limit_text too
        if(value == "MAX_DISCOUNT_ALLOWED"){
            this.props.dispatch(promoCodeAdminFormUpdate({ prop: 'threshold_limit_text', value: 'Discount up to this dollar amount' }));
        }else if(value == "MINIMUM_TOTAL_SPENT"){
            this.props.dispatch(promoCodeAdminFormUpdate({ prop: 'threshold_limit_text', value: 'Enable after spending this amount' }));
        }else{
            this.props.dispatch(promoCodeAdminFormUpdate({ prop: 'threshold_limit_text', value: 'Limit' }));
            this.props.dispatch(promoCodeAdminFormUpdate({ prop: 'threshold_limit', value: 0.00 }));
        }
    }

    updatePromoTotalType(value){
        this.props.dispatch(promoCodeAdminFormUpdate({ prop: 'total_type', value: value }));
        // Disable threshold options if not doing percent
        if(value == "FIXED"){
            this.disableThresholdOptions();
        }else{
            this.enableThresholdOptions({total_type: value});
        }
    }

    addUpdatePromoCode(){

        //error checking
        if (this.props.promoCodesForm.code.trim() == '') {
            this.props.dispatch(promoCodeAdminFormError('Please enter a promo code.'));
            this.props.dispatch(promoCodeAdminFormUpdate(({ prop: 'errorTarget', value: 'code' })));
        }
        else if (this.props.promoCodesForm.total_avail == "LIMITED DATE" && (!this.props.promoCodesForm.start_time || !this.props.promoCodesForm.end_time)) {
            this.props.dispatch(promoCodeAdminFormError('Please enter a start and end date for your promo code if it has limited date availability.'));
            this.props.dispatch(promoCodeAdminFormUpdate(({ prop: 'errorTarget', value: 'dates' })));
        }
        else if (this.props.promoCodesForm.total_avail == "LIMITED DATE" && this.props.promoCodesForm.start_time > this.props.promoCodesForm.end_time) {
            this.props.dispatch(promoCodeAdminFormError('Start date must be before end date.'));
            this.props.dispatch(promoCodeAdminFormUpdate(({ prop: 'errorTarget', value: 'dates' })));
        }
        else if (this.props.promoCodesForm.max_uses < 0) {
            this.props.dispatch(promoCodeAdminFormError('Please enter a quantity greater than or equal to 0.'));
            this.props.dispatch(promoCodeAdminFormUpdate(({ prop: 'errorTarget', value: 'max_uses' })));
        }
        else if (!validateCurrency(this.props.promoCodesForm.total) || this.props.promoCodesForm.total <= 0) {
            this.props.dispatch(promoCodeAdminFormError('Please enter a valid amount greater than 0 for total.'));
            this.props.dispatch(promoCodeAdminFormUpdate(({ prop: 'errorTarget', value: 'total' })));
        }
        else {
            let valid = true;
            let problemMessages = [];

            // Checks locations for codes they may already have that conflict
            let locationPromoCodeConflict = this.props.unfilteredLocations.filter((location) => {
                // Return selected location from our list

                return this.props.selectedLocations.some((selLoc) => {
                    return selLoc == location.id;
                });
            }).filter((location) => {
                // Determine if any applied locations already have that promo code
                return location.promo_codes.some((code) => {
                    let curID = this.props.promoCodesForm.selectedPromoCode && this.props.promoCodesForm.selectedPromoCode.id;
                    if(code.code == this.props.promoCodesForm.code && (this.props.editing == false || code.admin_promo_code_id != curID)){
                        problemMessages.push(location.restaurant.name + "/" + location.address + " already uses that code"); // Maybe just add crafted message to a running string var instead
                    }
                    return code == this.props.promoCodesForm.code;
                });

            }).length > 0;

            if(locationPromoCodeConflict){
                valid = false;
            }

            if (valid) {
                this.props.dispatch(promoCodeAdminFormError(""));
                this.props.dispatch(promoCodeAdminFormUpdate(({ prop: 'errorTarget', value: '' })));
                let scope = (this.props.editing) ? 'update': 'add';

                // add the promoCode
                this.props.dispatch(addUpdatePromoCode(this.props.promoCodesForm, this.props.selectedLocations, closePromoCodeCreate, scope));
            }else{
                // Craft good error message
                this.props.dispatch(promoCodeAdminFormError(problemMessages.join(' ')));
                this.setState(() => ({ animate: true }));

                setTimeout(() => {
                    this.setState(() => ({ animate: false }));
                }, 1000);
            }
        }

    }

    renderDispatchCenterSelect(){
        return (
            <>
                <Grid item xs={6} className="form-grid">
                    <InputLabel className="input-label-mb"></InputLabel>
                    <Select
                        label="Select Dispatch Center(s)"
                        fullWidth={true}
                        margin="none"
                        variant="outlined"
                        value={this.props.promoCodesForm.selectedDispatchCenterID}
                        onChange={(e) => { this.props.dispatch(promoCodeAdminFormUpdate({ prop: 'selectedDispatchCenterID', value: e.target.value })); }}
                    >
                        <MenuItem key={0} value={0}>All Dispatch Centers</MenuItem>
                        {   
                            this.props.dispatchCenters && this.props.dispatchCenters.length > 0 && this.props.dispatchCenters.map((dispatchCenter) => {
                                return <MenuItem key={dispatchCenter.id} value={dispatchCenter.id}>{dispatchCenter.name}</MenuItem>
                            })
                        }
                    </Select>
                </Grid>

                <Grid item xs={6} className="form-grid">
                    <br />
                    <FormControlLabel
                        control={
                        <Checkbox
                            checked={this.props.promoCodesForm.selectAllLocations}
                            onChange={(e) => { this.selectAllLocations(); }}
                            name={'Apply to all locations'}
                            color="primary"
                        />
                        }
                        label={'Apply to all locations'}
                    />
                </Grid>
            </>
        )
    }

    renderLocationsList() {

        let sortedList = (this.props.locations && this.props.locations.length > 0 && (this.props.location_filter.length >= minLocationFilterCharacterCount || this.props.promoCodesForm.selectedDispatchCenterID > 0 || this.props.selectedLocations.length > 0)) ? this.props.locations.sort(
            (a, b) => {
                                            
                return b.restaurant.name > a.restaurant.name ? 1 : -1;
            }
        ).sort(
            (a, b) => {

                let promoCodesAMatch = this.props.selectedLocations.filter(loc => loc == a.id).length > 0;
                let promoCodesBMatch = this.props.selectedLocations.filter(loc => loc == b.id).length > 0;
                
                return promoCodesBMatch > promoCodesAMatch ? 1 : -1;
            }
        ) : [];

        return (
            <Grid container>
                <Grid item xs={12}>
                    {this.props.locations && this.props.locations.length <= 0 && this.props.selectedLocations.length <= 0 && (this.props.location_filter.length >= minLocationFilterCharacterCount || this.props.promoCodesForm.selectedDispatchCenterID > 0) && (
                        <Typography className="no-results">No locations found.</Typography>
                    )}
                    {this.props.locations.length > 0 && (this.props.location_filter.length >= minLocationFilterCharacterCount || this.props.promoCodesForm.selectedDispatchCenterID > 0 || this.props.selectedLocations.length > 0) && 
                        sortedList.filter((location) => {

                        // Locations in this list are already filtered by the location_filter AND if they are already selected when editing a promo code

                        // 1) If they're selected return true, otherwise continue
                        let alreadySelected = this.props.selectedLocations.some((selLocation) => {
                            return selLocation == location.id; // selectedLocations contains just the IDs
                        });
                        if(alreadySelected){
                            location.remaining = location.promo_codes.filter((code) => {
                                if(typeof this.props.promoCodesForm.selectedPromoCode !== 'undefined'){
                                    return this.props.promoCodesForm.selectedPromoCode && (code.admin_promo_code_id == this.props.promoCodesForm.selectedPromoCode.id)
                                }else{
                                    return false;
                                }
                            }).map((code) => {
                                return code.remaining_uses + "/" + code.max_uses;
                            });
                            return true;
                        }

                        // 2) Before we return locations based on the filter we need to remove any that are not associated with a dispatch
                        if(!(location && location.dispatch_location && location.dispatch_location.dispatch_center && location.dispatch_location.dispatch_center.id)){
                            return false;
                        }

                        // 3) Next if we have a dispatch selected, return those locations
                        let dispatchMatch = this.props.promoCodesForm.selectedDispatchCenterID == location.dispatch_location.dispatch_center.id;

                        if(dispatchMatch){
                            return true;
                        }

                        // 4) Lastly if the filter has more than 3 characters we return all that's left
                        if(this.props.location_filter.length >= minLocationFilterCharacterCount){
                            return true;
                        }

                        return false;

                    }).map((location, index) => {
                        return (
                            <Grid key={"location-"+index} container>
                                <Grid item xs={11} className="list-name">
                                    <Typography>{location.restaurant.name} - {location.address}</Typography>
                                </Grid>
                                <Grid item xs={1} className="list-sort">
                                    <FormControlLabel
                                        control={
                                        <Checkbox
                                            checked={this.props.selectedLocations.filter(loc => loc == location.id).length > 0}
                                            onChange={(e, checked) => { this.props.dispatch(toggleSelectedLocation(e.target.value, checked)); }}
                                            name={'Selected'}
                                            color="primary"
                                            value={location.id}
                                        />
                                        }
                                        label={''}
                                    />
                                </Grid>
                            </Grid>
                        )
                    })}
                </Grid>
            </Grid>
        );
    }

    render() {
        return (
            <Modal
                aria-labelledby="promo-code-create"
                aria-describedby="promo-code-create"
                open={this.props.updateModalOpen}
                onClose={() => this.closeUpdateModal()}
                className="modal_admin"
                disableBackdropClick
            >
                <div className={this.state.animate ? 'modal-member shake': 'modal-member'}>
                    <Typography variant="h4" id="modal-title">
                        {(this.props.editing) ? 'Update Promo Code': 'Promo Codes'}
                        <CloseIcon/>
                    </Typography>

                    <div>
                        { this.props.promoCodesForm.message && (
                            <Typography className="modal-success">
                                { this.props.promoCodesForm.message }
                            </Typography>
                        )}
                    </div>

                    {this.props.loading && (
                        <div className="page-loading">
                            <CircularProgress size={100} />
                        </div>
                    )}

                    {!this.props.loading && (
                        <Grid container>
                            <Grid item xs={12} className="form-grid">
                                <Typography className="modal-description">
                                    Enter your promo code information and click 'Add Promo Code'. Please note: you will still need to save the promo code after adding it.
                                </Typography>
                            </Grid>

                            <Grid item xs={12} className="form-grid">
                                <TextField
                                    label="Code"
                                    className="modal-input"
                                    fullWidth={true}
                                    value={this.props.promoCodesForm.code}
                                    onChange={(e) => { this.props.dispatch(promoCodeAdminFormUpdate({ prop: 'code', value: e.target.value })); }}
                                    margin="normal"
                                    error={this.props.promoCodesForm.errorTarget == "code"}
                                />
                            </Grid>

                            <Grid item xs={12} className="form-grid">
                                <Typography variant="h4" id="modal-title">
                                Type (Subtotal Or Delivery)
                                </Typography>
                            </Grid>

                            <Grid item xs={12} className="form-grid" style={{padding: "10px 0px"}}>
                                <FormGroup row>
                                    <FormGroup style={{padding: "0px 30px"}}>
                                        <IconButton
                                            className="fa fa-shopping-cart jumboIcon" color={this.props.promoCodesForm.total_target == 'SUBTOTAL' ? 'primary' : 'default'}
                                            onClick={() => { this.updatePromoTarget('SUBTOTAL'); }}
                                        />
                                        <Typography style={{padding: "0px 25px"}}>
                                            Order Subtotal
                                        </Typography>
                                    </FormGroup>
                                    
                                    <FormGroup style={{padding: "0px 30px"}}>
                                        <IconButton
                                            className="fa fa-shipping-fast jumboIcon" color={this.props.promoCodesForm.total_target == 'DELIVERY' ? 'primary' : 'default'}
                                            onClick={() => { this.updatePromoTarget('DELIVERY'); }}
                                        />
                                        <Typography style={{padding: "0px 25px"}}>
                                            Delivery Promo
                                        </Typography>
                                    </FormGroup>
                                    
                                </FormGroup>
                            </Grid>

                            <Grid item xs={12} className="form-grid">
                                <Typography variant="h3" id="modal-title">
                                    Promo Deal
                                </Typography>
                            </Grid>

                            <Grid item xs={6} className="form-grid">
                                <InputLabel className="input-label-mb"></InputLabel>
                                <Select
                                    label="Promo Type"
                                    fullWidth={true}
                                    margin="none"
                                    variant="outlined"
                                    value={this.props.promoCodesForm.total_type}
                                    onChange={(e) => { 
                                        this.updatePromoTotalType(e.target.value);
                                    }}
                                >
                                    <MenuItem key={'PERCENTAGE'} value={'PERCENTAGE'}>Percentage</MenuItem>
                                    <MenuItem key={'FIXED'} value={'FIXED'}>Fixed</MenuItem>
                                </Select>
                            </Grid>

                            <Grid item xs={6} className="form-grid">
                                <TextField
                                    label="Amount"
                                    className="modal-input"
                                    fullWidth={true}
                                    // disabled={this.props.promoCodeForm.type == 'FREE DELIVERY'}
                                    value={this.props.promoCodesForm.total}
                                    onChange={(e) => { this.props.dispatch(promoCodeAdminFormUpdate({ prop: 'total', value: e.target.value })); }}
                                    margin="normal"
                                    variant="outlined"
                                    error={this.props.promoCodesForm.errorTarget == "total"}
                                />
                            </Grid>

                            <Grid item xs={12} className="form-grid">
                                <Typography variant="h3" id="modal-title">
                                    Spending Threshold (Subtotal promos only)
                                </Typography>
                            </Grid>

                            <Grid item xs={4} className="form-grid">
                                <Grid container direction='column'>
                                    <RadioGroup
                                        aria-labelledby="threshold-radio-buttons-group-label"
                                        defaultValue="NO_THRESHOLD"
                                        value={this.props.promoCodesForm.threshold}
                                        disabled={this.props.promoCodesForm.threshold_disable}
                                        name="radio-buttons-group"
                                        onChange={(e) => {
                                            this.props.dispatch(this.updateThresholdChoice(e.target.value));
                                        }}
                                    >
                                        <FormControlLabel value="NO_THRESHOLD" control={<Radio disabled={this.props.promoCodesForm.threshold_disable} />} label="No Threshold" />
                                        <FormControlLabel value="MAX_DISCOUNT_ALLOWED" control={<Radio disabled={this.props.promoCodesForm.threshold_disable} />} label="Maximum Discount Allowed (% Only)" />
                                        <FormControlLabel value="MINIMUM_TOTAL_SPENT" control={<Radio disabled={this.props.promoCodesForm.threshold_disable} />} label="After Minimum Spent (% Only)" />
                                    </RadioGroup>
                                </Grid>
                            </Grid>

                            <Grid item xs={4} className="form-grid">
                                <Grid container direction='column'>
                                    <span>{this.props.promoCodesForm.threshold_limit_text}</span>
                                    <TextField
                                        label={this.props.promoCodesForm.threshold_limit_text}
                                        className="modal-input"
                                        fullWidth={true}
                                        disabled={this.props.promoCodesForm.threshold == 'NO_THRESHOLD'}
                                        value={this.props.promoCodesForm.threshold_limit}
                                        onChange={(e) => { this.updateThresholdValue(e.target.value) }}
                                        margin="normal"
                                        variant="outlined"
                                        // error={this.props.promoCodesForm.errorTarget == "threshold_percent"}
                                    />
                                </Grid>
                            </Grid>

                            <Grid item xs={12} className="form-grid">
                                <Typography variant="h3" id="modal-title">
                                    Availability (Select One)
                                </Typography>
                            </Grid>

                            <Grid item xs={4} className="form-grid">
                                <FormControlLabel
                                    control={
                                    <Checkbox
                                        checked={this.props.promoCodesForm.total_avail == 'LIMITED DATE'}
                                        onChange={(e) => { this.props.dispatch(promoCodeAdminFormUpdate({ prop: 'total_avail', value: e.target.value })); }}
                                        name={'Specific Start/End Date'}
                                        color="primary"
                                        value={'LIMITED DATE'}
                                    />
                                    }
                                    label={'Specific Start/End Date'}
                                />
                            </Grid>

                            <Grid item xs={4} className="form-grid">
                                <InputLabel className="input-label-mb">Start Date</InputLabel>
                                <DatePicker 
                                    selected={this.props.promoCodesForm.start_time} 
                                    onChange={(date) => { this.props.dispatch(promoCodeAdminFormUpdate({ prop: 'start_time', value: date })); }} 
                                    disabled={this.props.promoCodesForm.total_avail != 'LIMITED DATE'}
                                    error={this.props.promoCodesForm.errorTarget == "dates"}
                                />
                                {
                                    this.props.promoCodesForm.error && this.props.promoCodesForm.errorTarget == 'dates' && (
                                        <Typography className="modal-error general-error message-block">There was an error with the dates</Typography>
                                    )
                                }
                            </Grid>

                            <Grid item xs={4} className="form-grid">
                                <InputLabel className="input-label-mb">End Date</InputLabel>
                                <DatePicker 
                                    selected={this.props.promoCodesForm.end_time}
                                    onChange={(date) => { 
                                        this.props.dispatch(promoCodeAdminFormUpdate({ prop: 'end_time', value: date })); 
                                        }} 
                                    disabled={this.props.promoCodesForm.total_avail != 'LIMITED DATE'}
                                    error={this.props.promoCodesForm.errorTarget == "dates"}
                                />
                                {
                                    this.props.promoCodesForm.error && this.props.promoCodesForm.errorTarget == 'dates' && (
                                        <Typography className="modal-error general-error">There was an error with the dates</Typography>
                                    )
                                }
                            </Grid>

                            <Grid item xs={12} className="form-grid">
                                <FormControlLabel
                                    control={
                                    <Checkbox
                                        checked={this.props.promoCodesForm.total_avail == 'ALWAYS'}
                                        onChange={(e) => { this.props.dispatch(promoCodeAdminFormUpdate({ prop: 'total_avail', value: e.target.value })); }}
                                        name={'Always Available'}
                                        color="primary"
                                        value={'ALWAYS'}
                                    />
                                    }
                                    label={'Always Available'}
                                />
                            </Grid>

                            <PromoDaySelection
                                activeForm={this.props.promoCodesForm}
                                updateValidDays={promoCodeAdminUpdateValidPromoDays}
                                dispatch={this.props.dispatch}
                            />

                            <Grid item xs={12} className="form-grid">
                                <Typography variant="h3" id="modal-title">
                                    Quantity
                                </Typography>
                            </Grid>

                            <Grid item xs={6} className="form-grid">
                                <InputLabel className="input-label-mb"></InputLabel>
                                <Select
                                    label="Max Uses"
                                    fullWidth={true}
                                    disabled={this.props.editing}
                                    margin="none"
                                    variant="outlined"
                                    value={this.props.promoCodesForm.max_uses > 0 ? 1 : 0}
                                    onChange={(e) => { this.props.dispatch(promoCodeAdminFormUpdate({ prop: 'max_uses', value: e.target.value })); }}
                                >
                                    <MenuItem key={'NO LIMIT'} value={0}>Unlimited</MenuItem>
                                    <MenuItem key={'LIMITED QUANTITY'} value={1}>Limited Quantity</MenuItem>
                                </Select>
                            </Grid>

                            <Grid item xs={6} className="form-grid">
                                <TextField
                                    label="Max Uses"
                                    className="modal-input"
                                    fullWidth={true}
                                    disabled={this.props.editing}
                                    helperText={this.props.promoCodesForm.max_uses == 0 ? "0 is unlimited" : ''}
                                    value={this.props.promoCodesForm.max_uses}
                                    onChange={(e) => { this.props.dispatch(promoCodeAdminFormUpdate({ prop: 'max_uses', value: e.target.value })); }}
                                    margin="normal"
                                    variant="outlined"
                                    error={this.props.promoCodesForm.errorTarget == "max_uses"}
                                />
                            </Grid>

                            <Grid item xs={12} className="form-grid">
                                <Typography variant="h3" id="modal-title">
                                    Choose Locations
                                </Typography>
                            </Grid>

                            {this.renderDispatchCenterSelect()}
                            
                            <Grid item xs={12} className="form-grid">
                                <TextField
                                    label={"Filter Locations (Minimum "+minLocationFilterCharacterCount+" characters OR selected dispatch center to show locations)"}
                                    className="modal-input"
                                    fullWidth={true}
                                    value={this.props.promoCodesForm.location_filter}
                                    onChange={(e) => { this.props.dispatch(promoCodeAdminFormUpdate({ prop: 'location_filter', value: e.target.value })); }}
                                    margin="normal"
                                />
                            </Grid>

                            {this.renderLocationsList()}

                            <Grid container className="sticky">
                                <Grid item xs={6} className="form-grid">
                                    <Button variant="contained" fullWidth={true} color="secondary" className="modal-button-list" onClick={this.handleCancelButton}>
                                        Cancel
                                    </Button>
                                </Grid>
                                <Grid item xs={6} className="form-grid">
                                    <Button variant="contained" fullWidth={true} color="primary" className={this.state.animate ? 'modal-button-list fade': 'modal-button-list'}
                                        onClick={this.addUpdatePromoCode}>
                                        {(this.props.editing) ? 'Update Promo Code': 'Add Promo Code'}
                                    </Button>
                                    { this.props.promoCodesForm.error && (
                                        <Typography className="modal-error general-error message-block">
                                            { this.props.promoCodesForm.error }
                                        </Typography>
                                    )}
                                </Grid>
                            </Grid>   
                        </Grid> 
                    )}  
                </div>
            </Modal>
        );
    }
};

const mapStateToProps = (state, props) => {
    return {
        loading: state.promoCodeAdminForm.loading,
        editing: state.promoCodeAdminForm.editing,
        promoCodesForm: state.promoCodeAdminForm,
        updateModalOpen: state.modals.promoCodeCreate,
        location_filter: state.promoCodeAdminForm.location_filter,
        selectedLocations: state.promoCodeAdminForm.selectedLocations,
    };
};

export default connect(mapStateToProps)(AdminPromoCodeModal);