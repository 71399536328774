const infoFormDefaultState = {
    name: '',
    additional_charge: 0.00,
    standard_charge_type: 'percentage',
    standard_charge_amount: 0.00,
    whitelabel_charge_type: 'percentage',
    whitelabel_charge_amount: 0.00,
    whitelabel_enabled: false,
    fuel_surcharge_amount: 0.00,
    fuel_surcharge_enabled: false,
    internal_charge_type: 'percentage',
    internal_charge_target: 'both',
    internal_charge_amount: 0.00,
    internal_enabled: false,
    internal_charge_comment: '',
    loyalty_points_enabled: false,
    custom_delivery: 'none',
    feature_image: '',
    online_payment_status: '',
    publishable_key: '',
    logo: '',
    logoImage: {},
    featureImage: {},
    loading: true,
    message: '',
    error: '',
};

export default (state = infoFormDefaultState, action) => {

    switch (action.type) {

        case 'INFO_FORM_UPDATE':

            return {
                ...state,
                [action.info.prop]: action.info.value
            };

        case 'INFO_FORM_ERROR':
            return {
                ...state,
                message: '',
                error: action.message
            }

        case 'INFO_FORM_SUCCESS':
            return {
                ...state,
                error: '',
                message: action.message
            }

        case 'INFO_FORM_RESET':
            return infoFormDefaultState;

        default:
            return state;
    }
};