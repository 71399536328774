import React from 'react';
import { connect } from 'react-redux';
import { resetPasswordFormUpdate, resetPasswordFormError } from '../actions/resetPasswordForm';
import { resetPassword, checkPasswordResetTokenForEmail } from '../actions/auth';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Alert from '@material-ui/lab/Alert';
import logo from '../assets/images/logo.png';

import CircularProgress from '@material-ui/core/CircularProgress';

class ResetPasswordPage extends React.Component {

    constructor(props) {
        super(props);   

        this.onSubmitReset = this.onSubmitReset.bind(this);

        this.state = {
            email: '',
            password_reset_token: '',
            source: 'unspecified',
            sitename: 'unspecified',
            lid: 'unspecified',
        }
    }

    componentDidMount() {

        if (this.props.match.params.email) {
            this.setState(() => ({email: this.props.match.params.email}));
        }

        if (this.props.match.params.token) {
            this.setState(() => ({password_reset_token: this.props.match.params.token}));
        }

        if (this.props.match.params.source) {
            this.setState(() => ({source: this.props.match.params.source}));
        }

        if (this.props.match.params.sitename) {
            this.setState(() => ({sitename: this.props.match.params.sitename}));
        }

        if (this.props.match.params.lid) {
            this.setState(() => ({lid: this.props.match.params.lid}));
        }

        this.props.dispatch(resetPasswordFormUpdate({prop: 'loading', value: true}));
        this.props.dispatch(checkPasswordResetTokenForEmail(this.props.match.params.email, this.props.match.params.token, resetPasswordFormError, resetPasswordFormUpdate, 
                        'There was a problem with your reset token. Please check that you have the right address or request another reset.', 'loading', 'criticalError'));
    }


    onSubmitReset(e) {
        e.preventDefault();

        if (!this.props.password || !this.props.password2) {
            this.props.dispatch(resetPasswordFormError('Please enter all info before proceeding.'));
        }
        else if (this.props.password != this.props.password2){
            this.props.dispatch(resetPasswordFormError('Provided passwords must match.'));
        }
        else if(this.props.password.length < 6){
            this.props.dispatch(resetPasswordFormError('Minimum password length must be 6 characters'));
        }
        else {
            this.props.dispatch(resetPassword(this.state.email, this.props.password, this.props.password2, 
                            this.state.password_reset_token, resetPasswordFormError, this.props.history, this.state.source,  this.state.sitename,  this.state.lid));
        }

    }

    render() {

        if (this.props.loading) {
            return (
                <div className="page-loading">
                    <CircularProgress size={100} />
                </div>
            );
        }
        else if(this.props.successAppReset){
            return (
                <div className="login-form">
                    <div className="paper">
                        <img src={logo} width="150px" />
    
                        <h2>Reset Password</h2>

                        { this.props.message && this.props.resetPasswordForm.successShowOnlineLink == false && (
                                <Typography className="success-text">{ this.props.message }</Typography>) }

                        { this.state.source == 'online' && this.props.resetPasswordForm.successShowOnlineLink && this.props.resetPasswordForm.onlineReturnLink && 
                                this.props.resetPasswordForm.onlineReturnName && (
                                        <Typography className="success-text">Password successfully set! Please return to <Link href={this.props.resetPasswordForm.onlineReturnLink}>{this.props.resetPasswordForm.onlineReturnName}</Link> and log in with your new password.</Typography>
                                    ) }
    
                    </div>
                </div>
            );
        }
        else {
            return (
                <div className="login-form">
                    <div className="paper">
                        <img src={logo} width="150px" />
    
                        <h2>Reset Password</h2>
    
                        <form className="form" onSubmit={this.onSubmitReset}>
                            { this.props.error && <Alert className="form-error" severity="error">{this.props.error}</Alert> }
                            {(this.state.email) && <input type="hidden" name="email" value={this.state.email} />}
    
                            <FormControl margin="normal" required fullWidth>
                                <InputLabel htmlFor="password">Your New Password (Minimum 6 characters)</InputLabel>
                                <Input name="password" type="password" id="password" value={this.props.password} onChange={(e) => this.props.dispatch(resetPasswordFormUpdate({ prop: 'password', value: e.target.value }))} />
                            </FormControl>
    
                            <FormControl margin="normal" required fullWidth>
                                <InputLabel htmlFor="password2">Enter It Again (Minimum 6 characters)</InputLabel>
                                <Input name="password2" type="password" id="password2" value={this.props.password2} onChange={(e) => this.props.dispatch(resetPasswordFormUpdate({ prop: 'password2', value: e.target.value }))} />
                            </FormControl>
    
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                size="large"
                                className="submit submit-prim"
                            >
                                Set Password
                            </Button>
                        </form>
                    </div>
                </div>
            );
        }
    }

};

const mapStateToProps = (state, props) => {
    return {
        emailAddress: state.resetPasswordForm.emailAddress,
        password: state.resetPasswordForm.password,
        password2: state.resetPasswordForm.password2,
        error: state.resetPasswordForm.error,
        message: state.resetPasswordForm.message,
        loading: state.resetPasswordForm.loading,
        successAppReset: state.resetPasswordForm.successAppReset,
        resetPasswordForm: state.resetPasswordForm,
    };
};

export default connect(mapStateToProps)(ResetPasswordPage);