import React from 'react';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import arrayMove from 'array-move';

import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

import LoadScreen from '../../common/LoadScreen';
import verifyLogin from '../../../utils/verifyLogin';
import { loadModifiers } from '../../../actions/menu/modifiers/modifiers';
import { loadFoodItems, loadAllFoodItems, setFoodItemFilter, updateFoodItemOrder, toggleFoodItemEnabled } from '../../../actions/menu/fooditems/foodItems';
import { foodItemFormUpdate, foodItemFormError, foodItemFormReset } from '../../../actions/menu/fooditems/foodItemForm';
import { openFoodItemCreate, closeFoodItemCreate, openFoodItemUpdate, closeFoodItemUpdate } from '../../../actions/modals';
import { loadPageData } from '../../../actions/auth';
import filterFoodItems from '../../../selectors/foodItems';

import CreateFoodItem from './modal/CreateFoodItem';
import UpdateFoodItem from './modal/UpdateFoodItem';

import SortableMenuList from '../common/SortableMenuList';

import checkPermissions from '../../../utils/checkUserPermissions';

class FoodItemsPage extends React.Component {

    constructor(props) {
        super(props);

        this.toggleCreateModal = this.toggleCreateModal.bind(this);
        this.toggleUpdateModal = this.toggleUpdateModal.bind(this);
        this.toggleFoodItemEnabled = this.toggleFoodItemEnabled.bind(this);
        this.checkPermissions = this.checkPermissions.bind(this);
        this.onSortEnd = this.onSortEnd.bind(this);

        this.state = {
            sidebarMenuOpen: false,
            selectedFoodItem: {}
        };
          
    }

    componentDidMount() {
        verifyLogin(this.props.history);
    }

    componentDidUpdate() {
        verifyLogin(this.props.history);
    }

    checkPermissions() {
        if (checkPermissions(this.props.user, 'restaurant_manager', this.props.match.params.id) ||
            checkPermissions(this.props.user, 'support')) {
            // load the restaurant
            this.props.dispatch(loadPageData(this.props.match.params));
            this.props.dispatch(loadFoodItems(this.props.match.params.id, this.props.match.params.category_id));
            this.props.dispatch(loadAllFoodItems(this.props.match.params.id, this.props.match.params.category_id));
            this.props.dispatch(loadModifiers(this.props.match.params.id));
        } else {
            this.props.history.push('/permission-denied');
        } 
    }

    toggleUpdateModal(isOpen, selectedFoodItem) {
        if (isOpen) {
            
            let scrollPosition = document.documentElement.scrollTop || document.body.scrollTop;

            this.setState(() => ({
                selectedFoodItem
            }));
            this.props.dispatch(foodItemFormReset());
            this.props.dispatch(foodItemFormUpdate({ prop: 'name', value: selectedFoodItem.name }));
            this.props.dispatch(foodItemFormUpdate({ prop: 'description', value: selectedFoodItem.description }));
            this.props.dispatch(foodItemFormUpdate({ prop: 'thumb_image', value: selectedFoodItem.thumb_image }));                        
            this.props.dispatch(foodItemFormUpdate({ prop: 'non_taxable', value: selectedFoodItem.non_taxable }));
            this.props.dispatch(foodItemFormUpdate({ prop: 'choices', value: selectedFoodItem.choices }));
            this.props.dispatch(foodItemFormUpdate({ prop: 'prices', value: selectedFoodItem.prices }));
            this.props.dispatch(foodItemFormUpdate({ prop: 'addons', value: selectedFoodItem.addons }));
            this.props.dispatch(foodItemFormUpdate({ prop: 'modifiers', value: selectedFoodItem.modifiers }));
            this.props.dispatch(foodItemFormUpdate({ prop: 'scrollPosition', value: scrollPosition }));
            if(selectedFoodItem.hours_available != null){
                this.props.dispatch(foodItemFormUpdate({ prop: 'hours_available', value: selectedFoodItem.hours_available }));
            }
            this.props.dispatch(foodItemFormUpdate({ prop: 'alwaysAvailable', value: selectedFoodItem.alwaysAvailable }));

            // configure the price setup
            if (selectedFoodItem.prices.length == 1 && selectedFoodItem.prices[0].name == '<default>') {

                this.props.dispatch(foodItemFormUpdate({ prop: 'hasMultiplePrices', value: false }));
                this.props.dispatch(foodItemFormUpdate({ prop: 'singlePrice', value: selectedFoodItem.prices[0].price }));
            } else if (selectedFoodItem.prices.length > 1) {
                this.props.dispatch(foodItemFormUpdate({ prop: 'hasMultiplePrices', value: true }));
            }

            this.props.dispatch(openFoodItemUpdate());
        }
        else {
            this.props.dispatch(closeFoodItemUpdate());
        }
    }

    toggleCreateModal(isOpen) {
        if (isOpen) {
            this.props.dispatch(foodItemFormReset());
            this.props.dispatch(foodItemFormUpdate({ prop: 'activeTab', value: 0 }));
            this.props.dispatch(openFoodItemCreate());
        }
        else {
            this.props.dispatch(closeFoodItemCreate());
        }
    }

    onSortEnd({oldIndex, newIndex})  {

        let newFoodItemOrder = arrayMove(this.props.foodItems, oldIndex, newIndex);
        for (let i = 0; i < newFoodItemOrder.length; i++) {
            newFoodItemOrder[i]['food_order'] = i + 1;
        }

        this.props.dispatch(updateFoodItemOrder(this.props.match.params.id, this.props.match.params.category_id, newFoodItemOrder));
    }

    toggleFoodItemEnabled(foodItem, enabled) {
        let confirmStr = "Are you sure you want to disable this food item?";
        if (enabled) {
            confirmStr = "Enable this food item?";
        }

        if (confirm(confirmStr)) {
            this.props.dispatch(toggleFoodItemEnabled(this.props.match.params.id, this.props.match.params.category_id, foodItem.id, enabled));
        };
    }

    renderFoodItems() {        
        if (this.props.loading) {
            return (
                <div className="page-loading">
                    <CircularProgress size={100} />
                </div>
            );
        }
        else {
            if (this.props.foodItems.length <= 0) {
                return (
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography className="no-results">No food items entered.</Typography>
                        </Grid>
                    </Grid>
                );
            }
            else {
                return (
                    <SortableMenuList
                        items={this.props.foodItems}
                        toggleEnabled={this.toggleFoodItemEnabled}
                        toggleUpdateModal={this.toggleUpdateModal}
                        onSortEnd={this.onSortEnd}
                        showImage={true}
                        isCategory={false}
                    />
                );
            }
        }
    }
    
    render() {
        return (
            <LoadScreen
                checkPermissions={this.checkPermissions}
            >
                <div className="card-container">
                    <Grid container className="menu-page-header">
                        <Grid item xs={12} className="heading-grid">

                            <h6 style={breadCrumbStyle}><span onClick={() => {this.props.history.push('/'+this.props.match.params.id+'/categories')}} style={breadCrumbLinkStyle}>Categories</span>&nbsp;&nbsp;&nbsp;&nbsp;</h6>
                            <h1>{this.props.selectedCategory.name}</h1>

                            <FormControl fullWidth>
                                <TextField 
                                    label="Filter Food Items" 
                                    variant="outlined" 
                                    value={this.props.filter}
                                    onChange={(e) => this.props.dispatch(setFoodItemFilter(e.target.value))} 
                                />
                            </FormControl>
                        </Grid>
                    </Grid>

                    {this.renderFoodItems()}
                </div>

                <div className="window-fab">
                    <Fab className="btn-fab btn-add" onClick={() => this.toggleCreateModal(true)}>
                        <AddIcon />
                    </Fab>
                </div>

                <CreateFoodItem 
                    categoryId={this.props.match.params.category_id}
                    restaurantId={this.props.match.params.id}
                />

                <UpdateFoodItem 
                    foodItem={this.state.selectedFoodItem}
                    categoryId={this.props.match.params.category_id}
                    restaurantId={this.props.match.params.id}
                />
            </LoadScreen>
        );
    }
};

const breadCrumbStyle = {
  float:"left",
  color: '#34a8e0',
  fontSize: '1rem',
  display: 'block',
  paddingTop: '20px',
}
const breadCrumbLinkStyle = {
    textDecoration:"none",
    cursor: 'pointer'
}

const mapStateToProps = (state, props) => {
    return {
        loading: state.foodItems.loading || state.restaurants.loading,
        restaurant: state.selectedRestaurant.restaurant,
        foodItems: filterFoodItems(state.foodItems.foodItems, {text: state.foodItems.filter}),
        foodItemForm: state.foodItemForm,
        createModalOpen: state.modals.foodItemCreate,
        updateModalOpen: state.modals.foodItemUpdate,
        selectedCategory: state.selectedCategory.category,
        user: state.loggedUser.user,
    };
};

export default connect(mapStateToProps)(FoodItemsPage);