import axios from 'axios';
import { API_URL } from '../../config/constants';
import { closeLoyaltyRewardCreate, closeLoyaltyPointsAdd } from '../modals';
import { 
    loyaltyRewardFormReset, 
    loyaltyRewardFormError, 
    loyaltyRewardFormSuccess, 
    loyaltyRewardFormUpdate 
} from './loyaltyRewardForm';
import { 
    loyaltyPointsFormReset, 
    loyaltyPointsFormError, 
    loyaltyPointsFormSuccess, 
    loyaltyPointsFormUpdate 
} from './loyaltyPointsForm';

export const setLoyaltySettings = (ratio, rewards, users) => {
    return {
        type: 'SET_LOYALTY_SETTINGS',
        ratio,
        rewards,
        users
    }
}

export const setLoyaltyFilter = (text) => {
    return {
        type: 'SET_LOYALTY_FILTER',
        text
    }
}

export const resetLoyalty = () => {
    return {
        type: 'RESET_LOYALTY'
    }
}

export const setLoyaltyLoading = () => ({
    type: 'SET_LOYALTY_LOADING'
});

export const loyaltyFormError = (message) => {

    return {
        type: 'LOYALTY_FORM_ERROR',
        message
    };
};

export const loyaltyFormUpdate = ({ prop, value }) => {

    return {
        type: 'LOYALTY_SETTING_UPDATE',
        setting: { prop, value }
    };
};

export const loadLoyalty = (restaurant_id) => {
    return (dispatch) => {
        return axios.get(API_URL+'/api/restaurants/'+restaurant_id+'/loyalty')
            .then(function(res) {
                dispatch(setLoyaltySettings(res.data.data.ratio, res.data.data.rewards, res.data.data.users));
            })
            .catch(function(err) {
                console.log("Err!");
                console.log(err);
            });
    };
}

export const updateLoyaltySettings = (restaurantId, { ratio }) => {

    return (dispatch) => {

        return axios.post(
                API_URL+'/api/restaurants/'+restaurantId+'/loyalty', 
                {
                    ratio
                }
            )
            .then(function(res) {
                if (res.data.status == 'success') {
                    dispatch(setLoyaltySettings(res.data.data.ratio, res.data.data.rewards, res.data.data.users));
                    dispatch(loyaltyFormUpdate({ prop: 'message', value: 'Ratio Updated.' }));
                }
                else {
                    dispatch(loyaltyFormError(res.data.message));
                }
            })
            .catch(function(err) {
                
                // token is likely invalid; remove it and prompt the user to login again
                dispatch(loyaltyFormError("We're sorry, but our system has encountered an issue, to which we have been notified."));
                if (err.response.status === 401) {
                    removeAuthToken(dispatch);
                }
                
            });
    };
};

export const addLoyaltyReward = (restaurantId, loyaltyReward) => {

    return (dispatch) => {

        dispatch(loyaltyRewardFormUpdate({ prop: 'loading', value: true }));

        return axios.post(
                API_URL+'/api/restaurants/'+restaurantId+'/loyalty/reward', 
                loyaltyReward
            )
            .then(function(res) {
                if (res.data.status == 'success') {
                    dispatch(setLoyaltySettings(res.data.data.ratio, res.data.data.rewards, res.data.data.users));
                    dispatch(loyaltyRewardFormReset());
                    dispatch(loyaltyRewardFormUpdate({ prop: 'loading', value: false }));
                    dispatch(closeLoyaltyRewardCreate());
                }
                else {
                    dispatch(loyaltyRewardFormUpdate({ prop: 'loading', value: false }));
                    dispatch(loyaltyRewardFormError(res.data.message));
                }
            })
            .catch(function(err) {
                
                // token is likely invalid; remove it and prompt the user to login again
                dispatch(loyaltyRewardFormUpdate({ prop: 'loading', value: false }));
                dispatch(loyaltyRewardFormError("We're sorry, but our system has encountered an issue, to which we have been notified."));
                if (err.response.status === 401) {
                    removeAuthToken(dispatch);
                }
                
            });
    };
};

export const updateLoyaltyReward = (restaurantId, rewardId, scope, reward) => {
    
    return (dispatch) => {

        const formData = new FormData();

        // add the data
        formData.append('scope', scope);
        formData.append('data', JSON.stringify(reward));

        dispatch(loyaltyRewardFormUpdate({ prop: 'loading', value: true }));

        return axios.post(
            API_URL+'/api/restaurants/'+restaurantId+'/loyalty/reward/'+rewardId, 
            formData,
            {
                headers: { 'Content-Type': 'multipart/form-data' }
            }
        ).then(function(res) {
            if (res.data.status == 'success') {
                dispatch(setLoyaltySettings(res.data.data.ratio, res.data.data.rewards, res.data.data.users));
                dispatch(loyaltyRewardFormReset());

                dispatch(loyaltyRewardFormUpdate({ prop: 'name', value: res.data.data.reward.name }));
                dispatch(loyaltyRewardFormUpdate({ prop: 'value', value: res.data.data.reward.value }));
                dispatch(loyaltyRewardFormUpdate({ prop: 'points', value: res.data.data.reward.points }));
                dispatch(loyaltyRewardFormUpdate({ prop: 'loading', value: false }));

                dispatch(loyaltyRewardFormSuccess('Reward Updated!'));
            }
            else {
                dispatch(loyaltyRewardFormError(res.data.message));
                console.log(res.data);
                console.log("Could not update your reward!");
            }
        })
        .catch(function(err) {
            console.log('err');
            console.log(err);
            // token is likely invalid; remove it and prompt the user to login again
            dispatch(loyaltyRewardFormError("We're sorry, but our system has encountered an issue, to which we have been notified."));
            if (err.response && err.response.status === 401) {
                removeAuthToken(dispatch);
            }
            
        });
    };
};

export const updateLoyaltyPoints = (restaurantId, scope, data) => {
    
    return (dispatch) => {

        const formData = new FormData();

        // add the data
        formData.append('scope', scope);
        formData.append('data', JSON.stringify(data));

        dispatch(loyaltyPointsFormUpdate({ prop: 'loading', value: true }));

        return axios.post(
            API_URL+'/api/restaurants/'+restaurantId+'/loyalty/points', 
            formData,
            {
                headers: { 'Content-Type': 'multipart/form-data' }
            }
        ).then(function(res) {
            if (res.data.status == 'success') {
                dispatch(setLoyaltySettings(res.data.data.ratio, res.data.data.rewards, res.data.data.users));
                dispatch(loyaltyPointsFormUpdate({ prop: 'loading', value: false }));

                if (scope == 'add') {
                    dispatch(closeLoyaltyPointsAdd());
                }
                else {
                    dispatch(loyaltyPointsFormSuccess('Loyalty User Updated!'));
                }
            }
            else {
                dispatch(loyaltyPointsFormError(res.data.message));
                dispatch(loyaltyPointsFormUpdate({ prop: 'loading', value: false }));
                console.log(res.data);
                console.log("Could not update points!");
            }
        })
        .catch(function(err) {
            console.log('err');
            console.log(err);
            // token is likely invalid; remove it and prompt the user to login again
            dispatch(loyaltyPointsFormError("We're sorry, but our system has encountered an issue, to which we have been notified."));
            dispatch(loyaltyPointsFormUpdate({ prop: 'loading', value: false }));
            if (err.response && err.response.status === 401) {
                removeAuthToken(dispatch);
            }
            
        });
    };
};