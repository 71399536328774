import React from 'react';
import ImageUploader from "react-images-upload";
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';

import moment from 'moment';
import TimePicker from 'rc-time-picker';

import { specialFormUpdate, specialFormUpdateHoursAvailable } from '../../../../actions/menu/specials/specialForm';
import { API_URL } from '../../../../config/constants';

const daysOfWeek = [
    {
        label: 'Sunday',
        id: 'sun'
    },
    {
        label: 'Monday',
        id: 'mon'
    },
    {
        label: 'Tuesday',
        id: 'tue'
    },
    {
        label: 'Wednesday',
        id: 'wed'
    },
    {
        label: 'Thursday',
        id: 'thu'
    },
    {
        label: 'Friday',
        id: 'fri'
    },
    {
        label: 'Saturday',
        id: 'sat'
    }
];

class Info extends React.Component {

    constructor(props) {
        super(props);
        
        this.onDrop = this.onDrop.bind(this);
        this.fillSchedule = this.fillSchedule.bind(this);

        this.state = { 
            pictures: [],
            selectedScheduleID: 0
        };

    }
    
    setSelectedSchedule(scheduleID){
        this.setState(() => ({ 
            selectedScheduleID: scheduleID
        }));
    }

    fillSchedule(){

        let schedule = this.props.restaurant.availability_schedules.filter((schedule) => {
            return schedule.id == this.state.selectedScheduleID;
        });

        this.props.dispatch(specialFormUpdate({ prop: 'hours_available', value: schedule[0] }));

    }

    onDrop(pictureFiles, pictureDataURLs) {
        this.props.dispatch(specialFormUpdate({ prop: 'newImage', value: pictureFiles[0] }));
    }
    
    render() {
        return (
            <div>
                <Typography className="modal-description">
                    Please enter a special name, price and description.
                </Typography>

                <Grid container>
                    <Grid item xs={12} className="form-grid">
                        <TextField
                            label="Name"
                            className="modal-input"
                            fullWidth={true}
                            value={this.props.specialForm.name}
                            onChange={(e) => { this.props.dispatch(specialFormUpdate({ prop: 'name', value: e.target.value })); }}
                            margin="normal"
                        />

                        <TextField
                            label="Description"
                            className="modal-input"
                            fullWidth={true}
                            multiline={true}
                            value={this.props.specialForm.description}
                            onChange={(e) => { this.props.dispatch(specialFormUpdate({ prop: 'description', value: e.target.value })); }}
                            margin="normal"
                        />

                        <TextField
                            label="Price"
                            className="modal-input"
                            fullWidth={true}
                            value={this.props.specialForm.price}
                            onChange={(e) => { this.props.dispatch(specialFormUpdate({ prop: 'price', value: e.target.value })); }}
                            margin="normal"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox 
                                    checked={!!this.props.specialForm.non_taxable}
                                    onChange={(e) => this.props.dispatch(specialFormUpdate({ prop: 'non_taxable', value: +!this.props.specialForm.non_taxable }))}
                                    name={"non_taxable"}
                                    color="primary"
                                />
                            }
                            label="Non-taxable"
                        />
                    </Grid>
                    <Grid item xs={4} className="form-grid">
                        {( this.props.specialForm.thumb_image && <img src={API_URL+'/'+this.props.specialForm.thumb_image} />)}
                    </Grid>
                    <Grid item xs={8} className="form-grid">
                        <ImageUploader
                            withIcon={true}
                            withPreview={true}
                            buttonText="Choose a Photo"
                            onChange={this.onDrop}
                            imgExtension={[".jpg", ".jpeg", ".gif", ".png", ".gif"]}
                            maxFileSize={5242880}
                            singleImage={true}
                        />
                    </Grid>
                    <Grid item xs={12} className="form-grid">
                        <Typography variant="h3" style={{paddingTop: "40px"}}>
                            Availability
                        </Typography>
                        <FormControl>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue="alwaysAvailable"
                                value={this.props.specialForm.alwaysAvailable ? 'alwaysAvailable' : 'setAvailability'}
                                name="radio-buttons-group"
                                onChange={(e) => {
                                    this.props.dispatch(specialFormUpdate({ prop: 'alwaysAvailable', value: !this.props.specialForm.alwaysAvailable }));
                                }}
                            >
                                <FormControlLabel value="alwaysAvailable" control={<Radio />} label="Always Available" />
                                <FormControlLabel value="setAvailability" control={<Radio />} label="Set Availability" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid container style={{padding: "20px 0px"}}>

                        {(!this.props.specialForm.alwaysAvailable) &&
                            <Grid container xs={6} className="previous-choice-window" style={{marginBottom: 20}}>
                                <Typography variant="h4">
                                    Use a saved schedule
                                </Typography>
                                <Grid container style={{marginBottom: 10, marginTop: 10}}>
                                    <Grid item xs={8} className="form-grid">
                                        <InputLabel>Availability Schedule</InputLabel>
                                        <Select
                                            fullWidth={true}
                                            labelId="type"
                                            value={this.state.selectedScheduleID}
                                            onChange={(e) => { this.setSelectedSchedule(e.target.value); }}
                                        >
                                            {this.props.restaurant.availability_schedules.map((schedule) => (
                                                <MenuItem key={schedule.id} value={schedule.id}>{schedule.name}</MenuItem>
                                            ))}
                                        </Select>
                                    </Grid>
                                    {this.state.selectedScheduleID != 0 && (
                                        <Grid item xs={4} className="form-grid">
                                            <Button 
                                                variant="contained" 
                                                fullWidth={true} 
                                                color="primary" 
                                                className="modal-button-list" 
                                                onClick={this.fillSchedule}
                                            >
                                                Fill Fields
                                            </Button>
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                        }
                        {(!this.props.specialForm.alwaysAvailable) &&
                            <Grid container key={"render1"} className="hour-of-operation-list">
                                <Grid item xs={3} className="form-grid"></Grid>
                                <Grid item xs={3} className="form-image-container">
                                    <Typography>
                                        Start Time
                                    </Typography>
                                </Grid>
                                <Grid item xs={3} className="form-image-container">
                                    <Typography>
                                        End Time
                                    </Typography>
                                </Grid>
                                <Grid item xs={3} className="form-grid"></Grid>
                            </Grid>
                        }

                        {(!this.props.specialForm.alwaysAvailable) && daysOfWeek.map((day) =>{
                            
                            const openingTimes = this.props.specialForm.hours_available[day.id+'_start'].split(":");
                            let openingHours = openingTimes[0];
                            let openingMinutes = openingTimes[1];

                            const closingTimes = this.props.specialForm.hours_available[day.id+'_end'].split(":");
                            let closingHours = closingTimes[0];
                            let closingMinutes = closingTimes[1];

                            return (
                                <Grid container key={"renderow1"+day.id} className="hour-of-operation-list">
                                    <Grid item xs={3} className="form-grid">
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={this.props.specialForm.hours_available[day.id+'_available']}
                                                    onChange={(e) => { 
                                                        this.props.dispatch(specialFormUpdateHoursAvailable({ prop: day.id+'_available', value: !this.props.specialForm.hours_available[day.id+'_available'] })); 
                                                    }}
                                                    name={day.label}
                                                    color="primary"
                                                />
                                            }
                                            label={day.label}
                                        />
                                    </Grid>
                                    <Grid item xs={3} className="form-grid">
                                        <TimePicker
                                            allowEmpty={false}
                                            showSecond={false}
                                            value={moment("2000-01-01").set("hour", openingHours).set("minute", openingMinutes)} 
                                            use12Hours={true}
                                            minuteStep={5}
                                            inputClassName="date-picker-input"
                                            onChange={(date) => { 
                                                let timeString = date.hour().toString().padStart(2, '0') + ":" + date.minutes().toString().padStart(2, '0') + ":00";                                            
                                                this.props.dispatch(specialFormUpdateHoursAvailable({ prop: day.id+'_start', value: timeString }));
                                            }}
                                            disabled={!this.props.specialForm.hours_available[day.id+'_available'] || this.props.specialForm.hours_available[day.id+'_all_day']} 
                                        />

                                    </Grid>
                                    <Grid item xs={3} className="form-grid">
                                        <TimePicker
                                            allowEmpty={false}
                                            showSecond={false}
                                            value={moment("2000-01-01").set("hour", closingHours).set("minute", closingMinutes)}
                                            use12Hours={true}
                                            minuteStep={5}
                                            inputClassName="date-picker-input"
                                            onChange={(date) => {
                                                let timeString = date.hour().toString().padStart(2, '0') + ":" + date.minutes().toString().padStart(2, '0') + ":00";
                                                this.props.dispatch(specialFormUpdateHoursAvailable({ prop: day.id+'_end', value: timeString }));                                            
                                            }}
                                            disabled={!this.props.specialForm.hours_available[day.id+'_available'] || this.props.specialForm.hours_available[day.id+'_all_day']}
                                        />
                                    </Grid>
                                    <Grid item xs={3} className="form-grid">
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={this.props.specialForm.hours_available[day.id+'_all_day']}
                                                    onChange={(e) => { 
                                                        this.props.dispatch(specialFormUpdateHoursAvailable({ prop: day.id+'_all_day', value: !this.props.specialForm.hours_available[day.id+'_all_day'] })); 
                                                    }}
                                                    name={'availableAllDay'}
                                                    color="primary"
                                                    disabled={!this.props.specialForm.hours_available[day.id+'_available']}
                                                />
                                            }
                                            label={"Available All Day?"}
                                        />
                                    </Grid>
                                </Grid>
                            )
                            
                        })}
                        {(!this.props.specialForm.alwaysAvailable) &&
                            <Grid container>
                                <Grid xs={3} style={{display: 'flex', alignItems: 'end'}}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={this.props.specialForm.saveSchedule}
                                                onChange={(e) => { 
                                                    this.props.dispatch(specialFormUpdate({ prop: 'saveSchedule', value: !this.props.specialForm.saveSchedule }));
                                                }}
                                                name={'saveSchedule'}
                                                color="primary"
                                            />
                                        }
                                        label={"Save Schedule?"}
                                    />
                                </Grid>
                                <Grid xs={6}>
                                    <TextField
                                        id="create-category-title"
                                        label="Schedule Name"
                                        className="modal-input"
                                        fullWidth={true}
                                        value={this.props.specialForm.scheduleName}
                                        onChange={(e) => { this.props.dispatch(specialFormUpdate({ prop: 'scheduleName', value: e.target.value })); }}
                                        margin="normal"
                                        disabled={!this.props.specialForm.saveSchedule}
                                    />
                                </Grid>                            
                            </Grid>
                        }

                    </Grid>

                </Grid>
            </div>
        );
    }
};

const mapStateToProps = (state, props) => {
    return {
        specialForm: state.specialForm,
        restaurant: state.selectedRestaurant.restaurant
    };
};

export default connect(mapStateToProps)(Info);