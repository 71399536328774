import React, { Component } from 'react';
import closeIcon from '../../../assets/images/x_icon.png';
import { connect } from 'react-redux';
import {resetModals} from '../../../actions/modals';

let closeImgStyle = {
    cursor:'pointer',
    float:'right',
    marginTop: '0px',
    width: '20px',
};

let hoverImgStyle = {
    ...closeImgStyle,
    opacity:"0.5"
};

class CloseIcon extends React.Component {

    constructor(props) {
        super(props);

        this.toggleHover = this.toggleHover.bind(this);

        this.state = {
            hover: false
        };

    }

    toggleHover(){
        this.setState({hover: !this.state.hover});
    }

    render() {
        let imgStyle = (this.state.hover) ? hoverImgStyle: closeImgStyle;
        return (
            <img
                src={closeIcon}
                style={imgStyle}
                onClick={() => {
                    this.props.dispatch(resetModals());
                }}
                onMouseEnter={this.toggleHover}
                onMouseLeave={this.toggleHover}
            />
        );
    }

}

export default connect()(CloseIcon);