import React from 'react';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ListSubheader from '@material-ui/core/ListSubheader';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import DeleteIcon from '@material-ui/icons/Delete';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { itemFormUpdate, itemFormAddItem, itemFormRemoveItem } from '../../../../actions/menu/specials/itemForm';

class ItemForm extends React.Component {

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleAddItem = this.handleAddItem.bind(this);
        this.handleAddChoices = this.handleAddChoices.bind(this);
        this.handleDeleteItem = this.handleDeleteItem.bind(this);

        this.state = {
            itemDisplayView: (props.itemForm.has_choices) ? 1 : 0,
            selectedItemSizes: [],
            selectedItemId: 0,
            selectedItemType: 'foodItems',
            selectedSizeId: 0
        };
    }

    componentDidMount() {
        if (this.props.itemForm.has_choices) {
            this.setState(() => ({itemDisplayView: 1}));
        }
    }

    handleDeleteItem(index) {
        this.props.dispatch(itemFormRemoveItem(index));
    }

    handleChange(event, newValue) {
        const hasChoices = (newValue) ? true : false;

        this.props.dispatch(itemFormUpdate({ prop: 'has_choices', value: hasChoices }));
        this.setState(() => ({itemDisplayView: newValue}))
    }

    handleAddItem() {
        if (this.props.itemForm.has_choices) {

            // it has choices; add it to the list
            this.props.dispatch(itemFormAddItem({
                type: this.state.selectedItemType,
                item_id: this.state.selectedItemId,
                size_id: this.getSizeId(),
                name: this.getSelectionName()
            }));
        }
        else {
            let newItem = {
                ...this.props.itemForm,
                items: this.props.itemForm.items.concat({
                    type: this.state.selectedItemType,
                    item_id: this.state.selectedItemId,
                    size_id: this.getSizeId(),
                    name: this.getSelectionName()
                })
            };
            newItem.name = this.getItemName(newItem);

            this.props.handleAddItem(newItem);
        }
    }

    handleAddChoices() {

        let newItem = this.props.itemForm;
        newItem.name = this.getItemName(newItem);

        this.props.handleAddItem(newItem);
    }

    getSelectionName() {
        // filter out the item we need
        const items = this.props.selectableItems[this.state.selectedItemType].filter((item) => {
            return item.item_id == this.state.selectedItemId;
        });

        if (this.state.selectedItemSizes.length > 1) {

            for (let i = 0; i < this.state.selectedItemSizes.length; i++) {
                if (this.state.selectedItemSizes[i].size_id == this.state.selectedSizeId) {
                    return this.state.selectedItemSizes[i].name + ' ' + items[0].name;
                }
            }
        }
        else {
            return items[0].name;
        }
    }

    getItemName(item) {
        // filter out the item we need
        if (item.has_choices) {
            let itemName = "Choice of: ";

            for (let i = 0; i < item.items.length; i++) {
                itemName += item.items[i].name + ', ';
            }
            itemName = itemName.replace(/, $/, '');
            return itemName;
        }
        else {
            return item.items[0].name;
        }
    }

    getSizeId() {
        if (this.state.selectedSizeId != 0) {
            return this.state.selectedSizeId;
        }
        else {
            return this.state.selectedItemSizes[0].size_id;
        }
    }

    setSelectedItem(itemId) {

        // separate the type from the id
        const itemMatches = itemId.match(/^\s*(pizzas|foodItems|customPizzas)\-(\d+)\s*$/);
        const type = itemMatches[1];
        itemId = itemMatches[2];

        // filter out the item we need
        const items = this.props.selectableItems[type].filter((item) => {
            return item.item_id == itemId;
        });

        this.setState(() => ({ 
            selectedItemId: itemId,
            selectedItemType: type,
            selectedItemSizes: items[0].sizes,
            selectedSizeId: 0
        }));
    }

    isButtonDisabled() {
        return !(this.state.selectedItemId != 0 && (this.state.selectedItemSizes.length == 1 || this.state.selectedSizeId != 0));
    }

    isChoiceButtonDisabled() {
        if (this.props.itemForm.items.length > 1) {
            return false;
        }

        return true;
    }

    renderItemSelection() {
        return (
            <Grid container>
                <Grid item xs={6} className="form-grid">
                    <InputLabel>Food Item</InputLabel>
                    <Select
                        fullWidth={true}
                        labelId="type"
                        value={this.state.selectedItemType+'-'+this.state.selectedItemId}
                        onChange={(e) => { this.setSelectedItem(e.target.value); }}
                    >
                        <MenuItem key={'foodItems-0'} value={'foodItems-0'}>Select a Menu Item</MenuItem>

                        {(this.props.selectableItems.pizzas.length > 0 || this.props.selectableItems.customPizzas.length > 0) && (
                            <ListSubheader>Pizzas</ListSubheader>
                        )}
                        {this.props.selectableItems.pizzas.map((item, index) => (
                            <MenuItem key={index} value={'pizzas-'+item.item_id}>{item.name}</MenuItem>
                        ))}

                        {this.props.selectableItems.customPizzas.map((item, index) => (
                            <MenuItem key={index} value={'customPizzas-'+item.item_id}>{item.name}</MenuItem>
                        ))}

                        {this.props.selectableItems.foodItems.length > 0 && (
                            <ListSubheader>Menu Items</ListSubheader>
                        )}
                        {this.props.selectableItems.foodItems.map((item, index) => (
                            <MenuItem key={index} value={'foodItems-'+item.item_id}>{item.name}</MenuItem>
                        ))}
                    </Select>
                </Grid>

                {this.state.selectedItemSizes.length > 1 && (
                    <Grid item xs={6} className="form-grid">
                        <InputLabel>Size</InputLabel>
                        <Select
                            fullWidth={true}
                            labelId="type"
                            value={this.state.selectedSizeId}
                            onChange={(e) => { this.setState(() => ({ selectedSizeId: e.target.value })); }}
                        >
                            {this.state.selectedItemSizes.map((size) => (
                                <MenuItem key={size.size_id} value={size.size_id}>{size.name}</MenuItem>
                            ))}
                        </Select>
                    </Grid>
                )}

                <Grid container>
                    {!this.props.itemForm.has_choices && (
                        <Grid item xs={6} className="form-grid">
                            <Button variant="contained" fullWidth={true} className="modal-button-bottom btn-secondary" onClick={this.props.handleGoBack}>
                                Back
                            </Button>
                        </Grid>
                    )}
                    <Grid item xs={(this.props.itemForm.has_choices) ? 12 : 6} className="form-grid">
                        <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom btn-primary" disabled={this.isButtonDisabled()} onClick={this.handleAddItem}>
                            Add Item
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
    
    render() {
        return (
            <div>
                <Tabs
                    value={this.state.itemDisplayView}
                    onChange={this.handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    centered
                >
                    <Tab label="Single Item" />
                    <Tab label="Choice Of:" />
                </Tabs>

                {this.renderItemSelection()}

                {this.props.itemForm.has_choices && (
                    <Grid container>
                        <Grid item xs={12} className="form-grid">
                            <Typography variant="h4" className="mt-1">Choices</Typography>
                        </Grid>

                        {this.props.itemForm.items.length <= 0 && (
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography className="no-results">No items entered.</Typography>
                                </Grid>
                            </Grid>
                        )}
                        {this.props.itemForm.items.map((item, index) => (
                            <Grid key={"specialitem-"+index} container>
                                <Grid item xs={11} className="form-grid">
                                    <p>{item.name}</p>
                                </Grid>
                                <Grid item xs={1} className="form-grid">
                                    <Button variant="contained" fullWidth={true} color="primary" className="modal-button-list" onClick={() => this.handleDeleteItem(index)}>
                                        <DeleteIcon className="modal-button-icon" />
                                    </Button>
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                )}

                {this.props.itemForm.has_choices && (          
                    <Grid container>
                        <Grid item xs={6} className="form-grid">
                            <Button variant="contained" fullWidth={true} className="modal-button-bottom btn-secondary" onClick={this.props.handleGoBack}>
                                Back
                            </Button>
                        </Grid>
                        <Grid item xs={6} className="form-grid">
                            <Button variant="contained" fullWidth={true} className="modal-button-bottom btn-primary" onClick={this.handleAddChoices} disabled={this.isChoiceButtonDisabled()}>
                                Add Item with Choices
                            </Button>
                        </Grid>
                    </Grid>
                )}
            </div>
        );
    }
};

const mapStateToProps = (state, props) => {
    return {
        itemForm: state.itemForm,
        selectableItems: state.specials.selectableItems
    };
};

export default connect(mapStateToProps)(ItemForm);