import React from 'react';
import { connect } from 'react-redux';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { promoCodeFormUpdate, promoCodeUpdateValidPromoDays } from '../../../actions/restaurant/promoCodeForm';

import PromoDaySelection from '../../common/modal/PromoDaySelection';

class PromoCodeForm extends React.Component {

    constructor(props) {
        super(props);

        this.updateThresholdChoice = this.updateThresholdChoice.bind(this);
        this.updatePromoTotalType = this.updatePromoTotalType.bind(this);
        this.updateThresholdValue = this.updateThresholdValue.bind(this);
        this.updateThresholdLimitText = this.updateThresholdLimitText.bind(this);
        this.disableThresholdOptions = this.disableThresholdOptions.bind(this);
        this.enableThresholdOptions = this.enableThresholdOptions.bind(this);
        this.updateAutoApplyValue = this.updateAutoApplyValue.bind(this);

        this.state = { pictures: [] };
    }

    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.updateModalOpen == true && prevProps.updateModalOpen == false) {
          this.updateThresholdLimitText(this.props.promoCodeForm.total_threshold);
        }
    }

    disableThresholdOptions(){
        this.updateThresholdChoice("NO_THRESHOLD");
        this.props.dispatch(promoCodeFormUpdate({ prop: 'threshold_disable', value: true }));
        this.props.dispatch(promoCodeFormUpdate({ prop: 'threshold_limit', value: 0.00 }));
    }

    enableThresholdOptions({total_target = null, total_type = null}){ // Called when one option can enable threshold options, but checks if other options are also valid first
        if(!total_target){
            total_target = this.props.promoCodeForm.total_target;
        }
        if(!total_type){
            total_type = this.props.promoCodeForm.total_type;
        }
        if(total_target == "SUBTOTAL" && total_type == "PERCENTAGE"){
            this.props.dispatch(promoCodeFormUpdate({ prop: 'threshold_disable', value: false }));
        }
    }

    updateThresholdValue(value){
        const validNumber = new RegExp(/^\d*\.?\d*$/);

        if (validNumber.test(value)) {
            this.props.dispatch(promoCodeFormUpdate({ prop: 'threshold_limit', value: value }));
        }
    }

    updateThresholdLimitText(value){
        if(value == "MAX_DISCOUNT_ALLOWED"){
            this.props.dispatch(promoCodeFormUpdate({ prop: 'threshold_limit_text', value: 'Discount up to this dollar amount' }));
        }else if(value == "MINIMUM_TOTAL_SPENT"){
            this.props.dispatch(promoCodeFormUpdate({ prop: 'threshold_limit_text', value: 'Enable after spending this amount' }));
        }else{
            this.props.dispatch(promoCodeFormUpdate({ prop: 'threshold_limit_text', value: 'Limit' }));
        }
    }

    updateThresholdChoice(value) {
        this.props.dispatch(promoCodeFormUpdate({ prop: 'total_threshold', value: value }));
        // If choice was MAX_DISCOUNT_ALLOWED or MINIMUM_TOTAL_SPENT then update threshold_limit_text too
        if(value == "MAX_DISCOUNT_ALLOWED"){
            this.props.dispatch(promoCodeFormUpdate({ prop: 'threshold_limit_text', value: 'Discount up to this dollar amount' }));
        }else if(value == "MINIMUM_TOTAL_SPENT"){
            this.props.dispatch(promoCodeFormUpdate({ prop: 'threshold_limit_text', value: 'Enable after spending this amount' }));
        }else{
            this.props.dispatch(promoCodeFormUpdate({ prop: 'threshold_limit_text', value: 'Limit' }));
            this.props.dispatch(promoCodeFormUpdate({ prop: 'threshold_limit', value: 0.00 }));
        }
    }

    updatePromoTotalType(value){
        this.props.dispatch(promoCodeFormUpdate({ prop: 'total_type', value: value }));
        // Disable threshold options if not doing percent
        if(value == "FIXED"){
            this.disableThresholdOptions();
        }else{
            this.enableThresholdOptions({total_type: value});
        }
    }

    updateAutoApplyValue(){
        if(this.props.promoCodeForm.auto_apply){
            this.props.dispatch(promoCodeFormUpdate({ prop: 'show_on_listing', value: false }));
        }
        this.props.dispatch(promoCodeFormUpdate({ prop: 'auto_apply', value: !this.props.promoCodeForm.auto_apply }));
    }
    
    render() {
        return (
            <Grid container>

                { this.props.promoCodeForm.error && (
                    <Grid item xs={12} className="form-grid">
                        <Typography className="modal-error">
                            { this.props.promoCodeForm.error }
                        </Typography>
                    </Grid>
                )}

                <Grid item xs={12} className="form-grid">
                    <TextField
                        label="code"
                        className="modal-input"
                        fullWidth={true}
                        value={this.props.promoCodeForm.code}
                        onChange={(e) => { this.props.dispatch(promoCodeFormUpdate({ prop: 'code', value: e.target.value })); }}
                        margin="normal"
                        variant="outlined"
                    />
                </Grid>

                <Grid item xs={12} className="form-grid">
                    <Typography variant="h3">
                        Promo Deal
                    </Typography>
                </Grid>

                <Grid item xs={6} className="form-grid">
                    <InputLabel className="input-label-mb"></InputLabel>
                    <Select
                        label="Promo Type"
                        fullWidth={true}
                        margin="none"
                        variant="outlined"
                        value={this.props.promoCodeForm.total_type}
                        onChange={(e) => { 
                            this.updatePromoTotalType(e.target.value);
                        }}
                    >
                        <MenuItem key={'PERCENTAGE'} value={'PERCENTAGE'}>Percentage</MenuItem>
                        <MenuItem key={'FIXED'} value={'FIXED'}>Fixed</MenuItem>
                    </Select>
                </Grid>

                <Grid item xs={6} className="form-grid">
                    <TextField
                        label="Amount"
                        className="modal-input"
                        fullWidth={true}
                        value={this.props.promoCodeForm.total}
                        onChange={(e) => { this.props.dispatch(promoCodeFormUpdate({ prop: 'total', value: e.target.value })); }}
                        margin="normal"
                        variant="outlined"
                    />
                </Grid>

                <Grid item xs={12} className="form-grid">
                    <Typography variant="h3" id="modal-title">
                        Spending Threshold (% off promos only)
                    </Typography>
                </Grid>

                <Grid item xs={4} className="form-grid">
                    <Grid container direction='column'>
                        <RadioGroup
                            aria-labelledby="threshold-radio-buttons-group-label"
                            defaultValue="NO_THRESHOLD"
                            value={this.props.promoCodeForm.total_threshold}
                            disabled={this.props.promoCodeForm.threshold_disable}
                            name="radio-buttons-group"
                            onChange={(e) => {
                                this.props.dispatch(this.updateThresholdChoice(e.target.value));
                            }}
                        >
                            <FormControlLabel value="NO_THRESHOLD" control={<Radio disabled={this.props.promoCodeForm.threshold_disable} />} label="No Threshold" />
                            <FormControlLabel value="MAX_DISCOUNT_ALLOWED" control={<Radio disabled={this.props.promoCodeForm.threshold_disable} />} label="Maximum Discount Allowed" />
                            <FormControlLabel value="MINIMUM_TOTAL_SPENT" control={<Radio disabled={this.props.promoCodeForm.threshold_disable} />} label="After Minimum Spent" />
                        </RadioGroup>
                    </Grid>
                </Grid>

                <Grid item xs={4} className="form-grid">
                    <Grid container direction='column'>
                        <span>{this.props.promoCodeForm.threshold_limit_text}</span>
                        <TextField
                            label={this.props.promoCodeForm.threshold_limit_text}
                            className="modal-input"
                            fullWidth={true}
                            disabled={this.props.promoCodeForm.total_threshold == 'NO_THRESHOLD'}
                            value={this.props.promoCodeForm.threshold_limit}
                            onChange={(e) => { this.updateThresholdValue(e.target.value) }}
                            margin="normal"
                            variant="outlined"
                            // error={this.props.promoCodeForm.errorTarget == "threshold_percent"}
                        />
                    </Grid>
                </Grid>

                <Grid item xs={12} className="form-grid">
                    <Typography variant="h3" id="modal-title">
                        Availability (Select One)
                    </Typography>
                </Grid>

                <Grid item xs={4} className="form-grid">
                    <FormControlLabel
                        control={
                        <Checkbox
                            checked={this.props.promoCodeForm.total_avail == 'LIMITED DATE'}
                            onChange={(e) => { this.props.dispatch(promoCodeFormUpdate({ prop: 'total_avail', value: e.target.value })); }}
                            name={'Specific Start/End Date'}
                            color="primary"
                            value={'LIMITED DATE'}
                        />
                        }
                        label={'Specific Start/End Date'}
                    />
                </Grid>

                <Grid item xs={4} className="form-grid">
                    <InputLabel className="input-label-mb">Start Date</InputLabel>
                    <DatePicker 
                        selected={this.props.promoCodeForm.start_time} 
                        onChange={(date) => { this.props.dispatch(promoCodeFormUpdate({ prop: 'start_time', value: date })); }} 
                        disabled={this.props.promoCodeForm.total_avail != 'LIMITED DATE'}
                    />
                </Grid>

                <Grid item xs={4} className="form-grid">
                    <InputLabel className="input-label-mb">End Date</InputLabel>
                    <DatePicker 
                        selected={this.props.promoCodeForm.end_time}
                        onChange={(date) => { 
                            this.props.dispatch(promoCodeFormUpdate({ prop: 'end_time', value: date })); 
                            }} 
                        disabled={this.props.promoCodeForm.total_avail != 'LIMITED DATE'}
                    />
                </Grid>

                <Grid item xs={12} className="form-grid">
                    <FormControlLabel
                        control={
                        <Checkbox
                            checked={this.props.promoCodeForm.total_avail == 'ALWAYS'}
                            onChange={(e) => { this.props.dispatch(promoCodeFormUpdate({ prop: 'total_avail', value: e.target.value })); }}
                            name={'Always Available'}
                            color="primary"
                            value={'ALWAYS'}
                        />
                        }
                        label={'Always Available'}
                    />
                </Grid>

                <Grid item xs={12} className="form-grid">
                    <Typography variant="h3" id="modal-title">
                        Automatically apply this promo?
                    </Typography>
                </Grid>

                <Grid item xs={6} className="form-grid">
                    <FormControlLabel
                        control={
                        <Checkbox
                            checked={this.props.promoCodeForm.auto_apply}
                            onChange={(e) => { this.updateAutoApplyValue() }}
                            name={'Automatically apply'}
                            color="primary"
                            value={1}
                        />
                        }
                        label={'Automatically apply'}
                    />
                </Grid>

                <Grid item xs={6} className="form-grid">
                    <FormControlLabel
                        control={
                        <Checkbox
                            disabled={this.props.promoCodeForm.auto_apply == false}
                            checked={this.props.promoCodeForm.show_on_listing}
                            onChange={(e) => { this.props.dispatch(promoCodeFormUpdate({ prop: 'show_on_listing', value: !this.props.promoCodeForm.show_on_listing })); }}
                            name={'Show this on your stores listing'}
                            color="primary"
                            value={1}
                        />
                        }
                        label={'Show this on your stores listing'}
                    />
                </Grid>

                <PromoDaySelection
                    activeForm={this.props.promoCodeForm}
                    updateValidDays={promoCodeUpdateValidPromoDays}
                    dispatch={this.props.dispatch}
                />

                <Grid item xs={12} className="form-grid">
                    <Typography variant="h3" id="modal-title">
                        Quantity
                    </Typography>
                </Grid>

                <Grid item xs={6} className="form-grid">
                    <InputLabel className="input-label-mb"></InputLabel>
                    <Select
                        label="Max Uses"
                        fullWidth={true}
                        disabled={this.props.editing}
                        margin="none"
                        variant="outlined"
                        value={this.props.promoCodeForm.max_uses > 0 ? 1 : 0}
                        onChange={(e) => { this.props.dispatch(promoCodeFormUpdate({ prop: 'max_uses', value: e.target.value })); }}
                    >
                        <MenuItem key={'NO LIMIT'} value={0}>Unlimited</MenuItem>
                        <MenuItem key={'LIMITED QUANTITY'} value={1}>Limited Quantity</MenuItem>
                    </Select>
                </Grid>

                <Grid item xs={6} className="form-grid">
                    <TextField
                        label="Max Uses"
                        className="modal-input"
                        fullWidth={true}
                        // disabled={this.props.editing}
                        helperText={this.props.promoCodeForm.max_uses == 0 ? "0 is unlimited" : ''}
                        value={this.props.promoCodeForm.max_uses}
                        onChange={(e) => { this.props.dispatch(promoCodeFormUpdate({ prop: 'max_uses', value: e.target.value })); }}
                        margin="normal"
                        variant="outlined"
                    />
                </Grid>

                <Grid container>
                    <Grid item xs={6} className="form-grid-right">
                        <Button variant="contained" fullWidth={true} color="secondary" className="modal-button-bottom btn-secondary" onClick={this.props.handleCancelButton}>
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item xs={6} className="form-grid-left">
                        <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom" onClick={this.props.handleSubmitPromoCode}>
                            {this.props.handleSubmitLabel}
                        </Button>
                    </Grid>
                </Grid>  
            </Grid>
        );
    }
};

const mapStateToProps = (state, props) => {
    return {
        promoCodeForm: state.promoCodeForm,
        curUser: state.loggedUser.user
    };
};

export default connect(mapStateToProps)(PromoCodeForm);