import React from 'react';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

import LoadScreen from '../common/LoadScreen';
import verifyLogin from '../../utils/verifyLogin';
import { userManagementFormUpdate, searchUsersByEmail } from '../../actions/superuser/userManagementForm';
import { addUserFormUpdate } from '../../actions/superuser/addUserForm';
import { openUserManagementUpdate, closeUserManagementUpdate, openUserCreate, closeUserCreate } from '../../actions/modals';

import { addUserFormReset } from '../../actions/superuser/addUserForm';

import UpdateUser from './modal/UpdateUser';
import AddUser from './modal/AddUser';

import checkPermissions from '../../utils/checkUserPermissions';

class UserManagementPage extends React.Component {

    constructor(props) {
        super(props);

        this.toggleUpdateModal = this.toggleUpdateModal.bind(this);
        this.searchUsersByEmail = this.searchUsersByEmail.bind(this);
        this.checkPermissions = this.checkPermissions.bind(this);

        this.state = {
            sidebarMenuOpen: false,
            selectedUser: {},
            lastSearchedEmail: '',
        };
    }

    componentDidMount() {
        verifyLogin(this.props.history);
    }

    componentWillUnmount(){
        //clear out form so they don't revisit and see 'no user found'
        this.props.userManagementFormReset();
    }

    checkPermissions() {
        if (!checkPermissions(this.props.user, 'superuser')) {
            this.props.history.push('/permission-denied');
        }
    }

    toggleUpdateModal(isOpen, selectedUser) {
        if (isOpen) {

            this.setState(() => ({ selectedUser }));
            this.props.dispatch(userManagementFormUpdate({ prop: 'first_name', value: selectedUser.first_name }));
            this.props.dispatch(userManagementFormUpdate({ prop: 'last_name', value: selectedUser.last_name }));
            this.props.dispatch(userManagementFormUpdate({ prop: 'phone', value: selectedUser.phone }));
            this.props.dispatch(userManagementFormUpdate({ prop: 'superuser', value: (selectedUser.superuser) ? true : false }));
            this.props.dispatch(userManagementFormUpdate({ prop: 'support', value: (selectedUser.support) ? true : false }));

            this.props.dispatch(openUserManagementUpdate());
        }
        else {
            this.props.dispatch(closeUserManagementUpdate());
        }
    }

    toggleAddUserModal(isOpen) {
        if (isOpen) {
            this.props.dispatch(addUserFormReset());
            this.props.dispatch(addUserFormUpdate({ prop: 'email', value: this.state.lastSearchedEmail }));
            this.props.dispatch(openUserCreate());
        }
        else {
            this.props.dispatch(closeManagerAdd());
        }
    }

    searchUsersByEmail() {
        if (this.props.userManagementForm.emailSearch.trim() != "") {

            this.props.dispatch(searchUsersByEmail(this.props.userManagementForm.emailSearch));
            
            this.setState(() => ({ 
                lastSearchedEmail: this.props.userManagementForm.emailSearch,
            }));
        }
    }

    renderUsers() {

        if (this.props.loading) {
            return (
                <div className="page-loading">
                    <CircularProgress size={100} />
                </div>
            );
        }
        else {
            if(this.props.userManagementForm.userHasSearched == true && this.props.userManagementForm.users.length == 0){
                return <Grid container alignItems="center" >
                            <Grid item xs={3} key={"0"}>
                                {/* Dummy spacing column because offsets aren't supported? */}
                            </Grid>
                            <Grid item xs={6} className="restaurant-card-grid" key={"00"}>
                                <Card className="card restaurant-card">
                                    <CardActionArea onClick={() => {console.log('click it')}}>
                                        <CardContent>
                                            <Typography gutterBottom variant="h5" component="h2" justify="center">
                                                No user found for that email address.
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                    <CardActions>
                                        <Button variant="contained" color="primary" fullWidth={true} className="modal-button-bottom" onClick={() => this.toggleAddUserModal(true)}>
                                            Add A New User
                                        </Button>
                                    </CardActions>
                                </Card>
                            </Grid>
                        </Grid>;
            }else{
                return this.props.userManagementForm.users.map((user, index) => (
                    <Grid item xs={4} className="restaurant-card-grid" key={"user-"+user.id}>
                        <Card className="card restaurant-card">
                            <CardActionArea onClick={() => this.toggleUpdateModal(true, user)}>
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        {user.email}
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                            <CardActions>
                                <Button size="small" color="primary" onClick={() => this.toggleUpdateModal(true, user)}>
                                    Edit
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                ));
            }
        }
    }

    render() {
        return (
            <LoadScreen
                checkPermissions={this.checkPermissions}
            >
                <div className="card-container">
                    <Grid container>
                        <Grid item xs={12} className="heading-grid">
                            <h1>User Management</h1>

                            <div>
                                { this.props.userManagementForm.error && (
                                    <Typography className="general-error">
                                        { this.props.userManagementForm.error }
                                    </Typography>
                                )}

                                { this.props.userManagementForm.message && (
                                    <Typography className="general-success message-block">
                                        { this.props.userManagementForm.message }
                                    </Typography>
                                )}
                            </div>

                            <FormControl fullWidth>
                                <TextField 
                                    label="Email" 
                                    variant="outlined" 
                                    value={this.props.userManagementForm.emailSearch}
                                    onChange={(e) => { this.props.dispatch(userManagementFormUpdate({ prop: 'emailSearch', value: e.target.value })); }} 
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} className="heading-grid">
                            <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom" onClick={this.searchUsersByEmail}>
                                Search  
                            </Button>
                        </Grid>

                        {this.renderUsers()}
                    </Grid>
                </div>

                <UpdateUser
                    user={this.state.selectedUser}
                />

                <AddUser />
            </LoadScreen>
        );
    }
};

const mapStateToProps = (state, props) => {
    return {
        loading: state.userManagementForm.loading,
        user: state.loggedUser.user,
        userManagementForm: state.userManagementForm
    };
};

export default connect(mapStateToProps)(UserManagementPage);