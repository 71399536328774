// Get filtered locations
export const getFilteredLocations = (locations, text, selectedLocations) => {
    return locations.filter((location) => {

        // match the text search
        let textNameMatch           = location.restaurant.name.toLowerCase().includes(text.toLowerCase());
        let addressNameMatch        = false;
        let selectedLocationMatch   = false;

        if(!textNameMatch){
            addressNameMatch = location.address.toLowerCase().includes(text.toLowerCase());
        }

        if(selectedLocations){
            selectedLocationMatch = selectedLocations.some((selLocation) => {
                return location.id == selLocation;
            });
        }

        return textNameMatch || addressNameMatch || selectedLocationMatch;

    });
};