const sauceFormDefaultState = {
    name: '',
    description: '',
    prices: [],
    message: '',
    error: '',
};

export default (state = sauceFormDefaultState, action) => {
    
    switch (action.type) {

        case 'SAUCE_FORM_UPDATE':
            return {
                ...state,
                [action.sauce.prop]: action.sauce.value
            };

        case 'SAUCE_FORM_UPDATE_PRICE':
            return {
                ...state,
                prices: state.prices.map((item, index) => {
                    if (index == action.item.index) {
                        return {
                            ...item,
                            [action.item.prop]: action.item.value
                        }
                    }

                    return item;
                })
            };

        case 'SAUCE_FORM_RESET_PRICES':
            return {
                ...state,
                prices: [],
            }

        case 'SAUCE_FORM_ERROR':
            return {
                ...state,
                message: '',
                error: action.message
            }

        case 'SAUCE_FORM_SUCCESS':
            return {
                ...state,
                error: '',
                message: action.message
            }

        case 'SAUCE_FORM_SET':
            return {
                ...state,
                ...action.sauce
            };

        case 'SAUCE_FORM_RESET':
            return sauceFormDefaultState;

        default:
            return state;
    }
};