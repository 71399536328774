import React from 'react';
import { connect } from 'react-redux';
import arrayMove from 'array-move';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import Grid from '@material-ui/core/Grid';

import { foodItemFormUpdateAddon, foodItemFormAddAddon, foodItemFormRemoveAddon, foodItemFormUpdate } from '../../../../actions/menu/fooditems/foodItemForm';

import SortableItemList from '../../common/SortableItemList';

class Addons extends React.Component {

    constructor(props) {
        super(props);

        this.addAddon = this.addAddon.bind(this);
        this.deleteAddon = this.deleteAddon.bind(this);
        this.handleUpdateAddon = this.handleUpdateAddon.bind(this);
        this.onSortEnd = this.onSortEnd.bind(this);

        this.state = {
            priceDisplayView: 0
        };
    }

    componentDidMount() {
    }

    onSortEnd({oldIndex, newIndex})  {

        let newAddonOrder = arrayMove(this.props.foodItemForm.addons, oldIndex, newIndex);
        for (let i = 0; i < newAddonOrder.length; i++) {
            newAddonOrder[i]['price_order'] = i + 1;
        }

        this.props.dispatch(foodItemFormUpdate({ prop: 'addons', value: newAddonOrder }));
    }

    addAddon() {
        this.props.dispatch(foodItemFormAddAddon({
            name: '',
            price: ''
        }));
    }

    handleUpdateAddon(prop, index, value) {
        this.props.dispatch(foodItemFormUpdateAddon({ prop, index, value }));
    }

    deleteAddon(index) {
        this.props.dispatch(foodItemFormRemoveAddon(index));
    }

    render() {
        return (
            <div>
                <Typography variant="h3">
                    Addons
                </Typography>

                <Typography className="modal-description">
                    Enter your food item's available addons, or leave blank for no addons.
                </Typography>

                {this.props.foodItemForm.addons.length <= 0 && (
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography className="no-results">No addons entered.</Typography>
                        </Grid>
                    </Grid>
                )}

                {this.props.foodItemForm.addons.length > 0 && (
                    <SortableItemList
                        items={this.props.foodItemForm.addons}
                        handleUpdateItem={this.handleUpdateAddon}
                        handleDeleteItem={this.deleteAddon}
                        onSortEnd={this.onSortEnd}
                    />
                )}

                <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom" onClick={this.addAddon}>
                    <AddIcon />
                </Button>

                {this.props.children}
            </div>
        );
    }
};

const mapStateToProps = (state, props) => {
    return {
        foodItemForm: state.foodItemForm
    };
};

export default connect(mapStateToProps)(Addons);