import React from 'react';
import { connect } from 'react-redux';
import { loginFormReset } from '../actions/loginForm';
import { forgotPasswordFormUpdate, forgotPasswordFormError, sendForgotPasswordRequest, forgotPasswordFormReset } from '../actions/forgotPasswordForm';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Alert from '@material-ui/lab/Alert';
import logo from '../assets/images/logo.png';

import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

class ForgotPasswordPage extends React.Component {

    constructor(props) {
        super(props);   

        this.onSubmitForgot = this.onSubmitForgot.bind(this);
    }

    componentDidMount() {

        this.props.dispatch(forgotPasswordFormReset());
        // Grab login form email then reset it
        this.props.dispatch(forgotPasswordFormUpdate({prop: 'emailAddress', value: this.props.loginFormEmailAddress}));
        this.props.dispatch(loginFormReset());
    }


    onSubmitForgot(e) {
        e.preventDefault();

        if (!this.props.emailAddress) {
            this.props.dispatch(forgotPasswordFormError('Please enter your email'));
        }
        else {
            this.props.dispatch(forgotPasswordFormUpdate({prop: 'loading', value: true}));
            this.props.dispatch(sendForgotPasswordRequest(this.props.emailAddress));
        }

    }

    render() {

        if (this.props.loading) {
            return (
                <div className="page-loading">
                    <CircularProgress size={100} />
                </div>
            );
        }
        else {
            return (
                <div className="login-form">
                    <div className="paper">
                        <img src={logo} width="150px" />
    
                        <h2>Forgot Your Password?</h2>
    
                        <form className="form" onSubmit={this.onSubmitForgot}>
                            { this.props.error && <Alert className="form-error" severity="error">{this.props.error}</Alert> }
                            { this.props.message && (<Typography className="success-text">{ this.props.message }</Typography>) }
    
                            <FormControl margin="normal" required fullWidth>
                                <InputLabel htmlFor="email">Email</InputLabel>
                                <Input name="email" type="text" id="email" value={this.props.emailAddress} onChange={(e) => this.props.dispatch(forgotPasswordFormUpdate({ prop: 'emailAddress', value: e.target.value }))} />
                            </FormControl>
    
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                size="large"
                                className="submit submit-prim"
                            >
                                Send Password Reset Request
                            </Button>
                        </form>
                    </div>
                </div>
            );
        }
    }

};

const mapStateToProps = (state, props) => {
    return {
        loginFormEmailAddress: state.loginForm.emailAddress,
        emailAddress: state.forgotPasswordForm.emailAddress,
        error: state.forgotPasswordForm.error,
        message: state.forgotPasswordForm.message,
        loading: state.forgotPasswordForm.loading
    };
};

export default connect(mapStateToProps)(ForgotPasswordPage);