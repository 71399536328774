import React from 'react';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';

import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import LoadScreen from '../common/LoadScreen';
import verifyLogin from '../../utils/verifyLogin';
import { 
    loadLoyalty, 
    loyaltyFormError, 
    loyaltyFormUpdate,
    updateLoyaltySettings,
    updateLoyaltyReward
} from '../../actions/restaurant/loyalty';

import { 
    openLoyaltyRewardCreate, 
    closeLoyaltyRewardCreate,
    openLoyaltyRewardUpdate, 
    closeLoyaltyRewardUpdate,
    openLoyaltyPointsAdd,
    closeLoyaltyPointsAdd,
    openLoyaltyPointsUpdate,
    closeLoyaltyPointsUpdate
} from '../../actions/modals';
import {
    loyaltyRewardFormReset,
    loyaltyRewardFormUpdate
} from '../../actions/restaurant/loyaltyRewardForm';
import {
    loyaltyPointsFormReset,
    loyaltyPointsFormUpdate
} from '../../actions/restaurant/loyaltyPointsForm';
import { loadPageData } from '../../actions/auth';

import LoyaltyRewardsList from './common/LoyaltyRewardsList';
import LoyaltyUsersList from './common/LoyaltyUsersList';
import CreateLoyaltyReward from './modal/CreateLoyaltyReward';
import UpdateLoyaltyReward from './modal/UpdateLoyaltyReward';
import AddLoyaltyPoints from './modal/AddLoyaltyPoints';
import UpdateLoyaltyUser from './modal/UpdateLoyaltyUser';

import checkPermissions from '../../utils/checkUserPermissions';

class LoyaltyPage extends React.Component {

    constructor(props) {
        super(props);

        this.updateLoyaltySettings = this.updateLoyaltySettings.bind(this);
        this.toggleCreateRewardModal = this.toggleCreateRewardModal.bind(this);
        this.toggleUpdateRewardModal = this.toggleUpdateRewardModal.bind(this);
        this.toggleAddPointsModal = this.toggleAddPointsModal.bind(this);
        this.toggleUpdatePointsModal = this.toggleUpdatePointsModal.bind(this);
        this.deleteReward = this.deleteReward.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.checkPermissions = this.checkPermissions.bind(this);

        this.state = {
            sidebarMenuOpen: false,
            selectedLoyalty: {},
            displayView: 0
        };
    }

    componentDidMount() {
        verifyLogin(this.props.history);
    }

    checkPermissions() {
        if (checkPermissions(this.props.user, 'restaurant_admin', this.props.match.params.id) ||
            checkPermissions(this.props.user, 'support')) {
            
            // load the restaurant
            this.props.dispatch(loadPageData(this.props.match.params));
            this.props.dispatch(loadLoyalty(this.props.match.params.id));
        } else {
            this.props.history.push('/permission-denied');
        }
    }

    componentDidUpdate() {
        verifyLogin(this.props.history);
    }

    handleChange(event, newValue) {
        this.props.dispatch(loyaltyFormUpdate({ prop: 'error', value: '' }));
        this.props.dispatch(loyaltyFormUpdate({ prop: 'message', value: '' }));
        this.setState(() => ({displayView: newValue}))
    }

    toggleAddPointsModal(isOpen) {
        if (isOpen) {
            this.props.dispatch(openLoyaltyPointsAdd());
        }
        else {
            this.props.dispatch(closeLoyaltyPointsAdd());
        }
    }

    toggleCreateRewardModal(isOpen) {
        if (isOpen) {
            this.props.dispatch(loyaltyRewardFormReset());
            this.props.dispatch(openLoyaltyRewardCreate());
        }
        else {
            this.props.dispatch(closeLoyaltyRewardCreate());
        }
    }

    toggleUpdateRewardModal(isOpen, selectedReward) {
        if (isOpen) {
            this.setState(() => ({
                selectedReward
            }));

            this.props.dispatch(loyaltyRewardFormReset());
            this.props.dispatch(loyaltyRewardFormUpdate({ prop: 'name', value: selectedReward.name }));
            this.props.dispatch(loyaltyRewardFormUpdate({ prop: 'value', value: selectedReward.value }));
            this.props.dispatch(loyaltyRewardFormUpdate({ prop: 'points', value: selectedReward.points }));

            this.props.dispatch(openLoyaltyRewardUpdate());
        }
        else {
            this.props.dispatch(closeLoyaltyRewardUpdate());
        }
    }

    toggleUpdatePointsModal(isOpen, selectedUser) {
        if (isOpen) {
            this.setState(() => ({
                selectedUser
            }));

            this.props.dispatch(loyaltyPointsFormReset());
            this.props.dispatch(loyaltyPointsFormUpdate({ prop: 'id', value: selectedUser.user_id }));
            this.props.dispatch(loyaltyPointsFormUpdate({ prop: 'points_used', value: selectedUser.points_used }));
            this.props.dispatch(loyaltyPointsFormUpdate({ prop: 'points', value: selectedUser.points }));

            this.props.dispatch(openLoyaltyPointsUpdate());
        }
        else {
            this.props.dispatch(closeLoyaltyPointsUpdate());
        }
    }

    deleteReward(reward) {

        if (confirm("Are you sure you want to delete this loyalty reward? This can't be undone.")) {
            this.props.dispatch(updateLoyaltyReward(this.props.match.params.id, reward.id, 'remove', {}));
            
        }
    }

    updateLoyaltySettings() {

        if ((typeof this.props.loyalty.ratio === 'string' && !this.props.loyalty.ratio.match(/^\d+(\.\d+)?$/)) || parseFloat(this.props.loyalty.ratio) < 0) {
            this.props.dispatch(loyaltyFormError("Please enter a valid loyalty points ratio."));
        } else {
            this.props.dispatch(loyaltyFormError(""));
            this.props.dispatch(updateLoyaltySettings(this.props.match.params.id, this.props.loyalty));
        }
    }

    renderLoyaltyUsers() {

        if (this.props.loading) {
            return (
                <div className="page-loading">
                    <CircularProgress size={100} />
                </div>
            );
        }
        else {
            return (
                <Grid container>
                    <Grid item xs={12} alignContent={"center"} className="heading-grid">
                        <Typography variant="h1">
                            Loyalty Users and Points
                        </Typography>
                    </Grid>

                    <LoyaltyUsersList
                        users={this.props.loyalty.users}
                        toggleUpdateModal={this.toggleUpdatePointsModal}
                    />

                    <AddLoyaltyPoints
                        restaurantId={this.props.match.params.id}
                    />

                    <UpdateLoyaltyUser 
                        user={this.state.selectedUser}
                        restaurantId={this.props.match.params.id}
                    />

                    <div className="window-fab">
                        <Fab className="btn-fab btn-add" onClick={() => this.toggleAddPointsModal(true)}>
                            <AddIcon />
                        </Fab>
                    </div>
                </Grid>
            );
        }
    }

    renderLoyaltyRewards() {

        if (this.props.loading) {
            return (
                <div className="page-loading">
                    <CircularProgress size={100} />
                </div>
            );
        }
        else {
            return (
                <Grid container>
                    <Grid item xs={12} alignContent={"center"} className="heading-grid">
                        <Typography variant="h1">
                            Loyalty Rewards and Settings
                        </Typography>

                        <Typography variant="h2">
                            Points Ratio
                        </Typography>
                        
                        <Typography>This is how many points are awarded per dollar. For instance, if the ratio is set to be 3 and an order was made with subtotal of $40.00, the customer would be awarded with 120 points.</Typography>

                    </Grid>

                    <Grid item xs={12} alignContent={"center"} className="heading-grid">
                        { this.props.loyalty.error && (
                            <Typography className="error-text">
                                { this.props.loyalty.error }
                            </Typography>
                        )}

                        { this.props.loyalty.message && (
                            <Typography className="success-text">
                                { this.props.loyalty.message }
                            </Typography>
                        )}
                    </Grid>

                    <Grid item xs={6} className="heading-grid">
                        <TextField
                            label="Ratio"
                            className="modal-input"
                            fullWidth={true}
                            value={this.props.loyalty.ratio}
                            onChange={(e) => { this.props.dispatch(loyaltyFormUpdate({ prop: 'ratio', value: e.target.value })); }}
                            margin="normal"
                            variant="outlined"
                        />
                    </Grid>

                    <Grid item xs={6} className="heading-grid">
                        <Button variant="contained" fullWidth={true} size={'large'} color="primary" className="button-prim button-inline" onClick={this.updateLoyaltySettings}>
                            Update Ratio
                        </Button>
                    </Grid>

                    <LoyaltyRewardsList
                        rewards={this.props.loyalty.rewards}
                        toggleUpdateModal={this.toggleUpdateRewardModal}
                        handleDeleteReward={this.deleteReward}
                    />

                    <CreateLoyaltyReward
                        restaurantId={this.props.match.params.id}
                        ratio={this.props.loyalty.ratio}
                    />

                    <UpdateLoyaltyReward 
                        reward={this.state.selectedReward}
                        restaurantId={this.props.match.params.id}
                        ratio={this.props.loyalty.ratio}
                    />

                    <div className="window-fab">
                        <Fab className="btn-fab btn-add" onClick={() => this.toggleCreateRewardModal(true)}>
                            <AddIcon />
                        </Fab>
                    </div>
                </Grid>
            );
        }
    }

    renderActiveTab() {
        if (this.props.loading) {
            return (
                <div className="page-loading">
                    <CircularProgress size={100} />
                </div>
            );
        }
        else {
            switch(this.state.displayView) {
                case 0:
                    return this.renderLoyaltyRewards();

                case 1:
                    return this.renderLoyaltyUsers();
            }
        }
    }
    
    render() {
        return (
            <LoadScreen
                checkPermissions={this.checkPermissions}
            >
                <div className="form-container">
                    <Tabs
                        value={this.state.displayView}
                        onChange={this.handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        centered
                    >
                        <Tab label="Rewards Settings" />
                        <Tab label="Users and Points" />
                    </Tabs>

                    {this.renderActiveTab()}
                </div>
            </LoadScreen>
        );
    }
};

const mapStateToProps = (state, props) => {
    return {
        loading: state.loyalty.loading || state.restaurants.loading,
        restaurant: state.selectedRestaurant.restaurant,
        // rewards: filterLoyalty(state.loyalty.rewards, {text: state.loyalty.filter}),
        // users: filterLoyalty(state.loyalty.users, {text: state.loyalty.filter}),
        loyalty: state.loyalty,
        user: state.loggedUser.user,
    };
};

export default connect(mapStateToProps)(LoyaltyPage);