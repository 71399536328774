const dispatchCenterFormDefaultState = {
    name: '',
    timezone: 'America/Glace_Bay',
    type: 'ORDER',
    sms_update_enabled: false,
    phone: '',
    getswift_update_enabled: false,
    getswift_api_key: '',
    cartwheel_update_enabled: false,
    cartwheel_team_id: '',
    message: '',
    error: '',
    delivery_time: 15,
    delivery_hours: {
        sun_open: '11:00:00',
        sun_close: '21:00:00',
        mon_open: '11:00:00',
        mon_close: '21:00:00',
        tue_open: '11:00:00',
        tue_close: '21:00:00',
        wed_open: '11:00:00',
        wed_close: '21:00:00',
        thu_open: '11:00:00',
        thu_close: '21:00:00',
        fri_open: '11:00:00',
        fri_close: '21:00:00',
        sat_open: '11:00:00',
        sat_close: '21:00:00',
        sun_closed: false,
        mon_closed: false,
        tue_closed: false,
        wed_closed: false,
        thu_closed: false,
        fri_closed: false,
        sat_closed: false
    },
    open: 0
};

export default (state = dispatchCenterFormDefaultState, action) => {
    
    switch (action.type) {

        case 'DISPATCH_CENTER_FORM_UPDATE':
            return {
                ...state,
                [action.directory.prop]: action.directory.value
            };

        case 'DISPATCH_FORM_UPDATE_DELIVERY_HOURS':
            return {
                ...state,
                delivery_hours: 
                    {
                        ...state.delivery_hours,
                        [action.location.prop]: action.location.value
                    }
            };

        case 'DISPATCH_CENTER_FORM_ERROR':
            return {
                ...state,
                message: '',
                error: action.message
            }

        case 'DISPATCH_CENTER_FORM_SUCCESS':
            return {
                ...state,
                error: '',
                message: action.message
            }

        case 'DISPATCH_CENTER_FORM_RESET':
            return dispatchCenterFormDefaultState;

        default:
            return state;
    }
};