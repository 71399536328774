export const toppingFormUpdate = ({ prop, value }) => {
    return {
        type: 'TOPPING_FORM_UPDATE',
        topping: { prop, value }
    };
};

export const toppingFormUpdatePrice = ({ prop, index, value }) => {

    return {
        type: 'TOPPING_FORM_UPDATE_PRICE',
        item: { prop, index, value }
    };
};

export const toppingFormAddPrice = (price) => {

    return {
        type: 'TOPPING_FORM_ADD_PRICE',
        price
    }
};

export const toppingFormResetPrices = (index) => {

    return {
        type: 'TOPPING_FORM_RESET_PRICES'
    }
}

export const toppingFormError = (message) => {

    return {
        type: 'TOPPING_FORM_ERROR',
        message
    };
};

export const toppingFormSuccess = (message) => {

    return {
        type: 'TOPPING_FORM_SUCCESS',
        message
    };
};

export const toppingFormSet = (topping, index) => {
    
    return {
        type: 'TOPPING_FORM_SET',
        topping: {
            ...topping,
            index
        }
    };
}

export const toppingFormReset = () => {
    
    return {
        type: 'TOPPING_FORM_RESET',
    };
}

export const populateNewToppingFormSizes = (sizes) => {

    return (dispatch) => {

        let prices = [];
        for(let i = 0; i < sizes.length; i++) {
            prices.push({
                size_id: sizes[i].id,
                size_name: sizes[i].size_name,
                price: 0.00
            });
        }

        dispatch(toppingFormUpdate({
            prop: 'prices',
            value: prices
        }));
    };
}

export const populateUpdateToppingFormSizes = (topping) => {

    return (dispatch) => {

        let prices = [];
        for(let i = 0; i < topping.prices.length; i++) {
            prices.push({
                id: topping.prices[i].id,
                size_id: topping.prices[i].size_id,
                size_name: topping.prices[i].size_name,
                price: topping.prices[i].price
            });
        }

        dispatch(toppingFormUpdate({
            prop: 'prices',
            value: prices
        }));
    };
}