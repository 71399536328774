import axios from 'axios';
import { API_URL } from '../../../config/constants';
import { appDirectoryFormUpdate, appDirectoryFormReset, appDirectoryFormError, appDirectoryFormSuccess } from './appDirectoryForm';
import { closeAppDirectoryCreate } from '../../modals';
import { setTimezones } from '../../timezones';

export const setSelectedAppDirectory = (directory) => {
    return {
        type: 'SET_SELECTED_APP_DIRECTORY',
        directory
    }
}

export const setAppDirectories = (directories) => {
    return {
        type: 'SET_APP_DIRECTORIES',
        directories
    }
}

export const setAppDirectoryFilter = (text) => {
    return {
        type: 'SET_APP_DIRECTORY_FILTER',
        text
    }
}

export const resetAppDirectories = () => {
    return {
        type: 'RESET_APP_DIRECTORIES'
    }
}

export const setAppDirectoriesLoading = () => {
    return {
        type: 'SET_APP_DIRECTORIES_LOADING'
    }
}

export const loadAppDirectories = () => {
    return (dispatch) => {
        return axios.get(API_URL+'/api/directories')
            .then(function(res) {

                // load the directories
                dispatch(setAppDirectories(res.data.data.directories));

                // update the timezones list
                dispatch(setTimezones(res.data.data.timezones));

            })
            .catch(function(err) {
                console.log("Err!");
                console.log(err);
            });
    };
}

export const addAppDirectory = ({ name, timezone }) => {
    
    return (dispatch) => {

        dispatch(setAppDirectoriesLoading());

        return axios.post(API_URL+'/api/directories', { name, timezone })
            .then(function(res) {
                if (res.data.data.status == 'success') {

                    // set the app directories, reset the form, and close the modal
                    dispatch(setAppDirectories(res.data.data.directories));
                    dispatch(appDirectoryFormReset());
                    dispatch(closeAppDirectoryCreate());
                }
                else {
                    dispatch(appDirectoryFormError(res.data.message));
                }
            })
            .catch(function(err) {
                
                // token is likely invalid; remove it and prompt the user to login again
                console.log(err);
                dispatch(appDirectoryFormError("We're sorry, but our system has encountered an issue, to which we have been notified."));
                if (err.response.status === 401) {
                    removeAuthToken(dispatch);
                }
                
            });
    };
};

export const updateAppDirectory = (directoryId, scope, data) => {
    
    return (dispatch) => {

        dispatch(setAppDirectoriesLoading());

        return axios.post(API_URL+'/api/directories/'+directoryId, { 
            scope,
            data: data
        }).then(function(res) {
            if (res.data.data.status == 'success') {

                // set the app directories, reset the form, and close the modal
                dispatch(setAppDirectories(res.data.data.directories));
                dispatch(setSelectedAppDirectory(res.data.data.directory));
                dispatch(appDirectoryFormSuccess('Directory Updated!'));
            }
            else {
                dispatch(appDirectoryFormError(res.data.message));
            }
        })
        .catch(function(err) {
            console.log('err');
            console.log(err);

            // token is likely invalid; remove it and prompt the user to login again
            dispatch(appDirectoryFormError("We're sorry, but our system has encountered an issue, to which we have been notified."));
            if (err.response.status === 401) {
                removeAuthToken(dispatch);
            }
            
        });
    };
};

export const updateDirectoryLocationOrder = (directory_id, directoriesOrder) => {

    return (dispatch) => {

        dispatch(setAppDirectoriesLoading());

        // prepare the new order of items to move over
        let itemOrder = [];
        for (let i = 0; i < directoriesOrder.length; i++) {
            itemOrder.push(directoriesOrder[i].id);
        }

        // prepare the order of directories and IDs to send
        return axios.post(API_URL+'/api/directories/'+directory_id+'/updateOrder', { order: itemOrder })
            .then(function(res) {
                if (res.data.data.status == 'success') {

                    dispatch(setAppDirectories(res.data.data.directories));
                    dispatch(setSelectedAppDirectory(res.data.data.directory));
                    dispatch(appDirectoryFormSuccess('Order Updated!'));
                }
                else {
                    dispatch(appDirectoryFormError(res.data.message));
                    console.log(res.data);
                    console.log("Could not change your app directories order!");
                }
            })
            .catch(function(err) {

                // token is likely invalid; remove it and prompt the user to login again
                dispatch(appDirectoryFormError("We're sorry, but our system has encountered an issue, to which we have been notified."));
                if (err.response.status === 401) {
                    removeAuthToken(dispatch);
                }
                
            });
    };
};