import axios from 'axios';
import { API_URL } from '../../../config/constants';
import { specialFormReset, specialFormError, specialFormSuccess, specialFormUpdate } from './specialForm';
import { setSelectedRestaurantAvailabilitySchedules } from '../../selectedRestaurant';
import { closeSpecialCreate } from '../../modals';

export const setSpecials = (specials) => {
    return {
        type: 'SET_SPECIALS',
        specials
    }
}

export const setSelectableItems = (selectableItems) => {
    return {
        type: 'SET_SELECTABLE_ITEMS',
        selectableItems
    }
}

export const setSpecialFilter = (text) => {
    return {
        type: 'SET_SPECIAL_FILTER',
        text
    }
}

export const resetSpecials = () => {
    return {
        type: 'RESET_SPECIALS'
    }
}

export const setSpecialsLoading = () => ({
    type: 'SET_SPECIALS_LOADING'
});

export const loadSpecials = (restaurant_id) => {
    return (dispatch) => {
        return axios.get(API_URL+'/api/restaurants/'+restaurant_id+'/specials')
            .then(function(res) {
                dispatch(setSpecials(res.data.data.specials));
                dispatch(setSelectableItems(res.data.data.selectableItems));
            })
            .catch(function(err) {
                console.log("Err!");
                console.log(err);
            });
    };
}

export const addSpecial = (restaurantId, special) => {

    return (dispatch) => {

        const formData = new FormData();

        // add the image
        formData.append('files', special.newImage);

        dispatch(setSpecialsLoading());

        // add the data
        formData.append('data', JSON.stringify(special));

        return axios.post(
                API_URL+'/api/restaurants/'+restaurantId+'/specials', 
                formData,
                {
                    headers: { 'Content-Type': 'multipart/form-data' }
                }
            )
            .then(function(res) {
                if (res.data.status == 'success') {
                    dispatch(setSpecials(res.data.data.specials));
                    dispatch(setSelectedRestaurantAvailabilitySchedules(res.data.data.availability_schedules));
                    dispatch(specialFormReset());
                    dispatch(closeSpecialCreate());
                }
                else {
                    dispatch(specialFormError(res.data.message));
                }
            })
            .catch(function(err) {
                
                // token is likely invalid; remove it and prompt the user to login again
                dispatch(specialFormError("We're sorry, but our system has encountered an issue, to which we have been notified."));
                if (err.response.status === 401) {
                    removeAuthToken(dispatch);
                }
                
            });
    };
};

export const updateSpecial = (restaurantId, specialId, scope, special) => {
    
    return (dispatch) => {

        const formData = new FormData();

        // add the image
        formData.append('files', special.newImage);

        // add the data
        formData.append('scope', scope);
        formData.append('data', JSON.stringify(special));

        dispatch(setSpecialsLoading());

        return axios.post(
            API_URL+'/api/restaurants/'+restaurantId+'/specials/'+specialId, 
            formData,
            {
                headers: { 'Content-Type': 'multipart/form-data' }
            }
        ).then(function(res) {
            if (res.data.status == 'success') {
                dispatch(setSpecials(res.data.data.specials));
                dispatch(specialFormReset());
                dispatch(specialFormUpdate({ prop: 'name', value: res.data.data.special.name }));
                dispatch(specialFormUpdate({ prop: 'description', value: res.data.data.special.description }));
                dispatch(specialFormUpdate({ prop: 'thumb_image', value: res.data.data.special.thumb_image }));
                dispatch(specialFormUpdate({ prop: 'non_taxable', value: res.data.data.special.non_taxable }));
                dispatch(specialFormUpdate({ prop: 'price', value: res.data.data.special.price }));
                dispatch(specialFormUpdate({ prop: 'items', value: res.data.data.special.items }));                
                if(res.data.data.special.hours_available != null){
                    dispatch(specialFormUpdate({ prop: 'hours_available', value: res.data.data.special.hours_available }));
                }
                dispatch(specialFormUpdate({ prop: 'alwaysAvailable', value: res.data.data.special.alwaysAvailable }));
                dispatch(specialFormUpdate({ prop: 'saveSchedule', value: false }));
                dispatch(specialFormUpdate({ prop: 'scheduleName', value: '' }));
                dispatch(setSelectedRestaurantAvailabilitySchedules(res.data.data.availability_schedules));
                dispatch(specialFormSuccess('Special Updated!'));

                window.scrollTo(0, special.scrollPosition);

            }
            else {
                dispatch(specialFormError(res.data.message));
                console.log(res.data);
                console.log("Could not update your special!");
            }
        })
        .catch(function(err) {
            console.log('err');
            console.log(err);
            // token is likely invalid; remove it and prompt the user to login again
            dispatch(specialFormError("We're sorry, but our system has encountered an issue, to which we have been notified."));
            if (err.response && err.response.status === 401) {
                removeAuthToken(dispatch);
            }
            
        });
    };
};

export const updateSpecialOrder = (restaurant_id, specials) => {

    return (dispatch) => {

        dispatch(setSpecialsLoading());

        // prepare the new order of items to move over
        let itemOrder = [];
        for (let i = 0; i < specials.length; i++) {
            itemOrder.push(specials[i].id);
        }

        // prepare the order of specials and IDs to send
        return axios.post(API_URL+'/api/restaurants/'+restaurant_id+'/specials/order', { order: itemOrder })
            .then(function(res) {
                if (res.data.status == 'success') {
                    dispatch(setSpecials(res.data.data.specials));
                    dispatch(specialFormSuccess());
                }
                else {
                    dispatch(specialFormError(res.data.message));
                    console.log(res.data);
                    console.log("Could not update special order!");
                }
            })
            .catch(function(err) {

                // token is likely invalid; remove it and prompt the user to login again
                dispatch(specialFormError("We're sorry, but our system has encountered an issue, to which we have been notified."));
                if (err.response.status === 401) {
                    removeAuthToken(dispatch);
                }
                
            });
    };
};

export const toggleSpecialEnabled = (restaurant_id, special_id, enabled) => {
    
    return (dispatch) => {

        dispatch(setSpecialsLoading());
        return axios.post(API_URL+'/api/restaurants/'+restaurant_id+'/specials/'+special_id, { 
                scope: 'enable',
                enabled 
            })
            .then(function(res) {
                if (res.data.status == 'success') {
                    dispatch(setSpecials(res.data.data.specials));
                }
                else {
                    dispatch(specialFormError(res.data.message));
                    console.log(res.data);
                    console.log("Could not add your special!");
                }
            })
            .catch(function(err) {

                // token is likely invalid; remove it and prompt the user to login again
                dispatch(specialFormError("We're sorry, but our system has encountered an issue, to which we have been notified."));
                if (err.response.status === 401) {
                    removeAuthToken(dispatch);
                }
                
            });
    };
};

export const removeSpecial = (special_id, restaurant_id) => {

    return (dispatch) => {

        dispatch(setSpecialsLoading());
        return axios.delete(API_URL+'/api/restaurants/'+restaurant_id+'/specials/'+special_id, {})
            .then(function(res) {
                if (res.data.status == 'success') {
                    dispatch(setSpecials(res.data.data.specials));
                }
                else {
                    // @TODO: need better messaging here
                    console.log(res.data);
                    console.log("Could not remove your special!");
                }
            })
            .catch(function(err) {

                // token is likely invalid; remove it and prompt the user to login again
                if (err.response.status === 401) {
                    removeAuthToken(dispatch);
                }
                
            });
    };
}