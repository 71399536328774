import React from 'react';
import { connect } from 'react-redux';
import arrayMove from 'array-move';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import AddIcon from '@material-ui/icons/Add';

import { choiceFormUpdate, choiceFormUpdateItem, choiceFormAddItem, choiceFormRemoveItem } from '../../../../actions/menu/fooditems/choiceForm';
import filterFoodItemsWithChoices from '../../../../utils/filterFoodItemsWithChoices';

import SortableItemList from '../../common/SortableItemList';

class ChoiceForm extends React.Component {

    constructor(props) {
        super(props);
        this.setSelectedFoodItem = this.setSelectedFoodItem.bind(this);
        this.updateNewChoice = this.updateNewChoice.bind(this);
        this.addItem = this.addItem.bind(this);
        this.onSortEnd = this.onSortEnd.bind(this);
        this.deleteItem = this.deleteItem.bind(this);
        this.handleUpdateItem = this.handleUpdateItem.bind(this);

        this.state = {
            selectedFoodItemChoices: [],
            selectedFoodItemId: 0,
            selectedChoiceId: 0
        }
    }

    componentDidMount() {
    }

    addItem() {
        this.props.dispatch(choiceFormAddItem({
            name: '',
            price: ''
        }));
    }

    deleteItem(index) {
        this.props.dispatch(choiceFormRemoveItem(index));
    }

    handleUpdateItem(prop, index, value) {
        this.props.dispatch(choiceFormUpdateItem({ prop, index, value }));
    }

    onSortEnd({oldIndex, newIndex})  {

        let newSelectionOrder = arrayMove(this.props.choiceForm.selections, oldIndex, newIndex);
        for (let i = 0; i < newSelectionOrder.length; i++) {
            newSelectionOrder[i]['item_order'] = i + 1;
        }

        this.props.dispatch(choiceFormUpdate({ prop: 'selections', value: newSelectionOrder }));
    }

    updateNewChoice() {
        // get the choice to update
        const choices = this.state.selectedFoodItemChoices.filter((choice) => {
            return choice.id == this.state.selectedChoiceId;
        });

        // update the general info
        this.props.dispatch(choiceFormUpdate({ prop: 'name', value: choices[0].name }));
        this.props.dispatch(choiceFormUpdate({ prop: 'is_multiple', value: (choices[0].is_multiple) ? true : false }));
        this.props.dispatch(choiceFormUpdate({ prop: 'max_choices', value: (choices[0].max_choices) ? choices[0].max_choices.toString() : '' }));

        // reset the choice items
        this.props.dispatch(choiceFormUpdate({ prop: 'selections', value: [] }));

        // add the choice items
        for (let i = 0; i < choices[0].selections.length; i++) {
            this.props.dispatch(choiceFormAddItem({
                name: choices[0].selections[i].name,
                price: choices[0].selections[i].price
            }));
        }
    }

    setSelectedFoodItem(foodItemId) {

        // filter out the item we need
        const foodItems = this.props.foodItems.filter((foodItem) => {
            return foodItem.id == foodItemId;
        });

        this.setState(() => ({ 
            selectedFoodItemId: foodItemId,
            selectedFoodItemChoices: foodItems[0].choices
        }));
    }

    render() {

        return (
            <Grid container>
                { this.props.choiceForm.error && (
                    <Grid item xs={12} className="form-grid">
                        <Typography className="modal-error">
                            { this.props.choiceForm.error }
                        </Typography>
                    </Grid>
                )}

                
                <div className="previous-choice-window">
                    <Typography variant="h4">
                        Use a previous choice
                    </Typography>

                    <Typography className="modal-description">
                        If you want to duplicate a choice that you already entered for another food item, please choose the food item and choice that you want to duplicate.
                    </Typography>

                    <Grid container>
                        <Grid item xs={4} className="form-grid">
                            <InputLabel>Food Item</InputLabel>
                            <Select
                                fullWidth={true}
                                labelId="type"
                                value={this.state.selectedFoodItemId}
                                onChange={(e) => { this.setSelectedFoodItem(e.target.value); }}
                            >
                                {this.props.foodItems.map((foodItem) => (
                                    <MenuItem key={foodItem.id} value={foodItem.id}>{foodItem.name}</MenuItem>
                                ))}
                            </Select>
                        </Grid>

                        {this.state.selectedFoodItemChoices.length > 0 && (
                            <Grid item xs={4} className="form-grid">
                                <InputLabel>Choice</InputLabel>
                                <Select
                                    fullWidth={true}
                                    labelId="type"
                                    value={this.state.selectedChoiceId}
                                    onChange={(e) => { this.setState(() => ({ selectedChoiceId: e.target.value })); }}
                                >
                                    {this.state.selectedFoodItemChoices.map((choice) => (
                                        <MenuItem key={choice.id} value={choice.id}>{choice.name}</MenuItem>
                                    ))}
                                </Select>
                            </Grid>
                        )}

                        {this.state.selectedChoiceId != 0 && (
                            <Grid item xs={4} className="form-grid">
                                <Button 
                                    variant="contained" 
                                    fullWidth={true} 
                                    color="primary" 
                                    className="modal-button-list" 
                                    onClick={this.updateNewChoice}
                                >
                                    Fill Fields
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                </div>

                <Grid item xs={12} className="form-grid">
                    <TextField
                        label="Name"
                        className="modal-input"
                        fullWidth={true}
                        value={this.props.choiceForm.name}
                        onChange={(e) => { this.props.dispatch(choiceFormUpdate({ prop: 'name', value: e.target.value })); }}
                        margin="normal"
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={6} className="form-grid">
                    <FormGroup row>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={this.props.choiceForm.is_multiple}
                                    onChange={(e) => { this.props.dispatch(choiceFormUpdate({ prop: 'is_multiple', value: !this.props.choiceForm.is_multiple })); }}
                                    name="choice_multiple"
                                    color="primary"
                                />
                            }
                            label="Multiple Choices Available"
                            labelPlacement="start"
                            className="switch-window"
                        />
                    </FormGroup>
                </Grid>
                <Grid item xs={6} className="form-grid">
                    <TextField
                        disabled={!this.props.choiceForm.is_multiple}
                        label="Maximum Selected Choices"
                        className="modal-input"
                        fullWidth={true}
                        value={this.props.choiceForm.max_choices}
                        onChange={(e) => { this.props.dispatch(choiceFormUpdate({ prop: 'max_choices', value: e.target.value })); }}
                        margin="normal"
                        variant="outlined"
                    />
                </Grid>

                <Grid item xs={12} className="form-grid">
                    <Typography variant="h3">
                        Choice Items
                    </Typography>
                </Grid>

                {this.props.choiceForm.selections.length <= 0 && (
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography className="no-results">No selections entered.</Typography>
                        </Grid>
                    </Grid>
                )}

                {this.props.choiceForm.selections.length > 0 && (
                    <Grid item xs={12} className="form-grid">
                        <SortableItemList
                            items={this.props.choiceForm.selections}
                            handleUpdateItem={this.handleUpdateItem}
                            handleDeleteItem={this.deleteItem}
                            onSortEnd={this.onSortEnd}
                        />
                    </Grid>
                )}

                <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom" onClick={this.addItem}>
                    <AddIcon />
                </Button>

                <Grid container>
                    <Grid item xs={6} className="form-grid-right">
                        <Button variant="contained" fullWidth={true} color="secondary" className="modal-button-bottom btn-secondary" onClick={this.props.handleCancelButton}>
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item xs={6} className="form-grid-left">
                        <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom" onClick={this.props.handleSubmitChoice}>
                            {this.props.handleSubmitLabel}
                        </Button>
                    </Grid>
                </Grid>   
            </Grid>
        );
    }
};

const mapStateToProps = (state, props) => {
    return {
        choiceForm: state.choiceForm,
        foodItems: filterFoodItemsWithChoices(state.foodItems.allFoodItems)
    };
};

export default connect(mapStateToProps)(ChoiceForm);