import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { logout } from '../../actions/auth';
import { openLeftSidebar } from '../../actions/sidebar';
import logo from '../../assets/images/c2o_logo.png';
import Typography from '@material-ui/core/Typography';

import checkPermissions from '../../utils/checkUserPermissions';

class Header extends React.Component {

    constructor(props) {
        super(props);

        this.onClickLogout = this.onClickLogout.bind(this);
        this.openLeftSidebar = this.openLeftSidebar.bind(this);
    }

    openLeftSidebar() {
        this.props.dispatch(openLeftSidebar());
    }

    onClickLogout() {
        this.props.dispatch(logout(this.props.history));
    }

    render() {

        let restaurantIDReg = RegExp(/^\/([^\/]*).*$/);
        let foundRestaurantID = (restaurantIDReg.test(this.props.location.pathname) && restaurantIDReg.exec(this.props.location.pathname)[1]) ? restaurantIDReg.exec(this.props.location.pathname)[1] : null;
        // Only show restaurant name if we're at an address like /7/dashboard or /7/pizzas

        return (
            <div>
                <AppBar position="static" className="header">
                    {(this.props.location.pathname !== "/login" && this.props.location.pathname !== "/login/") && (
                        <Toolbar>
                            <IconButton className="menu-button" aria-label="Menu" onClick={this.openLeftSidebar}>
                                <MenuIcon />
                            </IconButton>
                            <div className="grow">
                                <a href="/"><img className="header-logo" src={logo} /></a>
                            </div>
                            <div className="header-restaurant">
                                {this.props.restaurant && (foundRestaurantID == this.props.restaurant.id) && (
                                    <span className="header-restaurant-text">{this.props.restaurant.name}</span>
                                )}
                                
                            </div>
                            {this.props.ordersLastUpdatedVisible && (
                                    <Typography className="header-time">Last updated: {this.props.ordersLastUpdated}</Typography>
                            )}
                            {checkPermissions(this.props.user, 'support') && (
                                    <Button onClick={()=>{ window.location.href = "https://www.click2order.ca/internal-support-form" }} >Support Form</Button>
                                )
                            }
                            <Button onClick={this.onClickLogout}>Logout</Button>
                        </Toolbar>
                    )}
                    {(this.props.location.pathname === "/login" || this.props.location.pathname === "/login/") && (
                        <Toolbar />
                    )}
                </AppBar>
            </div>
        );
    }
}

const mapStateToProps = (state, props) => {
    return {
        organization: 1,
        restaurant: state.selectedRestaurant.restaurant,
        user: state.loggedUser.user,
        ordersLastUpdatedVisible: state.orders.ordersLastUpdatedVisible,
        ordersLastUpdated: state.orders.ordersLastUpdated
    };
};

export default withRouter(connect(mapStateToProps)(Header));