import React from 'react';
import { connect } from 'react-redux';
import { loginFormUpdate, loginFormError } from '../actions/loginForm';
import { login } from '../actions/auth';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Alert from '@material-ui/lab/Alert';
import logo from '../assets/images/logo.png';

import { getQueryParameters } from '../utils/routeUtil';

class LoginPage extends React.Component {

    constructor(props) {
        super(props);   

        this.onSubmitLogin = this.onSubmitLogin.bind(this);
        this.forgotPassword = this.forgotPassword.bind(this);

        this.state = {
            redirect: null
        }
    }

    componentDidMount() {

        const queryParams = getQueryParameters(this.props.location.search);
        if (queryParams.redirect) {
            this.setState(() => ({redirect: queryParams.redirect}));
        }

        if (localStorage.getItem('c2o.token')) {
            this.props.history.push('/');
        }
    }


    onSubmitLogin(e) {
        e.preventDefault();

        if (!this.props.emailAddress || !this.props.password) {
            this.props.dispatch(loginFormError('Please enter all login info before proceeding.'));
        }
        else {
            this.props.dispatch(login(this.props.emailAddress, this.props.password, this.props.remember_me, this.props.history));
        }
    }

    forgotPassword(e) {
        e.preventDefault();

        this.props.history.push('/forgotPassword');
    }

    render() {
        return (
            <div className="login-form">
                <div className="paper">
                    <img src={logo} />

                    <form className="form" onSubmit={this.onSubmitLogin}>
                        { this.props.error && <Alert className="form-error" severity="error">{this.props.error}</Alert> }
                        { this.props.message && <Alert className="form-error" severity="success">{this.props.message}</Alert> }

                        {(this.state.redirect) && <input type="hidden" name="redirect" value={this.state.redirect} />}
                        <FormControl margin="normal" required fullWidth>
                            <InputLabel htmlFor="email">Email Address</InputLabel>
                            <Input id="email" name="email" autoComplete="email" value={this.props.emailAddress} onChange={(e) => this.props.dispatch(loginFormUpdate({ prop: 'emailAddress', value: e.target.value }))} autoFocus />
                        </FormControl>
                        <FormControl margin="normal" required fullWidth>
                            <InputLabel htmlFor="password">Password</InputLabel>
                            <Input name="password" type="password" id="password" autoComplete="current-password" value={this.props.password} onChange={(e) => this.props.dispatch(loginFormUpdate({ prop: 'password', value: e.target.value }))} />
                        </FormControl>
                        <FormControlLabel
                            control={
                                <Checkbox 
                                    checked={this.props.remember_me}
                                    onChange={(e) => this.props.dispatch(loginFormUpdate({ prop: 'remember_me', value: !this.props.remember_me }))}
                                    name={"Remember"}
                                    color="primary"
                                />
                            }
                            label="Remember me"
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            size="large"
                            className="submit submit-prim"
                        >
                            Sign in
                        </Button>
                    </form>

                    <Button
                        type="button"
                        fullWidth
                        color="primary"
                        size="large"
                        className="forgot-password"
                        onClick={this.forgotPassword}
                    >
                        Forgot Your Password?
                    </Button>
                </div>
            </div>
        );
    }

};

const mapStateToProps = (state, props) => {
    return {
        emailAddress: state.loginForm.emailAddress,
        password: state.loginForm.password,
        remember_me: state.loginForm.remember_me,
        error: state.loginForm.error,
        message: state.loginForm.message,
    };
};

export default connect(mapStateToProps)(LoginPage);