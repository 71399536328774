import React from 'react';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

class ManagersList extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    render() {
        return (
            <Grid container>
                <Grid item xs={12} className="sortable-list">
                {this.props.managers.map((manager, index) => (
                    <div key={"managerlist-"+index}>
                        <Grid key={"item-"+index} className="sortable-menu-item" container>
                            <Grid item xs={10} className="list-name">
                                <Typography>{manager.email} - {(manager.type == 'admin') ? 'Superuser' : 'Manager'}</Typography>
                            </Grid>
                            <Grid item xs={2} className="list-sort">
                                <Button fullWidth={true} color="secondary" className="modal-button-list" onClick={() => this.props.revokeAccess(manager)}>
                                    Revoke Access
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                ))}
                </Grid>
            </Grid>
        );
    }
};

export default connect()(ManagersList);