import React from 'react';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

import SortableContainer from '../../../common/sort/SortableContainer';
import SortableHandle from '../../../common/sort/SortableHandle';
import SortableItem from '../../../common/sort/SortableItem';

class SortableFoodItemModifierItem extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    render() {
        return (
            <SortableContainer helperClass='sortableHelper'>
                <Grid container>
                    <Grid item xs={12} className="sortable-choice-list">
                        {this.props.items.map((item, index) => (
                            <SortableItem key={"settingssize-"+index} index={index}>
                                <Grid key={"item-"+index} className={(item.enabled) ? "sortable-menu-item" : "sortable-menu-item disabled-item"} container>
                                    <Grid item xs={5} className="form-grid">
                                        <TextField
                                            label="Name"
                                            className="modal-input"
                                            fullWidth={true}
                                            value={item.name}
                                            margin="normal"
                                            variant="outlined"
                                            disabled={true}
                                        />
                                    </Grid>
                                    {item.prices.map((price, priceIndex) => (
                                        <Grid key={"priceIndex-"+priceIndex} item xs={1} className="form-grid">
                                            <TextField
                                                className="modal-input"
                                                fullWidth={true}
                                                value={price.price}
                                                margin="normal"
                                                variant="outlined"
                                                disabled={true}
                                            />
                                        </Grid>
                                    ))}
                                    <Grid item xs={1} className="list-name red-text">
                                        <Typography>{(item.enabled) ? "" : "Disabled"}</Typography>
                                    </Grid>
                                </Grid>
                            </SortableItem>
                        ))}
                    </Grid>
                </Grid>
            </SortableContainer>
        );
    }
};

export default connect()(SortableFoodItemModifierItem);