import React from 'react';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';

import validateCurrency from '../../../../utils/validateCurrency';
import { addModifier, updateModifier } from '../../../../actions/menu/modifiers/modifiers';
import { modifierFormError } from '../../../../actions/menu/modifiers/modifierForm';
import { closeModifierModal } from '../../../../actions/modals';

import CloseIcon from '../../../common/modal/CloseIcon';

import ModifierInfo from './ModifierInfo';

class ModifierModal extends React.Component {

    constructor(props) {
        super(props);

        this.closeModal = this.closeModal.bind(this);
        this.createModifier = this.createModifier.bind(this);
        this.updateModifier = this.updateModifier.bind(this);

    }

    closeModal() {
        this.props.dispatch(closeModifierModal());
    }

    createModifier() {

        // perform some error checking
        if (this.props.modifierForm.name.trim() == "") {
            this.props.dispatch(modifierFormError("Please enter a modifier name."));
        }else{
            //this.props.dispatch(modifierFormError(""));
            this.props.dispatch(addModifier(this.props.restaurantId, this.props.modifierForm));
        }

    }

    updateModifier(){
        this.props.dispatch(updateModifier(this.props.restaurantId, this.props.modifierForm));
    }


    render() {
        return (
            <Modal
                aria-labelledby="modifier-add"
                aria-describedby="modifier-add"
                open={this.props.modalOpen}
                onClose={() => this.closeModal()}
                className="modal_admin w-90"
                disableBackdropClick
            >
                <div className="modal-member">
                    <Typography variant="h4" id="modal-title">
                        Add a New Modifier
                        <CloseIcon/>
                    </Typography>

                    {this.props.loading && (
                        <div className="page-loading">
                            <CircularProgress size={100} />
                        </div>
                    )}
        
                    {!this.props.loading && (
                        <div>
                            { this.props.modifierForm.error && (
                                <Typography className="modal-error">
                                    { this.props.modifierForm.error }
                                </Typography>
                            )}
                            { this.props.modifierForm.message && (
                                <Typography className="modal-success">
                                    { this.props.modifierForm.message }
                                </Typography>
                            )}
                            <ModifierInfo />
                            
                            {this.props.modifierForm.id == 0 &&
                                <Button variant="contained" fullWidth={true} className="modal-button-bottom" onClick={this.createModifier}>
                                    Create
                                </Button>
                            }
                            {this.props.modifierForm.id != 0 &&
                                <Button variant="contained" fullWidth={true} className="modal-button-bottom" onClick={this.updateModifier}>
                                    Update
                                </Button>
                            }
                        </div>
                    )}
                </div>
            </Modal>
        );
    }
};

const mapStateToProps = (state, props) => {
    return {
        loading: state.modifiers.loading,
        restaurant: state.selectedRestaurant.restaurant,
        modifierForm: state.modifierForm,
        modalOpen: state.modals.modifierModal
    };
};

export default connect(mapStateToProps)(ModifierModal);