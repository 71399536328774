export const setSelectedOrder = (orderId, order, orderInformation) => ({
    type: 'SET_SELECTED_ORDER',
    orderId,
    order,
    orderInformation
});

export const selectedOrderReceiptError = (message) => {

    return {
        type: 'SELECTED_ORDER_RECEIPT_ERROR',
        message
    };
};

export const selectedOrderReceiptMessage = (message) => {

    return {
        type: 'SELECTED_ORDER_RECEIPT_MESSAGE',
        message
    };
};

export const setSelectedOrderReceiptLoading = () => {

    return {
        type: 'SET_SELECTED_ORDER_RECEIPT_LOADING',
    };
};

export const removeSelectedOrderReceiptLoading = () => {

    return {
        type: 'REMOVE_SELECTED_ORDER_RECEIPT_LOADING',
    };
};

export const resetSelectedOrder = () => ({
    type: 'RESET_SELECTED_ORDER'
});