import React from 'react';
import { connect } from 'react-redux';
import arrayMove from 'array-move';

import Typography from '@material-ui/core/Typography';

import ItemsList from './ItemsList';
import ItemForm from './ItemForm';

import { itemFormError, itemFormReset } from '../../../../actions/menu/specials/itemForm';
import { specialFormAddItem, specialFormRemoveItem, specialFormUpdate } from '../../../../actions/menu/specials/specialForm';

class Items extends React.Component {

    constructor(props) {
        super(props);
        
        this.handleAddItemView = this.handleAddItemView.bind(this);
        this.handleAddItem = this.handleAddItem.bind(this);
        this.handleCancelButton = this.handleCancelButton.bind(this);
        this.handleRemoveItem = this.handleRemoveItem.bind(this);
        this.onSortEnd = this.onSortEnd.bind(this);

        this.state = {
            itemDisplayView: 'list'
        };
    }

    componentDidMount() {
    }

    handleAddItem(item) {

        let valid = true;

        if (item.items.length <= 0 && valid) {
            valid = false;
            this.props.dispatch(itemFormError("Please enter at least one item to select."));
        }

        if (valid) {
            this.props.dispatch(itemFormError(""));
            
            // add the item
            this.props.dispatch(specialFormAddItem(item));

            // reset the form and return to the main list view
            this.props.dispatch(itemFormReset());
            this.setState(() => ({ itemDisplayView: 'list' }));
        }
    }

    handleRemoveItem(index) {
        if (confirm("Remove this item?")) {
            this.props.dispatch(specialFormRemoveItem(index));
        }
    }

    handleAddItemView() {
        this.props.dispatch(itemFormReset());
        this.setState(() => ({ itemDisplayView: 'new_item' }));
    }

    handleCancelButton() {
        this.setState(() => ({ itemDisplayView: 'list' }));
    }

    onSortEnd({oldIndex, newIndex})  {

        let newItemOrder = arrayMove(this.props.specialForm.items, oldIndex, newIndex);
        for (let i = 0; i < newItemOrder.length; i++) {
            newItemOrder[i]['item_order'] = i + 1;
        }

        this.props.dispatch(specialFormUpdate({ prop: 'items', value: newItemOrder }));
    }

    renderItemView() {
        switch (this.state.itemDisplayView) {

            case 'new_item':
                return (
                    <div>
                        <Typography variant="h5" id="modal-title">
                            Add a new Item
                        </Typography>
                        
                        <Typography className="modal-description">
                            Please choose your item or item choices, and click 'Add Item'.
                        </Typography>
                        
                        <ItemForm 
                            handleAddItem={this.handleAddItem}
                            handleCancelButton={this.handleCancelButton}
                            handleSubmitItem={this.handleAddItem}
                            handleSubmitLabel={'Add Item'}
                            handleGoBack={this.handleCancelButton}
                        />
                    </div>
                );

            case 'list':
            default:
                return (
                    <ItemsList 
                        handleAddItemView={this.handleAddItemView}
                        handleRemoveItem={this.handleRemoveItem}
                        onSortEnd={this.onSortEnd}
                        checkAddSpecialItems={this.props.checkAddSpecialItems}
                    />
                );
        }
    }

    render() {
        return (
            <div>
                {this.renderItemView()}
                {this.state.itemDisplayView == 'list' && this.props.children}
            </div>
        );
    }
};

const mapStateToProps = (state, props) => {
    return {
        specialForm: state.specialForm,
        itemForm: state.itemForm
    };
};

export default connect(mapStateToProps)(Items);