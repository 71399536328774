import moment from 'moment';

const locationFormDefaultState = {
    address: '',
    address2: '',
    city: '',
    province: 8,
    postal: '',
    phone: '',
    phone2: '',
    lat: 0.00,
    lng: 0.00,
    add_taxes: false,
    ignore_sms_warning: false,
    preorder_enabled: false,
    warning_phone: '',
    printer_template: 0,
    delivery_type: 'none',
    pickup_options: 'BOTH',
    message: '',
    delivery_charge: 0.00,
    max_delivery_range: 0,
    promo_code_enable: false,
    payment_type: 'ONLINE',
    hoursOfOperation: {
        sun_open: '00:00:00',
        sun_close: '00:00:00',
        mon_open: '00:00:00',
        mon_close: '00:00:00',
        tue_open: '00:00:00',
        tue_close: '00:00:00',
        wed_open: '00:00:00',
        wed_close: '00:00:00',
        thu_open: '00:00:00',
        thu_close: '00:00:00',
        fri_open: '00:00:00',
        fri_close: '00:00:00',
        sat_open: '00:00:00',
        sat_close: '00:00:00',
        automatic_close: true,
        automatic_close_minutes: 0,
        sun_closed: false,
        mon_closed: false,
        tue_closed: false,
        wed_closed: false,
        thu_closed: false,
        fri_closed: false,
        sat_closed: false
    },
    deliveryHours: {
        sun_open: '00:00:00',
        sun_close: '00:00:00',
        mon_open: '00:00:00',
        mon_close: '00:00:00',
        tue_open: '00:00:00',
        tue_close: '00:00:00',
        wed_open: '00:00:00',
        wed_close: '00:00:00',
        thu_open: '00:00:00',
        thu_close: '00:00:00',
        fri_open: '00:00:00',
        fri_close: '00:00:00',
        sat_open: '00:00:00',
        sat_close: '00:00:00',
        sun_closed: false,
        mon_closed: false,
        tue_closed: false,
        wed_closed: false,
        thu_closed: false,
        fri_closed: false,
        sat_closed: false
    },
    holidayHours: [],
    holidayHoursNewStartDate: '',
    holidayHoursNewEndDate: '',
    holidayHoursNewOpenTime: moment().set({hour: 8, minute: 0}), // Mirror below for reset
    holidayHoursNewCloseTime: moment().set({hour: 22, minute: 0}),
    holidayHoursNewClosedAllDay: 0,
    holidayHoursSelectedID: 0,
    showNewHolidayHours: false,
    deliveryCharges: [
        {
            max_distance: 0,
            min_total: 0,
            price: 0
        }
    ],
    deletedDeliveryCharges: [],
    promoCodes: [],
    deletedPromoCodes: [],
    error: '',
    holidayError: '',
    activeTab: 0,
    dispatchAssociated: false,
    restaurant_id: false,
    triggerUpdate: false,
    selectedLocation: [],
    cartwheel_update_enabled: false,
    cartwheel_team_id: '',
};

export default (state = locationFormDefaultState, action) => {

    switch (action.type) {

        case 'LOCATION_FORM_UPDATE':
            return {
                ...state,
                [action.location.prop]: action.location.value
            };

        case 'LOCATION_FORM_UPDATE_HOUR_OF_OPERATION':
            return {
                ...state,
                hoursOfOperation: 
                    {
                        ...state.hoursOfOperation,
                        [action.location.prop]: action.location.value
                    }
            };

        case 'LOCATION_FORM_UPDATE_DELIVERY_HOURS':
            return {
                ...state,
                deliveryHours: 
                    {
                        ...state.deliveryHours,
                        [action.location.prop]: action.location.value
                    }
            };

        case 'LOCATION_FORM_ADD_DELIVERY_CHARGE':
            return {
                ...state,
                deliveryCharges: state.deliveryCharges.concat(action.deliveryCharge)
            };
    
        case 'LOCATION_FORM_REMOVE_DELIVERY_CHARGE':

            let deletedDeliveryCharges = (state.deliveryCharges[action.index].id) ? state.deletedDeliveryCharges.concat(state.deliveryCharges[action.index].id) : state.deletedDeliveryCharges;

            return {
                ...state,
                deletedDeliveryCharges,
                deliveryCharges: state.deliveryCharges.filter((charge, index) => {
                    return index != action.index;
                })
            };

        case 'LOCATION_FORM_UPDATE_DELIVERY_CHARGE':
            return {
                ...state,
                deliveryCharges: state.deliveryCharges.map((deliveryCharge, index) => {
                    if (index == action.deliveryCharge.index) {
                        return {
                            ...deliveryCharge,
                            [action.deliveryCharge.prop]: action.deliveryCharge.value
                        }
                    }

                    return deliveryCharge;
                })
            };

        case 'LOCATION_FORM_RESET_DELIVERY_CHARGES':
            return {
                ...state,
                deliveryCharges: [],
            }


        case 'LOCATION_FORM_ADD_PROMO_CODE':
            return {
                ...state,
                promoCodes: state.promoCodes.concat(action.promoCode)
            };
    
        case 'LOCATION_FORM_REMOVE_PROMO_CODE':

            let deletedPromoCodes = (state.promoCodes[action.index].id) ? state.deletedPromoCodes.concat(state.promoCodes[action.index].id) : state.deletedPromoCodes;

            return {
                ...state,
                deletedPromoCodes,
                promoCodes: state.promoCodes.filter((code, index) => {
                    return index != action.index;
                })
            };

        case 'LOCATION_FORM_UPDATE_PROMO_CODE':
            return {
                ...state,
                promoCodes: state.promoCodes.map((promoCode, index) => {
                    if (index == action.promoCode.index) {
                        return action.promoCode.promoCode;
                    }

                    if(action.promoCode.promoCode.auto_apply){
                        promoCode.auto_apply = 0; // There can only be one
                        promoCode.show_on_listing = 0;
                    }

                    return promoCode;
                })
            };

        case 'LOCATION_FORM_RESET_PROMO_CODES':
            return {
                ...state,
                promoCodes: [],
            }

        case 'LOCATION_FORM_ERROR':
            return {
                ...state,
                message: '',
                error: action.message
            }

        case 'LOCATION_FORM_HOLIDAY_ERROR':
            return {
                ...state,
                message: '',
                holidayError: action.message
            }

        case 'LOCATION_FORM_SUCCESS':
            return {
                ...state,
                error: '',
                message: action.message
            }

        case 'LOCATION_FORM_NEW_HOLIDAY_RESET':
            return {
                ...state,
                holidayError: '',
                holidayHoursNewStartDate: '',
                holidayHoursNewEndDate: '',
                holidayHoursNewOpenTime: moment().utc().set({hour: 8, minute: 0}),
                holidayHoursNewCloseTime: moment().utc().set({hour: 22, minute: 0}),
                holidayHoursNewClosedAllDay: 0,
                holidayHoursSelectedID: 0,
                showNewHolidayHours: false,
            }

        case 'LOCATION_FORM_RESET':
            return locationFormDefaultState;

        default:
            return state;
    }
};