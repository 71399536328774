import React from 'react';
import { connect } from 'react-redux';
import arrayMove from 'array-move';

import Typography from '@material-ui/core/Typography';

import SettingsList from './SettingsList';
import SettingForm from './SettingForm';

import { 
    populateNewCustomPizzaFormSizes, 
    populateUpdateCustomPizzaFormSizes, 
    customPizzaFormUpdate, 
    customPizzaFormUpdatePrice,
    customPizzaFormError, 
    customPizzaFormReset, 
    customPizzaFormSet,
    customPizzaFormUpdateHoursAvailable } from '../../../../actions/menu/pizzas/customPizzaForm';
import { settingsFormUpdate, settingsFormAddCustomPizza, settingsFormUpdateCustomPizza, settingsFormRemoveCustomPizza } from '../../../../actions/menu/pizzas/settingsForm';

import validateCurrency from '../../../../utils/validateCurrency';

class CustomPizzas extends React.Component {

    constructor(props) {
        super(props);

        this.handleAddCustomPizzaView = this.handleAddCustomPizzaView.bind(this);
        this.handleAddCustomPizza = this.handleAddCustomPizza.bind(this);
        this.handleUpdateCustomPizzaView = this.handleUpdateCustomPizzaView.bind(this);
        this.handleUpdateCustomPizza = this.handleUpdateCustomPizza.bind(this);
        this.handleCancelButton = this.handleCancelButton.bind(this);
        this.handleRemoveCustomPizza = this.handleRemoveCustomPizza.bind(this);
        this.handleUpdateCustomPizzaForm = this.handleUpdateCustomPizzaForm.bind(this);
        this.handleUpdateCustomPizzaFormPrice = this.handleUpdateCustomPizzaFormPrice.bind(this);
        this.handleUpdateHoursAvailable = this.handleUpdateHoursAvailable.bind(this);
        this.onSortEnd = this.onSortEnd.bind(this);

        this.state = {
            customPizzasDisplayView: 'list'
        };
    }

    componentDidMount() {
    }

    handleUpdateCustomPizzaForm(customPizza) {
        this.props.dispatch(customPizzaFormUpdate(customPizza));
    }

    handleUpdateCustomPizzaFormPrice(price) {
        this.props.dispatch(customPizzaFormUpdatePrice(price));
    }

    handleUpdateHoursAvailable(setting){
        this.props.dispatch(customPizzaFormUpdateHoursAvailable(setting));
    }

    onSortEnd({oldIndex, newIndex})  {

        let newCustomPizzaOrder = arrayMove(this.props.settingsForm.customPizzas, oldIndex, newIndex);
        for (let i = 0; i < newCustomPizzaOrder.length; i++) {
            newCustomPizzaOrder[i]['customPizza_order'] = i + 1;
        }

        this.props.dispatch(settingsFormUpdate({ prop: 'customPizzas', value: newCustomPizzaOrder }));
    }

    handleAddCustomPizza() {
        //error checking

        if ((typeof this.props.customPizzaForm.max_toppings === 'string' && !this.props.customPizzaForm.max_toppings.match(/^\d+$/)) || parseInt(this.props.customPizzaForm.max_toppings)  <= 0) {
            this.props.dispatch(customPizzaFormError('Please enter a valid max toppings amount greater than 0.'));
        }
        else {
            let valid = true;

            // go through each price and determine if it is valid
            for (let i = 0; i < this.props.customPizzaForm.prices.length; i++) {

                // ignore any blank rows
                if (!validateCurrency(this.props.customPizzaForm.prices[i].price)) {
                    valid = false;
                    this.props.dispatch(customPizzaFormError("Please ensure that all sizes have a valid price."));
                }
            }

            // verify that the max toppings aren't used elsewhere
            if (valid) {
                for (let i = 0; i < this.props.settingsForm.customPizzas.length; i++) {
                    if (this.props.settingsForm.customPizzas[i].max_toppings == this.props.customPizzaForm.max_toppings) {
                        valid = false;
                        this.props.dispatch(customPizzaFormError("There is already a setting for "+this.props.customPizzaForm.max_toppings+" topping pizza."));
                    }
                }
            }

            if (valid) {

                this.props.dispatch(customPizzaFormError(""));
                
                // add the customPizza
                this.props.dispatch(settingsFormAddCustomPizza(this.props.customPizzaForm));

                // reset the form and return to the main list view
                this.props.dispatch(customPizzaFormReset());
                this.setState(() => ({ customPizzasDisplayView: 'list' }));
            }
        }
    }

    handleUpdateCustomPizza() {

        //error checking
        if ((typeof this.props.customPizzaForm.max_toppings === 'string' && !this.props.customPizzaForm.max_toppings.match(/^\d+$/)) || parseInt(this.props.customPizzaForm.max_toppings) <= 0) {
            this.props.dispatch(customPizzaFormError('Please enter a valid max toppings amount greater than 0.'));
        }
        else {
            let valid = true;

            // go through each price and determine if it is valid
            for (let i = 0; i < this.props.customPizzaForm.prices.length; i++) {

                // ignore any blank rows
                if (!validateCurrency(this.props.customPizzaForm.prices[i].price)) {
                    valid = false;
                    this.props.dispatch(customPizzaFormError("Please ensure that all sizes have a valid price."));
                }
            }

            // verify that the max toppings aren't used elsewhere
            if (valid) {
                for (let i = 0; i < this.props.settingsForm.customPizzas.length; i++) {
                    if (i != this.props.customPizzaForm.index && this.props.settingsForm.customPizzas[i].max_toppings == this.props.customPizzaForm.max_toppings) {
                        valid = false;
                        this.props.dispatch(customPizzaFormError("There is already a setting for "+this.props.customPizzaForm.max_toppings+" topping pizza."));
                    }
                }
            }

            if (valid) {
                this.props.dispatch(customPizzaFormError(""));
                
                // update the choice
                this.props.dispatch(settingsFormUpdateCustomPizza(this.props.customPizzaForm, this.props.customPizzaForm.index));

                // reset the form and return to the main list view
                this.props.dispatch(customPizzaFormReset());
                this.setState(() => ({ customPizzasDisplayView: 'list' }));
            }
        }
    }

    handleRemoveCustomPizza(index) {
        if (confirm("Remove this custom pizza?")) {
            this.props.dispatch(settingsFormRemoveCustomPizza(index));
        }
    }

    handleAddCustomPizzaView() {
        this.props.dispatch(customPizzaFormReset());
        this.props.dispatch(customPizzaFormError(""));
        this.props.dispatch(populateNewCustomPizzaFormSizes(this.props.sizes));
        this.setState(() => ({ customPizzasDisplayView: 'new_custom_pizza' }));
    }

    handleUpdateCustomPizzaView(customPizza, index) {
        this.props.dispatch(customPizzaFormError(""));
        this.props.dispatch(customPizzaFormSet(customPizza, index));
        this.props.dispatch(populateUpdateCustomPizzaFormSizes(customPizza));
        this.setState(() => ({ customPizzasDisplayView: 'update_custom_pizza' }));
    }

    handleCancelButton() {
        this.setState(() => ({ customPizzasDisplayView: 'list' }));
    }

    renderCustomPizzasView() {
        switch (this.state.customPizzasDisplayView) {

            case 'new_custom_pizza':
                return (
                    <div>
                        <Typography variant="h5" id="modal-title">
                            Add a new Custom Pizza
                        </Typography>
                        
                        <Typography className="modal-description">
                            Enter your custom pizza max toppings, prices, and click 'Add Custom Pizza'. Please note: you will still need to save the customPizzas after adding them.
                        </Typography>
                        
                        <SettingForm 
                            form={this.props.customPizzaForm}
                            showNonTaxable={true}
                            handleCancelButton={this.handleCancelButton}
                            handleSubmit={this.handleAddCustomPizza}
                            handleSubmitLabel={'Add Custom Pizza'}
                            updateSettingPrice={this.handleUpdateCustomPizzaFormPrice}
                            updateSetting={this.handleUpdateCustomPizzaForm}
                            updateHoursAvailable={this.handleUpdateHoursAvailable}
                            isCustomPizza={true}
                        />
                    </div>
                );

            case 'update_custom_pizza':
                return (
                    <div>
                        <Typography variant="h5" id="modal-title">
                            Update Custom Pizza
                        </Typography>
                        
                        <Typography className="modal-description">
                            Update your custom pizza max toppings, prices, and click 'Update Custom Pizza'. Please note: you will still need to save the customPizzas after updating.
                        </Typography>

                        <SettingForm 
                            form={this.props.customPizzaForm}
                            showNonTaxable={true}
                            handleCancelButton={this.handleCancelButton}
                            handleSubmit={this.handleUpdateCustomPizza}
                            handleSubmitLabel={'Update Custom Pizza'}
                            updateSettingPrice={this.handleUpdateCustomPizzaFormPrice}
                            updateSetting={this.handleUpdateCustomPizzaForm}
                            updateHoursAvailable={this.handleUpdateHoursAvailable}
                            isCustomPizza={true}
                        />
                    </div>
                );

            case 'list':
            default:
                return (
                    <div>
                        <Typography variant="h5" id="modal-title">
                            Custom Pizzas
                        </Typography>
                        
                        <Typography className="modal-description">
                            Enter any available custom pizzas that you have by max toppings, and their price per size.
                        </Typography>

                        <SettingsList 
                            items={this.props.settingsForm.customPizzas}
                            handleAddView={this.handleAddCustomPizzaView}
                            handleUpdateView={this.handleUpdateCustomPizzaView}
                            handleRemove={this.handleRemoveCustomPizza}
                            addLabel={"Add Custom Pizza"}
                            emptyLabel={"No custom pizzas added."}
                            isCustomPizza={true}
                        />
                    </div>
                );
        }
    }

    render() {
        return (
            <div>
                {this.renderCustomPizzasView()}
                {this.state.customPizzasDisplayView == 'list' && this.props.children}
            </div>
        );
    }
};

const mapStateToProps = (state, props) => {
    return {
        settingsForm: state.pizzaSettingsForm,
        sizes: state.pizzas.settings.sizes,
        customPizzaForm: state.customPizzaForm
    };
};

export default connect(mapStateToProps)(CustomPizzas);