export const toggleSelectedLocation = (location_id, checking) => {
    // checking is the state it will become
    return {
        type: 'TOGGLE_PROMO_LOCATION',
        location_id,
        checking,
    }
}

export const selectAllLocations = (allLocations) => {
    return {
        type: 'SELECT_ALL_PROMO_LOCATIONS',
        allLocations
    }
}

export const setPromosLoading = () => {
    return {
        type: 'SET_PROMO_CODES_LOADING'
    }
}

export const setPromosDoneLoading = () => {
    return {
        type: 'SET_PROMO_CODES_DONE_LOADING'
    }
}

export const promoCodesFormReset = () => {
    
    return {
        type: 'PROMO_CODES_FORM_RESET',
    };
}