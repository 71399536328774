// the default items that we can possibly filter by
const specialsDefaultState = {
    loading: true,
    specials: [],
    selectableItems: [],
    filter: ''
};

export default (state = specialsDefaultState, action) => {
    switch (action.type) {
        case 'SET_SPECIALS':
            return {
                ...state,
                loading: false,
                specials: action.specials,
                filter: ''
            };

        case 'SET_SELECTABLE_ITEMS':
            return {
                ...state,
                loading: false,
                selectableItems: action.selectableItems,
                filter: ''
            };

        case 'SET_SPECIAL_FILTER':
            return {
                ...state,
                filter: action.text
            };

        case 'SET_SPECIALS_LOADING':
            return {
                ...state,
                loading: true
            };
            
        case 'RESET_SPECIALS':
            return specialsDefaultState;

        default:
            return state;
    }
};