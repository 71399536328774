// Filter out restaurants where all locations are associated with entity (dispatch center/app directory/etc.)

export default (active_locations, restaurant) => {

    let num_locations = 0;

    for (const [key, value] of Object.entries(restaurant.locations)) {
            
        let restaurant_location = value;

        for (const [key, value] of Object.entries(active_locations)) {

            if(value.location_id == restaurant_location.id){
                num_locations++;
            }

        }

    }

    return !(num_locations == restaurant.locations.length && restaurant.locations.length != 0);
    
};