const customPizzaFormDefaultState = {
    index: -99,
    max_toppings: 3,
    non_taxable: 0,
    prices: [],
    message: '',
    error: '',
    alwaysAvailable: true,
    hours_available: {
        sun_start: '00:00:00',
        sun_end: '00:00:00',
        mon_start: '00:00:00',
        mon_end: '00:00:00',
        tue_start: '00:00:00',
        tue_end: '00:00:00',
        wed_start: '00:00:00',
        wed_end: '00:00:00',
        thu_start: '00:00:00',
        thu_end: '00:00:00',
        fri_start: '00:00:00',
        fri_end: '00:00:00',
        sat_start: '00:00:00',
        sat_end: '00:00:00',
        sun_available: false,
        mon_available: false,
        tue_available: false,
        wed_available: false,
        thu_available: false,
        fri_available: false,
        sat_available: false,
        sun_all_day: false,
        mon_all_day: false,
        tue_all_day: false,
        wed_all_day: false,
        thu_all_day: false,
        fri_all_day: false,
        sat_all_day: false
    },
    scrollPosition: 0,
    saveSchedule: false,
    scheduleName: ''
};

export default (state = customPizzaFormDefaultState, action) => {
    
    switch (action.type) {

        case 'CUSTOM_PIZZA_FORM_UPDATE':
            return {
                ...state,
                [action.customPizza.prop]: action.customPizza.value
            };

        case 'CUSTOM_PIZZA_FORM_UPDATE_HOURS_AVAILABLE':
            return {
                ...state,
                hours_available: 
                    {
                        ...state.hours_available,
                        [action.location.prop]: action.location.value
                    }
            };

        case 'CUSTOM_PIZZA_FORM_UPDATE_PRICE':
            return {
                ...state,
                prices: state.prices.map((item, index) => {
                    if (index == action.item.index) {
                        return {
                            ...item,
                            [action.item.prop]: action.item.value
                        }
                    }

                    return item;
                })
            };

        case 'CUSTOM_PIZZA_FORM_RESET_PRICES':
            return {
                ...state,
                prices: [],
            }

        case 'CUSTOM_PIZZA_FORM_ERROR':
            return {
                ...state,
                message: '',
                error: action.message
            }

        case 'CUSTOM_PIZZA_FORM_SUCCESS':
            return {
                ...state,
                error: '',
                message: action.message
            }

        case 'CUSTOM_PIZZA_FORM_SET':
            return {
                ...state,
                ...action.customPizza
            };

        case 'CUSTOM_PIZZA_FORM_RESET':
            return customPizzaFormDefaultState;

        default:
            return state;
    }
};