import React from 'react';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { loadAdminRestaurantLocations } from '../../../actions/superuser/restaurantLocations';
import { updateAppDirectory } from '../../../actions/superuser/appDirectories/directories';
import { appDirectoryFormUpdate, appDirectoryFormError, appDirectoryFormReset } from '../../../actions/superuser/appDirectories/appDirectoryForm';
import { openAppDirectoryUpdate, closeAppDirectoryUpdate } from '../../../actions/modals';  

import CloseIcon from '../../common/modal/CloseIcon';

import Info from './AppDirectoryInfo';
import Locations from './AppDirectoryLocations';

class UpdateAppDirectory extends React.Component {

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.closeUpdateModal = this.closeUpdateModal.bind(this);
        this.updateAppDirectoryInfo = this.updateAppDirectoryInfo.bind(this);
        this.updateAppDirectoryLocations = this.updateAppDirectoryLocations.bind(this);

        this.state = {
            displayView: 0
        };
    }

    componentDidMount() {
        this.props.dispatch(loadAdminRestaurantLocations());
    }

    handleChange(event, newValue) {
        this.props.dispatch(appDirectoryFormUpdate({ prop: 'error', value: '' }));
        this.props.dispatch(appDirectoryFormUpdate({ prop: 'message', value: '' }));
        this.setState(() => ({displayView: newValue}))
    }

    closeUpdateModal() {
        this.props.dispatch(closeAppDirectoryUpdate());
        this.setState(() => ({displayView: 0}))
    }

    updateAppDirectoryInfo() {

        // perform some error checking
        if (this.props.appDirectoryForm.name.trim() == "") {

            // required info isn't present
            this.props.dispatch(appDirectoryFormError("Please enter an app directory name."));
        }
        else {

            // clear the error and attempt to update the food item info
            this.props.dispatch(appDirectoryFormError(""));
            this.props.dispatch(updateAppDirectory(this.props.directory.id, 'info', this.props.appDirectoryForm));
        }
    }

    updateAppDirectoryLocations() {

        let valid = true;
        let multiplePriceCount = 0;

        // perform error checking on the food prices
        if (this.props.foodItemForm.hasMultiplePrices) {

            // go through each price and determine if it is valid
            for (let i = 0; i < this.props.foodItemForm.prices.length; i++) {

                // ignore any blank rows
                if (this.props.foodItemForm.prices[i].name != '' || this.props.foodItemForm.prices[i].price != '') {
                    if (!validateCurrency(this.props.foodItemForm.prices[i].price) || this.props.foodItemForm.prices[i].name.match(/^\s*$/)) {
                        valid = false;
                        this.props.dispatch(foodItemFormError("Please ensure that all prices have a valid name and price."));
                    }
                    else {
                        multiplePriceCount++;
                    }
                }
            }

            if (multiplePriceCount <= 0 && valid) {
                valid = false;
                this.props.dispatch(foodItemFormError("Please enter at least one price."));
            }
        }
        else {
            if (!validateCurrency(this.props.foodItemForm.singlePrice)) {
                this.props.dispatch(foodItemFormError("Please enter a valid food item price."));
                valid = false;
            }
        }

        if (valid) {
            this.props.dispatch(foodItemFormError(""));
            
            // clean out any multiple prices that are empty
            if (this.props.foodItemForm.hasMultiplePrices) {
                let trimmedPrices = this.props.foodItemForm.prices.filter((price) => {
                    return price.name != '' && price.price != '';
                });
                this.props.dispatch(foodItemFormUpdate({ prop: 'prices', value: trimmedPrices }));
            }

            this.props.dispatch(updateFoodItem(this.props.restaurantId, this.props.categoryId, this.props.foodItem.id, 'prices', this.props.foodItemForm));
        }
    }

    renderUpdateModalActiveTab() {
        if (this.props.loading) {
            return (
                <div className="page-loading">
                    <CircularProgress size={100} />
                </div>
            );
        }
        else {
            switch(this.state.displayView) {
                case 0:
                    return this.renderUpdateModalInfo();

                case 1:
                    return this.renderUpdateModalLocations();
            }
        }
    }

    renderUpdateFeedback() {
        return (
            <div>
                { this.props.appDirectoryForm.error && (
                    <Typography className="modal-error">
                        { this.props.appDirectoryForm.error }
                    </Typography>
                )}

                { this.props.appDirectoryForm.message && (
                    <Typography className="modal-success">
                        { this.props.appDirectoryForm.message }
                    </Typography>
                )}
            </div>
        );
    }

    renderUpdateModalInfo() {
        return (
            <div>
                {this.renderUpdateFeedback()}

                <Info />

                <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom" onClick={this.updateAppDirectoryInfo}>
                    Update Directory
                </Button>
            </div>
        );
    }

    renderUpdateModalLocations() {
        return (
            <div>
                {this.renderUpdateFeedback()}

                <Grid container>
                    <Grid item xs={12} className="form-grid">
                        <Typography variant="h4" className="input-label-mb">
                            Locations
                        </Typography>
                    </Grid>
                </Grid>

                <Locations />
            </div>
        );
    }

    render() {
        return (
            <Modal
                aria-labelledby="app-directory-update"
                aria-describedby="app-directory-update"
                open={this.props.updateModalOpen}
                onClose={() => this.closeUpdateModal()}
                className="modal_admin"
                disableBackdropClick
            >
                <div className="modal-member">
                    <Typography variant="h4" id="modal-title">
                        <CloseIcon/>
                    </Typography>
                    <Tabs
                        value={this.state.displayView}
                        onChange={this.handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        centered
                    >
                        <Tab label="General Info" />
                        <Tab label="Locations" />
                    </Tabs>

                    <Typography variant="h2" id="modal-title">
                        Update {this.props.directory.name}
                    </Typography>

                    {this.renderUpdateModalActiveTab()}
                </div>
            </Modal>
        );
    }
};

const mapStateToProps = (state, props) => {
    return {
        loading: state.directories.loading,
        directory: state.directories.selectedDirectory,
        appDirectoryForm: state.appDirectoryForm,
        updateModalOpen: state.modals.appDirectoryUpdate,
    };
};

export default connect(mapStateToProps)(UpdateAppDirectory);