const addonFormDefaultState = {
    name: '',
    description: '',
    price: 0.00,
    usePizzas: false,
    useFood: false,
    useSpecials: false,
    message: '',
    error: '',
};

export default (state = addonFormDefaultState, action) => {

    switch (action.type) {

        case 'ADDON_FORM_UPDATE':
            return {
                ...state,
                [action.addon.prop]: action.addon.value
            };

        case 'ADDON_FORM_ERROR':
            return {
                ...state,
                message: '',
                error: action.message
            }

        case 'ADDON_FORM_SUCCESS':
            return {
                ...state,
                error: '',
                message: action.message
            }

        case 'ADDON_FORM_RESET':
            return addonFormDefaultState;

        default:
            return state;
    }
};