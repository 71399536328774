export const managerFormUpdate = ({ prop, value }) => {

    return {
        type: 'MANAGER_FORM_UPDATE',
        manager: { prop, value }
    };
};

export const managerFormError = (message) => {

    return {
        type: 'MANAGER_FORM_ERROR',
        message
    };
};

export const managerFormSuccess = (message) => {

    return {
        type: 'MANAGER_FORM_SUCCESS',
        message
    };
};

export const managerFormReset = () => {
    
    return {
        type: 'MANAGER_FORM_RESET',
    };
}
