const loyaltyPointsFormDefaultState = {
    id: '',
    email: '',
    points: 0,
    points_used: 0,
    loading: false,
    message: '',
    error: ''
};

export default (state = loyaltyPointsFormDefaultState, action) => {

    switch (action.type) {

        case 'LOYALTY_POINTS_FORM_UPDATE':
            return {
                ...state,
                [action.user.prop]: action.user.value
            };

        case 'LOYALTY_POINTS_FORM_ERROR':
            return {
                ...state,
                message: '',
                error: action.message
            }

        case 'LOYALTY_POINTS_FORM_SUCCESS':
            return {
                ...state,
                error: '',
                message: action.message
            }

        case 'LOYALTY_POINTS_FORM_RESET':
            return loyaltyPointsFormDefaultState;

        default:
            return state;
    }
};