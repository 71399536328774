import React from "react";
import { connect } from 'react-redux';
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionActions from "@material-ui/core/AccordionActions";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import EditIcon from '@material-ui/icons/Edit';
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Slider from "@material-ui/core/Slider";
import InputLabel from '@material-ui/core/InputLabel';
import DatePicker from "react-datepicker";
import TimePicker from 'rc-time-picker';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';

import {locationFormUpdate, locationFormHolidayError, locationFormNewHolidayReset, addHolidayHours, updateHolidayHours, removeHolidayHours} from '../../actions/restaurant/locationForm';
import {getLocationByID} from '../../actions/helpers/getLocationByID';

const classes = theme => ({
  root: {
    width: "100% !important",
    "& .Mui-expanded": {
      transform: "rotate(0deg)",
      backgroundColor: "rgb(245, 240, 237)"
    },
    "& .MuiAccordionSummary-content": {
      flexGrow: 12
    },
    "& .MuiIconButton-root": {
      flexGrow: 2,
      paddingTop: 0
    }
  },
  accordion: {
    minHeight: 40, //ugly but works
    height: "100%"
  },
  heading: {
    fontSize: theme.typography.pxToRem(15)
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(19),
    color: theme.palette.text.primary
  },
  detailFullWidth: {
    alignItems: "center",
  },
  column: {
    flexBasis: "33.33%"
  },
  fullWidthColumn: {
    flexBasis: "100%",
  },
  helper: {
    padding: theme.spacing(1, 2)
  },
  smallerText: {
    fontSize: "1rem"
  },
  detailText: {
    fontSize: "0.9rem"
  },
  hidden: {
    visibility: 'hidden',
  }
});

class DetailedAccordion extends React.Component {

  constructor(props) {
    super(props);

    let defaultExpanded = (this.props.new && this.props.new == true) ? true: false;

    this.state = {
      expanded: defaultExpanded
    }

  }

  toggleExpand(expanded, holiday_id) { // holiday_id may be false if we are working with a new holiday accordion

    // expanded is desired state, not current

    if(expanded && holiday_id != false && holiday_id == this.props.holiday.id){ // If we are editing an existing holiday set its data
      this.props.dispatch(locationFormUpdate({ prop: 'holidayHoursNewStartDate', value: moment(this.props.holiday.date_start, 'YYYY-MM-DD').toDate() }));
      this.props.dispatch(locationFormUpdate({ prop: 'holidayHoursNewEndDate', value: moment(this.props.holiday.date_end, 'YYYY-MM-DD').toDate() }));
      this.props.dispatch(locationFormUpdate({ prop: 'holidayHoursNewOpenTime', value: moment(this.props.holiday.day_open, 'HH:mm:ss') }));
      this.props.dispatch(locationFormUpdate({ prop: 'holidayHoursNewCloseTime', value: moment(this.props.holiday.day_close, 'HH:mm:ss') }));
      this.props.dispatch(locationFormUpdate({ prop: 'holidayHoursNewClosedAllDay', value: this.props.holiday.day_closed }));
      this.props.dispatch(locationFormUpdate({ prop: 'holidayHoursSelectedID', value: this.props.holiday.id }));
      this.props.dispatch(locationFormUpdate({ prop: 'showNewHolidayHours', value: false }));
      this.setState(() => ({expanded: expanded}));
    }else if(expanded && this.props.new){ // This is the new accordion
      this.props.dispatch(locationFormNewHolidayReset());
      this.props.dispatch(locationFormUpdate({ prop: 'showNewHolidayHours', value: true }));
      this.props.dispatch(locationFormUpdate({ prop: 'holidayHoursSelectedID', value: false }));
      this.setState(() => ({expanded: expanded}));
    }else{ // Here we are either toggling an accordion closed or we are within a non-selected accordion. In both cases we close
      this.setState(() => ({expanded: false}));
    }

  }

  componentDidUpdate(prevProps, prevState){
    // This is called for all accordions, both new and for each holiday date range
    // We want to close all other accordions except the one we just clicked

    if(prevProps.currentLocation.holiday_hours != this.props.currentLocation.holiday_hours){
      // This is needed because the dispatch is not being synchronous for some reason and the update to this.props.locations.locations happens last
      this.props.dispatch(locationFormUpdate({ prop: 'holidayHours', value: this.props.currentLocation.holiday_hours }));
    }

    if(this.state.expanded !== false && (this.props.holidayHoursSelectedID != (this.props.holiday.id ?? false))){
      this.toggleExpand(false, this.props.holiday.id ?? false);
    }

  }

  addHolidayHoursVerify(){

    let valid = true;

    if (this.props.locationForm.holidayHoursNewStartDate == '' || this.props.locationForm.holidayHoursNewEndDate == '') {
      valid = false;
      this.props.dispatch(locationFormHolidayError("Please ensure that your open date and close date have been set."));
    }

    if (this.props.locationForm.holidayHoursNewStartDate == this.props.locationForm.holidayHoursNewEndDate && !this.props.locationForm.holidayHoursNewClosedAllDay) {
      valid = false;
      this.props.dispatch(locationFormHolidayError("Please ensure that your open date and close date are not the same if your store isn't closed all day."));
    }

    if (valid) {

        this.props.dispatch(locationFormHolidayError(""));

        if(this.props.new){
          this.props.dispatch(addHolidayHours(this.props.locationForm, this.props.selectedFullLocation));
          this.toggleExpand(false, false);
          this.props.dispatch(locationFormUpdate({ prop: 'showNewHolidayHours', value: true }));
          this.props.dispatch(locationFormUpdate({ prop: 'holidayHoursSelectedID', value: false }));
        }else{
          this.props.dispatch(updateHolidayHours(this.props.locationForm, this.props.selectedFullLocation, this.props.holiday.id));
          this.toggleExpand(false, this.props.holiday.id);
        }

    }
  }

  removeHolidayHoursVerify(){
    let confirmStr = "Are you sure you would like to remove this holiday hours entry?";
    if(confirm(confirmStr)){
      this.props.dispatch(removeHolidayHours(this.props.selectedFullLocation, this.props.holiday.id));
    }
  }

  render(){
    let newEditHeader, newEditClosedHeader = '';
    if(this.props.new){
      newEditHeader = 'Create Holiday Hours';
      newEditClosedHeader = 'Create Holiday Hours';
    }else{
      newEditHeader = 'Editing Holiday Hours';
      newEditClosedHeader = moment(this.props.holiday.date_start, 'YYYY-MM-DD').format('MMMM Do YYYY') + " - " + moment(this.props.holiday.date_end, 'YYYY-MM-DD').format('MMMM Do YYYY');
    }

    return (
      <>
        <div className="accordionRoot">
          {/*elevation is  not listed in docs but is available. defaultExpanded */}
          <Accordion elevation={3} className="accordion" defaultExpanded={this.state.expanded} expanded={this.state.expanded}>
              <AccordionSummary
                  expandIcon={this.state.expanded ?  <EditIcon />: <AddIcon />}
                  onClick={(event) => {
                    
                    this.toggleExpand(!this.state.expanded, this.props.holiday && this.props.holiday.id);
                    //If I wanted to expand only on IconClick I would use the below
                    //event.stopPropagation();
                  }}
              >
                  {
                      this.state.expanded && (<div className="fullWidthColumn">
                          <Typography className="heading">{newEditHeader}</Typography>
                          </div>)
                  }
                  {
                      !this.state.expanded && (<div className="fullWidthColumn">
                          <Typography className="heading">{newEditClosedHeader}</Typography>
                          </div>)
                  }
              </AccordionSummary>
              <AccordionDetails className="detailFullWidth">
                  <div className="fullWidthColumn" >
                      <Typography className="smallerText">Holiday Hours are temporary. After the set dates/times have passed, your hours will go back to normal.</Typography>
                  </div>
              </AccordionDetails>
              <AccordionDetails className="detailFullWidth">
                  <div className="column" >
                      <Typography className="secondaryHeading">
                          Set Date
                      </Typography>
                      <Typography className="detailText">
                          Choose a single day or range
                      </Typography>
                      <br />
                      <InputLabel className="input-label-mb">Start Date</InputLabel>
                      <DatePicker 
                          selected={this.props.locationForm.holidayHoursNewStartDate} 
                          onChange={(date) => { this.props.dispatch(locationFormUpdate({ prop: 'holidayHoursNewStartDate', value: date })); }} 
                          dateFormat="MM/dd/yyyy"
                          selectsStart
                          startDate={this.props.locationForm.holidayHoursNewStartDate}
                          endDate={this.props.locationForm.holidayHoursNewEndDate}
                          maxDate={this.props.locationForm.holidayHoursNewEndDate}
                          minDate={new Date() }
                      />
                  </div>
                  <div className="column" >
                      <Typography className="secondaryHeading hidden">
                          Set Date
                      </Typography>
                      <Typography className="detailText hidden">
                          Choose a single day or range
                      </Typography>
                      <br />
                      <InputLabel className="input-label-mb">End Date</InputLabel>
                      <DatePicker 
                          selected={this.props.locationForm.holidayHoursNewEndDate} 
                          onChange={(date) => { this.props.dispatch(locationFormUpdate({ prop: 'holidayHoursNewEndDate', value: date })); }} 
                          dateFormat="MM/dd/yyyy"
                          selectsEnd
                          startDate={this.props.locationForm.holidayHoursNewStartDate}
                          endDate={this.props.locationForm.holidayHoursNewEndDate}
                          minDate={this.props.locationForm.holidayHoursNewStartDate}
                      />
                  </div>
              </AccordionDetails>
              <AccordionDetails>
                  <Grid container>
                      <Grid item xs={12} className="form-grid">
                          <Typography className="secondaryHeading">
                              Set Hours
                          </Typography>
                      </Grid>
                      <Grid item xs={12} className="form-grid">
                          <Typography className="detailText">
                              Modify your open and close time or set to closed all day.
                          </Typography>
                      </Grid>
                      <Grid item xs={4} className="form-grid">
                          <Grid container direction="column">
                              <Grid item xs={4} className="form-grid">
                                  <InputLabel className="input-label-mb">Open Time</InputLabel>
                                  <TimePicker
                                    allowEmpty={false}
                                    showSecond={false}
                                    defaultValue={this.props.locationForm.holidayHoursNewOpenTime}
                                    value={this.props.locationForm.holidayHoursNewOpenTime}
                                    use12Hours={true}
                                    minuteStep={5}
                                    inputClassName="date-picker-input"
                                    onChange={(date) => { this.props.dispatch(locationFormUpdate({ prop: 'holidayHoursNewOpenTime', value: date })); }} 
                                  />
                              </Grid>
                          </Grid>
                      </Grid>
                      <Grid item xs={4} className="form-grid">
                          <Grid container direction="column">
                              <Grid item xs={4} className="form-grid">
                                  <InputLabel className="input-label-mb">Close Time</InputLabel>
                                  <TimePicker
                                    allowEmpty={false}
                                    showSecond={false}
                                    defaultValue={this.props.locationForm.holidayHoursNewCloseTime}
                                    value={this.props.locationForm.holidayHoursNewCloseTime}
                                    use12Hours={true}
                                    minuteStep={5}
                                    inputClassName="date-picker-input"
                                    onChange={(date) => { this.props.dispatch(locationFormUpdate({ prop: 'holidayHoursNewCloseTime', value: date })); }} 
                                  />
                              </Grid>
                          </Grid>
                      </Grid>
                      <Grid item xs={3} className="form-image-container">
                          <FormControlLabel
                              control={
                              <Checkbox
                                  checked={this.props.locationForm.holidayHoursNewClosedAllDay}
                                  onChange={(e) => { this.props.dispatch(locationFormUpdate({ prop: 'holidayHoursNewClosedAllDay', value: !this.props.locationForm.holidayHoursNewClosedAllDay })); }}
                                  name={"Closed all day?"}
                                  color="primary"
                              />
                              }
                              label={"Closed all day?"}
                          />
                      </Grid>
                  </Grid>
              </AccordionDetails>
              { this.props.locationForm.holidayError != '' && 
                <AccordionDetails>
                  <div className="error-text">
                    {this.props.locationForm.holidayError}
                  </div>
                </AccordionDetails>
              }
              <AccordionActions>
                {!this.props.new && 
                  <Button variant="contained" fullWidth={true} color={"secondary"} className="modal-button-list" onClick={() => {this.removeHolidayHoursVerify()}}>
                    Delete
                  </Button>
                }
                <Button variant="contained" fullWidth={true} color={"primary"} className="modal-button-bottom" onClick={() => {this.addHolidayHoursVerify()}}>
                    {(this.props.new) ? 'Add Holiday Hours' : 'Update Holiday Hours'}
                </Button>
              </AccordionActions>
          </Accordion>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
      loading: state.loyaltyRewardForm.loading,
      locations: state.locations.locations,
      currentLocation: getLocationByID(state.locations.locations, props.selectedFullLocation.id), // Since selectedFullLocation is passed in it doesn't get the updates we need it to have in order to read the updates holiday hours
      locationForm: state.locationForm,
      holidayHoursSelectedID: state.locationForm.holidayHoursSelectedID,
      modalOpen: state.modals.searchReports
  };
};

export default connect(mapStateToProps)(DetailedAccordion);
