// check if the current user is able to access the section

export default (user, permissionLevel, permission_id = null) => {
    
    // if the user is a superuser, they have access
    if (user.permissionLevel == 'superuser') {
        return true;
    }

    // if the user is a support user, they have access to support pages
    if (user.permissionLevel == 'support' && permissionLevel == 'support') {
        return true;
    }

    // if the page can be seen by all dispatchers, check if the user has access to at let one dispatch center
    if (permissionLevel == 'dispatch_all' && user.dispatchPermissions.length > 0) {
        return true;
    }

    if (permissionLevel == 'dispatch' && permission_id) {
        let permission = null;

        // get the matching permission
        for (let i = 0; i < user.dispatchPermissions.length; i++) {
            if (user.dispatchPermissions[i].dispatch_center_id == permission_id) {
                permission = user.dispatchPermissions[i];
            }
        }

        if (permission) {

            // evaluate the restaurant permission and return if the user has access
            // if (permission.authtype == 'admin' || 
            //     (permission.authtype == 'manager' && (permissionLevel == 'restaurant_manager' || permissionLevel == 'restaurant_staff')) || 
            //     (permission.authtype == 'staff' && permissionLevel == 'restaurant_staff')) {
            //     // the user is a staff user; they have access to  staff pages
            //     return true;
            // }

            // // access denied
            // return false;
            return true;
        } else {

            // not authorized
            return false;
        }
    }
    
    // if we are looking for a restaurant admin, go through the restaurant permissions and 
    // see if there is a match
    if (['restaurant_admin', 'restaurant_manager', 'restaurant_staff'].includes(permissionLevel) && permission_id) {

        let permission = null;

        // get the matching permission
        for (let i = 0; i < user.restaurantPermissions.length; i++) {
            if (user.restaurantPermissions[i].restaurant_id == permission_id) {
                permission = user.restaurantPermissions[i];
            }
        }

        if (permission) {

            // evaluate the restaurant permission and return if the user has access
            if (permission.authtype == 'admin' || 
                (permission.authtype == 'manager' && (permissionLevel == 'restaurant_manager' || permissionLevel == 'restaurant_staff')) || 
                (permission.authtype == 'staff' && permissionLevel == 'restaurant_staff')) {
                // the user is a staff user; they have access to  staff pages
                return true;
            }

            // access denied
            return false;
        } else {

            // not authorized
            return false;
        }
    }

    return false;
};