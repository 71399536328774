import React from 'react';
import { sortableElement  } from 'react-sortable-hoc';

const SortableItem = (props) => {
    return (
        <div key={'sortitem-'+props.index}>{props.children}</div>
    );
}

export default sortableElement(SortableItem);
