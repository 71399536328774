import axios from 'axios';
import { API_URL } from '../../config/constants';
import { completeOrderFormLoading, completeOrderFormReset } from './completeOrderForm';
import { closeConfirmOrder, closeCompleteOrder, closeSearchReports } from '../modals';
import { resetSelectedOrder } from '../restaurant/selectedOrder';
import { readErrMsg } from '../helpers/readErrMsg';

import { 
    confirmOrderFormError
} from '../../actions/restaurant/confirmOrderForm';

import { 
    completeOrderFormError
} from '../../actions/restaurant/completeOrderForm';

import { 
    selectedOrderReceiptMessage,
    selectedOrderReceiptError,
    setSelectedOrderReceiptLoading,
    removeSelectedOrderReceiptLoading,
    setSelectedOrder
} from '../../actions/restaurant/selectedOrder';

export const setOrdersLoading = () => {
    return {
        type: 'SET_ORDERS_LOADING'
    };
}

export const removeOrdersLoading = () => {
    return {
        type: 'REMOVE_ORDERS_LOADING'
    };
}

export const playChime = () => {
    return {
        type: 'PLAY_CHIME'
    };
}

export const stopChime = () => {
    return {
        type: 'STOP_CHIME'
    };
}

export const setMaxOrderId = (maxOrderId = 0) => {
    return {
        type: 'SET_MAX_ORDER_ID',
        maxOrderId
    };
}

export const setOrdersLastUpdatedVisible = (visible) => {
    return {
        type: 'SET_ORDERS_LAST_UPDATED_VISIBLE',
        visible
    };
}

export const setOrders = ({ incoming = [], confirmed = [], ready = [], complete = [] }, orderInformation, maxOrderId = 0) => {
    return {
        type: 'SET_ORDERS_FEED',
        incoming,
        confirmed,
        ready,
        complete,
        orderInformation,
        maxOrderId
    };
}

export const setSupportOrders = ({ all = [], restaurantWarning = [], dispatchWarning = [] }, orderInformation, maxOrderId = 0, maxWarningOrderId = 0) => {
    return {
        type: 'SET_SUPPORT_ORDERS_FEED',
        all,
        restaurantWarning,
        dispatchWarning,
        orderInformation,
        maxOrderId,
        maxWarningOrderId,
    };
}

export const setSearchOrders = (orders, orderInformation) => {
    return {
        type: 'SET_SEARCH_ORDERS_FEED',
        orders,
        orderInformation
    };
}

export const removeDispatchOrder = (order_id, currentView) => { // Included dispatch in the name because other pages may categorize their orders differently e.g. complete/confirmed/incoming
    return (dispatch, getState) => {
        const state = getState();
        let orders = state.orders;

        orders[currentView] = orders[currentView].filter((order) => {
            return order.id != order_id;
        });

        dispatch(setOrders(orders, orders.orderInformation, orders.maxOrderId));
    }
}

export const loadOrders = (restaurant_id, location_id, max_order_id, selectedOrderID = 0) => {
    return (dispatch) => {
        return axios.get(API_URL+'/api/restaurants/'+restaurant_id+'/orders/'+location_id)
            .then(function(res) {

                // determine which orders are placed in which lists
                let orders = {
                    incoming: [],
                    confirmed: [],
                    ready: [],
                    complete: []
                };

                for (let i = 0; i < res.data.data.orders.length; i++) {
                    if (res.data.data.orders[i].order_status == 'new') {
                        orders['incoming'].push(res.data.data.orders[i]);
                    } else if (res.data.data.orders[i].order_status == 'confirmed' || res.data.data.orders[i].order_status == 'ready') {
                        orders['confirmed'].push(res.data.data.orders[i]);
                    } else {
                        orders['complete'].push(res.data.data.orders[i]);
                    }
                }

                if (res.data.data.maxoid != 0 && res.data.data.maxoid > max_order_id) {
                    // sound the chime
                    dispatch(playChime());
                }

                dispatch(setOrders(orders, res.data.data.orderInformation, res.data.data.maxoid));

                if(selectedOrderID != 0){
                    let selectedOrder = res.data.data.orders.find(o => o.id == selectedOrderID);
                    dispatch(setSelectedOrder(selectedOrder.id, selectedOrder, res.data.data.orderInformation[selectedOrder.id]));
                }

            })
            .catch(function(err) {
                console.log(err);
                console.log(err.response);
            });
    };
}

export const loadDispatchOrders = (dispatch_center_id, max_order_id) => {
    return (dispatch) => {
        return axios.get(API_URL+'/api/dispatch/orders/'+dispatch_center_id)
            .then(function(res) {

                // determine which orders are placed in which lists
                let orders = {
                    incoming: [],
                    confirmed: [],
                    ready: [],
                    complete: [],
                };

                for (let i = 0; i < res.data.data.orders.length; i++) {
                    if (res.data.data.orders[i].order_status == 'confirmed') {
                        orders['confirmed'].push(res.data.data.orders[i]);
                    } else if (res.data.data.orders[i].order_status == 'ready') {
                        orders['ready'].push(res.data.data.orders[i]);
                    } else {
                        orders['complete'].push(res.data.data.orders[i]);
                    }
                }

                if (max_order_id != 0 && res.data.data.maxoid != max_order_id) {
                    // sound the chime
                    dispatch(playChime());
                }

                dispatch(setOrders(orders, res.data.data.orderInformation, res.data.data.maxoid));                
            })
            .catch(function(err) {
                console.log(err);
                console.log(err.response);
            });
    };
}

export const loadSupportOrders = (max_warning_order_id, selectedOrderID = 0) => {
    return (dispatch) => {
        return axios.get(API_URL+'/api/support/orders')
            .then(function(res) {

                // determine which orders are placed in which lists
                let orders = {
                    all: res.data.data.orders,
                    dispatchWarning: res.data.data.warningDispatchOrders,
                    restaurantWarning: res.data.data.warningRestaurantOrders
                };

                if (max_warning_order_id != 0 && res.data.data.maxWarningOrderId != max_warning_order_id) {
                    // sound the chime
                    dispatch(playChime());
                }

                dispatch(setSupportOrders(orders, res.data.data.orderInformation, res.data.data.maxoid, res.data.data.maxWarningOrderId));

                if(selectedOrderID != 0){
                    let selectedOrder = res.data.data.orders.find(o => o.id == selectedOrderID);
                    dispatch(setSelectedOrder(selectedOrder.id, selectedOrder, res.data.data.orderInformation[selectedOrder.id]));
                }
                
            })
            .catch(function(err) {
                console.log(err);
                console.log(err.response);
            });
    };
}

export const searchSupportOrders = (searchData) => {
    return (dispatch) => {
        return axios.post(API_URL+'/api/orders/search', searchData)
            .then(function(res) {

                dispatch(setSearchOrders(res.data.data.orders, res.data.data.orderInformation));   
                dispatch(closeSearchReports());             
            })
            .catch(function(err) {
                console.log(err);
                console.log(err.response);
            });
    };
}

export const confirmOrder = (restaurant_id, order_id, minutes, selectedOrderID = 0, requestSource = 'orders') => {
    return (dispatch) => {

        dispatch(setOrdersLoading());

        return axios.post(API_URL+'/api/restaurants/'+restaurant_id+'/orders/confirm', {
            oid: order_id,
            confirm_minutes: minutes,
            requestSource: requestSource
        }).then(function(res) {

                if(requestSource == 'support'){

                    let orders = {
                        all: res.data.data.orders,
                        dispatchWarning: res.data.data.warningDispatchOrders,
                        restaurantWarning: res.data.data.warningRestaurantOrders
                    };

                    dispatch(setSupportOrders(orders, res.data.data.orderInformation, res.data.data.maxOrderId));

                }else{

                    let orders = {
                        incoming: [],
                        confirmed: [],
                        complete: []
                    };
    
                    for (let i = 0; i < res.data.data.orders.length; i++) {
                        if (res.data.data.orders[i].order_status == 'new') {
                            orders['incoming'].push(res.data.data.orders[i]);
                        } else if (res.data.data.orders[i].order_status == 'confirmed' || res.data.data.orders[i].order_status == 'ready') {
                            orders['confirmed'].push(res.data.data.orders[i]);
                        } else {
                            orders['complete'].push(res.data.data.orders[i]);
                        }
                    }
    
                    dispatch(setOrders(orders, res.data.data.orderInformation, res.data.data.maxoid));    

                }

                if(selectedOrderID != 0){
                    let selectedOrder = res.data.data.orders.find(o => o.id == selectedOrderID);
                    dispatch(setSelectedOrder(selectedOrder.id, selectedOrder, res.data.data.orderInformation[selectedOrder.id]));
                }

                dispatch(closeConfirmOrder());

            })
            .catch(function(err) {
                console.log("Err!");
                console.log(err);
                console.log(err.response);
                let messageToUse = '';
                let genericMessage = "There was an issue with the request.";
                if((err.response.status ?? 500) == 500){
                    messageToUse = genericMessage;
                }else{
                    messageToUse = err.response.data.message ?? genericMessage;
                }

                dispatch(confirmOrderFormError(messageToUse));
            });
    };
}

export const completeOrder = (order_id, did) => {
    return (dispatch) => {

        dispatch(completeOrderFormLoading());

        return axios.post(API_URL+'/api/dispatch/completeorder', {
            oid: order_id,
            dispatch_center_id: did,
        }).then(function(res) {

                dispatch(removeDispatchOrder(order_id, 'ready'));
                dispatch(setMaxOrderId(res.data.data.maxoid));
                dispatch(closeCompleteOrder());
                dispatch(resetSelectedOrder());
                dispatch(completeOrderFormReset());
            })
            .catch(function(err) {

                let response = readErrMsg(err);

                dispatch(completeOrderFormError(response.msg));
            });
    };
}

export const readyOrder = (restaurant_id, order_id, minutes, requestSource, selectedOrderID = 0) => {
    return (dispatch) => {

        dispatch(setOrdersLoading());

        return axios.post(API_URL+'/api/restaurants/'+restaurant_id+'/orders/ready', {
            oid: order_id,
            confirm_minutes: minutes,
            requestSource: requestSource
        }).then(function(res) {

                if(requestSource == 'support'){
                    let orders = {
                        all: res.data.data.orders,
                        dispatchWarning: res.data.data.warningDispatchOrders,
                        restaurantWarning: res.data.data.warningRestaurantOrders
                    };

                    dispatch(setSupportOrders(orders, res.data.data.orderInformation, res.data.data.maxOrderId));
                }else{
                    // determine which orders are placed in which lists
                    let orders = {
                        incoming: [],
                        confirmed: [],
                        ready: [],
                        complete: []
                    };

                    for (let i = 0; i < res.data.data.orders.length; i++) {
                        if (res.data.data.orders[i].order_status == 'confirmed') {
                            orders['confirmed'].push(res.data.data.orders[i]);
                        } else if (res.data.data.orders[i].order_status == 'ready') {
                            orders['ready'].push(res.data.data.orders[i]);
                        } else {
                            orders['complete'].push(res.data.data.orders[i]);
                        }
                    }
                    
                    dispatch(setOrders(orders, res.data.data.orderInformation));
                }

                if(selectedOrderID != 0){
                    let selectedOrder = res.data.data.orders.find(o => o.id == selectedOrderID);
                    dispatch(setSelectedOrder(selectedOrder.id, selectedOrder, res.data.data.orderInformation[selectedOrder.id]));
                }

                dispatch(closeConfirmOrder());

            })
            .catch(function(err) {
                console.log("Err!");
                console.log(err);
                console.log(err.response);
            });
    };
}

export const rejectOrderSupport = (order_id, max_warning_order_id) => {
    return (dispatch) => {

        dispatch(setOrdersLoading());

        return axios.post(API_URL+'/api/support/orders/reject', {
            oid: order_id
        }).then(function(res) {

                // determine which orders are placed in which lists
                let orders = {
                    all: res.data.data.orders,
                    dispatchWarning: res.data.data.warningDispatchOrders,
                    restaurantWarning: res.data.data.warningRestaurantOrders
                };

                if (max_warning_order_id != 0 && res.data.data.maxWarningOrderId != max_warning_order_id) {
                    // sound the chime
                    dispatch(playChime());
                }

                dispatch(setSupportOrders(orders, res.data.data.orderInformation, res.data.data.maxoid, res.data.data.maxWarningOrderId));
                dispatch(removeOrdersLoading());
                dispatch(resetSelectedOrder());
            })
            .catch(function(err) {
                console.log(err);
                console.log(err.response);
            });
    };
}

export const rejectOrder = (restaurant_id, order_id) => {
    return (dispatch) => {

        dispatch(setOrdersLoading());

        return axios.post(API_URL+'/api/restaurants/'+restaurant_id+'/orders/reject', {
            oid: order_id
        }).then(function(res) {

                // determine which orders are placed in which lists
                let orders = {
                    incoming: [],
                    confirmed: [],
                    complete: []
                };

                for (let i = 0; i < res.data.data.orders.length; i++) {
                    if (res.data.data.orders[i].order_status == 'new') {
                        orders['incoming'].push(res.data.data.orders[i]);
                    } else if (res.data.data.orders[i].order_status == 'confirmed' || res.data.data.orders[i].order_status == 'ready') {
                        orders['confirmed'].push(res.data.data.orders[i]);
                    } else {
                        orders['complete'].push(res.data.data.orders[i]);
                    }
                }

                dispatch(setOrders(orders, res.data.data.orderInformation, res.data.data.maxoid));
                dispatch(resetSelectedOrder());
            })
            .catch(function(err) {
                console.log(err);
                console.log(err.response);
            });
    };
}

export const resendGetSwiftOrder = (order_id) => {
    return (dispatch) => {

        dispatch(setOrdersLoading());

        return axios.post(API_URL+'/api/support/orders/getswift/resend', {
            oid: order_id
        }).then(function(res) {

                let orders = {
                    all: res.data.data.orders,
                    dispatchWarning: res.data.data.warningDispatchOrders,
                    restaurantWarning: res.data.data.warningRestaurantOrders
                };

                dispatch(setSupportOrders(orders, res.data.data.orderInformation, res.data.data.maxoid, res.data.data.maxWarningOrderId));
                dispatch(resetSelectedOrder());
            })
            .catch(function(err) {
                console.log("Err!");
                console.log(err);
                console.log(err.response);
            });
    };
}

export const resendReceipt = (order_id) => {
    return (dispatch) => {

        dispatch(setSelectedOrderReceiptLoading());

        return axios.get(API_URL+'/api/email/order', {
            params: {
                oid: order_id
              }
        }).then(function(res) {

                let email = res.data.data.email;

                dispatch(selectedOrderReceiptMessage("The customers receipt was successfully emailed to " + email));
                dispatch(removeSelectedOrderReceiptLoading());
            })
            .catch(function(err) {
                let response = readErrMsg(err);

                dispatch(selectedOrderReceiptError(response.msg));
                dispatch(removeSelectedOrderReceiptLoading());
            });
    };
}