import React from 'react';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { settingsFormUpdate, settingsFormError, savePizzaSettings } from '../../../../actions/menu/pizzas/settingsForm';
import { closeCustomPizzasUpdate } from '../../../../actions/modals';

import CloseIcon from '../../../common/modal/CloseIcon';

import CustomPizzas from './CustomPizzas';
import CustomPizzaCrusts from './CustomPizzaCrusts';
import CustomPizzaSauces from './CustomPizzaSauces';

class CustomPizzaSettings extends React.Component {

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.closeConfigModal = this.closeConfigModal.bind(this);
        this.updateCustomPizzas = this.updateCustomPizzas.bind(this);
        this.updateCustomPizzaCrusts = this.updateCustomPizzaCrusts.bind(this);
        this.updateCustomPizzaSauces = this.updateCustomPizzaSauces.bind(this);
        this.updateTabSelection = this.updateTabSelection.bind(this);

        this.state = {
            displayView: 0
        };
    }

    handleChange(event, newValue) {
        this.props.dispatch(settingsFormUpdate({ prop: 'error', value: '' }));
        this.props.dispatch(settingsFormUpdate({ prop: 'message', value: '' }));
        this.setState(() => ({displayView: newValue}))
    }

    getSteps() {
        return [
            'Sizes and Prices',
            'Toppings',
            'Crusts',
            'Sauces'
        ];
    }

    closeConfigModal() {
        this.props.dispatch(closeCustomPizzasUpdate());
        this.setState(() => ({displayView: 0}));
    }

    updateTabSelection(index) {
        this.props.dispatch(settingsFormUpdate({ prop: 'activeTab', value: index }));
    }

    updateCustomPizzas() {
        this.props.dispatch(settingsFormError(""));
        this.props.dispatch(savePizzaSettings(this.props.restaurantId, 'customPizzas', this.props.pizzaSettingsForm.customPizzas, this.props.pizzaSettingsForm.deletedCustomPizzas, true, this.props.pizzaSettingsForm.scrollPosition));
    }

    updateCustomPizzaCrusts() {
        this.props.dispatch(settingsFormError(""));
        this.props.dispatch(savePizzaSettings(this.props.restaurantId, 'customPizzaCrusts', this.props.pizzaSettingsForm.customPizzaCrusts, this.props.pizzaSettingsForm.deletedCustomPizzaCrusts, true, this.props.pizzaSettingsForm.scrollPosition));
    }

    updateCustomPizzaSauces() {
        this.props.dispatch(settingsFormError(""));
        this.props.dispatch(savePizzaSettings(this.props.restaurantId, 'customPizzaSauces', this.props.pizzaSettingsForm.customPizzaSauces, this.props.pizzaSettingsForm.deletedCustomPizzaSauces, true, this.props.pizzaSettingsForm.scrollPosition));
    }

    renderUpdateModalActiveTab() {
        if (this.props.loading) {
            return (
                <div className="page-loading">
                    <CircularProgress size={100} />
                </div>
            );
        }
        else {
            switch(this.state.displayView) {
                case 0:
                    return this.renderUpdateConfigCustomPizzas();

                case 1:
                    return this.renderUpdateConfigCustomCrusts();

                case 2:
                    return this.renderUpdateConfigCustomSauces();

                default:
                    return <div />;
            }
        }
    }

    renderUpdateFeedback() {
        return (
            <div>
                { this.props.pizzaSettingsForm.error && (
                    <Typography className="modal-error">
                        { this.props.pizzaSettingsForm.error }
                    </Typography>
                )}

                { this.props.pizzaSettingsForm.message && (
                    <Typography className="modal-success">
                        { this.props.pizzaSettingsForm.message }
                    </Typography>
                )}
            </div>
        );
    }

    renderUpdateConfigCustomPizzas() {
        return (
            <div>
                {this.renderUpdateFeedback()}

                <CustomPizzas />

                <Grid container>
                    <Grid item xs={12}>
                        <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom" onClick={this.updateCustomPizzas}>
                            Save Settings
                        </Button>
                    </Grid>
                </Grid>
            </div>
        );
    }

    renderUpdateConfigCustomCrusts() {
        return (
            <div>
                {this.renderUpdateFeedback()}

                <CustomPizzaCrusts />

                <Grid container>
                    <Grid item xs={12}>
                        <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom" onClick={this.updateCustomPizzaCrusts}>
                            Save Settings
                        </Button>
                    </Grid>
                </Grid>
            </div>
        );
    }

    renderUpdateConfigCustomSauces() {
        return (
            <div>
                {this.renderUpdateFeedback()}

                <CustomPizzaSauces />

                <Grid container>
                    <Grid item xs={12}>
                        <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom" onClick={this.updateCustomPizzaSauces}>
                            Save Settings
                        </Button>
                    </Grid>
                </Grid>
            </div>
        );
    }
    
    render() {
        return (
            <Modal
                aria-labelledby="update-custom-pizza-settings"
                aria-describedby="update-custom-pizza-settings"
                open={this.props.updateModalOpen}
                onClose={() => this.closeConfigModal()}
                className="modal_admin"
                disableBackdropClick
            >
                <div className="modal-member">
                    <Typography variant="h4" id="modal-title">
                        <CloseIcon/>
                    </Typography>
                    <Tabs
                        value={this.state.displayView}
                        onChange={this.handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        centered
                        style={{marginBottom: 20}}
                    >
                        <Tab label="Custom Pizzas" />
                        <Tab label="Crusts" />
                        <Tab label="Sauces" />
                    </Tabs>

                    <Typography variant="h4" id="modal-title" style={{marginBottom: 20}}>
                        Create your own Pizza Settings
                    </Typography>

                    {this.renderUpdateModalActiveTab()}
                </div>
            </Modal>
        );
    }
};

const mapStateToProps = (state, props) => {
    return {
        loading: state.pizzas.loading,
        restaurant: state.selectedRestaurant.restaurant,
        settings: state.pizzas.settings,
        pizzaSettingsForm: state.pizzaSettingsForm,
        updateModalOpen: state.modals.customPizzasUpdate
    };
};

export default connect(mapStateToProps)(CustomPizzaSettings);