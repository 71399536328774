// the default items that we can possibly filter by
const addonsDefaultState = {
    loading: true,
    addons: [],
    filter: ''
};

export default (state = addonsDefaultState, action) => {
    switch (action.type) {
        case 'SET_ADDONS':
            return {
                ...state,
                loading: false,
                addons: action.addons,
                filter: ''
            };

        case 'SET_ADDON_FILTER':
            return {
                ...state,
                filter: action.text
            };

        case 'SET_ADDONS_LOADING':
            return {
                ...state,
                loading: true
            };
            
        case 'RESET_ADDONS':
            return addonsDefaultState;

        default:
            return state;
    }
};