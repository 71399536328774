// the default items that we can possibly filter by
const loyaltyDefaultState = {
    loading: true,
    rewards: [],
    deletedRewards: [],
    users: [],
    deletedUsers: [],
    ratio: 0,
    filter: '',
    error: '',
    message: ''
};

export default (state = loyaltyDefaultState, action) => {
    switch (action.type) {
        case 'SET_LOYALTY_SETTINGS':
            return {
                ...state,
                loading: false,
                rewards: action.rewards,
                users: action.users,
                ratio: action.ratio,
                filter: ''
            };
            

        case 'SET_LOYALTY_FILTER':
            return {
                ...state,
                filter: action.text
            };

        case 'SET_LOYALTY_LOADING':
            return {
                ...state,
                loading: true
            };


        case 'LOYALTY_SETTING_UPDATE':
            return {
                ...state,
                [action.setting.prop]: action.setting.value
            };

        case 'LOYALTY_FORM_ERROR':
            return {
                ...state,
                message: '',
                error: action.message
            };
            
        case 'RESET_LOYALTY':
            return loyaltyDefaultState;

        default:
            return state;
    }
};