import React from 'react';
import { connect } from 'react-redux';

import Typography from '@material-ui/core/Typography';

import PromoCodesList from './PromoCodesList';
import PromoCodeForm from './PromoCodeForm';

import { promoCodeFormUpdate, promoCodeFormError, promoCodeFormReset, promoCodeFormSet } from '../../../actions/restaurant/promoCodeForm';
import { locationFormAddPromoCode, locationFormUpdatePromoCode, locationFormRemovePromoCode, locationFormUpdate } from '../../../actions/restaurant/locationForm';

import validateCurrency from '../../../utils/validateCurrency';

class PromoCodes extends React.Component {

    constructor(props) {
        super(props);

        this.handleAddPromoCodeView = this.handleAddPromoCodeView.bind(this);
        this.handleAddPromoCode = this.handleAddPromoCode.bind(this);
        this.handleUpdatePromoCodeView = this.handleUpdatePromoCodeView.bind(this);
        this.handleUpdatePromoCode = this.handleUpdatePromoCode.bind(this);
        this.handleCancelButton = this.handleCancelButton.bind(this);
        this.handleRemovePromoCode = this.handleRemovePromoCode.bind(this);

        this.state = {
            promoCodeDisplayView: 'list'
        };
    }

    componentDidMount() {
    }

    handleAddPromoCode() {
        //error checking
        if (this.props.promoCodeForm.code.trim() == '') {
            this.props.dispatch(promoCodeFormError('Please enter a promo code.'));
        }
        else if (!this.props.promoCodeForm.total_avail == "ALWAYS" && (!this.props.promoCodeForm.start_time || !this.props.promoCodeForm.end_time)) {
            this.props.dispatch(promoCodeFormError('Please enter a start and end date for your promo code if it will not always be available.'));
        }
        else if (!this.props.promoCodeForm.total_avail == "ALWAYS" && this.props.promoCodeForm.start_time > this.props.promoCodeForm.end_time) {
            this.props.dispatch(promoCodeFormError('Start date must be before end date.'));
        }
        else if(this.props.promoCodeForm.max_uses < 0){
            this.props.dispatch(promoCodeFormError('Quantity must be equal to or greater than 0 (0 meaning unlimited).'));
        }
        else if (!validateCurrency(this.props.promoCodeForm.total) || this.props.promoCodeForm.total <= 0) {
            this.props.dispatch(promoCodeFormError('Please enter a valid amount greater than 0.'));
        }
        else {
            let valid = true;
            for (let i = 0; i < this.props.locationForm.promoCodes.length; i++) {
                if (this.props.locationForm.promoCodes[i].code.toLowerCase() == this.props.promoCodeForm.code.toLowerCase()) {
                    this.props.dispatch(promoCodeFormError('Promo code is already used.'));
                    valid = false;
                }
            }

            if (valid) {
                this.props.dispatch(promoCodeFormError(""));
                
                // add the promoCode
                this.props.dispatch(locationFormAddPromoCode(this.props.promoCodeForm));

                // reset the form and return to the main list view
                this.props.dispatch(promoCodeFormReset());
                this.setState(() => ({ promoCodeDisplayView: 'list' }));
            }
        }
    }

    handleUpdatePromoCode() {
        //error checking
        if (this.props.promoCodeForm.code.trim() == '') {
            this.props.dispatch(promoCodeFormError('Please enter a promo code.'));
        }
        else if (!this.props.promoCodeForm.total_avail == "ALWAYS" && (!this.props.promoCodeForm.start_time || !this.props.promoCodeForm.end_time)) {
            this.props.dispatch(promoCodeFormError('Please enter a start and end date for your promo code if it will not always be available.'));
        }
        else if (!this.props.promoCodeForm.total_avail == "ALWAYS" && this.props.promoCodeForm.start_time > this.props.promoCodeForm.end_time) {
            this.props.dispatch(promoCodeFormError('Start date must be before end date.'));
        }
        else if(this.props.promoCodeForm.max_uses < 0){
            this.props.dispatch(promoCodeFormError('Quantity must be equal to or greater than 0 (0 meaning unlimited).'));
        }
        else if (!validateCurrency(this.props.promoCodeForm.total) || this.props.promoCodeForm.total <= 0) {
            this.props.dispatch(promoCodeFormError('Please enter a valid amount greater than 0.'));
        }
        else {
            let valid = true;
            for (let i = 0; i < this.props.locationForm.promoCodes.length; i++) {
                if (this.props.promoCodeForm.index != i && this.props.locationForm.promoCodes[i].code.toLowerCase() == this.props.promoCodeForm.code.toLowerCase()) {
                    this.props.dispatch(promoCodeFormError('Promo code is already used.'));
                    valid = false;
                }
            }

            if (valid) {
                this.props.dispatch(promoCodeFormError(""));
                
                // update the promo code
                this.props.dispatch(locationFormUpdatePromoCode(this.props.promoCodeForm, this.props.promoCodeForm.index, this.props.locationForm.promoCodes[this.props.promoCodeForm.index]));

                // reset the form and return to the main list view
                this.props.dispatch(promoCodeFormReset());
                this.setState(() => ({ promoCodeDisplayView: 'list' }));
            }
        }
    }

    handleRemovePromoCode(index) {
        if (confirm("Remove this promo code?")) {
            this.props.dispatch(locationFormRemovePromoCode(index));
        }
    }

    handleAddPromoCodeView() {
        this.props.dispatch(promoCodeFormReset());
        this.setState(() => ({ promoCodeDisplayView: 'new_promo_code' }));
    }

    handleUpdatePromoCodeView(promoCode, index) {
        this.props.dispatch(promoCodeFormSet(promoCode, index));
        this.props.dispatch(promoCodeFormUpdate({ prop: 'index', value: index })); 
        this.setState(() => ({ promoCodeDisplayView: 'update_promo_code' }));
    }

    handleCancelButton() {
        this.setState(() => ({ promoCodeDisplayView: 'list' }));
    }

    renderPromoCodeView() {
        switch (this.state.promoCodeDisplayView) {

            case 'new_promo_code':
                return (
                    <div>
                        <Typography className="modal-description">
                            Enter your promo code information and click 'Add Promo Code'. Please note: you will still need to save the promo code after adding it.
                        </Typography>
                        
                        <PromoCodeForm 
                            handleCancelButton={this.handleCancelButton}
                            handleSubmitPromoCode={this.handleAddPromoCode}
                            handleSubmitLabel={'Add Promo Code'}
                        />
                    </div>
                );

            case 'update_promo_code':
                return (
                    <div>
                        <Typography className="modal-description">
                            Update your promo code information and click 'Update Promo Code'. Please note: you will still need to save the promo code after updating it.
                        </Typography>

                        <PromoCodeForm 
                            handleCancelButton={this.handleCancelButton}
                            handleSubmitPromoCode={this.handleUpdatePromoCode}
                            handleSubmitLabel={'Update Promo Code'}
                        />
                    </div>
                );

            case 'list':
            default:
                return (
                    <div>
                        <Typography className="modal-description">
                            Enter your location's available promo codes below:
                        </Typography>

                        <PromoCodesList 
                            handleAddPromoCodeView={this.handleAddPromoCodeView}
                            handleUpdatePromoCodeView={this.handleUpdatePromoCodeView}
                            handleRemovePromoCode={this.handleRemovePromoCode}
                        />
                    </div>
                );
        }
    }

    render() {
        return (
            <div>
                <Typography variant="h3">
                    Promo Codes
                </Typography>

                {this.renderPromoCodeView()}
                {this.state.promoCodeDisplayView == 'list' && this.props.children}
            </div>
        );
    }
};

const mapStateToProps = (state, props) => {
    return {
        locationForm: state.locationForm,
        promoCodeForm: state.promoCodeForm
    };
};

export default connect(mapStateToProps)(PromoCodes);