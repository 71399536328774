const endTime = new Date();
endTime.setHours(23);
endTime.setMinutes(59);
endTime.setSeconds(59);

const startTime = new Date();
startTime.setHours(0);
startTime.setMinutes(0);
startTime.setSeconds(0);

const itemizedFormDefaultState = {
    start_time: startTime,
    end_time: endTime,
    order_status: 'all',
    sort_option: 'Name',
    sort_direction: 'Asc',
    message: '',
    error: '',
    initialSet: true,
    loading: false,
    location_id: 0,
    orderCount: 0,
    totalQuantity: 0,
    totalSales: 0,
    foodItems: [],
    pizzas: [],
    specials: [],
    addons: [],
    orders: [],
    hiddenRows: [],
};

export default (state = itemizedFormDefaultState, action) => {

    switch (action.type) {

        case 'REPORTS_ITEMIZED_FORM_UPDATE':
            if(action.search.prop == 'start_time_time' || action.search.prop == 'end_time_time'){
                let curTime = (action.search.prop == 'start_time_time') ? state.start_time: state.end_time;
                let newTime = new Date(curTime.getFullYear(), curTime.getMonth(),
                                curTime.getDate(), action.search.value.toDate().getHours(),
                                action.search.value.toDate().getMinutes(), action.search.value.toDate().getSeconds());
                
                action.search.value = newTime;
                action.search.prop = action.search.prop.substring(0, action.search.prop.length - 5);
            }
            return {
                ...state,
                [action.search.prop]: action.search.value,
                default: 0
            };

        case 'REPORTS_ITEMIZED_FORM_LOADING':
            return {
                ...state,
                loading: true,
            }

        case 'REPORTS_ITEMIZED_FORM_DONE_LOADING':
            return {
                ...state,
                loading: false,
            }

        case 'REPORTS_ITEMIZED_FORM_ERROR':
            return {
                ...state,
                message: '',
                error: action.message
            }

        case 'REPORTS_ITEMIZED_FORM_SUCCESS':
            return {
                ...state,
                error: '',
                message: action.message
            }

        case 'SET_CATEGORIZED_ORDERS':
            return {
                ...state,
                foodItems: action.foodItems,
                pizzas: action.pizzas,
                specials: action.specials,
                addons: action.addons,
                orderCount: action.orderCount,
                totalQuantity: action.totalQuantity,
                totalSales: action.totalSales,
            }

        case 'SET_ITEMIZED_ORDERS':
            return {
                ...state,
                orders: action.orders,
                orderCount: action.orderCount,
                totalQuantity: action.totalQuantity,
                totalSales: action.totalSales,
            }

        case 'TOGGLE_ITEMIZED_REPORT_ROW':

            state.hiddenRows[action.foodItemID] = (state.hiddenRows[action.foodItemID]) ? false: true;

            return {
                ...state,
                error: '',
                hiddenRows: state.hiddenRows
            }

        case 'REPORTS_ITEMIZED_FORM_RESET':
            return searchFormDefaultState;

        default:
            return state;
    }
};