import React from 'react';
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

class OrdersList extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    openOrderDetails(order) {
        this.props.toggleViewOrder(order, true);
    }

    getPaymentType(order) {
        if (order.payment_method == "in_store") {
            if (order.order_type == "delivery") {
                return "PAY AT DOOR";
            }
            else {
              return "PAY IN STORE";
            }
        }
        else {
            return "PAID ONLINE";
        }
    }

    getOrderStatus(order) {
        if (order.order_status == "rejected") {
            return (
                <span className="order-status-rejected">Rejected</span>
            );
        }
        else if (order.order_status == "complete") {
            return (
                <span className="order-status-completed">Completed</span>
            );
        }
        else if (order.order_status == "new") {
            return (
                <span>New</span>
            );
        }
        else if (order.order_status == "confirmed") {
            return (
                <span>Confirmed</span>
            );
        } else if (order.order_status == "ready") {
            return (
                <span>Ready</span>
            );
        } else {
            return (
                <span>{order.order_status}</span>
            );
        }
    }

    render() {
        if (this.props.orders.length > 0) {
            return (
                <Grid container>
                    <Grid item xs={12}>
                    {this.props.orders.map((order, index) => (
                        <div key={"item-"+index} onClick={() => { this.openOrderDetails(order) }}>
                            <Grid className="sortable-menu-item" container>
                                <Grid item xs={10} className="summary-item-info">
                                    <Typography>{order.order_id} - <strong>{this.getPaymentType(order)}</strong> - {order.time_received_formatted}</Typography>
                                    <Typography>{this.getOrderStatus(order)}</Typography>
                                    <Typography><strong>{(this.props.isDispatch) && order.restaurant_name+' - '}{order.order_type}</strong> - {order.name}</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    {(!this.props.isDispatch) && (
                                        <Typography className="summary-item-total">${(order.total) && parseFloat(order.total).toFixed(2)}</Typography>
                                    )}
                                </Grid>
                            </Grid>
                        </div>
                    ))}
                    </Grid>
                </Grid>
            );
        } else {
            return (
                <Grid container>
                    <Grid item xs={12}>
                        <Typography className="no-results">No orders.</Typography>
                    </Grid>
                </Grid>
            );
        }
    }
};

export default connect()(OrdersList);