const selectedDefaultState = {
    id: 0,
    category: {}
};

export default (state = selectedDefaultState, action) => {
    switch (action.type) {
        case 'SET_SELECTED_CATEGORY':
            
            return {
                ...state,
                category: action.category
            };

        default:
            return state;
    }
}