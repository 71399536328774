import React from 'react';
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { loyaltyRewardFormUpdate } from '../../../actions/restaurant/loyaltyRewardForm';

class LoyaltyRewardInfo extends React.Component {

    constructor(props) {
        super(props);
    }
    
    calculatePointsSpendTotal() {
        if (this.props.loyaltyRewardForm.points == "" || this.props.loyaltyRewardForm.points < 0) {
            return 0;
        }
        else {
            return parseFloat(parseFloat(this.props.loyaltyRewardForm.points) / parseFloat(this.props.ratio)).toFixed(2)

        }
    }
    
    render() {
        return (
            <Grid container>
                <Grid item xs={6} className="form-grid">
                    <TextField
                        label="Reward Name"
                        className="modal-input"
                        fullWidth={true}
                        value={this.props.loyaltyRewardForm.name}
                        onChange={(e) => { this.props.dispatch(loyaltyRewardFormUpdate({ prop: 'name', value: e.target.value })); }}
                        margin="normal"
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={6} className="form-grid">
                    <TextField
                        label="Value (in dollars)"
                        className="modal-input"
                        fullWidth={true}
                        type="number"
                        value={this.props.loyaltyRewardForm.value}
                        onChange={(e) => { this.props.dispatch(loyaltyRewardFormUpdate({ prop: 'value', value: e.target.value })); }}
                        margin="normal"
                        variant="outlined"
                    />
                </Grid>

                <Grid item xs={6} className="form-grid">
                    <TextField
                        label="Points"
                        className="modal-input"
                        fullWidth={true}
                        type="number"
                        value={this.props.loyaltyRewardForm.points}
                        onChange={(e) => { this.props.dispatch(loyaltyRewardFormUpdate({ prop: 'points', value: e.target.value })); }}
                        margin="normal"
                        variant="outlined"
                    />
                </Grid>

                <Grid item xs={6} className="form-image-container">
                    <Typography className="ratio-description">
                        Spend {this.calculatePointsSpendTotal()} Online
                    </Typography>
                </Grid>
            </Grid>
        );
    }
};

const mapStateToProps = (state, props) => {
    return {
        loyaltyRewardForm: state.loyaltyRewardForm,
        curUser: state.loggedUser.user
    };
};

export default connect(mapStateToProps)(LoyaltyRewardInfo);