import React from 'react';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import validateCurrency from '../../../../utils/validateCurrency';
import { addFoodItem, updateFoodItem, toggleFoodItemEnabled } from '../../../../actions/menu/fooditems/foodItems';
import { foodItemFormUpdate, foodItemFormError, foodItemFormReset } from '../../../../actions/menu/fooditems/foodItemForm';
import { openFoodItemUpdate, closeFoodItemUpdate } from '../../../../actions/modals';
import filterFoodItems from '../../../../selectors/foodItems';

import CloseIcon from '../../../common/modal/CloseIcon';

import Info from './Info';
import Prices from './Prices';
import Choices from './Choices';
import Addons from './Addons';

class UpdateFoodItem extends React.Component {

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.closeUpdateModal = this.closeUpdateModal.bind(this);
        this.updateFoodItemInfo = this.updateFoodItemInfo.bind(this);
        this.updateFoodItemPrices = this.updateFoodItemPrices.bind(this);
        this.updateFoodItemChoices = this.updateFoodItemChoices.bind(this);
        this.updateFoodItemAddons = this.updateFoodItemAddons.bind(this);

        this.state = {
            displayView: 0
        };
    }

    handleChange(event, newValue) {
        this.props.dispatch(foodItemFormUpdate({ prop: 'error', value: '' }));
        this.props.dispatch(foodItemFormUpdate({ prop: 'message', value: '' }));
        this.setState(() => ({displayView: newValue}))
    }

    closeUpdateModal() {
        this.props.dispatch(closeFoodItemUpdate());
        this.setState(() => ({displayView: 0}));
    }

    updateFoodItemInfo() {

        // perform some error checking
        if (this.props.foodItemForm.name.trim() == "") {
            this.props.dispatch(foodItemFormError("Please enter a food item name."));
        }else if(this.props.foodItemForm.saveSchedule && this.props.foodItemForm.scheduleName.trim() == ""){
            this.props.dispatch(foodItemFormError("Please enter a name for the availability schedule."));
        }else if(this.props.foodItemForm.saveSchedule && this.props.restaurant.availability_schedules.map((schedule) => {return schedule.name}).includes(this.props.foodItemForm.scheduleName.trim())){
            this.props.dispatch(foodItemFormError("An availability schedule with that name already exists."));
        }else{

            // clear the error and attempt to update the food item info
            this.props.dispatch(foodItemFormError(""));
            this.props.dispatch(updateFoodItem(this.props.restaurantId, this.props.categoryId, this.props.foodItem.id, 'info', this.props.foodItemForm));
        }
    }

    updateFoodItemPrices() {

        let valid = true;
        let multiplePriceCount = 0;

        // perform error checking on the food prices
        if (this.props.foodItemForm.hasMultiplePrices) {

            // go through each price and determine if it is valid
            for (let i = 0; i < this.props.foodItemForm.prices.length; i++) {

                // ignore any blank rows
                if (this.props.foodItemForm.prices[i].name != '' || this.props.foodItemForm.prices[i].price != '') {
                    if (!validateCurrency(this.props.foodItemForm.prices[i].price) || this.props.foodItemForm.prices[i].name.match(/^\s*$/)) {
                        valid = false;
                        this.props.dispatch(foodItemFormError("Please ensure that all prices have a valid name and price."));
                    }
                    else {
                        multiplePriceCount++;
                    }
                }
            }

            if (multiplePriceCount <= 0 && valid) {
                valid = false;
                this.props.dispatch(foodItemFormError("Please enter at least one price."));
            }
        }
        else {
            if (!validateCurrency(this.props.foodItemForm.singlePrice)) {
                this.props.dispatch(foodItemFormError("Please enter a valid food item price."));
                valid = false;
            }
        }

        if (valid) {
            this.props.dispatch(foodItemFormError(""));
            
            // clean out any multiple prices that are empty
            if (this.props.foodItemForm.hasMultiplePrices) {
                let trimmedPrices = this.props.foodItemForm.prices.filter((price) => {
                    return price.name != '' && price.price != '';
                });
                this.props.dispatch(foodItemFormUpdate({ prop: 'prices', value: trimmedPrices }));
            }

            this.props.dispatch(updateFoodItem(this.props.restaurantId, this.props.categoryId, this.props.foodItem.id, 'prices', this.props.foodItemForm));
        }
    }

    updateFoodItemChoices() {
        this.props.dispatch(updateFoodItem(this.props.restaurantId, this.props.categoryId, this.props.foodItem.id, 'choices', this.props.foodItemForm));
    }

    updateFoodItemAddons() {

        let valid = true;

        // go through each price and determine if it is valid
        for (let i = 0; i < this.props.foodItemForm.addons.length; i++) {

            // ignore any blank rows
            if (this.props.foodItemForm.addons[i].name != '' || this.props.foodItemForm.addons[i].price != '') {
                if (!validateCurrency(this.props.foodItemForm.addons[i].price) || this.props.foodItemForm.addons[i].name.match(/^\s*$/)) {
                    valid = false;
                    this.props.dispatch(foodItemFormError("Please ensure that all addons have a valid name and price."));
                }
            }
        }

        if (valid) {
            this.props.dispatch(foodItemFormError(""));
            
            // clean out any addons that are empty
            let trimmedAddons = this.props.foodItemForm.addons.filter((addon) => {
                return addon.name != '' && addon.price != '';
            });
            this.props.dispatch(foodItemFormUpdate({ prop: 'addons', value: trimmedAddons }));

            this.props.dispatch(updateFoodItem(this.props.restaurantId, this.props.categoryId, this.props.foodItem.id, 'addons', this.props.foodItemForm));
        }
    }

    renderUpdateModalActiveTab() {
        if (this.props.loading) {
            return (
                <div className="page-loading">
                    <CircularProgress size={100} />
                </div>
            );
        }
        else {
            switch(this.state.displayView) {
                case 0:
                    return this.renderUpdateModalInfo();

                case 1:
                    return this.renderUpdateModalPrices();

                case 2:
                    return this.renderUpdateModalChoices();

                case 3:
                    return this.renderUpdateModalAddons();
            }
        }
    }

    renderUpdateFeedback() {
        return (
            <div>
                { this.props.foodItemForm.error && (
                    <Typography className="modal-error">
                        { this.props.foodItemForm.error }
                    </Typography>
                )}

                { this.props.foodItemForm.message && (
                    <Typography className="modal-success">
                        { this.props.foodItemForm.message }
                    </Typography>
                )}
            </div>
        );
    }

    renderUpdateModalInfo() {
        return (
            <div>
                {this.renderUpdateFeedback()}

                <Info />

                <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom" onClick={this.updateFoodItemInfo}>
                    Update Food Item
                </Button>
            </div>
        );
    }

    renderUpdateModalPrices() {
        return (
            <div>
                {this.renderUpdateFeedback()}

                <Typography className="modal-description">
                    Enter any available food price options that you have, such as 'Small', 'Medium' and 'Large'. 
                    Please note, you do not need to enter a name if there is only one size.
                </Typography>

                <Prices />

                <Grid container>
                    <Grid item xs={12}>
                        <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom" onClick={this.updateFoodItemPrices}>
                            Update Food Item
                        </Button>
                    </Grid>
                </Grid>
            </div>
        );
    }

    renderUpdateModalChoices() {
        return (
            <div>
                {this.renderUpdateFeedback()}
                <Choices>
                    <Grid container>
                        <Grid item xs={12}>
                            <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom" onClick={this.updateFoodItemChoices}>
                                Update Food Item
                            </Button>
                        </Grid>
                    </Grid>
                </Choices>             
            </div>
        );
    }

    renderUpdateModalAddons() {
        return (
            <div>
                {this.renderUpdateFeedback()}
                <Addons>
                    <Grid container>
                        <Grid item xs={12}>
                            <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom" onClick={this.updateFoodItemAddons}>
                                Update Food Item
                            </Button>
                        </Grid>
                    </Grid>
                </Addons>
            </div>
        );
    }
    
    render() {
        return (
            <Modal
                aria-labelledby="food-item-update"
                aria-describedby="food-item-update"
                open={this.props.updateModalOpen}
                onClose={() => this.closeUpdateModal()}
                className="modal_admin"
                disableBackdropClick
            >
                <div className="modal-member">
                    <Typography variant="h4" id="modal-title">
                        <CloseIcon/>
                    </Typography>
                    <Tabs
                        value={this.state.displayView}
                        onChange={this.handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        centered
                    >
                        <Tab label="General Info" />
                        <Tab label="Prices" />
                        <Tab label="Choices" />
                        <Tab label="Addons" />
                    </Tabs>

                    <Typography variant="h2">
                        Update {this.props.foodItem.name}
                    </Typography>

                    {this.renderUpdateModalActiveTab()}
                </div>
            </Modal>
        );
    }
};

const mapStateToProps = (state, props) => {
    return {
        loading: state.foodItems.loading,
        restaurant: state.selectedRestaurant.restaurant,
        foodItems: filterFoodItems(state.foodItems.foodItems, {text: state.foodItems.filter}),
        foodItemForm: state.foodItemForm,
        createModalOpen: state.modals.foodItemCreate,
        updateModalOpen: state.modals.foodItemUpdate,
    };
};

export default connect(mapStateToProps)(UpdateFoodItem);