
import React from 'react';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import SortableFoodItemModifierItem from './SortableFoodItemModifierItem';

import { foodItemModifierFormSet, foodItemModifierFormUpdate } from '../../../../actions/menu/fooditems/foodItemModifierForm';

class FoodItemModifierForm extends React.Component {

    constructor(props) {
        super(props);

        this.setSelectedModifier = this.setSelectedModifier.bind(this);

    }

    componentDidMount() {
    }

    setSelectedModifier(modifierId) {

        // filter out the item we need
        const modifiers = this.props.modifiers.filter((modifier) => {
            return modifier.id == modifierId;
        });

        let foodItemModifier = {
            "id": 0,
            "min": modifiers[0].min,
            "max": modifiers[0].max,
            "modifier": modifiers[0]
        };

        this.props.dispatch(foodItemModifierFormSet(foodItemModifier));

    }

    render(){
        return (
            <Grid container>
                {this.props.foodItemModifierForm.error && (
                    <Grid item xs={12} className="form-grid">
                        <Typography className="modal-error">
                            { this.props.foodItemModifierForm.error }
                        </Typography>
                    </Grid>
                )}
                <Grid item xs={12} className="form-grid">
                    <div className="previous-choice-window">
                        <Typography variant="h4">
                            Choose a base modifier
                        </Typography>
                        <Grid container>
                            <Grid item xs={4} className="form-grid">
                                <Select
                                    fullWidth={true}
                                    labelId="type"
                                    value={this.props.foodItemModifierForm.modifier.id}
                                    onChange={(e) => { this.setSelectedModifier(e.target.value); }}
                                >
                                    {this.props.modifiers.filter((modifier) => {
                                        return modifier.enabled == 1 || modifier.id == this.props.foodItemModifierForm.modifier.id;
                                    }).map((modifier) => (
                                        <MenuItem key={modifier.id} value={modifier.id}>{modifier.name}</MenuItem>
                                    ))}
                                </Select>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
                <Grid item xs={12} className="form-grid">
                    <Typography className="modal-description">
                        Name
                    </Typography>
                    <TextField
                        label="Name"
                        className="modal-input"
                        fullWidth={true}
                        value={this.props.foodItemModifierForm.modifier.name}
                        disabled={true}
                        margin="normal"
                    />
                </Grid>
                <Grid item container>
                    <Grid item xs={3} className="form-grid">
                        <TextField
                            label="Min"
                            className="modal-input"
                            fullWidth={true}
                            value={this.props.foodItemModifierForm.min}
                            onChange={(e) => { this.props.dispatch(foodItemModifierFormUpdate({ prop: 'min', value: e.target.value })); }}
                            margin="normal"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={3} className="form-grid">
                        <TextField
                            label="Max"
                            className="modal-input"
                            fullWidth={true}
                            value={this.props.foodItemModifierForm.max}
                            onChange={(e) => { this.props.dispatch(foodItemModifierFormUpdate({ prop: 'max', value: e.target.value })); }}
                            margin="normal"
                            variant="outlined"
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} className="form-grid">
                    <Typography variant="h3">
                        Modifier Items
                    </Typography>
                </Grid>
                {this.props.foodItemModifierForm.modifier.items.length > 0 && (
                    <Grid item xs={12} className="form-grid">
                        <SortableFoodItemModifierItem
                            items={this.props.foodItemModifierForm.modifier.items}
                        />
                    </Grid>
                )}
                <Grid item container>
                    <Grid item xs={6} className="form-grid-right">
                        <Button variant="contained" fullWidth={true} color="secondary" className="modal-button-bottom btn-secondary" onClick={this.props.handleCancelButton}>
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item xs={6} className="form-grid-left">
                        <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom" onClick={this.props.handleSubmitChoice}>
                            {this.props.handleSubmitLabel}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        );
    }

};

const mapStateToProps = (state, props) => {
    return {
        foodItemModifierForm: state.foodItemModifierForm,
        modifiers: state.modifiers.modifiers
    };
};

export default connect(mapStateToProps)(FoodItemModifierForm);
