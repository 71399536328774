export const setSelectedRestaurant = ({restaurant}) => ({
    type: 'SET_SELECTED_RESTAURANT',
    restaurant
});

export const setSelectedRestaurantAvailabilitySchedules = (schedules) => {
    return {
        type: 'SET_SELECTED_RESTAURANT_AVAILABILITY_SCHEDULES',
        schedules
    }
};

export const resetSelectedRestaurant = () => ({
    type: 'RESET_SELECTED_RESTAURANT'
});