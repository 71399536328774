export const crustFormUpdate = ({ prop, value }) => {
    return {
        type: 'CRUST_FORM_UPDATE',
        crust: { prop, value }
    };
};

export const crustFormUpdatePrice = ({ prop, index, value }) => {

    return {
        type: 'CRUST_FORM_UPDATE_PRICE',
        item: { prop, index, value }
    };
};

export const crustFormResetPrices = (index) => {

    return {
        type: 'CRUST_FORM_RESET_PRICES'
    }
}

export const crustFormError = (message) => {

    return {
        type: 'CRUST_FORM_ERROR',
        message
    };
};

export const crustFormSuccess = (message) => {

    return {
        type: 'CRUST_FORM_SUCCESS',
        message
    };
};

export const crustFormSet = (crust, index) => {
    
    return {
        type: 'CRUST_FORM_SET',
        crust: {
            ...crust,
            index
        }
    };
}

export const crustFormReset = () => {
    
    return {
        type: 'CRUST_FORM_RESET',
    };
}

export const populateNewCrustFormSizes = (sizes) => {

    return (dispatch) => {

        let prices = [];
        for(let i = 0; i < sizes.length; i++) {
            prices.push({
                size_id: sizes[i].id,
                size_name: sizes[i].size_name,
                price: 0.00
            });
        }

        dispatch(crustFormUpdate({
            prop: 'prices',
            value: prices
        }));
    };
}

export const populateUpdateCrustFormSizes = (crust) => {

    return (dispatch) => {

        let prices = [];
        for(let i = 0; i < crust.prices.length; i++) {
            prices.push({
                id: crust.prices[i].id,
                size_id: crust.prices[i].size_id,
                size_name: crust.prices[i].size_name,
                price: crust.prices[i].price
            });
        }

        dispatch(crustFormUpdate({
            prop: 'prices',
            value: prices
        }));
    };
}