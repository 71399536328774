import React from 'react';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import { updateLoyaltyPoints } from '../../../actions/restaurant/loyalty';
import { loyaltyPointsFormError, loyaltyPointsFormReset, loyaltyPointsFormUpdate } from '../../../actions/restaurant/loyaltyPointsForm';
import { openLoyaltyPointsUpdate, closeLoyaltyPointsUpdate } from '../../../actions/modals';

import CloseIcon from '../../common/modal/CloseIcon';

class UpdateLoyaltyUser extends React.Component {

    constructor(props) {
        super(props);

        this.toggleModal = this.toggleModal.bind(this);
        this.submitUpdateLoyaltyPoints = this.submitUpdateLoyaltyPoints.bind(this);
    }

    toggleModal(isOpen) {
        if (isOpen) {
            this.props.dispatch(loyaltyPointsFormReset());
            this.props.dispatch(openLoyaltyPointsUpdate());
        }
        else {
            this.props.dispatch(closeLoyaltyPointsUpdate());
        }
    }

    submitUpdateLoyaltyPoints() {

        // perform some error checking
        if ((typeof this.props.loyaltyPointsForm.points === 'string' && this.props.loyaltyPointsForm.points.trim() == '') || this.props.loyaltyPointsForm.points != parseInt(this.props.loyaltyPointsForm.points, 10) || this.props.loyaltyPointsForm.points <= 0) {

            // warning phone isn't valid
            this.props.dispatch(loyaltyPointsFormError("Please enter a valid points value greater than 0."));
        }
        else if ((typeof this.props.loyaltyPointsForm.points_used === 'string' && this.props.loyaltyPointsForm.points_used.trim() == '') || this.props.loyaltyPointsForm.points_used != parseInt(this.props.loyaltyPointsForm.points_used, 10) || this.props.loyaltyPointsForm.points_used <= 0) {

            // warning phone isn't valid
            this.props.dispatch(loyaltyPointsFormError("Please enter a valid points used value greater than 0."));
        }
        else {
            this.props.dispatch(loyaltyPointsFormError(""));
            this.props.dispatch(updateLoyaltyPoints(this.props.restaurantId, 'update', this.props.loyaltyPointsForm));
        }
    }
    
    render() {
        return (
            <Modal
                aria-labelledby="loyaltyPoints-update"
                aria-describedby="loyaltyPoints-update"
                open={this.props.modalOpen}
                onClose={() => this.toggleModal(false)}
                className="modal_admin"
                disableBackdropClick
            >
                <div className="modal-member">
                    <Typography variant="h4" id="modal-title">
                        <CloseIcon/>
                    </Typography>
                    <Typography variant="h2">
                        Update Loyalty Points - {(this.props.user) && this.props.user.user.email}
                    </Typography>

                    { this.props.loyaltyPointsForm.error && (
                        <Typography className="modal-error">
                            { this.props.loyaltyPointsForm.error }
                        </Typography>
                    )}

                    { this.props.loyaltyPointsForm.message && (
                        <Typography className="modal-success">
                            { this.props.loyaltyPointsForm.message }
                        </Typography>
                    )}          

                    {(this.props.loading) && (
                        <div className="page-loading">
                            <CircularProgress size={100} />
                        </div>
                    )}
                    {(!this.props.loading) && (
                        <div>
                            <Grid container>
                                <Grid item xs={6} className="form-grid">
                                    <TextField
                                        label="Set points to this value"
                                        className="modal-input"
                                        fullWidth={true}
                                        type="number"
                                        value={this.props.loyaltyPointsForm.points}
                                        onChange={(e) => { this.props.dispatch(loyaltyPointsFormUpdate({ prop: 'points', value: e.target.value })); }}
                                        margin="normal"
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={6} className="form-grid">
                                    <TextField
                                        label="Points Used"
                                        className="modal-input"
                                        fullWidth={true}
                                        type="number"
                                        value={this.props.loyaltyPointsForm.points_used}
                                        onChange={(e) => { this.props.dispatch(loyaltyPointsFormUpdate({ prop: 'points_used', value: e.target.value })); }}
                                        margin="normal"
                                        variant="outlined"
                                    />
                                </Grid>
                            </Grid>

                            <Button variant="contained" fullWidth={true} className="modal-button-bottom" onClick={this.submitUpdateLoyaltyPoints}>
                                Update Loyalty Points
                            </Button>
                        </div>
                    )}
                </div>
            </Modal>
        );
    }
};

const mapStateToProps = (state, props) => {
    return {
        loading: state.loyaltyPointsForm.loading,
        restaurant: state.selectedRestaurant.restaurant,
        loyaltyPointsForm: state.loyaltyPointsForm,
        modalOpen: state.modals.loyaltyPointsUpdate
    };
};

export default connect(mapStateToProps)(UpdateLoyaltyUser);