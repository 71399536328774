import React from 'react';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import TextField from '@material-ui/core/TextField';

import { searchManagers } from '../../../actions/restaurant/managers';
import { managerFormUpdate, managerFormError, managerFormReset } from '../../../actions/restaurant/managerForm';
import { openManagerAdd, closeManagerAdd } from '../../../actions/modals';

import CloseIcon from '../../common/modal/CloseIcon';

class AddManager extends React.Component {

    constructor(props) {
        super(props);

        this.toggleModal = this.toggleModal.bind(this);
        this.searchManagers = this.searchManagers.bind(this);
    }

    toggleModal(isOpen) {
        if (isOpen) {
            this.props.dispatch(managerFormReset());
            this.props.dispatch(openManagerAdd());
        }
        else {
            this.props.dispatch(closeManagerAdd());
        }
    }

    searchManagers() {
        if (this.props.managerForm.email.trim() == '' || this.props.managerForm.first_name.trim() == '' || this.props.managerForm.last_name.trim() == '') {
            this.props.dispatch(managerFormError("Please enter a valid first name, last name and email address."));
        }
        else {
            this.props.dispatch(managerFormError(""));
            this.props.dispatch(searchManagers(this.props.restaurantId, this.props.managerForm));
        }
    }

    renderNoManagersFound() {
        return (
            <Grid item xs={12}>
                <Grid item xs={12} className="form-grid-right">
                    <Typography variant="h2">
                        User Not Found
                    </Typography>
                    <Typography className="modal-description">
                        Email was not found for a user in our system. If you want this user to manage your restaurant, click the button below and we will send them an e-mail to connect to our system.
                    </Typography>
                </Grid>

                <Grid item xs={12} className="form-grid-right">
                    <Typography variant="h2">
                        Optional Password
                    </Typography>
                    <Typography className="modal-description">
                        If you add the user's password here, they will not be e-mailed to confirm their account.
                    </Typography>

                    <TextField
                        label="Password"
                        className="modal-input"
                        fullWidth={true}
                        value={this.props.managerForm.password}
                        onChange={(e) => { this.props.dispatch(managerFormUpdate({ prop: 'password', value: e.target.value })); }}
                        margin="normal"
                        variant="outlined"
                    />
                </Grid>

                <Grid container>
                    <Grid item xs={4} className="form-grid-right">
                        <Button variant="contained" fullWidth={true} className="modal-button-bottom button-green" onClick={() => this.props.enableNewManager(this.props.managerForm, 'staff')}>
                            Add Staff User
                        </Button>
                    </Grid>
                    <Grid item xs={4} className="form-grid-right">
                        <Button variant="contained" fullWidth={true} className="modal-button-bottom" onClick={() => this.props.enableNewManager(this.props.managerForm, 'manager')}>
                            Add Manager
                        </Button>
                    </Grid>
                    <Grid item xs={4} className="form-grid-left">
                        <Button variant="contained" fullWidth={true} className="modal-button-bottom btn-secondary" onClick={() => this.props.enableNewManager(this.props.managerForm, 'admin')}>
                            Add Admin
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    renderFoundManagers() {
        return (
            <Grid item xs={12}>
                <Grid item xs={12} className="form-grid-right">
                    <Typography variant="h2">
                        Found Users
                    </Typography>
                </Grid>

                <Grid item xs={12} className="sortable-choice-list">
                    {this.props.managerForm.foundManagers.map((manager, index) => (
                        <div key={'manager-'+index}>
                            <Grid key={"item-"+index} className="sortable-menu-item" container>
                                <Grid item xs={6} className="list-name">
                                    <Typography>{manager.first_name} {manager.last_name} ({manager.email})</Typography>
                                </Grid>

                                <Grid item xs={2} className="form-grid">
                                    {!manager.isManager && (
                                        <Button fullWidth={true} variant="contained" color="primary" className="button-green" onClick={() => this.props.enableManager(manager, 'staff')}>
                                            Enable Staff User
                                        </Button>
                                    )}
                                </Grid>

                                <Grid item xs={2} className="form-grid">
                                    {!manager.isManager && (
                                        <Button fullWidth={true} variant="contained" color="primary" onClick={() => this.props.enableManager(manager, 'manager')}>
                                            Enable Manager
                                        </Button>
                                    )}
                                </Grid>

                                <Grid item xs={2} className="form-grid">
                                    {manager.isManager && (
                                        <Button fullWidth={true} variant="contained" color="primary" disabled={true}>
                                            Already a Manager
                                        </Button>
                                    )}
                                    {!manager.isManager && (
                                        <Button fullWidth={true} variant="contained" color="secondary" onClick={() => this.props.enableManager(manager, 'admin')}>
                                            Enable Admin
                                        </Button>
                                    )}
                                </Grid>
                            </Grid>
                        </div>
                    ))}
                </Grid>
            </Grid>
        );
    }

    render() {
        return (
            <Modal
                aria-labelledby="manager-add"
                aria-describedby="manager-add"
                open={this.props.modalOpen}
                onClose={() => this.toggleModal(false)}
                className="modal_admin"
                disableBackdropClick
            >
                <div className="modal-member">
                    <Typography variant="h4" id="modal-title">
                        <CloseIcon/>
                    </Typography>
                    <Typography variant="h2">
                        Add a Restaurant Manager
                    </Typography>
                    <Typography className="modal-description">
                        Please enter your the manager email that you are searching for, and an optional first and last name that will be populated if this is a new user.
                    </Typography>

                    { this.props.managerForm.error && (
                        <Typography className="modal-error">
                            { this.props.managerForm.error }
                        </Typography>
                    )}

                    {(this.props.loading) && (
                        <div className="page-loading">
                            <CircularProgress size={100} />
                        </div>
                    )}
                    {(!this.props.loading) && (
                        <Grid container>
                            <Grid item xs={6} className="form-grid grid-center">
                                <TextField
                                    label="First Name"
                                    className="modal-input"
                                    fullWidth={true}
                                    value={this.props.managerForm.first_name}
                                    onChange={(e) => { this.props.dispatch(managerFormUpdate({ prop: 'first_name', value: e.target.value })); }}
                                    margin="normal"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={6} className="form-grid grid-center">
                                <TextField
                                    label="Last Name"
                                    className="modal-input"
                                    fullWidth={true}
                                    value={this.props.managerForm.last_name}
                                    onChange={(e) => { this.props.dispatch(managerFormUpdate({ prop: 'last_name', value: e.target.value })); }}
                                    margin="normal"
                                    variant="outlined"
                                />
                            </Grid>

                            <Grid item xs={12} className="form-grid grid-center">
                                <TextField
                                    label="Email"
                                    className="modal-input"
                                    fullWidth={true}
                                    value={this.props.managerForm.email}
                                    onChange={(e) => { this.props.dispatch(managerFormUpdate({ prop: 'email', value: e.target.value })); }}
                                    margin="normal"
                                    variant="outlined"
                                />
                            </Grid>


                            <Grid item xs={12}>
                                <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom" onClick={this.searchManagers}>
                                    Search
                                </Button>
                            </Grid>

                            {this.props.managerForm.foundManagers.length > 0 && this.renderFoundManagers()}

                            {this.props.managerForm.managersEmpty && this.renderNoManagersFound()}
                        </Grid>
                    )}
                </div>
            </Modal>
        );
    }
};

const mapStateToProps = (state, props) => {
    return {
        loading: state.managerForm.loading,
        restaurant: state.selectedRestaurant.restaurant,
        managerForm: state.managerForm,
        modalOpen: state.modals.managerAdd,
    };
};

export default connect(mapStateToProps)(AddManager);