import React from 'react';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';

import { updateLoyaltyReward } from '../../../actions/restaurant/loyalty';
import { loyaltyRewardFormError, loyaltyRewardFormReset } from '../../../actions/restaurant/loyaltyRewardForm';
import { openLoyaltyRewardUpdate, closeLoyaltyRewardUpdate } from '../../../actions/modals';

import CloseIcon from '../../common/modal/CloseIcon';

import Info from './LoyaltyRewardInfo';

class UpdateLoyaltyReward extends React.Component {

    constructor(props) {
        super(props);

        this.toggleUpdateModal = this.toggleUpdateModal.bind(this);
        this.submitUpdateLoyaltyReward = this.submitUpdateLoyaltyReward.bind(this);
    }

    toggleUpdateModal(isOpen) {
        if (isOpen) {
            this.props.dispatch(loyaltyRewardFormReset());
            this.props.dispatch(openLoyaltyRewardUpdate());
        }
        else {
            this.props.dispatch(closeLoyaltyRewardUpdate());
        }
    }

    submitUpdateLoyaltyReward() {

        // perform some error checking
        if (this.props.loyaltyRewardForm.name.trim() == "") {

            // required info isn't present
            this.props.dispatch(loyaltyRewardFormError("Please enter a reward name."));
        }
        else if ((typeof this.props.loyaltyRewardForm.value === 'string' && !this.props.loyaltyRewardForm.value.match(/^\d+(\.\d+)?$/)) || 
            parseFloat(this.props.loyaltyRewardForm.value) <= 0) {
            
            // phone numbers entered aren't valid
            this.props.dispatch(loyaltyRewardFormError("Please enter a valid dollar value greater than $0.00."));
        }
        else if ((typeof this.props.loyaltyRewardForm.points === 'string' && this.props.loyaltyRewardForm.points.trim() == '') || this.props.loyaltyRewardForm.points != parseInt(this.props.loyaltyRewardForm.points, 10)) {

            // warning phone isn't valid
            this.props.dispatch(loyaltyRewardFormError("Please enter a valid points value greater than 0."));
        }
        else {
            this.props.dispatch(loyaltyRewardFormError(""));
            this.props.dispatch(updateLoyaltyReward(this.props.restaurantId, this.props.reward.id, 'info', this.props.loyaltyRewardForm));
        }
    }
    
    render() {
        return (
            <Modal
                aria-labelledby="loyaltyReward-add"
                aria-describedby="loyaltyReward-add"
                open={this.props.updateModalOpen}
                onClose={() => this.toggleUpdateModal(false)}
                className="modal_admin"
                disableBackdropClick
            >
                <div className="modal-member">
                    <Typography variant="h4" id="modal-title">
                        <CloseIcon/>
                    </Typography>
                    <Typography variant="h2">
                        Update {(this.props.reward && this.props.reward.name) ? this.props.reward.name : 'Reward'}
                    </Typography>

                    { this.props.loyaltyRewardForm.error && (
                        <Typography className="modal-error">
                            { this.props.loyaltyRewardForm.error }
                        </Typography>
                    )}

                    { this.props.loyaltyRewardForm.message && (
                        <Typography className="modal-success">
                            { this.props.loyaltyRewardForm.message }
                        </Typography>
                    )}

                    {(this.props.loading) && (
                        <div className="page-loading">
                            <CircularProgress size={100} />
                        </div>
                    )}
                    {(!this.props.loading) && (
                        <div>
                            <Info
                                ratio={this.props.ratio}
                            />
                    
                            <Button variant="contained" fullWidth={true} className="modal-button-bottom" onClick={this.submitUpdateLoyaltyReward}>
                                Update Reward
                            </Button>
                        </div>
                    )}
                </div>
            </Modal>
        );
    }
};

const mapStateToProps = (state, props) => {
    return {
        loading: state.loyaltyRewardForm.loading,
        restaurant: state.selectedRestaurant.restaurant,
        loyaltyRewardForm: state.loyaltyRewardForm,
        updateModalOpen: state.modals.loyaltyRewardUpdate
    };
};

export default connect(mapStateToProps)(UpdateLoyaltyReward);