import React from 'react';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import moment from 'moment';

import { searchFormUpdate, searchFormError, searchFormReset } from '../../actions/reports/searchForm';
import { openSearchReports, closeSearchReports } from '../../actions/modals';

import CloseIcon from '../../components/common/modal/CloseIcon';

class SearchOrders extends React.Component {

    constructor(props) {
        super(props);

        this.toggleModal = this.toggleModal.bind(this);
    }

    toggleModal(isOpen) {
        if (isOpen) {
            this.props.dispatch(searchFormReset());
            this.props.dispatch(openSearchReports());
        }
        else {
            this.props.dispatch(closeSearchReports());
        }
    }
    
    render() {
        return (
            <Modal
                aria-labelledby="loyaltyReward-add"
                aria-describedby="loyaltyReward-add"
                open={this.props.modalOpen}
                onClose={() => this.toggleModal(false)}
                className="modal_admin"
                disableBackdropClick
            >
                <div className="modal-member">
                    <Typography variant="h4" className="modal-title">
                        <CloseIcon/>
                    </Typography>
                    <Typography variant="h2">
                        Search Orders
                    </Typography>
                    <Typography className="order-description">
                        Please enter the criteria that you want to search:
                    </Typography>

                    { this.props.searchForm.error && (
                        <Typography className="modal-error">
                            { this.props.searchForm.error }
                        </Typography>
                    )}

                    { this.props.searchForm.message && (
                        <Typography className="modal-success">
                            { this.props.searchForm.message }
                        </Typography>
                    )}

                    {(this.props.loading) && (
                        <div className="page-loading">
                            <CircularProgress size={100} />
                        </div>
                    )}
                    {(!this.props.loading) && (
                        <div>
                            <Grid container>
                                <Grid item xs={12} className="form-grid">
                                    <TextField
                                        label="Order ID"
                                        className="modal-input"
                                        fullWidth={true}
                                        value={this.props.searchForm.order_id}
                                        onChange={(e) => { this.props.dispatch(searchFormUpdate({ prop: 'order_id', value: e.target.value })); }}
                                        margin="normal"
                                        variant="outlined"
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <div className="separator">Or</div>
                                </Grid>
                                <Grid item xs={12} className="form-grid">
                                    <TextField
                                        label="Name"
                                        className="modal-input"
                                        fullWidth={true}
                                        value={this.props.searchForm.name}
                                        onChange={(e) => { this.props.dispatch(searchFormUpdate({ prop: 'name', value: e.target.value })); }}
                                        margin="normal"
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12} className="form-grid">
                                    <TextField
                                        label="Address"
                                        className="modal-input"
                                        fullWidth={true}
                                        value={this.props.searchForm.address}
                                        onChange={(e) => { this.props.dispatch(searchFormUpdate({ prop: 'address', value: e.target.value })); }}
                                        margin="normal"
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12} className="form-grid">
                                    <TextField
                                        label="Phone Number"
                                        className="modal-input"
                                        fullWidth={true}
                                        value={this.props.searchForm.phone}
                                        onChange={(e) => { this.props.dispatch(searchFormUpdate({ prop: 'phone', value: e.target.value })); }}
                                        margin="normal"
                                        variant="outlined"
                                    />
                                </Grid>

                                
                                <Grid item xs={12} className="form-grid form-grid-mb">
                                    <Typography variant="h6" className="input-label-mb">
                                        Order Status
                                    </Typography>

                                    <Select
                                        fullWidth={true}
                                        labelId="orderStatus"
                                        value={this.props.searchForm.order_status}
                                        onChange={(e) => { this.props.dispatch(searchFormUpdate({ prop: 'order_status', value: e.target.value })); }}
                                        variant="outlined"
                                    >
                                        <MenuItem key={'all'} value={'all'}>All</MenuItem>
                                        <MenuItem key={'complete'} value={'complete'}>Completed</MenuItem>
                                        <MenuItem key={'cancelled'} value={'cancelled'}>Cancelled</MenuItem>
                                    </Select>

                                </Grid>

                                <Grid item xs={2} className="form-grid">
                                    <InputLabel className="input-label-mb">Orders from any date</InputLabel>
                                    <FormControlLabel
                                        control={
                                            <Checkbox 
                                                checked={this.props.searchForm.any_time}
                                                onChange={(e) => { this.props.dispatch(searchFormUpdate({ prop: 'any_time', value: +!this.props.searchForm.any_time })); }}
                                                name={"any_time"}
                                                color="primary"
                                            />
                                        }
                                        label="Any date"
                                    />
                                </Grid>

                                <Grid item xs={4} className="form-grid">
                                    <InputLabel className="input-label-mb">Start Date</InputLabel>
                                    <Grid container>
                                        <Grid item xs={6} className="form-grid">
                                            <DatePicker 
                                                className="date-picker-input"
                                                selected={this.props.searchForm.start_time} 
                                                onChange={(date) => { this.props.dispatch(searchFormUpdate({ prop: 'start_time', value: date })); }} 
                                                dateFormat="MM/dd/yyyy"
                                                selectsStart
                                                startDate={this.props.searchForm.start_time}
                                                endDate={this.props.searchForm.end_time}
                                            />
                                            <TimePicker
                                                allowEmpty={false}
                                                showSecond={false}
                                                defaultValue={moment().set({hour: 8, minute: 0})}
                                                use12Hours={true}
                                                minuteStep={5}
                                                inputClassName="date-picker-input"
                                                onChange={(date) => { this.props.dispatch(searchFormUpdate({ prop: 'start_time_time', value: date })); }} 
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={4} className="form-grid">
                                    <InputLabel className="input-label-mb">End Time</InputLabel>
                                    <Grid container>
                                        <Grid item xs={6} className="form-grid">
                                            <DatePicker 
                                                className="date-picker-input"
                                                selected={this.props.searchForm.end_time} 
                                                onChange={(date) => { this.props.dispatch(searchFormUpdate({ prop: 'end_time', value: date })); }} 
                                                dateFormat="MM/dd/yyyy"
                                                selectsEnd
                                                startDate={this.props.searchForm.start_time}
                                                endDate={this.props.searchForm.end_time}
                                            />
                                            <TimePicker
                                                allowEmpty={false}
                                                showSecond={false}
                                                defaultValue={moment().set({hour: 23, minute: 59})}
                                                use12Hours={true}
                                                minuteStep={5}
                                                inputClassName="date-picker-input"
                                                onChange={(date) => { this.props.dispatch(searchFormUpdate({ prop: 'end_time_time', value: date })); }} 
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                    
                            <Button variant="contained" fullWidth={true} className="modal-button-bottom" onClick={() => this.props.submitForm(this.props.searchForm)}>
                                Search
                            </Button>
                        </div>
                    )}
                </div>
            </Modal>
        );
    }
};

const mapStateToProps = (state, props) => {
    return {
        loading: state.loyaltyRewardForm.loading,
        restaurant: state.selectedRestaurant.restaurant,
        searchForm: state.reportSearchForm,
        modalOpen: state.modals.searchReports
    };
};

export default connect(mapStateToProps)(SearchOrders);