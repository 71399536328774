import axios from 'axios';
import { API_URL } from '../../../config/constants';
import { dispatchCenterFormUpdate, dispatchCenterFormReset, dispatchCenterFormError, dispatchCenterFormSuccess } from './dispatchCenterForm';
import { closeDispatchCenterCreate } from '../../modals';
import { setTimezones } from '../../timezones';

export const setSelectedDispatchCenter = (dispatchCenter) => {
    return {
        type: 'SET_SELECTED_DISPATCH_CENTER',
        dispatchCenter
    }
}

export const setDispatchCenters = (centers) => {
    return {
        type: 'SET_DISPATCH_CENTERS',
        centers
    }
}

export const setDispatchCenterFilter = (text) => {
    return {
        type: 'SET_DISPATCH_CENTER_FILTER',
        text
    }
}

export const resetDispatchCenters = () => {
    return {
        type: 'RESET_DISPATCH_CENTERS'
    }
}

export const setDispatchCentersLoading = () => {
    return {
        type: 'SET_DISPATCH_CENTERS_LOADING'
    }
}

export const loadDispatchCenters = () => {
    return (dispatch) => {
        return axios.get(API_URL+'/api/dispatchcenters')
            .then(function(res) {

                // load the centers
                dispatch(setDispatchCenters(res.data.data.dispatch_centers));

                // update the timezones list
                dispatch(setTimezones(res.data.data.timezones));

            })
            .catch(function(err) {
                console.log("Err!");
                console.log(err);
            });
    };
}

export const addDispatchCenter = (center) => {
    
    return (dispatch) => {

        dispatch(setDispatchCentersLoading());

        return axios.post(API_URL+'/api/dispatchcenters', center)
            .then(function(res) {
                if (res.data.data.status == 'success') {

                    // set the app centers, reset the form, and close the modal
                    dispatch(setDispatchCenters(res.data.data.dispatch_centers));
                    dispatch(dispatchCenterFormReset());
                    dispatch(closeDispatchCenterCreate());
                }
                else {
                    dispatch(dispatchCenterFormError(res.data.message));
                }
            })
            .catch(function(err) {
                
                // token is likely invalid; remove it and prompt the user to login again
                console.log(err);
                dispatch(dispatchCenterFormError("We're sorry, but our system has encountered an issue, to which we have been notified."));
                if (err.response.status === 401) {
                    removeAuthToken(dispatch);
                }
                
            });
    };
};

export const updateDispatchCenter = (centerId, scope, data) => {
    
    return (dispatch) => {

        dispatch(setDispatchCentersLoading());

        return axios.post(API_URL+'/api/dispatchcenters/'+centerId, { 
            scope,
            data: data
        }).then(function(res) {
            if (res.data.data.status == 'success') {

                // set the app centers, reset the form, and close the modal
                dispatch(setDispatchCenters(res.data.data.dispatch_centers));
                dispatch(setSelectedDispatchCenter(res.data.data.center));
                dispatch(dispatchCenterFormSuccess('Center Updated!'));
            }
            else {
                dispatch(dispatchCenterFormError(res.data.message));
            }
        })
        .catch(function(err) {
            console.log('err');
            console.log(err);

            // token is likely invalid; remove it and prompt the user to login again
            dispatch(dispatchCenterFormError("We're sorry, but our system has encountered an issue, to which we have been notified."));
            if (err.response.status === 401) {
                removeAuthToken(dispatch);
            }
            
        });
    };
};