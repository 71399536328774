import axios from 'axios';
import hello from 'hellojs';
import _ from 'lodash';
import { API_URL } from '../config/constants';
import { setSelectedRestaurant } from './selectedRestaurant';
import { infoFormUpdate, infoFormError } from './restaurant/infoForm';
import { closeRestaurantCreate } from './modals';

export const setRestaurants = (restaurants) => {
    return {
        type: 'SET_RESTAURANTS',
        restaurants
    }
}

export const toggleRestaurantsLoading = (loading) => {
    return {
        type: 'TOGGLE_RESTAURANTS_LOADING',
        loading
    }
}

export const setRestaurantFilter = (text) => {
    return {
        type: 'SET_RESTAURANT_FILTER',
        text
    }
}

export const resetRestaurants = () => {
    return {
        type: 'RESET_RESTAURANTS'
    }
}

export const loadRestaurants = (selectedRestaurantId = null, scope = null) => {

    return (dispatch) => {
        let url = API_URL+'/api/restaurants';
        if (selectedRestaurantId) {
            url = API_URL+'/api/restaurants/'+selectedRestaurantId;
        }

        return axios.get(url)
            .then(function(res) {
                // add the new restaurant
                dispatch(setRestaurants(res.data.data.restaurants));

                if (selectedRestaurantId) {

                    // found a match; set the selected restaurant
                    dispatch(setSelectedRestaurant({ restaurant: res.data.data.restaurants[0] }));

                    if (scope == 'generalInformation') {

                        // it's the restaurant general information page; load the correct data into the info form
                        dispatch(infoFormUpdate({ prop: 'name', value: res.data.data.restaurants[0].name }));
                        dispatch(infoFormUpdate({ prop: 'additional_charge', value: res.data.data.restaurants[0].additional_charge }));
                        dispatch(infoFormUpdate({ prop: 'standard_charge_type', value: res.data.data.restaurants[0].standard_charge_type }));
                        dispatch(infoFormUpdate({ prop: 'standard_charge_amount', value: res.data.data.restaurants[0].standard_charge_amount }));
                        dispatch(infoFormUpdate({ prop: 'whitelabel_charge_type', value: res.data.data.restaurants[0].standard_charge_type }));
                        dispatch(infoFormUpdate({ prop: 'whitelabel_charge_amount', value: res.data.data.restaurants[0].standard_charge_amount }));
                        dispatch(infoFormUpdate({ prop: 'fuel_surcharge_amount', value: res.data.data.restaurants[0].fuel_surcharge_amount }));
                        dispatch(infoFormUpdate({ prop: 'fuel_surcharge_enabled', value: res.data.data.restaurants[0].fuel_surcharge_enabled }));
                        dispatch(infoFormUpdate({ prop: 'online_payment_status', value: res.data.data.restaurants[0].online_payment_status }));
                        dispatch(infoFormUpdate({ prop: 'publishable_key', value: res.data.data.restaurants[0].publishable_key }));
                        dispatch(infoFormUpdate({ prop: 'whitelabel_enabled', value: (res.data.data.restaurants[0].whitelabel_enabled == 1) ? true : false }));
                        dispatch(infoFormUpdate({ prop: 'internal_enabled', value: (res.data.data.restaurants[0].internal_enabled == 1) ? true : false }));
                        dispatch(infoFormUpdate({ prop: 'internal_charge_type', value: res.data.data.restaurants[0].internal_charge_type }));
                        dispatch(infoFormUpdate({ prop: 'internal_charge_target', value: res.data.data.restaurants[0].internal_charge_target }));
                        dispatch(infoFormUpdate({ prop: 'internal_charge_comment', value: res.data.data.restaurants[0].internal_charge_comment }));
                        dispatch(infoFormUpdate({ prop: 'internal_charge_amount', value: res.data.data.restaurants[0].internal_charge_amount }));
                        dispatch(infoFormUpdate({ prop: 'loyalty_points_enabled', value: (res.data.data.restaurants[0].loyalty_points_enabled == 1) ? true : false }));
                        dispatch(infoFormUpdate({ prop: 'custom_delivery', value: (res.data.data.restaurants[0].custom_delivery) ? res.data.data.restaurants[0].custom_delivery : 'none' }));
                        dispatch(infoFormUpdate({ prop: 'logo', value: (res.data.data.restaurants[0].logo && res.data.data.restaurants[0].logo != '') ? res.data.data.restaurants[0].logo : null }));
                        dispatch(infoFormUpdate({ prop: 'feature_image', value: (res.data.data.restaurants[0].feature_image && res.data.data.restaurants[0].feature_image != '') ? res.data.data.restaurants[0].feature_image : null }));
                        dispatch(infoFormUpdate({ prop: 'loading', value: false }));
                    }
                }
            })
            .catch(function(err) {
                console.log("Err!");
                console.log(err);
            });
    };
}

export const updateRestaurantInfo = (restaurantId, data) => {
    
    return (dispatch) => {

        const formData = new FormData();

        // add the image
        formData.append('logo', data.logoImage);
        formData.append('feature', data.featureImage);

        // add the data
        formData.append('data', JSON.stringify(data));

        dispatch(infoFormUpdate({ prop: 'loading', value: true }));

        return axios.post(
            API_URL+'/api/restaurants/'+restaurantId, 
            formData,
            {
                headers: { 'Content-Type': 'multipart/form-data' }
            }
        ).then(function(res) {
            if (res.data.status == 'success') {

                // add the new restaurant
                dispatch(setRestaurants(res.data.data.restaurants));

                // find the selected restaurant and set it
                for (let i = 0; i < res.data.data.restaurants.length; i++) {
                    if (res.data.data.restaurants[i].id == restaurantId) {

                        // found a match; set the selected restaurant
                        dispatch(setSelectedRestaurant({ restaurant: res.data.data.restaurants[i] }));

                        // it's the restaurant general information page; load the correct data into the info form
                        dispatch(infoFormUpdate({ prop: 'name', value: res.data.data.restaurants[i].name }));
                        dispatch(infoFormUpdate({ prop: 'additional_charge', value: res.data.data.restaurants[i].additional_charge }));
                        dispatch(infoFormUpdate({ prop: 'standard_charge_type', value: res.data.data.restaurants[i].standard_charge_type }));
                        dispatch(infoFormUpdate({ prop: 'standard_charge_amount', value: res.data.data.restaurants[i].standard_charge_amount }));
                        dispatch(infoFormUpdate({ prop: 'whitelabel_charge_type', value: res.data.data.restaurants[i].standard_charge_type }));
                        dispatch(infoFormUpdate({ prop: 'whitelabel_charge_amount', value: res.data.data.restaurants[i].standard_charge_amount }));
                        dispatch(infoFormUpdate({ prop: 'whitelabel_enabled', value: (res.data.data.restaurants[i].whitelabel_enabled == 1) ? true : false }));
                        dispatch(infoFormUpdate({ prop: 'online_payment_status', value: res.data.data.restaurants[i].online_payment_status }));
                        dispatch(infoFormUpdate({ prop: 'publishable_key', value: res.data.data.restaurants[i].publishable_key }));
                        dispatch(infoFormUpdate({ prop: 'loyalty_points_enabled', value: (res.data.data.restaurants[i].loyalty_points_enabled == 1) ? true : false }));
                        dispatch(infoFormUpdate({ prop: 'custom_delivery', value: (res.data.data.restaurants[i].custom_delivery) ? res.data.data.restaurants[i].custom_delivery : 'none' }));
                        dispatch(infoFormUpdate({ prop: 'logo', value: (res.data.data.restaurants[i].logo && res.data.data.restaurants[i].logo != '') ? res.data.data.restaurants[i].logo : null }));
                        dispatch(infoFormUpdate({ prop: 'logoImage', value: null }));
                        dispatch(infoFormUpdate({ prop: 'feature_image', value: (res.data.data.restaurants[i].feature_image && res.data.data.restaurants[i].feature_image != '') ? res.data.data.restaurants[i].feature_image : null }));
                        dispatch(infoFormUpdate({ prop: 'featureImage', value: null }));
                        dispatch(infoFormUpdate({ prop: 'message', value: 'Information Updated.' }));
                        dispatch(infoFormUpdate({ prop: 'loading', value: false }));
                    }
                }
            }
            else {
                dispatch(infoFormError(res.data.message));
                console.log(res.data);
                console.log("Could not update your restaurant!");
            }
        })
        .catch(function(err) {
            console.log('err');
            console.log(err);
            // token is likely invalid; remove it and prompt the user to login again
            dispatch(infoFormError("We're sorry, but our system has encountered an issue, to which we have been notified."));
            if (err.response.status === 401) {
                removeAuthToken(dispatch);
            }
            
        });
    };
};

export const updateRestaurantEnabled = (restaurantId, enabled) => {
    
    return (dispatch) => {

        const scope = (enabled) ? 'enable' : 'disable';

        dispatch(toggleRestaurantsLoading(true));

        return axios.post(
            API_URL+'/api/restaurants/'+restaurantId, 
            {
                scope
            }
        ).then(function(res) {
            if (res.data.status == 'success') {

                // add the new restaurant
                dispatch(setRestaurants(res.data.data.restaurants));
                dispatch(toggleRestaurantsLoading(false));
            }
            else {
                alert(res.data.message);
                dispatch(toggleRestaurantsLoading(false));
            }
        })
        .catch(function(err) {
            console.log('err');
            console.log(err.response.data);
            // token is likely invalid; remove it and prompt the user to login again
            alert(err.response.data.status);
        });
    };
};

export const addRestaurant = (data) => {
    
    return (dispatch) => {

        const formData = new FormData();

        // add the image
        formData.append('logo', data.logoImage);
        formData.append('feature', data.featureImage);

        // add the data
        formData.append('data', JSON.stringify(data));

        dispatch(infoFormUpdate({ prop: 'loading', value: true }));

        return axios.post(
            API_URL+'/api/restaurants', 
            formData,
            {
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
            }
        ).then(function(res) {
            if (res.data.status == 'success') {

                // add the new restaurant
                dispatch(setRestaurants(res.data.data.restaurants));
                dispatch(infoFormUpdate({ prop: 'loading', value: false }));
                dispatch(closeRestaurantCreate());
            }
            else {
                dispatch(infoFormError(res.data.message));
                console.log(res.data);
                console.log("Could not add your restaurant!");
            }
        })
        .catch(function(err) {
            console.log('err');
            console.log(err);
            // token is likely invalid; remove it and prompt the user to login again
            dispatch(infoFormError("We're sorry, but our system has encountered an issue, to which we have been notified."));
            if (err.response.status === 401) {
                removeAuthToken(dispatch);
            }
            
        });
    };
};

export const disconnectPayment = (restaurant_id) => {
    return (dispatch) => {
        dispatch(infoFormUpdate({ prop: 'loading', value: true }));

        return axios.post(
            API_URL+'/api/restaurants/'+restaurant_id+'/payment',
            {
                scope: 'disconnect',
                data: '{}'
            }
        ).then(function(res) {
            if (res.data.status == 'success') {

                // add the new restaurant
                dispatch(setRestaurants(res.data.data.restaurants));
                dispatch(infoFormUpdate({ prop: 'online_payment_status', value: res.data.data.restaurant.online_payment_status }));
                dispatch(infoFormUpdate({ prop: 'publishable_key', value: res.data.data.restaurant.publishable_key }));
                dispatch(infoFormUpdate({ prop: 'message', value: 'Payment Information Updated.' }));
                dispatch(infoFormUpdate({ prop: 'loading', value: false }));
                dispatch(closeRestaurantCreate());
            }
            else {
                dispatch(infoFormError(res.data.message));
                console.log(res.data);
                console.log("Could not add your restaurant!");
            }
        })
        .catch(function(err) {
            console.log('err');
            console.log(err);
            // token is likely invalid; remove it and prompt the user to login again
            dispatch(infoFormError("We're sorry, but our system has encountered an issue, to which we have been notified."));
            if (err.response.status === 401) {
                removeAuthToken(dispatch);
            }
            
        });
    };
};

export const connectPayment = (restaurant) => {

    return (dispatch) => {
        dispatch(infoFormUpdate({ prop: 'loading', value: true }));

        // initialize hellojs
        hello.init({
            stripe: {
                id: 'ca_4nJ6aCskta6iOEWvTgpHpdMr2jshDk6Z',
                name: 'stripe',
                oauth: {
                    version: 2,
                    response_type: 'code',
                    auth: 'https://connect.stripe.com/oauth/authorize',
                    grant: 'https://connect.stripe.com/oauth/token'
                },
                login: function (p) {
                    _.extend(p.qs, {
                        'stripe_user[first_name]': (restaurant.owner && restaurant.owner.first_name) ? restaurant.owner.first_name : '',
                        'stripe_user[last_name]': (restaurant.owner && restaurant.owner.last_name) ? restaurant.owner.last_name : '',
                        'stripe_user[email]': (restaurant.owner && restaurant.owner.email) ? restaurant.owner.email : '',
                        'stripe_user[country]': 'CA',
                        'stripe_user[business_name]': (restaurant.name) ? restaurant.name : '',
                        'stripe_user[physical_product]': true,
                        'stripe_user[currency]': 'CAD',
                        'stripe_user[shipping_days]': 0,
                        'stripe_user[product_category]': 'food_and_restaurants',
                        'stripe_user[product_description]': 'Pizza and food pickup and delivery',
                        'state': restaurant,
                        'scope': 'read_write',
                    });
                }
            }
        }, {
            redirect_uri: API_URL+'/oauth/authenticate'
        });

        hello.login('stripe').then(function() {
        }, function(e) {
            dispatch(loadRestaurants(restaurant.id, 'generalInformation'));
        });
    }
};