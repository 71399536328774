const timezonesDefaultState = {
    timezones: []
};

export default (state = timezonesDefaultState, action) => {
    switch (action.type) {
        case 'SET_TIMEZONES':
            return {
                timezones: action.timezones
            };
            
        case 'RESET_TIMEZONES':
            return timezonesDefaultState;

        default:
            return state;
    }
};