import React from 'react';
import { connect } from 'react-redux';
import Sound from 'react-sound';
import { API_URL } from '../../config/constants';
import Button from '@material-ui/core/Button';
import Badge from '@material-ui/core/Badge';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import PauseIcon from '@material-ui/icons/Pause';
import PlayIcon from '@material-ui/icons/PlayArrow';
import SearchIcon from '@material-ui/icons/Search';

import LoadScreen from '../common/LoadScreen';
import verifyLogin from '../../utils/verifyLogin';
import checkPermissions from '../../utils/checkUserPermissions';
import { loadLoggedInUser } from '../../actions/loggedUser';

import { getFormattedTime, getCurrentTime } from '../../utils/dateUtil';

import { 
    loadSupportOrders,
    searchSupportOrders,
    confirmOrder,
    readyOrder,
    rejectOrderSupport,
    resendGetSwiftOrder,
    resendReceipt,
    stopChime
} from '../../actions/restaurant/orders';

import { 
    confirmOrderFormError
} from '../../actions/restaurant/confirmOrderForm';
import { searchFormUpdate, searchFormError, searchFormReset } from '../../actions/reports/searchForm';

import { 
    setSelectedOrder,
} from '../../actions/restaurant/selectedOrder';

import { openConfirmOrder, openSearchReports, closeSearchReports } from '../../actions/modals';
import { loadPageData } from '../../actions/auth';

import OrderConfirm from '../common/OrderConfirm';
import SearchOrders from '../common/SearchOrders';
import chime from '../../assets/chime.wav';

class SupportOrdersPage extends React.Component {

    constructor(props) {
        super(props);

        this.submitSearchOrders = this.submitSearchOrders.bind(this);
        this.toggleSearchModal = this.toggleSearchModal.bind(this);
        this.confirmOrder = this.confirmOrder.bind(this);
        this.readyOrder = this.readyOrder.bind(this);
        this.resendGetSwift = this.resendGetSwift.bind(this);
        this.resendReceipt = this.resendReceipt.bind(this);
        this.checkPermissions = this.checkPermissions.bind(this);
        this.stopTimers = this.stopTimers.bind(this);
        this.startTimers = this.startTimers.bind(this);

        this.state = {
            sidebarMenuOpen: false,
            currentView: 'all',
            timerConstID : 0
        };
    }

    componentDidMount() {
        verifyLogin(this.props.history);
        this.props.dispatch(loadLoggedInUser(this.props.history, this.checkPermissions));
    }

    componentDidUpdate() {
        verifyLogin(this.props.history);
    }

    componentWillUnmount() {
        this.stopTimers();
    }   

    checkPermissions() {
        if (checkPermissions(this.props.user, 'support')) {
            this.props.dispatch(loadPageData(this.props.match.params));
            this.props.dispatch(loadSupportOrders(this.props.orders.maxWarningOrderId, this.props.selectedOrder.orderId));
            
            this.setOrdersFeed();
        } else {
            this.props.history.push('/permission-denied');
        }
    }

    toggleSearchModal(isOpen) {
        if (isOpen) {
            this.props.dispatch(searchFormReset());
            this.props.dispatch(openSearchReports());
            this.updateCurrentView('search');
        }
        else {
            this.props.dispatch(closeSearchReports());
        }
    }

    resendGetSwift(order_id) {
        this.props.dispatch(resendGetSwiftOrder(order_id));
    }

    resendReceipt(order_id) {
        this.props.dispatch(resendReceipt(order_id));
    }

    submitSearchOrders(formData) {
        this.props.dispatch((searchSupportOrders(formData)));
    }

    confirmOrder(restaurant_id, order_id, minutes) {
        if (minutes > 0) {
            this.props.dispatch(confirmOrder(restaurant_id, order_id, minutes, this.props.selectedOrder.orderId, 'support'));
        } else {
            this.props.dispatch(confirmOrderFormError('Invalid confirm time entered'));
        }
    }

    readyOrder(restaurant_id, order_id, minutes) {
        if (minutes > 0) {
            this.props.dispatch(readyOrder(restaurant_id, order_id, minutes, 'support', this.props.selectedOrder.orderId));
        } else {
            this.props.dispatch(confirmOrderFormError('Invalid dispatch time entered'));
        }
    }

    stopTimers(){
        clearInterval(this.state.timerConstID);
        
        this.setState(() => ({
            timerConstID: 0
        }));
    }

    startTimers(){
        this.setOrdersFeed();
    }

    togglePlay() {
        this.setState({ play: !this.state.play }, () => {
          this.state.play ? this.audio.play() : this.audio.pause();
        });
    }

    setOrdersFeed() {
        let intervalID = setInterval(() => {
            this.props.dispatch(loadSupportOrders(this.props.orders.maxWarningOrderId, this.props.selectedOrder.orderId));
        }, 30000);
        
        this.setState(() => ({
            timerConstID: intervalID
        }));
    }

    updateCurrentView(scope) {
        this.setState(() => ({
            currentView: scope
        }));
    }

    getCurrentViewOrders() {
        if (this.state.currentView == 'search') {
            return this.props.orders.search;
        } else if (this.state.currentView == 'restaurantWarning') {
            return this.props.orders.restaurantWarning;
        } else if (this.state.currentView == 'dispatchWarning') {
            return this.props.orders.dispatchWarning;
        } else {
          //  console.log(this.props.orders.all)
            return this.props.orders.all;
        }
    }

    selectOrder(order) {
        this.props.dispatch(setSelectedOrder(order.id, order, this.props.orders.orderInformation[order.id]));
    }

    selectSearchOrder(order) {
        this.props.dispatch(setSelectedOrder(order.id, order, this.props.orders.searchOrderInformation[order.id]));
    }

    openConfirmOrder() {
        this.props.dispatch(openConfirmOrder());
    }

    getFeedRestaurantStatusBackground(order) {

        if ( order.time_confirmed ) {
            return '#0faa1f'
        }
        if ( order.warning_restaurant) {
            return '#CC3433'
        }
        return '#727272'

    }

   
    getOrderStatusCircle(order) {
        if (order.warning_restaurant || order.warning_dispatch) {
            return "#c21111"
        }

        return '#0eac1e';
    }

    getFeedDispatchStatusBackground(order) {
       
        if (order.time_ready) {
            return '#0faa1f'
        }
        if (order.warning_dispatch) {
            return '#CC3433'
        }
        return '#727272'
    }

    render() {
        let soundStatus = Sound.status.STOPPED;
        if (this.props.orders.playChime) {
            soundStatus = Sound.status.PLAYING;
        } 
        return (
            <div>
                <div className="orders-feed-window" onClick={() => this.props.dispatch(stopChime())}>
                    <Sound
                        url={chime}
                        playStatus={soundStatus}
                    />

                    <div className="orders-feed-section orders-feed-list">
                        {(this.props.loading) && (
                            <div className="load-screen">
                                <CircularProgress className="loader orders-loader orders-loader-list" size={100} />
                            </div>
                        )}
                        {(!this.props.loading) && (
                            <div>
                                <div className="orders-list-tabs">
                                    <div 
                                        className={(this.state.currentView == 'all') ? "orders-list-tab orders-list-view-selected" : "orders-list-tab"}
                                        onClick={() => this.updateCurrentView('all')}
                                    >
                                        <Badge
                                           // badgeContent={this.props.orders.all.length}
                                           // color="secondary"
                                            anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'left',
                                            }}
                                        >
                                            All Orders
                                        </Badge>
                                    </div>
                                    <div 
                                        className={(this.state.currentView == 'restaurantWarning') ? "orders-list-tab orders-list-view-selected" : "orders-list-tab"}
                                        onClick={() => this.updateCurrentView('restaurantWarning')}
                                    >
                                        <Badge
                                            badgeContent={this.props.orders.restaurantWarning.length}
                                            color="secondary"
                                            anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'left',
                                            }}
                                        >
                                            Warning: Restaurant
                                        </Badge>
                                    </div>
                                    <div 
                                        className={(this.state.currentView == 'dispatchWarning') ? "orders-list-tab orders-list-view-selected" : "orders-list-tab"}
                                        onClick={() => this.updateCurrentView('dispatchWarning')}
                                    >
                                        <Badge
                                            badgeContent={this.props.orders.dispatchWarning.length}
                                            color="secondary"
                                            anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'left',
                                            }}
                                        >
                                            Warning: Dispatch
                                        </Badge>
                                    </div>
                                    <div 
                                        className={(this.state.currentView == 'search') ? "orders-list-tab orders-list-view-selected" : "orders-list-tab"}
                                        onClick={() => this.updateCurrentView('search')}
                                    >
                                        <Badge
                                            badgeContent={this.props.orders.search.length}
                                            color="secondary"
                                            anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'left',
                                            }}
                                        >
                                            Search
                                        </Badge>
                                    </div>
                                </div>
                                <div className="orders-list">
                                    {this.getCurrentViewOrders().map((order) => {
                                       // console.log(order)
                                        return (
                                            <div key={order.id} className={(this.props.selectedOrder.orderId == order.id) ? "orders-list-item orders-list-item-selected orders-list-item" : "orders-list-item orders-list-item"} onClick={() => { (this.state.currentView == 'search') ? this.selectSearchOrder(order) : this.selectOrder(order) }}>
                                               
                                                        <div className="feed-info">
                                                            <p className="orders-list-item-text orders-list-item-text-bold">{order.order_id}</p>
                                                            <p className="orders-list-item-text">{order.time_received_print}</p>
                                                            <p className="orders-list-item-text">{order.name}</p>
                                                            <p className="orders-list-item-text orders-list-item-text-bold">{order.order_type}</p>
                                                            <div className="confirm-dispatch-wrapper">

                                                            <Button
                                                                  type="button"
                                                                  variant="contained"
  
                                                                 style={{
                                                                  backgroundColor: this.getFeedRestaurantStatusBackground(order),
                                                                  padding: "9px 22px",
                                                                  fontSize: "0.9375rem",
                                                                  color: '#fff'
                                                              }}
                                                                  size="large"
                                                                  className="btn-order-status-complete order-feed-details-button"
                                                              >
                                                                  Rest. Confirm
                                                              </Button>
                                                                <Button
                                                                  type="button"
                                                                  variant="contained"
  
                                                                 style={{
                                                                  backgroundColor: this.getFeedDispatchStatusBackground(order),
                                                                  padding: "9px 22px",
                                                                  fontSize: "0.9375rem",
                                                                  color: '#fff'
                                                              }}
                                                                  size="large"
                                                                  className="btn-order-status-complete order-feed-details-button"
                                                              >
                                                                  Dispatch
                                                              </Button>       
                                                            </div>
                                                        </div>
                                                        <div className="feed-image">         
                                                           <img src={API_URL + "/" + order.restaurant.logo} alt="" width={65} height={65} className="feed-logo-image" />
                                                        </div>   
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                    </div>
                    
                    <div className="orders-feed-section orders-feed-info" >
                        {(this.props.loading) && (
                            <div className="load-screen">
                                <CircularProgress className="loader orders-loader" size={100} />
                            </div>
                        )}
                        {(!this.props.loading) && (
                            <div>
                                {(this.props.selectedOrder.orderId == 0) && (
                                    <p className="order-feed-empty">Please select an order to view the restaurant information.</p>
                                )}

                                {(this.props.selectedOrder.orderId != 0) && (
                                    <div>
                                        <div className="order-feed-information-status">
                                            <div className="order-feed-information-status-badge">
                                                {/* ng-className="{'support-order-information-status-danger': currentOrder.warning_restaurant || currentOrder.warning_dispatch || currentOrder.order_status == 'rejected'}" */}
                                                <div className={"order-feed-information-status-color orders-list-item-"+this.props.selectedOrder.order.order_status} style={{  backgroundColor: this.getOrderStatusCircle(this.props.selectedOrder.order)}}></div>
                                            </div>
                                            <div className="order-feed-information-status-text">
                                                <p className="order-feed-information-status-title">Status</p>
                                                <p className="order-feed-information-status-text">{this.props.selectedOrder.order.order_status}</p>
                                            </div>
                                        </div>
                                        <div className="order-feed-information-section">
                                            <img src={API_URL + '/' + this.props.selectedOrder.order.restaurant.logo} alt={this.props.selectedOrder.order.restaurant.name} className="order-feed-information-restaurant-logo" />
                                            <div className="order-feed-information-restaurant-info">
                                                <p className="order-feed-restaurant-title">{this.props.selectedOrder.order.restaurant.name}</p>
                                                <p>{this.props.selectedOrder.order.location.address}, {this.props.selectedOrder.order.location.city}</p>
                                                <p>{this.props.selectedOrder.order.location.phone}</p>
                                            </div>
                                        </div>
                                        <div className="order-feed-information-section">
                                            <div className="order-feed-information-restaurant-info">
                                                <p className="order-feed-details-heading">Pickup Time</p>
                                                <p className="order-feed-pickup-time">{this.props.selectedOrder.order.pickup_time_print || 'N/A'}</p>
                                            </div>
                                        </div>
                                        <div className="order-feed-information-section">
                                            <div className="order-feed-information-restaurant-info">
                                                <p className="order-feed-details-heading">Order Type</p>
                                                <p className="order-feed-order-type">{this.props.selectedOrder.order.order_type}</p>
                                            </div>
                                        </div>
                                        <div className="order-feed-information-section">
                                            <div className="order-feed-information-restaurant-info">
                                                <p className="order-feed-details-heading">Payment Method</p>
                                                <p className="order-feed-order-type">{this.props.selectedOrder.order.payment_method}</p>
                                            </div>
                                        </div>
                                        <div className="order-feed-information-section">
                                            <div className="order-feed-information-restaurant-info">
                                                <p className="order-feed-details-heading">Customer Info</p>
                                                <p className="order-feed-order-type">{this.props.selectedOrder.order.name}</p>
                                                {(this.props.selectedOrder.order.order_type == 'delivery') && <p className="order-feed-order-type">{this.props.selectedOrder.order.address}</p>}
                                                {(this.props.selectedOrder.order.order_type == 'delivery') && <p className="order-feed-order-type">Instructions: {this.props.selectedOrder.order.instructions}</p>}
                                                <p className="order-feed-order-type">{this.props.selectedOrder.order.phone}</p>
                                                <p className="order-feed-order-type">{this.props.selectedOrder.order.email}</p>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>

                    <div className="orders-feed-section orders-feed-details">
                        {(this.props.loading) && (
                            <div className="load-screen">
                                <CircularProgress className="loader orders-loader" size={100} />
                            </div>
                        )}
                        {(!this.props.loading) && (
                            <div>
                                {(this.props.selectedOrder.orderId == 0) && (
                                    <p className="order-feed-empty">Please select an order to view the order details.</p>
                                )}

                                {(this.props.selectedOrder.orderId != 0) && (
                                    <div>
                                        <div className="order-feed-details-header">
                                            <p className="order-feed-details-heading">
                                                Order Number
                                            </p>
                                            <p className="order-feed-number-text">
                                                {this.props.selectedOrder.order.order_id}
                                            </p>
                                        </div>

                                        <div className="order-feed-details-items">
                                            {this.props.selectedOrder.orderInformation.pizzas.map((pizza) => {
                                                return (
                                                    <div>
                                                        <p>{pizza.quantity} x {pizza.size} {pizza.name} - { pizza.totalPrice.toFixed(2) }</p>
                                                        {(pizza.details.length) && (
                                                            <ul className="order-item-details">
                                                                {pizza.details.map((detail) => (
                                                                    <li>
                                                                        {detail.description}{(detail.price != '0.00') && <span> - {parseFloat(detail.price).toFixed(2)}</span>}
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        )}
                                                    </div>
                                                );
                                            })}

                                            {this.props.selectedOrder.orderInformation.foodItems.map((foodItem) => {
                                                return (
                                                    <div>
                                                        <p>{ foodItem.quantity } x {(foodItem.size != '<default>') && <span>{ foodItem.size } </span>}{ foodItem.name } - { foodItem.totalPrice.toFixed(2) }</p>
                                                        {(foodItem.details.length > 0) && (
                                                            <ul className="order-item-details">
                                                                {foodItem.details.map((detail) => (
                                                                    <li>
                                                                        {detail.description}{(detail.price != '0.00') && <span> - {parseFloat(detail.price).toFixed(2)}</span>}
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        )}
                                                    </div>
                                                );
                                            })}
                                            {this.props.selectedOrder.orderInformation.beverages.map((beverage) => {
                                                return (
                                                    <div>
                                                        <p>{ beverage.quantity } x {(beverage.size != '<default>') && <span>{ beverage.size } </span>}{ beverage.name } - { beverage.totalPrice.toFixed(2) }</p>
                                                        {(beverage.details.length) && (
                                                            <ul className="order-item-details">
                                                                {beverage.details.map((detail) => (
                                                                    <li>
                                                                        {detail.description}{(detail.price != '0.00') && <span> - {parseFloat(detail.price).toFixed(2)}</span>}
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        )}
                                                    </div>
                                                );
                                            })}
                                            {this.props.selectedOrder.orderInformation.specials.map((special) => {
                                                return (
                                                    <div>
                                                        <p>{ special.quantity } x { special.name } - { special.totalPrice.toFixed(2) }</p>
                                                        {(special.details.length) && (
                                                            <ul className="order-item-details">
                                                                {special.details.map((detail) => (
                                                                    <li>
                                                                        {detail.description}

                                                                        {(detail.details && detail.details.length) && (
                                                                            <ul>
                                                                                {detail.details.map((subDetail) => {

                                                                                    let subDetailText = "";
                                                                                    let formattedPrice = "";

                                                                                    let subDetailPrice = subDetail.match(/\d\.\d+/gm);
                                                                                    if(subDetailPrice && subDetailPrice.length == 1){
                                                                                        formattedPrice = parseFloat(subDetailPrice[0]).toFixed(2);
                                                                                        subDetailText = subDetail.slice(0, subDetail.length - subDetailPrice[0].length) + formattedPrice;
                                                                                    }else{
                                                                                        subDetailText = subDetail;
                                                                                    }

                                                                                    return (
                                                                                        <li>{subDetailText}</li>
                                                                                    );
                                                                                })}
                                                                            </ul>
                                                                        )}
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        )}
                                                    </div>
                                                );
                                            })}
                                            {this.props.selectedOrder.orderInformation.addons.map((addon) => {
                                                return(
                                                    <div>
                                                        <p>{ addon.quantity } x { addon.name } - { addon.totalPrice.toFixed(2) }</p>
                                                        {(addon.details.length > 0) && (
                                                            <ul className="order-item-details">
                                                                {addon.details.map((detail) => (
                                                                    <li>
                                                                        {detail.name}{(detail.price != '0.00') && <span> - {parseFloat(detail.price).toFixed(2)}</span>}
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        )}
                                                    </div>
                                                );
                                            })}                                            
                                        </div>
                                        
                                        <p className="order-feed-details-totals">
                                            <span><strong>Subtotal:</strong>&nbsp;{this.props.selectedOrder.order.subtotal}<br /></span>
                                            {(this.props.selectedOrder.order.promo_total > 0) && <span className="view-order-redeem"><strong>Promo ({this.props.selectedOrder.order.promo_name}):</strong>&nbsp;{this.props.selectedOrder.order.promo_total}<br /></span>}
                                            {(this.props.selectedOrder.order.loyalty_redeem_amount > 0) && <span className="view-order-redeem"><strong>Loyalty Reward Redeemed:</strong>&nbsp;{this.props.selectedOrder.order.loyalty_redeem_name} - {this.props.selectedOrder.order.loyalty_redeem_amount}<br /></span>}
                                            {(this.props.selectedOrder.order.add_taxes) && <span><strong>Taxes:</strong>&nbsp;{this.props.selectedOrder.order.taxes}<br /></span>}
                                            {(this.props.selectedOrder.order.additional_charge > 0) && <span><strong>Order Charge:</strong>&nbsp;{this.props.selectedOrder.order.additional_charge}<br /></span>}
                                            {(this.props.selectedOrder.order.delivery_charge > 0) && <span><strong>Delivery:</strong>&nbsp;{this.props.selectedOrder.order.delivery_charge}<br /></span>}
                                            {(this.props.selectedOrder.order.fuel_surcharge > 0) && <span><strong>Service Fee:</strong>&nbsp;{this.props.selectedOrder.order.fuel_surcharge}<br /></span>}
                                            {(this.props.selectedOrder.order.tip_amount > 0) && <span><strong>Tip:</strong>&nbsp;{this.props.selectedOrder.order.tip_amount}<br /></span>}
                                            {(this.props.selectedOrder.order.taxes > 0) && <span><strong>Tax:</strong>&nbsp;{this.props.selectedOrder.order.taxes}<br /></span>}
                                            <strong>Total:</strong>&nbsp;{this.props.selectedOrder.order.total}
                                        </p>

                                        <div className="order-feed-details-update">
                                            <p className="order-feed-details-heading">
                                                Update Order
                                            </p>

                                            {(this.props.selectedOrder.order.order_status == 'new' || this.props.selectedOrder.order.order_status == 'pending') && (
                                                <div className="order-feed-details-buttons">
                                                    <Button
                                                        key="confirm"
                                                        type="button"
                                                        variant="contained"
                                                        size="large"
                                                        className="btn-order-status-complete order-feed-details-button"
                                                        style={{
                                                            backgroundColor: '#0eac1e',
                                                            color: '#fff'
                                                        }}
                                                        onClick={() => { this.openConfirmOrder() }}
                                                    >
                                                        Confirm
                                                    </Button>   

                                                    <Button
                                                        key="reject"
                                                        type="button"
                                                        variant="contained"
                                                        size="large"
                                                        className="btn-order-status-warning order-feed-details-button"
                                                        style={{
                                                            backgroundColor: '#c21111',
                                                            color: '#fff'
                                                        }}
                                                        onClick={() => { this.props.dispatch(rejectOrderSupport(this.props.selectedOrder.order.id, this.props.orders.maxWarningOrderId)) }}
                                                    >
                                                        Reject
                                                    </Button> 
                                                </div>
                                            )}
                                            {(this.props.selectedOrder.order.order_status == 'confirmed') && (
                                                <div className="order-feed-details-buttons">
                                                    <Button
                                                        key="confirm"
                                                        type="button"
                                                        variant="contained"
                                                        size="large"
                                                        className="btn-order-status-complete order-feed-details-button"
                                                        style={{
                                                            backgroundColor: '#0eac1e',
                                                            color: '#fff'
                                                        }}
                                                        onClick={() => { this.openConfirmOrder() }}
                                                    >
                                                        Dispatch
                                                    </Button>   
                                                </div>
                                            )}

                                            {(this.props.selectedOrder.order.warning_getswift) && (
                                                <div className="order-getswift-job-window">
                                                    <p className="order-feed-details-heading">
                                                        GetSwift Job Not Sent
                                                    </p>

                                                    <div className="order-feed-details-buttons">
                                                        <Button
                                                            key="confirm"
                                                            type="button"
                                                            variant="contained"
                                                            color="secondary"
                                                            size="large"
                                                            className="btn-order-status-complete order-feed-details-button"
                                                            onClick={() => { this.resendGetSwift(this.props.selectedOrder.orderId) }}
                                                        >
                                                            Re-Send Job
                                                        </Button>   
                                                    </div>
                                                </div>
                                            )}

                                            {(this.props.selectedOrder.order.order_status != 'new') && (
                                                
                                                <div className="order-getswift-job-window">
                                                    {(this.props.selectedOrderLoading) && (
                                                        <div className="load-screen">
                                                            <CircularProgress className="loader orders-loader" size={100} />
                                                        </div>
                                                    )}
                                                    {(!this.props.selectedOrderLoading) && (
                                                        <div>
                                                            <p className="order-feed-details-heading">
                                                                Send To Customer
                                                            </p>

                                                            { this.props.selectedOrderError && (
                                                                <Typography className="modal-error">
                                                                    { this.props.selectedOrderError }
                                                                </Typography>
                                                            )}

                                                            { this.props.selectedOrderMessage && (
                                                                <Typography className="modal-success">
                                                                    { this.props.selectedOrderMessage }
                                                                </Typography>
                                                            )}

                                                            <div className="order-feed-details-buttons">
                                                                <Button
                                                                    key="confirm"
                                                                    type="button"
                                                                    variant="contained"
                                                                    color="secondary"
                                                                    size="large"
                                                                    className="btn-order-status-complete order-feed-details-button"
                                                                    style={{
                                                                        backgroundColor: '#0eac1e',
                                                                        color: '#fff'
                                                                    }}
                                                                    onClick={() => { this.resendReceipt(this.props.selectedOrder.orderId) }}
                                                                >
                                                                    Re-send Email Receipt
                                                                </Button>   
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>

                    <div className="window-fab">
                        <Fab className="btn-fab btn-add" onClick={() => this.toggleSearchModal(true)}>
                            <SearchIcon />
                        </Fab>
                    </div>
                </div>

                <OrderConfirm 
                    order={this.props.selectedOrder.order}
                    restaurantId={(this.props.selectedOrder.order.restaurant && this.props.selectedOrder.order.restaurant.id) ? this.props.selectedOrder.order.restaurant.id : 0}
                    confirmOrder={(this.props.selectedOrder.order.order_status == 'confirmed') ? this.readyOrder : this.confirmOrder}
                />

                <SearchOrders 
                    submitForm={this.submitSearchOrders}
                />
            </div>
        );
    }
};

const mapStateToProps = (state, props) => {
    return {
        loading: state.orders.loading,
        user: state.loggedUser.user,
        restaurant: state.selectedRestaurant.restaurant,
        orders: state.orders,
        selectedOrder: state.selectedOrder,
        selectedOrderError: state.selectedOrder.orderReceiptError,
        selectedOrderMessage: state.selectedOrder.orderReceiptMessage,
        selectedOrderLoading: state.selectedOrder.orderReceiptLoading,
        confirmModalOpen: state.modals.orderConfirm,
        searchModalOpen: state.modals.orderSearch
    };
};

export default connect(mapStateToProps)(SupportOrdersPage);