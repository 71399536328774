import React from 'react';
import { connect } from 'react-redux';
import arrayMove from 'array-move';

import Typography from '@material-ui/core/Typography';

import SortableSettingsList from './SortableSettingsList';
import SettingForm from './SettingForm';

import { 
    populateNewSauceFormSizes, 
    populateUpdateSauceFormSizes, 
    sauceFormUpdate, 
    sauceFormUpdatePrice,
    sauceFormError, 
    sauceFormReset, 
    sauceFormSet } from '../../../../actions/menu/pizzas/sauceForm';
import { settingsFormUpdate, settingsFormAddSauce, settingsFormUpdateSauce, settingsFormRemoveSauce } from '../../../../actions/menu/pizzas/settingsForm';

import validateCurrency from '../../../../utils/validateCurrency';

class ConfigSauces extends React.Component {

    constructor(props) {
        super(props);

        this.handleAddSauceView = this.handleAddSauceView.bind(this);
        this.handleAddSauce = this.handleAddSauce.bind(this);
        this.handleUpdateSauceView = this.handleUpdateSauceView.bind(this);
        this.handleUpdateSauce = this.handleUpdateSauce.bind(this);
        this.handleCancelButton = this.handleCancelButton.bind(this);
        this.handleRemoveSauce = this.handleRemoveSauce.bind(this);
        this.handleUpdateSauceForm = this.handleUpdateSauceForm.bind(this);
        this.handleUpdateSauceFormPrice = this.handleUpdateSauceFormPrice.bind(this);
        this.onSortEnd = this.onSortEnd.bind(this);

        this.state = {
            saucesDisplayView: 'list'
        };
    }

    componentDidMount() {
    }

    handleUpdateSauceForm(sauce) {
        this.props.dispatch(sauceFormUpdate(sauce));
    }

    handleUpdateSauceFormPrice(price) {
        this.props.dispatch(sauceFormUpdatePrice(price));
    }

    onSortEnd({oldIndex, newIndex})  {

        let newSauceOrder = arrayMove(this.props.settingsForm.sauces, oldIndex, newIndex);
        for (let i = 0; i < newSauceOrder.length; i++) {
            newSauceOrder[i]['sauce_order'] = i + 1;
        }

        this.props.dispatch(settingsFormUpdate({ prop: 'sauces', value: newSauceOrder }));
    }

    handleAddSauce() {
        //error checking

        if (this.props.sauceForm.name.trim() == '') {
            this.props.dispatch(sauceFormError('Please enter a sauce name'));
        }
        else {
            let valid = true;

            // go through each price and determine if it is valid
            for (let i = 0; i < this.props.sauceForm.prices.length; i++) {

                // ignore any blank rows
                if (!validateCurrency(this.props.sauceForm.prices[i].price)) {
                    valid = false;
                    this.props.dispatch(sauceFormError("Please ensure that all sizes have a valid price."));
                }
            }

            if (valid) {

                this.props.dispatch(sauceFormError(""));
                
                // add the sauce
                this.props.dispatch(settingsFormAddSauce(this.props.sauceForm));

                // reset the form and return to the main list view
                this.props.dispatch(sauceFormReset());
                this.setState(() => ({ saucesDisplayView: 'list' }));
            }
        }
    }

    handleUpdateSauce() {

        //error checking
        if (this.props.sauceForm.name.trim() == '') {
            this.props.dispatch(sauceFormError('Please enter a sauce name'));
        }
        else {
            let valid = true;

            // go through each price and determine if it is valid
            for (let i = 0; i < this.props.sauceForm.prices.length; i++) {

                // ignore any blank rows
                if (!validateCurrency(this.props.sauceForm.prices[i].price)) {
                    valid = false;
                    this.props.dispatch(sauceFormError("Please ensure that all sizes have a valid price."));
                }
            }

            if (valid) {
                this.props.dispatch(sauceFormError(""));
                
                // update the choice
                this.props.dispatch(settingsFormUpdateSauce(this.props.sauceForm, this.props.sauceForm.index));

                // reset the form and return to the main list view
                this.props.dispatch(sauceFormReset());
                this.setState(() => ({ saucesDisplayView: 'list' }));
            }
        }
    }

    handleRemoveSauce(index) {
        if (confirm("Remove this sauce?")) {
            this.props.dispatch(settingsFormRemoveSauce(index));
        }
    }

    handleAddSauceView() {
        this.props.dispatch(sauceFormReset());
        this.props.dispatch(populateNewSauceFormSizes(this.props.sizes));
        this.setState(() => ({ saucesDisplayView: 'new_sauce' }));
    }

    handleUpdateSauceView(sauce, index) {
        this.props.dispatch(sauceFormSet(sauce, index));
        this.props.dispatch(populateUpdateSauceFormSizes(sauce));
        this.setState(() => ({ saucesDisplayView: 'update_sauce' }));
    }

    handleCancelButton() {
        this.setState(() => ({ saucesDisplayView: 'list' }));
    }

    renderSaucesView() {
        switch (this.state.saucesDisplayView) {

            case 'new_sauce':
                return (
                    <div>
                        <Typography variant="h5" id="modal-title">
                            Add a new Sauce
                        </Typography>
                        
                        <Typography className="modal-description">
                            Enter your sauce information and prices, and click 'Add Sauce'. Please note: you will still need to save the sauces after adding them.
                        </Typography>
                        
                        <SettingForm 
                            form={this.props.sauceForm}
                            handleCancelButton={this.handleCancelButton}
                            handleSubmit={this.handleAddSauce}
                            handleSubmitLabel={'Add Sauce'}
                            updateSettingPrice={this.handleUpdateSauceFormPrice}
                            updateSetting={this.handleUpdateSauceForm}
                        />
                    </div>
                );

            case 'update_sauce':
                return (
                    <div>
                        <Typography variant="h5" id="modal-title">
                            Update Sauce
                        </Typography>
                        
                        <Typography className="modal-description">
                            Update your sauce information, and click 'Update Sauce'. Please note: you will still need to save the sauce after updating.
                        </Typography>

                        <SettingForm 
                            form={this.props.sauceForm}
                            handleCancelButton={this.handleCancelButton}
                            handleSubmit={this.handleUpdateSauce}
                            handleSubmitLabel={'Update Sauce'}
                            updateSettingPrice={this.handleUpdateSauceFormPrice}
                            updateSetting={this.handleUpdateSauceForm}
                        />
                    </div>
                );

            case 'list':
            default:
                return (
                    <div>
                        <Typography variant="h5" id="modal-title">
                            Sauces
                        </Typography>
                        
                        <Typography className="modal-description">
                            Enter any available pizza sauces that you have, and their prices if included as extra sauces.
                        </Typography>

                        <SortableSettingsList 
                            items={this.props.settingsForm.sauces}
                            handleAddView={this.handleAddSauceView}
                            handleUpdateView={this.handleUpdateSauceView}
                            handleRemoveItem={this.handleRemoveSauce}
                            emptyLabel={"No sauces."}
                            addLabel={"Add Sauce"}
                            onSortEnd={this.onSortEnd}
                        />
                    </div>
                );
        }
    }

    render() {
        return (
            <div>
                {this.renderSaucesView()}
                {this.state.saucesDisplayView == 'list' && this.props.children}
            </div>
        );
    }
};

const mapStateToProps = (state, props) => {
    return {
        settingsForm: state.pizzaSettingsForm,
        sizes: state.pizzas.settings.sizes,
        sauceForm: state.sauceForm
    };
};

export default connect(mapStateToProps)(ConfigSauces);