// Get filtered locations

export default (locations, { text="" }) => {

    return locations.filter((location) => {

        // match the text search
        return location.address.toLowerCase().includes(text.toLowerCase()) || location.city.toLowerCase().includes(text.toLowerCase());

    });
};