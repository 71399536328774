import React from 'react';
import { connect } from 'react-redux';
import arrayMove from 'array-move';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import Typography from '@material-ui/core/Typography';

import { settingsFormUpdate, settingsFormUpdateSize, settingsFormAddSize, settingsFormRemoveSize } from '../../../../actions/menu/pizzas/settingsForm';

import SortableContainer from '../../../common/sort/SortableContainer';
import SortableHandle from '../../../common/sort/SortableHandle';
import SortableItem from '../../../common/sort/SortableItem';

class ConfigSizes extends React.Component {

    constructor(props) {
        super(props);

        this.addSize = this.addSize.bind(this);
        this.onSortEnd = this.onSortEnd.bind(this);
    }

    onSortEnd({oldIndex, newIndex})  {

        let newSelectionOrder = arrayMove(this.props.pizzaSettingsForm.sizes, oldIndex, newIndex);
        for (let i = 0; i < newSelectionOrder.length; i++) {
            newSelectionOrder[i]['size_order'] = i + 1;
        }

        this.props.dispatch(settingsFormUpdate({ prop: 'sizes', value: newSelectionOrder }));
    }

    addSize() {
        this.props.dispatch(settingsFormAddSize({
            size_name: '',
            default_price: '0.00'
        }));
    }

    deleteSize(index) {
        this.props.dispatch(settingsFormRemoveSize(index));
    }
    
    render() {

        return (
            <div>
                {this.props.pizzaSettingsForm.sizes.length <= 0 && (
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography className="no-results">No sizes entered.</Typography>
                        </Grid>
                    </Grid>
                )}
                {this.props.pizzaSettingsForm.sizes.length > 0 && (
                    <SortableContainer helperClass='sortableHelper' onSortEnd={this.onSortEnd} useDragHandle>
                        {this.props.pizzaSettingsForm.sizes.map((size, index) => (
                            <SortableItem key={"settingssize-"+index} index={index}>
                                <Grid key={"item-"+index} container>
                                    <Grid item xs={1} className="list-sort">
                                        <SortableHandle />
                                    </Grid>
                                    <Grid item xs={5} className="form-grid">
                                        <TextField
                                            label="Name"
                                            className="modal-input"
                                            fullWidth={true}
                                            value={size.size_name}
                                            onChange={(e) => { this.props.dispatch(settingsFormUpdateSize({ prop: 'size_name', index, value: e.target.value })); }}
                                            margin="normal"
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={5} className="form-grid">
                                        <TextField
                                            label="Price"
                                            className="modal-input"
                                            fullWidth={true}
                                            value={size.default_price}
                                            onChange={(e) => { this.props.dispatch(settingsFormUpdateSize({ prop: 'default_price', index, value: e.target.value })); }}
                                            margin="normal"
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={1} className="form-grid">
                                        <Button variant="contained" fullWidth={true} color="primary" className="modal-button-list" onClick={() => this.deleteSize(index)}>
                                            <DeleteIcon className="modal-button-icon" />
                                        </Button>
                                    </Grid>
                                </Grid>
                            </SortableItem>
                        ))}
                    </SortableContainer>
                )}
                <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom" onClick={this.addSize}>
                    <AddIcon />
                </Button>
            </div>
        );
    }
};

const mapStateToProps = (state, props) => {
    return {
        pizzaSettingsForm: state.pizzaSettingsForm
    };
};

export default connect(mapStateToProps)(ConfigSizes);