// the default items that we can possibly filter by
const foodItemsDefaultState = {
    loading: true,
    foodItems: [],
    allFoodItems: [],
    filter: ''
};

export default (state = foodItemsDefaultState, action) => {
    switch (action.type) {
        case 'SET_FOOD_ITEMS':
            return {
                ...state,
                loading: false,
                foodItems: action.foodItems,
                filter: ''
            };

        case 'SET_ALL_FOOD_ITEMS':
            return {
                ...state,
                allFoodItems: action.foodItems,
            };

        case 'SET_FOOD_ITEM_FILTER':
            return {
                ...state,
                filter: action.text
            };

        case 'SET_FOOD_ITEMS_LOADING':
            return {
                ...state,
                loading: true
            };
            
        case 'RESET_FOOD_ITEMS':
            return foodItemsDefaultState;

        default:
            return state;
    }
};