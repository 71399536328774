import React from 'react';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import DeleteIcon from '@material-ui/icons/Delete';
import TextField from '@material-ui/core/TextField';

import SortableContainer from '../../../common/sort/SortableContainer';
import SortableHandle from '../../../common/sort/SortableHandle';
import SortableItem from '../../../common/sort/SortableItem';

class SortableModifierItem extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    renderEnableButton(item) {
        if (item.enabled) {
            return (
                <Button fullWidth={true} color="secondary" className="modal-button-list" onClick={() => this.props.toggleItemEnabled(item.index, 0)}>
                    Disable
                </Button>
            );
        }
        else {
            return (
                <Button fullWidth={true} color="primary" className="modal-button-list" onClick={() => this.props.toggleItemEnabled(item.index, 1)}>
                    Enable
                </Button>
            );
        }
    }

    render() {
        return (
            <SortableContainer helperClass='sortableHelper' onSortEnd={this.props.onSortEnd} useDragHandle>
                <Grid container>
                    <Grid item xs={12} className="sortable-choice-list">
                        {this.props.items.filter((item, index) => {
                            return item.deleted == 0;
                        }).map((item, index) => (
                            <SortableItem key={"settingssize-"+index} index={item.index}>
                                <Grid key={"item-"+index} className={(item.enabled) ? "sortable-menu-item" : "sortable-menu-item disabled-item"} container>
                                    <Grid item xs={1} className="list-sort">
                                        <SortableHandle />
                                    </Grid>
                                    <Grid item xs={5} className="form-grid">
                                        <TextField
                                            label="Name"
                                            className="modal-input"
                                            fullWidth={true}
                                            value={item.name}
                                            onChange={(e) => { this.props.handleUpdateItem('name', item.index, e.target.value ); }}
                                            margin="normal"
                                            variant="outlined"
                                        />
                                    </Grid>
                                    {item.prices.filter((item, index) => {
                                        return item.deleted == 0;
                                    }).map((price, priceIndex) => (
                                        <Grid key={"priceIndex-"+priceIndex} item xs={1} className="form-grid">
                                            <TextField
                                                label={this.props.priceLevels[price.index].name}
                                                className="modal-input"
                                                fullWidth={true}
                                                value={price.price}
                                                onChange={(e) => { this.props.handleUpdatePrice('price', item.index, price.index, e.target.value ); }}
                                                margin="normal"
                                                variant="outlined"
                                            />
                                        </Grid>
                                    ))}
                                    <Grid item xs={1} className="list-sort">
                                        {this.renderEnableButton(item)}
                                    </Grid>
                                    <Grid item xs={1} className="list-sort">
                                        <Button fullWidth={true} color="secondary" onClick={() => this.props.handleDeleteItem(item.index)}>
                                            <DeleteIcon />
                                        </Button>
                                    </Grid>
                                </Grid>
                            </SortableItem>
                        ))}
                    </Grid>
                </Grid>
            </SortableContainer>
        );
    }
};

export default connect()(SortableModifierItem);