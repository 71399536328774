const forgotPasswordFormDefaultState = {
    emailAddress: '',
    message: '',
    error: '',
};

export default (state = forgotPasswordFormDefaultState, action) => {
    switch (action.type) {
        case 'FORGOT_PASSWORD_FORM_UPDATE':
            return {
                ...state,
                [action.field.prop]: action.field.value
            };

        case 'FORGOT_PASSWORD_FORM_ERROR':
            return {
                ...state,
                message: '',
                error: action.message
            }

        case 'FORGOT_PASSWORD_FORM_RESET':
            return forgotPasswordFormDefaultState;

        default:
            return state;
    }
};