// the default items that we can possibly filter by
const restaurantsDefaultState = {
    loading: true,
    restaurants: [],
    filter: ''
};

export default (state = restaurantsDefaultState, action) => {
    switch (action.type) {
        case 'SET_RESTAURANTS':
            return {
                ...state,
                loading: false,
                restaurants: action.restaurants,
                filter: ''
            };

        case 'SET_RESTAURANT_FILTER':
            return {
                ...state,
                filter: action.text
            };

        case 'TOGGLE_RESTAURANTS_LOADING':
            return {
                ...state,
                loading: action.loading
            };
            
        case 'RESET_RESTAURANTS':
            return restaurantsDefaultState;

        default:
            return state;
    }
};