export const loyaltyPointsFormUpdate = ({ prop, value }) => {

    return {
        type: 'LOYALTY_POINTS_FORM_UPDATE',
        user: { prop, value }
    };
};

export const loyaltyPointsFormError = (message) => {

    return {
        type: 'LOYALTY_POINTS_FORM_ERROR',
        message
    };
};

export const loyaltyPointsFormSuccess = (message) => {

    return {
        type: 'LOYALTY_POINTS_FORM_SUCCESS',
        message
    };
};

export const loyaltyPointsFormReset = () => {
    
    return {
        type: 'LOYALTY_POINTS_FORM_RESET',
    };
}
