import React from 'react';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import SortableContainer from '../../../common/sort/SortableContainer';
import SortableHandle from '../../../common/sort/SortableHandle';
import SortableItem from '../../../common/sort/SortableItem';


class FoodItemModifiersList extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    render(){
        return (
            <div>
                {this.props.foodItemForm.modifiers.length <= 0 && (
                    <p>No modifiers added.</p>
                )}

                {this.props.foodItemForm.modifiers.length > 0 && (
                    <SortableContainer helperClass='sortableHelper' onSortEnd={this.props.onModifierSortEnd} useDragHandle>
                        <Grid container>
                            <Grid item xs={12} className="sortable-choice-list">
                                {this.props.foodItemForm.modifiers.map((item, index) => (
                                    <SortableItem key={"settingssize-"+index} index={index}>
                                        <Grid key={"item-"+index} className={(item.modifier.enabled) ? "sortable-menu-item" : "sortable-menu-item disabled-item"} container>
                                            <Grid item xs={1} className="list-sort">
                                                <SortableHandle />
                                            </Grid>
                                            <Grid item xs={8} className="list-name">
                                                <Typography>{item.modifier.name}</Typography>
                                            </Grid>
                                            <Grid item xs={1} className="list-name red-text">
                                                <Typography>{(item.modifier.enabled) ? "" : "Disabled"}</Typography>
                                            </Grid>
                                            <Grid item xs={1} className="list-sort">
                                                <Button fullWidth={true} color="primary" onClick={() => this.props.handleUpdateModifierView(item, index)}>
                                                    <EditIcon />
                                                </Button>
                                            </Grid>
                                            <Grid item xs={1} className="list-sort">
                                                <Button fullWidth={true} color="secondary" onClick={() => this.props.handleRemoveModifier(index)}>
                                                    <DeleteIcon />
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </SortableItem>
                                ))}
                            </Grid>
                        </Grid>
                    </SortableContainer>
                )}
                <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom" onClick={this.props.handleAddModifierView}>
                    Add existing Modifier
                </Button>
            </div>
        );
    }

};

const mapStateToProps = (state, props) => {
    return {
        foodItemForm: state.foodItemForm
    };
};

export default connect(mapStateToProps)(FoodItemModifiersList);