import React from 'react';
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';

import { locationFormUpdate } from '../../../actions/restaurant/locationForm';

class LocationInfo extends React.Component {

    constructor(props) {
        super(props);
        this.state = { pictures: [] };
    }
    
    
    render() {
        return (
            <Grid container>
                <Grid item xs={12} className="form-grid">
                    <Typography variant="h3">
                        General Location Info
                    </Typography>
                </Grid>
                <Grid item xs={8} className="form-grid">
                    <TextField
                        label="Address"
                        className="modal-input"
                        fullWidth={true}
                        value={this.props.locationForm.address}
                        onChange={(e) => { this.props.dispatch(locationFormUpdate({ prop: 'address', value: e.target.value })); }}
                        margin="normal"
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={4} className="form-grid">
                    <TextField
                        label="Unit No."
                        className="modal-input"
                        fullWidth={true}
                        value={this.props.locationForm.address2}
                        onChange={(e) => { this.props.dispatch(locationFormUpdate({ prop: 'address2', value: e.target.value })); }}
                        margin="normal"
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={4} className="form-grid">
                    <TextField
                        label="City"
                        className="modal-input"
                        fullWidth={true}
                        value={this.props.locationForm.city}
                        onChange={(e) => { this.props.dispatch(locationFormUpdate({ prop: 'city', value: e.target.value })); }}
                        margin="normal"
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={4} className="form-grid">
                    <InputLabel className="input-label-mb"></InputLabel>
                    <Select
                        label="Province"
                        fullWidth={true}
                        margin="none"
                        variant="outlined"
                        value={this.props.locationForm.province}
                        onChange={(e) => { this.props.dispatch(locationFormUpdate({ prop: 'province', value: e.target.value })); }}
                    >
                        <MenuItem key={'1'} value={'1'}>British Columbia</MenuItem>
                        <MenuItem key={'2'} value={'2'}>Alberta</MenuItem>
                        <MenuItem key={'3'} value={'3'}>Saskatchewan</MenuItem>
                        <MenuItem key={'4'} value={'4'}>Manitoba</MenuItem>
                        <MenuItem key={'5'} value={'5'}>Ontario</MenuItem>
                        <MenuItem key={'6'} value={'6'}>Quebec</MenuItem>
                        <MenuItem key={'7'} value={'7'}>New Brunswick</MenuItem>
                        <MenuItem key={'8'} value={'8'}>Nova Scotia</MenuItem>
                        <MenuItem key={'9'} value={'9'}>Prince Edward Island</MenuItem>
                        <MenuItem key={'10'} value={'10'}>Newfoundland</MenuItem>
                        <MenuItem key={'11'} value={'11'}>Yukon Territory</MenuItem>
                        <MenuItem key={'12'} value={'12'}>Northwest Territories</MenuItem>
                        <MenuItem key={'13'} value={'13'}>Nunavut</MenuItem>
                    </Select>
                </Grid>
                <Grid item xs={4} className="form-grid">
                    <TextField
                        label="Postal Code"
                        className="modal-input"
                        fullWidth={true}
                        value={this.props.locationForm.postal}
                        onChange={(e) => { this.props.dispatch(locationFormUpdate({ prop: 'postal', value: e.target.value })); }}
                        margin="normal"
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={6} className="form-grid">
                    <TextField
                        label="Phone"
                        className="modal-input"
                        fullWidth={true}
                        value={this.props.locationForm.phone}
                        onChange={(e) => { this.props.dispatch(locationFormUpdate({ prop: 'phone', value: e.target.value })); }}
                        margin="normal"
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={6} className="form-grid">
                    <TextField
                        label="Alternate Phone"
                        className="modal-input"
                        fullWidth={true}
                        value={this.props.locationForm.phone2}
                        onChange={(e) => { this.props.dispatch(locationFormUpdate({ prop: 'phone2', value: e.target.value })); }}
                        margin="normal"
                        variant="outlined"
                    />
                </Grid>

                <Grid item xs={12} className="form-grid">
                    <Typography variant="h3">
                        Accepted Payment Type
                    </Typography>
                </Grid>

                <Grid item xs={12} className="form-grid">
                    <InputLabel className="input-label-mb"></InputLabel>
                    <Select
                        label="Accepted Payment"
                        fullWidth={true}
                        margin="none"
                        variant="outlined"
                        value={this.props.locationForm.payment_type}
                        onChange={(e) => { this.props.dispatch(locationFormUpdate({ prop: 'payment_type', value: e.target.value })); }}
                    >
                        <MenuItem key={'ONLINE'} value={'ONLINE'}>Online Payment Only</MenuItem>
                        <MenuItem key={'IN STORE'} value={'IN STORE'}>In-Store Payment Only</MenuItem>
                        <MenuItem key={'BOTH'} value={'BOTH'}>Both Online and In-Store Payment</MenuItem>
                    </Select>
                </Grid>


                <Grid item xs={12} className="form-grid">
                    <Typography variant="h3">
                        Order Settings
                    </Typography>
                </Grid>

                <Grid item xs={3} className="form-grid">
                    <FormControlLabel
                        control={
                        <Checkbox
                            checked={this.props.locationForm.add_taxes}
                            onChange={(e) => { this.props.dispatch(locationFormUpdate({ prop: 'add_taxes', value: !this.props.locationForm.add_taxes })); }}
                            name={"Add Tax Charges?"}
                            color="primary"
                        />
                        }
                        label={"Add Tax Charges?"}
                        className="switch-window"
                    />
                </Grid>

                <Grid item xs={3} className="form-grid">
                    <FormControlLabel
                        control={
                        <Checkbox
                            checked={this.props.locationForm.ignore_sms_warning}
                            onChange={(e) => { this.props.dispatch(locationFormUpdate({ prop: 'ignore_sms_warning', value: !this.props.locationForm.ignore_sms_warning })); }}
                            name={"Ignore SMS Warnings?"}
                            color="primary"
                        />
                        }
                        label={"Ignore SMS Warnings?"}
                        className="switch-window"
                    />
                </Grid>

                <Grid item xs={3} className="form-grid">
                    <FormControlLabel
                        control={
                        <Checkbox
                            checked={this.props.locationForm.preorder_enabled}
                            onChange={(e) => { this.props.dispatch(locationFormUpdate({ prop: 'preorder_enabled', value: !this.props.locationForm.preorder_enabled })); }}
                            name={"Enable Pre-Ordering?"}
                            color="primary"
                        />
                        }
                        label={"Enable Pre-Ordering?"}
                        className="switch-window"
                    />
                </Grid> 

                <Grid item xs={3} className="form-grid">
                    <FormControlLabel
                        control={
                        <Checkbox
                            checked={this.props.locationForm.promo_code_enable}
                            onChange={(e) => { this.props.dispatch(locationFormUpdate({ prop: 'promo_code_enable', value: !this.props.locationForm.promo_code_enable })); }}
                            name={"Allow Promo Codes"}
                            color="primary"
                        />
                        }
                        label={"Allow Promo Codes"}
                        className="switch-window"
                    />
                </Grid>       

                <Grid item xs={12} className="form-grid">
                    <Typography>Enter this phone number if you would like to receive a warning when an order hasn't been picked up for at least 3 minutes.</Typography>
                    <TextField
                        label="Warning Phone Number"
                        className="modal-input"
                        fullWidth={true}
                        value={this.props.locationForm.warning_phone}
                        onChange={(e) => { this.props.dispatch(locationFormUpdate({ prop: 'warning_phone', value: e.target.value })); }}
                        margin="normal"
                        variant="outlined"
                    />
                </Grid>

                <Grid item xs={12} className="form-grid">
                    <Typography variant="h3">
                        SMS Printer Settings
                    </Typography>
                </Grid>

                <Grid item xs={12} className="form-grid">
                    <InputLabel className="input-label-mb"></InputLabel>
                    <Select
                        label="Printer Template"
                        fullWidth={true}
                        margin="none"
                        variant="outlined"
                        value={this.props.locationForm.printer_template}
                        onChange={(e) => { this.props.dispatch(locationFormUpdate({ prop: 'printer_template', value: e.target.value })); }}
                    >
                        <MenuItem key={'0'} value={'0'}>Original Template</MenuItem>
                        <MenuItem key={'1'} value={'1'}>New Template</MenuItem>
                    </Select>
                </Grid>
            </Grid>
        );
    }
};

const mapStateToProps = (state, props) => {
    return {
        locationForm: state.locationForm,
        curUser: state.loggedUser.user
    };
};

export default connect(mapStateToProps)(LocationInfo);