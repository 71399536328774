import React from 'react';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import HeightIcon from '@material-ui/icons/Height';
import DeleteIcon from '@material-ui/icons/Delete';
import filterActiveLocations from '../../../../utils/filterActiveLocations';
import filterActiveRestaurants from '../../../../utils/filterActiveRestaurants';

import { updateDirectoryLocationOrder } from '../../../../actions/superuser/appDirectories/directories';

import arrayMove from 'array-move';
import verifyLogin from '../../../../utils/verifyLogin';
import SortableMenuList from '../../../menu/common/SortableMenuList';

class UpdateLocationsSortable extends React.Component {

    constructor(props) {
        super(props);

        this.updateSelectedRestaurant = this.updateSelectedRestaurant.bind(this);
        this.onSortEnd = this.onSortEnd.bind(this);

        this.state = {
            selectedRestaurantId: 0,
            selectedRestaurant: {},
            availableLocations: [],
            selectedLocationId: 0
        };
    }

    updateSelectedRestaurant(e) {
        let selectedRestaurant = {},
            availableLocations = [],
            selectedRestaurantId = '';

        // update the selected restaurant from the list of admin restaurants
        for (let i = 0; i < this.props.restaurants.length; i++) {
            if (this.props.restaurants[i].id == e.target.value) {
                selectedRestaurant = this.props.restaurants[i];
                selectedRestaurantId = this.props.restaurants[i].id;
                availableLocations = this.props.restaurants[i].locations;
            }
        }

        return this.setState(() => ({ 
            selectedRestaurantId,
            selectedRestaurant,
            availableLocations
        }));
    }

    onSortEnd({oldIndex, newIndex})  {

        let appDirectoryID = this.props.locations[oldIndex]['app_directory_id'];
        let newDispatchLocationOrder = arrayMove(this.props.locations, oldIndex, newIndex);
        for (let i = 0; i < newDispatchLocationOrder.length; i++) {
            newDispatchLocationOrder[i]['location_order'] = i + 1;
        }

        this.props.dispatch(updateDirectoryLocationOrder(appDirectoryID, newDispatchLocationOrder));
    }

    renderLocationsList() {

        let getCustomName = (item) => {
            return item.location.restaurant.name + " - " + item.location.address;
        }

        return (
            <Grid container>
                <Grid item xs={12}>
                    <br />
                    {this.props.locations.length <= 0 && (
                        <Typography className="no-results">No locations entered.</Typography>
                    )}
                    {this.props.locations.length > 0 && (
                        <SortableMenuList
                            items={this.props.locations}
                            onSortEnd={this.onSortEnd}
                            showImage={false}
                            showEnable={false}
                            showDelete={true}
                            deleteItem={this.props.deleteLocation}
                            showEdit={false}
                            getCustomName={getCustomName}
                        />
                    )}
                </Grid>
            </Grid>
        );
    }
    
    render() {

        return (
            <div>
                <Grid container>
                    <Grid item xs={6} className="form-grid">
                        <Select
                            fullWidth={true}
                            labelId="restaurant"
                            value={this.state.selectedRestaurantId}
                            onChange={this.updateSelectedRestaurant}
                            variant="outlined"
                        >
                            <MenuItem key={'restaurant-empty'} value={0}>Select a Restaurant</MenuItem>
                            {this.props.restaurants.filter(filterActiveRestaurants.bind(this, this.props.locations)).map((restaurant) =>
                                    <MenuItem key={'adminrestaurant-'+restaurant.id} value={restaurant.id}>{restaurant.name}</MenuItem>
                            )}
                        </Select>
                    </Grid>

                    {this.state.availableLocations.length > 0 && (
                        <Grid item xs={6} className="form-grid">
                            <Select
                                fullWidth={true}
                                labelId="restaurant"
                                value={this.state.selectedLocationId}
                                onChange={(e) => { this.setState(() => ({ selectedLocationId: e.target.value })) }}
                                variant="outlined"
                            >
                                <MenuItem key={'location-empty'} value={0}>Select a Location</MenuItem>
                                {this.state.availableLocations.filter(filterActiveLocations.bind(this, this.props.locations)).map((location) => 
                                    <MenuItem key={'adminrestaurantlocation-'+location.id} value={location.id}>{location.address}</MenuItem>
                                )}
                            </Select>
                        </Grid>   
                    )}  

                    <Grid item xs={12} className="form-grid">
                        <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom" onClick={() => { this.props.addLocation(this.state.selectedLocationId) }}>
                            Add Location
                        </Button>
                    </Grid> 
                </Grid>     
                
                {this.renderLocationsList()}
            </div>
        );
    }
};

const mapStateToProps = (state, props) => {
    return {
        restaurants: state.adminRestaurantLocations.restaurants
    };
};

export default connect(mapStateToProps)(UpdateLocationsSortable);