import React from 'react';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';

import { addLoyaltyReward } from '../../../actions/restaurant/loyalty';
import { loyaltyRewardFormError, loyaltyRewardFormReset } from '../../../actions/restaurant/loyaltyRewardForm';
import { openLoyaltyRewardCreate, closeLoyaltyRewardCreate } from '../../../actions/modals';

import CloseIcon from '../../common/modal/CloseIcon';

import Info from './LoyaltyRewardInfo';

class CreateLoyaltyReward extends React.Component {

    constructor(props) {
        super(props);

        this.toggleCreateModal = this.toggleCreateModal.bind(this);
        this.submitCreateLoyaltyReward = this.submitCreateLoyaltyReward.bind(this);
    }

    toggleCreateModal(isOpen) {
        if (isOpen) {
            this.props.dispatch(loyaltyRewardFormReset());
            this.props.dispatch(openLoyaltyRewardCreate());
        }
        else {
            this.props.dispatch(closeLoyaltyRewardCreate());
        }
    }

    submitCreateLoyaltyReward() {

        // perform some error checking
        if (this.props.loyaltyRewardForm.name.trim() == "") {

            // required info isn't present
            this.props.dispatch(loyaltyRewardFormError("Please enter a reward name."));
        }
        else if ((typeof this.props.loyaltyRewardForm.value === 'string' && !this.props.loyaltyRewardForm.value.match(/^\d+(\.\d+)?$/)) || 
            parseFloat(this.props.loyaltyRewardForm.value) <= 0) {

            // phone numbers entered aren't valid
            this.props.dispatch(loyaltyRewardFormError("Please enter a valid dollar value greater than $0.00."));
        }
        else if ((typeof this.props.loyaltyRewardForm.points === 'string' && !this.props.loyaltyRewardForm.points.match(/^\d+$/)) || 
                    parseInt(this.props.loyaltyRewardForm.points)  <= 0) {


            // warning points chosen isn't valid
            this.props.dispatch(loyaltyRewardFormError("Please enter a valid points value greater than 0."));
        }
        else {
            this.props.dispatch(loyaltyRewardFormError(""));
            this.props.dispatch(addLoyaltyReward(this.props.restaurantId, this.props.loyaltyRewardForm));
        }
    }
    
    render() {
        return (
            <Modal
                aria-labelledby="loyaltyReward-add"
                aria-describedby="loyaltyReward-add"
                open={this.props.createModalOpen}
                onClose={() => this.toggleCreateModal(false)}
                className="modal_admin"
                disableBackdropClick
            >
                <div className="modal-member">
                    <Typography variant="h4" id="modal-title">
                        <CloseIcon/>
                    </Typography>
                    <Typography variant="h2">
                        Add a New Loyalty Reward
                    </Typography>

                    { this.props.loyaltyRewardForm.error && (
                        <Typography className="modal-error">
                            { this.props.loyaltyRewardForm.error }
                        </Typography>
                    )}

                    {(this.props.loading) && (
                        <div className="page-loading">
                            <CircularProgress size={100} />
                        </div>
                    )}
                    {(!this.props.loading) && (
                        <div>
                            <Info
                                ratio={this.props.ratio}
                            />
                    
                            <Button variant="contained" fullWidth={true} className="modal-button-bottom" onClick={this.submitCreateLoyaltyReward}>
                                Add Reward
                            </Button>
                        </div>
                    )}
                </div>
            </Modal>
        );
    }
};

const mapStateToProps = (state, props) => {
    return {
        loading: state.loyaltyRewardForm.loading,
        restaurant: state.selectedRestaurant.restaurant,
        loyaltyRewardForm: state.loyaltyRewardForm,
        createModalOpen: state.modals.loyaltyRewardCreate
    };
};

export default connect(mapStateToProps)(CreateLoyaltyReward);