import React from 'react';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

import LoadScreen from '../common/LoadScreen';
import verifyLogin from '../../utils/verifyLogin';
import { loadAppDirectories, setAppDirectoryFilter, setSelectedAppDirectory } from '../../actions/superuser/appDirectories/directories';
import filterAppDirectories from '../../utils/filterListName';
import { appDirectoryFormReset, appDirectoryFormUpdate } from '../../actions/superuser/appDirectories/appDirectoryForm';
import { openAppDirectoryCreate, closeAppDirectoryCreate, openAppDirectoryUpdate, closeAppDirectoryUpdate } from '../../actions/modals';

import CreateAppDirectory from './modal/CreateAppDirectory';
import UpdateAppDirectory from './modal/UpdateAppDirectory';

import checkPermissions from '../../utils/checkUserPermissions';

class AppDirectoriesPage extends React.Component {

    constructor(props) {
        super(props);

        this.checkPermissions = this.checkPermissions.bind(this);

        this.state = {
            sidebarMenuOpen: false
        };
    }

    componentDidMount() {
        verifyLogin(this.props.history);
    }

    checkPermissions() {
        if (checkPermissions(this.props.user, 'superuser')) {
            this.props.dispatch(loadAppDirectories());
        } else {
            this.props.history.push('/permission-denied');
        }
    }

    toggleCreateModal(isOpen) {
        if (isOpen) {
            this.props.dispatch(appDirectoryFormReset());
            this.props.dispatch(openAppDirectoryCreate());
        }
        else {
            this.props.dispatch(closeAppDirectoryCreate());
        }
    }

    toggleUpdateModal(isOpen, selectedDirectory) {
        if (isOpen) {
            this.props.dispatch(appDirectoryFormReset());
            this.props.dispatch(setSelectedAppDirectory(selectedDirectory));
            this.props.dispatch(appDirectoryFormUpdate({ prop: 'name', value: selectedDirectory.name }));
            this.props.dispatch(appDirectoryFormUpdate({ prop: 'timezone', value: selectedDirectory.timezone }));

            this.props.dispatch(openAppDirectoryUpdate());
        }
        else {
            this.props.dispatch(closeAppDirectoryUpdate());
        }
    }

    renderAppDirectories() {

        if (this.props.loading) {
            return (
                <div className="page-loading">
                    <CircularProgress size={100} />
                </div>
            );
        }
        else {
            return this.props.directories.map((directory) => (
                <Grid item xs={3} className="restaurant-card-grid" key={"directory-"+directory.id}>
                    <Card className="card restaurant-card">
                        <CardActionArea onClick={() => this.toggleUpdateModal(true, directory)}>
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="h2">
                                    {directory.name}
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                        <CardActions>
                            <Button size="small" color="primary" onClick={() => this.toggleUpdateModal(true, directory)}>
                                Edit
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>
            ));
        }
    }

    render() {
        return (
            <LoadScreen
                checkPermissions={this.checkPermissions}
            >
                <div className="card-container">
                    <Grid container>
                        <Grid item xs={12} className="heading-grid">
                            <h1>App Directories</h1>
                            <FormControl fullWidth>
                                <TextField 
                                    label="Filter App Directories" 
                                    variant="outlined" 
                                    value={this.props.filter}
                                    onChange={(e) => this.props.dispatch(setAppDirectoryFilter(e.target.value))} 
                                />
                            </FormControl>
                        </Grid>
                        {this.renderAppDirectories()}
                    </Grid>
                </div>

                <div className="window-fab">
                    <Fab className="btn-fab btn-add" onClick={() => this.toggleCreateModal(true)}>
                        <AddIcon />
                    </Fab>
                </div>

                <CreateAppDirectory />

                <UpdateAppDirectory />
            </LoadScreen>
        );
    }
};

const mapStateToProps = (state, props) => {
    return {
        loading: state.directories.loading,
        user: state.loggedUser.user,
        directories: filterAppDirectories(state.directories.directories, {text: state.directories.filter})
    };
};

export default connect(mapStateToProps)(AppDirectoriesPage);