// Get filtered managers

export default (managers, { text="" }) => {

    return managers.filter((manager) => {

        // match the text search
        return manager.email.toLowerCase().includes(text.toLowerCase()) || manager.first_name.toLowerCase().includes(text.toLowerCase()) || manager.last_name.toLowerCase().includes(text.toLowerCase());

    });
};