import axios from 'axios';
import { API_URL } from '../../../config/constants';
import { categoryFormReset, categoryFormError, categoryFormSuccess } from './categoryForm';
import { closeCategoryCreate } from '../../modals';
import { setSelectedCategory } from './selectedCategory';

export const setCategories = (categories) => {
    return {
        type: 'SET_CATEGORIES',
        categories
    }
}

export const setCategoryFilter = (text) => {
    return {
        type: 'SET_CATEGORY_FILTER',
        text
    }
}

export const resetCategories = () => {
    return {
        type: 'RESET_CATEGORIES'
    }
}

export const setCategoriesLoading = () => ({
    type: 'SET_CATEGORIES_LOADING'
});

export const loadCategories = (restaurant_id, selectedCategoryId = null) => {
    return (dispatch) => {
        return axios.get(API_URL+'/api/restaurants/'+restaurant_id+'/categories')
            .then(function(res) {
                // add the new restaurant
                dispatch(setCategories(res.data.data.categories));

                if (selectedCategoryId) {

                    // there is a selected category id set; find it and set it
                    for (let i = 0; i < res.data.data.categories.length; i++) {
                        if (res.data.data.categories[i].id == selectedCategoryId) {

                            // found a match; set the selected category
                            dispatch(setSelectedCategory({ category: res.data.data.categories[i] }));
                        }
                    }
                }

            })
            .catch(function(err) {
                console.log("Err!");
                console.log(err);
            });
    };
}

export const addCategory = (restaurant_id, { name, description } = {}) => {
    
    return (dispatch) => {

        dispatch(setCategoriesLoading());
        return axios.post(API_URL+'/api/restaurants/'+restaurant_id+'/categories', { name, description })
            .then(function(res) {
                if (res.data.status == 'success') {
                    dispatch(setCategories(res.data.data.categories));
                    dispatch(categoryFormReset());
                    dispatch(closeCategoryCreate());
                }
                else {
                    dispatch(categoryFormError(res.data.message));
                }
            })
            .catch(function(err) {
                
                // token is likely invalid; remove it and prompt the user to login again
                console.log(err);
                dispatch(categoryFormError("We're sorry, but our system has encountered an issue, to which we have been notified."));
                if (err.response.status === 401) {
                    removeAuthToken(dispatch);
                }
                
            });
    };
};

export const updateCategory = (restaurant_id, category_id, { name, description } = {}) => {
    
    return (dispatch) => {

        dispatch(setCategoriesLoading());
        return axios.post(API_URL+'/api/restaurants/'+restaurant_id+'/categories/'+category_id, { name, description })
            .then(function(res) {
                if (res.data.status == 'success') {
                    dispatch(setCategories(res.data.data.categories));
                    dispatch(categoryFormSuccess('Category Updated!'));
                }
                else {
                    dispatch(categoryFormError(res.data.message));
                    console.log(res.data);
                    console.log("Could not add your category!");
                }
            })
            .catch(function(err) {

                // token is likely invalid; remove it and prompt the user to login again
                dispatch(categoryFormError("We're sorry, but our system has encountered an issue, to which we have been notified."));
                if (err.response.status === 401) {
                    removeAuthToken(dispatch);
                }
                
            });
    };
};

export const updateCategoryOrder = (restaurant_id, categories) => {

    return (dispatch) => {

        dispatch(setCategoriesLoading());

        // prepare the new order of items to move over
        let itemOrder = [];
        for (let i = 0; i < categories.length; i++) {
            itemOrder.push(categories[i].id);
        }

        // prepare the order of categories and IDs to send
        return axios.post(API_URL+'/api/restaurants/'+restaurant_id+'/categories/order', { order: itemOrder })
            .then(function(res) {
                if (res.data.status == 'success') {
                    dispatch(setCategories(res.data.data.categories));
                    dispatch(categoryFormSuccess());
                }
                else {
                    dispatch(categoryFormError(res.data.message));
                    console.log(res.data);
                    console.log("Could not add your category!");
                }
            })
            .catch(function(err) {

                // token is likely invalid; remove it and prompt the user to login again
                dispatch(categoryFormError("We're sorry, but our system has encountered an issue, to which we have been notified."));
                if (err.response.status === 401) {
                    removeAuthToken(dispatch);
                }
                
            });
    };
};

export const toggleCategoryEnabled = (restaurant_id, category_id, enabled) => {
    
    return (dispatch) => {

        dispatch(setCategoriesLoading());
        return axios.post(API_URL+'/api/restaurants/'+restaurant_id+'/categories/'+category_id, { enabled })
            .then(function(res) {
                if (res.data.status == 'success') {
                    dispatch(setCategories(res.data.data.categories));
                }
                else {
                    dispatch(categoryFormError(res.data.message));
                    console.log(res.data);
                    console.log("Could not add your category!");
                }
            })
            .catch(function(err) {

                // token is likely invalid; remove it and prompt the user to login again
                dispatch(categoryFormError("We're sorry, but our system has encountered an issue, to which we have been notified."));
                if (err.response.status === 401) {
                    removeAuthToken(dispatch);
                }
                
            });
    };
};

export const removeCategory = (category_id, restaurant_id) => {

    return (dispatch) => {

        dispatch(setCategoriesLoading());
        return axios.delete(API_URL+'/api/restaurants/'+restaurant_id+'/categories/'+category_id, {})
            .then(function(res) {
                if (res.data.status == 'success') {
                    dispatch(setCategories(res.data.data.categories));
                }
                else {
                    // @TODO: need better messaging here
                    console.log(res.data);
                    console.log("Could not remove your category!");
                }
            })
            .catch(function(err) {

                // token is likely invalid; remove it and prompt the user to login again
                if (err.response.status === 401) {
                    removeAuthToken(dispatch);
                }
                
            });
    };
}