import React from 'react';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { loadAdminRestaurantLocations } from '../../../actions/superuser/restaurantLocations';
import { updateDispatchCenter } from '../../../actions/superuser/dispatchCenters/centers';
import { dispatchCenterFormUpdate, dispatchCenterFormError, dispatchCenterFormReset } from '../../../actions/superuser/dispatchCenters/dispatchCenterForm';
import { closeDispatchCenterUpdate } from '../../../actions/modals';  
import validatePhone from '../../../utils/validatePhone';

import CloseIcon from '../../common/modal/CloseIcon';

import Info from './DispatchCenterInfo';
import Locations from './DispatchCenterLocations';
import Users from './DispatchCenterUsers';
import DeliveryHours from './DispatchCenterDeliveryHours';

class UpdateDispatchCenter extends React.Component {

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.closeUpdateModal = this.closeUpdateModal.bind(this);
        this.updateDispatchCenterInfo = this.updateDispatchCenterInfo.bind(this);
        this.updateDispatchDeliveryHours = this.updateDispatchDeliveryHours.bind(this);

        this.state = {
            displayView: 0
        };
    }

    componentDidMount() {
        this.props.dispatch(loadAdminRestaurantLocations());
    }

    handleChange(event, newValue) {
        this.props.dispatch(dispatchCenterFormUpdate({ prop: 'error', value: '' }));
        this.props.dispatch(dispatchCenterFormUpdate({ prop: 'message', value: '' }));
        this.setState(() => ({displayView: newValue}))
    }

    closeUpdateModal() {
        this.props.dispatch(closeDispatchCenterUpdate());
        this.setState(() => ({displayView: 0}))
    }

    updateDispatchCenterInfo() {

        // perform some error checking
        if (this.props.dispatchCenterForm.name.trim() == "") {

            // required info isn't present
            this.props.dispatch(dispatchCenterFormError("Please enter a dispatch center name."));
        }
        else if (this.props.dispatchCenterForm.phone.trim() != "" && !validatePhone(this.props.dispatchCenterForm.phone.trim())) {

            // required info isn't present
            this.props.dispatch(dispatchCenterFormError("Please enter a valid contact phone number."));
        }
        else if (this.props.dispatchCenterForm.cartwheel_update_enabled && this.props.dispatchCenterForm.cartwheel_team_id.trim() == "") {

            // cartwheel config isn't correct
            this.props.dispatch(locationFormError("Please supply a Team ID when enablind cartwheel."));
        }
        else {

            // clear the error and attempt to update the food item info
            this.props.dispatch(dispatchCenterFormError(""));
            this.props.dispatch(updateDispatchCenter(this.props.dispatchCenter.id, 'info', this.props.dispatchCenterForm));
        }
    }

    updateDispatchDeliveryHours(){
        this.props.dispatch(dispatchCenterFormError(""));
        this.props.dispatch(updateDispatchCenter(this.props.dispatchCenter.id, 'deliveryHours', this.props.dispatchCenterForm));
    }

    renderUpdateModalActiveTab() {
        if (this.props.loading) {
            return (
                <div className="page-loading">
                    <CircularProgress size={100} />
                </div>
            );
        }
        else {
            switch(this.state.displayView) {
                case 0:
                    return this.renderUpdateModalInfo();

                case 1:
                    return this.renderUpdateModalLocations();

                case 2:
                    return this.renderUpdateModalUsers();

                case 3:
                    return this.renderUpdateDeliveryHours();
            }
        }
    }

    renderUpdateFeedback() {
        return (
            <div>
                { this.props.dispatchCenterForm.error && (
                    <Typography className="modal-error">
                        { this.props.dispatchCenterForm.error }
                    </Typography>
                )}

                { this.props.dispatchCenterForm.message && (
                    <Typography className="modal-success">
                        { this.props.dispatchCenterForm.message }
                    </Typography>
                )}
            </div>
        );
    }

    renderUpdateModalInfo() {
        return (
            <div>
                {this.renderUpdateFeedback()}

                <Info />

                <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom" onClick={this.updateDispatchCenterInfo}>
                    Update Directory
                </Button>
            </div>
        );
    }

    renderUpdateModalLocations() {
        return (
            <div>
                {this.renderUpdateFeedback()}

                <Typography variant="h5" id="modal-title">
                    Locations
                </Typography>

                <Locations />
            </div>
        );
    }

    renderUpdateModalUsers() {
        return (
            <div>
                {this.renderUpdateFeedback()}

                <Typography variant="h5" id="modal-title">
                    Dispatch Center Admins
                </Typography>

                <Users />
            </div>
        );
    }

    renderUpdateDeliveryHours() {
        return (
            <div>
                {this.renderUpdateFeedback()}
                <DeliveryHours />
                <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom" onClick={this.updateDispatchDeliveryHours}>
                    Update Delivery Hours
                </Button>
            </div>
        );
    }

    render() {
        return (
            <Modal
                aria-labelledby="dispatch-center-update"
                aria-describedby="dispatch-center-update"
                open={this.props.updateModalOpen}
                onClose={() => this.closeUpdateModal()}
                className="modal_admin"
                disableBackdropClick
            >
                <div className="modal-member">
                    <Typography variant="h4" id="modal-title">
                        <CloseIcon/>
                    </Typography>
                    <Tabs
                        value={this.state.displayView}
                        onChange={this.handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        centered
                    >
                        <Tab label="General Info" />
                        <Tab label="Locations" />
                        <Tab label="Users" />
                        <Tab label="Delivery Hours" />
                    </Tabs>

                    <Typography variant="h2">
                        Update {this.props.dispatchCenter.name}
                    </Typography>

                    {this.renderUpdateModalActiveTab()}
                </div>
            </Modal>
        );
    }
};

const mapStateToProps = (state, props) => {
    return {
        loading: state.dispatchCenters.loading,
        dispatchCenter: state.dispatchCenters.selectedDispatchCenter,
        dispatchCenterForm: state.dispatchCenterForm,
        updateModalOpen: state.modals.dispatchCenterUpdate,
    };
};

export default connect(mapStateToProps)(UpdateDispatchCenter);