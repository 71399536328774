const completeOrderFormDefaultState = {
    loading: false,
    error: ''
};

export default (state = completeOrderFormDefaultState, action) => {

    switch (action.type) {

        case 'COMPLETE_ORDER_FORM_UPDATE':
            return {
                ...state,
                [action.manager.prop]: action.manager.value
            };

        case 'COMPLETE_ORDER_FORM_ERROR':
            return {
                ...state,
                message: '',
                loading: false,
                error: action.message
            }

        case 'COMPLETE_ORDER_FORM_LOADING':
            return {
                ...state,
                loading: true
            }

        case 'COMPLETE_ORDER_FORM_RESET':
            return completeOrderFormDefaultState;

        default:
            return state;
    }
};