const foodItemFormDefaultState = {
    name: '',
    description: '',
    message: '',
    item_image: '',
    thumb_image: '',
    app_view_image: '',
    non_taxable: 0,
    singlePrice: '',
    hasMultiplePrices: false,
    deletedPrices: [],
    prices: [{
        name: '',
        price: '',
        price_order: 0
    }],
    newImage: {},
    deletedChoices: [],
    choices: [],
    deletedAddons: [],
    addons: [],
    modifiers: [],
    deletedModifiers: [],
    error: '',
    activeTab: 0,
    alwaysAvailable: true,
    hours_available: {
        sun_start: '00:00:00',
        sun_end: '00:00:00',
        mon_start: '00:00:00',
        mon_end: '00:00:00',
        tue_start: '00:00:00',
        tue_end: '00:00:00',
        wed_start: '00:00:00',
        wed_end: '00:00:00',
        thu_start: '00:00:00',
        thu_end: '00:00:00',
        fri_start: '00:00:00',
        fri_end: '00:00:00',
        sat_start: '00:00:00',
        sat_end: '00:00:00',
        sun_available: false,
        mon_available: false,
        tue_available: false,
        wed_available: false,
        thu_available: false,
        fri_available: false,
        sat_available: false,
        sun_all_day: false,
        mon_all_day: false,
        tue_all_day: false,
        wed_all_day: false,
        thu_all_day: false,
        fri_all_day: false,
        sat_all_day: false
    },
    scrollPosition: 0,
    saveSchedule: false,
    scheduleName: ''
};

export default (state = foodItemFormDefaultState, action) => {
    
    switch (action.type) {

        case 'FOOD_ITEM_FORM_UPDATE':
            return {
                ...state,
                [action.foodItem.prop]: action.foodItem.value
            };

        case 'FOOD_ITEM_FORM_UPDATE_HOURS_AVAILABLE':
            return {
                ...state,
                hours_available: 
                    {
                        ...state.hours_available,
                        [action.location.prop]: action.location.value
                    }
            };

        case 'FOOD_ITEM_FORM_UPDATE_PRICE':
            return {
                ...state,
                prices: state.prices.map((price, index) => {
                    if (index == action.price.index) {
                        return {
                            ...price,
                            [action.price.prop]: action.price.value
                        }
                    }

                    return price;
                })
            };

        case 'FOOD_ITEM_FORM_ADD_PRICE':
            return {
                ...state,
                prices: state.prices.concat(action.price)
            };

        case 'FOOD_ITEM_FORM_REMOVE_PRICE':
            const deletedPrices = (state.prices[action.index].id) ? state.deletedPrices.concat(state.prices[action.index].id) : state.deletedPrices;

            return {
                ...state,
                deletedPrices,
                prices: state.prices.filter((price, index) => {
                    return index != action.index;
                })
            };

        case 'FOOD_ITEM_FORM_ADD_CHOICE':
            return {
                ...state,
                choices: state.choices.concat(action.choice)
            };

        case 'FOOD_ITEM_FORM_UPDATE_CHOICE':
            return {
                ...state,
                choices: state.choices.map((choice, index) => {
                    if (index == action.index) {
                        return action.choice;
                    }

                    return choice;
                })
            };

        case 'FOOD_ITEM_FORM_REMOVE_CHOICE':
            const deletedChoices = (state.choices[action.index].id) ? state.deletedChoices.concat(state.choices[action.index].id) : state.deletedChoices;

            return {
                ...state,
                deletedChoices,
                choices: state.choices.filter((choice, index) => {
                    return index != action.index;
                })
            };

        case 'FOOD_ITEM_FORM_UPDATE_ADDON':
            return {
                ...state,
                addons: state.addons.map((addon, index) => {
                    if (index == action.addon.index) {
                        return {
                            ...addon,
                            [action.addon.prop]: action.addon.value
                        }
                    }

                    return addon;
                })
            };

        case 'FOOD_ITEM_FORM_ADD_ADDON':
            return {
                ...state,
                addons: state.addons.concat(action.addon)
            };

        case 'FOOD_ITEM_FORM_REMOVE_ADDON':
            const deletedAddons = (state.addons[action.index].id) ? state.deletedAddons.concat(state.addons[action.index].id) : state.deletedAddons;

            return {
                ...state,
                deletedAddons,
                addons: state.addons.filter((addon, index) => {
                    return index != action.index;
                })
            };

        case 'FOOD_ITEM_FORM_ADD_MODIFIER':
            let newModifier = {
                ...action.modifier,
                sort_order: state.modifiers.length + 1
            };
            return {
                ...state,
                modifiers: state.modifiers.concat(newModifier)
            };

        case 'FOOD_ITEM_FORM_UPDATE_MODIFIER':
            return {
                ...state,
                modifiers: state.modifiers.map((modifier, index) => {
                    if (index == action.index) {
                        return action.modifier;
                    }
                    return modifier;
                })
            };

        case 'FOOD_ITEM_FORM_REMOVE_MODIFIER':
            const deletedModifiers = (state.modifiers[action.index].id) ? state.deletedModifiers.concat(state.modifiers[action.index].id) : state.deletedModifiers;

            return {
                ...state,
                deletedModifiers,
                modifiers: state.modifiers.filter((modifier, index) => {
                    return index != action.index;
                }).map((modifier, index) => {
                    return{
                        ...modifier,
                        sort_order: index
                    }
                })
            };

        case 'FOOD_ITEM_FORM_ERROR':
            return {
                ...state,
                message: '',
                error: action.message
            }

        case 'FOOD_ITEM_FORM_SUCCESS':
            return {
                ...state,
                error: '',
                message: action.message
            }

        case 'FOOD_ITEM_FORM_RESET':
            return foodItemFormDefaultState;

        default:
            return state;
    }
};