// Get filtered locations
export const getLocationByID = (locations, location_id) => {
    let selectedLocation = locations.filter((location) => {
        return location.id == location_id;
    });

    if(selectedLocation.length == 1){
        selectedLocation = selectedLocation[0];
    }else{
        selectedLocation = false;
    }
    
    return selectedLocation;
};