import React from 'react';
import { connect } from 'react-redux';
import { registerManagerFormUpdate, registerManagerFormError } from '../actions/registerManagerForm';
import { registerManager, checkPasswordResetTokenForEmail } from '../actions/auth';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Alert from '@material-ui/lab/Alert';
import logo from '../assets/images/logo.png';

import CircularProgress from '@material-ui/core/CircularProgress';

class RegisterManagerPage extends React.Component {

    constructor(props) {
        super(props);   

        this.onSubmitRegister = this.onSubmitRegister.bind(this);

        this.state = {
            email: '',
            password_reset_token: ''
        }
    }

    componentDidMount() {

        //Log them out without redirect upon entering this page?

        if (this.props.match.params.email) {
            this.setState(() => ({email: this.props.match.params.email}));
        }

        if (this.props.match.params.token) {
            this.setState(() => ({password_reset_token: this.props.match.params.token}));
        }

        this.props.dispatch(registerManagerFormUpdate({prop: 'loading', value: true}));
        this.props.dispatch(checkPasswordResetTokenForEmail(this.props.match.params.email, this.props.match.params.token, registerManagerFormError, registerManagerFormUpdate, 
                    'Registration cannot proceed. Please check to see that you have followed the correct link.', 'loading', 'criticalError'));
    }


    onSubmitRegister(e) {
        e.preventDefault();

        if (!this.props.password || !this.props.password2 || !this.props.firstName || !this.props.lastName) {
            this.props.dispatch(registerManagerFormError('Please enter all info before proceeding.'));
        }
        else if (this.props.password != this.props.password2){
            this.props.dispatch(registerManagerFormError('Provided passwords must match.'));
        }
        else if(this.props.password.length < 6){
            this.props.dispatch(registerManagerFormError('Minimum password length must be 6 characters'));
        }
        else {
            this.props.dispatch(registerManager(this.state.email, this.props.password, this.props.password2, 
                            this.props.firstName, this.props.lastName, this.state.password_reset_token, registerManagerFormError, this.props.history));
        }

    }

    render() {

        if (this.props.loading) {
            return (
                <div className="page-loading">
                    <CircularProgress size={100} />
                </div>
            );
        }
        else {
            return (
                <div className="login-form">
                    <div className="paper">
                        <img src={logo} width="150px" />
    
                        <h2>Manager Registration</h2>
    
                        { this.props.criticalError == true && <Alert className="form-error" severity="error">{this.props.error}</Alert> }

                        { this.props.criticalError == false && (<form className="form" onSubmit={this.onSubmitRegister}>
                            { this.props.error && <Alert className="form-error" severity="error">{this.props.error}</Alert> }
                            {(this.state.email) && <input type="hidden" name="email" value={this.state.email} />}
                            {(this.state.password_reset_token) && <input type="hidden" name="password_reset_token" value={this.state.password_reset_token} />}
    
                            <FormControl margin="normal" required fullWidth>
                                <InputLabel htmlFor="firstName">First Name</InputLabel>
                                <Input name="firstName" type="text" id="firstName" value={this.props.firstName} onChange={(e) => this.props.dispatch(registerManagerFormUpdate({ prop: 'firstName', value: e.target.value }))} />
                            </FormControl>
    
                            <FormControl margin="normal" required fullWidth>
                                <InputLabel htmlFor="lastName">Last Name</InputLabel>
                                <Input name="lastName" type="text" id="lastName" value={this.props.lastName} onChange={(e) => this.props.dispatch(registerManagerFormUpdate({ prop: 'lastName', value: e.target.value }))} />
                            </FormControl>
    
                            <FormControl margin="normal" required fullWidth>
                                <InputLabel htmlFor="password">Your New Password (Minimum 6 characters)</InputLabel>
                                <Input name="password" type="password" id="password" value={this.props.password} onChange={(e) => this.props.dispatch(registerManagerFormUpdate({ prop: 'password', value: e.target.value }))} />
                            </FormControl>
    
                            <FormControl margin="normal" required fullWidth>
                                <InputLabel htmlFor="password2">Enter It Again (Minimum 6 characters)</InputLabel>
                                <Input name="password2" type="password" id="password2" value={this.props.password2} onChange={(e) => this.props.dispatch(registerManagerFormUpdate({ prop: 'password2', value: e.target.value }))} />
                            </FormControl>
    
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                size="large"
                                className="submit submit-prim"
                            >
                                Register
                            </Button>
                        </form>)}
                    </div>
                </div>
            );
        }
    }

};

const mapStateToProps = (state, props) => {
    return {
        emailAddress: state.registerManagerForm.emailAddress,
        firstName: state.registerManagerForm.firstName,
        lastName: state.registerManagerForm.lastName,
        password: state.registerManagerForm.password,
        password2: state.registerManagerForm.password2,
        error: state.registerManagerForm.error,
        criticalError: state.registerManagerForm.criticalError,
        loading: state.registerManagerForm.loading
    };
};

export default connect(mapStateToProps)(RegisterManagerPage);