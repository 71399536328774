import React from 'react';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';

import { addDispatchCenter } from '../../../actions/superuser/dispatchCenters/centers';
import { dispatchCenterFormError, dispatchCenterFormReset } from '../../../actions/superuser/dispatchCenters/dispatchCenterForm';
import { openDispatchCenterCreate, closeDispatchCenterCreate } from '../../../actions/modals';
import validatePhone from '../../../utils/validatePhone';

import CloseIcon from '../../common/modal/CloseIcon';

import Info from './DispatchCenterInfo';

class CreateDispatchCenter extends React.Component {

    constructor(props) {
        super(props);

        this.toggleCreateModal = this.toggleCreateModal.bind(this);
        this.submitCreateDispatchCenter = this.submitCreateDispatchCenter.bind(this);
    }

    toggleCreateModal(isOpen) {
        if (isOpen) {
            this.props.dispatch(dispatchCenterFormReset());
            this.props.dispatch(openDispatchCenterCreate());
        }
        else {
            this.props.dispatch(closeDispatchCenterCreate());
        }
    }

    submitCreateDispatchCenter() {

        // perform some error checking
        if (this.props.dispatchCenterForm.name.trim() == "") {

            // required info isn't present
            this.props.dispatch(dispatchCenterFormError("Please enter a dispatch center name."));
        }
        else if (this.props.dispatchCenterForm.phone.trim() != "" && !validatePhone(this.props.dispatchCenterForm.phone.trim())) {

            // required info isn't present
            this.props.dispatch(dispatchCenterFormError("Please enter a valid contact phone number."));
        }
        else if (this.props.dispatchCenterForm.cartwheel_update_enabled && this.props.dispatchCenterForm.cartwheel_team_id.trim() == "") {

            // cartwheel config isn't correct
            this.props.dispatch(locationFormError("Please supply a Team ID when enablind cartwheel."));
        }
        else {

            this.props.dispatch(dispatchCenterFormError(""));
            this.props.dispatch(addDispatchCenter(this.props.dispatchCenterForm));
        }
    }

    render() {
        return (
            <Modal
                aria-labelledby="dispatch-center-add"
                aria-describedby="dispatch-center-add"
                open={this.props.createModalOpen}
                onClose={() => this.toggleCreateModal(false)}
                className="modal_admin"
                disableBackdropClick
            >
                <div className="modal-member">
                    <Typography variant="h4" id="modal-title">
                        Add a Dispatch Center
                        <CloseIcon/>
                    </Typography>

                    { this.props.dispatchCenterForm.error && (
                        <Typography className="modal-error">
                            { this.props.dispatchCenterForm.error }
                        </Typography>
                    )}

                    <Info />

                    <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom" onClick={this.submitCreateDispatchCenter}>
                        Add Dispatch Center
                    </Button>
                </div>
            </Modal>
        );
    }
};

const mapStateToProps = (state, props) => {
    return {
        loading: state.directories.loading,
        restaurant: state.selectedRestaurant.restaurant,
        dispatchCenterForm: state.dispatchCenterForm,
        createModalOpen: state.modals.dispatchCenterCreate
    };
};

export default connect(mapStateToProps)(CreateDispatchCenter);