const userManagementFormDefaultState = {
    first_name: '',
    last_name: '',
    phone: '',
    superuser: false,
    support: false,
    new_password: '',
    message: '',
    error: '',
    loading: false,
    emailSearch: '',
    users: [],
    userHasSearched: false,
};

export default (state = userManagementFormDefaultState, action) => {
    
    switch (action.type) {

        case 'USER_MANAGEMENT_FORM_UPDATE':
            return {
                ...state,
                [action.directory.prop]: action.directory.value
            };

        case 'USER_MANAGEMENT_FORM_ERROR':
            return {
                ...state,
                message: '',
                loading: false,
                error: action.message
            }

        case 'USER_MANAGEMENT_FORM_LOADING':
            return {
                ...state,
                loading: true
            }

        case 'USER_MANAGEMENT_FORM_SUCCESS':
            return {
                ...state,
                error: '',
                loading: false,
                message: action.message
            }

        case "USER_MANAGEMENT_USERS_LIST_UPDATE":
            return {
                ...state,
                users: state.users.map((item) => {
                    if (item.id == action.user.value.id) {
                        return {
                            ...action.user.value,
                        }
                    }

                    return item;
                })
            }

        case 'USER_MANAGEMENT_FORM_RESET':
            return userManagementFormDefaultState;

        default:
            return state;
    }
};