import React from 'react';
import { connect } from 'react-redux';
import DatePicker from "react-datepicker";

import CircularProgress from '@material-ui/core/CircularProgress';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { massPromoCodeAdminFormUpdate, massPromoCodeAdminUpdateValidPromoDays, massPromoCodeAdminFormError, resetEditingMassAdminPromoCode, 
    updateMassPromoCode, addMassPromoCode } from '../../../actions/superuser/massPromoCodeAdminForm';

import { promoCodeAdminFormReset } from '../../../actions/superuser/promoCodeAdminForm';

import { closeMassPromoCodeCreate } from '../../../actions/modals';  
import PromoDaySelection from '../../common/modal/PromoDaySelection'; 

import CloseIcon from '../../common/modal/CloseIcon';
import validateCurrency from '../../../utils/validateCurrency';

class MassPromoCodeModal extends React.Component {

    constructor(props) {
        super(props);

        this.closeUpdateModal = this.closeUpdateModal.bind(this);
        this.handleCancelButton = this.handleCancelButton.bind(this);
        this.updateMassPromoCode = this.updateMassPromoCode.bind(this);

        this.state = {
            displayView: 0
        };
    }

    componentDidMount(){
        massPromoCodeAdminFormUpdate({ prop: 'total_avail', value: 'LIMITED QUANTITY' });
        massPromoCodeAdminFormUpdate({ prop: 'max_uses', value: '1' });
        massPromoCodeAdminFormUpdate({ prop: 'remaining_uses', value: '1' });
    }

    closeUpdateModal() {
        this.props.dispatch(closeMassPromoCodeCreate());
    }

    handleCancelButton() {
        this.props.dispatch(promoCodeAdminFormReset());
        this.props.dispatch(resetEditingMassAdminPromoCode());
        this.props.dispatch(closeMassPromoCodeCreate());
    }

    updateMassPromoCode(){

        //error checking
        if (!validateCurrency(this.props.promoCodesForm.total) || this.props.promoCodesForm.total <= 0) {
            this.props.dispatch(massPromoCodeAdminFormError('Please enter a valid amount greater than 0 for total.'));
        }
        else if (this.props.promoCodesForm.total_avail == "LIMITED DATE" && (!this.props.promoCodesForm.start_time || !this.props.promoCodesForm.end_time)) {
            this.props.dispatch(massPromoCodeAdminFormError('Please enter a start and end date for your promo code if it has limited date availability.'));
        }
        else if (this.props.promoCodesForm.total_avail == "LIMITED DATE" && this.props.promoCodesForm.start_time > this.props.promoCodesForm.end_time) {
            this.props.dispatch(massPromoCodeAdminFormError('Start date must be before end date.'));
        }
        // else if(this.props.promoCodesForm.codes_to_create <= 0){
        //     this.props.dispatch(massPromoCodeAdminFormError('Please enter a valid number of codes to create greater than 0.'));
        // }
        else if(this.props.promoCodesForm.label.trim() == ''){
            this.props.dispatch(massPromoCodeAdminFormError('Please enter a label for this batch of promo codes'));
        }
        else {
            this.props.dispatch(massPromoCodeAdminFormError(""));

            if(this.props.editing){
                // update the promoCode
                this.props.dispatch(updateMassPromoCode(this.props.promoCodesForm, closeMassPromoCodeCreate));
            }else{
                // add the promoCode
                this.props.dispatch(addMassPromoCode(this.props.promoCodesForm, closeMassPromoCodeCreate));
            }
        }

    }

    render() {
        return (
            <Modal
                aria-labelledby="promo-code-create"
                aria-describedby="promo-code-create"
                open={this.props.updateModalOpen}
                onClose={() => this.closeUpdateModal()}
                className="modal_admin"
                disableBackdropClick
            >
                <div className="modal-member">
                    <Typography variant="h4" id="modal-title">
                        {(this.props.editing) ? 'Mass Promo Code Update': 'Mass Promo Code Creation'}
                        <CloseIcon/>
                    </Typography>

                    <div>
                        { this.props.promoCodesForm.error && (
                            <Typography className="modal-error">
                                { this.props.promoCodesForm.error }
                            </Typography>
                        )}

                        { this.props.promoCodesForm.message && (
                            <Typography className="modal-success">
                                { this.props.promoCodesForm.message }
                            </Typography>
                        )}
                    </div>

                    {this.props.loading && (
                        <div className="page-loading">
                            <CircularProgress size={100} />
                        </div>
                    )}

                    {!this.props.loading && (
                        <Grid container>
                            <Grid item xs={12} className="form-grid">
                                <Typography className="modal-description">
                                    {(this.props.editing) ? "Enter your mass promo code information and click 'Update Mass Promo Codes'.": "Enter your mass promo code information and click 'Create Mass Promo Codes'."}
                                </Typography>
                            </Grid>

                            <Grid item xs={12} className="form-grid">
                                <Typography variant="h4" id="modal-title">
                                    Batch Label
                                </Typography>
                            </Grid>

                            <Grid item xs={12} className="form-grid">
                                <TextField
                                    label="Label"
                                    className="modal-input"
                                    fullWidth={true}
                                    value={this.props.promoCodesForm.label}
                                    onChange={(e) => { this.props.dispatch(massPromoCodeAdminFormUpdate({ prop: 'label', value: e.target.value })); }}
                                    margin="normal"
                                />
                            </Grid>

                            <Grid item xs={12} className="form-grid">
                                <Typography variant="h4" id="modal-title">
                                Type (Delivery Only)
                                </Typography>
                            </Grid>

                            <Grid item xs={12} className="form-grid" style={{padding: "10px 0px"}}>
                                <FormGroup row>
                                    {/* <FormGroup style={{padding: "0px 30px"}}>
                                        <IconButton
                                            className="fa fa-shopping-cart jumboIcon" color={this.props.promoCodesForm.total_target == 'SUBTOTAL' ? 'primary' : 'default'}
                                            onClick={() => { this.props.dispatch(massPromoCodeAdminFormUpdate({ prop: 'total_target', value: 'SUBTOTAL' })); }}
                                        />
                                        <Typography style={{padding: "0px 25px"}}>
                                            Order Subtotal
                                        </Typography>
                                    </FormGroup> */}
                                    
                                    <FormGroup style={{padding: "0px 30px"}}>
                                        <IconButton
                                            className="fa fa-shipping-fast jumboIcon" color={this.props.promoCodesForm.total_target == 'DELIVERY' ? 'primary' : 'default'}
                                            onClick={() => { this.props.dispatch(massPromoCodeAdminFormUpdate({ prop: 'total_target', value: 'DELIVERY' })); }}
                                        />
                                        <Typography style={{padding: "0px 25px"}}>
                                            Delivery Promo
                                        </Typography>
                                    </FormGroup>
                                    
                                </FormGroup>
                            </Grid>

                            <Grid item xs={12} className="form-grid">
                                <Typography variant="h4" id="modal-title">
                                    Promo Deal
                                </Typography>
                            </Grid>

                            <Grid item xs={6} className="form-grid">
                                <InputLabel className="input-label-mb"></InputLabel>
                                <Select
                                    label="Promo Type"
                                    fullWidth={true}
                                    margin="none"
                                    variant="outlined"
                                    value={this.props.promoCodesForm.total_type}
                                    onChange={(e) => { this.props.dispatch(massPromoCodeAdminFormUpdate({ prop: 'total_type', value: e.target.value })); }}
                                >
                                    <MenuItem key={'PERCENTAGE'} value={'PERCENTAGE'}>Percentage</MenuItem>
                                    <MenuItem key={'FIXED'} value={'FIXED'}>Fixed</MenuItem>
                                </Select>
                            </Grid>

                            <Grid item xs={6} className="form-grid">
                                <TextField
                                    label="Amount"
                                    className="modal-input"
                                    fullWidth={true}
                                    // disabled={this.props.promoCodeForm.type == 'FREE DELIVERY'}
                                    value={this.props.promoCodesForm.total}
                                    onChange={(e) => { this.props.dispatch(massPromoCodeAdminFormUpdate({ prop: 'total', value: e.target.value })); }}
                                    margin="normal"
                                    variant="outlined"
                                />
                            </Grid>

                            <Grid item xs={12} className="form-grid">
                                <Typography variant="h3" id="modal-title">
                                    Availability (Select One)
                                </Typography>
                            </Grid>

                            <Grid item xs={4} className="form-grid">
                                <FormControlLabel
                                    control={
                                    <Checkbox
                                        checked={this.props.promoCodesForm.total_avail == 'LIMITED DATE'}
                                        onChange={(e) => { this.props.dispatch(massPromoCodeAdminFormUpdate({ prop: 'total_avail', value: e.target.value })); }}
                                        name={'Specific Start/End Date'}
                                        color="primary"
                                        value={'LIMITED DATE'}
                                    />
                                    }
                                    label={'Specific Start/End Date'}
                                />
                            </Grid>

                            <Grid item xs={4} className="form-grid">
                                <InputLabel className="input-label-mb">Start Date</InputLabel>
                                <DatePicker 
                                    selected={this.props.promoCodesForm.start_time} 
                                    onChange={(date) => { this.props.dispatch(massPromoCodeAdminFormUpdate({ prop: 'start_time', value: date })); }} 
                                    disabled={this.props.promoCodesForm.total_avail != 'LIMITED DATE'}
                                />
                            </Grid>

                            <Grid item xs={4} className="form-grid">
                                <InputLabel className="input-label-mb">End Date</InputLabel>
                                <DatePicker 
                                    selected={this.props.promoCodesForm.end_time}
                                    onChange={(date) => { this.props.dispatch(massPromoCodeAdminFormUpdate({ prop: 'end_time', value: date })); }} 
                                    disabled={this.props.promoCodesForm.total_avail != 'LIMITED DATE'}
                                />
                            </Grid>

                            <Grid item xs={12} className="form-grid">
                                <FormControlLabel
                                    control={
                                    <Checkbox
                                        checked={this.props.promoCodesForm.total_avail == 'ALWAYS'}
                                        onChange={(e) => { this.props.dispatch(massPromoCodeAdminFormUpdate({ prop: 'total_avail', value: e.target.value })); }}
                                        name={'Always Available'}
                                        color="primary"
                                        value={'ALWAYS'}
                                    />
                                    }
                                    label={'Always Available'}
                                />
                            </Grid>

                            <PromoDaySelection
                                activeForm={this.props.promoCodesForm}
                                updateValidDays={massPromoCodeAdminUpdateValidPromoDays}
                                dispatch={this.props.dispatch}
                            />

                            {
                                this.props.editing && (
                                    <>
                                        <Grid item xs={12} className="form-grid">
                                            <Typography variant="h3" id="modal-title">
                                                Usage Information
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} className="form-grid">
                                            <TextField
                                                label="Max Uses Per Code"
                                                className="modal-input"
                                                fullWidth={true}
                                                disabled={true}
                                                // helperText={this.props.promoCodesForm.max_uses == 0 ? "0 is unlimited" : ''}
                                                value={this.props.promoCodesForm.max_uses}
                                                onChange={(e) => { this.props.dispatch(massPromoCodeAdminFormUpdate({ prop: 'max_uses', value: e.target.value })); }}
                                                margin="normal"
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item xs={6} className="form-grid">
                                            <TextField
                                                label="Used codes (One-Time Use assumed)"
                                                className="modal-input"
                                                fullWidth={true}
                                                disabled={true}
                                                // helperText={this.props.promoCodesForm.max_uses == 0 ? "0 is unlimited" : ''}
                                                value={this.props.promoCodesForm.selectedPromoCode.used_single_mass_promo_codes_count + "/" + this.props.promoCodesForm.selectedPromoCode.global_batch_count}
                                                onChange={(e) => { this.props.dispatch(massPromoCodeAdminFormUpdate({ prop: 'max_uses', value: e.target.value })); }}
                                                margin="normal"
                                                variant="outlined"
                                            />
                                        </Grid>
                                    </>
                                )
                            }
                            {
                                !this.props.editing && (
                                    <>
                                        <Grid item xs={12} className="form-grid">
                                            <Typography variant="h3" id="modal-title">
                                                Quantity
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={6} className="form-grid">
                                            <InputLabel className="input-label-mb"></InputLabel>
                                            <Select
                                                label="Max Uses"
                                                fullWidth={true}
                                                disabled={this.props.editing}
                                                margin="none"
                                                variant="outlined"
                                                value={this.props.promoCodesForm.max_uses > 0 ? 1 : 0}
                                                onChange={(e) => { this.props.dispatch(massPromoCodeAdminFormUpdate({ prop: 'max_uses', value: e.target.value })); }}
                                            >
                                                <MenuItem key={'NO LIMIT'} value={0}>Unlimited</MenuItem>
                                                <MenuItem key={'LIMITED QUANTITY'} value={1}>Limited Quantity</MenuItem>
                                            </Select>
                                        </Grid>

                                        <Grid item xs={6} className="form-grid">
                                            <TextField
                                                label="Max Uses"
                                                className="modal-input"
                                                fullWidth={true}
                                                helperText={this.props.promoCodesForm.max_uses == 0 ? "0 is unlimited" : ''}
                                                disabled={this.props.editing}
                                                value={this.props.promoCodesForm.max_uses}
                                                onChange={(e) => { this.props.dispatch(massPromoCodeAdminFormUpdate({ prop: 'max_uses', value: e.target.value })); }}
                                                margin="normal"
                                                variant="outlined"
                                            />
                                        </Grid>

                                        <Grid item xs={12} className="form-grid">
                                            <Typography variant="h4" id="modal-title">
                                                Codes To Create
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={12} className="form-grid">
                                            <TextField
                                                label="Number Of Codes To Create"
                                                className="modal-input"
                                                fullWidth={true}
                                                disabled={this.props.editing}
                                                value={this.props.promoCodesForm.codes_to_create}
                                                onChange={(e) => { this.props.dispatch(massPromoCodeAdminFormUpdate({ prop: 'codes_to_create', value: e.target.value })); }}
                                                margin="normal"
                                            />
                                        </Grid>
                                    </>
                                )
                            }

                            <Grid container className="sticky">
                                <Grid item xs={6} className="form-grid">
                                    <Button variant="contained" fullWidth={true} color={'secondary'} className="modal-button-list" onClick={this.handleCancelButton}>
                                        Cancel
                                    </Button>
                                </Grid>
                                <Grid item xs={6} className="form-grid">
                                    <Button variant="contained" fullWidth={true} color={'primary'} className="modal-button-list" onClick={this.updateMassPromoCode}>
                                        {(this.props.editing) ? 'Update Mass Promo Codes': 'Create Mass Promo Codes'}
                                    </Button>
                                </Grid>
                            </Grid>   
                        </Grid> 
                    )}  
                </div>
            </Modal>
        );
    }
};

const mapStateToProps = (state, props) => {
    return {
        loading: state.promoCodeAdminForm.loading,
        editing: state.massPromoCodeAdminForm.editing,
        promoCodesForm: state.massPromoCodeAdminForm,
        updateModalOpen: state.modals.massPromoCodeCreate,
    };
};

export default connect(mapStateToProps)(MassPromoCodeModal);