const resetPasswordFormDefaultState = {
    emailAddress: '',
    password: '',
    password2: '',
    message: '',
    onlineReturnLink: '',
    onlineReturnName: '',
    error: '',
    loading: '',
    successAppReset: false,
    successShowOnlineLink: false,
};

export default (state = resetPasswordFormDefaultState, action) => {
    switch (action.type) {
        case 'RESET_PASSWORD_FORM_UPDATE':
            return {
                ...state,
                [action.field.prop]: action.field.value
            };

        case 'RESET_PASSWORD_FORM_ERROR':
            return {
                ...state,
                message: '',
                onlineReturnLink: '',
                onlineReturnName: '',
                successAppReset: false,
                successShowOnlineLink: false,
                error: action.message
            }

        case 'RESET_PASSWORD_FORM_RESET':
            return resetPasswordFormDefaultState;

        default:
            return state;
    }
};