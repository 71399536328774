import React from 'react';
import { connect } from 'react-redux';

import Typography from '@material-ui/core/Typography';

import SettingsList from './SettingsList';
import SettingForm from './SettingForm';

import { 
    populateNewToppingFormSizes, 
    populateUpdateToppingFormSizes, 
    toppingFormUpdate, 
    toppingFormUpdatePrice,
    toppingFormError, 
    toppingFormReset, 
    toppingFormSet } from '../../../../actions/menu/pizzas/toppingForm';
import { settingsFormAddTopping, settingsFormUpdateTopping, settingsFormRemoveTopping } from '../../../../actions/menu/pizzas/settingsForm';

import validateCurrency from '../../../../utils/validateCurrency';

class ConfigToppings extends React.Component {

    constructor(props) {
        super(props);

        this.handleAddToppingView = this.handleAddToppingView.bind(this);
        this.handleAddTopping = this.handleAddTopping.bind(this);
        this.handleUpdateToppingView = this.handleUpdateToppingView.bind(this);
        this.handleUpdateTopping = this.handleUpdateTopping.bind(this);
        this.handleCancelButton = this.handleCancelButton.bind(this);
        this.handleRemoveTopping = this.handleRemoveTopping.bind(this);
        this.handleUpdateToppingForm = this.handleUpdateToppingForm.bind(this);
        this.handleUpdateToppingFormPrice = this.handleUpdateToppingFormPrice.bind(this);

        this.state = {
            toppingsDisplayView: 'list'
        };
    }

    componentDidMount() {
    }

    handleUpdateToppingForm(topping) {
        this.props.dispatch(toppingFormUpdate(topping));
    }

    handleUpdateToppingFormPrice(price) {
        this.props.dispatch(toppingFormUpdatePrice(price));
    }

    handleAddTopping() {
        //error checking

        if (this.props.toppingForm.name.trim() == '') {
            this.props.dispatch(toppingFormError('Please enter a topping name'));
        }
        else {
            let valid = true;

            // go through each price and determine if it is valid
            for (let i = 0; i < this.props.toppingForm.prices.length; i++) {

                // ignore any blank rows
                if (!validateCurrency(this.props.toppingForm.prices[i].price)) {
                    valid = false;
                    this.props.dispatch(toppingFormError("Please ensure that all sizes have a valid price."));
                }
            }

            if (valid) {
                this.props.dispatch(toppingFormError(""));
                
                // add the topping
                this.props.dispatch(settingsFormAddTopping(this.props.toppingForm));

                // reset the form and return to the main list view
                this.props.dispatch(toppingFormReset());
                this.setState(() => ({ toppingsDisplayView: 'list' }));
            }
        }
    }

    handleUpdateTopping() {

        //error checking
        if (this.props.toppingForm.name.trim() == '') {
            this.props.dispatch(toppingFormError('Please enter a topping name'));
        }
        else {
            let valid = true;

            // go through each price and determine if it is valid
            for (let i = 0; i < this.props.toppingForm.prices.length; i++) {

                // ignore any blank rows
                if (!validateCurrency(this.props.toppingForm.prices[i].price)) {
                    valid = false;
                    this.props.dispatch(toppingFormError("Please ensure that all sizes have a valid price."));
                }
            }

            if (valid) {
                this.props.dispatch(toppingFormError(""));
                
                // update the choice
                this.props.dispatch(settingsFormUpdateTopping(this.props.toppingForm, this.props.toppingForm.index));

                // reset the form and return to the main list view
                this.props.dispatch(toppingFormReset());
                this.setState(() => ({ toppingsDisplayView: 'list' }));
            }
        }
    }

    handleRemoveTopping(index) {
        if (confirm("Remove this topping?")) {
            this.props.dispatch(settingsFormRemoveTopping(index));
        }
    }

    handleAddToppingView() {
        this.props.dispatch(toppingFormReset());
        this.props.dispatch(populateNewToppingFormSizes(this.props.sizes));
        this.setState(() => ({ toppingsDisplayView: 'new_topping' }));
    }

    handleUpdateToppingView(topping, index) {
        this.props.dispatch(toppingFormSet(topping, index));
        this.props.dispatch(populateUpdateToppingFormSizes(topping));
        this.setState(() => ({ toppingsDisplayView: 'update_topping' }));
    }

    handleCancelButton() {
        this.setState(() => ({ toppingsDisplayView: 'list' }));
    }

    renderToppingsView() {
        switch (this.state.toppingsDisplayView) {

            case 'new_topping':
                return (
                    <div>
                        <Typography variant="h5" id="modal-title">
                            Add a new Topping
                        </Typography>
                        
                        <Typography className="modal-description">
                            Enter your topping information and prices, and click 'Add Topping'. Please note: you will still need to save the toppings after adding them.
                        </Typography>
                        
                        <SettingForm 
                            form={this.props.toppingForm}
                            handleCancelButton={this.handleCancelButton}
                            handleSubmit={this.handleAddTopping}
                            handleSubmitLabel={'Add Topping'}
                            updateSettingPrice={this.handleUpdateToppingFormPrice}
                            updateSetting={this.handleUpdateToppingForm}
                        />
                    </div>
                );

            case 'update_topping':
                return (
                    <div>
                        <Typography variant="h5" id="modal-title">
                            Update Topping
                        </Typography>
                        
                        <Typography className="modal-description">
                            Update your topping information, and click 'Update Topping'. Please note: you will still need to save the topping after updating.
                        </Typography>

                        <SettingForm 
                            form={this.props.toppingForm}
                            handleCancelButton={this.handleCancelButton}
                            handleSubmit={this.handleUpdateTopping}
                            handleSubmitLabel={'Update Topping'}
                            updateSettingPrice={this.handleUpdateToppingFormPrice}
                            updateSetting={this.handleUpdateToppingForm}
                        />
                    </div>
                );

            case 'list':
            default:
                return (
                    <div>
                        <Typography variant="h5" id="modal-title">
                            Toppings
                        </Typography>
                        
                        <Typography className="modal-description">
                            Enter any available pizza toppings that you have, and their prices if included as extra toppings.
                        </Typography>

                        <SettingsList 
                            items={this.props.pizzaSettingsForm.toppings}
                            handleAddView={this.handleAddToppingView}
                            handleUpdateView={this.handleUpdateToppingView}
                            handleRemove={this.handleRemoveTopping}
                            addLabel={"Add Topping"}
                            emptyLabel={"No toppings added."}
                        />
                    </div>
                );
        }
    }

    render() {
        return (
            <div>
                {this.renderToppingsView()}
                {this.state.toppingsDisplayView == 'list' && this.props.children}
            </div>
        );
    }
};

const mapStateToProps = (state, props) => {
    return {
        settingsForm: state.settingsForm,
        pizzaSettingsForm: state.pizzaSettingsForm,
        sizes: state.pizzas.settings.sizes,
        toppingForm: state.toppingForm
    };
};

export default connect(mapStateToProps)(ConfigToppings);