const appDirectoryFormDefaultState = {
    name: '',
    timezone: 'America/Glace_Bay',
    message: '',
    error: ''
};

export default (state = appDirectoryFormDefaultState, action) => {
    
    switch (action.type) {

        case 'APP_DIRECTORY_FORM_UPDATE':
            return {
                ...state,
                [action.directory.prop]: action.directory.value
            };


        case 'APP_DIRECTORY_FORM_ERROR':
            return {
                ...state,
                message: '',
                error: action.message
            }

        case 'APP_DIRECTORY_FORM_SUCCESS':
            return {
                ...state,
                error: '',
                message: action.message
            }

        case 'APP_DIRECTORY_FORM_RESET':
            return appDirectoryFormDefaultState;

        default:
            return state;
    }
};