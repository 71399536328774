// Get filtered food items

export default (foodItems, { text="" }) => {

    return foodItems.filter((foodItem) => {

        // match the text search
        return foodItem.name.toLowerCase().includes(text.toLowerCase());

    });
};