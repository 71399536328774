// the default items that we can possibly filter by
const managersDefaultState = {
    loading: true,
    managers: [],
    filter: ''
};

export default (state = managersDefaultState, action) => {
    switch (action.type) {
        case 'SET_MANAGERS':
            return {
                ...state,
                loading: false,
                managers: action.managers,
                filter: ''
            };

        case 'SET_MANAGER_FILTER':
            return {
                ...state,
                filter: action.text
            };

        case 'SET_MANAGERS_LOADING':
            return {
                ...state,
                loading: true
            };
            
        case 'RESET_MANAGERS':
            return managersDefaultState;

        default:
            return state;
    }
};