import React from 'react';
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import SettingToggle from '../../../common/SettingToggle';

import { addonFormUpdate } from '../../../../actions/menu/addons/addonForm';

class Info extends React.Component {

    constructor(props) {
        super(props);
        this.state = { pictures: [] };
    }

    render() {
        return (
            <div>
                <Typography className="modal-description">
                    Please enter a addon name, price and description.
                </Typography>

                <TextField
                    label="Name"
                    className="modal-input"
                    fullWidth={true}
                    value={this.props.addonForm.name}
                    onChange={(e) => { this.props.dispatch(addonFormUpdate({ prop: 'name', value: e.target.value })); }}
                    margin="normal"
                />

                <TextField
                    label="Price"
                    className="modal-input"
                    fullWidth={true}
                    value={this.props.addonForm.price}
                    onChange={(e) => { this.props.dispatch(addonFormUpdate({ prop: 'price', value: e.target.value })); }}
                    margin="normal"
                />

                <Grid container>
                    <Grid item xs={12} className="form-grid">
                        <Typography className="modal-description">
                            Enable with:
                        </Typography>
                    </Grid>
                    <Grid item xs={4} className="form-grid">
                        <SettingToggle
                            name={"Pizzas"}
                            value={this.props.addonForm.usePizzas}
                            handleChange={(e) => this.props.dispatch(addonFormUpdate({ prop: 'usePizzas', value: !this.props.addonForm.usePizzas })) }
                        />
                    </Grid>
                    <Grid item xs={4} className="form-grid">
                        <SettingToggle
                            name={"Food Items"}
                            value={this.props.addonForm.useFood}
                            handleChange={(e) => this.props.dispatch(addonFormUpdate({ prop: 'useFood', value: !this.props.addonForm.useFood })) }
                        />
                    </Grid>
                    <Grid item xs={4} className="form-grid">
                        <SettingToggle
                            name={"Specials"}
                            value={this.props.addonForm.useSpecials}
                            handleChange={(e) => this.props.dispatch(addonFormUpdate({ prop: 'useSpecials', value: !this.props.addonForm.useSpecials })) }
                        />
                    </Grid>
                </Grid>
            </div>
        );
    }
};

const mapStateToProps = (state, props) => {
    return {
        addonForm: state.addonForm
    };
};

export default connect(mapStateToProps)(Info);