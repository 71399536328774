export const searchFormUpdate = ({ prop, value }) => {

    return {
        type: 'REPORTS_SEARCH_FORM_UPDATE',
        search: { prop, value }
    };
};

export const searchFormError = (message) => {

    return {
        type: 'REPORTS_SEARCH_FORM_ERROR',
        message
    };
};

export const searchFormSuccess = (message) => {

    return {
        type: 'REPORTS_SEARCH_FORM_SUCCESS',
        message
    };
};

export const searchFormReset = () => {
    
    return {
        type: 'REPORTS_SEARCH_FORM_RESET',
    };
}