import axios from 'axios';
import { API_URL } from '../../config/constants';
import { locationFormReset, locationFormError, locationFormSuccess, locationFormUpdate } from './locationForm';
import { closeLocationCreate } from '../modals';

export const setLocations = (locations) => {
    return {
        type: 'SET_LOCATIONS',
        locations
    }
}

export const setLocationFilter = (text) => {
    return {
        type: 'SET_LOCATION_FILTER',
        text
    }
}

export const resetLocations = () => {
    return {
        type: 'RESET_LOCATIONS'
    }
}

export const setLocationsLoading = () => ({
    type: 'SET_LOCATIONS_LOADING'
});

export const setLocationsDoneLoading = () => ({
    type: 'SET_LOCATIONS_DONE_LOADING'
});

export const loadLocations = (restaurant_id, updateSelectedLocation=false) => {
    return (dispatch) => {
        return axios.get(API_URL+'/api/restaurants/'+restaurant_id+'/locations')
            .then(function(res) {
                dispatch(setLocations(res.data.data.locations));
                if(updateSelectedLocation){
                    dispatch(locationFormUpdate({ prop: 'selectedLocation', value: res.data.data.locations.find(l => l.id == updateSelectedLocation) }));
                }
            })
            .catch(function(err) {
                console.log("Err!");
                console.log(err);
            });
    };
}

export const addLocation = (restaurantId, location) => {

    return (dispatch) => {

        const formData = new FormData();
        dispatch(setLocationsLoading());

        // add the data
        formData.append('data', JSON.stringify(location));

        return axios.post(
                API_URL+'/api/restaurants/'+restaurantId+'/locations', 
                formData,
                {
                    headers: { 'Content-Type': 'multipart/form-data' }
                }
            )
            .then(function(res) {
                if (res.data.status == 'success') {
                    dispatch(setLocations(res.data.data.locations));
                    dispatch(locationFormReset());
                    dispatch(closeLocationCreate());
                }
                else {
                    dispatch(locationFormError(res.data.message));
                }
            })
            .catch(function(err) {
                
                // token is likely invalid; remove it and prompt the user to login again
                dispatch(locationFormError("We're sorry, but our system has encountered an issue, to which we have been notified."));
                if (err.response.status === 401) {
                    removeAuthToken(dispatch);
                }
                
            });
    };
};

export const updateLocation = (restaurantId, locationId, scope, location) => {

    return (dispatch) => {

        const formData = new FormData();

        // add the data
        formData.append('scope', scope);
        formData.append('data', JSON.stringify(location));

        dispatch(setLocationsLoading());

        return axios.post(
            API_URL+'/api/restaurants/'+restaurantId+'/locations/'+locationId, 
            formData,
            {
                headers: { 'Content-Type': 'multipart/form-data' }
            }
        ).then(function(res) {
            if (res.data.status == 'success') {
                dispatch(setLocations(res.data.data.locations));
                dispatch(locationFormReset());

                dispatch(locationFormUpdate({ prop: 'address', value: res.data.data.location.address }));
                dispatch(locationFormUpdate({ prop: 'address2', value: res.data.data.location.address2 }));
                dispatch(locationFormUpdate({ prop: 'city', value: res.data.data.location.city }));
                dispatch(locationFormUpdate({ prop: 'province', value: res.data.data.location.province }));
                dispatch(locationFormUpdate({ prop: 'postal', value: res.data.data.location.postal }));
                dispatch(locationFormUpdate({ prop: 'phone', value: res.data.data.location.phone }));
                dispatch(locationFormUpdate({ prop: 'phone2', value: res.data.data.location.phone2 }));

                dispatch(locationFormUpdate({ prop: 'add_taxes', value: (res.data.data.location.add_taxes == 1) ? true : false }));
                dispatch(locationFormUpdate({ prop: 'ignore_sms_warning', value: (res.data.data.location.ignore_sms_warning == 1) ? true : false }));
                dispatch(locationFormUpdate({ prop: 'preorder_enabled', value: (res.data.data.location.preorder_enabled == 1) ? true : false }));
                dispatch(locationFormUpdate({ prop: 'promo_code_enable', value: (res.data.data.location.promo_code_enable == 1) ? true : false }));

                dispatch(locationFormUpdate({ prop: 'warning_phone', value: res.data.data.location.warning_phone }));
                dispatch(locationFormUpdate({ prop: 'printer_template', value: res.data.data.location.printer_template }));
                dispatch(locationFormUpdate({ prop: 'payment_type', value: res.data.data.location.payment_type }));

                dispatch(locationFormUpdate({ prop: 'delivery_charge', value: res.data.data.location.delivery_charge }));
                dispatch(locationFormUpdate({ prop: 'max_delivery_range', value: res.data.data.location.max_delivery_range }));

                dispatch(locationFormUpdate({ prop: 'delivery_type', value: res.data.data.location.delivery_type }));
                dispatch(locationFormUpdate({ prop: 'pickup_options', value: res.data.data.location.pickup_options }));

                dispatch(locationFormUpdate({ prop: 'hoursOfOperation', value: res.data.data.location.hours_of_operation }));
                dispatch(locationFormUpdate({ prop: 'holidayHours', value: res.data.data.location.holiday_hours }));
                dispatch(locationFormUpdate({ prop: 'deliveryHours', value: res.data.data.location.delivery_hours }));
                dispatch(locationFormUpdate({ prop: 'deliveryCharges', value: res.data.data.location.delivery_charges }));
                dispatch(locationFormUpdate({ prop: 'promoCodes', value: res.data.data.location.promo_codes }));
                dispatch(locationFormUpdate({ prop: 'dispatchAssociated', value: res.data.data.location.dispatch_location }));

                dispatch(locationFormUpdate({ prop: 'restaurant_id', value: res.data.data.location.restaurant_id }));
                dispatch(locationFormUpdate({ prop: 'selectedLocation', value: res.data.data.location }));

                dispatch(locationFormSuccess('Location Updated!'));
            }
            else {
                dispatch(locationFormError(res.data.message));
                console.log(res.data);
                console.log("Could not update your location!");
            }
        })
        .catch(function(err) {
            console.log('err');
            console.log(err);
            // token is likely invalid; remove it and prompt the user to login again
            dispatch(locationFormError("We're sorry, but our system has encountered an issue, to which we have been notified."));
            if (err.response && err.response.status === 401) {
                removeAuthToken(dispatch);
            }
            
        });
    };
};

export const toggleLocationEnabled = (restaurant_id, location_id, enabled) => {
    
    return (dispatch) => {

        dispatch(setLocationsLoading());
        return axios.post(API_URL+'/api/restaurants/'+restaurant_id+'/locations/'+location_id, { 
                scope: 'enable',
                enabled 
            })
            .then(function(res) {
                if (res.data.status == 'success') {
                    dispatch(setLocations(res.data.data.locations));
                }
                else {
                    dispatch(locationFormError(res.data.message));
                    console.log(res.data);
                    console.log("Could not add your location!");
                }
            })
            .catch(function(err) {

                // token is likely invalid; remove it and prompt the user to login again
                dispatch(locationFormError("We're sorry, but our system has encountered an issue, to which we have been notified."));
                if (err.response.status === 401) {
                    removeAuthToken(dispatch);
                }
                
            });
    };
};

export const createTestOrder = (restaurant_id, location_id) => {
    
    return (dispatch) => {

        dispatch(setLocationsLoading());
        return axios.post(API_URL+'/api/restaurants/'+restaurant_id+'/create_test_order/'+location_id)
            .then(function(res) {
                alert('Test order created.');
                dispatch(setLocationsDoneLoading());

            })
            .catch(function(err) {

                dispatch(locationFormError("We're sorry, but our system has encountered an issue, to which we have been notified."));
                dispatch(setLocationsDoneLoading());
                
            });
    };
};

export const toggleOpen = (restaurant_id, location_id, scope) => {
    
    return (dispatch) => {

        dispatch(setLocationsLoading());
        return axios.post(API_URL+'/api/restaurants/'+restaurant_id+'/locations/'+location_id, { 
                scope
            })
            .then(function(res) {
                if (res.data.status == 'success') {
                    dispatch(setLocations(res.data.data.locations));
                }
                else {
                    dispatch(locationFormError(res.data.message));
                    console.log(res.data);
                    console.log("Could not update your location's open status!");
                }
            })
            .catch(function(err) {

                // token is likely invalid; remove it and prompt the user to login again
                dispatch(locationFormError("We're sorry, but our system has encountered an issue, to which we have been notified."));
                if (err.response.status === 401) {
                    removeAuthToken(dispatch);
                }
                
            });
    };
};