import React from 'react';
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { pizzaFormAddTopping, pizzaFormRemoveTopping } from '../../../../actions/menu/pizzas/pizzaForm';

import SettingToggle from '../../../common/SettingToggle';

class Toppings extends React.Component {

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.isToppingSet = this.isToppingSet.bind(this);
    }

    handleChange(e, topping_id) {

        if (e.target.checked) {
            this.props.dispatch(pizzaFormAddTopping(topping_id));
        }
        else {
            this.props.dispatch(pizzaFormRemoveTopping(topping_id));
        }
    }

    isToppingSet(topping_id) {

        for (let i = 0; i < this.props.pizzaForm.toppings.length; i++) {
            if (this.props.pizzaForm.toppings[i].topping_id == topping_id) {
                return true;
            }
        }

        return false;
    }
    
    render() {
        return (
            <div>
                <Typography className="modal-description">
                    Please select the toppings that come on the pizza.
                </Typography>

                <Grid container>
                    {this.props.settings.toppings.map((topping, index) => (
                        <Grid key={'topping-'+index} item xs={3} className="form-grid">
                            <SettingToggle
                                name={topping.name}
                                value={this.isToppingSet(topping.topping_id)}
                                handleChange={(e) => this.handleChange(e, topping.topping_id)}
                            />
                        </Grid>
                    ))}
                </Grid>
            </div>
        );
    }
};

const mapStateToProps = (state, props) => {
    return {
        pizzaForm: state.pizzaForm,
        settings: state.pizzas.settings
    };
};

export default connect(mapStateToProps)(Toppings);