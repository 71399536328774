// the default items that we can possibly filter by
const appDirectoriesDefaultState = {
    loading: true,
    directories: [],
    selectedDirectory: {},
    filter: ''
};

export default (state = appDirectoriesDefaultState, action) => {
    switch (action.type) {
        case 'SET_APP_DIRECTORIES':
            return {
                ...state,
                loading: false,
                directories: action.directories,
                filter: ''
            };

        case 'SET_SELECTED_APP_DIRECTORY':
            return {
                ...state,
                selectedDirectory: action.directory
            };
    

        case 'SET_APP_DIRECTORY_FILTER':
            return {
                ...state,
                filter: action.text
            };

        case 'SET_APP_DIRECTORIES_LOADING':
            return {
                ...state,
                loading: true
            };
            
        case 'RESET_APP_DIRECTORIES':
            return appDirectoriesDefaultState;

        default:
            return state;
    }
};