const menuImportFormDefaultState = {
    purge: false,
    error: '',
    message: '',
    loading: false,
};

export default (state = menuImportFormDefaultState, action) => {
    
    switch (action.type) {

        case 'IMPORT_MENU_FORM_UPDATE':
            return {
                ...state,
                [action.importMenu.prop]: action.importMenu.value
            };

        case 'IMPORT_MENU_FORM_ERROR':
            return {
                ...state,
                message: '',
                loading: false,
                error: action.message
            }

        case 'IMPORT_MENU_FORM_MESSAGE':
            return {
                ...state,
                error: '',
                loading: false,
                message: action.message
            }

        case 'IMPORT_MENU_FORM_RESET':
            return menuExportFormDefaultState;

        default:
            return state;
    }
};