import React from 'react';

import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { daysOfWeek } from '../../../utils/dateUtil';

class PromoDaySelection extends React.Component {

    render() {
        return (
            <>
                <Grid item xs={12} className="form-grid">
                    <Typography variant="h3" id="modal-title">
                        Days active
                    </Typography>
                </Grid>

                <Grid container className="form-grid">
                    {daysOfWeek.map((day) => {

                        return (
                            <Grid key={'day'+day.id} item xs={3} className="form-image-container setWidthLabel">
                                <FormControlLabel
                                    control={
                                    <Checkbox
                                        checked={!!this.props.activeForm.valid_days[day.id]}
                                        onChange={(e) => { this.props.dispatch(this.props.updateValidDays({ prop: day.id, value: !this.props.activeForm.valid_days[day.id] })); }}
                                        name={day.label}
                                        color="primary"
                                    />
                                    }
                                    label={day.label}
                                />
                            </Grid>
                        );
                    })}
                </Grid>
            </>
        );
    }
};

export default PromoDaySelection;