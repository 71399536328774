const settingsFormDefaultState = {
    sizes: [{
        size_name: '',
        default_price: '0.00'
    }],
    toppings: [],
    crusts: [],
    sauces: [],
    customPizzas: [],
    customPizzaCrusts: [],
    customPizzaSauces: [],
    deletedSizes: [],
    deletedCrusts: [],
    deletedSauces: [],
    deletedToppings: [],
    deletedCustomPizzas: [],
    deletedCustomPizzaCrusts: [],
    deletedCustomPizzaSauces: [],
    message: '',
    error: '',
    activeTab: 0,
    scrollPosition: 0
};

export default (state = settingsFormDefaultState, action) => {

    switch (action.type) {

        case 'PIZZA_SETTINGS_FORM_UPDATE':
            return {
                ...state,
                [action.setting.prop]: action.setting.value
            };

        case 'PIZZA_SETTINGS_FORM_UPDATE_SIZE':
            return {
                ...state,
                sizes: state.sizes.map((size, index) => {
                    if (index == action.size.index) {
                        return {
                            ...size,
                            [action.size.prop]: action.size.value
                        }
                    }

                    return size;
                })
            };

        case 'PIZZA_SETTINGS_FORM_ADD_SIZE':
            return {
                ...state,
                sizes: state.sizes.concat(action.size)
            };

        case 'PIZZA_SETTINGS_FORM_REMOVE_SIZE':
            const deletedSizes = (state.sizes[action.index].id) ? state.deletedSizes.concat(state.sizes[action.index].id) : state.deletedSizes;

            return {
                ...state,
                deletedSizes,
                sizes: state.sizes.filter((size, index) => {
                    return index != action.index;
                })
            };

        case 'PIZZA_SETTINGS_FORM_UPDATE_TOPPING':
            return {
                ...state,
                toppings: state.toppings.map((topping, index) => {
                    if (index == action.index) {
                        return action.topping;
                    }

                    return topping;
                })
            };

        case 'PIZZA_SETTINGS_FORM_ADD_TOPPING':
            return {
                ...state,
                toppings: state.toppings.concat(action.topping)
            };

        case 'PIZZA_SETTINGS_FORM_REMOVE_TOPPING':
            const deletedToppings = (state.toppings[action.index].id) ? state.deletedToppings.concat(state.toppings[action.index].id) : state.deletedToppings;

            return {
                ...state,
                deletedToppings,
                toppings: state.toppings.filter((topping, index) => {
                    return index != action.index;
                })
            };

        case 'PIZZA_SETTINGS_FORM_UPDATE_CRUST':
            return {
                ...state,
                crusts: state.crusts.map((crust, index) => {
                    if (index == action.index) {
                        return action.crust;
                    }

                    return crust;
                })
            };

        case 'PIZZA_SETTINGS_FORM_ADD_CRUST':
            return {
                ...state,
                crusts: state.crusts.concat(JSON.parse(JSON.stringify(action.crust)))
            };

        case 'PIZZA_SETTINGS_FORM_REMOVE_CRUST':
            const deletedCrusts = (state.crusts[action.index].id) ? state.deletedCrusts.concat(state.crusts[action.index].id) : state.deletedCrusts;

            return {
                ...state,
                deletedCrusts,
                crusts: state.crusts.filter((crust, index) => {
                    return index != action.index;
                })
            };

        case 'PIZZA_SETTINGS_FORM_UPDATE_SAUCE':
            return {
                ...state,
                sauces: state.sauces.map((sauce, index) => {
                    if (index == action.index) {
                        return action.sauce;
                    }

                    return sauce;
                })
            };

        case 'PIZZA_SETTINGS_FORM_ADD_SAUCE':
            return {
                ...state,
                sauces: state.sauces.concat(action.sauce)
            };

        case 'PIZZA_SETTINGS_FORM_REMOVE_SAUCE':
            const deletedSauces = (state.sauces[action.index].id) ? state.deletedSauces.concat(state.sauces[action.index].id) : state.deletedSauces;

            return {
                ...state,
                deletedSauces,
                sauces: state.sauces.filter((sauce, index) => {
                    return index != action.index;
                })
            };  
        
        case 'PIZZA_SETTINGS_FORM_UPDATE_CUSTOM_PIZZA':
            return {
                ...state,
                customPizzas: state.customPizzas.map((customPizza, index) => {
                    if (index == action.index) {
                        return action.customPizza;
                    }

                    return customPizza;
                })
            };

        case 'PIZZA_SETTINGS_FORM_ADD_CUSTOM_PIZZA':
            return {
                ...state,
                customPizzas: state.customPizzas.concat(JSON.parse(JSON.stringify(action.customPizza)))
            };

        case 'PIZZA_SETTINGS_FORM_REMOVE_CUSTOM_PIZZA':
            const deletedCustomPizzas = (state.customPizzas[action.index].id) ? state.deletedCustomPizzas.concat(state.customPizzas[action.index].id) : state.deletedCustomPizzas;

            return {
                ...state,
                deletedCustomPizzas,
                customPizzas: state.customPizzas.filter((customPizza, index) => {
                    return index != action.index;
                })
            };

        case 'PIZZA_SETTINGS_FORM_ADD_CUSTOM_CRUST':

            return {
                ...state,
                customPizzaCrusts: state.customPizzaCrusts.concat({
                    crust_id: action.crust
                })
            };

        case 'PIZZA_SETTINGS_FORM_REMOVE_CUSTOM_CRUST':
            let deletedCustomPizzaCrusts = state.deletedCustomPizzaCrusts;
            for (let i = 0; i < state.customPizzaCrusts.length; i++) {
                if (state.customPizzaCrusts[i].id && state.customPizzaCrusts[i].crust_id == action.crust) {
                    deletedCustomPizzaCrusts = state.deletedCustomPizzaCrusts.concat(state.customPizzaCrusts[i].id);
                }
            }
            return {
                ...state,
                deletedCustomPizzaCrusts,
                customPizzaCrusts: state.customPizzaCrusts.filter((crust, index) => {
                    return crust.crust_id != action.crust;
                })
            };

        case 'PIZZA_SETTINGS_FORM_ADD_CUSTOM_SAUCE':

            return {
                ...state,
                customPizzaSauces: state.customPizzaSauces.concat({
                    sauce_id: action.sauce
                })
            };

        case 'PIZZA_SETTINGS_FORM_REMOVE_CUSTOM_SAUCE':
            let deletedCustomPizzaSauces = state.deletedCustomPizzaSauces;
            for (let i = 0; i < state.customPizzaSauces.length; i++) {
                if (state.customPizzaSauces[i].id && state.customPizzaSauces[i].sauce_id == action.sauce) {
                    deletedCustomPizzaSauces = state.deletedCustomPizzaSauces.concat(state.customPizzaSauces[i].id);
                }
            }
            return {
                ...state,
                deletedCustomPizzaSauces,
                customPizzaSauces: state.customPizzaSauces.filter((sauce, index) => {
                    return sauce.sauce_id != action.sauce;
                })
            };

        case 'PIZZA_SETTINGS_FORM_ERROR':
            return {
                ...state,
                message: '',
                error: action.message
            }

        case 'PIZZA_SETTINGS_FORM_SUCCESS':
            return {
                ...state,
                error: '',
                message: action.message
            }

        case 'PIZZA_SETTINGS_FORM_RESET':
            return settingsFormDefaultState;

        default:
            return state;
    }
};