import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Icon from '@material-ui/core/Icon';
import { closeLeftSidebar } from '../../actions/sidebar';
import { logout } from '../../actions/auth';

import checkPermissions from '../../utils/checkUserPermissions';

class LeftSidebar extends React.Component {

    constructor(props) {
        super(props);

        this.closeSidebar = this.closeSidebar.bind(this);
        this.renderSuperuserMenu = this.renderSuperuserMenu.bind(this);
    }

    closeSidebar() {
        this.props.dispatch(closeLeftSidebar());
    }

    renderSuperuserMenu() {
        let userAvailable = Object.keys(this.props.curUser).length > 0;
        if (this.props.curUser && Object.keys(this.props.curUser)) {
            return (
                <div>
                    {this.props.restaurant && this.props.restaurant.id && (
                        <div>
                            <Divider />

                            <List>
                                <ListItem button onClick={() => { this.props.history.push('/'+this.props.restaurant.id+'/dashboard') }}>
                                    <ListItemText primary="Restaurant Management" />
                                </ListItem>

                                {userAvailable && checkPermissions(this.props.curUser, 'restaurant_admin', this.props.restaurant.id) && (
                                    <ListItem button onClick={() => { this.props.history.push('/'+this.props.restaurant.id+'/settings') }}>
                                        <ListItemIcon>
                                            <Icon className="fa fa-utensils" />
                                        </ListItemIcon>
                                        <ListItemText primary="Settings" />
                                    </ListItem>
                                )}
                                
                                <ListItem button onClick={() => { this.props.history.push('/'+this.props.restaurant.id+'/dashboard') }}>
                                    <ListItemIcon>
                                        <Icon className="fa fa-map-marker" />
                                    </ListItemIcon>
                                    <ListItemText primary="Locations" />
                                </ListItem>

                                {userAvailable && checkPermissions(this.props.curUser, 'restaurant_admin', this.props.restaurant.id) && (
                                    <ListItem button onClick={() => { this.props.history.push('/'+this.props.restaurant.id+'/loyalty') }}>
                                        <ListItemIcon>
                                            <Icon className="fa fa-file" />
                                        </ListItemIcon>
                                        <ListItemText primary="Loyalty Points Settings" />
                                    </ListItem>
                                )}

                                {userAvailable && checkPermissions(this.props.curUser, 'restaurant_admin', this.props.restaurant.id) && (
                                    <ListItem button onClick={() => { this.props.history.push('/'+this.props.restaurant.id+'/managers') }}>
                                        <ListItemIcon>
                                            <Icon className="fa fa-user" />
                                        </ListItemIcon>
                                        <ListItemText primary="Managers" />
                                    </ListItem>
                                )}
                            </List>

                            {userAvailable && (checkPermissions(this.props.curUser, 'restaurant_manager', this.props.restaurant.id) || checkPermissions(this.props.curUser, 'support')) && (
                                <div>
                                    <Divider />

                                    <List>
                                        <ListItem button>
                                            <ListItemText primary="Menu Management" />
                                        </ListItem>

                                        <ListItem button onClick={() => { this.props.history.push('/'+this.props.restaurant.id+'/categories') }}>
                                            <ListItemIcon>
                                                <Icon className="fa fa-utensils" />
                                            </ListItemIcon>
                                            <ListItemText primary="Food Items" />
                                        </ListItem>
                                        
                                        <ListItem button onClick={() => { this.props.history.push('/'+this.props.restaurant.id+'/pizzas') }}>
                                            <ListItemIcon>
                                                <Icon className="fa fa-file" />
                                            </ListItemIcon>
                                            <ListItemText primary="Pizzas" />
                                        </ListItem>

                                        <ListItem button onClick={() => { this.props.history.push('/'+this.props.restaurant.id+'/specials') }}>
                                            <ListItemIcon>
                                                <Icon className="fa fa-file" />
                                            </ListItemIcon>
                                            <ListItemText primary="Specials" />
                                        </ListItem>

                                        <ListItem button onClick={() => { this.props.history.push('/'+this.props.restaurant.id+'/addons') }}>
                                            <ListItemIcon>
                                                <Icon className="fa fa-file" />
                                            </ListItemIcon>
                                            <ListItemText primary="Addons" />
                                        </ListItem>

                                        <ListItem button onClick={() => { this.props.history.push('/'+this.props.restaurant.id+'/modifiers') }}>
                                            <ListItemIcon>
                                                <Icon className="fa fa-file" />
                                            </ListItemIcon>
                                            <ListItemText primary="Modifiers" />
                                        </ListItem>

                                    </List>
                                </div>
                            )}
                        </div>
                    )}

                    {userAvailable && checkPermissions(this.props.curUser, 'superuser') && (
                        <div>
                            <Divider />

                            <List>
                                <ListItem button>
                                    <ListItemText primary="Superuser Menu" />
                                </ListItem>

                                <ListItem button onClick={() => { this.props.history.push({ 
                                                            pathname: '/',
                                                            state: {
                                                                arrivalAction: 'create_restaurant'
                                                            }
                                                        }); }}>
                                    <ListItemIcon>
                                        <Icon className="fa fa-utensils" />
                                    </ListItemIcon>
                                    <ListItemText primary="Add a New Restaurant" />
                                </ListItem>

                                <ListItem button onClick={() => { this.props.history.push('/admin/directories') }}>
                                    <ListItemIcon>
                                        <Icon className="fa fa-mobile-alt" />
                                    </ListItemIcon>
                                    <ListItemText primary="App Directories" />
                                </ListItem>

                                <ListItem button onClick={() => { this.props.history.push('/admin/export-menus') }}>
                                    <ListItemIcon>
                                        <Icon className="fa fa-file-import" />
                                    </ListItemIcon>
                                    <ListItemText primary="Export Restaurant Menu" />
                                </ListItem>

                                <ListItem button onClick={() => { this.props.history.push('/'+this.props.restaurant.id+'/importMenu') }}>
                                    <ListItemIcon>
                                        <Icon className="fa fa-file-download" />
                                    </ListItemIcon>
                                    <ListItemText primary="Import Menu" />
                                </ListItem>
                                
                                <ListItem button onClick={() => { this.props.history.push('/admin/users') }}>
                                    <ListItemIcon>
                                        <Icon className="fa fa-users" />
                                    </ListItemIcon>
                                    <ListItemText primary="User Management" />
                                </ListItem>
                            </List>
                        </div>
                    )}

                    {((this.props.curUser.dispatchPermissions && this.props.curUser.dispatchPermissions.length > 0) || (userAvailable && checkPermissions(this.props.curUser, 'superuser'))) && (
                        <div>
                            <Divider />

                            <List>
                                <ListItem button>
                                    <ListItemText primary="Dispatch" />
                                </ListItem>

                                <ListItem button onClick={() => { this.props.history.push('/admin/dispatch-centers') }}>
                                    <ListItemIcon>
                                        <Icon className="fa fa-car-side" />
                                    </ListItemIcon>
                                    <ListItemText primary="Dispatch Centers" />
                                </ListItem>
                            </List>
                        </div>
                    )}

                    <Divider />

                    {userAvailable && checkPermissions(this.props.curUser, 'support') && (
                        <List>
                            <ListItem button>
                                <ListItemText primary="Support" />
                            </ListItem>

                            <ListItem button onClick={() => { this.props.history.push('/admin/support/orders') }}>
                                <ListItemIcon>
                                    <Icon className="fa fa-utensils" />
                                </ListItemIcon>
                                <ListItemText primary="Support Orders" />
                            </ListItem>

                            <ListItem button onClick={() => { this.props.history.push('/admin/support/summary') }}>
                                <ListItemIcon>
                                    <Icon className="fa fa-receipt" />
                                </ListItemIcon>
                                <ListItemText primary="Support Summary" />
                            </ListItem>

                            <ListItem button onClick={() => { this.props.history.push('/admin/support/promos') }}>
                                <ListItemIcon>
                                    <Icon className="fa fa-dollar-sign" />
                                </ListItemIcon>
                                <ListItemText primary="Promo Code Manager" />
                            </ListItem>
                        </List>
                    )}

                    <Divider />
                </div>
            );
        }
    }

    render() {
        return (
            <Drawer open={this.props.isSidebarOpen} onClose={this.closeSidebar}>
                <div
                    tabIndex={0}
                    role="button"
                    onClick={this.closeSidebar}
                    onKeyDown={this.closeSidebar}
                    className="sidebar_left"
                >
                    <div className="list">

                        {this.renderSuperuserMenu()}

                        <Divider />

                        <List>
                            <ListItem button>
                                <ListItemText primary="User Settings" />
                            </ListItem>

                            <ListItem button onClick={() => { alert("Coming Soon!") }}>
                                <ListItemIcon>
                                    <Icon className="fa fa-user" />
                                </ListItemIcon>
                                <ListItemText primary="My Profile" />
                            </ListItem>
                            
                            <ListItem button onClick={() => { this.props.dispatch(logout(this.props.history)) }}>
                                <ListItemIcon>
                                    <Icon className="fa fa-sign-out-alt" />
                                </ListItemIcon>
                                <ListItemText primary="Logout" />
                            </ListItem>
                        </List>
                    </div>
                </div>
            </Drawer>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isSidebarOpen: state.sidebar.left,
        curUser: state.loggedUser.user,
        restaurant: state.selectedRestaurant.restaurant
    };
};

export default withRouter(connect(mapStateToProps)(LeftSidebar));