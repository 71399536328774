import React from 'react';
import { connect } from 'react-redux';
import arrayMove from 'array-move';

import Typography from '@material-ui/core/Typography';

import SortableSettingsList from './SortableSettingsList';
import SettingForm from './SettingForm';

import { 
    populateNewCrustFormSizes, 
    populateUpdateCrustFormSizes, 
    crustFormUpdate, 
    crustFormUpdatePrice,
    crustFormError, 
    crustFormReset, 
    crustFormSet } from '../../../../actions/menu/pizzas/crustForm';
import { settingsFormUpdate, settingsFormAddCrust, settingsFormUpdateCrust, settingsFormRemoveCrust } from '../../../../actions/menu/pizzas/settingsForm';

import validateCurrency from '../../../../utils/validateCurrency';

class ConfigCrusts extends React.Component {

    constructor(props) {
        super(props);

        this.handleAddCrustView = this.handleAddCrustView.bind(this);
        this.handleAddCrust = this.handleAddCrust.bind(this);
        this.handleUpdateCrustView = this.handleUpdateCrustView.bind(this);
        this.handleUpdateCrust = this.handleUpdateCrust.bind(this);
        this.handleCancelButton = this.handleCancelButton.bind(this);
        this.handleRemoveCrust = this.handleRemoveCrust.bind(this);
        this.handleUpdateCrustForm = this.handleUpdateCrustForm.bind(this);
        this.handleUpdateCrustFormPrice = this.handleUpdateCrustFormPrice.bind(this);
        this.onSortEnd = this.onSortEnd.bind(this);

        this.state = {
            crustsDisplayView: 'list'
        };
    }

    componentDidMount() {
    }

    handleUpdateCrustForm(crust) {
        this.props.dispatch(crustFormUpdate(crust));
    }

    handleUpdateCrustFormPrice(price) {
        this.props.dispatch(crustFormUpdatePrice(price));
    }

    onSortEnd({oldIndex, newIndex})  {

        let newCrustOrder = arrayMove(this.props.settingsForm.crusts, oldIndex, newIndex);
        for (let i = 0; i < newCrustOrder.length; i++) {
            newCrustOrder[i]['crust_order'] = i + 1;
        }

        this.props.dispatch(settingsFormUpdate({ prop: 'crusts', value: newCrustOrder }));
    }

    handleAddCrust() {
        //error checking

        if (this.props.crustForm.name.trim() == '') {
            this.props.dispatch(crustFormError('Please enter a crust name'));
        }
        else {
            let valid = true;

            // go through each price and determine if it is valid
            for (let i = 0; i < this.props.crustForm.prices.length; i++) {

                // ignore any blank rows
                if (!validateCurrency(this.props.crustForm.prices[i].price)) {
                    valid = false;
                    this.props.dispatch(crustFormError("Please ensure that all sizes have a valid price."));
                }
            }

            if (valid) {

                this.props.dispatch(crustFormError(""));
                
                // add the crust
                this.props.dispatch(settingsFormAddCrust(this.props.crustForm));

                // reset the form and return to the main list view
                this.props.dispatch(crustFormReset());
                this.setState(() => ({ crustsDisplayView: 'list' }));
            }
        }
    }

    handleUpdateCrust() {

        //error checking
        if (this.props.crustForm.name.trim() == '') {
            this.props.dispatch(crustFormError('Please enter a crust name'));
        }
        else {
            let valid = true;

            // go through each price and determine if it is valid
            for (let i = 0; i < this.props.crustForm.prices.length; i++) {

                // ignore any blank rows
                if (!validateCurrency(this.props.crustForm.prices[i].price)) {
                    valid = false;
                    this.props.dispatch(crustFormError("Please ensure that all sizes have a valid price."));
                }
            }

            if (valid) {
                this.props.dispatch(crustFormError(""));
                
                // update the choice
                this.props.dispatch(settingsFormUpdateCrust(this.props.crustForm, this.props.crustForm.index));

                // reset the form and return to the main list view
                this.props.dispatch(crustFormReset());
                this.setState(() => ({ crustsDisplayView: 'list' }));
            }
        }
    }

    handleRemoveCrust(index) {
        if (confirm("Remove this crust?")) {
            this.props.dispatch(settingsFormRemoveCrust(index));
        }
    }

    handleAddCrustView() {
        this.props.dispatch(crustFormReset());
        this.props.dispatch(populateNewCrustFormSizes(this.props.sizes));
        this.setState(() => ({ crustsDisplayView: 'new_crust' }));
    }

    handleUpdateCrustView(crust, index) {
        this.props.dispatch(crustFormSet(crust, index));
        this.props.dispatch(populateUpdateCrustFormSizes(crust));
        this.setState(() => ({ crustsDisplayView: 'update_crust' }));
    }

    handleCancelButton() {
        this.setState(() => ({ crustsDisplayView: 'list' }));
    }

    renderCrustsView() {
        switch (this.state.crustsDisplayView) {

            case 'new_crust':
                return (
                    <div>
                        <Typography variant="h5" id="modal-title">
                            Add a new Crust
                        </Typography>
                        
                        <Typography className="modal-description">
                            Enter your crust information and prices, and click 'Add Crust'. Please note: you will still need to save the crusts after adding them.
                        </Typography>
                        
                        <SettingForm 
                            form={this.props.crustForm}
                            handleCancelButton={this.handleCancelButton}
                            handleSubmit={this.handleAddCrust}
                            handleSubmitLabel={'Add Crust'}
                            updateSettingPrice={this.handleUpdateCrustFormPrice}
                            updateSetting={this.handleUpdateCrustForm}
                        />
                    </div>
                );

            case 'update_crust':
                return (
                    <div>
                        <Typography variant="h5" id="modal-title">
                            Update Crust
                        </Typography>
                        
                        <Typography className="modal-description">
                            Update your crust information, and click 'Update Crust'. Please note: you will still need to save the crust after updating.
                        </Typography>

                        <SettingForm 
                            form={this.props.crustForm}
                            handleCancelButton={this.handleCancelButton}
                            handleSubmit={this.handleUpdateCrust}
                            handleSubmitLabel={'Update Crust'}
                            updateSettingPrice={this.handleUpdateCrustFormPrice}
                            updateSetting={this.handleUpdateCrustForm}
                        />
                    </div>
                );

            case 'list':
            default:
                return (
                    <div>
                        <Typography variant="h5" id="modal-title">
                            Crusts
                        </Typography>
                        
                        <Typography className="modal-description">
                            Enter any available pizza crusts that you have, and their prices if included as extra crusts.
                        </Typography>

                        <SortableSettingsList 
                            items={this.props.settingsForm.crusts}
                            handleAddView={this.handleAddCrustView}
                            handleUpdateView={this.handleUpdateCrustView}
                            handleRemoveItem={this.handleRemoveCrust}
                            emptyLabel={"No crusts."}
                            addLabel={"Add Crust"}
                            onSortEnd={this.onSortEnd}
                        />
                    </div>
                );
        }
    }

    render() {
        return (
            <div>
                {this.renderCrustsView()}
                {this.state.crustsDisplayView == 'list' && this.props.children}
            </div>
        );
    }
};

const mapStateToProps = (state, props) => {
    return {
        settingsForm: state.pizzaSettingsForm,
        sizes: state.pizzas.settings.sizes,
        crustForm: state.crustForm
    };
};

export default connect(mapStateToProps)(ConfigCrusts);