import React from 'react';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import SortableContainer from '../../../common/sort/SortableContainer';
import SortableHandle from '../../../common/sort/SortableHandle';
import SortableItem from '../../../common/sort/SortableItem';

class ChoicesList extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    render() {
        return (
            <div>
                {this.props.foodItemForm.choices.length <= 0 && (
                    <p>No choices added.</p>
                )}

                {this.props.foodItemForm.choices.length > 0 && (
                    <SortableContainer helperClass='sortableHelper' onSortEnd={this.props.onSortEnd} useDragHandle>
                        <Grid container>
                            <Grid item xs={12} className="sortable-choice-list">
                                {this.props.foodItemForm.choices.map((item, index) => (
                                    <SortableItem key={"settingssize-"+index} index={index}>
                                        <Grid key={"item-"+index} className="sortable-menu-item" container>
                                            <Grid item xs={1} className="list-sort">
                                                <SortableHandle />
                                            </Grid>
                                            <Grid item xs={9} className="list-name">
                                                <Typography>{item.name}</Typography>
                                            </Grid>
                                            <Grid item xs={1} className="list-sort">
                                                <Button fullWidth={true} color="primary" onClick={() => this.props.handleUpdateChoiceView(item, index)}>
                                                    <EditIcon />
                                                </Button>
                                            </Grid>
                                            <Grid item xs={1} className="list-sort">
                                                <Button fullWidth={true} color="secondary" onClick={() => this.props.handleRemoveChoice(index)}>
                                                    <DeleteIcon />
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </SortableItem>
                                ))}
                            </Grid>
                        </Grid>
                    </SortableContainer>
                )}
{/* 
                {this.props.foodItemForm.choices.map((choice, index) => (
                    <Grid key={"fooditemchoice-"+index} container>
                        <Grid item xs={1} className="form-grid">
                            <Button 
                                variant="contained" 
                                fullWidth={true} 
                                color="primary" 
                                className="modal-button-list" 
                                onClick={this.submitCreateFoodItem}
                            >
                                <HeightIcon className="modal-button-icon" />
                            </Button>
                        </Grid>
                        <Grid item xs={9} className="form-grid">
                            <Typography>{choice.name}</Typography>
                        </Grid>
                        <Grid item xs={1} className="form-grid">
                            <Button variant="contained" fullWidth={true} color="primary" className="modal-button-list" onClick={() => this.props.handleUpdateChoiceView(choice, index)}>
                                <EditIcon className="modal-button-icon" />
                            </Button>
                        </Grid>
                        <Grid item xs={1} className="form-grid">
                            <Button variant="contained" fullWidth={true} color="primary" className="modal-button-list" onClick={() => this.props.handleRemoveChoice(index)}>
                                <DeleteIcon className="modal-button-icon" />
                            </Button>
                        </Grid>
                    </Grid>
                ))} */}
                <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom" onClick={this.props.handleAddChoiceView}>
                    New Choice
                </Button>
            </div>
        );
    }
};

const mapStateToProps = (state, props) => {
    return {
        foodItemForm: state.foodItemForm
    };
};

export default connect(mapStateToProps)(ChoicesList);