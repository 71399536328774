import React from 'react';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

import validateCurrency from '../../../../utils/validateCurrency';
import { addSpecial } from '../../../../actions/menu/specials/specials';
import { specialFormError, specialFormReset, specialFormUpdate } from '../../../../actions/menu/specials/specialForm';
import { openSpecialCreate, closeSpecialCreate } from '../../../../actions/modals';

import CloseIcon from '../../../common/modal/CloseIcon';

import Info from './Info';
import Items from './Items';

class CreateSpecial extends React.Component {

    constructor(props) {
        super(props);

        this.toggleCreateModal = this.toggleCreateModal.bind(this);
        this.checkSpecialInfo = this.checkSpecialInfo.bind(this);
        this.checkAddSpecialItems = this.checkAddSpecialItems.bind(this);
        this.submitCreateSpecial = this.submitCreateSpecial.bind(this);
        this.updateTabSelection = this.updateTabSelection.bind(this);
    }

    getSteps() {
        return [
            'General Information',
            'Available Items'
        ];
    }

    toggleCreateModal(isOpen) {
        if (isOpen) {
            this.props.dispatch(specialFormReset());
            this.props.dispatch(openSpecialCreate());
        }
        else {
            this.props.dispatch(closeSpecialCreate());
        }
    }

    checkAddSpecialItems() {

        let valid = true;

        if (this.props.specialForm.items.length <= 0) {
            valid = false;
            this.props.dispatch(specialFormError("Please enter at least one item for the special."));
        }

        if (valid) {
            this.props.dispatch(specialFormError(""));
            this.props.dispatch(addSpecial(this.props.restaurantId, this.props.specialForm));
        }
    }

    updateTabSelection(index) {
        this.props.dispatch(specialFormUpdate({ prop: 'activeTab', value: index }));
    }

    checkSpecialInfo() {

        // perform some error checking
        if (this.props.specialForm.name.trim() == "" || !validateCurrency(this.props.specialForm.price) || this.props.specialForm.price <= 0) {
            this.props.dispatch(specialFormError("Please enter a special name and valid price greater than 0."));
        }else if(this.props.specialForm.saveSchedule && this.props.specialForm.scheduleName.trim() == ""){
            this.props.dispatch(specialFormError("Please enter a name for the availability schedule."));
        }else if(this.props.specialForm.saveSchedule && this.props.restaurant.availability_schedules.map((schedule) => {return schedule.name}).includes(this.props.specialForm.scheduleName.trim())){
            this.props.dispatch(specialFormError("An availability schedule with that name already exists."));
        } else {

            this.props.dispatch(specialFormError(""));
            this.props.dispatch(specialFormUpdate({ prop: 'activeTab', value: 1 }));
        }
    }

    submitCreateSpecial() {
        this.props.dispatch(addSpecial(this.props.restaurantId, this.props.categoryId, this.props.specialForm));
    }

    renderCreateModalActiveTab() {
        if (this.props.loading) {
            return (
                <div className="page-loading">
                    <CircularProgress size={100} />
                </div>
            );
        }
        else {
            switch(this.props.specialForm.activeTab) {
                case 0:
                    return this.renderCreateModalInfo();

                case 1:
                    return this.renderCreateModalItems();
            }
        }
    }

    renderCreateModalInfo() {
        return (
            <div>
                <Info />

                <Button variant="contained" fullWidth={true} className="modal-button-bottom" onClick={this.checkSpecialInfo}>
                    Next - Add Available Items
                </Button>
            </div>
        );
    }

    renderCreateModalItems() {
        return (
            <div>
                { this.props.specialForm.error && (
                    <Typography className="modal-error">
                        { this.props.specialForm.error }
                    </Typography>
                )}

                <Typography variant="h5" id="modal-title">
                    Available Items
                </Typography>

                <Typography className="modal-description">
                    Enter any available food items with the special, along with a price/size if necessary.
                </Typography>

                <Items
                    checkAddSpecialItems={this.checkAddSpecialItems}
                >
                    <Grid container>
                        <Grid item xs={6} className="form-grid">
                            <Button variant="contained" fullWidth={true} className="modal-button-bottom btn-secondary" onClick={() => this.updateTabSelection(0)}>
                                Back
                            </Button>
                        </Grid>
                        <Grid item xs={6} className="form-grid">
                            <Button variant="contained" fullWidth={true} className="modal-button-bottom" onClick={this.checkAddSpecialItems}>
                                Add Special
                            </Button>
                        </Grid>
                    </Grid>
                </Items>
            </div>
        );
    }

    render() {
        return (
            <Modal
                aria-labelledby="special-add"
                aria-describedby="special-add"
                open={this.props.createModalOpen}
                onClose={() => this.toggleCreateModal(false)}
                className="modal_admin"
                disableBackdropClick
            >
                <div className="modal-member">
                    <Typography variant="h4" id="modal-title">
                        <CloseIcon/>
                    </Typography>
                    <Stepper activeStep={this.props.specialForm.activeTab} alternativeLabel>
                        {this.getSteps().map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    <Typography variant="h4" id="modal-title">
                        Add a Special
                    </Typography>

                    { this.props.specialForm.error && (
                        <Typography className="modal-error">
                            { this.props.specialForm.error }
                        </Typography>
                    )}

                    {this.renderCreateModalActiveTab()}
                </div>
            </Modal>
        );
    }
};

const mapStateToProps = (state, props) => {
    return {
        loading: state.specials.loading,
        restaurant: state.selectedRestaurant.restaurant,
        specialForm: state.specialForm,
        createModalOpen: state.modals.specialCreate
    };
};

export default connect(mapStateToProps)(CreateSpecial);