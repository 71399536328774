import React from 'react';
import { connect } from 'react-redux';

import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';

import { appDirectoryFormUpdate } from '../../../actions/superuser/appDirectories/appDirectoryForm';

class AppDirectoryInfo extends React.Component {
    
    render() {
        return (
            <Grid container>
                <Grid item xs={12} className="form-grid">
                    <Typography className="modal-description">
                        Please enter an app directory name and local timezone.
                    </Typography>
                </Grid>

                <Grid item xs={6} className="form-grid">
                    <TextField
                        label="Name"
                        className="modal-input"
                        fullWidth={true}
                        variant="outlined"
                        value={this.props.appDirectoryForm.name}
                        onChange={(e) => { this.props.dispatch(appDirectoryFormUpdate({ prop: 'name', value: e.target.value })); }}
                        margin="normal"
                    />
                </Grid>

                <Grid item xs={6} className="form-grid">
                    <InputLabel className="input-label-mb"></InputLabel>
                    <Select
                        fullWidth={true}
                        labelId="timezone"
                        variant="outlined"
                        value={this.props.appDirectoryForm.timezone}
                        onChange={(e) => { this.props.dispatch(appDirectoryFormUpdate({ prop: 'timezone', value: e.target.value })); }}
                    >
                        {this.props.timezones.map((tz) => <MenuItem key={tz} value={tz}>{tz}</MenuItem>)}
                    </Select>
                </Grid>      
            </Grid>      
        );
    }
};

const mapStateToProps = (state, props) => {
    return {
        appDirectoryForm: state.appDirectoryForm,
        timezones: state.timezones.timezones
    };
};

export default connect(mapStateToProps)(AppDirectoryInfo);