const categoryFormDefaultState = {
    name: '',
    description: '',
    message: '',
    error: '',
};

export default (state = categoryFormDefaultState, action) => {
    
    switch (action.type) {

        case 'CATEGORY_FORM_UPDATE':
            return {
                ...state,
                [action.category.prop]: action.category.value
            };

        case 'CATEGORY_FORM_ERROR':
            return {
                ...state,
                message: '',
                error: action.message
            }

        case 'CATEGORY_FORM_SUCCESS':
            return {
                ...state,
                error: '',
                message: action.message
            }

        case 'CATEGORY_FORM_RESET':
            return categoryFormDefaultState;

        default:
            return state;
    }
};