import axios from 'axios';
import { API_URL } from '../config/constants';
import setAuthHeader from '../utils/setAuthHeader';
import { readErrMsg } from './helpers/readErrMsg';
import { loginFormReset, loginFormUpdate, loginFormError } from './loginForm';
import { resetPasswordFormUpdate } from '../actions/resetPasswordForm';
import { removeCurrentUser, resetAllReducers } from './loggedUser';
import { registerManagerFormReset} from './registerManagerForm';
import { loadRestaurants } from './restaurants';
import { loadCategories } from './menu/categories/categories';


// @TODO: this can be moved to a util
export const authorize = () => {

    let token = localStorage.getItem('c2o.token');

    // is the token present?
    if (token) {
        setAuthHeader(token);
    }
}

export const checkPasswordResetTokenForEmail = (email, password_reset_token, errorHandler, formUpdater, errorMessage, loadingPropName, criticalErrorPropName='') => {
    return (dispatch) => {
        return axios.post(API_URL+'/api/checkResetPasswordToken', {email, password_reset_token})
            .then(function(res) {

                // Do nothing?
                dispatch(formUpdater({prop: loadingPropName, value: false}));

            })
            .catch(function(err) {
                if (err.response.status == 422) {
                    dispatch(errorHandler("Invalid data provided. " + errorMessage));
                }
                else {
                    dispatch(errorHandler("Code "+err.response.status+": "+err.response.data.error+". " + errorMessage));
                }
                //Hide form and only show message?
                if(criticalErrorPropName != ''){
                    dispatch(formUpdater({prop: criticalErrorPropName, value: true}));
                }
                dispatch(formUpdater({prop: loadingPropName, value: false}));
            });
    };
}

export const resetPassword = (email, password, password2, password_reset_token, errorForm, history, source, sitename, lid) => {
    return (dispatch) => {
        return axios.post(API_URL+'/api/resetpassword', {email, password, password2, password_reset_token, source, sitename, lid})
            .then(function(res) {

                //Set success message for login form and push them there
                if(source == 'app'){
                    dispatch(resetPasswordFormUpdate({prop: 'message', value: 'Password successfully set! Please return to the app and log in with your new password!'}));
                    dispatch(resetPasswordFormUpdate({prop: 'successAppReset', value: true}));
                }else if(source == 'online'){

                    if(sitename != 'unspecified' && lid != 'unspecified' && res.data.data.restaurantName && res.data.data.onlineOrderingLink){
                        // Expected outcome
                        dispatch(resetPasswordFormUpdate({prop: 'onlineReturnLink', value: res.data.data.onlineOrderingLink}));
                        dispatch(resetPasswordFormUpdate({prop: 'onlineReturnName', value: res.data.data.restaurantName}));
                        dispatch(resetPasswordFormUpdate({prop: 'successAppReset', value: true}));
                        dispatch(resetPasswordFormUpdate({prop: 'successShowOnlineLink', value: true}));
                    }else{
                        //in case restaurant data was malformed along the way
                        dispatch(resetPasswordFormUpdate({prop: 'message', value: 'Password successfully set! Please return to your selected restaurant and log in with your new password!'}));
                        dispatch(resetPasswordFormUpdate({prop: 'successAppReset', value: true}));
                    }
                    
                }else{
                    dispatch(loginFormUpdate({prop: 'message', value: 'Password successfully set! You may now log in.'}));
                    history.push('/login');
                }

            })
            .catch(function(err) {
                console.log(err);
                console.log(err.response.data);
                if (err.response.status == 401) {
                    dispatch(errorForm("Invalid login credentials entered"));
                }
                else {
                    dispatch(errorForm("Code "+err.response.status+": "+err.response.data.message));
                }
            });
    };
}

export const registerManager = (email, password, password2, firstName, lastName, password_reset_token, errorForm, history) => {
    return (dispatch) => {
        return axios.post(API_URL+'/api/registerManager', {email, password, password2, firstName, lastName, password_reset_token})
            .then(function(res) {

                const token = res.data.access_token;
                localStorage.setItem('c2o.token', token);

                setAuthHeader(token);
                dispatch(registerManagerFormReset());
                history.push('/');

            })
            .catch(function(err) {
                console.log('registerManager err response');
                console.log(err);
                console.log(err.response.data);
                if (err.response.status == 401) {
                    dispatch(errorForm("Invalid login credentials entered"));
                }
                else {
                    dispatch(errorForm("Code "+err.response.status+": "+err.response.data.message));
                }
            });
    };
}

export const login = (email, password, remember, history) => {
    return (dispatch) => {
        return axios.post(API_URL+'/api/login', {email, password, remember, source: 'manager'})
            .then(function(res) {

                const token = res.data.access_token;
                localStorage.setItem('c2o.token', token);

                setAuthHeader(token);
                dispatch(loginFormReset());

                let loginDestination = getLoginDestination(res.data.userInfo);
                history.push(loginDestination);

            })
            .catch(function(err) {
                let response = readErrMsg(err);
                if (response.statusCode == 401) {
                    dispatch(loginFormError(response.msg));
                }
                else {
                    dispatch(loginFormError("Code "+response.statusCode+": "+response.msg));
                }
            });
    };
}

export const logout = (history) => {
    return (dispatch) => {
        localStorage.removeItem('c2o.token');
        dispatch(removeCurrentUser());
        history.push('/login');
        dispatch(resetAllReducers());
    };
}

export const loadPageData = (params, scope = null) => {
    return (dispatch) => {
        
        if (params.id) {
            dispatch(loadRestaurants(params.id, scope));
        }

        if (params.category_id) {
            dispatch(loadCategories(params.id, params.category_id));
        }
    };
}

function getLoginDestination(userInfo){
    let destination = '/';

    if(userInfo.permissionLevel == 'superuser'){

        return destination;
    }else if(userInfo.dispatchPermissions.length > 0 && userInfo.restaurantPermissions.length > 0){ // Hybrid dispatch/restaurant manager

        return destination;
    }else if(userInfo.dispatchPermissions.length > 0 && userInfo.restaurantPermissions.length == 0){ // Strictly dispatch user

        destination = '/admin/dispatch-centers';
        return destination;
    }else if(userInfo.dispatchPermissions.length == 0 && userInfo.restaurantPermissions.length > 1){ // Multiple restaurants manager

        return destination;
    }else if(userInfo.dispatchPermissions.length == 0 && userInfo.restaurantPermissions.length == 1){ // Single restaurants manager

        if(userInfo.restaurantPermissions[0] && userInfo.restaurantPermissions[0].restaurant_id){
            destination = '/'+userInfo.restaurantPermissions[0].restaurant_id+'/dashboard';
        }
        
        return destination;
    }else{

        return destination;
    }

}