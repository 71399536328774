import React from 'react';
import { sortableContainer  } from 'react-sortable-hoc';

import Grid from '@material-ui/core/Grid';

const SortableContainer = (props) => {
    return (
        <div>{props.children}</div>
    );
}

export default sortableContainer(SortableContainer);
