import React from 'react';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import DeleteIcon from '@material-ui/icons/Delete';

import SortableContainer from '../../../common/sort/SortableContainer';
import SortableHandle from '../../../common/sort/SortableHandle';
import SortableItem from '../../../common/sort/SortableItem';

class ItemsList extends React.Component {

    constructor(props) {
        super(props);

        this.renderItemName = this.renderItemName.bind(this);
    }

    componentDidMount() {
    }

    renderItemName(item) {
        if (item.name) {
            return item.name;
        }
        else {
            return "Some Name";
        }
    }

    render() {
        return (
            <div>
                {this.props.specialForm.items.length <= 0 && (
                    <p>No items added.</p>
                )}

                <SortableContainer helperClass='sortableHelper' onSortEnd={this.props.onSortEnd} useDragHandle>
                    {this.props.specialForm.items.map((item, index) => (

                        <SortableItem key={"item-"+index} index={index}>
                            <Grid key={"item-"+index} container>
                                <Grid item xs={1} className="list-sort">
                                    <SortableHandle />
                                </Grid>
                                <Grid item xs={10} className="form-grid">
                                    <p>{this.renderItemName(item)}</p>
                                </Grid>
                                <Grid item xs={1} className="form-grid">
                                    <Button variant="contained" fullWidth={true} color="primary" className="modal-button-list" onClick={() => this.props.handleRemoveItem(index)}>
                                        <DeleteIcon className="modal-button-icon" />
                                    </Button>
                                </Grid>
                            </Grid>
                        </SortableItem>
                    ))}
                </SortableContainer>

                <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom" onClick={this.props.handleAddItemView}>
                    Add A New Item
                </Button>

                {this.props.children}
            </div>
        );
    }
};

const mapStateToProps = (state, props) => {
    return {
        specialForm: state.specialForm
    };
};

export default connect(mapStateToProps)(ItemsList);