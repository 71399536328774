import { promoCodeFormError } from './promoCodeForm';
import { loadLocations } from './locations';
import axios from 'axios';
import { API_URL } from '../../config/constants';
import { readErrMsg } from '../helpers/readErrMsg';

export const locationFormUpdate = ({ prop, value }) => {
    return {
        type: 'LOCATION_FORM_UPDATE',
        location: { prop, value }
    };
};

export const locationFormError = (message) => {

    return {
        type: 'LOCATION_FORM_ERROR',
        message
    };
};

export const locationFormHolidayError = (message) => {

    return {
        type: 'LOCATION_FORM_HOLIDAY_ERROR',
        message
    };
};

export const locationFormSuccess = (message) => {

    return {
        type: 'LOCATION_FORM_SUCCESS',
        message
    };
};

export const locationFormReset = () => {
    
    return {
        type: 'LOCATION_FORM_RESET',
    };
}

export const locationFormNewHolidayReset = () => {
    
    return {
        type: 'LOCATION_FORM_NEW_HOLIDAY_RESET',
    };
}

export const locationFormUpdateHourOfOperation = ({ prop, value }) => {

    return {
        type: 'LOCATION_FORM_UPDATE_HOUR_OF_OPERATION',
        location: { prop, value }
    };
};

export const locationFormUpdateDeliveryHours = ({ prop, value }) => {

    return {
        type: 'LOCATION_FORM_UPDATE_DELIVERY_HOURS',
        location: { prop, value }
    };
};

export const locationFormAddDeliveryCharge = (deliveryCharge) => {

    return {
        type: 'LOCATION_FORM_ADD_DELIVERY_CHARGE',
        deliveryCharge
    }
};

export const locationFormRemoveDeliveryCharge = (index) => {

    return {
        type: 'LOCATION_FORM_REMOVE_DELIVERY_CHARGE',
        index
    }
}

export const locationFormUpdateDeliveryCharge = ({ prop, index, value }) => {

    return {
        type: 'LOCATION_FORM_UPDATE_DELIVERY_CHARGE',
        deliveryCharge: { prop, index, value }
    };
};

export const locationFormAddPromoCode = (promoCode) => {

    return {
        type: 'LOCATION_FORM_ADD_PROMO_CODE',
        promoCode
    }
};

export const locationFormRemovePromoCode = (index) => {

    return {
        type: 'LOCATION_FORM_REMOVE_PROMO_CODE',
        index
    }
}

export const locationFormPerformUpdatePromoCode = (promoCode, index) => {

    return {
        type: 'LOCATION_FORM_UPDATE_PROMO_CODE',
        promoCode,
        index
    }
}

export const locationFormUpdatePromoCode = (promoCode, index, currentPromoCode) => {

    promoCode.max_uses          = +promoCode.max_uses;
    promoCode.remaining_uses    = (+currentPromoCode.max_uses > 0 && +promoCode.max_uses == +currentPromoCode.max_uses) ? +currentPromoCode.remaining_uses: +promoCode.max_uses; // Need to compare to previous state to see if we show the remaining_uses as new max_uses or the previous value
    promoCode.total_avail       = promoCode.total_avail;
    promoCode.total_type        = promoCode.total_type; // PERCENTAGE OR FIXED
    promoCode.start_time        = (promoCode.start_time == null) ? null: new Date(promoCode.start_time);
    promoCode.end_time          = (promoCode.end_time == null) ? null: new Date(promoCode.end_time);

    return (dispatch) => {
        dispatch(promoCodeFormError(""));
        dispatch(locationFormPerformUpdatePromoCode({promoCode, index}));
    }
    
};

export const addHolidayHours = (locationForm, selectedLocation) => {
    return (dispatch) => {

        return axios.post(
            API_URL+'/api/restaurants/'+selectedLocation.restaurant_id+'/holidayhours/create/'+selectedLocation.id, 
            {
                holidayHoursNewStartDate: locationForm.holidayHoursNewStartDate,
                holidayHoursNewEndDate: locationForm.holidayHoursNewEndDate,
                holidayHoursNewOpenTime: locationForm.holidayHoursNewOpenTime,
                holidayHoursNewCloseTime: locationForm.holidayHoursNewCloseTime,
                holidayHoursNewClosedAllDay: locationForm.holidayHoursNewClosedAllDay,
            }
        ).then(function(res) {
            // add the new hours
            dispatch(locationFormNewHolidayReset());
            dispatch(loadLocations(selectedLocation.restaurant_id, selectedLocation.id)).then(function() {
                dispatch(locationFormUpdate({ prop: 'triggerUpdate', value: true }));
            });
        })
        .catch(function(err) {
            let response = readErrMsg(err);
            if (response.statusCode == 500) {
                dispatch(locationFormHolidayError(response.msg));
            }
            else {
                dispatch(locationFormHolidayError(response.msg));
            }
        });
    };
}

export const updateHolidayHours = (locationForm, selectedLocation, holiday_id) => {
    return (dispatch) => {

        return axios.post(
            API_URL+'/api/restaurants/'+selectedLocation.restaurant_id+'/holidayhours/update/'+selectedLocation.id+'/'+holiday_id, 
            {
                holidayHoursNewStartDate: locationForm.holidayHoursNewStartDate,
                holidayHoursNewEndDate: locationForm.holidayHoursNewEndDate,
                holidayHoursNewOpenTime: locationForm.holidayHoursNewOpenTime.local().format('h:mm a'),
                holidayHoursNewCloseTime: locationForm.holidayHoursNewCloseTime.local().format('h:mm a'),
                holidayHoursNewClosedAllDay: locationForm.holidayHoursNewClosedAllDay,
            }
        ).then(function(res) {
            // add the new hours
            dispatch(locationFormNewHolidayReset());
            dispatch(loadLocations(selectedLocation.restaurant_id, selectedLocation.id));
        })
        .catch(function(err) {
            let response = readErrMsg(err);
            if (response.statusCode == 500) {
                dispatch(locationFormHolidayError(response.msg));
            }
            else {
                dispatch(locationFormHolidayError(response.msg));
            }
        });
    };
}



export const removeHolidayHours = (selectedLocation, holiday_id) => {
    return (dispatch) => {

        return axios.post(
            API_URL+'/api/restaurants/'+selectedLocation.restaurant_id+'/holidayhours/remove/'+selectedLocation.id+'/'+holiday_id
        ).then(function(res) {
            // add the new hours
            dispatch(locationFormNewHolidayReset());
            dispatch(loadLocations(selectedLocation.restaurant_id, selectedLocation.id));
        })
        .catch(function(err) {
            let response = readErrMsg(err);
            if (response.statusCode == 500) {
                dispatch(locationFormHolidayError(response.msg));
            }
            else {
                dispatch(locationFormHolidayError(response.msg));
            }
        });
    };
}