// the default items that we can possibly filter by
const restaurantLocationsDefaultState = {
    restaurants: []
};

export default (state = restaurantLocationsDefaultState, action) => {
    switch (action.type) {
        case 'SET_ADMIN_RESTAURANT_LOCATIONS':
            return {
                restaurants: action.restaurants
            };
            
        case 'RESET_ADMIN_RESTAURANT_LOCATIONS':
            return restaurantLocationsDefaultState;

        default:
            return state;
    }
};