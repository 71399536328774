import React from 'react';
import { connect } from 'react-redux';

import { updateDispatchCenter } from '../../../actions/superuser/dispatchCenters/centers';
import { dispatchCenterFormError } from '../../../actions/superuser/dispatchCenters/dispatchCenterForm';

import UpdateLocations from './common/UpdateLocations';

class DispatchCenterLocations extends React.Component {

    constructor(props) {
        super(props);

        this.addDispatchCenterLocation = this.addDispatchCenterLocation.bind(this);
        this.deleteDispatchCenterLocation = this.deleteDispatchCenterLocation.bind(this);
    }

    addDispatchCenterLocation(selectedLocationId) {

        if (selectedLocationId > 0) {
            this.props.dispatch(updateDispatchCenter(this.props.dispatchCenter.id, 'add_location', {location_id: selectedLocationId}));
        }
        else {
            this.props.dispatch(dispatchCenterFormError());  
        }
    }

    deleteDispatchCenterLocation(location) {

        this.props.dispatch(updateDispatchCenter(this.props.dispatchCenter.id, 'delete_location', {location_id: location.location.id}));
    }
    
    render() {
        return (
            <UpdateLocations
                locations={this.props.locations}
                deleteLocation={this.deleteDispatchCenterLocation}
                addLocation={this.addDispatchCenterLocation}
            />
        );
    }
};

const mapStateToProps = (state, props) => {
    return {
        dispatchCenter: state.dispatchCenters.selectedDispatchCenter,
        locations: state.dispatchCenters.selectedDispatchCenter.locations,
        dispatchCenterForm: state.dispatchCenterForm,
        restaurants: state.adminRestaurantLocations.restaurants
    };
};

export default connect(mapStateToProps)(DispatchCenterLocations);