import React from 'react';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import Grid from '@material-ui/core/Grid';

import { addAppDirectory } from '../../../actions/superuser/appDirectories/directories';
import { appDirectoryFormError, appDirectoryFormReset } from '../../../actions/superuser/appDirectories/appDirectoryForm';
import { openAppDirectoryCreate, closeAppDirectoryCreate } from '../../../actions/modals';

import CloseIcon from '../../common/modal/CloseIcon';

import Info from './AppDirectoryInfo';

class CreateAppDirectory extends React.Component {

    constructor(props) {
        super(props);

        this.toggleCreateModal = this.toggleCreateModal.bind(this);
        this.submitCreateAppDirectory = this.submitCreateAppDirectory.bind(this);
    }

    toggleCreateModal(isOpen) {
        if (isOpen) {
            this.props.dispatch(appDirectoryFormReset());
            this.props.dispatch(openAppDirectoryCreate());
        }
        else {
            this.props.dispatch(closeAppDirectoryCreate());
        }
    }

    submitCreateAppDirectory() {

        // perform some error checking
        if (this.props.appDirectoryForm.name.trim() == "") {

            // required info isn't present
            this.props.dispatch(appDirectoryFormError("Please enter a directory name."));
        }
        else {

            this.props.dispatch(appDirectoryFormError(""));
            this.props.dispatch(addAppDirectory(this.props.appDirectoryForm));
        }
    }

    render() {
        return (
            <Modal
                aria-labelledby="app-directory-add"
                aria-describedby="app-directory-add"
                open={this.props.createModalOpen}
                onClose={() => this.toggleCreateModal(false)}
                className="modal_admin"
                disableBackdropClick
            >
                <div className="modal-member">
                    <Typography variant="h4" id="modal-title">
                        <CloseIcon/>
                    </Typography>
                    <Grid container>
                        <Grid item xs={12} className="form-grid">
                            <Typography variant="h2" id="modal-title">
                                Add an App Directory
                            </Typography>
                        </Grid>
                    </Grid>

                    <Info />

                    <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom" onClick={this.submitCreateAppDirectory}>
                        Add Directory
                    </Button>
                </div>
            </Modal>
        );
    }
};

const mapStateToProps = (state, props) => {
    return {
        loading: state.directories.loading,
        restaurant: state.selectedRestaurant.restaurant,
        appDirectoryForm: state.appDirectoryForm,
        createModalOpen: state.modals.appDirectoryCreate
    };
};

export default connect(mapStateToProps)(CreateAppDirectory);