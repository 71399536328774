import React from 'react';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

import LoadScreen from '../common/LoadScreen';
import verifyLogin from '../../utils/verifyLogin';
import { loadDispatchCenters, setDispatchCenterFilter, setSelectedDispatchCenter } from '../../actions/superuser/dispatchCenters/centers';
import filterDispatchCenters from '../../utils/filterListName';
import { dispatchCenterFormReset, dispatchCenterFormUpdate } from '../../actions/superuser/dispatchCenters/dispatchCenterForm';
import { openDispatchCenterCreate, closeDispatchCenterCreate, openDispatchCenterUpdate, closeDispatchCenterUpdate } from '../../actions/modals';

import CreateDispatchCenter from './modal/CreateDispatchCenter';
import UpdateDispatchCenter from './modal/UpdateDispatchCenter';

import checkPermissions from '../../utils/checkUserPermissions';

class DispatchCentersPage extends React.Component {

    constructor(props) {
        super(props);

        this.checkPermissions = this.checkPermissions.bind(this);

        this.state = {
            sidebarMenuOpen: false
        };
    }

    componentDidMount() {
        verifyLogin(this.props.history);
    }

    checkPermissions() {
        if (checkPermissions(this.props.user, 'dispatch_all')) {
            this.props.dispatch(loadDispatchCenters());
        } else {
            this.props.history.push('/permission-denied');
        }
    }

    toggleCreateModal(isOpen) {
        if (isOpen) {
            this.props.dispatch(dispatchCenterFormReset());
            this.props.dispatch(openDispatchCenterCreate());
        }
        else {
            this.props.dispatch(closeDispatchCenterCreate());
        }
    }

    toggleUpdateModal(isOpen, selectedDispatchCenter) {
        
        if (isOpen) {
            this.props.dispatch(dispatchCenterFormReset());
            this.props.dispatch(setSelectedDispatchCenter(selectedDispatchCenter));
            this.props.dispatch(dispatchCenterFormUpdate({ prop: 'name', value: selectedDispatchCenter.name }));
            this.props.dispatch(dispatchCenterFormUpdate({ prop: 'type', value: selectedDispatchCenter.type }));
            this.props.dispatch(dispatchCenterFormUpdate({ prop: 'timezone', value: selectedDispatchCenter.timezone }));
            this.props.dispatch(dispatchCenterFormUpdate({ prop: 'sms_update_enabled', value: (selectedDispatchCenter.sms_update_enabled > 0) ? true : false }));
            this.props.dispatch(dispatchCenterFormUpdate({ prop: 'phone', value: selectedDispatchCenter.phone ?? '' }));
            this.props.dispatch(dispatchCenterFormUpdate({ prop: 'getswift_update_enabled', value: (selectedDispatchCenter.getswift_update_enabled > 0) ? true : false }));
            this.props.dispatch(dispatchCenterFormUpdate({ prop: 'getswift_api_key', value: selectedDispatchCenter.getswift_api_key ?? '' }));
            this.props.dispatch(dispatchCenterFormUpdate({ prop: 'cartwheel_update_enabled', value: (selectedDispatchCenter.cartwheel_update_enabled > 0) ? true : false }));
            this.props.dispatch(dispatchCenterFormUpdate({ prop: 'cartwheel_team_id', value: selectedDispatchCenter.cartwheel_team_id ?? '' }));
            this.props.dispatch(dispatchCenterFormUpdate({ prop: 'delivery_time', value: selectedDispatchCenter.delivery_time ?? 15 }));
            this.props.dispatch(dispatchCenterFormUpdate({ prop: 'delivery_hours', value: selectedDispatchCenter.delivery_hours }));
            this.props.dispatch(dispatchCenterFormUpdate({ prop: 'open', value: selectedDispatchCenter.open ?? 0 }));

            this.props.dispatch(openDispatchCenterUpdate());
        }
        else {
            this.props.dispatch(closeDispatchCenterUpdate());
        }
    }

    renderDispatchCenters() {

        if (this.props.loading) {
            return (
                <div className="page-loading">
                    <CircularProgress size={100} />
                </div>
            );
        }
        else {
            return this.props.dispatchCenters.map((center) => (
                <Grid item xs={3} className="restaurant-card-grid" key={"center-"+center.id}>
                    <Card className="card restaurant-card">
                        <CardActionArea onClick={() => this.toggleUpdateModal(true, center)}>
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="h2">
                                    {center.name}
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                        <CardActions>
                            <Button size="small" color="primary" onClick={() => this.props.history.push('/admin/dispatch-centers/'+center.id+'/orders')}>
                                Orders Feed
                            </Button>
                            <Button size="small" color="primary" onClick={() => this.props.history.push('/admin/dispatch-centers/'+center.id+'/reports')}>
                                Reports
                            </Button>
                            {(checkPermissions(this.props.user, 'superuser')) && (
                                <Button size="small" color="primary" onClick={() => this.toggleUpdateModal(true, center)}>
                                    Edit
                                </Button>
                            )}
                        </CardActions>
                    </Card>
                </Grid>
            ));
        }
    }

    render() {
        return (
            <LoadScreen
                checkPermissions={this.checkPermissions}
            >
                <div className="card-container">
                    <Grid container>
                        <Grid item xs={12} className="heading-grid">
                            <h1>Dispatch Centers</h1>
                            <FormControl fullWidth>
                                <TextField 
                                    label="Filter App Directories" 
                                    variant="outlined" 
                                    value={this.props.filter}
                                    onChange={(e) => this.props.dispatch(setDispatchCenterFilter(e.target.value))} 
                                />
                            </FormControl>
                        </Grid>
                        {this.renderDispatchCenters()}
                    </Grid>
                </div>

                <div className="window-fab">
                    <Fab className="btn-fab btn-add" onClick={() => this.toggleCreateModal(true)}>
                        <AddIcon />
                    </Fab>
                </div>

                <CreateDispatchCenter />

                <UpdateDispatchCenter />
            </LoadScreen>
        );
    }
};

const mapStateToProps = (state, props) => {
    return {
        loading: state.dispatchCenters.loading,
        user: state.loggedUser.user,
        dispatchCenters: filterDispatchCenters(state.dispatchCenters.centers, {text: state.dispatchCenters.filter})
    };
};

export default connect(mapStateToProps)(DispatchCentersPage);