import React from 'react';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import SearchIcon from '@material-ui/icons/Search';

import LoadScreen from '../common/LoadScreen';
import verifyLogin from '../../utils/verifyLogin';
import { 
    exportDispatchReportCSV,
    loadDispatchOrdersReport,
    setOrdersReportLoading,
    setOrdersSummarySearchLoading
} from '../../actions/reports/orders';
import { searchFormReset } from '../../actions/reports/searchForm';
import { openOrderDetails, closeOrderDetails, openSearchReports, closeSearchReports } from '../../actions/modals';
import { loadPageData } from '../../actions/auth';

import OrdersList from './common/OrdersList';
import OrderDetails from './modal/OrderDetails';
import SearchOrders from '../common/SearchOrders';

import checkPermissions from '../../utils/checkUserPermissions';

import "jspdf-autotable";
import generatePDF from "./common/generatePDF";

class DispatchReportsPage extends React.Component {

    constructor(props) {
        super(props);

        this.toggleViewOrder = this.toggleViewOrder.bind(this);
        this.toggleSearchModal = this.toggleSearchModal.bind(this);
        this.submitSearchOrders = this.submitSearchOrders.bind(this);
        this.checkPermissions = this.checkPermissions.bind(this);

        this.state = {
            sidebarMenuOpen: false,
            selectedOrder: {},
            displayedSearchData: {}
        };
    }

    componentDidMount() {
        verifyLogin(this.props.history);
    }

    checkPermissions() {
        if (checkPermissions(this.props.user, 'dispatch', this.props.match.params.did)) {
            this.props.dispatch(loadPageData(this.props.match.params));
            this.props.dispatch(loadDispatchOrdersReport(this.props.match.params.did));
        } else {
            this.props.history.push('/permission-denied');
        }
    }

    toggleSearchModal(isOpen) {
        if (isOpen) {
            this.props.dispatch(searchFormReset());
            this.props.dispatch(openSearchReports());
        }
        else {
            this.props.dispatch(closeSearchReports());
        }
    }

    toggleViewOrder(order, isOpen) {
        if (isOpen) {
            this.setState(() => ({
                selectedOrder: order
            }));

            this.props.dispatch(openOrderDetails());
        }
        else {
            this.props.dispatch(closeOrderDetails());
        }
    }

    submitSearchOrders(searchData) {
        // configure the available search conditions
        if( (searchData.end_time != '' || searchData.start_time != '') && (searchData.end_time == '' || searchData.start_time == '') ){
            // both aren't supplied
            alert("Start time and End time must both be supplied if searching by date!");
        }else{
            this.displayedSearchData = searchData;
            this.props.dispatch(setOrdersSummarySearchLoading());
            this.props.dispatch(closeSearchReports());
            this.props.dispatch((loadDispatchOrdersReport(this.props.match.params.did, searchData)));
        }
    }

    renderOrders() {

        if (this.props.loading) {
            return (
                <div className="page-loading">
                    <Grid container>
                        <Grid item xs={12} className="restaurant-card-grid">
                        {this.props.generatingFile && (
                            <div className="form-image-container">
                                <Typography>Hang tight! We're preparing your file and it should be ready shortly!</Typography>    
                            </div>
                        )}
                        {this.props.performingSearch && (
                            <div className="form-image-container">
                                <Typography>Please wait while we perform your search, depending on the volume of orders this may take a few moments.</Typography>    
                            </div>
                        )}
                            <div className="form-image-container">
                                <CircularProgress size={100} />
                            </div>
                        </Grid>
                    </Grid>
                </div>
            );
        }
        else {
            return (
                <OrdersList
                    orders={this.props.orders}
                    toggleViewOrder={this.toggleViewOrder}
                    isDispatch={true}
                />
            );
        }
    }

    goBackPage() {
        if (this.props.currentPage > 1) {
            let searchForm = this.props.searchForm;
            searchForm.page = this.props.currentPage - 1;
            this.props.dispatch((loadDispatchOrdersReport(this.props.match.params.did, searchForm)));
        }
    }

    goForwardPage() {
        if (this.props.currentPage < this.props.totalPages) {
            let searchForm = this.props.searchForm;
            searchForm.page = this.props.currentPage + 1;
            this.props.dispatch((loadDispatchOrdersReport(this.props.match.params.did, searchForm)));
        }
    }

    exportCSV(){
        let searchForm = this.displayedSearchData;
        this.props.dispatch(setOrdersReportLoading());
        this.props.dispatch(exportDispatchReportCSV(this.props.match.params.did, searchForm));
    }

    exportPDF(){
        let summaryTitle = this.props.dispatchCenter.name;
        generatePDF(this.props.ordersCompleteList, this.props.summary, true, summaryTitle);
    }
    
    render() {

        let commasRegex = /\B(?=(\d{3})+(?!\d))/g;

        return (
            <LoadScreen
                checkPermissions={this.checkPermissions}
            >
                <div className="card-container">
                    <Grid container className="menu-page-header">
                        <Grid item xs={12} className="heading-grid">
                            <h1>Dispatched Orders Report - {this.props.dispatchCenter.name}</h1>
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid item xs={4} className="restaurant-card-grid">
                            <Card className="card restaurant-card">
                                <CardActions>
                                    <Button size="small" color="primary" onClick={() => { this.exportPDF() }}>
                                        Generate PDF
                                    </Button>
                                    <Button size="small" color="primary" onClick={() => { this.exportCSV() }}>
                                        Download CSV
                                    </Button>
                                </CardActions>
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        Order Summary
                                    </Typography>
                                    <div className="summary-line-item">
                                        <Typography>
                                            Start Time:
                                        </Typography>
                                        <Typography>
                                            {(this.props.summary.summaryStartFormatted) && this.props.summary.summaryStartFormatted}
                                        </Typography>
                                    </div>
                                    <div className="summary-line-item">
                                        <Typography>
                                            End Time:
                                        </Typography>
                                        <Typography>
                                            {(this.props.summary.summaryEndFormatted) && this.props.summary.summaryEndFormatted}
                                        </Typography>
                                    </div>
                                    <div className="summary-line-item">
                                        <Typography>
                                            Orders Created:
                                        </Typography>
                                        <Typography>
                                            {(this.props.summary.totalCreated) && this.props.summary.totalCreated}
                                        </Typography>
                                    </div>
                                    <div className="summary-line-item">
                                        <Typography>
                                            Orders Completed:
                                        </Typography>
                                        <Typography>
                                            {this.props.summary.totalCompleted}
                                        </Typography>
                                    </div>
                                    <div className="summary-line-item">
                                        <Typography>
                                            Orders Cancelled:
                                        </Typography>
                                        <Typography>
                                            {this.props.summary.totalCancelled}
                                        </Typography>
                                    </div>
                                    <div className="summary-line-item">
                                        <Typography>
                                            Paid Online:
                                        </Typography>
                                        <Typography>
                                            {this.props.summary.totalPaidOnline}
                                        </Typography>
                                    </div>
                                    <div className="summary-line-item">
                                        <Typography>
                                            Paid In-store:
                                        </Typography>
                                        <Typography>
                                            {this.props.summary.totalPaidInStore}
                                        </Typography>
                                    </div>
                                    <div className="summary-line-item">
                                        <Typography>
                                            Delivery Fees:
                                        </Typography>
                                        <Typography>
                                            ${(this.props.summary.totalDeliveryCharges) && this.props.summary.totalDeliveryCharges.toFixed(2).replace(commasRegex, ",")}
                                        </Typography>
                                    </div>
                                    {/* <div className="summary-line-item">
                                        <Typography>
                                            Fuel Surcharge (pre-tax):
                                        </Typography>
                                        <Typography>
                                            ${(this.props.summary.fuelSurcharge) && this.props.summary.fuelSurcharge.toFixed(2).replace(commasRegex, ",")}
                                        </Typography>
                                    </div> */}
                                    <div className="summary-line-item">
                                        <Typography>
                                            Driver Tips:
                                        </Typography>
                                        <Typography>
                                            ${(this.props.summary.totalDeliveryTip) && this.props.summary.totalDeliveryTip.toFixed(2).replace(commasRegex, ",")}
                                        </Typography>
                                    </div>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={8} className="restaurant-card-grid">
                            <Card className="card restaurant-card">
                                <CardActions>
                                    <Button size="small" color="primary" onClick={() => { this.goBackPage() }}>
                                        &lt;&lt;
                                    </Button>
                                    <Typography>
                                        Page {this.props.currentPage} of {(this.props.totalPages >= 1) ? this.props.totalPages : 1}
                                    </Typography>
                                    <Button size="small" color="primary" onClick={() => { this.goForwardPage() }}>
                                        &gt;&gt;
                                    </Button>
                                </CardActions>
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        Orders
                                    </Typography>
                                    {this.renderOrders()}
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </div>

                <div className="window-fab">
                    <Fab className="btn-fab btn-add" onClick={() => this.toggleSearchModal(true)}>
                        <SearchIcon />
                    </Fab>
                </div>

                <SearchOrders 
                    submitForm={this.submitSearchOrders}
                    searchFormReset={this.searchFormReset}
                />

                <OrderDetails
                    order={this.state.selectedOrder}
                    isDispatch={true}
                />
            </LoadScreen>
        );
    }
};

const mapStateToProps = (state, props) => {
    return {
        loading: state.reportOrders.loading,
        generatingFile: state.reportOrders.generatingFile,
        performingSearch: state.reportOrders.performingSearch,
        restaurant: state.selectedRestaurant.restaurant,
        searchForm: state.reportSearchForm,
        summary: state.reportOrders.orderSummary,
        orders: state.reportOrders.orders,
        dispatchCenter: state.reportOrders.dispatchCenter,
        ordersCompleteList: state.reportOrders.ordersCompleteList,
        currentPage: state.reportOrders.currentPage,
        totalPages: state.reportOrders.totalPages,
        user: state.loggedUser.user,
    };
};

export default connect(mapStateToProps)(DispatchReportsPage);