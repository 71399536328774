import React from 'react';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import EditIcon from '@material-ui/icons/Edit';
import HeightIcon from '@material-ui/icons/Height';
import DeleteIcon from '@material-ui/icons/Delete';

import SortableContainer from '../../../common/sort/SortableContainer';
import SortableHandle from '../../../common/sort/SortableHandle';
import SortableItem from '../../../common/sort/SortableItem';

class SortableSettingsList extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    render() {
        return (
            <div>
                {this.props.items.length <= 0 && (
                    <p>{this.props.emptyLabel}</p>
                )}

                <SortableContainer helperClass='sortableHelper' onSortEnd={this.props.onSortEnd} useDragHandle>
                    {this.props.items.map((item, index) => (
                        <SortableItem key={"settingssize-"+index} index={index}>
                            <Grid key={"item-"+index} container>
                                <Grid item xs={1} className="list-sort">
                                    <SortableHandle />
                                </Grid>
                                <Grid item xs={9} className="form-grid">
                                    <p>{item.name}</p>
                                </Grid>
                                <Grid item xs={1} className="form-grid">
                                    <Button variant="contained" fullWidth={true} color="primary" className="modal-button-list" onClick={() => this.props.handleUpdateView(item, index)}>
                                        <EditIcon className="modal-button-icon" />
                                    </Button>
                                </Grid>
                                <Grid item xs={1} className="form-grid">
                                    <Button variant="contained" fullWidth={true} color="primary" className="modal-button-list" onClick={() => this.props.handleRemoveItem(index)}>
                                        <DeleteIcon className="modal-button-icon" />
                                    </Button>
                                </Grid>
                            </Grid>
                        </SortableItem>
                    ))}
                </SortableContainer>
                <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom" onClick={this.props.handleAddView}>
                    {this.props.addLabel}
                </Button>
            </div>
        );
    }
};

export default connect()(SortableSettingsList);