const choiceFormDefaultState = {
    name: '',
    description: '',
    is_multiple: false,
    max_choices: '',
    selections: [{
        name: '',
        price: '',
        item_order: 0
    }],
    deletedSelections: [],
    error: '',
};

export default (state = choiceFormDefaultState, action) => {
    
    switch (action.type) {

        case 'CHOICE_FORM_UPDATE':
            return {
                ...state,
                [action.foodItem.prop]: action.foodItem.value
            };

        case 'CHOICE_FORM_UPDATE_ITEM':
            return {
                ...state,
                selections: state.selections.map((item, index) => {
                    if (index == action.item.index) {
                        return {
                            ...item,
                            [action.item.prop]: action.item.value
                        }
                    }

                    return item;
                })
            };

        case 'CHOICE_FORM_ADD_ITEM':
            return {
                ...state,
                selections: state.selections.concat(action.item)
            };

        case 'CHOICE_FORM_REMOVE_ITEM':
            const deletedSelections = (state.selections[action.index].id) ? state.deletedSelections.concat(state.selections[action.index].id) : state.deletedSelections;

            return {
                ...state,
                deletedSelections,
                selections: state.selections.filter((item, index) => {
                    return index != action.index;
                })
            };

        case 'CHOICE_FORM_ERROR':
            return {
                ...state,
                message: '',
                error: action.message
            }

        case 'CHOICE_FORM_SUCCESS':
            return {
                ...state,
                error: '',
                message: action.message
            }

        case 'CHOICE_FORM_SET':
            return {
                ...state,
                ...action.choice
            };

        case 'CHOICE_FORM_RESET':
            return choiceFormDefaultState;

        default:
            return state;
    }
};