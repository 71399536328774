const registerManagerFormDefaultState = {
    emailAddress: '',
    password: '',
    password2: '',
    message: '',
    firstName: '',
    lastName: '',
    loading: false,
    error: '',
    criticalError: false,
};

export default (state = registerManagerFormDefaultState, action) => {
    switch (action.type) {
        case 'REGISTER_MANAGER_FORM_UPDATE':
            return {
                ...state,
                [action.field.prop]: action.field.value
            };

        case 'REGISTER_MANAGER_FORM_ERROR':
            return {
                ...state,
                message: '',
                error: action.message
            }

        case 'REGISTER_MANAGER_FORM_RESET':
            return registerManagerFormDefaultState;

        default:
            return state;
    }
};