export const openSearchReports = () => {

    return {
        type: 'OPEN_SEARCH_REPORTS_MODAL',
    };
};

export const closeSearchReports = () => {

    return {
        type: 'CLOSE_SEARCH_REPORTS_MODAL',
    };
};

export const openRestaurantCreate = () => {

    return {
        type: 'OPEN_RESTAURANT_CREATE_MODAL',
    };
};

export const closeRestaurantCreate = () => {

    return {
        type: 'CLOSE_RESTAURANT_CREATE_MODAL',
    };
};

export const openLoyaltyRewardCreate = () => {

    return {
        type: 'OPEN_LOYALTY_REWARD_CREATE_MODAL',
    };
};

export const closeLoyaltyRewardCreate = () => {

    return {
        type: 'CLOSE_LOYALTY_REWARD_CREATE_MODAL',
    };
};

export const openLoyaltyRewardUpdate = () => {

    return {
        type: 'OPEN_LOYALTY_REWARD_UPDATE_MODAL',
    };
};

export const closeLoyaltyRewardUpdate = () => {

    return {
        type: 'CLOSE_LOYALTY_REWARD_UPDATE_MODAL',
    };
};

export const openLoyaltyPointsAdd = () => {

    return {
        type: 'OPEN_LOYALTY_POINTS_ADD_MODAL',
    };
};

export const closeLoyaltyPointsAdd = () => {

    return {
        type: 'CLOSE_LOYALTY_POINTS_ADD_MODAL',
    };
};

export const openLoyaltyPointsUpdate = () => {

    return {
        type: 'OPEN_LOYALTY_POINTS_UPDATE_MODAL',
    };
};

export const closeLoyaltyPointsUpdate = () => {

    return {
        type: 'CLOSE_LOYALTY_POINTS_UPDATE_MODAL',
    };
};

export const openLocationCreate = () => {

    return {
        type: 'OPEN_LOCATION_CREATE_MODAL',
    };
};

export const closeLocationCreate = () => {

    return {
        type: 'CLOSE_LOCATION_CREATE_MODAL',
    };
};

export const openLocationUpdate = () => {

    return {
        type: 'OPEN_LOCATION_UPDATE_MODAL',
    };
};

export const closeLocationUpdate = () => {

    return {
        type: 'CLOSE_LOCATION_UPDATE_MODAL',
    };
};

export const openManagerAdd = () => {

    return {
        type: 'OPEN_MANAGER_ADD_MODAL',
    };
};

export const closeManagerAdd = () => {

    return {
        type: 'CLOSE_MANAGER_ADD_MODAL',
    };
};

export const openAddonCreate = () => {

    return {
        type: 'OPEN_ADDON_CREATE_MODAL',
    };
};

export const closeAddonCreate = () => {

    return {
        type: 'CLOSE_ADDON_CREATE_MODAL',
    };
};

export const openAddonUpdate = () => {

    return {
        type: 'OPEN_ADDON_UPDATE_MODAL',
    };
};

export const closeAddonUpdate = () => {

    return {
        type: 'CLOSE_ADDON_UPDATE_MODAL',
    };
};

export const openModifierModal = () => {
    return {
        type: 'OPEN_MODIFIER_MODAL',
    };
};

export const closeModifierModal = () => {
    return {
        type: 'CLOSE_MODIFIER_MODAL',
    };
};

export const openSpecialCreate = () => {

    return {
        type: 'OPEN_SPECIAL_CREATE_MODAL',
    };
};

export const closeSpecialCreate = () => {

    return {
        type: 'CLOSE_SPECIAL_CREATE_MODAL',
    };
};

export const openSpecialUpdate = () => {

    return {
        type: 'OPEN_SPECIAL_UPDATE_MODAL',
    };
};

export const closeSpecialUpdate = () => {

    return {
        type: 'CLOSE_SPECIAL_UPDATE_MODAL',
    };
};

export const openPizzaCreate = () => {

    return {
        type: 'OPEN_PIZZA_CREATE_MODAL',
    };
};

export const closePizzaCreate = () => {

    return {
        type: 'CLOSE_PIZZA_CREATE_MODAL',
    };
};

export const openPizzaUpdate = () => {

    return {
        type: 'OPEN_PIZZA_UPDATE_MODAL',
    };
};

export const closePizzaUpdate = () => {

    return {
        type: 'CLOSE_PIZZA_UPDATE_MODAL',
    };
};

export const openPizzaSettingsConfig = () => {

    return {
        type: 'OPEN_PIZZA_SETTINGS_CONFIG_MODAL',
    };
};

export const closePizzaSettingsConfig = () => {

    return {
        type: 'CLOSE_PIZZA_SETTINGS_CONFIG_MODAL',
    };
};

export const openPizzaSettingsUpdate = () => {

    return {
        type: 'OPEN_PIZZA_SETTINGS_UPDATE_MODAL',
    };
};

export const closePizzaSettingsUpdate = () => {

    return {
        type: 'CLOSE_PIZZA_SETTINGS_UPDATE_MODAL',
    };
};

export const openCustomPizzasUpdate = () => {

    return {
        type: 'OPEN_CUSTOM_PIZZAS_UPDATE_MODAL',
    };
};

export const closeCustomPizzasUpdate = () => {

    return {
        type: 'CLOSE_CUSTOM_PIZZAS_UPDATE_MODAL',
    };
};

export const openCategoryCreate = () => {

    return {
        type: 'OPEN_CATEGORY_CREATE_MODAL',
    };
};

export const closeCategoryCreate = () => {

    return {
        type: 'CLOSE_CATEGORY_CREATE_MODAL',
    };
};

export const openUserCreate = () => {

    return {
        type: 'OPEN_USER_CREATE_MODAL',
    };
};

export const closeUserCreate = () => {

    return {
        type: 'CLOSE_USER_CREATE_MODAL',
    };
};

export const openCategoryUpdate = () => {

    return {
        type: 'OPEN_CATEGORY_UPDATE_MODAL',
    };
};

export const closeCategoryUpdate = () => {

    return {
        type: 'CLOSE_CATEGORY_UPDATE_MODAL',
    };
};

export const openFoodItemCreate = () => {

    return {
        type: 'OPEN_FOOD_ITEM_CREATE_MODAL',
    };
};

export const closeFoodItemCreate = () => {

    return {
        type: 'CLOSE_FOOD_ITEM_CREATE_MODAL',
    };
};

export const openFoodItemUpdate = () => {

    return {
        type: 'OPEN_FOOD_ITEM_UPDATE_MODAL',
    };
};

export const closeFoodItemUpdate = () => {

    return {
        type: 'CLOSE_FOOD_ITEM_UPDATE_MODAL',
    };
};

export const openAppDirectoryCreate = () => {

    return {
        type: 'OPEN_APP_DIRECTORY_CREATE_MODAL',
    };
};

export const closeAppDirectoryCreate = () => {

    return {
        type: 'CLOSE_APP_DIRECTORY_CREATE_MODAL',
    };
};

export const openAppDirectoryUpdate = () => {

    return {
        type: 'OPEN_APP_DIRECTORY_UPDATE_MODAL',
    };
};

export const closeAppDirectoryUpdate = () => {

    return {
        type: 'CLOSE_APP_DIRECTORY_UPDATE_MODAL',
    };
};

export const openDispatchCenterCreate = () => {

    return {
        type: 'OPEN_DISPATCH_CENTER_CREATE_MODAL',
    };
};


export const closeDispatchCenterCreate = () => {

    return {
        type: 'CLOSE_DISPATCH_CENTER_CREATE_MODAL',
    };
};

export const openPromoCodeCreate = () => {

    return {
        type: 'OPEN_PROMO_CODE_CREATE_MODAL',
    };
};

export const openMassPromoCodeCreate = () => {

    return {
        type: 'OPEN_MASS_PROMO_CODE_CREATE_MODAL',
    };
};

export const openMassPromoCodeUpdate = () => {

    return {
        type: 'OPEN_MASS_PROMO_CODE_UPDATE_MODAL',
    };
};

export const closePromoCodeCreate = () => {

    return {
        type: 'CLOSE_PROMO_CODE_CREATE_MODAL',
    };
};

export const closeMassPromoCodeCreate = () => {

    return {
        type: 'CLOSE_MASS_PROMO_CODE_CREATE_MODAL',
    };
};

export const closeMassPromoCodeUpdate = () => {

    return {
        type: 'CLOSE_MASS_PROMO_CODE_UPDATE_MODAL',
    };
};

export const openPromoCodeUpdate = () => {

    return {
        type: 'OPEN_PROMO_CODE_UPDATE_MODAL',
    };
};

export const closeDispatchCenterUpdate = () => {

    return {
        type: 'CLOSE_DISPATCH_CENTER_UPDATE_MODAL',
    };
};

export const openDispatchCenterUpdate = () => {

    return {
        type: 'OPEN_DISPATCH_CENTER_UPDATE_MODAL',
    };
};

export const openUserManagementUpdate = () => {

    return {
        type: 'OPEN_USER_MANAGEMENT_UPDATE_MODAL',
    };
};

export const closeUserManagementUpdate = () => {

    return {
        type: 'CLOSE_USER_MANAGEMENT_UPDATE_MODAL',
    };
};

export const openConfirmOrder = () => {

    return {
        type: 'OPEN_CONFIRM_ORDER_MODAL',
    };
};

export const openCompleteOrder = () => {

    return {
        type: 'OPEN_COMPLETE_ORDER_MODAL',
    };
};

export const closeConfirmOrder = () => {

    return {
        type: 'CLOSE_CONFIRM_ORDER_MODAL',
    };
};

export const closeCompleteOrder = () => {

    return {
        type: 'CLOSE_COMPLETE_ORDER_MODAL',
    };
};

export const openOrderDetails = () => {

    return {
        type: 'OPEN_ORDER_DETAILS',
    };
}

export const closeOrderDetails = () => {

    return {
        type: 'CLOSE_ORDER_DETAILS',
    };
}

export const resetModals = () => {

    return {
        type: 'RESET_MODALS',
    };
};