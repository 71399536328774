import React from 'react';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import arrayMove from 'array-move';

import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';

import LoadScreen from '../../common/LoadScreen';
import verifyLogin from '../../../utils/verifyLogin';
import { loadSpecials, setSpecialFilter, toggleSpecialEnabled, updateSpecialOrder } from '../../../actions/menu/specials/specials';
import { specialFormUpdate, specialFormReset } from '../../../actions/menu/specials/specialForm';
import { 
    openSpecialCreate, 
    closeSpecialCreate, 
    openSpecialUpdate, 
    closeSpecialUpdate
} from '../../../actions/modals';
import { loadPageData } from '../../../actions/auth';
import filterSpecials from '../../../utils/filterListName';

import CreateSpecial from './modal/CreateSpecial';
import UpdateSpecial from './modal/UpdateSpecial';

import SortableMenuList from '../common/SortableMenuList';

import checkPermissions from '../../../utils/checkUserPermissions';

class SpecialsPage extends React.Component {

    constructor(props) {
        super(props);

        this.toggleCreateModal = this.toggleCreateModal.bind(this);
        this.toggleUpdateModal = this.toggleUpdateModal.bind(this);
        this.toggleSpecialEnabled = this.toggleSpecialEnabled.bind(this);
        this.checkPermissions = this.checkPermissions.bind(this);
        this.onSortEnd = this.onSortEnd.bind(this);

        this.state = {
            sidebarMenuOpen: false,
            selectedSpecial: {}
        };
    }

    componentDidMount() {
        verifyLogin(this.props.history);
    }

    componentDidUpdate() {
        verifyLogin(this.props.history);
    }

    checkPermissions() {
        if (checkPermissions(this.props.user, 'restaurant_manager', this.props.match.params.id) ||
            checkPermissions(this.props.user, 'support')) {
            // load the restaurant
            this.props.dispatch(loadPageData(this.props.match.params));
            this.props.dispatch(loadSpecials(this.props.match.params.id));
        } else {
            this.props.history.push('/permission-denied');
        }  
    }

    toggleUpdateModal(isOpen, selectedSpecial) {
        if (isOpen) {

            let scrollPosition = document.documentElement.scrollTop || document.body.scrollTop;

            this.setState(() => ({
                selectedSpecial
            }));
            this.props.dispatch(specialFormReset());
            this.props.dispatch(specialFormUpdate({ prop: 'name', value: selectedSpecial.name }));
            this.props.dispatch(specialFormUpdate({ prop: 'description', value: selectedSpecial.description }));
            this.props.dispatch(specialFormUpdate({ prop: 'thumb_image', value: selectedSpecial.thumb_image }));
            this.props.dispatch(specialFormUpdate({ prop: 'non_taxable', value: selectedSpecial.non_taxable }));
            this.props.dispatch(specialFormUpdate({ prop: 'price', value: selectedSpecial.price }));
            this.props.dispatch(specialFormUpdate({ prop: 'items', value: selectedSpecial.items }));
            this.props.dispatch(specialFormUpdate({ prop: 'scrollPosition', value: scrollPosition }));
            if(selectedSpecial.hours_available != null){
                this.props.dispatch(specialFormUpdate({ prop: 'hours_available', value: selectedSpecial.hours_available }));
            }
            this.props.dispatch(specialFormUpdate({ prop: 'alwaysAvailable', value: selectedSpecial.alwaysAvailable }));
            this.props.dispatch(openSpecialUpdate());
        }
        else {
            this.props.dispatch(closeSpecialUpdate());
        }
    }

    toggleCreateModal(isOpen) {
        if (isOpen) {
            this.props.dispatch(specialFormReset());
            this.props.dispatch(specialFormUpdate({ prop: 'activeTab', value: 0 }));
            this.props.dispatch(openSpecialCreate());
        }
        else {
            this.props.dispatch(closeSpecialCreate());
        }
    }

    onSortEnd({oldIndex, newIndex})  {

        let newOrder = arrayMove(this.props.specials, oldIndex, newIndex);
        for (let i = 0; i < newOrder.length; i++) {
            newOrder[i]['special_order'] = i + 1;
        }

        this.props.dispatch(updateSpecialOrder(this.props.match.params.id, newOrder));
    }

    toggleSpecialEnabled(special, enabled) {
        let confirmStr = "Are you sure you want to disable this special?";
        if (enabled) {
            confirmStr = "Enable this special?";
        }

        if (confirm(confirmStr)) {
            this.props.dispatch(toggleSpecialEnabled(this.props.match.params.id, special.id, enabled));
        };
    }

    renderSpecials() {

        if (this.props.loading) {
            return (
                <div className="page-loading">
                    <CircularProgress size={100} />
                </div>
            );
        }
        else {
            if (this.props.specials.length <= 0) {
                return (
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography className="no-results">No specials entered.</Typography>
                        </Grid>
                    </Grid>
                );
            }
            else {
                return (
                    <SortableMenuList
                        items={this.props.specials}
                        toggleEnabled={this.toggleSpecialEnabled}
                        toggleUpdateModal={this.toggleUpdateModal}
                        onSortEnd={this.onSortEnd}
                        showImage={true}
                        isCategory={false}
                    />
                );
            }
        }
    }

    renderSpecialsPage() {
        return (
            <div>
                <div className="card-container">
                    <Grid container className="menu-page-header">
                        <Grid item xs={12} className="heading-grid">
                            <h1>Specials</h1>
                            <FormControl fullWidth>
                                <TextField 
                                    label="Filter Specials" 
                                    variant="outlined" 
                                    value={this.props.filter}
                                    onChange={(e) => this.props.dispatch(setSpecialFilter(e.target.value))} 
                                />
                            </FormControl>
                        </Grid>
                    </Grid>

                    {this.renderSpecials()}
                </div>

                <div className="window-fab">
                    <Fab className="btn-fab btn-add" onClick={() => this.toggleCreateModal(true)}>
                        <AddIcon />
                    </Fab>
                </div>

                <CreateSpecial
                    restaurantId={this.props.match.params.id}
                />

                <UpdateSpecial 
                    special={this.state.selectedSpecial}
                    restaurantId={this.props.match.params.id}
                />
            </div>
        )
    }
    
    render() {
        return (
            <LoadScreen
                checkPermissions={this.checkPermissions}
            >
                {this.renderSpecialsPage()}                
            </LoadScreen>
        );
    }
};

const mapStateToProps = (state, props) => {
    return {
        loading: state.specials.loading || state.restaurants.loading,
        restaurant: state.selectedRestaurant.restaurant,
        specials: filterSpecials(state.specials.specials, {text: state.specials.filter}),
        specialForm: state.specialForm,
        user: state.loggedUser.user,
    };
};

export default connect(mapStateToProps)(SpecialsPage);