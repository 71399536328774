export const sauceFormUpdate = ({ prop, value }) => {
    return {
        type: 'SAUCE_FORM_UPDATE',
        sauce: { prop, value }
    };
};

export const sauceFormUpdatePrice = ({ prop, index, value }) => {

    return {
        type: 'SAUCE_FORM_UPDATE_PRICE',
        item: { prop, index, value }
    };
};

export const sauceFormResetPrices = (index) => {

    return {
        type: 'SAUCE_FORM_RESET_PRICES'
    }
}

export const sauceFormError = (message) => {

    return {
        type: 'SAUCE_FORM_ERROR',
        message
    };
};

export const sauceFormSuccess = (message) => {

    return {
        type: 'SAUCE_FORM_SUCCESS',
        message
    };
};

export const sauceFormSet = (sauce, index) => {
    
    return {
        type: 'SAUCE_FORM_SET',
        sauce: {
            ...sauce,
            index
        }
    };
}

export const sauceFormReset = () => {
    
    return {
        type: 'SAUCE_FORM_RESET',
    };
}

export const populateNewSauceFormSizes = (sizes) => {

    return (dispatch) => {

        let prices = [];
        for(let i = 0; i < sizes.length; i++) {
            prices.push({
                size_id: sizes[i].id,
                size_name: sizes[i].size_name,
                price: 0.00
            });
        }

        dispatch(sauceFormUpdate({
            prop: 'prices',
            value: prices
        }));
    };
}

export const populateUpdateSauceFormSizes = (sauce) => {

    return (dispatch) => {

        let prices = [];
        for(let i = 0; i < sauce.prices.length; i++) {
            prices.push({
                id: sauce.prices[i].id,
                size_id: sauce.prices[i].size_id,
                size_name: sauce.prices[i].size_name,
                price: sauce.prices[i].price
            });
        }

        dispatch(sauceFormUpdate({
            prop: 'prices',
            value: prices
        }));
    };
}