import React from 'react';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';

import validateCurrency from '../../../../utils/validateCurrency';
import { addAddon } from '../../../../actions/menu/addons/addons';
import { addonFormError, addonFormReset } from '../../../../actions/menu/addons/addonForm';
import { openAddonCreate, closeAddonCreate } from '../../../../actions/modals';

import CloseIcon from '../../../common/modal/CloseIcon';

import Info from './Info';

class CreateAddon extends React.Component {

    constructor(props) {
        super(props);

        this.toggleCreateModal = this.toggleCreateModal.bind(this);
        this.submitCreateAddon = this.submitCreateAddon.bind(this);
    }

    toggleCreateModal(isOpen) {
        if (isOpen) {
            this.props.dispatch(addonFormReset());
            this.props.dispatch(openAddonCreate());
        }
        else {
            this.props.dispatch(closeAddonCreate());
        }
    }

    submitCreateAddon() {

        // perform some error checking
        if (
            this.props.addonForm.name.trim() == "" || 
            !validateCurrency(this.props.addonForm.price) || 
            this.props.addonForm.price <= 0) {

            // required info isn't present
            this.props.dispatch(addonFormError("Please enter a addon name and valid price greater than 0."));
        }
        else if (!this.props.addonForm.usePizzas && !this.props.addonForm.useFood && !this.props.addonForm.useSpecials) {
            this.props.dispatch(addonFormError("You must enable your addon for at least one food type."));
        }
        else {

            this.props.dispatch(addonFormError(""));
            this.props.dispatch(addAddon(this.props.restaurantId, this.props.addonForm));
        }
    }

    render() {
        return (
            <Modal
                aria-labelledby="addon-add"
                aria-describedby="addon-add"
                open={this.props.createModalOpen}
                onClose={() => this.toggleCreateModal(false)}
                className="modal_admin"
                disableBackdropClick
            >
                <div className="modal-member">
                    <Typography variant="h4" id="modal-title">
                        Add a New Item Addon
                        <CloseIcon/>
                    </Typography>

                    {this.props.loading && (
                        <div className="page-loading">
                            <CircularProgress size={100} />
                        </div>
                    )}
        
                    {!this.props.loading && (
                        <div>
                            { this.props.addonForm.error && (
                                <Typography className="modal-error">
                                    { this.props.addonForm.error }
                                </Typography>
                            )}

                            <Info />

                            <Button variant="contained" fullWidth={true} className="modal-button-bottom" onClick={this.submitCreateAddon}>
                                Add Addon
                            </Button>
                        </div>
                    )}
                </div>
            </Modal>
        );
    }
};

const mapStateToProps = (state, props) => {
    return {
        loading: state.addons.loading,
        restaurant: state.selectedRestaurant.restaurant,
        addonForm: state.addonForm,
        createModalOpen: state.modals.addonCreate
    };
};

export default connect(mapStateToProps)(CreateAddon);