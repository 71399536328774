// the default items that we can possibly filter by
const pizzasDefaultState = {
    loading: true,
    pizzas: [],
    settings: {},
    showConfig: false,
    filter: ''
};

export default (state = pizzasDefaultState, action) => {
    switch (action.type) {
        case 'SET_PIZZAS':
            return {
                ...state,
                loading: false,
                pizzas: action.pizzas,
                filter: ''
            };

        case 'SET_PIZZA_SETTINGS':
            return {
                ...state,
                loading: false,
                settings: action.settings,
                filter: ''
            };

        case 'SET_PIZZA_FILTER':
            return {
                ...state,
                filter: action.text
            };

        case 'SET_PIZZAS_SHOW_CONFIG':
            return {
                ...state,
                showConfig: action.showConfig
            };
    

        case 'SET_PIZZAS_LOADING':
            return {
                ...state,
                loading: true
            };
            
        case 'RESET_PIZZAS':
            return pizzasDefaultState;

        default:
            return state;
    }
};