export const foodItemFormUpdate = ({ prop, value }) => {

    return {
        type: 'FOOD_ITEM_FORM_UPDATE',
        foodItem: { prop, value }
    };
};

export const foodItemFormUpdateHoursAvailable = ({ prop, value }) => {
    return {
        type: 'FOOD_ITEM_FORM_UPDATE_HOURS_AVAILABLE',
        location: { prop, value }
    };
};

export const foodItemFormUpdatePrice = ({ prop, index, value }) => {

    return {
        type: 'FOOD_ITEM_FORM_UPDATE_PRICE',
        price: { prop, index, value }
    };
};

export const foodItemFormAddPrice = (price) => {

    return {
        type: 'FOOD_ITEM_FORM_ADD_PRICE',
        price
    }
};

export const foodItemFormRemovePrice = (index) => {

    return {
        type: 'FOOD_ITEM_FORM_REMOVE_PRICE',
        index
    }
}

export const foodItemFormAddChoice = (choice) => {

    return {
        type: 'FOOD_ITEM_FORM_ADD_CHOICE',
        choice
    }
};

export const foodItemFormUpdateChoice = (choice, index) => {

    return {
        type: 'FOOD_ITEM_FORM_UPDATE_CHOICE',
        choice,
        index
    }
};

export const foodItemFormRemoveChoice = (index) => {

    return {
        type: 'FOOD_ITEM_FORM_REMOVE_CHOICE',
        index
    }
}

export const foodItemFormUpdateAddon = ({ prop, index, value }) => {

    return {
        type: 'FOOD_ITEM_FORM_UPDATE_ADDON',
        addon: { prop, index, value }
    };
};

export const foodItemFormAddAddon = (addon) => {

    return {
        type: 'FOOD_ITEM_FORM_ADD_ADDON',
        addon
    }
};

export const foodItemFormRemoveAddon = (index) => {

    return {
        type: 'FOOD_ITEM_FORM_REMOVE_ADDON',
        index
    }
}

export const foodItemFormAddModifier = (modifier) => {
    return {
        type: 'FOOD_ITEM_FORM_ADD_MODIFIER',
        modifier
    }
};

export const foodItemFormUpdateModifier = (modifier, index) => {
    return {
        type: 'FOOD_ITEM_FORM_UPDATE_MODIFIER',
        modifier,
        index
    }
};

export const foodItemFormRemoveModifier = (index) => {
    return {
        type: 'FOOD_ITEM_FORM_REMOVE_MODIFIER',
        index
    }
};

export const foodItemFormError = (message) => {

    return {
        type: 'FOOD_ITEM_FORM_ERROR',
        message
    };
};

export const foodItemFormSuccess = (message) => {

    return {
        type: 'FOOD_ITEM_FORM_SUCCESS',
        message
    };
};

export const foodItemFormReset = () => {
    
    return {
        type: 'FOOD_ITEM_FORM_RESET',
    };
}