import React from 'react';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import HeightIcon from '@material-ui/icons/Height';
import DeleteIcon from '@material-ui/icons/Delete';
import Typography from '@material-ui/core/Typography';

import { updateDispatchCenter } from '../../../actions/superuser/dispatchCenters/centers';
import { dispatchCenterFormError } from '../../../actions/superuser/dispatchCenters/dispatchCenterForm';
import validateEmail from '../../../utils/validateEmail';

class DispatchCenterUsers extends React.Component {

    constructor(props) {
        super(props);

        this.addDispatchCenterUser = this.addDispatchCenterUser.bind(this);
        this.deleteDispatchCenterUser = this.deleteDispatchCenterUser.bind(this);
        this.updateAddUserEmail = this.updateAddUserEmail.bind(this);

        this.state = {
            addUserEmail: ''
        };
    }

    addDispatchCenterUser(userEmail) {

        if (validateEmail(userEmail)) {
            this.props.dispatch(updateDispatchCenter(this.props.dispatchCenter.id, 'add_user', {email: userEmail}));
        }
        else {
            this.props.dispatch(dispatchCenterFormError('Invalid email supplied.'));  
        }
    }

    deleteDispatchCenterUser(user) {

        this.props.dispatch(updateDispatchCenter(this.props.dispatchCenter.id, 'delete_user', {user_id: user.user.id}));
    }

    updateAddUserEmail(e) {
        const newEmail = e.target.value;
        this.setState(() => ({ addUserEmail: newEmail }));
    }

    renderUsersList() {
        if (this.props.users.length <= 0) {
            return (
                <Grid container>
                    <Grid item xs={12}>
                        <Typography className="no-results">No managers entered.</Typography>
                    </Grid>
                </Grid>
            );
        } else {
            return this.props.users.map((user, index) => (
                <Grid key={"user-"+index} container>
                    <Grid item xs={1} className="form-grid">
                        <Button 
                            variant="contained" 
                            fullWidth={true} 
                            color="primary" 
                            className="modal-button-list" 
                            onClick={this.submitCreateFoodItem}
                        >
                            <HeightIcon className="modal-button-icon" />
                        </Button>
                    </Grid>
                    <Grid item xs={10} className="form-grid">
                        <p>{user.user.email}</p>
                    </Grid>
                    <Grid item xs={1} className="form-grid">
                        <Button variant="contained" fullWidth={true} color="primary" className="modal-button-list" onClick={() => this.deleteDispatchCenterUser(user)}>
                            <DeleteIcon className="modal-button-icon" />
                        </Button>
                    </Grid>
                </Grid>
            ));
        }
    }
    
    render() {
        return (
            <Grid container>
                <Grid item xs={12} className="form-grid">

                    <TextField
                        label="New Admin Email"
                        className="modal-input"
                        fullWidth={true}
                        value={this.state.addUserEmail}
                        onChange={(e) => { this.updateAddUserEmail(e) }}
                        margin="normal"
                    />
                </Grid>

                <Grid item xs={12} className="form-grid">
                    <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom" onClick={() => { this.addDispatchCenterUser(this.state.addUserEmail) }}>
                        Add Admin
                    </Button>
                </Grid> 

                {this.renderUsersList()}
            </Grid>      
        );
    }
};

const mapStateToProps = (state, props) => {
    return {
        dispatchCenter: state.dispatchCenters.selectedDispatchCenter,
        users: state.dispatchCenters.selectedDispatchCenter.users,
        dispatchCenterForm: state.dispatchCenterForm
    };
};

export default connect(mapStateToProps)(DispatchCenterUsers);