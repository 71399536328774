import axios from 'axios';
import { API_URL } from '../../config/constants';
import { readErrMsg } from '../helpers/readErrMsg';

export const promoCodeAdminFormUpdate = ({ prop, value }) => {

    return {
        type: 'PROMO_CODE_ADMIN_FORM_UPDATE',
        promoCode: { prop, value }
    };
};

export const promoCodeAdminUpdateValidPromoDays = ({ prop, value }) => {

    return {
        type: 'PROMO_CODE_FORM_UPDATE_VALID_DAYS',
        promoCode: { prop, value }
    };
};

export const setSelectedAdminPromoCode = (promoCode) => {
    return {
        type: 'SET_SELECTED_ADMIN_PROMO_CODE',
        promoCode
    };
}

export const promoCodeAdminFormError = (message) => {

    return {
        type: 'PROMO_CODE_ADMIN_FORM_ERROR',
        message
    };
};

export const promoCodeAdminFormSuccess = (message) => {

    return {
        type: 'PROMO_CODE_ADMIN_FORM_SUCCESS',
        message
    };
};

export const promoCodeAdminFormReset = () => {
    
    return {
        type: 'PROMO_CODE_ADMIN_FORM_RESET',
    };
}

export const setPromoCodeAdminLoading = () => {
    
    return {
        type: 'PROMO_CODE_ADMIN_FORM_LOADING',
    };
}

export const setPromoCodeAdminEditing = () => {
    
    return {
        type: 'PROMO_CODE_ADMIN_FORM_EDITING',
    };
}

export const setPromoCodeAdminDoneLoading = () => {
    
    return {
        type: 'PROMO_CODE_ADMIN_FORM_DONE_LOADING',
    };
}

export const setPromos = (promos) => {
    return {
        type: 'SET_PROMOS',
        promos
    }
}

export const setPromoArchives = (archive_promos) => {
    return {
        type: 'SET_PROMO_ARCHIVES',
        archive_promos
    }
}

export const setPromosFilter = (promos_filter) => {
    return {
        type: 'SET_PROMOS_FILTER',
        promos_filter
    }
}

export const setLocations = (locations) => {
    return {
        type: 'SET_PROMO_ADMIN_LOCATIONS',
        locations
    }
}

export const setDispatchCenters = (dispatchCenters) => {
    return {
        type: 'SET_PROMO_ADMIN_DISPATCH_CENTERS',
        dispatchCenters
    }
}

export const resetPromoLocations = () => {
    return {
        type: 'RESET_PROMO_CODE_ADMIN_LOCATIONS'
    }
}

export const addUpdatePromoCode = (formData, selectedLocations, closeForm, scope) => {

    return (dispatch) => {

        dispatch(setPromoCodeAdminLoading());
        let code_id = (formData && formData.selectedPromoCode && formData.selectedPromoCode.id) ? formData.selectedPromoCode.id: null;

        return axios.post(
            API_URL+'/api/support/manage/promocode', 
            {
                code: formData.code,
                code_id,
                total_avail: formData.total_avail,
                total_target: formData.total_target,
                total_type: formData.total_type,
                threshold: formData.threshold,
                threshold_limit: formData.threshold_limit,
                valid_days: formData.valid_days,
                type: formData.type,
                total: formData.total,
                start_time: formData.start_time,
                end_time: formData.end_time,
                max_uses: formData.max_uses,
                locations: selectedLocations,
                scope
            }
        ).then(function(res) {
            dispatch(promoCodeAdminFormReset());
            dispatch(resetPromoLocations());
            dispatch(setPromoCodeAdminDoneLoading());
            dispatch(promoCodeAdminFormError(''));
            dispatch(closeForm());
            dispatch(loadPromoCodes());
        })
        .catch(function(err) {
            dispatch(setPromoCodeAdminDoneLoading());

            let response = readErrMsg(err);
            if (response.statusCode == 500) {
                dispatch(promoCodeAdminFormError('An unexpected error was encountered'));
            }
            else {
                dispatch(promoCodeAdminFormError(response.msg));
            }
            
        });
    };
};

export const removeAdminPromoCode = (code_id) => {

    return (dispatch) => {

        dispatch(setPromoCodeAdminLoading());

        return axios.post(
            API_URL+'/api/support/manage/removepromocode', 
            {
                code_id: code_id,
            }
        ).then(function(res) {
            dispatch(setPromoCodeAdminDoneLoading());
            dispatch(loadPromoCodes());
        })
        .catch(function(err) {
            dispatch(setPromoCodeAdminDoneLoading());

            let response = readErrMsg(err);
            if (response.statusCode == 500) {
                dispatch(promoCodeAdminFormError('An unexpected error was encountered'));
            }
            else {
                dispatch(promoCodeAdminFormError(response.msg));
            }
            
        });
    };
};

export const readdAdminPromoCode = (code_id) => {

    return (dispatch) => {

        dispatch(setPromoCodeAdminLoading());

        return axios.post(
            API_URL+'/api/support/manage/readdpromocode', 
            {
                code_id: code_id,
            }
        ).then(function(res) {
            dispatch(setPromoCodeAdminDoneLoading());
            dispatch(loadPromoCodes());
        })
        .catch(function(err) {
            dispatch(setPromoCodeAdminDoneLoading());

            let response = readErrMsg(err);
            if (response.statusCode == 500) {
                dispatch(promoCodeAdminFormError('An unexpected error was encountered'));
            }
            else {
                dispatch(promoCodeAdminFormError(response.msg));
            }
            
        });
    };
};

export const loadPromoCodes = () => {
    return (dispatch) => {

        dispatch(setPromoCodeAdminLoading());

        return axios.get(API_URL+'/api/support/promos').then(function(res) {

                let promos = res.data.data.promos;
                let archive_promos = res.data.data.archive_promos;
                
                let locations = res.data.data.locations;
                let dispatchCenters = res.data.data.dispatchCenters;

                dispatch(setPromos(promos));
                dispatch(setPromoArchives(archive_promos));
                dispatch(setLocations(locations));
                dispatch(setDispatchCenters(dispatchCenters));
                dispatch(setPromoCodeAdminDoneLoading());
            })
            .catch(function(err) {
                console.log("Err!");
                console.log(err);
                console.log(err.response);
                dispatch(setPromoCodeAdminDoneLoading());
            });
    };
};

export const downloadMassPromoCodes = (promo_code_id, label) => {
    return (dispatch) => {
        
        dispatch(setPromoCodeAdminLoading());

        return axios.post(API_URL+'/api/support/promocodes/download/'+promo_code_id, {
            responseType: 'blob',
        }).then(function(response) {

            dispatch(setPromoCodeAdminDoneLoading());
            
            let fileName = 'promo codes for ' + label + '.csv';

            var fileDownload = require('js-file-download');
            fileDownload(response.data, fileName);
            
        })
        .catch(function(err) {
            dispatch(setPromoCodeAdminDoneLoading());
            console.log(err);
            console.log(err.response);
        });
    };
}