export const setTimezones = (timezones) => {
    return {
        type: 'SET_TIMEZONES',
        timezones
    }
}

export const resetTimezones = () => {
    return {
        type: 'RESET_TIMEZONES'
    }
}