import React from 'react';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import arrayMove from 'array-move';

import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import SettingsIcon from '@material-ui/icons/Settings';
import Typography from '@material-ui/core/Typography';

import LoadScreen from '../../common/LoadScreen';
import verifyLogin from '../../../utils/verifyLogin';
import { loadPizzas, setPizzaFilter, togglePizzaEnabled, updatePizzaOrder } from '../../../actions/menu/pizzas/pizzas';
import { settingsFormUpdate } from '../../../actions/menu/pizzas/settingsForm';
import { populateNewPizzaFormSizes, pizzaFormUpdate, pizzaFormReset } from '../../../actions/menu/pizzas/pizzaForm';
import { loadPizzaSettings } from '../../../actions/menu/pizzas/settingsForm';
import { 
    openPizzaCreate, 
    closePizzaCreate, 
    openPizzaUpdate, 
    closePizzaUpdate, 
    openPizzaSettingsConfig, 
    closePizzaSettingsConfig, 
    openPizzaSettingsUpdate, 
    closePizzaSettingsUpdate,
    openCustomPizzasUpdate,
    closeCustomPizzasUpdate
} from '../../../actions/modals';
import { loadPageData } from '../../../actions/auth';
import filterPizzas from '../../../utils/filterListName';

import ConfigPizzaSettings from './modal/ConfigPizzaSettings';
import UpdatePizzaSettings from './modal/UpdatePizzaSettings';
import CustomPizzaSettings from './modal/CustomPizzaSettings';
import CreatePizza from './modal/CreatePizza';
import UpdatePizza from './modal/UpdatePizza';

import SortableMenuList from '../common/SortableMenuList';

import checkPermissions from '../../../utils/checkUserPermissions';

class PizzasPage extends React.Component {

    constructor(props) {
        super(props);

        this.toggleCreateModal = this.toggleCreateModal.bind(this);
        this.toggleUpdateModal = this.toggleUpdateModal.bind(this);
        this.toggleUpdateSettingsModal = this.toggleUpdateSettingsModal.bind(this);
        this.togglePizzaEnabled = this.togglePizzaEnabled.bind(this);
        this.checkPermissions = this.checkPermissions.bind(this);
        this.onSortEnd = this.onSortEnd.bind(this);

        this.state = {
            sidebarMenuOpen: false,
            selectedPizza: {}
        };
    }

    componentDidMount() {
        verifyLogin(this.props.history);
    }

    componentDidUpdate() {
        verifyLogin(this.props.history);
    }

    checkPermissions() {
        if (checkPermissions(this.props.user, 'restaurant_manager', this.props.match.params.id) ||
            checkPermissions(this.props.user, 'support')) {
            // load the restaurant
            this.props.dispatch(loadPageData(this.props.match.params));
            this.props.dispatch(loadPizzas(this.props.match.params.id));
        } else {
            this.props.history.push('/permission-denied');
        }  
    }

    toggleCustomPizzaModal(isOpen) {
        if (isOpen) {
            
            let scrollPosition = document.documentElement.scrollTop || document.body.scrollTop;

            this.props.dispatch(settingsFormUpdate({ prop: 'scrollPosition', value: scrollPosition }));
            this.props.dispatch(loadPizzaSettings(this.props.settings));
            this.props.dispatch(openCustomPizzasUpdate());
        }
        else {
            this.props.dispatch(closeCustomPizzasUpdate());
        }
    }

    toggleUpdateSettingsModal(isOpen) {
        if (isOpen) {

            let scrollPosition = document.documentElement.scrollTop || document.body.scrollTop;
            
            this.props.dispatch(settingsFormUpdate({ prop: 'activeTab', value: 0 }));
            this.props.dispatch(settingsFormUpdate({ prop: 'scrollPosition', value: scrollPosition }));
            this.props.dispatch(loadPizzaSettings(this.props.settings));
            this.props.dispatch(openPizzaSettingsUpdate());
        }
        else {
            this.props.dispatch(closePizzaSettingsUpdate());
        }
    }

    toggleUpdateModal(isOpen, selectedPizza) {
        if (isOpen) {

            let scrollPosition = document.documentElement.scrollTop || document.body.scrollTop;

            this.setState(() => ({
                selectedPizza
            }));
            this.props.dispatch(pizzaFormReset());
            this.props.dispatch(pizzaFormUpdate({ prop: 'name', value: selectedPizza.name }));
            this.props.dispatch(pizzaFormUpdate({ prop: 'description', value: selectedPizza.description }));
            this.props.dispatch(pizzaFormUpdate({ prop: 'thumb_image', value: selectedPizza.thumb_image }));
            this.props.dispatch(pizzaFormUpdate({ prop: 'non_taxable', value: selectedPizza.non_taxable }));
            this.props.dispatch(pizzaFormUpdate({ prop: 'prices', value: selectedPizza.prices.sort(function(a, b) {
                if ( a.price_order < b.price_order ){
                    return -1;
                  }
                  if ( a.price_order > b.price_order ){
                    return 1;
                  }
                  return 0;
            }) }));
            this.props.dispatch(pizzaFormUpdate({ prop: 'toppings', value: selectedPizza.toppings }));
            this.props.dispatch(pizzaFormUpdate({ prop: 'crusts', value: selectedPizza.available_crusts }));
            this.props.dispatch(pizzaFormUpdate({ prop: 'sauces', value: selectedPizza.available_sauces }));
            this.props.dispatch(pizzaFormUpdate({ prop: 'scrollPosition', value: scrollPosition }));
            if(selectedPizza.hours_available != null){
                this.props.dispatch(pizzaFormUpdate({ prop: 'hours_available', value: selectedPizza.hours_available }));
            }
            this.props.dispatch(pizzaFormUpdate({ prop: 'alwaysAvailable', value: selectedPizza.alwaysAvailable }));

            this.props.dispatch(openPizzaUpdate());
        }
        else {
            this.props.dispatch(closePizzaUpdate());
        }
    }

    toggleSettingsConfigModal(isOpen) {
        if (isOpen) {
            this.props.dispatch(settingsFormUpdate({ prop: 'activeTab', value: 0 }));
            this.props.dispatch(loadPizzaSettings(this.props.settings));
            this.props.dispatch(openPizzaSettingsConfig());
        }
        else {
            this.props.dispatch(closePizzaSettingsConfig());
        }
    }

    toggleCreateModal(isOpen) {
        if (isOpen) {
            this.props.dispatch(pizzaFormReset());
            this.props.dispatch(pizzaFormUpdate({ prop: 'activeTab', value: 0 }));
            this.props.dispatch(populateNewPizzaFormSizes(this.props.settings.sizes));
            this.props.dispatch(openPizzaCreate());
        }
        else {
            this.props.dispatch(closePizzaCreate());
        }
    }

    onSortEnd({oldIndex, newIndex})  {

        let newPizzaOrder = arrayMove(this.props.pizzas, oldIndex, newIndex);
        for (let i = 0; i < newPizzaOrder.length; i++) {
            newPizzaOrder[i]['pizza_order'] = i + 1;
        }

        this.props.dispatch(updatePizzaOrder(this.props.match.params.id, newPizzaOrder));
    }

    renderEnableButton(pizza) {
        if (pizza.enabled) {
            return (
                <Button size="small" color="primary" onClick={() => this.togglePizzaEnabled(pizza, 0)}>
                    Disable
                </Button>
            );
        }
        else {
            return (
                <Button size="small" color="primary" onClick={() => this.togglePizzaEnabled(pizza, 1)}>
                    Enable
                </Button>
            );
        }
    }

    togglePizzaEnabled(pizza, enabled) {
        let confirmStr = "Are you sure you want to disable this pizza?";
        if (enabled) {
            confirmStr = "Enable this pizza?";
        }

        if (confirm(confirmStr)) {
            this.props.dispatch(togglePizzaEnabled(this.props.match.params.id, pizza.id, enabled));
        };
    }

    renderPizzas() {

        if (this.props.loading) {
            return (
                <div className="page-loading">
                    <CircularProgress size={100} />
                </div>
            );
        }
        else {
            if (this.props.pizzas.length <= 0) {
                return (
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography className="no-results">No pizzas entered.</Typography>
                        </Grid>
                    </Grid>
                );
            }
            else {
                return (
                    <SortableMenuList
                        items={this.props.pizzas}
                        toggleEnabled={this.togglePizzaEnabled}
                        toggleUpdateModal={this.toggleUpdateModal}
                        onSortEnd={this.onSortEnd}
                        showImage={true}
                        isCategory={false}
                    />
                );
            }
        }
    }

    renderPizzasPage() {
        if (this.props.showConfig) {
            return (
                <div className="window-menu-settings">
                    <h1>Pizza Setup</h1>
                    <p>We noticed that your pizzas haven't been completely set up yet. Please take a few minutes and enter the available sizes and toppings for your pizza. If you would also like to add custom crusts and sauces, you can do that too!</p>

                    <Button 
                        variant="contained"
                        size="large"
                        color="primary" 
                        onClick={() => this.toggleSettingsConfigModal(true)}
                        className="setup-button"
                    >
                        Setup
                    </Button>

                    <ConfigPizzaSettings 
                        categoryId={this.props.match.params.category_id}
                        restaurantId={this.props.match.params.id}
                    />
                </div>
            );
        }
        else {
            return (
                <div>
                    <div className="card-container">
                        <Grid container className="menu-page-header">
                            <Grid item xs={12} className="heading-grid">
                                <h1>Pizzas</h1>
                                <FormControl fullWidth>
                                    <TextField 
                                        label="Filter Pizzas" 
                                        variant="outlined" 
                                        value={this.props.filter}
                                        onChange={(e) => this.props.dispatch(setPizzaFilter(e.target.value))} 
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>

                        {this.renderPizzas()}
                    </div>

                    <div className="window-fab">
                        <Fab variant="extended" className="btn-fab btn-red" onClick={() => this.toggleCustomPizzaModal(true)}>
                            Create your own Pizza
                        </Fab>

                        <Fab className="btn-fab btn-edit" onClick={() => this.toggleUpdateSettingsModal(true)}>
                            <SettingsIcon />
                        </Fab>

                        <Fab className="btn-fab btn-add" onClick={() => this.toggleCreateModal(true)}>
                            <AddIcon />
                        </Fab>
                    </div>

                    <UpdatePizzaSettings 
                        restaurantId={this.props.match.params.id}
                    />

                    <CustomPizzaSettings 
                        restaurantId={this.props.match.params.id}
                    />

                    <CreatePizza
                        restaurantId={this.props.match.params.id}
                    />

                    <UpdatePizza 
                        pizza={this.state.selectedPizza}
                        restaurantId={this.props.match.params.id}
                    />
                </div>
            )
        }
    }
    
    render() {
        return (
            <LoadScreen
                checkPermissions={this.checkPermissions}
            >
                {this.renderPizzasPage()}                
            </LoadScreen>
        );
    }
};

const mapStateToProps = (state, props) => {
    return {
        loading: state.pizzas.loading || state.restaurants.loading,
        restaurant: state.selectedRestaurant.restaurant,
        pizzas: filterPizzas(state.pizzas.pizzas, {text: state.pizzas.filter}),
        settings: state.pizzas.settings,
        showConfig: state.pizzas.showConfig,
        pizzaForm: state.pizzaForm,
        createModalOpen: state.modals.pizzaCreate,
        updateModalOpen: state.modals.pizzaUpdate,
        user: state.loggedUser.user,
    };
};

export default connect(mapStateToProps)(PizzasPage);