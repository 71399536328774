
export const modifierFormUpdate = ({ prop, value }) => {
    return {
        type: 'MODIFIER_FORM_UPDATE',
        modifier: { prop, value }
    };
};

export const modifierFormError = (message) => {
    return {
        type: 'MODIFIER_FORM_ERROR',
        message
    };
};

export const modifierFormSuccess = (message) => {
    return {
        type: 'MODIFIER_FORM_SUCCESS',
        message
    };
};

export const modifierFormReset = () => {  
    return {
        type: 'MODIFIER_FORM_RESET',
    };
};

export const modifierFormUpdateItem = ({ prop, index, value }) => {
    return {
        type: 'MODIFIER_FORM_UPDATE_ITEM',
        item: { prop, index, value }
    };
};

export const modifierFormUpdatePriceLevel = ({ prop, index, value }) => {
    return {
        type: 'MODIFIER_FORM_UPDATE_PRICE_LEVEL',
        item: { prop, index, value }
    };
};

export const modifierFormUpdatePrice = ({ prop, index, priceIndex, value }) => {
    return {
        type: 'MODIFIER_FORM_UPDATE_PRICE',
        item: { prop, index, priceIndex, value }
    };
};

export const modifierFormAddItem = (item) => {
    return {
        type: 'MODIFIER_FORM_ADD_ITEM',
        item
    }
};

export const modifierFormRemoveItem = (index) => {
    return {
        type: 'MODIFIER_FORM_REMOVE_ITEM',
        index
    }
}