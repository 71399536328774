import React from 'react';
import { connect } from 'react-redux';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';

import { dispatchCenterFormUpdate } from '../../../actions/superuser/dispatchCenters/dispatchCenterForm';

class DispatchCenterInfo extends React.Component {
    
    render() {
        return (
            <Grid container>
                <Grid item xs={12} className="form-grid">
                    <Typography className="modal-description">
                        Please enter a dispatch center name, type, local timezone, and any contact information.
                    </Typography>
                </Grid>

                <Grid item xs={4} className="form-grid" style={{marginBottom: 20}}>
                    <TextField
                        label="Name"
                        className="modal-input"
                        fullWidth={true}
                        value={this.props.dispatchCenterForm.name}
                        onChange={(e) => { this.props.dispatch(dispatchCenterFormUpdate({ prop: 'name', value: e.target.value })); }}
                    />
                </Grid>

                <Grid item xs={4} className="form-grid">
                    <InputLabel>Type</InputLabel>
                    <Select
                        fullWidth={true}
                        labelId="type"
                        value={this.props.dispatchCenterForm.type}
                        onChange={(e) => { this.props.dispatch(dispatchCenterFormUpdate({ prop: 'type', value: e.target.value })); }}
                    >
                         <MenuItem key={'ORDER'} value={'ORDER'}>Order Dispatch Center</MenuItem>
                         <MenuItem key={'DELIVERY'} value={'DELIVERY'}>Delivery Center</MenuItem>
                    </Select>
                </Grid>

                <Grid item xs={4} className="form-grid">
                    <InputLabel>Timezone</InputLabel>
                    <Select
                        fullWidth={true}
                        labelId="timezone"
                        value={this.props.dispatchCenterForm.timezone}
                        onChange={(e) => { this.props.dispatch(dispatchCenterFormUpdate({ prop: 'timezone', value: e.target.value })); }}
                    >
                         {this.props.timezones.map((tz) => <MenuItem key={tz} value={tz}>{tz}</MenuItem>)}
                    </Select>
                </Grid>  

                <Grid item xs={2} className="form-grid">
                    <FormGroup row>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={this.props.dispatchCenterForm.sms_update_enabled}
                                    onChange={(e) => { this.props.dispatch(dispatchCenterFormUpdate({ prop: 'sms_update_enabled', value: !this.props.dispatchCenterForm.sms_update_enabled })); }}
                                    name="sms_update_enabled"
                                    color="primary"
                                />
                            }
                            label="Updates via SMS?"
                            labelPlacement="top"
                        />
                    </FormGroup>
                </Grid>   

                <Grid item xs={10} className="form-grid">
                    <TextField
                        label="Phone"
                        className="modal-input"
                        fullWidth={true}
                        value={this.props.dispatchCenterForm.phone}
                        onChange={(e) => { this.props.dispatch(dispatchCenterFormUpdate({ prop: 'phone', value: e.target.value })); }}
                        margin="normal"
                    />
                </Grid> 

                <Grid item xs={2} className="form-grid">
                    <FormGroup row>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={this.props.dispatchCenterForm.getswift_update_enabled}
                                    onChange={(e) => { 
                                            this.props.dispatch(dispatchCenterFormUpdate({ prop: 'getswift_update_enabled', value: !this.props.dispatchCenterForm.getswift_update_enabled }));
                                            this.props.dispatch(dispatchCenterFormUpdate({ prop: 'cartwheel_update_enabled', value: false }));
                                        }}
                                    name="getswift_update_enabled"
                                    color="primary"
                                />
                            }
                            label="Enable GetSwift?"
                            labelPlacement="top"
                        />
                    </FormGroup>
                </Grid>   

                <Grid item xs={10} className="form-grid">
                    <TextField
                        label="GetSwift API Key"
                        className="modal-input"
                        fullWidth={true}
                        value={this.props.dispatchCenterForm.getswift_api_key}
                        onChange={(e) => { this.props.dispatch(dispatchCenterFormUpdate({ prop: 'getswift_api_key', value: e.target.value })); }}
                        margin="normal"
                    />
                </Grid>

                <Grid item xs={2} className="form-grid">
                    <FormGroup row>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={this.props.dispatchCenterForm.cartwheel_update_enabled}
                                    onChange={(e) => { 
                                        this.props.dispatch(dispatchCenterFormUpdate({ prop: 'cartwheel_update_enabled', value: !this.props.dispatchCenterForm.cartwheel_update_enabled }));
                                        this.props.dispatch(dispatchCenterFormUpdate({ prop: 'getswift_update_enabled', value: false }));
                                    }}
                                    name="cartwheel_update_enabled"
                                    color="primary"
                                />
                            }
                            label="Enable Cartwheel?"
                            labelPlacement="top"
                        />
                    </FormGroup>
                </Grid>

                <Grid item xs={10} className="form-grid">
                    <TextField
                        label="Cartwheel Team ID"
                        className="modal-input"
                        fullWidth={true}
                        value={this.props.dispatchCenterForm.cartwheel_team_id}
                        onChange={(e) => { this.props.dispatch(dispatchCenterFormUpdate({ prop: 'cartwheel_team_id', value: e.target.value })); }}
                        margin="normal"
                    />
                </Grid> 

                <Grid xs={2} />
                <Grid item xs={10} className="form-grid" style={{marginBottom: 20, marginTop: 16}}>
                    <InputLabel>Default Delivery Time</InputLabel>
                    <Select
                        fullWidth={false}
                        margin="normal"
                        labelId="deliveryTime"
                        value={this.props.dispatchCenterForm.delivery_time}
                        onChange={(e) => { this.props.dispatch(dispatchCenterFormUpdate({ prop: 'delivery_time', value: e.target.value })); }}
                    >
                         <MenuItem key={10} value={10}>10</MenuItem>
                         <MenuItem key={15} value={15}>15</MenuItem>
                         <MenuItem key={20} value={20}>20</MenuItem>
                         <MenuItem key={25} value={25}>25</MenuItem>
                         <MenuItem key={30} value={30}>30</MenuItem>
                         <MenuItem key={35} value={35}>35</MenuItem>
                         <MenuItem key={40} value={40}>40</MenuItem>
                         <MenuItem key={45} value={45}>45</MenuItem>
                         <MenuItem key={50} value={50}>50</MenuItem>
                         <MenuItem key={55} value={55}>55</MenuItem>
                         <MenuItem key={60} value={60}>60</MenuItem>
                    </Select>
                </Grid> 

            </Grid>      
        );
    }
};

const mapStateToProps = (state, props) => {
    return {
        dispatchCenterForm: state.dispatchCenterForm,
        timezones: state.timezones.timezones
    };
};

export default connect(mapStateToProps)(DispatchCenterInfo);