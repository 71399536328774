import React from 'react';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

import validateCurrency from '../../../../utils/validateCurrency';
import { addFoodItem } from '../../../../actions/menu/fooditems/foodItems';
import { foodItemFormUpdate, foodItemFormError, foodItemFormReset } from '../../../../actions/menu/fooditems/foodItemForm';
import { openFoodItemCreate, closeFoodItemCreate } from '../../../../actions/modals';
import filterFoodItems from '../../../../selectors/foodItems';

import CloseIcon from '../../../common/modal/CloseIcon';

import Info from './Info';
import Prices from './Prices';
import Choices from './Choices';
import Addons from './Addons';

class CreateFoodItem extends React.Component {

    constructor(props) {
        super(props);

        this.toggleCreateModal = this.toggleCreateModal.bind(this);
        this.checkFoodItemInfo = this.checkFoodItemInfo.bind(this);
        this.checkAddFoodItemPrices = this.checkAddFoodItemPrices.bind(this);
        this.submitCreateFoodItem = this.submitCreateFoodItem.bind(this);
        this.updateTabSelection = this.updateTabSelection.bind(this);
    }

    getSteps() {
        return [
            'Add General Information',
            'Add Prices',
            'Add Choices',
            'Addons'
        ];
    }

    toggleCreateModal(isOpen) {
        if (isOpen) {
            this.props.dispatch(foodItemFormReset());
            this.props.dispatch(openFoodItemCreate());
        }
        else {
            this.props.dispatch(closeFoodItemCreate());
        }
    }

    checkAddFoodItemPrices() {

        let valid = true;
        let multiplePriceCount = 0;

        // perform error checking on the food prices
        if (this.props.foodItemForm.hasMultiplePrices) {

            // go through each price and determine if it is valid
            for (let i = 0; i < this.props.foodItemForm.prices.length; i++) {

                // ignore any blank rows
                if (this.props.foodItemForm.prices[i].name != '' || this.props.foodItemForm.prices[i].price != '') {
                    if (!validateCurrency(this.props.foodItemForm.prices[i].price) || this.props.foodItemForm.prices[i].name.match(/^\s*$/) || +this.props.foodItemForm.prices[i].price == 0.00) {
                        valid = false;
                        this.props.dispatch(foodItemFormError("Please ensure that all prices have a valid name and price."));
                    }
                    else {
                        multiplePriceCount++;
                    }
                }
            }

            if (multiplePriceCount <= 0 && valid) {
                valid = false;
                this.props.dispatch(foodItemFormError("Please enter at least one price."));
            }
        }
        else {
            if (!validateCurrency(this.props.foodItemForm.singlePrice)) {
                this.props.dispatch(foodItemFormError("Please enter a valid food item price."));
                valid = false;
            }
            if(+this.props.foodItemForm.singlePrice == 0.00){
                this.props.dispatch(foodItemFormError("Please enter a valid food item price."));
                valid = false;
            }
        }

        if (valid) {
            this.props.dispatch(foodItemFormError(""));
            
            // clean out any multiple prices that are empty
            if (this.props.foodItemForm.hasMultiplePrices) {
                let trimmedPrices = this.props.foodItemForm.prices.filter((price) => {
                    return price.name != '' && price.price != '';
                });
                this.props.dispatch(foodItemFormUpdate({ prop: 'prices', value: trimmedPrices }));
            }

            this.props.dispatch(foodItemFormUpdate({ prop: 'activeTab', value: 2 }));
        }
    }

    updateTabSelection(index) {
        this.props.dispatch(foodItemFormUpdate({ prop: 'activeTab', value: index }));
    }

    checkFoodItemInfo() {

        // perform some error checking
        if (this.props.foodItemForm.name.trim() == "") {
            this.props.dispatch(foodItemFormError("Please enter a food item name."));
        }else if(this.props.foodItemForm.saveSchedule && this.props.foodItemForm.scheduleName.trim() == ""){
            this.props.dispatch(foodItemFormError("Please enter a name for the availability schedule."));
        }else if(this.props.foodItemForm.saveSchedule && this.props.restaurant.availability_schedules.map((schedule) => {return schedule.name}).includes(this.props.foodItemForm.scheduleName.trim())){
            this.props.dispatch(foodItemFormError("An availability schedule with that name already exists."));
        }else{
            this.props.dispatch(foodItemFormError(""));
            this.props.dispatch(foodItemFormUpdate({ prop: 'activeTab', value: 1 }));
        }
    }

    submitCreateFoodItem() {
        this.props.dispatch(addFoodItem(this.props.restaurantId, this.props.categoryId, this.props.foodItemForm));
    }

    renderCreateModalActiveTab() {
        if (this.props.loading) {
            return (
                <div className="page-loading">
                    <CircularProgress size={100} />
                </div>
            );
        }
        else {
            switch(this.props.foodItemForm.activeTab) {
                case 0:
                    return this.renderCreateModalInfo();

                case 1:
                    return this.renderCreateModalPrices();

                case 2:
                    return this.renderCreateModalChoices();

                case 3:
                    return this.renderCreateModalAddons();
            }
        }
    }

    renderCreateModalInfo() {
        return (
            <div>
                { this.props.foodItemForm.error && (
                    <Typography className="modal-error">
                        { this.props.foodItemForm.error }
                    </Typography>
                )}

                <Info />

                <Button variant="contained" fullWidth={true} className="modal-button-bottom" onClick={this.checkFoodItemInfo}>
                    Next - Add Prices
                </Button>
            </div>
        );
    }

    renderCreateModalPrices() {
        return (
            <div>
                { this.props.foodItemForm.error && (
                    <Typography className="modal-error">
                        { this.props.foodItemForm.error }
                    </Typography>
                )}

                <Typography className="modal-description">
                    Enter any available food price options that you have, such as 'Small', 'Medium' and 'Large'. 
                    Please note, you do not need to enter a name if there is only one size.
                </Typography>

                <Prices />

                <Grid container>
                    <Grid item xs={6} className="form-grid-right">
                        <Button variant="contained" fullWidth={true} className="modal-button-bottom btn-secondary" onClick={() => this.updateTabSelection(0)}>
                            Back
                        </Button>
                    </Grid>
                    <Grid item xs={6} className="form-grid-left">
                        <Button variant="contained" fullWidth={true} className="modal-button-bottom" onClick={this.checkAddFoodItemPrices}>
                            Next - Choices
                        </Button>
                    </Grid>
                </Grid>
            </div>
        );
    }

    renderCreateModalChoices() {
        return (
            <div>
                { this.props.foodItemForm.error && (
                    <Typography className="modal-error">
                        { this.props.foodItemForm.error }
                    </Typography>
                )}

                <Choices>
                    <Grid container>
                        <Grid item xs={6} className="form-grid-right">
                            <Button variant="contained" fullWidth={true} color="secondary" className="modal-button-bottom btn-secondary" onClick={() => this.updateTabSelection(1)}>
                                Back
                            </Button>
                        </Grid>
                        <Grid item xs={6} className="form-grid-left">
                            <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom" onClick={() => this.updateTabSelection(3)}>
                                Next - Addons
                            </Button>
                        </Grid>
                    </Grid> 
                </Choices>             
            </div>
        );
    }

    renderCreateModalAddons() {
        return (
            <div>
                { this.props.foodItemForm.error && (
                    <Typography className="modal-error">
                        { this.props.foodItemForm.error }
                    </Typography>
                )}

                <Addons>
                    <Grid container>
                        <Grid item xs={6} className="form-grid-right">
                            <Button variant="contained" fullWidth={true} color="secondary" className="modal-button-bottom btn-secondary" onClick={() => this.updateTabSelection(2)}>
                                Back
                            </Button>
                        </Grid>
                        <Grid item xs={6} className="form-grid-left">
                            <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom" onClick={() => this.submitCreateFoodItem()}>
                                Add Food Item
                            </Button>
                        </Grid>
                    </Grid> 
                </Addons>
            </div>
        );
    }
    
    render() {
        return (
            <Modal
                aria-labelledby="food-item-add"
                aria-describedby="food-item-add"
                open={this.props.createModalOpen}
                onClose={() => this.toggleCreateModal(false)}
                className="modal_admin"
                disableBackdropClick
            >
                <div className="modal-member">
                    <Typography variant="h4" id="modal-title">
                        <CloseIcon/>
                    </Typography>
                    <Stepper activeStep={this.props.foodItemForm.activeTab} alternativeLabel>
                        {this.getSteps().map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>

                    <Typography variant="h2">
                        Add a Food Item
                    </Typography>

                    {this.renderCreateModalActiveTab()}
                </div>
            </Modal>
        );
    }
};

const mapStateToProps = (state, props) => {
    return {
        loading: state.foodItems.loading,
        restaurant: state.selectedRestaurant.restaurant,
        foodItems: filterFoodItems(state.foodItems.foodItems, {text: state.foodItems.filter}),
        foodItemForm: state.foodItemForm,
        createModalOpen: state.modals.foodItemCreate,
        updateModalOpen: state.modals.foodItemUpdate,
    };
};

export default connect(mapStateToProps)(CreateFoodItem);