import axios from 'axios';
import { API_URL } from '../../../config/constants';
import { modifierFormReset, modifierFormError, modifierFormSuccess, modifierFormUpdate } from './modifierForm';
import { closeModifierModal } from '../../modals';

export const setModifiers = (modifiers) => {
    return {
        type: 'SET_MODIFIERS',
        modifiers
    }
};

export const setModifierFilter = (text) => {
    return {
        type: 'SET_MODIFIER_FILTER',
        text
    }
};

export const resetModifiers = () => {
    return {
        type: 'RESET_MODIFIERS'
    }
};

export const setModifiersLoading = () => ({
    type: 'SET_MODIFIERS_LOADING'
});

export const loadModifiers = (restaurant_id) => {
    return (dispatch) => {
        return axios.get(API_URL+'/api/restaurants/'+restaurant_id+'/modifiers')
            .then(function(res) {
                dispatch(setModifiers(res.data.data.modifiers));
            })
            .catch(function(err) {
                console.log(err);
            });
    };
};

export const addModifier = (restaurantId, modifier) => {

    return (dispatch) => {

        const formData = new FormData();

        dispatch(setModifiersLoading());

        // add the data
        formData.append('data', JSON.stringify(modifier));

        return axios.post(
                API_URL+'/api/restaurants/'+restaurantId+'/modifiers', 
                formData,
                {
                    headers: { 'Content-Type': 'multipart/form-data' }
                }
            )
            .then(function(res) {
                if (res.data.status == 'success') {
                    dispatch(setModifiers(res.data.data.modifiers));
                    dispatch(modifierFormReset());
                    dispatch(closeModifierModal());
                }
                else {
                    dispatch(modifierFormError(res.data.message));
                }
            })
            .catch(function(err) {
                
                // token is likely invalid; remove it and prompt the user to login again
                dispatch(modifierFormError("We're sorry, but our system has encountered an issue, to which we have been notified."));
                if (err.response.status === 401) {
                    removeAuthToken(dispatch);
                }
                
            });

    };

};

export const updateModifier = (restaurantId, modifier) => {
    
    return (dispatch) => {
        
        const formData = new FormData();

        // add the data
        formData.append('scope', 'info');
        formData.append('data', JSON.stringify(modifier));

        dispatch(setModifiersLoading());

        return axios.post(
            API_URL+'/api/restaurants/'+restaurantId+'/modifiers/'+modifier.id, 
            formData,
            {
                headers: { 'Content-Type': 'multipart/form-data' }
            }
        ).then(function(res) {
            if (res.data.status == 'success') {

                console.log(res.data.data.modifiers);
                console.log(res.data.data.modifier);

                dispatch(setModifiers(res.data.data.modifiers));
                dispatch(modifierFormReset());
                dispatch(modifierFormUpdate({ prop: 'id', value: res.data.data.modifier.id }));
                dispatch(modifierFormUpdate({ prop: 'name', value: res.data.data.modifier.name }));
                dispatch(modifierFormUpdate({ prop: 'min', value: res.data.data.modifier.min }));
                dispatch(modifierFormUpdate({ prop: 'max', value: res.data.data.modifier.max }));
                dispatch(modifierFormUpdate({ prop: 'price_levels', value: res.data.data.modifier.price_levels }));
                dispatch(modifierFormUpdate({ prop: 'items', value: res.data.data.modifier.items }));                
                dispatch(modifierFormSuccess('Modifier Updated!'));

            }
            else {
                dispatch(modifierFormError(res.data.message));
                console.log("Could not update your modifier!");
            }
        })
        .catch(function(err) {
            console.log('err');
            console.log(err);
            // token is likely invalid; remove it and prompt the user to login again
            dispatch(modifierFormError("We're sorry, but our system has encountered an issue, to which we have been notified."));
            if (err.response && err.response.status === 401) {
                removeAuthToken(dispatch);
            }
            
        });

    };
};

export const updateModifierOrder = (restaurant_id, modifiers) => {

    return (dispatch) => {

        dispatch(setModifiersLoading());

        // prepare the new order of items to move over
        let itemOrder = [];
        for (let i = 0; i < modifiers.length; i++) {
            itemOrder.push(modifiers[i].id);
        }

        // prepare the order of addons and IDs to send
        return axios.post(API_URL+'/api/restaurants/'+restaurant_id+'/modifiers/order', { order: itemOrder })
            .then(function(res) {
                if (res.data.status == 'success') {
                    dispatch(setModifiers(res.data.data.modifiers));
                }
                else {
                    dispatch(modifierFormError(res.data.message));
                    console.log(res.data);
                    console.log("Could not update modifier order!");
                }
            })
            .catch(function(err) {

                // token is likely invalid; remove it and prompt the user to login again
                dispatch(modifierFormError("We're sorry, but our system has encountered an issue, to which we have been notified."));
                if (err.response.status === 401) {
                    removeAuthToken(dispatch);
                }
                
            });
    };
};

export const toggleModifierEnabled = (restaurant_id, modifier_id, enabled) => {
    
    return (dispatch) => {

        dispatch(setModifiersLoading());
        return axios.post(API_URL+'/api/restaurants/'+restaurant_id+'/modifiers/'+modifier_id, { 
                scope: 'enable',
                enabled 
            })
            .then(function(res) {
                if (res.data.status == 'success') {
                    dispatch(setModifiers(res.data.data.modifiers));
                }
                else {
                    dispatch(modifierFormError(res.data.message));
                    console.log(res.data);
                    console.log("Could not toggle modifier!");
                }
            })
            .catch(function(err) {

                // token is likely invalid; remove it and prompt the user to login again
                dispatch(modifierFormError("We're sorry, but our system has encountered an issue, to which we have been notified."));
                if (err.response.status === 401) {
                    removeAuthToken(dispatch);
                }
                
            });
    };
};