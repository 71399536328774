const selectedDefaultState = {
    id: 0,
    restaurant: {}
};

export default (state = selectedDefaultState, action) => {

    switch (action.type) {

        case 'SET_SELECTED_RESTAURANT':
            return {
                ...state,
                restaurant: action.restaurant
            };

        case 'SET_SELECTED_RESTAURANT_AVAILABILITY_SCHEDULES':
            return {
                ...state,
                restaurant: {
                    ...state.restaurant,
                    availability_schedules: action.schedules
                }
            };

        case 'RESET_SELECTED_RESTAURANT':
            return selectedDefaultState;

        default:
            return state;
    }
}