import React from 'react';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

import validatePhone from '../../../utils/validatePhone';
import { addLocation } from '../../../actions/restaurant/locations';
import { locationFormUpdate, locationFormError, locationFormReset } from '../../../actions/restaurant/locationForm';
import { openLocationCreate, closeLocationCreate } from '../../../actions/modals';
import filterLocations from '../../../selectors/locations';

import CloseIcon from '../../common/modal/CloseIcon';

import Info from './LocationInfo';
import HoursOfOperation from './HoursOfOperation';
import DeliveryCharges from './DeliveryCharges';
import PromoCodes from './PromoCodes';

const daysOfWeek = [
    {
        label: 'Sunday',
        id: 'sun'
    },
    {
        label: 'Monday',
        id: 'mon'
    },
    {
        label: 'Tuesday',
        id: 'tue'
    },
    {
        label: 'Wednesday',
        id: 'wed'
    },
    {
        label: 'Thursday',
        id: 'thu'
    },
    {
        label: 'Friday',
        id: 'fri'
    },
    {
        label: 'Saturday',
        id: 'sat'
    }
];

class CreateLocation extends React.Component {

    constructor(props) {
        super(props);

        this.toggleCreateModal = this.toggleCreateModal.bind(this);
        this.checkLocationInfo = this.checkLocationInfo.bind(this);
        this.checkAddLocationHours = this.checkAddLocationHours.bind(this);
        this.checkLocationDeliveryCharges = this.checkLocationDeliveryCharges.bind(this);
        this.submitCreateLocation = this.submitCreateLocation.bind(this);
        this.updateTabSelection = this.updateTabSelection.bind(this);
    }

    getSteps() {
        return [
            'General Information',
            'Hours of Operation',
            'Delivery Charges',
            'Promo Codes'
        ];
    }

    toggleCreateModal(isOpen) {
        if (isOpen) {
            this.props.dispatch(locationFormReset());
            this.props.dispatch(openLocationCreate());
        }
        else {
            this.props.dispatch(closeLocationCreate());
        }
    }

    checkAddLocationHours() {

        let valid = true;
    
        for (let i = 0; i < daysOfWeek.length; i++) {
            if (this.props.locationForm.hoursOfOperation[daysOfWeek[i].id+'_open'] == this.props.locationForm.hoursOfOperation[daysOfWeek[i].id+'_close'] && !this.props.locationForm.hoursOfOperation[daysOfWeek[i].id+'_closed']) {
                valid = false;
                this.props.dispatch(locationFormError("Please ensure that your open date and close date are not the same if your store isn't closed all day."));
            }
        }

        if (valid) {
            this.props.dispatch(locationFormError(""));
            this.updateTabSelection(2);
        }
    }

    updateTabSelection(index) {
        this.props.dispatch(locationFormUpdate({ prop: 'activeTab', value: index }));
    }

    checkLocationInfo() {

        // perform some error checking
        if (this.props.locationForm.address.trim() == "" || this.props.locationForm.city.trim() == "" || this.props.locationForm.postal.trim() == "") {

            // required info isn't present
            this.props.dispatch(locationFormError("Please enter a location address, city, province and postal code."));
        }
        else if (this.props.locationForm.phone.trim() == "" || !validatePhone(this.props.locationForm.phone) || (this.props.locationForm.phone2.trim() != "" && !validatePhone(this.props.locationForm.phone2))) {

            // phone numbers entered aren't valid
            this.props.dispatch(locationFormError("Please ensure that your phone number is entered, or all entered phone numbers are valid."));
        }
        else if (this.props.locationForm.warning_phone.trim() != "" && !validatePhone(this.props.locationForm.warning_phone)) {

            // warning phone isn't valid
            this.props.dispatch(locationFormError("Please ensure that your warning phone number is valid."));
        }
        else {

            this.props.dispatch(locationFormError(""));
            this.updateTabSelection(1);
        }
    }

    checkLocationDeliveryCharges() {
        let valid = true;
        
        if (this.props.locationForm.delivery_type == 'fixed') {
            // delivery type is fixed, ensure that the fixed distance and total are correct
            if (this.props.locationForm.max_delivery_range <= 0 || this.props.locationForm.delivery_charge < 0) {
                valid = false;
                this.props.dispatch(locationFormError("Please ensure that your delivery range is greater than zero, and your price is valid."));
            }else if(this.props.locationForm.max_delivery_range > 99.999){
                valid = false;
                this.props.dispatch(locationFormError("Please ensure that your delivery range is no more than 99.999."));
            }else if(this.props.locationForm.delivery_charge > 999.99){
                valid = false;
                this.props.dispatch(locationFormError("Please ensure that your delivery charge is no more than 999.99."));
            }
        }
        else if (this.props.locationForm.delivery_type == 'distance') {

            // delivery type is distance, ensure that all entered charges are correct
            for (let i = 0; i < this.props.locationForm.deliveryCharges.length; i++) {

                if (
                    (typeof this.props.locationForm.deliveryCharges[i].max_distance === 'string' && !this.props.locationForm.deliveryCharges[i].max_distance.match(/^\d+(\.\d+)?$/)) || 
                    parseFloat(this.props.locationForm.deliveryCharges[i].max_distance) < 0 || 
                    (typeof this.props.locationForm.deliveryCharges[i].min_total === 'string' && !this.props.locationForm.deliveryCharges[i].min_total.match(/^\d+(\.\d+)?$/)) || 
                    parseFloat(this.props.locationForm.deliveryCharges[i].min_total) <= 0 || 
                    (typeof this.props.locationForm.deliveryCharges[i].price === 'string' && !this.props.locationForm.deliveryCharges[i].price.match(/^\d+(\.\d+)?$/)) || 
                    parseFloat(this.props.locationForm.deliveryCharges[i].price) < 0
                ) {
                    valid = false;
                    this.props.dispatch(locationFormError("Please ensure that you have entered a max distance, min order total and price for each delivery charge."));
                }
            }
        }

        if (valid) {
            this.props.dispatch(locationFormError(""));
            this.updateTabSelection(3);
        }
    }

    submitCreateLocation() {
        this.props.dispatch(addLocation(this.props.restaurantId, this.props.locationForm));
    }

    renderCreateModalActiveTab() {
        if (this.props.loading) {
            return (
                <div className="page-loading">
                    <CircularProgress size={100} />
                </div>
            );
        }
        else {
            switch(this.props.locationForm.activeTab) {
                case 0:
                    return this.renderCreateModalInfo();

                case 1:
                    return this.renderCreateModalHours();

                case 2:
                    return this.renderCreateModalDelivery();

                case 3:
                    return this.renderCreateModalPromoCodes();
            }
        }
    }

    renderCreateModalInfo() {
        return (
            <div>
                <Info />

                <Button variant="contained" fullWidth={true} className="modal-button-bottom" onClick={this.checkLocationInfo}>
                    Next - Add Hours of Operation
                </Button>
            </div>
        );
    }

    renderCreateModalHours() {
        return (
            <div>
                <HoursOfOperation />

                <Grid container>
                    <Grid item xs={6} className="form-grid-right">
                        <Button variant="contained" fullWidth={true} className="modal-button-bottom btn-secondary" onClick={() => this.updateTabSelection(0)}>
                            Back
                        </Button>
                    </Grid>
                    <Grid item xs={6} className="form-grid-left">
                        <Button variant="contained" fullWidth={true} className="modal-button-bottom" onClick={this.checkAddLocationHours}>
                            Next - Delivery Settings
                        </Button>
                    </Grid>
                </Grid>
            </div>
        );
    }

    renderCreateModalDelivery() {
        return (
            <div>
                <DeliveryCharges />

                <Grid container>
                    <Grid item xs={6} className="form-grid-right">
                        <Button variant="contained" fullWidth={true} color="secondary" className="modal-button-bottom btn-secondary" onClick={() => this.updateTabSelection(1)}>
                            Back
                        </Button>
                    </Grid>
                    <Grid item xs={6} className="form-grid-left">
                        <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom" onClick={this.checkLocationDeliveryCharges}>
                            Next - Promo Codes
                        </Button>
                    </Grid>
                </Grid>       
            </div>
        );
    }

    renderCreateModalPromoCodes() {
        return (
            <div>
                <PromoCodes />

                <Grid container>
                    <Grid item xs={6} className="form-grid-right">
                        <Button variant="contained" fullWidth={true} color="secondary" className="modal-button-bottom btn-secondary" onClick={() => this.updateTabSelection(2)}>
                            Back
                        </Button>
                    </Grid>
                    <Grid item xs={6} className="form-grid-left">
                        <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom" onClick={() => this.submitCreateLocation()}>
                            Add Location
                        </Button>
                    </Grid>
                </Grid>       
            </div>
        );
    }

    
    render() {
        return (
            <Modal
                aria-labelledby="location-add"
                aria-describedby="location-add"
                open={this.props.createModalOpen}
                onClose={() => this.toggleCreateModal(false)}
                className="modal_admin"
                disableBackdropClick
            >
                <div className="modal-member">
                    <Typography variant="h4" id="modal-title">
                        <CloseIcon/>
                    </Typography>
                    <Stepper activeStep={this.props.locationForm.activeTab} alternativeLabel>
                        {this.getSteps().map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>

                    <Typography variant="h2">
                        Add a New Location
                    </Typography>

                    { this.props.locationForm.error && (
                        <Typography className="modal-error">
                            { this.props.locationForm.error }
                        </Typography>
                    )}

                    {this.renderCreateModalActiveTab()}
                </div>
            </Modal>
        );
    }
};

const mapStateToProps = (state, props) => {
    return {
        loading: state.locations.loading,
        restaurant: state.selectedRestaurant.restaurant,
        locations: filterLocations(state.locations.locations, {text: state.locations.filter}),
        locationForm: state.locationForm,
        createModalOpen: state.modals.locationCreate,
        updateModalOpen: state.modals.locationUpdate,
    };
};

export default connect(mapStateToProps)(CreateLocation);