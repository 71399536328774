import React from 'react';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import LoadScreen from '../common/LoadScreen';
import verifyLogin from '../../utils/verifyLogin';
import { infoFormUpdate, infoFormError } from '../../actions/restaurant/infoForm';
import { updateRestaurantInfo } from '../../actions/restaurants';
import { loadPageData } from '../../actions/auth';
import validateCurrency from '../../utils/validateCurrency';

import Info from './modal/GeneralInfo';

import checkPermissions from '../../utils/checkUserPermissions';

const scopes = [
    'general',
    'payment',
    'charges',
    'loyalty',
    'custompartners'
]

class GeneralInformationPage extends React.Component {

    constructor(props) {
        super(props);
        this.updateRestaurantSettings = this.updateRestaurantSettings.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.checkPermissions = this.checkPermissions.bind(this);

        this.state = {
            sidebarMenuOpen: false,
            displayView: 0,
            infoScope: 'general'
        };
    }

    componentDidMount() {
        verifyLogin(this.props.history);
    }

    checkPermissions() {
        if (checkPermissions(this.props.curUser, 'restaurant_admin', this.props.match.params.id)) {
            this.props.dispatch(loadPageData(this.props.match.params, 'generalInformation'));
        } else {
            this.props.history.push('/permission-denied');
        } 
    }

    handleChange(event, newValue) {
        this.props.dispatch(infoFormUpdate({ prop: 'error', value: '' }));
        this.props.dispatch(infoFormUpdate({ prop: 'message', value: '' }));
        this.setState(() => ({displayView: newValue}))
        this.setState(() => ({infoScope: scopes[newValue]}))
    }

    updateRestaurantSettings() {
        // perform some error checking
        if (this.props.infoForm.name.trim() == "") {

            // restaurant name isn't present
            this.props.dispatch(infoFormError("Please enter a restaurant name."));
        }
        else if (!validateCurrency(this.props.infoForm.additional_charge) || this.props.infoForm.additional_charge < 0) {

            // additional charge value isn't valid
            this.props.dispatch(infoFormError("Please make sure that your additional charge is a valid number that is not less than zero."));
        }
        else if (this.props.curUser && 
            this.props.curUser.superuser && (
            (typeof this.props.infoForm.standard_charge_amount === 'string' && !this.props.infoForm.standard_charge_amount.match(/^\d+(\.\d+)?$/)) || 
            parseFloat(this.props.infoForm.standard_charge_amount)  < 0)) {

            // additional charge value isn't valid
            this.props.dispatch(infoFormError("Please make sure that your standard charge is a valid number that is not less than zero."));
        }
        else if (this.props.curUser && 
            this.props.curUser.superuser && (
            this.props.infoForm.whitelabel_enabled && (typeof this.props.infoForm.whitelabel_charge_amount === 'string' && !this.props.infoForm.whitelabel_charge_amount.match(/^\d+(\.\d+)?$/)) || 
            parseFloat(this.props.infoForm.whitelabel_charge_amount)  < 0)) {

            // additional charge value isn't valid
            this.props.dispatch(infoFormError("Please make sure that your whitelabel charge is a valid number that is not less than zero."));
        }
        else if (this.props.curUser && 
            this.props.curUser.superuser && (
            this.props.infoForm.internal_enabled && (typeof this.props.infoForm.internal_charge_amount === 'string' && !this.props.infoForm.internal_charge_amount.match(/^\d+(\.\d+)?$/)) || 
            parseFloat(this.props.infoForm.internal_charge_amount)  < 0)) {

            // additional charge value isn't valid
            this.props.dispatch(infoFormError("Please make sure that your internal charge is a valid number that is not less than zero."));
        }
        else {

            // clear the error and attempt to update the food item info
            this.props.dispatch(infoFormError(""));
            this.props.dispatch(updateRestaurantInfo(this.props.match.params.id, this.props.infoForm));
        }
    }

    renderTabs() {
        if (checkPermissions(this.props.curUser, 'superuser')) {
            return (
                <Tabs
                    value={this.state.displayView}
                    onChange={this.handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    centered
                >
                    <Tab label="General Info" />
                    <Tab label="Payment Info" />
                    <Tab label="Charges" />
                    <Tab label="Loyalty Rewards" />
                    <Tab label="Custom Partners" />
                </Tabs>
            );
        } else {
            return (
                <Tabs
                    value={this.state.displayView}
                    onChange={this.handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    centered
                >
                    <Tab label="General Info" />
                    <Tab label="Payment Info" />
                </Tabs>
            );
        }
    }

    render() {
        return (
            <LoadScreen
                checkPermissions={this.checkPermissions}
            >
                <div className="form-container">

                    {this.renderTabs()}

                    <Grid container>
                        <Grid item xs={12} alignContent={"center"} className="heading-grid">
                            <Typography variant="h1">
                                General Settings
                            </Typography>
                            
                            <Typography>Please update the settings that you want to update, and click the 'Update Info' button to save your changes.</Typography>

                            { this.props.infoForm.error && (
                                <Typography className="error-text">
                                    { this.props.infoForm.error }
                                </Typography>
                            )}

                            { this.props.infoForm.message && (
                                <Typography className="success-text">
                                    { this.props.infoForm.message }
                                </Typography>
                            )}

                            <Info
                                restaurant_id={this.props.match.params.id}
                                scope={this.state.infoScope}
                            />

                            {this.state.infoScope != 'payment' && (
                                <Button variant="contained" fullWidth={true} size={'large'} color="primary" className="button-prim" onClick={this.updateRestaurantSettings}>
                                    Update Settings
                                </Button>
                            )}
                        </Grid>
                    </Grid>
                </div>
            </LoadScreen>
        );
    }
};

const mapStateToProps = (state, props) => {
    return {
        loading: state.restaurantInfoForm.loading,
        curUser: state.loggedUser.user,
        infoForm: state.restaurantInfoForm
    };
};

export default connect(mapStateToProps)(GeneralInformationPage);