// the default items that we can possibly filter by
const dispatchCentersDefaultState = {
    loading: true,
    centers: [],
    selectedDispatchCenter: {},
    filter: ''
};

export default (state = dispatchCentersDefaultState, action) => {
    switch (action.type) {
        case 'SET_DISPATCH_CENTERS':
            return {
                ...state,
                loading: false,
                centers: action.centers,
                filter: ''
            };

        case 'SET_SELECTED_DISPATCH_CENTER':
            return {
                ...state,
                selectedDispatchCenter: action.dispatchCenter
            };
    

        case 'SET_DISPATCH_CENTER_FILTER':
            return {
                ...state,
                filter: action.text
            };

        case 'SET_DISPATCH_CENTERS_LOADING':
            return {
                ...state,
                loading: true
            };
            
        case 'RESET_DISPATCH_CENTERS':
            return dispatchCentersDefaultState;

        default:
            return state;
    }
};