import React from 'react';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';

import validateCurrency from '../../../../utils/validateCurrency';
import { updateAddon } from '../../../../actions/menu/addons/addons';
import { addonFormError, addonFormReset } from '../../../../actions/menu/addons/addonForm';
import { openAddonUpdate, closeAddonUpdate } from '../../../../actions/modals';

import CloseIcon from '../../../common/modal/CloseIcon';

import Info from './Info';

class UpdateAddon extends React.Component {

    constructor(props) {
        super(props);

        this.toggleUpdateModal = this.toggleUpdateModal.bind(this);
        this.submitUpdateAddon = this.submitUpdateAddon.bind(this);
    }

    toggleUpdateModal(isOpen) {
        if (isOpen) {
            this.props.dispatch(addonFormReset());
            this.props.dispatch(openAddonUpdate());
        }
        else {
            this.props.dispatch(closeAddonUpdate());
        }
    }

    submitUpdateAddon() {

        // perform some error checking
        if (
            this.props.addonForm.name.trim() == "" || 
            !validateCurrency(this.props.addonForm.price) || 
            this.props.addonForm.price <= 0) {

            // required info isn't present
            this.props.dispatch(addonFormError("Please enter a addon name and valid price greater than 0."));
        }
        else if (!this.props.addonForm.usePizzas && !this.props.addonForm.useFood && !this.props.addonForm.useSpecials) {
            this.props.dispatch(addonFormError("You must enable your addon for at least one food type."));
        }
        else {

            this.props.dispatch(addonFormError(""));
            this.props.dispatch(updateAddon(this.props.restaurantId, this.props.addon.id, 'info', this.props.addonForm));
        }
    }

    render() {
        return (
            <Modal
                aria-labelledby="addon-add"
                aria-describedby="addon-add"
                open={this.props.updateModalOpen}
                onClose={() => this.toggleUpdateModal(false)}
                className="modal_admin"
                disableBackdropClick
            >
                <div className="modal-member">
                    <Typography variant="h4" id="modal-title">
                        Update {this.props.addon.name}
                        <CloseIcon/>
                    </Typography>

                    {this.props.loading && (
                        <div className="page-loading">
                            <CircularProgress size={100} />
                        </div>
                    )}
        
                    {!this.props.loading && (
                        <div>
                            { this.props.addonForm.error && (
                                <Typography className="modal-error">
                                    Update {this.props.addonForm.error}
                                </Typography>
                            )}

                            { this.props.addonForm.message && (
                                <Typography className="modal-success">
                                    { this.props.addonForm.message }
                                </Typography>
                            )}

                            <Info />

                            <Button variant="contained" fullWidth={true} className="modal-button-bottom" onClick={this.submitUpdateAddon}>
                                Update
                            </Button>
                        </div>
                    )}
                </div>
            </Modal>
        );
    }
};

const mapStateToProps = (state, props) => {
    return {
        loading: state.addons.loading,
        restaurant: state.selectedRestaurant.restaurant,
        addonForm: state.addonForm,
        updateModalOpen: state.modals.addonUpdate
    };
};

export default connect(mapStateToProps)(UpdateAddon);