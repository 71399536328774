import React from 'react';
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { settingsFormAddCustomSauce, settingsFormRemoveCustomSauce } from '../../../../actions/menu/pizzas/settingsForm';

import SettingToggle from '../../../common/SettingToggle';

class CustomPizzaSauces extends React.Component {

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.isSauceSet = this.isSauceSet.bind(this);
    }

    handleChange(e, sauce_id) {
        if (e.target.checked) {
            this.props.dispatch(settingsFormAddCustomSauce(sauce_id));
        }
        else {
            this.props.dispatch(settingsFormRemoveCustomSauce(sauce_id));
        }
    }

    isSauceSet(sauce_id) {

        for (let i = 0; i < this.props.settingsForm.customPizzaSauces.length; i++) {
            if (this.props.settingsForm.customPizzaSauces[i].sauce_id == sauce_id) {
                return true;
            }
        }

        return false;
    }
    
    render() {
        return (
            <div>
                <Typography className="modal-description">
                    Please select the sauces that can come with the custom pizza.
                </Typography>

                <Grid container>
                    {this.props.settings.sauces.map((sauce, index) => (
                        <Grid key={'sauce-'+index} item xs={3} className="form-grid">
                            <SettingToggle
                                name={sauce.name}
                                value={this.isSauceSet(sauce.id)}
                                handleChange={(e) => this.handleChange(e, sauce.id)}
                            />
                        </Grid>
                    ))}
                </Grid>
            </div>
        );
    }
};

const mapStateToProps = (state, props) => {
    return {
        settingsForm: state.pizzaSettingsForm,
        settings: state.pizzas.settings
    };
};

export default connect(mapStateToProps)(CustomPizzaSauces);