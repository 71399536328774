
const foodItemModifierFormDefaultState = {
    min: 0,
    max: 0,
    modifier: {
        id: 0,
        name: '',
        items: []
    },
    error: ''
};

export default (state = foodItemModifierFormDefaultState, action) => {
    
    switch (action.type) {

        case 'FOOD_ITEM_MODIFIER_FORM_UPDATE':
            return {
                ...state,
                [action.foodItemModifier.prop]: action.foodItemModifier.value
            };

        case 'FOOD_ITEM_MODIFIER_FORM_SET':
            return {
                ...state,
                ...action.foodItemModifier
            };

        case 'FOOD_ITEM_MODIFIER_FORM_RESET':
            return foodItemModifierFormDefaultState;

        default:
            return state;
    }
};