import axios from 'axios';
import { API_URL } from '../config/constants';

export const forgotPasswordFormUpdate = ({ prop, value }) => {

    return {
        type: 'FORGOT_PASSWORD_FORM_UPDATE',
        field: { prop, value }
    };
};

export const forgotPasswordFormError = (message) => {

    return {
        type: 'FORGOT_PASSWORD_FORM_ERROR',
        message
    };
};

export const forgotPasswordFormReset = () => {

    return {
        type: 'FORGOT_PASSWORD_FORM_RESET'
    };
};

export const sendForgotPasswordRequest = (email) => {
    return (dispatch) => {
        return axios.post(API_URL+'/api/forgotpassword', {email})
            .then(function(res) {
                console.log(res);
                dispatch(forgotPasswordFormUpdate({prop: 'loading', value: false}));
                dispatch(forgotPasswordFormUpdate({prop: 'message', value: res.data.message}));

            })
            .catch(function(err) {
                console.log(err);
                dispatch(forgotPasswordFormUpdate({prop: 'loading', value: false}));
                dispatch(forgotPasswordFormError("There was an error processing your request. Please try again later."));
            });
    };
}