const modifierFormDefaultState = {
    id: 0,
    name: '',
    min: 0,
    max: 0,
    items: [{
        index: 0,
        id: 0,
        name: '',
        enabled: true,
        deleted: 0,
        sort_order: 0,
        prices: [{
            index: 0,
            id: 0,
            deleted: 0,
            modifier_item_id: 0,
            modifier_price_level_id: 0,
            price: 0.00
        }]
    }],
    price_levels: [{
        index: 0,
        id: 0,
        name: 'default',
        deleted: 0
    }],
    message: '',
    error: '',
};

export default (state = modifierFormDefaultState, action) => {

    switch (action.type) {

        case 'MODIFIER_FORM_UPDATE':
            return {
                ...state,
                [action.modifier.prop]: action.modifier.value
            };

        case 'MODIFIER_FORM_ERROR':
            return {
                ...state,
                message: '',
                error: action.message
            }

        case 'MODIFIER_FORM_SUCCESS':
            return {
                ...state,
                error: '',
                message: action.message
            }

        case 'MODIFIER_FORM_RESET':
            return modifierFormDefaultState;

        case 'MODIFIER_FORM_UPDATE_ITEM':
            return {
                ...state,
                items: state.items.map((item, index) => {
                    if (index == action.item.index) {
                        return {
                            ...item,
                            [action.item.prop]: action.item.value
                        }
                    }
                    return item;
                })
            };

        case 'MODIFIER_FORM_UPDATE_PRICE_LEVEL':
            return {
                ...state,
                price_levels: state.price_levels.map((priceLevel, index) => {
                    if (index == action.item.index) {
                        return {
                            ...priceLevel,
                            [action.item.prop]: action.item.value
                        }
                    }
                    return priceLevel;
                })
            };

        case 'MODIFIER_FORM_UPDATE_PRICE':
            return {
                ...state,
                items: state.items.map((item, index) => {
                    if (index == action.item.index) {
                        
                        let prices = item.prices.map((price, priceIndex) => {
                            if(priceIndex == action.item.priceIndex){
                                return {
                                    ...price,
                                    [action.item.prop]: action.item.value
                                }        
                            }
                            return price;
                        });

                        return {
                            ...item,
                            prices: prices
                        };

                    }
                    return item;
                })
            };


        case 'MODIFIER_FORM_ADD_ITEM':
            return {
                ...state,
                items: state.items.concat(action.item)
            };

        case 'MODIFIER_FORM_REMOVE_ITEM':
            return {
                ...state,
                items: state.items.filter((item, index) => {
                    return index != action.index;
                }).map((item, index) => {
                    return{
                        ...item,
                        index: index,
                        sort_order: index
                    }
                })
            };

        default:
            return state;
    }
};