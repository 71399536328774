export const completeOrderFormUpdate = ({ prop, value }) => {

    return {
        type: 'COMPLETE_ORDER_FORM_UPDATE',
        manager: { prop, value }
    };
};

export const completeOrderFormError = (message) => {

    return {
        type: 'COMPLETE_ORDER_FORM_ERROR',
        message
    };
};

export const completeOrderFormLoading = () => {

    return {
        type: 'COMPLETE_ORDER_FORM_LOADING',
    };
};

export const completeOrderFormReset = () => {
    
    return {
        type: 'COMPLETE_ORDER_FORM_RESET',
    };
}
