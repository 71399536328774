export const itemFormUpdate = ({ prop, value }) => {

    return {
        type: 'ITEM_FORM_UPDATE',
        item: { prop, value }
    };
};

export const itemFormAddItem = (item) => {

    return {
        type: 'ITEM_FORM_ADD_ITEM',
        item
    }
};

export const itemFormRemoveItem = (index) => {

    return {
        type: 'ITEM_FORM_REMOVE_ITEM',
        index
    }
}

export const itemFormError = (message) => {

    return {
        type: 'ITEM_FORM_ERROR',
        message
    };
};

export const itemFormSuccess = (message) => {

    return {
        type: 'ITEM_FORM_SUCCESS',
        message
    };
};

export const itemFormReset = () => {
    
    return {
        type: 'ITEM_FORM_RESET',
    };
}