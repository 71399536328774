import React from 'react';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

// import filterLocations from '../../selectors/locations'; // If we decide to re-add location filters
import LoadScreen from '../common/LoadScreen';
import verifyLogin from '../../utils/verifyLogin';
import { loadPageData } from '../../actions/auth';
import { 
    // setLocationFilter, // If we decide to re-add location filters
    addLocation, 
    updateLocation, 
    loadLocations, 
    toggleOpen,
    toggleLocationEnabled,
    createTestOrder,
} from '../../actions/restaurant/locations';
import { locationFormUpdate, locationFormError, locationFormReset } from '../../actions/restaurant/locationForm';
import { openLocationCreate, closeLocationCreate, openLocationUpdate, closeLocationUpdate } from '../../actions/modals';

import LocationsList from '../restaurants/common/LocationsList';
import CreateLocation from './modal/CreateLocation';
import UpdateLocation from './modal/UpdateLocation';

import checkPermissions from '../../utils/checkUserPermissions';

class DashboardPage extends React.Component {

    constructor(props) {
        super(props);

        this.toggleOpen = this.toggleOpen.bind(this);
        this.checkPermissions = this.checkPermissions.bind(this);
        this.toggleUpdateModal = this.toggleUpdateModal.bind(this);
        this.toggleLocationEnabled = this.toggleLocationEnabled.bind(this);
        this.createTestOrder = this.createTestOrder.bind(this);
        this.submitCreateLocation = this.submitCreateLocation.bind(this);
        this.submitUpdateLocation = this.submitUpdateLocation.bind(this);
        this.toggleOpen = this.toggleOpen.bind(this);

        this.state = {
            sidebarMenuOpen: false,
            selectedLocation: {}
        };
    }

    componentDidMount() {
        verifyLogin(this.props.history);    
    }

    componentDidUpdate() {
        verifyLogin(this.props.history);
    }

    checkPermissions() {
        if (checkPermissions(this.props.user, 'restaurant_staff', this.props.match.params.id) ||
            checkPermissions(this.props.user, 'support')) {
            // load the restaurant
            this.props.dispatch(loadPageData(this.props.match.params));
            this.props.dispatch(loadLocations(this.props.match.params.id));
        } else {
            this.props.history.push('/permission-denied');
        }    
    }

    

    toggleUpdateModal(isOpen, selectedLocation) {
        if (isOpen) {
            this.setState(() => ({
                selectedLocation
            }));

            this.props.dispatch(locationFormReset());
            this.props.dispatch(locationFormUpdate({ prop: 'address', value: selectedLocation.address }));
            this.props.dispatch(locationFormUpdate({ prop: 'address2', value: selectedLocation.address2 }));
            this.props.dispatch(locationFormUpdate({ prop: 'city', value: selectedLocation.city }));
            this.props.dispatch(locationFormUpdate({ prop: 'province', value: selectedLocation.province }));
            this.props.dispatch(locationFormUpdate({ prop: 'postal', value: selectedLocation.postal }));
            this.props.dispatch(locationFormUpdate({ prop: 'phone', value: selectedLocation.phone }));
            this.props.dispatch(locationFormUpdate({ prop: 'phone2', value: selectedLocation.phone2 }));

            this.props.dispatch(locationFormUpdate({ prop: 'add_taxes', value: (selectedLocation.add_taxes == 1) ? true : false }));
            this.props.dispatch(locationFormUpdate({ prop: 'ignore_sms_warning', value: (selectedLocation.ignore_sms_warning == 1) ? true : false }));
            this.props.dispatch(locationFormUpdate({ prop: 'preorder_enabled', value: (selectedLocation.preorder_enabled == 1) ? true : false }));
            this.props.dispatch(locationFormUpdate({ prop: 'promo_code_enable', value: (selectedLocation.promo_code_enable == 1) ? true : false }));

            this.props.dispatch(locationFormUpdate({ prop: 'warning_phone', value: selectedLocation.warning_phone }));
            this.props.dispatch(locationFormUpdate({ prop: 'printer_template', value: selectedLocation.printer_template }));
            this.props.dispatch(locationFormUpdate({ prop: 'payment_type', value: selectedLocation.payment_type }));

            this.props.dispatch(locationFormUpdate({ prop: 'delivery_charge', value: selectedLocation.delivery_charge }));
            this.props.dispatch(locationFormUpdate({ prop: 'max_delivery_range', value: selectedLocation.max_delivery_range }));

            this.props.dispatch(locationFormUpdate({ prop: 'delivery_type', value: selectedLocation.delivery_type }));
            this.props.dispatch(locationFormUpdate({ prop: 'pickup_options', value: selectedLocation.pickup_options }));

            this.props.dispatch(locationFormUpdate({ prop: 'hoursOfOperation', value: selectedLocation.hours_of_operation }));
            this.props.dispatch(locationFormUpdate({ prop: 'holidayHours', value: selectedLocation.holiday_hours }));
            this.props.dispatch(locationFormUpdate({ prop: 'deliveryHours', value: selectedLocation.delivery_hours }));
            this.props.dispatch(locationFormUpdate({ prop: 'deliveryCharges', value: selectedLocation.delivery_charges }));
            this.props.dispatch(locationFormUpdate({ prop: 'promoCodes', value: selectedLocation.promo_codes }));

            this.props.dispatch(locationFormUpdate({ prop: 'dispatchAssociated', value: selectedLocation.dispatchAssociated }));
            this.props.dispatch(locationFormUpdate({ prop: 'restaurant_id', value: selectedLocation.restaurant_id }));

            this.props.dispatch(locationFormUpdate({ prop: 'selectedLocation', value: selectedLocation }));

            this.props.dispatch(openLocationUpdate());
        }
        else {
            this.props.dispatch(closeLocationUpdate());
        }
    }

    toggleLocationEnabled(location, enabled) {
        let confirmStr = "Are you sure you want to disable this location?";
        if (enabled) {
            confirmStr = "Enable this location?";
        }

        if (confirm(confirmStr)) {
            this.props.dispatch(toggleLocationEnabled(this.props.match.params.id, location.id, enabled));
        };
    }

    createTestOrder(location){
        let confirmStr = "Are you sure you would like to create a test order for this restaurant?";
        if(confirm(confirmStr)){
            this.props.dispatch(createTestOrder(this.props.match.params.id, location.id));
        }
    }

    toggleOpen(selectedLocation, action) {
        this.props.dispatch(toggleOpen(this.props.match.params.id, selectedLocation.id, action));
    }

    toggleCreateModal(isOpen) {
        if (isOpen) {
            this.props.dispatch(locationFormReset());
            this.props.dispatch(openLocationCreate());
            this.props.dispatch(locationFormUpdate({ prop: 'activeTab', value: 0 }));
        }
        else {
            this.props.dispatch(closeLocationCreate());
        }
    }

    submitCreateLocation() {

        // perform some error checking
        if (this.props.locationForm.name.trim() == "") {

            // required info isn't present
            this.props.dispatch(locationFormError("Please enter a location name."));
        }
        else {

            // add the location
            this.props.dispatch(addLocation(this.props.match.params.id, this.props.locationForm));
        }
    }

    submitUpdateLocation() {

        // perform some error checking
        if (this.props.locationForm.name.trim() == "") {

            // required fields aren't present
            this.props.dispatch(locationFormError("Please enter a location name."));
        }
        else {

            // add the food location
            this.props.dispatch(updateLocation(this.props.match.params.id, this.state.selectedLocation.id, this.props.locationForm));
        }
    }

    renderLocations() {

        if (this.props.loading) {
            return (
                <div className="page-loading">
                    <CircularProgress size={100} />
                </div>
            );
        }
        else {
            return (
                <div>
                    <h2>Locations</h2>
                    <LocationsList
                        history={this.props.history}
                        locations={this.props.locations}
                        toggleOpen={this.toggleOpen}
                        condensed={true}

                        toggleEnabled={this.toggleLocationEnabled}
                        createTestOrder={this.createTestOrder}
                        toggleUpdateModal={this.toggleUpdateModal}
                    />
                </div>
            );
        }
    }
    
    render() {
        return (
            <LoadScreen
                checkPermissions={this.checkPermissions}
            >
                <Grid container>
                    <Grid item xs={3} className="heading-grid">
                        <h2>Today's Stats</h2>
                        <Card className="card totals-card">
                            <CardContent>
                                <Typography gutterBottom variant="h4" component="h2" className="total-amount">
                                    Total Sales
                                </Typography>
                                <Typography variant="h5" color="textSecondary" component="p" className="total-amount">
                                    ${(+this.props.restaurant.total_revenue).toFixed(2)}
                                </Typography>
                            </CardContent>
                        </Card>

                        <Card className="card totals-card">
                            <CardContent>
                                <Typography gutterBottom variant="h4" component="h2" className="total-amount">
                                    Total Orders
                                </Typography>
                                <Typography variant="h5" color="textSecondary" component="p" className="total-amount">
                                    {this.props.restaurant.total_orders}
                                </Typography>
                            </CardContent>
                        </Card>

                        <Card className="card totals-card">
                            <CardContent>
                                <Typography gutterBottom variant="h4" component="h2" className="total-amount">
                                    Best Seller
                                </Typography>
                                <Typography variant="h5" color="textSecondary" component="p" className="total-amount">
                                    {this.props.restaurant.best_seller}
                                </Typography>
                            </CardContent>
                        </Card>

                        <h2>Item Reports</h2>
                        <Card className="card support-card">
                            <CardContent>
                                <Typography variant="h5" color="textSecondary" component="p" className="total-amount typography-pointer"
                                    onClick={() => { this.props.history.push('/'+this.props.restaurant.id+'/reports/itemized/') }}
                                    >
                                    Visit Item Report Page
                                </Typography>
                            </CardContent>
                        </Card>

                        <h2>Help & Support</h2>
                        <Card className="card support-card">
                            <CardContent>
                                <Typography variant="h5" color="textSecondary" component="p" className="total-amount typography-pointer"
                                    onClick={()=>{ window.open('https://www.chewslocal.ca/support/merchant-support', '_blank') }}
                                    >
                                    Visit Support Page
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={9} className="heading-grid">
                        {this.renderLocations()}
                    </Grid>
                </Grid>


                <div className="window-fab">
                    <Fab className="btn-fab btn-add" onClick={() => this.toggleCreateModal(true)}>
                        <AddIcon />
                    </Fab>
                </div>


                <CreateLocation 
                    restaurantId={this.props.restaurant.id}
                />

                <UpdateLocation 
                    restaurantId={this.props.restaurant.id}
                />
            </LoadScreen>
        );
    }
};

const mapStateToProps = (state, props) => {
    return {
        loading: state.locations.loading || state.restaurants.loading,
        restaurant: state.selectedRestaurant.restaurant,
        locations: state.locations.locations,  
        user: state.loggedUser.user,
        // locations: filterLocations(state.locations.locations, {text: state.locations.filter}), // If we decide to re-add location filtering
        locationForm: state.locationForm,
        createModalOpen: state.modals.locationCreate,
        updateModalOpen: state.modals.locationUpdate,
    };
};

export default connect(mapStateToProps)(DashboardPage);