import axios from 'axios';
import { API_URL } from '../config/constants';

import { addonFormReset } from './menu/addons/addonForm';
import { resetAddons } from './menu/addons/addons';

import { resetCategories } from './menu/categories/categories';
import { categoryFormReset } from './menu/categories/categoryForm';

import { choiceFormReset } from './menu/fooditems/choiceForm';
import { foodItemFormReset } from './menu/fooditems/foodItemForm';
import { resetFoodItems } from './menu/fooditems/foodItems';

import { crustFormReset } from './menu/pizzas/crustForm';
import { customPizzaFormReset } from './menu/pizzas/customPizzaForm';
import { pizzaFormReset } from './menu/pizzas/pizzaForm';
import { resetPizzas } from './menu/pizzas/pizzas';
import { sauceFormReset } from './menu/pizzas/sauceForm';
import { settingsFormReset } from './menu/pizzas/settingsForm';
import { toppingFormReset } from './menu/pizzas/toppingForm';

import { itemFormReset } from './menu/specials/itemForm';
import { specialFormReset } from './menu/specials/specialForm';
import { resetSpecials } from './menu/specials/specials';

import { searchFormReset } from './reports/searchForm';

import { confirmOrderFormReset } from './restaurant/confirmOrderForm';
import { infoFormReset } from './restaurant/infoForm';
import { locationFormReset } from './restaurant/locationForm';
import { resetLocations } from './restaurant/locations';
import { resetLoyalty } from './restaurant/loyalty';
import { loyaltyPointsFormReset } from './restaurant/loyaltyPointsForm';
import { loyaltyRewardFormReset } from './restaurant/loyaltyRewardForm';
import { managerFormReset } from './restaurant/managerForm';
import { resetManagers } from './restaurant/managers';
import { promoCodeFormReset } from './restaurant/promoCodeForm';
import { resetSelectedOrder } from './restaurant/selectedOrder';

import { appDirectoryFormReset } from './superuser/appDirectories/appDirectoryForm';
import { resetAppDirectories } from './superuser/appDirectories/directories';

import { resetDispatchCenters } from './superuser/dispatchCenters/centers';
import { dispatchCenterFormReset } from './superuser/dispatchCenters/dispatchCenterForm';

import { exportMenuReset } from './superuser/menuExportForm';
import { adminRestaurantLocationsReset } from './superuser/restaurantLocations';
import { userManagementFormReset } from './superuser/userManagementForm';

import { loginFormReset } from './loginForm';
import { resetModals } from './modals';
import { resetRestaurants } from './restaurants';
import { resetSelectedRestaurant } from './selectedRestaurant';
import { resetTimezones } from './timezones';

import { resetPasswordFormReset } from './resetPasswordForm';
import { registerManagerFormReset } from './registerManagerForm';

export const setUser = (user) => {
    return {
        type: 'SET_USER',
        user
    }
}

export const removeCurrentUser = () => {
    return {
        type: 'REMOVE_USER'
    }
}

export const resetAllReducers = () => {
    return (dispatch) => {
        dispatch(addonFormReset());
        dispatch(resetAddons());

        dispatch(resetCategories());
        dispatch(categoryFormReset());

        dispatch(choiceFormReset());
        dispatch(foodItemFormReset());
        dispatch(resetFoodItems());

        dispatch(crustFormReset());
        dispatch(customPizzaFormReset());
        dispatch(pizzaFormReset());
        dispatch(resetPizzas());
        dispatch(sauceFormReset());
        dispatch(settingsFormReset());
        dispatch(toppingFormReset());

        dispatch(itemFormReset());
        dispatch(specialFormReset());
        dispatch(resetSpecials());

        dispatch(searchFormReset());

        dispatch(confirmOrderFormReset());
        dispatch(infoFormReset());
        dispatch(locationFormReset());
        dispatch(resetLocations());
        dispatch(resetLoyalty());
        dispatch(loyaltyPointsFormReset());
        dispatch(loyaltyRewardFormReset());
        dispatch(managerFormReset());
        dispatch(resetManagers());
        dispatch(promoCodeFormReset());
        dispatch(resetSelectedOrder());

        dispatch(appDirectoryFormReset());
        dispatch(resetAppDirectories());

        dispatch(resetDispatchCenters());
        dispatch(dispatchCenterFormReset());

        dispatch(exportMenuReset());
        dispatch(adminRestaurantLocationsReset());
        dispatch(userManagementFormReset());

        dispatch(loginFormReset());
        dispatch(resetModals());
        dispatch(resetRestaurants());
        dispatch(resetSelectedRestaurant());
        dispatch(resetTimezones());

        dispatch(resetPasswordFormReset());
        dispatch(registerManagerFormReset());
    }
}

export const loadLoggedInUser = (history, checkPermissions = null) => {
    return (dispatch) => {
        return axios.get(API_URL+'/api/profile')
            .then(function(res) {
                // add the new user
                dispatch(setUser(res.data.data));

                if (checkPermissions) {
                    checkPermissions();
                }

            })
            .catch(function(err) {
                
                // there was an error with the token; remove it and
                // redirect back to login
                localStorage.removeItem('c2o.token')
                history.push('/login');
            });
    };
}