export const loyaltyRewardFormUpdate = ({ prop, value }) => {

    return {
        type: 'LOYALTY_REWARD_FORM_UPDATE',
        reward: { prop, value }
    };
};

export const loyaltyRewardFormError = (message) => {

    return {
        type: 'LOYALTY_REWARD_FORM_ERROR',
        message
    };
};

export const loyaltyRewardFormSuccess = (message) => {

    return {
        type: 'LOYALTY_REWARD_FORM_SUCCESS',
        message
    };
};

export const loyaltyRewardFormReset = () => {
    
    return {
        type: 'LOYALTY_REWARD_FORM_RESET',
    };
}
