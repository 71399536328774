import React from 'react';
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';

import moment from 'moment';
import TimePicker from 'rc-time-picker';

import { dispatchFormUpdateDeliveryHours, dispatchCenterFormUpdate } from '../../../actions/superuser/dispatchCenters/dispatchCenterForm';

const daysOfWeek = [
    {
        label: 'Sunday',
        id: 'sun'
    },
    {
        label: 'Monday',
        id: 'mon'
    },
    {
        label: 'Tuesday',
        id: 'tue'
    },
    {
        label: 'Wednesday',
        id: 'wed'
    },
    {
        label: 'Thursday',
        id: 'thu'
    },
    {
        label: 'Friday',
        id: 'fri'
    },
    {
        label: 'Saturday',
        id: 'sat'
    }
];

class DispatchCenterDeliveryHours extends React.Component {

    constructor(props) {
        super(props);

    }
    
    render() {
        return (
            
            <Grid container>
                <Typography variant="h5" id="modal-title">
                    Delivery Status
                </Typography>
                <Grid container>
                    <Grid item xs={12} className="form-grid">
                        <FormGroup row>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={this.props.dispatchCenterForm.open}
                                        onChange={(e) => { this.props.dispatch(dispatchCenterFormUpdate({ prop: 'open', value: !this.props.dispatchCenterForm.open })); }}
                                        name="open"
                                        color="primary"
                                    />
                                }
                                label="Open"
                                labelPlacement="top"
                                style={{marginLeft: 0, marginBottom: 20, marginTop: 10 }}
                            />
                        </FormGroup>
                    </Grid>
                </Grid>
                <Typography variant="h5" id="modal-title">
                    Delivery Hours
                </Typography>
                <Grid container key={"render3"} className="hour-of-operation-list">
                    <Grid item xs={3} className="form-grid"></Grid>
                    <Grid item xs={3} className="form-image-container">
                        <Typography>
                            Start Time
                        </Typography>
                    </Grid>
                    <Grid item xs={3} className="form-image-container">
                        <Typography>
                            End Time
                        </Typography>
                    </Grid>
                    <Grid item xs={3} className="form-grid"></Grid>
                </Grid> 

                {daysOfWeek.map((day) => {

                    const openingTimes = this.props.dispatchCenterForm.delivery_hours[day.id+'_open'].split(":");
                    let openingHours = openingTimes[0];
                    let openingMinutes = openingTimes[1];

                    const closingTimes = this.props.dispatchCenterForm.delivery_hours[day.id+'_close'].split(":");
                    let closingHours = closingTimes[0];
                    let closingMinutes = closingTimes[1];
                    
                    return (
                        <Grid container key={"renderdow2"+day.id} className="hour-of-operation-list">
                            <Grid item xs={3} className="form-image-container">
                                <Typography>
                                    {day.label}
                                </Typography>
                            </Grid>
                            <Grid item xs={3} className="form-grid">
                                {/* moment requires a date be set but we are not interested in that so a static one is fine */}
                                <TimePicker
                                    allowEmpty={false}
                                    showSecond={false}
                                    defaultValue={moment("2000-01-01").set("hour", openingHours).set("minute", openingMinutes)} 
                                    use12Hours={true}
                                    minuteStep={5}
                                    inputClassName="date-picker-input"
                                    onChange={(date) => { 
                                        let timeString = date.hour().toString().padStart(2, '0') + ":" + date.minutes().toString().padStart(2, '0') + ":00";
                                        this.props.dispatch(dispatchFormUpdateDeliveryHours({ prop: day.id+'_open', value: timeString })); 
                                    }}
                                />

                            </Grid>
                            <Grid item xs={3} className="form-grid">
                                <TimePicker
                                    allowEmpty={false}
                                    showSecond={false}
                                    defaultValue={moment("2000-01-01").set("hour", closingHours).set("minute", closingMinutes)} 
                                    use12Hours={true}
                                    minuteStep={5}
                                    inputClassName="date-picker-input"
                                    onChange={(date) => { 
                                        let timeString = date.hour().toString().padStart(2, '0') + ":" + date.minutes().toString().padStart(2, '0') + ":00";
                                        this.props.dispatch(dispatchFormUpdateDeliveryHours({ prop: day.id+'_close', value: timeString })); 
                                    }}
                                />
                            </Grid>
                            <Grid item xs={3} className="form-image-container">
                                <FormControlLabel
                                    control={
                                    <Checkbox
                                        checked={this.props.dispatchCenterForm.delivery_hours && !!this.props.dispatchCenterForm.delivery_hours[day.id+'_closed']}
                                        onChange={(e) => { this.props.dispatch(dispatchFormUpdateDeliveryHours({ prop: day.id+'_closed', value: !this.props.dispatchCenterForm.delivery_hours[day.id+'_closed'] })); }}
                                        name={"Closed all day?"}
                                        color="primary"
                                    />
                                    }
                                    label={"Closed all day?"}
                                />
                            </Grid>
                        </Grid>
                    )
                })}
            </Grid>
        );
    }
};

const mapStateToProps = (state, props) => {
    return {
        dispatchCenterForm: state.dispatchCenterForm
    };
};

export default connect(mapStateToProps)(DispatchCenterDeliveryHours);