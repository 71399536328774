export const dispatchCenterFormUpdate = ({ prop, value }) => {

    return {
        type: 'DISPATCH_CENTER_FORM_UPDATE',
        directory: { prop, value }
    };
};

export const dispatchFormUpdateDeliveryHours = ({ prop, value }) => {

    return {
        type: 'DISPATCH_FORM_UPDATE_DELIVERY_HOURS',
        location: { prop, value }
    };
};

export const dispatchCenterFormError = (message) => {

    return {
        type: 'DISPATCH_CENTER_FORM_ERROR',
        message
    };
};

export const dispatchCenterFormSuccess = (message) => {

    return {
        type: 'DISPATCH_CENTER_FORM_SUCCESS',
        message
    };
};

export const dispatchCenterFormReset = () => {
    
    return {
        type: 'DISPATCH_CENTER_FORM_RESET',
    };
}