export const registerManagerFormUpdate = ({ prop, value }) => {

    return {
        type: 'REGISTER_MANAGER_FORM_UPDATE',
        field: { prop, value }
    };
};

export const registerManagerFormError = (message) => {

    return {
        type: 'REGISTER_MANAGER_FORM_ERROR',
        message
    };
};

export const registerManagerFormReset = () => {
    
    return {
        type: 'REGISTER_MANAGER_FORM_RESET',
    };
}