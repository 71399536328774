import React from 'react';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import validateCurrency from '../../../../utils/validateCurrency';
import { updatePizza } from '../../../../actions/menu/pizzas/pizzas';
import { pizzaFormUpdate, pizzaFormError } from '../../../../actions/menu/pizzas/pizzaForm';
import { closePizzaUpdate } from '../../../../actions/modals';

import CloseIcon from '../../../common/modal/CloseIcon';

import Info from './Info';
import Toppings from './Toppings';
import Crusts from './Crusts';
import Sauces from './Sauces';

class UpdatePizza extends React.Component {

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.closeUpdateModal = this.closeUpdateModal.bind(this);
        this.updatePizzaInfo = this.updatePizzaInfo.bind(this);
        this.updatePizzaToppings = this.updatePizzaToppings.bind(this);
        this.updatePizzaCrusts = this.updatePizzaCrusts.bind(this);
        this.updatePizzaSauces = this.updatePizzaSauces.bind(this);

        this.state = {
            displayView: 0
        };
    }

    handleChange(event, newValue) {
        this.props.dispatch(pizzaFormUpdate({ prop: 'error', value: '' }));
        this.props.dispatch(pizzaFormUpdate({ prop: 'message', value: '' }));
        this.setState(() => ({displayView: newValue}))
    }

    closeUpdateModal() {
        this.props.dispatch(closePizzaUpdate());
        this.setState(() => ({displayView: 0}));
    }

    updatePizzaInfo() {

        // perform some error checking
        if (this.props.pizzaForm.name.trim() == "") {
            this.props.dispatch(pizzaFormError("Please enter a pizza name."));
        }else if(this.props.pizzaForm.saveSchedule && this.props.pizzaForm.scheduleName.trim() == ""){
            this.props.dispatch(pizzaFormError("Please enter a name for the availability schedule."));
        }else if(this.props.pizzaForm.saveSchedule && this.props.restaurant.availability_schedules.map((schedule) => {return schedule.name}).includes(this.props.pizzaForm.scheduleName.trim())){
            this.props.dispatch(pizzaFormError("An availability schedule with that name already exists."));            
        } else {

            let valid = true;
            // go through each price and determine if it is valid
            for (let i = 0; i < this.props.pizzaForm.prices.length; i++) {

                // ignore any blank rows
                if (this.props.pizzaForm.prices[i].name != '' || this.props.pizzaForm.prices[i].price != '') {
                    if (!validateCurrency(this.props.pizzaForm.prices[i].price) || this.props.pizzaForm.prices[i].price <= 0) {
                        valid = false;
                        this.props.dispatch(pizzaFormError("Please ensure that all prices have a valid price that is greater than 0."));
                    }
                }
            }

            if (valid) {

                // clear the error and attempt to update the pizza info
                this.props.dispatch(pizzaFormError(""));
                this.props.dispatch(updatePizza(this.props.restaurantId, this.props.pizza.id, 'info', this.props.pizzaForm));
            }
        }
    }

    updatePizzaToppings() {

        this.props.dispatch(pizzaFormError(""));
        this.props.dispatch(updatePizza(this.props.restaurantId, this.props.pizza.id, 'toppings', this.props.pizzaForm));
    }

    updatePizzaCrusts() {

        this.props.dispatch(pizzaFormError(""));
        this.props.dispatch(updatePizza(this.props.restaurantId, this.props.pizza.id, 'crusts', this.props.pizzaForm));
    }

    updatePizzaSauces() {

        this.props.dispatch(pizzaFormError(""));
        this.props.dispatch(updatePizza(this.props.restaurantId, this.props.pizza.id, 'sauces', this.props.pizzaForm));
    }

    renderUpdateModalActiveTab() {
        if (this.props.loading) {
            return (
                <div className="page-loading">
                    <CircularProgress size={100} />
                </div>
            );
        }
        else {
            switch(this.state.displayView) {
                case 0:
                    return this.renderUpdateModalInfo();

                case 1:
                    return this.renderUpdateModalToppings();

                case 2:
                    return this.renderUpdateModalCrusts();

                case 3:
                    return this.renderUpdateModalSauces();
            }
        }
    }

    renderUpdateFeedback() {
        return (
            <div>
                { this.props.pizzaForm.error && (
                    <Typography className="modal-error">
                        { this.props.pizzaForm.error }
                    </Typography>
                )}

                { this.props.pizzaForm.message && (
                    <Typography className="modal-success">
                        { this.props.pizzaForm.message }
                    </Typography>
                )}
            </div>
        );
    }

    renderUpdateModalInfo() {
        return (
            <div>
                {this.renderUpdateFeedback()}

                <Info />

                <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom" onClick={this.updatePizzaInfo}>
                    Update Pizza
                </Button>
            </div>
        );
    }

    renderUpdateModalToppings() {
        return (
            <div>
                {this.renderUpdateFeedback()}

                <Toppings />

                <Grid container>
                    <Grid item xs={12} className="form-grid">
                        <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom" onClick={this.updatePizzaToppings}>
                            Update Pizza
                        </Button>
                    </Grid>
                </Grid>
            </div>
        );
    }

    renderUpdateModalCrusts() {
        return (
            <div>
                {this.renderUpdateFeedback()}

                <Crusts />

                <Grid container>
                    <Grid item xs={12} className="form-grid">
                        <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom" onClick={this.updatePizzaCrusts}>
                            Update Pizza
                        </Button>
                    </Grid>
                </Grid>        
            </div>
        );
    }

    renderUpdateModalSauces() {
        return (
            <div>
                {this.renderUpdateFeedback()}

                <Sauces />

                <Grid container>
                    <Grid item xs={12} className="form-grid">
                        <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom" onClick={this.updatePizzaSauces}>
                            Update Pizza
                        </Button>
                    </Grid>
                </Grid>        
            </div>
        );
    }
    
    render() {
        return (
            <Modal
                aria-labelledby="pizza-update"
                aria-describedby="pizza-update"
                open={this.props.updateModalOpen}
                onClose={() => this.closeUpdateModal()}
                className="modal_admin"
                disableBackdropClick
            >
                <div className="modal-member">
                    <Typography variant="h4" id="modal-title">
                        <CloseIcon/>
                    </Typography>
                    <Tabs
                        value={this.state.displayView}
                        onChange={this.handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        centered
                        style={{marginBottom: 20}}
                    >
                        <Tab label="Info and Prices" />
                        <Tab label="Toppings" />
                        <Tab label="Crusts" />
                        <Tab label="Sauces" />
                    </Tabs>

                    <Typography variant="h4" id="modal-title">
                        Update {this.props.pizza.name}
                    </Typography>

                    {this.renderUpdateModalActiveTab()}
                </div>
            </Modal>
        );
    }
};

const mapStateToProps = (state, props) => {
    return {
        loading: state.pizzas.loading,
        restaurant: state.selectedRestaurant.restaurant,
        pizzaForm: state.pizzaForm,
        createModalOpen: state.modals.pizzaCreate,
        updateModalOpen: state.modals.pizzaUpdate,
    };
};

export default connect(mapStateToProps)(UpdatePizza);