const promoCodeAdminFormDefaultState = {
    code: '',
    type: 'PERCENTAGE',
    total_target: 'DELIVERY',
    total_type: 'PERCENTAGE',
    total_avail: 'ALWAYS', // ALWAYS OR LIMITED DATE, LIMITED QUANTITY TO BE SEPARATE
    threshold: 'NO_THRESHOLD', // NO_THRESHOLD, MAX_DISCOUNT_ALLOWED, MINIMUM_TOTAL_SPENT
    threshold_disable: true, // because total_target defaults to delivery we need to start this off disabled
    threshold_limit: 0.00,
    threshold_limit_text: 'Limit',
    auto_apply: false,
    max_uses: 0,
    total: 0.00,
    start_time: null,
    end_time: null,
    deleted: false,
    index: null,
    loading: false,
    editing: false,
    message: '',
    error: '',
    errorTarget: '',
    selectAllLocations: false,
    selectedLocations: [],
    selectedDispatchCenterID: 0,
    location_filter: '',
    selectedPromoCode: null,
    valid_days: {
        sun: true,
        mon: true,
        tue: true,
        wed: true,
        thu: true,
        fri: true,
        sat: true
    },
};

export default (state = promoCodeAdminFormDefaultState, action) => {
    
    switch (action.type) {

        case 'SET_SELECTED_ADMIN_PROMO_CODE':
            return {
                ...state,
                selectedPromoCode: action.promoCode
            };

        case 'PROMO_CODE_ADMIN_FORM_UPDATE':
            return {
                ...state,
                [action.promoCode.prop]: action.promoCode.value
            };

        case 'PROMO_CODE_FORM_UPDATE_VALID_DAYS':
            return {
                ...state,
                valid_days: 
                    {
                        ...state.valid_days,
                        [action.promoCode.prop]: action.promoCode.value
                    }
            };

        case 'PROMO_CODE_ADMIN_FORM_ERROR':
            let newErrorTarget = state.errorTarget;
            if(action.message == ''){
                newErrorTarget = ''; // Also clear out the target
            }
            
            return {
                ...state,
                message: '',
                error: action.message,
                errorTarget: newErrorTarget
            }

        case 'PROMO_CODE_ADMIN_FORM_SUCCESS':
            return {
                ...state,
                error: '',
                message: action.message
            }

        case 'PROMO_CODE_ADMIN_FORM_LOADING':
            return {
                ...state,
                loading: true,
            }

        case 'PROMO_CODE_ADMIN_FORM_EDITING':
            return {
                ...state,
                editing: true,
            }

        case 'PROMO_CODE_ADMIN_FORM_DONE_LOADING':
            return {
                ...state,
                loading: false,
            }

        case 'PROMO_CODE_ADMIN_FORM_SET':
            return {
                ...state,
                ...action.promoCode
            };
        
        case 'SELECT_ALL_PROMO_LOCATIONS':
            let allSelectedLocations = [];

            if(state.selectAllLocations){ // Then we are currently untoggling it
                // Let it be empty
            }else{
                action.allLocations.forEach((location) => {

                    if(!(location && location.dispatch_location && location.dispatch_location.dispatch_center && location.dispatch_location.dispatch_center.id)){
                        return false;
                    }

                    allSelectedLocations.push(location.id);
                });
            }

            return {
                ...state,
                selectedLocations: allSelectedLocations,
                selectAllLocations: !state.selectAllLocations
            };

        case 'TOGGLE_PROMO_LOCATION':

            let selectedLocations = state.selectedLocations;
            if(action.checking){
                selectedLocations.push(action.location_id)
            }else{
                selectedLocations = selectedLocations.filter((loc_id) => {
                    return loc_id != action.location_id;
                });
            }

            return {
                ...state,
                selectedLocations: selectedLocations
            };

        case 'RESET_PROMO_CODE_ADMIN_LOCATIONS':
            return {
                ...state,
                selectedLocations: [],
                location_filter: '',
                selectedDispatchCenterID: 0,
            };

        case 'PROMO_CODE_ADMIN_FORM_RESET':
            return promoCodeAdminFormDefaultState;

        default:
            return state;
    }
};