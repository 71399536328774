const managerFormDefaultState = {
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    foundManagers: [],
    managersEmpty: false,
    message: '',
    error: ''
};

export default (state = managerFormDefaultState, action) => {

    switch (action.type) {

        case 'MANAGER_FORM_UPDATE':
            return {
                ...state,
                [action.manager.prop]: action.manager.value
            };

        case 'MANAGER_FORM_RESET_FOUND_MANAGERS':
            return {
                ...state,
                foundManagers: [],
            };

        case 'MANAGER_FORM_ERROR':
            return {
                ...state,
                message: '',
                error: action.message
            }

        case 'MANAGER_FORM_SUCCESS':
            return {
                ...state,
                error: '',
                message: action.message
            }

        case 'MANAGER_FORM_RESET':
            return managerFormDefaultState;

        default:
            return state;
    }
};