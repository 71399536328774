import React from 'react';
import { connect } from 'react-redux';
import "react-datepicker/dist/react-datepicker.css";

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';

import { openOrderDetails, closeOrderDetails } from '../../../actions/modals';

import CloseIcon from '../../common/modal/CloseIcon';

class OrderDetails extends React.Component {

    constructor(props) {
        super(props);

        this.toggleModal = this.toggleModal.bind(this);
    }

    getPaymentType(order) {
        if (order.payment_method == "in_store") {
            if (order.order_type == "delivery") {
                return "PAY AT DOOR";
            }
            else {
              return "PAY IN STORE";
            }
        }
        else {
            return "PAID ONLINE";
        }
    }

    toggleModal(isOpen) {
        if (isOpen) {
            this.props.dispatch(openOrderDetails());
        }
        else {
            this.props.dispatch(closeOrderDetails());
        }
    }
    
    render() {
        let orderInformation = {};
        if (this.props.orderInformation) {
            orderInformation = this.props.orderInformation[this.props.order.id];
        }

        return (
            <Modal
                aria-labelledby="orderDetails"
                aria-describedby="orderDetails"
                open={this.props.modalOpen}
                onClose={() => this.toggleModal(false)}
                className="modal_admin"
                disableBackdropClick
            >
                <div className="modal-member">
                    <Typography variant="h4" id="modal-title">
                        <CloseIcon/>
                    </Typography>
                    <Typography variant="h2">
                        Order {this.props.order.order_id}
                    </Typography>
                    
                    <Grid container>
                        {(this.props.isDispatch) && (
                            <Grid item xs={(this.props.isDispatch) ? 4 : 6} className="form-grid">
                                <Typography variant="h3">
                                    Location Information
                                </Typography>
                                <Typography>
                                    {this.props.order.restaurant_name}<br />
                                    {this.props.order.location_address}<br />
                                    {this.props.order.location_city}<br />
                                    <strong>Phone:</strong> {this.props.order.location_phone}<br />
                                </Typography>
                            </Grid>
                        )}

                        <Grid item xs={(this.props.isDispatch) ? 4 : 6} className="form-grid">
                            <Typography variant="h3">
                                Customer Information
                            </Typography>
                            <Typography>
                                {this.props.order.name}<br />
                                {(this.props.order.order_type == 'delivery') && (<span>
                                    {this.props.order.address}<br />
                                    {(this.props.order.instructions && this.props.order.instructions != "") && (<span>
                                        <strong>Delivery Instructions:</strong> {this.props.order.instructions}<br />
                                        </span>
                                    )}
                                    </span>
                                )}<br />
                                
                                <strong>Phone:</strong> {this.props.order.phone}<br />
                                <strong>Email:</strong> {this.props.order.email}
                            </Typography>
                            <Typography>
                                <strong>Order Status:</strong> {this.props.order.order_status}<br />
                                <strong>Order Type:</strong> {this.props.order.order_type}<br />
                                <strong>Payment Type:</strong> {this.getPaymentType(this.props.order)}
                            </Typography>
                        </Grid>

                        <Grid item xs={(this.props.isDispatch) ? 4 : 6} className="form-grid">
                            <Typography variant="h3">
                                Order Details
                            </Typography>

                            {(orderInformation && orderInformation.pizzas) && orderInformation.pizzas.map((pizza) => (
                                <div>
                                    <Typography>
                                        <Grid container className="order-item-details">
                                            <Grid item xs={9} className="form-grid-right order-item-details">
                                                {pizza.quantity} x {pizza.size} {pizza.name}
                                            </Grid>
                                            <Grid item container justify="flex-end" xs={3} className="form-grid-left">
                                                {(!this.props.isDispatch) && ("$" + pizza.totalPrice.toFixed(2))}
                                            </Grid>
                                        </Grid>
                                    </Typography>
                                    {(pizza.details.length > 0) && (
                                        <ul className="order-item-details">
                                            {pizza.details.map((detail) => (
                                                <li>
                                                    {/* {detail.description} {(detail.price && detail.price != 0.00 && !this.props.isDispatch) && " - ($"+detail.price+")"} */} {/* Keeping this around in case we want to show prices again? */}
                                                    {detail.description}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                            ))}

                            {(orderInformation && orderInformation.foodItems) && orderInformation.foodItems.map((foodItem) => (
                                <div>
                                    <Typography>
                                        <Grid container className="order-item-details">
                                            <Grid item xs={9} className="form-grid-right order-item-details">
                                                {foodItem.quantity} x {(foodItem.size != '<default>') && foodItem.size+" "}{foodItem.name}
                                            </Grid>
                                            <Grid item container justify="flex-end" xs={3} className="form-grid-left">
                                                {(!this.props.isDispatch) && ("$" + foodItem.totalPrice.toFixed(2))}
                                            </Grid>
                                        </Grid>
                                    </Typography>
                                    {(foodItem.details.length > 0) && (
                                        <ul className="order-item-details">
                                            {foodItem.details.map((detail) => (
                                                <li>
                                                    {/* {detail.description} {(detail.price && detail.price != 0.00 && !this.props.isDispatch) && " - ($"+detail.price+")"} */}
                                                    {detail.description}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                            ))}

                            {(orderInformation && orderInformation.beverages) && orderInformation.beverages.map((beverage) => (
                                <div>
                                    <Typography>
                                        <Grid container className="order-item-details">
                                            <Grid item xs={9} className="form-grid-right order-item-details">
                                                {beverage.quantity} x {(beverage.size != '<default>') && beverage.size+" "}{beverage.name}
                                            </Grid>
                                            <Grid item container justify="flex-end" xs={3} className="form-grid-left">
                                                {(!this.props.isDispatch) && ("$" + beverage.totalPrice.toFixed(2))}
                                            </Grid>
                                        </Grid>
                                    </Typography>
                                    {(beverage.details.length > 0) && (
                                        <ul className="order-item-details">
                                            {beverage.details.map((detail) => (
                                                <li>
                                                    {detail.description} {(detail.price && detail.price != 0.00 && !this.props.isDispatch) && " - ($"+detail.price + ")"}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                            ))}

                            {(orderInformation && orderInformation.specials) && orderInformation.specials.map((special) => (
                                <div>
                                    <Typography>
                                         
                                        <Grid container className="order-item-details">
                                            <Grid item xs={9} className="form-grid-right order-item-details">
                                                {special.quantity} x {special.name}
                                            </Grid>
                                            <Grid item container justify="flex-end" xs={3} className="form-grid-left">
                                                {(!this.props.isDispatch) && ("$" + special.totalPrice.toFixed(2))}
                                            </Grid>
                                        </Grid>
                                    </Typography>
                                    {/* Below subdetails have had price removed serverside */}
                                    {(special.details.length > 0) && (
                                        <ul className="order-item-details">
                                            {special.details.map((detail) => (
                                                <li>
                                                    {detail.description}
                                                    {(detail.details && detail.details.length > 0) && (
                                                        <ul>
                                                            {detail.details.map((subdetail, index) => (
                                                                <li key={'subdetail-'.index}>{subdetail}</li>
                                                            ))}
                                                        </ul>
                                                    )}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                            ))}

                            {(orderInformation && orderInformation.addons) && orderInformation.addons.map((addon) => (
                                <div>
                                    <Typography>
                                        <Grid container className="order-item-details">
                                            <Grid item xs={9} className="form-grid-right order-item-details">
                                                {addon.quantity} x {addon.name}
                                            </Grid>
                                            <Grid item container justify="flex-end" xs={3} className="form-grid-left">
                                                {(!this.props.isDispatch) && ("$" + addon.totalPrice.toFixed(2))}
                                            </Grid>
                                        </Grid>
                                    </Typography>
                                    {(addon.details.length > 0) && (
                                        <ul className="order-item-details">
                                            {addon.details.map((detail) => (
                                                <li>
                                                    {detail.name} {(detail.price && detail.price != 0.00 && !this.props.isDispatch) && " - ($"+detail.price + ")"}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                            ))}

                            <Typography className="order-details-summary">
                                {!this.props.isDispatch && (
                                    <Grid container>
                                        <Grid item xs={6} className="form-grid-right">
                                            <strong>Subtotal:</strong>
                                        </Grid>
                                        <Grid item container justify="flex-end" xs={6} className="form-grid-left">
                                            ${this.props.order.subtotal}
                                        </Grid>
                                    </Grid>
                                )}
                                {(!this.props.isDispatch && this.props.order.additional_charge > 0) && (
                                    <Grid container>
                                        <Grid item xs={6} className="form-grid-right">
                                            <strong>Order Charge:</strong>
                                        </Grid>
                                        <Grid item container justify="flex-end" xs={6} className="form-grid-left">
                                            ${this.props.order.additional_charge}
                                        </Grid>
                                    </Grid>
                                )}
                                {(!this.props.isDispatch && this.props.order.promo_total > 0) && (
                                    <Grid container>
                                        <Grid item xs={6} className="form-grid-right">
                                            <span className="view-order-redeem red-text"><strong>Promo ({this.props.order.promo_name}):</strong></span>
                                        </Grid>
                                        <Grid item container justify="flex-end" xs={6} className="form-grid-left">
                                            <span className="view-order-redeem red-text">$-{this.props.order.promo_total}</span>
                                        </Grid>
                                    </Grid>
                                )}
                                {(!this.props.isDispatch && this.props.order.loyalty_redeem_amount > 0) && (
                                    <Grid container>
                                        <Grid item xs={6} className="form-grid-right">
                                            <span className="view-order-redeem red-text"><strong>Loyalty Reward Redeemed:</strong></span>
                                        </Grid>
                                        <Grid item container justify="flex-end" xs={6} className="form-grid-left">
                                            <span className="view-order-redeem red-text">{this.props.order.loyalty_redeem_name} - $-{this.props.order.loyalty_redeem_amount}</span>
                                        </Grid>
                                    </Grid>
                                )}
                                {(!this.props.isDispatch && this.props.order.taxes > 0) && (
                                    <Grid container>
                                        <Grid item xs={6} className="form-grid-right">
                                            <strong>Taxes:</strong>
                                        </Grid>
                                        <Grid item container justify="flex-end" xs={6} className="form-grid-left">
                                            ${this.props.order.taxes}
                                        </Grid>
                                    </Grid>
                                )}
                                {!this.props.isDispatch && (
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <hr />
                                        </Grid>
                                        {/* {this.props.order.internal_charge && this.props.order.internal_charge > 0 && (
                                            <Grid container item xs={12}>
                                                <Grid item xs={6} className="form-grid-right red-text">
                                                    <strong>Internal Charge:</strong>
                                                </Grid>
                                                <Grid item container justify="flex-end" xs={6} className="form-grid-left red-text">
                                                    -${(Number(this.props.order.internal_charge)).toFixed(2)}
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <hr />
                                                </Grid>
                                            </Grid> // Unsure about formatting here, we don't show the other charges so maybe this can be scrapped. Though the other charges are global and this can differ per order?
                                        )} */}
                                        <Grid item xs={6} className="form-grid-right">
                                            <strong>Total For Restaurant:</strong>
                                        </Grid>
                                        <Grid item container justify="flex-end" xs={6} className="form-grid-left">
                                            ${(Number(this.props.order.subtotal) + Number(this.props.order.taxes) + Number(this.props.order.additional_charge) - Number(this.props.order.promo_total) - Number(this.props.order.loyalty_redeem_amount)).toFixed(2)}
                                        </Grid>
                                    </Grid>
                                )}
                                {this.props.isDispatch && (
                                    <Grid container>
                                        <Grid item xs={6} className="form-grid-right">
                                            <strong>Delivery:</strong>
                                        </Grid>
                                        <Grid item container justify="flex-end" xs={6} className="form-grid-left">
                                            ${this.props.order.delivery_charge}
                                        </Grid>
                                    </Grid>
                                )}
                                {/* {this.props.isDispatch && this.props.order.fuel_surcharge && this.props.order.fuel_surcharge > 0 && (
                                    <Grid container>
                                        <Grid item xs={9} className="form-grid-right">
                                            <strong>Fuel Surcharge (pre-tax):</strong>
                                        </Grid>
                                        <Grid item container justify="flex-end" xs={3} className="form-grid-left">
                                            ${this.props.order.fuel_surcharge}
                                        </Grid>
                                    </Grid>
                                )} */}
                                {this.props.isDispatch && (this.props.order.tip_amount > 0) && (
                                    <Grid container>
                                        <Grid item xs={6} className="form-grid-right">
                                            <strong>Driver Tip:</strong>
                                        </Grid>
                                        <Grid item container justify="flex-end" xs={6} className="form-grid-left">
                                            ${this.props.order.tip_amount}
                                        </Grid>
                                    </Grid>
                                )}
                                {!this.props.isDispatch && (
                                    <Grid container>
                                        <Grid item xs={6} className="form-grid-right">
                                            <strong>Delivery & Driver Tip:</strong>
                                        </Grid>
                                        <Grid item container justify="flex-end" xs={6} className="form-grid-left">
                                            ${(+this.props.order.delivery_charge + +this.props.order.delivery_charge_tax + +this.props.order.tip_amount).toFixed(2)}
                                        </Grid>
                                    </Grid>
                                )}
                                {!this.props.isDispatch && this.props.order.fuel_surcharge && this.props.order.fuel_surcharge > 0 && (
                                    <Grid container>
                                        <Grid item xs={6} className="form-grid-right">
                                            <strong>Fuel Surcharge:</strong>
                                        </Grid>
                                        <Grid item container justify="flex-end" xs={6} className="form-grid-left">
                                            ${(+this.props.order.fuel_surcharge).toFixed(2)}
                                        </Grid>
                                    </Grid>
                                )}

                                {(this.props.order.promo_delivery_total > 0) && (
                                    <Grid container>
                                        <Grid item xs={6} className="form-grid-right">
                                            <span className="view-order-redeem red-text"><strong>Promo ({this.props.order.promo_name}):</strong></span>
                                        </Grid>
                                        <Grid item container justify="flex-end" xs={6} className="form-grid-left">
                                            <span className="view-order-redeem red-text">$-{this.props.order.promo_delivery_total}</span>
                                        </Grid>
                                    </Grid>
                                )}

                                {(!this.props.isDispatch && this.props.order.total > 0) && (
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <hr />
                                        </Grid>
                                        <Grid item xs={6} className="form-grid-right">
                                            <strong>Total:</strong>
                                        </Grid>
                                        <Grid item container justify="flex-end" xs={6} className="form-grid-left">
                                            ${(+this.props.order.total).toFixed(2)}
                                        </Grid>
                                    </Grid>
                                )}
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
            </Modal>
        );
    }
};

const mapStateToProps = (state, props) => {
    return {
        restaurant: state.selectedRestaurant.restaurant,
        orderInformation: state.reportOrders.orderSummary.orderInformation,
        modalOpen: state.modals.orderDetails
    };
};

export default connect(mapStateToProps)(OrderDetails);