import React from 'react';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

class SettingsList extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    render() {
        return (
            <div>
                {this.props.items.length <= 0 && (
                    <p>{this.props.emptyLabel}</p>
                )}
                {this.props.items.map((item, index) => (
                    <Grid key={"item-"+index} container>
                        <Grid item xs={10} className="form-grid">
                            {!this.props.isCustomPizza && <p>{item.name}</p>}
                            {this.props.isCustomPizza && <p>{item.max_toppings} Topping Pizza</p>}
                        </Grid>
                        <Grid item xs={1} className="form-grid">
                            <Button variant="contained" fullWidth={true} color="primary" className="modal-button-list" onClick={() => this.props.handleUpdateView(item, index)}>
                                <EditIcon className="modal-button-icon" />
                            </Button>
                        </Grid>
                        <Grid item xs={1} className="form-grid">
                            <Button variant="contained" fullWidth={true} color="primary" className="modal-button-list" onClick={() => this.props.handleRemove(index)}>
                                <DeleteIcon className="modal-button-icon" />
                            </Button>
                        </Grid>
                    </Grid>
                ))}
                <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom" onClick={this.props.handleAddView}>
                    {this.props.addLabel}
                </Button>
            </div>
        );
    }
};

export default connect()(SettingsList);