import React from 'react';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import arrayMove from 'array-move';

import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

import LoadScreen from '../../common/LoadScreen';
import verifyLogin from '../../../utils/verifyLogin';
import { loadAddons, setAddonFilter, toggleAddonEnabled, updateAddonOrder } from '../../../actions/menu/addons/addons';
import { addonFormUpdate, addonFormReset } from '../../../actions/menu/addons/addonForm';
import { 
    openAddonCreate, 
    closeAddonCreate, 
    openAddonUpdate, 
    closeAddonUpdate
} from '../../../actions/modals';
import { loadPageData } from '../../../actions/auth';
import filterAddons from '../../../utils/filterListName';

import CreateAddon from './modal/CreateAddon';
import UpdateAddon from './modal/UpdateAddon';

import SortableMenuList from '../common/SortableMenuList';

import checkPermissions from '../../../utils/checkUserPermissions';

class AddonsPage extends React.Component {

    constructor(props) {
        super(props);

        this.toggleCreateModal = this.toggleCreateModal.bind(this);
        this.toggleUpdateModal = this.toggleUpdateModal.bind(this);
        this.toggleAddonEnabled = this.toggleAddonEnabled.bind(this);
        this.checkPermissions = this.checkPermissions.bind(this);
        this.onSortEnd = this.onSortEnd.bind(this);

        this.state = {
            sidebarMenuOpen: false,
            selectedAddon: {}
        };
    }

    componentDidMount() {
        verifyLogin(this.props.history);
    }

    componentDidUpdate() {
        verifyLogin(this.props.history);
    }

    checkPermissions() {
        if (checkPermissions(this.props.user, 'restaurant_manager', this.props.match.params.id) ||
            checkPermissions(this.props.user, 'support')) {
            this.props.dispatch(loadPageData(this.props.match.params));
            this.props.dispatch(loadAddons(this.props.match.params.id));
        } else {
            this.props.history.push('/permission-denied');
        } 
    }

    toggleUpdateModal(isOpen, selectedAddon) {
        if (isOpen) {

            this.setState(() => ({
                selectedAddon
            }));
            this.props.dispatch(addonFormReset());
            this.props.dispatch(addonFormUpdate({ prop: 'name', value: selectedAddon.name }));
            this.props.dispatch(addonFormUpdate({ prop: 'price', value: selectedAddon.price }));
            this.props.dispatch(addonFormUpdate({ prop: 'usePizzas', value: (selectedAddon.usePizzas) ? true : false }));
            this.props.dispatch(addonFormUpdate({ prop: 'useFood', value: (selectedAddon.useFood) ? true : false }));
            this.props.dispatch(addonFormUpdate({ prop: 'useSpecials', value: (selectedAddon.useSpecials) ? true : false }));
            this.props.dispatch(openAddonUpdate());
        }
        else {
            this.props.dispatch(closeAddonUpdate());
        }
    }

    toggleCreateModal(isOpen) {
        if (isOpen) {
            this.props.dispatch(addonFormReset());
            this.props.dispatch(openAddonCreate());
        }
        else {
            this.props.dispatch(closeAddonCreate());
        }
    }

    onSortEnd({oldIndex, newIndex})  {

        let newOrder = arrayMove(this.props.addons, oldIndex, newIndex);
        for (let i = 0; i < newOrder.length; i++) {
            newOrder[i]['addon_order'] = i + 1;
        }

        this.props.dispatch(updateAddonOrder(this.props.match.params.id, newOrder));
    }

    renderEnableButton(addon) {
        if (addon.enabled) {
            return (
                <Button size="small" color="primary" onClick={() => this.toggleAddonEnabled(addon, 0)}>
                    Disable
                </Button>
            );
        }
        else {
            return (
                <Button size="small" color="primary" onClick={() => this.toggleAddonEnabled(addon, 1)}>
                    Enable
                </Button>
            );
        }
    }

    toggleAddonEnabled(addon, enabled) {
        let confirmStr = "Are you sure you want to disable this addon?";
        if (enabled) {
            confirmStr = "Enable this addon?";
        }

        if (confirm(confirmStr)) {
            this.props.dispatch(toggleAddonEnabled(this.props.match.params.id, addon.id, enabled));
        };
    }

    renderAddons() {

        if (this.props.loading) {
            return (
                <div className="page-loading">
                    <CircularProgress size={100} />
                </div>
            );
        }
        else {
            if (this.props.addons.length <= 0) {
                return (
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography className="no-results">No addons entered.</Typography>
                        </Grid>
                    </Grid>
                );
            }
            else {
                return (
                    <SortableMenuList
                        items={this.props.addons}
                        toggleEnabled={this.toggleAddonEnabled}
                        toggleUpdateModal={this.toggleUpdateModal}
                        onSortEnd={this.onSortEnd}
                        showImage={false}
                        isCategory={false}
                    />
                );
            }
        }
    }

    renderAddonsPage() {
        return (
            <div>
                <div className="card-container">
                    <Grid container className="menu-page-header">
                        <Grid item xs={12} className="heading-grid">
                            <h1>Addons</h1>
                            <FormControl fullWidth>
                                <TextField 
                                    label="Filter Addons" 
                                    variant="outlined" 
                                    value={this.props.filter}
                                    onChange={(e) => this.props.dispatch(setAddonFilter(e.target.value))} 
                                />
                            </FormControl>
                        </Grid>
                    </Grid>

                    {this.renderAddons()}
                </div>

                <div className="window-fab">
                    <Fab className="btn-fab btn-add" onClick={() => this.toggleCreateModal(true)}>
                        <AddIcon />
                    </Fab>
                </div>

                <CreateAddon
                    restaurantId={this.props.match.params.id}
                />

                <UpdateAddon 
                    addon={this.state.selectedAddon}
                    restaurantId={this.props.match.params.id}
                />
            </div>
        )
    }
    
    render() {
        return (
            <LoadScreen
                checkPermissions={this.checkPermissions}
            >
                {this.renderAddonsPage()}                
            </LoadScreen>
        );
    }
};

const mapStateToProps = (state, props) => {
    return {
        loading: state.addons.loading || state.restaurants.loading,
        restaurant: state.selectedRestaurant.restaurant,
        addons: filterAddons(state.addons.addons, {text: state.addons.filter}),
        addonForm: state.addonForm,
        user: state.loggedUser.user,
    };
};

export default connect(mapStateToProps)(AddonsPage);