export const resetPasswordFormUpdate = ({ prop, value }) => {

    return {
        type: 'RESET_PASSWORD_FORM_UPDATE',
        field: { prop, value }
    };
};

export const resetPasswordFormError = (message) => {

    return {
        type: 'RESET_PASSWORD_FORM_ERROR',
        message
    };
};

export const resetPasswordFormReset = () => {
    
    return {
        type: 'RESET_PASSWORD_FORM_RESET',
    };
}