const confirmOrderFormDefaultState = {
    minutes: 15,
    loading: false,
    error: ''
};

export default (state = confirmOrderFormDefaultState, action) => {

    switch (action.type) {

        case 'CONFIRM_ORDER_FORM_UPDATE':
            return {
                ...state,
                [action.manager.prop]: action.manager.value
            };

        case 'CONFIRM_ORDER_FORM_ERROR':
            return {
                ...state,
                message: '',
                error: action.message
            }

        case 'CONFIRM_ORDER_FORM_LOADING':
            return {
                ...state,
                loading: true
            }

        case 'CONFIRM_ORDER_FORM_RESET':
            return confirmOrderFormDefaultState;

        default:
            return state;
    }
};