const addUserFormDefaultState = {
    first_name: '',
    last_name: '',
    phone: '',
    superuser: false,
    support: false,
    new_password: '',
    message: '',
    loading: false,
    error: '',
    email: '',
};

export default (state = addUserFormDefaultState, action) => {
    
    switch (action.type) {

        case 'ADD_USER_FORM_UPDATE':
            return {
                ...state,
                [action.directory.prop]: action.directory.value
            };

        case 'ADD_USER_FORM_ERROR':
            return {
                ...state,
                message: '',
                loading: false,
                error: action.message
            }

        case 'ADD_USER_FORM_LOADING':
            return {
                ...state,
                loading: true
            }

        case 'ADD_USER_FORM_SUCCESS':
            return {
                ...state,
                error: '',
                loading: false,
                message: action.message
            }

        case "ADD_USER_LIST_UPDATE":
            return {
                ...state,
                users: state.users.map((item) => {
                    if (item.id == action.user.value.id) {
                        return {
                            ...action.user.value,
                        }
                    }

                    return item;
                })
            }

        case 'ADD_USER_FORM_RESET':
            return addUserFormDefaultState;

        default:
            return state;
    }
};