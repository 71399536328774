import axios from 'axios';
import { API_URL } from '../../config/constants';
import { managerFormReset, managerFormError, managerFormUpdate } from './managerForm';
import { closeManagerAdd } from '../modals';

export const setManagers = (managers) => {
    return {
        type: 'SET_MANAGERS',
        managers
    }
}

export const setManagerFilter = (text) => {
    return {
        type: 'SET_MANAGER_FILTER',
        text
    }
}

export const resetManagers = () => {
    return {
        type: 'RESET_MANAGERS'
    }
}

export const setManagersLoading = () => ({
    type: 'SET_MANAGERS_LOADING'
});

export const loadManagers = (restaurant_id) => {
    return (dispatch) => {
        return axios.get(API_URL+'/api/restaurants/'+restaurant_id+'/managers')
            .then(function(res) {
                dispatch(setManagers(res.data.data.managers));
            })
            .catch(function(err) {
                console.log("Err!");
                console.log(err);
            });
    };
}

export const addManager = (restaurantId, managerId, manager_type) => {

    return (dispatch) => {

        return axios.post(
                API_URL+'/api/restaurants/'+restaurantId+'/managers', 
                {
                    manager_id: managerId,
                    manager_type
                }
            )
            .then(function(res) {
                if (res.data.status == 'success') {
                    dispatch(setManagers(res.data.data.managers));
                    dispatch(managerFormReset());
                    dispatch(closeManagerAdd());
                }
                else {
                    dispatch(managerFormError(res.data.message));
                }
            })
            .catch(function(err) {
                
                // token is likely invalid; remove it and prompt the user to login again
                dispatch(managerFormError("We're sorry, but our system has encountered an issue, to which we have been notified."));
                if (err.response.status === 401) {
                    removeAuthToken(dispatch);
                }
                
            });
    };
};

export const addNewManager = (restaurantId, {first_name, last_name, email, password}, manager_type = 'staff') => {

    return (dispatch) => {

        return axios.post(
                API_URL+'/api/restaurants/'+restaurantId+'/managers', 
                {
                    is_new_user: true,
                    first_name,
                    last_name,
                    email,
                    password,
                    manager_type
                }
            )
            .then(function(res) {
                if (res.data.status == 'success') {
                    dispatch(setManagers(res.data.data.managers));
                    dispatch(managerFormReset());
                    dispatch(closeManagerAdd());
                }
                else {
                    dispatch(managerFormError(res.data.message));
                }
            })
            .catch(function(err) {
                
                // token is likely invalid; remove it and prompt the user to login again
                dispatch(managerFormError("We're sorry, but our system has encountered an issue, to which we have been notified."));
                if (err.response.status === 401) {
                    removeAuthToken(dispatch);
                }
                
            });
    };
};

export const revokeManagerAccess = (restaurantId, managerId) => {

    return (dispatch) => {

        dispatch(setManagersLoading());

        return axios.post(API_URL+'/api/restaurants/'+restaurantId+'/managers/'+managerId+'/revoke', {})
            .then(function(res) {
                if (res.data.status == 'success') {
                    dispatch(setManagers(res.data.data.managers));
                    dispatch(managerFormReset());
                    dispatch(closeManagerAdd());
                }
                else {
                    dispatch(managerFormError(res.data.message));
                }
            })
            .catch(function(err) {
                
                // token is likely invalid; remove it and prompt the user to login again
                dispatch(managerFormError("We're sorry, but our system has encountered an issue, to which we have been notified."));
                if (err.response.status === 401) {
                    removeAuthToken(dispatch);
                }
                
            });
    };
};

export const searchManagers = (restaurantId, {first_name, last_name, email}) => {
    
    return (dispatch) => {
        dispatch(managerFormUpdate({ prop: 'loading', value: true }));

        return axios.post(
            API_URL+'/api/restaurants/'+restaurantId+'/managers/search', 
            {
                first_name,
                last_name,
                email
            }
        ).then(function(res) {
            if (res.data.status == 'success') {
                dispatch(managerFormUpdate({ prop: 'foundManagers', value: res.data.data.users }));

                if (res.data.data.users.length <= 0) {
                    dispatch(managerFormUpdate({ prop: 'managersEmpty', value: true }));
                }

                dispatch(managerFormUpdate({ prop: 'loading', value: false }));
                dispatch(managerFormError(''));
            }
            else {
                dispatch(managerFormError(res.data.message));
                console.log(res.data);
                console.log("Could not update your manager!");
            }
        })
        .catch(function(err) {
            console.log('err');
            console.log(err);
            // token is likely invalid; remove it and prompt the user to login again
            dispatch(managerFormError("We're sorry, but our system has encountered an issue, to which we have been notified."));
            if (err.response && err.response.status === 401) {
                removeAuthToken(dispatch);
            }
            
        });
    };
};