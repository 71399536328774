import React from 'react';
import { connect } from 'react-redux';
import arrayMove from 'array-move';

import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import SortableModifierItem from './SortableModifierItem';

import { modifierFormUpdate, modifierFormUpdateItem, modifierFormAddItem, modifierFormRemoveItem, modifierFormUpdatePrice, modifierFormUpdatePriceLevel } from '../../../../actions/menu/modifiers/modifierForm';

class ModifierInfo extends React.Component {

    constructor(props) {
        super(props);

        this.addItem = this.addItem.bind(this);
        this.onSortEnd = this.onSortEnd.bind(this);
        this.deleteItem = this.deleteItem.bind(this);
        this.handleUpdateItem = this.handleUpdateItem.bind(this);
        this.handleUpdatePrice = this.handleUpdatePrice.bind(this);
        this.addPriceLevel = this.addPriceLevel.bind(this);
        this.updatePriceLevel = this.updatePriceLevel.bind(this);
        this.removePriceLevel = this.removePriceLevel.bind(this);
        this.toggleItemEnabled = this.toggleItemEnabled.bind(this);

    }

    addItem() {
                
        let itemIndex = this.props.modifierForm.items.length > 0 ? this.props.modifierForm.items.length : 0;
        let sortOrder = 0;
        for(let i = this.props.modifierForm.items.length - 1; i >= 0; i--){
            if(this.props.modifierForm.items[i].deleted == 0){
                sortOrder = i + 1;
                break;
            }    
        }

        let prices = this.props.modifierForm.price_levels.map((priceLevel, index) => {
            return {
                index: index,
                id: 0,
                deleted: priceLevel.deleted,
                modifier_item_id: 0,
                modifier_price_level_id: priceLevel.id,
                price: 0.00
            };
        });

        this.props.dispatch(modifierFormAddItem({
            index: itemIndex,
            id: 0,
            name: '',
            enabled: true,
            deleted: 0,
            sort_order: sortOrder,
            prices: prices
        }));

    }

    addPriceLevel(){

        let priceIndex = this.props.modifierForm.price_levels.length;
        let priceLevels = this.props.modifierForm.price_levels.concat({
            index: priceIndex,
            id: 0,
            name: '',
            deleted: 0
        });
        this.props.dispatch(modifierFormUpdate({ prop: 'price_levels', value: priceLevels }));

        let items = this.props.modifierForm.items.map((item, index) => {
            return {
                ...item,
                prices: item.prices.concat({
                    index: priceIndex,
                    id: 0,
                    deleted: 0,
                    modifier_item_id: item.id,
                    modifier_price_level_id: 0,
                    price: 0.00
                })
            };
        });
        this.props.dispatch(modifierFormUpdate({ prop: 'items', value: items }));

    }

    updatePriceLevel(prop, index, value){

        this.props.dispatch(modifierFormUpdatePriceLevel({ prop, index, value }));

    }

    removePriceLevel(){
        
        if(this.props.modifierForm.price_levels.length > 1){

            // If the last price level is new then we can just remove it and the prices.            
            if(this.props.modifierForm.price_levels[this.props.modifierForm.price_levels.length - 1].id == 0){

                // Remove the last price level
                let priceLevels = this.props.modifierForm.price_levels.slice(0, -1);
                this.props.dispatch(modifierFormUpdate({ prop: 'price_levels', value: priceLevels }));
    
                // and the last price from each of the items
                let items = this.props.modifierForm.items.map((item, index) => {
                    let prices = item.prices.slice(0, -1);
                    return {
                        ...item,
                        prices: prices
                    };
                });
                this.props.dispatch(modifierFormUpdate({ prop: 'items', value: items }));
                
            }else{

                // Price levels and prices that are in the database are marked as deleted
                let deleteIndex = -1;
                for(let i = this.props.modifierForm.price_levels.length - 1; i > 0; i--){
                    if(this.props.modifierForm.price_levels[i].deleted == 0){
                        deleteIndex = i;
                        break;
                    }    
                }

                this.props.dispatch(modifierFormUpdatePriceLevel({ prop: 'deleted', index: deleteIndex, value: 1 }));
                
                let items = this.props.modifierForm.items.map((item, index) => {
                    
                    let prices = item.prices.map((price, priceIndex) => {
                        if(priceIndex == deleteIndex){
                            return {
                                ...price,
                                deleted: 1
                            }        
                        }
                        return price;
                    });

                    return {
                        ...item,
                        prices: prices
                    };

                });
                this.props.dispatch(modifierFormUpdate({ prop: 'items', value: items }));

            }

        }
    }

    deleteItem(deleteIndex) {
        
        if(this.props.modifierForm.items[deleteIndex].id == 0){
            this.props.dispatch(modifierFormRemoveItem(deleteIndex));
        }else{
            
            let sortOrder = -1;
            let sortedItems = this.props.modifierForm.items.map((item, index) => {                
                let deleted = index == deleteIndex ? 1 : item.deleted;
                if(item.deleted == 0 && deleted == 0) sortOrder++;
                return{
                    ...item,
                    sort_order: sortOrder,
                    deleted: deleted
                }
            });
            this.props.dispatch(modifierFormUpdate({ prop: 'items', value: sortedItems }));

        }

    }

    handleUpdateItem(prop, index, value) {
        this.props.dispatch(modifierFormUpdateItem({ prop, index, value }));
    }

    handleUpdatePrice(prop, index, priceIndex, value){
        this.props.dispatch(modifierFormUpdatePrice({ prop, index, priceIndex, value }));
    }

    onSortEnd({oldIndex, newIndex})  {

        let newItemOrder = arrayMove(this.props.modifierForm.items, oldIndex, newIndex);
        for (let i = 0; i < newItemOrder.length; i++) {
            newItemOrder[i]['index'] = i;
            newItemOrder[i]['sort_order'] = i;
        }

        this.props.dispatch(modifierFormUpdate({ prop: 'items', value: newItemOrder }));
    }

    toggleItemEnabled(index, enabled) {
        let confirmStr = "Are you sure you want to disable this item?";
        if (enabled) {
            confirmStr = "Enable this item?";
        }

        if (confirm(confirmStr)) {
            this.props.dispatch(modifierFormUpdateItem({ prop: 'enabled', index, value: enabled }));
        };
    }

    render() {
        
        return (
            <div>
                <Typography className="modal-description">
                    Please enter a modifier name, min and max.
                </Typography>
                <TextField
                    label="Name"
                    className="modal-input"
                    fullWidth={true}
                    value={this.props.modifierForm.name}
                    onChange={(e) => { this.props.dispatch(modifierFormUpdate({ prop: 'name', value: e.target.value })); }}
                    margin="normal"
                />
                <Grid container>
                    <Grid item xs={3} className="form-grid">
                        <TextField
                            label="Min"
                            className="modal-input"
                            fullWidth={true}
                            value={this.props.modifierForm.min}
                            onChange={(e) => { this.props.dispatch(modifierFormUpdate({ prop: 'min', value: e.target.value })); }}
                            margin="normal"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={3} className="form-grid">
                        <TextField
                            label="Max"
                            className="modal-input"
                            fullWidth={true}
                            value={this.props.modifierForm.max}
                            onChange={(e) => { this.props.dispatch(modifierFormUpdate({ prop: 'max', value: e.target.value })); }}
                            margin="normal"
                            variant="outlined"
                        />
                    </Grid>
                </Grid>
                {/*
                <Grid item xs={12} className="form-grid">
                    <Typography variant="h3">
                        Price Levels
                    </Typography>
                </Grid>
                <Grid container>
                    {this.props.modifierForm.price_levels.filter((item, index) => {
                        return item.deleted == 0;
                    }).map((priceLevel, index) => (
                        <Grid key={"item-"+index} item xs={2} className="form-grid">
                            <TextField
                                label="Name"
                                className="modal-input"
                                fullWidth={true}
                                value={priceLevel.name}
                                onChange={(e) => { this.updatePriceLevel('name', priceLevel.index, e.target.value); }}
                                margin="normal"
                                variant="outlined"
                            />
                        </Grid>
                    ))}
                    <Grid item xs={1} className="list-sort">
                        <Button fullWidth={true} color="secondary" onClick={this.removePriceLevel}>
                            <DeleteIcon />
                        </Button>
                    </Grid>
                </Grid>
                <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom" onClick={this.addPriceLevel}>
                    Add Price Level <AddIcon />
                </Button>
                */}
                <Grid item xs={12} className="form-grid">
                    <Typography variant="h3">
                        Modifier Items
                    </Typography>
                </Grid>
                {this.props.modifierForm.items.filter((item, index) => {
                        return item.deleted == 0;
                    }).length <= 0 && (
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography className="no-results">No items entered.</Typography>
                        </Grid>
                    </Grid>
                )}
                {this.props.modifierForm.items.filter((item, index) => {
                        return item.deleted == 0;
                    }).length > 0 && (
                    <Grid item xs={12} className="form-grid">
                        <SortableModifierItem
                            items={this.props.modifierForm.items}
                            priceLevels={this.props.modifierForm.price_levels}
                            toggleItemEnabled={this.toggleItemEnabled}
                            handleUpdateItem={this.handleUpdateItem}
                            handleDeleteItem={this.deleteItem}
                            handleUpdatePrice={this.handleUpdatePrice}
                            onSortEnd={this.onSortEnd}
                        />
                    </Grid>
                )}
                <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom" onClick={this.addItem}>
                    Add Item <AddIcon />
                </Button>
            </div>
        );
    }
};

const mapStateToProps = (state, props) => {
    return {
        modifierForm: state.modifierForm
    };
};

export default connect(mapStateToProps)(ModifierInfo);