export const categoryFormUpdate = ({ prop, value }) => {

    return {
        type: 'CATEGORY_FORM_UPDATE',
        category: { prop, value }
    };
};

export const categoryFormError = (message) => {

    return {
        type: 'CATEGORY_FORM_ERROR',
        message
    };
};

export const categoryFormSuccess = (message) => {

    return {
        type: 'CATEGORY_FORM_SUCCESS',
        message
    };
};

export const categoryFormReset = () => {
    
    return {
        type: 'CATEGORY_FORM_RESET',
    };
}