import React from 'react';
import { connect } from 'react-redux';
import arrayMove from 'array-move';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ChoicesList from './ChoicesList';
import ChoiceForm from './ChoiceForm';
import SortableMenuList from '../../common/SortableMenuList';
import FoodItemModifiersList from './FoodItemModifiersList';
import FoodItemModifierForm from './FoodItemModifierForm';

import { foodItemModifierFormSet, foodItemModifierFormReset } from '../../../../actions/menu/fooditems/foodItemModifierForm';
import { choiceFormUpdate, choiceFormError, choiceFormReset, choiceFormSet } from '../../../../actions/menu/fooditems/choiceForm';
import { foodItemFormAddChoice, foodItemFormUpdateChoice, foodItemFormRemoveChoice, foodItemFormUpdate, foodItemFormAddModifier, foodItemFormUpdateModifier, foodItemFormRemoveModifier } from '../../../../actions/menu/fooditems/foodItemForm';


import validateCurrency from '../../../../utils/validateCurrency';

class Choices extends React.Component {

    constructor(props) {
        super(props);

        this.handleAddChoiceView = this.handleAddChoiceView.bind(this);
        this.handleAddChoice = this.handleAddChoice.bind(this);
        this.handleUpdateChoiceView = this.handleUpdateChoiceView.bind(this);
        this.handleUpdateChoice = this.handleUpdateChoice.bind(this);
        this.handleCancelButton = this.handleCancelButton.bind(this);
        this.handleRemoveChoice = this.handleRemoveChoice.bind(this);
        this.onSortEnd = this.onSortEnd.bind(this);
        this.handleAddModifier = this.handleAddModifier.bind(this);
        this.handleAddModifierView = this.handleAddModifierView.bind(this);
        this.handleUpdateModifier = this.handleUpdateModifier.bind(this);
        this.handleUpdateModifierView = this.handleUpdateModifierView.bind(this);
        this.handleRemoveModifier = this.handleRemoveModifier.bind(this);
        this.onModifierSortEnd = this.onModifierSortEnd.bind(this);

        this.state = {
            displayView: 'list'
        };
    }

    componentDidMount() {
    }

    onSortEnd({oldIndex, newIndex})  {

        let newChoiceOrder = arrayMove(this.props.foodItemForm.choices, oldIndex, newIndex);
        for (let i = 0; i < newChoiceOrder.length; i++) {
            newChoiceOrder[i]['choice_order'] = i + 1;
        }

        this.props.dispatch(foodItemFormUpdate({ prop: 'choices', value: newChoiceOrder }));
    }

    handleAddChoice() {
        //error checking

        if (this.props.choiceForm.name.trim() == '') {
            this.props.dispatch(choiceFormError('Please enter a choice name'));
        }
        else if (this.props.choiceForm.max_choices.toString().trim() != '' && this.props.choiceForm.max_choices != parseInt(this.props.choiceForm.max_choices, 10)) {
            this.props.dispatch(choiceFormError('Please enter a valid number for max choices'));
        }
        else {
            let valid = true;
            let multipleItemCount = 0;

            // go through each price and determine if it is valid
            for (let i = 0; i < this.props.choiceForm.selections.length; i++) {

                // ignore any blank rows
                if (this.props.choiceForm.selections[i].name != '' || this.props.choiceForm.selections[i].price != '') {
                    if (!validateCurrency(this.props.choiceForm.selections[i].price) || this.props.choiceForm.selections[i].name.match(/^\s*$/)) {
                        valid = false;
                        this.props.dispatch(choiceFormError("Please ensure that all items have a valid name and price."));
                    }
                    else {
                        multipleItemCount++;
                    }
                }
            }

            if (multipleItemCount <= 0 && valid) {
                valid = false;
                this.props.dispatch(choiceFormError("Please enter at least one item to select."));
            }
            else if (this.props.choiceForm.is_multiple && this.props.choiceForm.max_choices > this.props.choiceForm.selections.length && valid) {
                valid = false;
                this.props.dispatch(choiceFormError("Please ensure that your max choices are less than or equal to your total choices."));
            }

            if (valid) {
                this.props.dispatch(choiceFormError(""));
                
                // clean out any items that are empty
                let trimmedItems = this.props.choiceForm.selections.filter((item) => {
                    return item.name != '' && item.price != '';
                });
                this.props.dispatch(choiceFormUpdate({ prop: 'items', value: trimmedItems }));

                // add the choice
                this.props.dispatch(foodItemFormAddChoice(this.props.choiceForm));

                // reset the form and return to the main list view
                this.props.dispatch(choiceFormReset());
                this.setState(() => ({ displayView: 'list' }));
            }
        }
    }

    handleUpdateChoice() {
        //error checking
        if (this.props.choiceForm.name.trim() == '') {
            this.props.dispatch(choiceFormError('Please enter a choice name'));
        }
        else if (this.props.choiceForm.is_multiple && this.props.choiceForm.max_choices.toString().trim() != '' && this.props.choiceForm.max_choices != parseInt(this.props.choiceForm.max_choices, 10)) {
            this.props.dispatch(choiceFormError('Please enter a valid number for max choices'));
        }
        else {
            let valid = true;
            let multipleItemCount = 0;

            // go through each price and determine if it is valid
            for (let i = 0; i < this.props.choiceForm.selections.length; i++) {

                // ignore any blank rows
                if (this.props.choiceForm.selections[i].name != '' || this.props.choiceForm.selections[i].price != '') {
                    if (!validateCurrency(this.props.choiceForm.selections[i].price) || this.props.choiceForm.selections[i].name.match(/^\s*$/)) {
                        valid = false;
                        this.props.dispatch(choiceFormError("Please ensure that all items have a valid name and price."));
                    }
                    else {
                        multipleItemCount++;
                    }
                }
            }

            if (multipleItemCount <= 0 && valid) {
                valid = false;
                this.props.dispatch(choiceFormError("Please enter at least one item to select."));
            }
            else if (this.props.choiceForm.is_multiple && this.props.choiceForm.max_choices > this.props.choiceForm.selections.length && valid) {
                valid = false;
                this.props.dispatch(choiceFormError("Please ensure that your max choices are less than or equal to your total choices."));
            }

            if (valid) {
                this.props.dispatch(choiceFormError(""));
                
                // clean out any items that are empty
                let trimmedItems = this.props.choiceForm.selections.filter((item) => {
                    return item.name != '' && item.price != '';
                });
                this.props.dispatch(choiceFormUpdate({ prop: 'selections', value: trimmedItems }));

                // update the choice
                this.props.dispatch(foodItemFormUpdateChoice(this.props.choiceForm, this.props.choiceForm.index));

                // reset the form and return to the main list view
                this.props.dispatch(choiceFormReset());
                this.setState(() => ({ displayView: 'list' }));
            }
        }
    }

    handleRemoveChoice(index) {
        if (confirm("Remove this choice?")) {
            this.props.dispatch(foodItemFormRemoveChoice(index));
        }
    }

    handleAddChoiceView() {
        this.props.dispatch(choiceFormReset());
        this.setState(() => ({ displayView: 'new_choice' }));
    }

    handleUpdateChoiceView(choice, index) {
        this.props.dispatch(choiceFormSet(choice, index));
        this.setState(() => ({ displayView: 'update_choice' }));
    }

    handleCancelButton() {
        this.setState(() => ({ displayView: 'list' }));
    }

    handleAddModifierView(){
        this.props.dispatch(foodItemModifierFormReset());
        this.setState(() => ({ displayView: 'new_modifier' }));
    }

    handleUpdateModifierView(modifier, index){
        this.props.dispatch(foodItemModifierFormSet(modifier, index));
        this.setState(() => ({ displayView: 'update_modifier' }));
    }

    handleAddModifier(){

        this.props.dispatch(foodItemFormAddModifier(this.props.foodItemModifierForm));

        // reset the form and return to the main list view
        this.props.dispatch(foodItemModifierFormReset());
        this.setState(() => ({ displayView: 'list' }));

    }

    handleUpdateModifier(){

        this.props.dispatch(foodItemFormUpdateModifier(this.props.foodItemModifierForm, this.props.foodItemModifierForm.index));

        // reset the form and return to the main list view
        this.props.dispatch(foodItemModifierFormReset());
        this.setState(() => ({ displayView: 'list' }));

    }

    handleRemoveModifier(index){
        if (confirm("Remove this modifier?")) {
            this.props.dispatch(foodItemFormRemoveModifier(index));
        }
    }

    onModifierSortEnd({oldIndex, newIndex}){
        
        let newModifierOrder = arrayMove(this.props.foodItemForm.modifiers, oldIndex, newIndex);
        for (let i = 0; i < newModifierOrder.length; i++) {
            newModifierOrder[i]['sort_order'] = i + 1;
        }

        this.props.dispatch(foodItemFormUpdate({ prop: 'modifiers', value: newModifierOrder }));

    }

    renderChoiceView() {
        switch (this.state.displayView) {

            case 'new_choice':
                return (
                    <div>
                        <Typography variant="h3">
                            Food Choices
                        </Typography>
                        <Typography className="modal-description">
                            Enter your choice information and available items, and click 'Add Choice'. Please note: you will still need to save the choice after adding it.
                        </Typography>
                        
                        <ChoiceForm 
                            handleCancelButton={this.handleCancelButton}
                            handleSubmitChoice={this.handleAddChoice}
                            handleSubmitLabel={'Add Choice'}
                        />
                    </div>
                );

            case 'update_choice':
                return (
                    <div>
                        <Typography variant="h3">
                            Food Choices
                        </Typography>
                        <Typography className="modal-description">
                            Update your choice information, and click 'Add Choice'. Please note: you will still need to save the choice after adding it.
                        </Typography>

                        <ChoiceForm 
                            handleCancelButton={this.handleCancelButton}
                            handleSubmitChoice={this.handleUpdateChoice}
                            handleSubmitLabel={'Update Choice'}
                        />
                    </div>
                );

            case 'new_modifier':
                return (
                    <div>
                        <Typography variant="h3">
                            Modifiers
                        </Typography>
                        <Typography className="modal-description">
                            Add an existing modifier
                        </Typography>
                        <FoodItemModifierForm 
                            handleCancelButton={this.handleCancelButton}
                            handleSubmitChoice={this.handleAddModifier}
                            handleSubmitLabel={'Add Modifier'}
                        />
                    </div>
                );

            case 'update_modifier':
                return (
                    <div>
                        <Typography variant="h3">
                            Modifiers
                        </Typography>
                        <Typography className="modal-description">
                            Update modifier
                        </Typography>
                        <FoodItemModifierForm 
                            handleCancelButton={this.handleCancelButton}
                            handleSubmitChoice={this.handleUpdateModifier}
                            handleSubmitLabel={'Update Modifier'}
                        />
                    </div>
                );


            case 'list':
            default:
                return (
                    <div>
                        <Typography variant="h3">
                            Food Choices
                        </Typography>
                        <Typography className="modal-description">
                            Enter your food item's available choices, or leave blank for no choice options.
                        </Typography>
                        <ChoicesList 
                            handleAddChoiceView={this.handleAddChoiceView}
                            handleUpdateChoiceView={this.handleUpdateChoiceView}
                            handleRemoveChoice={this.handleRemoveChoice}
                            onSortEnd={this.onSortEnd}
                        />
                        <Typography variant="h3">
                            Modifiers
                        </Typography>
                        <FoodItemModifiersList 
                            handleAddModifierView={this.handleAddModifierView}
                            handleUpdateModifierView={this.handleUpdateModifierView}
                            handleRemoveModifier={this.handleRemoveModifier}
                            onModifierSortEnd={this.onModifierSortEnd}
                        />
                    </div>
                );
        }
    }

    render() {
        return (
            <div>
                {this.renderChoiceView()}
                {this.state.displayView == 'list' && this.props.children}
            </div>
        );
    }
};

const mapStateToProps = (state, props) => {
    return {
        foodItemForm: state.foodItemForm,
        choiceForm: state.choiceForm,
        foodItemModifierForm: state.foodItemModifierForm
    };
};

export default connect(mapStateToProps)(Choices);