import axios from 'axios';
import { API_URL } from '../../../config/constants';
import { addonFormReset, addonFormError, addonFormSuccess, addonFormUpdate } from './addonForm';
import { closeAddonCreate } from '../../modals';

export const setAddons = (addons) => {
    return {
        type: 'SET_ADDONS',
        addons
    }
}

export const setAddonFilter = (text) => {
    return {
        type: 'SET_ADDON_FILTER',
        text
    }
}

export const resetAddons = () => {
    return {
        type: 'RESET_ADDONS'
    }
}

export const setAddonsLoading = () => ({
    type: 'SET_ADDONS_LOADING'
});

export const loadAddons = (restaurant_id) => {
    return (dispatch) => {
        return axios.get(API_URL+'/api/restaurants/'+restaurant_id+'/addons')
            .then(function(res) {
                dispatch(setAddons(res.data.data.addons));
            })
            .catch(function(err) {
                console.log("Err!");
                console.log(err);
            });
    };
}

export const addAddon = (restaurantId, addon) => {

    return (dispatch) => {

        const formData = new FormData();

        // add the image
        formData.append('files', addon.newImage);

        dispatch(setAddonsLoading());

        // add the data
        formData.append('data', JSON.stringify(addon));

        return axios.post(
                API_URL+'/api/restaurants/'+restaurantId+'/addons', 
                formData,
                {
                    headers: { 'Content-Type': 'multipart/form-data' }
                }
            )
            .then(function(res) {
                if (res.data.status == 'success') {
                    dispatch(setAddons(res.data.data.addons));
                    dispatch(addonFormReset());
                    dispatch(closeAddonCreate());
                }
                else {
                    dispatch(addonFormError(res.data.message));
                }
            })
            .catch(function(err) {
                
                // token is likely invalid; remove it and prompt the user to login again
                dispatch(addonFormError("We're sorry, but our system has encountered an issue, to which we have been notified."));
                if (err.response.status === 401) {
                    removeAuthToken(dispatch);
                }
                
            });
    };
};

export const updateAddon = (restaurantId, addonId, scope, addon) => {
    
    return (dispatch) => {

        const formData = new FormData();

        // add the image
        formData.append('files', addon.newImage);

        // add the data
        formData.append('scope', scope);
        formData.append('data', JSON.stringify(addon));

        dispatch(setAddonsLoading());

        return axios.post(
            API_URL+'/api/restaurants/'+restaurantId+'/addons/'+addonId, 
            formData,
            {
                headers: { 'Content-Type': 'multipart/form-data' }
            }
        ).then(function(res) {
            if (res.data.status == 'success') {
                dispatch(setAddons(res.data.data.addons));
                dispatch(addonFormReset());
                dispatch(addonFormUpdate({ prop: 'name', value: res.data.data.addon. name }));
                dispatch(addonFormUpdate({ prop: 'price', value: res.data.data.addon.price }));
                dispatch(addonFormUpdate({ prop: 'usePizzas', value: res.data.data.addon.usePizzas }));
                dispatch(addonFormUpdate({ prop: 'useFood', value: res.data.data.addon.useFood }));
                dispatch(addonFormUpdate({ prop: 'useSpecials', value: res.data.data.addon.useSpecials }));
                dispatch(addonFormSuccess('Addon Updated!'));
            }
            else {
                dispatch(addonFormError(res.data.message));
                console.log(res.data);
                console.log("Could not update your addon!");
            }
        })
        .catch(function(err) {
            console.log('err');
            console.log(err);
            // token is likely invalid; remove it and prompt the user to login again
            dispatch(addonFormError("We're sorry, but our system has encountered an issue, to which we have been notified."));
            if (err.response && err.response.status === 401) {
                removeAuthToken(dispatch);
            }
            
        });
    };
};

export const updateAddonOrder = (restaurant_id, addons) => {

    return (dispatch) => {

        dispatch(setAddonsLoading());

        // prepare the new order of items to move over
        let itemOrder = [];
        for (let i = 0; i < addons.length; i++) {
            itemOrder.push(addons[i].id);
        }

        // prepare the order of addons and IDs to send
        return axios.post(API_URL+'/api/restaurants/'+restaurant_id+'/addons/order', { order: itemOrder })
            .then(function(res) {
                if (res.data.status == 'success') {
                    dispatch(setAddons(res.data.data.addons));
                    dispatch(addonFormSuccess());
                }
                else {
                    dispatch(addonFormError(res.data.message));
                    console.log(res.data);
                    console.log("Could not update addon order!");
                }
            })
            .catch(function(err) {

                // token is likely invalid; remove it and prompt the user to login again
                dispatch(addonFormError("We're sorry, but our system has encountered an issue, to which we have been notified."));
                if (err.response.status === 401) {
                    removeAuthToken(dispatch);
                }
                
            });
    };
};

export const toggleAddonEnabled = (restaurant_id, addon_id, enabled) => {
    
    return (dispatch) => {

        dispatch(setAddonsLoading());
        return axios.post(API_URL+'/api/restaurants/'+restaurant_id+'/addons/'+addon_id, { 
                scope: 'enable',
                enabled 
            })
            .then(function(res) {
                if (res.data.status == 'success') {
                    dispatch(setAddons(res.data.data.addons));
                }
                else {
                    dispatch(addonFormError(res.data.message));
                    console.log(res.data);
                    console.log("Could not add your addon!");
                }
            })
            .catch(function(err) {

                // token is likely invalid; remove it and prompt the user to login again
                dispatch(addonFormError("We're sorry, but our system has encountered an issue, to which we have been notified."));
                if (err.response.status === 401) {
                    removeAuthToken(dispatch);
                }
                
            });
    };
};