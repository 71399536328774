const selectedDefaultState = {
    orderId: 0,
    order: {},
    orderInformation: {},
    orderReceiptError: '',
    orderReceiptMessage: '',
    orderReceiptLoading: false,
};

export default (state = selectedDefaultState, action) => {

    switch (action.type) {
        case 'SET_SELECTED_ORDER':
            
            return {
                ...state,
                orderId: action.orderId,
                order: action.order,
                orderInformation: action.orderInformation,
                orderReceiptMessage: '',
                orderReceiptError: '',
                orderReceiptLoading: false,
            };

        case 'SELECTED_ORDER_RECEIPT_ERROR':
            return {
                ...state,
                orderReceiptMessage: '',
                orderReceiptError: action.message
            }

        case 'SELECTED_ORDER_RECEIPT_MESSAGE':
            return {
                ...state,
                orderReceiptError: '',
                orderReceiptMessage: action.message
            }
    
        case 'SET_SELECTED_ORDER_RECEIPT_LOADING':
            return {
                ...state,
                orderReceiptLoading: true
            }
    
    
        case 'REMOVE_SELECTED_ORDER_RECEIPT_LOADING':
            return {
                ...state,
                orderReceiptLoading: false
            }
    
        case 'RESET_SELECTED_ORDER':
            return selectedDefaultState;

        default:
            return state;
    }
}