import React from 'react';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import moment from 'moment';
import TimePicker from 'rc-time-picker';

const daysOfWeek = [
    {
        label: 'Sunday',
        id: 'sun'
    },
    {
        label: 'Monday',
        id: 'mon'
    },
    {
        label: 'Tuesday',
        id: 'tue'
    },
    {
        label: 'Wednesday',
        id: 'wed'
    },
    {
        label: 'Thursday',
        id: 'thu'
    },
    {
        label: 'Friday',
        id: 'fri'
    },
    {
        label: 'Saturday',
        id: 'sat'
    }
];

class SettingForm extends React.Component {

    constructor(props) {
        super(props);

        this.fillSchedule = this.fillSchedule.bind(this);

        this.state = { 
            selectedScheduleID: 0
        };
    }

    componentDidMount() {
    }

    setSelectedSchedule(scheduleID){
        this.setState(() => ({ 
            selectedScheduleID: scheduleID
        }));
    }

    fillSchedule(){

        let schedule = this.props.restaurant.availability_schedules.filter((schedule) => {
            return schedule.id == this.state.selectedScheduleID;
        });
        
        this.props.updateSetting({ prop: 'hours_available', value: schedule[0] });

    }

    render() {
        return (
            <Grid container>
                { this.props.form.error && (
                    <Grid item xs={12} className="form-grid">
                        <Typography className="modal-error">
                            { this.props.form.error }
                        </Typography>
                    </Grid>
                )}

                <Grid item xs={12} className="form-grid">
                    {this.props.isCustomPizza && (
                        <TextField
                            label="Maximum Selectable Toppings"
                            className="modal-input"
                            fullWidth={true}
                            value={this.props.form.max_toppings}
                            onChange={(e) => { this.props.updateSetting({ prop: 'max_toppings', value: e.target.value }); }}
                            margin="normal"
                        />
                    )}
                    {!this.props.isCustomPizza && (
                        <TextField
                            label="Name"
                            className="modal-input"
                            fullWidth={true}
                            value={this.props.form.name}
                            onChange={(e) => { this.props.updateSetting({ prop: 'name', value: e.target.value }); }}
                            margin="normal"
                        />
                    )}
                </Grid>

                <Typography variant="h5" style={{paddingTop: "40px"}}>
                    Prices
                </Typography>

                {(this.props.showNonTaxable ?? false) && (
                    <Grid className="customPizzaNontaxableIndent" 
                        item xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox 
                                    checked={!!this.props.form.non_taxable}
                                    onChange={(e) => { this.props.updateSetting({ prop: 'non_taxable', value: +!this.props.form.non_taxable }); }}
                                    name={"non_taxable"}
                                    color="primary"
                                />
                            }
                            label="Non-taxable"
                        />
                    </Grid>
                )}
                
                <Grid item xs={12} className="form-grid">
                    {this.props.form.prices.map((price, index) => (
                        <Grid key={'price-'+index} item xs={12} className="form-grid">
                            <Grid key={'setting-'+index} container>
                                <Grid item xs={4} className="form-grid">
                                    <p>{price.size_name}</p>
                                </Grid>
                                <Grid item xs={8} className="form-grid">
                                    <TextField
                                        label="Price"
                                        className="modal-input"
                                        fullWidth={true}
                                        value={price.price}
                                        onChange={(e) => { this.props.updateSettingPrice({ prop: 'price', index, value: e.target.value }); }}
                                        margin="normal"
                                        variant="outlined"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    ))}
                </Grid>

                {(this.props.isCustomPizza) &&
                    <Grid item xs={12} className="form-grid">
                        <Typography variant="h5" style={{paddingTop: "40px"}}>
                            Availability
                        </Typography>
                        <FormControl>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue="alwaysAvailable"
                                value={this.props.form.alwaysAvailable ? 'alwaysAvailable' : 'setAvailability'}
                                name="radio-buttons-group"
                                onChange={(e) => {
                                    this.props.updateSetting({ prop: 'alwaysAvailable', value: !this.props.form.alwaysAvailable });
                                }}
                            >
                                <FormControlLabel value="alwaysAvailable" control={<Radio />} label="Always Available" />
                                <FormControlLabel value="setAvailability" control={<Radio />} label="Set Availability" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                }
                <Grid container style={{padding: "20px 0px"}}>
                    {(this.props.isCustomPizza && !this.props.form.alwaysAvailable) &&
                        <Grid container xs={6} className="previous-choice-window" style={{marginBottom: 20}}>
                            <Typography variant="h4">
                                Use a saved schedule
                            </Typography>
                            <Grid container style={{marginBottom: 10, marginTop: 10}}>
                                <Grid item xs={8} className="form-grid">
                                    <InputLabel>Availability Schedule</InputLabel>
                                    <Select
                                        fullWidth={true}
                                        labelId="type"
                                        value={this.state.selectedScheduleID}
                                        onChange={(e) => { this.setSelectedSchedule(e.target.value); }}
                                    >
                                        {this.props.restaurant.availability_schedules.map((schedule) => (
                                            <MenuItem key={schedule.id} value={schedule.id}>{schedule.name}</MenuItem>
                                        ))}
                                    </Select>
                                </Grid>
                                {this.state.selectedScheduleID != 0 && (
                                    <Grid item xs={4} className="form-grid">
                                        <Button 
                                            variant="contained" 
                                            fullWidth={true} 
                                            color="primary" 
                                            className="modal-button-list" 
                                            onClick={this.fillSchedule}
                                        >
                                            Fill Fields
                                        </Button>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    }                    
                    {(this.props.isCustomPizza && !this.props.form.alwaysAvailable) &&
                        <Grid container key={"render1"} className="hour-of-operation-list">
                            <Grid item xs={3} className="form-grid"></Grid>
                            <Grid item xs={3} className="form-image-container">
                                <Typography>
                                    Start Time
                                </Typography>
                            </Grid>
                            <Grid item xs={3} className="form-image-container">
                                <Typography>
                                    End Time
                                </Typography>
                            </Grid>
                            <Grid item xs={3} className="form-grid"></Grid>
                        </Grid>
                    }

                    {(this.props.isCustomPizza && !this.props.form.alwaysAvailable) && daysOfWeek.map((day) =>{
                        
                        const openingTimes = this.props.form.hours_available[day.id+'_start'].split(":");
                        let openingHours = openingTimes[0];
                        let openingMinutes = openingTimes[1];

                        const closingTimes = this.props.form.hours_available[day.id+'_end'].split(":");
                        let closingHours = closingTimes[0];
                        let closingMinutes = closingTimes[1];

                        return (
                            <Grid container key={"renderow1"+day.id} className="hour-of-operation-list">
                                <Grid item xs={3} className="form-grid">
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={this.props.form.hours_available[day.id+'_available']}
                                                onChange={(e) => { 
                                                    this.props.updateHoursAvailable({ prop: day.id+'_available', value: !this.props.form.hours_available[day.id+'_available'] });
                                                }}
                                                name={day.label}
                                                color="primary"
                                            />
                                        }
                                        label={day.label}
                                    />
                                </Grid>
                                <Grid item xs={3} className="form-grid">
                                    <TimePicker
                                        allowEmpty={false}
                                        showSecond={false}
                                        value={moment("2000-01-01").set("hour", openingHours).set("minute", openingMinutes)} 
                                        use12Hours={true}
                                        minuteStep={5}
                                        inputClassName="date-picker-input"
                                        onChange={(date) => { 
                                            let timeString = date.hour().toString().padStart(2, '0') + ":" + date.minutes().toString().padStart(2, '0') + ":00";                                                                                        
                                            this.props.updateHoursAvailable({ prop: day.id+'_start', value: timeString });
                                        }}
                                        disabled={!this.props.form.hours_available[day.id+'_available'] || this.props.form.hours_available[day.id+'_all_day']} 
                                    />

                                </Grid>
                                <Grid item xs={3} className="form-grid">
                                    <TimePicker
                                        allowEmpty={false}
                                        showSecond={false}
                                        value={moment("2000-01-01").set("hour", closingHours).set("minute", closingMinutes)}
                                        use12Hours={true}
                                        minuteStep={5}
                                        inputClassName="date-picker-input"
                                        onChange={(date) => {
                                            let timeString = date.hour().toString().padStart(2, '0') + ":" + date.minutes().toString().padStart(2, '0') + ":00";
                                            this.props.updateHoursAvailable({ prop: day.id+'_end', value: timeString });
                                        }}
                                        disabled={!this.props.form.hours_available[day.id+'_available'] || this.props.form.hours_available[day.id+'_all_day']}
                                    />
                                </Grid>
                                <Grid item xs={3} className="form-grid">
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={this.props.form.hours_available[day.id+'_all_day']}
                                                onChange={(e) => {
                                                    this.props.updateHoursAvailable({ prop: day.id+'_all_day', value: !this.props.form.hours_available[day.id+'_all_day'] });
                                                }}
                                                name={'availableAllDay'}
                                                color="primary"
                                                disabled={!this.props.form.hours_available[day.id+'_available']}
                                            />
                                        }
                                        label={"Available All Day?"}
                                    />
                                </Grid>
                            </Grid>
                        )
                        
                    })}
                    {(this.props.isCustomPizza && !this.props.form.alwaysAvailable) &&
                        <Grid container>
                            <Grid xs={3} style={{display: 'flex', alignItems: 'end'}}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={this.props.form.saveSchedule}
                                            onChange={(e) => { 
                                                this.props.updateSetting({ prop: 'saveSchedule', value: !this.props.form.saveSchedule });
                                            }}
                                            name={'saveSchedule'}
                                            color="primary"
                                        />
                                    }
                                    label={"Save Schedule?"}
                                />
                            </Grid>
                            <Grid xs={6}>
                                <TextField
                                    id="create-category-title"
                                    label="Schedule Name"
                                    className="modal-input"
                                    fullWidth={true}
                                    value={this.props.form.scheduleName}
                                    onChange={(e) => { this.props.updateSetting({ prop: 'scheduleName', value: e.target.value }); }}
                                    margin="normal"
                                    disabled={!this.props.form.saveSchedule}
                                />
                            </Grid>                            
                        </Grid>
                    }
                </Grid>

                <Grid container>
                    <Grid item xs={6} className="form-grid">
                        <Button variant="contained" fullWidth={true} color="secondary" className="modal-button-bottom" onClick={this.props.handleCancelButton}>
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item xs={6} className="form-grid">
                        <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom" onClick={this.props.handleSubmit}>
                            {this.props.handleSubmitLabel}
                        </Button>
                    </Grid>
                </Grid>   
            </Grid>
        );
    }
};

const mapStateToProps = (state, props) => {
    return {
        restaurant: state.selectedRestaurant.restaurant
    };
};

export default connect(mapStateToProps)(SettingForm);